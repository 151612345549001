import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';

export const rightChoices = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.COMMIT_ANSWERS_FINISH: {
            return update(state, { $set: action.payload.right_choices });
        }
        case ACTION_TYPES.GO_TO_NEXT: {
            return update(state, { $set: {} });
        }
        default:
            return state;
    }
};
