import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { callApi } from '../../../../common/middlewares/apiMiddleware';
import { Album as AlbumPreview } from '../../../../albums/components/Album';
import { objCamelFromSnake } from '../../../../common/helpers/objCamelFromSnake';

class Album extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onLoad: PropTypes.func.isRequired,
    };
    static defaultProps = {
        onLoad: () => {},
    };

    state = {
        inProcess: true,
        canEdit: false,
        album: null,
    };

    componentDidMount() {
        callApi(`/albums/api/albums/${this.props.id}/`).then((response) => {
            const data = objCamelFromSnake(response);
            this.setState({ inProcess: false, album: data, canEdit: data.canEdit });
            this.props.onLoad();
        });
    }

    render() {
        const {
            state: { inProcess, album, canEdit },
        } = this;
        if (inProcess) {
            return (<div className="single-album-container">
                <div className="single-album-preloader" />
            </div>);
        }
        if (!album.count) {
            return null;
        }
        return (
            <AlbumPreview canEdit={ canEdit } album={ album } inRouter={ false } />
        );
    }
}

export default Album;
