import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { dictionary } from '@/common/localization/dictionary';

/* global getCentrifugeConnection */

export default class CommentsLink extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        userId: PropTypes.number.isRequired,
        url: PropTypes.string,
        commentChannel: PropTypes.string,
        commentCount: PropTypes.number,
        newCommentCount: PropTypes.number,
    };

    static defaultProps = {
        url: null,
        commentCount: 0,
        newCommentCount: 0,
        commentChannel: '',
    };

    state = {
        commentCount: this.props.commentCount,
        newCommentCount: this.props.newCommentCount,
    };

    componentDidMount = () => {
        if (this.props.commentChannel) {
            const centrifuge = getCentrifugeConnection();
            this.subscription = centrifuge.subscribe(
                this.props.commentChannel,
                (message) => {
                    if (message.data.key === 'comment' && !message.data.data.edited) {
                        let newCommentCount = this.state.newCommentCount;
                        if (message.data.data.userid !== this.props.userId) {
                            newCommentCount += 1;
                        }
                        this.setState(
                            prevState => ({
                                commentCount: prevState.commentCount + 1,
                                newCommentCount,
                            }),
                        );
                    }
                },
            );
        }
    };

    componentWillUnmount = () => {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };

    render() {
        let newCommentCount = null;
        if (+this.state.newCommentCount > 0) {
            newCommentCount = (
                <span className="comment_cnt new_comment_cnt">
                    { this.state.newCommentCount }
                </span>
            );
        }

        return (
            <A
                className="b-flexrow comment-button-simple"
                onClick={ this.props.onClick }
                href={ this.props.url }
                role="button"
            >
                <span className="comment-text">{ dictionary.comments }&nbsp;</span>
                <span className="fa fa-comments-o" />
                &nbsp;
                <span className="comment_counter comment_cnt">
                    { this.state.commentCount }
                </span>
                &nbsp;
                { newCommentCount }
            </A>
        );
    }
}
