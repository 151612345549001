import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { styles, backgroundColors } from '../constants/styles';

export class UrlPreview extends Component {
    static propTypes = {
        description: PropTypes.string.isRequired,
        image: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        descriptionLength: PropTypes.number,
    };

    static defaultProps = {
        image: null,
        descriptionLength: 200,
    };

    state = {
        imageFirstLetterFallback: !this.props.image,
    };

    getTitleHash = (title) => {
        const h = title
            ? title
                .split('')
                .map(c => c.charCodeAt(0))
                .reduce((sum, current) => sum + current, 0)
            : 0;
        return h % backgroundColors.length;
    };

    getImageFirstLetterFallback = () => {
        const titleHash = this.getTitleHash(this.props.title);
        const fontSize = styles.image.width / 2;

        return (
            <div
                style={ {
                    ...styles.firstLetterWrapper,
                    backgroundColor: backgroundColors[titleHash],
                } }
            >
                <span style={ { ...styles.firstLetter, fontSize } }>
                    { this.props.title[0] }
                </span>
            </div>
        );
    };

    handleImageLoadError = () => {
        this.setState({
            imageFirstLetterFallback: true,
        });
    };

    render() {
        const description = this.props.description.length > this.props.descriptionLength ?
            `${this.props.description.substring(0, this.props.descriptionLength)}...`
            : this.props.description;

        return (<div style={ styles.root } >
            {!!this.props.image && this.props.image.length > 0 ? (
                <div style={ styles.imageWrapper }>
                    { !this.state.imageFirstLetterFallback
                        ? <img
                            src={ this.props.image }
                            style={ styles.image }
                            onError={ this.handleImageLoadError }
                        />
                        : this.getImageFirstLetterFallback()
                    }
                </div>
            ) : <div style={ styles.imageWrapper }>{ this.getImageFirstLetterFallback() }</div>
            }
            <div style={ styles.titleWrapper }>
                <div style={ styles.title }>
                    {this.props.url
                        ? <A href={ this.props.url } target="_blank" rel="noopener noreferrer">{ this.props.title }</A>
                        : this.props.title }
                </div>
                {!!this.props.description &&
                    <div
                        style={ styles.description }
                    >
                        { description }
                    </div>
                }
            </div>
        </div>);
    }
}
