import ACTION_TYPES from '../actionTypes';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';

const INITIAL_STATE = {
    picture: null,
    question: {},
    text: '',
};

const tagsRegexp = new RegExp(/(<([^>]+)>)/, 'ig');

export const quizByPass = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZ_BY_PASS_LOAD_FINISH: {
            const data = objCamelFromSnake(action.payload);
            data.question.text = data.question.text.replace(tagsRegexp, '');
            data.text = data.text.replace(tagsRegexp, '');
            return data;
        }
        default:
            return state;
    }
};
