import React from 'react';
import { Select } from 'intdev-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { commonMedia } from '@/common/media';


class DropDownQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.shape().isRequired,
        choicesList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })).isRequired,
        value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: null,
    };

    currentValue = () => {
        if (this.props.value) {
            for (const choice of this.props.choicesList) {
                if (this.props.value === choice.id) {
                    return choice;
                }
            }
        }
        return {};
    };

    handleChange = (value) => {
        const answer = (value) ? { value: value.id } : { value };
        this.props.onChange(null, answer);
    };

    render() {
        const options = this.props.choicesList.map((item) => {
            const newItem = item;
            newItem.text = item.text.replace(/<\/?[^>]+(>|$)/g, '');
            return newItem;
        });
        return (
            <Select
                onChange={ this.handleChange }
                options={ options }
                value={ this.currentValue() }
                labelKey="text"
                placeholder="Выберите ответ"
                menuStyle={ commonMedia.fontSizeBody }
                valueStyle={ commonMedia.fontSizeBody }
                hintStyle={ commonMedia.fontSizeBody }
                inputProps={ { style: { ...commonMedia.fontSizeBody } } }
            />
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id] || {};
    return {
        choicesList: ownProps.question.choice_set.map(choiceId => state.choices[choiceId]),
        value: answer.value || null,
    };
};

export default connect(mapStateToProps)(DropDownQuestion);
