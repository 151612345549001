import React from 'react';
import PropTypes from 'prop-types';
import { Record as BasicRecord } from './Record';


export class ServiceSincereRecord extends React.Component {
    static propTypes = {
        type_name: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    };

    render() {
        const {
            type_name: typeName, header, text, ...rest
        } = this.props;

        return (
            <BasicRecord
                { ...rest }
                header={ `${typeName}: ${header}` }
                text={ `<div class='timeline-text--sincere-service-story'>${text}</div>` }
            />
        );
    }
}
