/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Dialog, Validation } from 'intdev-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showNotification } from '../../../common/helpers/showNotification';
import { isEmptyObject } from '../../../common/helpers/helpers';
import { apiUrls } from '../../constants/apiUrls';
import { SUGGESTION_SELECT_VALUE_SHAPE } from '../../../user_suggestions/constants';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';

import {
    eventModalCleanValidationErrors,
    eventModalFieldValueChange,
    eventModalSetValidationErrors,
    eventPanelCreate, modalClose,
    loadLocations,
} from '../../actions';
import EventEditPanel from './EventEditPanel';
import EventSinglePanel from './EventSinglePanel';
import EventCopyPanel from './EventCopyPanel';
import getValidationProps from '../../helpers/getValidationProps';
import {
    ACCEPT_DATE_FORMAT, CREATE_TYPE_COPY, CREATE_TYPE_SERIES, CREATE_TYPE_SINGLE,
    isFieldValueEmpty, LabeledRadioSelect, PANEL_GLOBAL_ERROR, styles,
} from './EventEditModal';
import { getModalData } from '../../reducers';
import formatDuration from '../../helpers/formatDuration';
import { choiceShape } from '../../../common/constants/propTypes';

export class EventEditModalComponent extends React.Component {
    static propTypes = {
        // config
        user: SUGGESTION_SELECT_VALUE_SHAPE,
        objectId: PropTypes.number,
        contentType: PropTypes.number,
        canChangeOwner: PropTypes.bool,
        minCreateHour: PropTypes.number.isRequired,
        maxCreateHour: PropTypes.number.isRequired,
        minuteStartStep: PropTypes.number.isRequired,
        minEventDuration: PropTypes.number.isRequired,
        maxEventDuration: PropTypes.number.isRequired,
        minuteDurationStep: PropTypes.number.isRequired,
        // dispatch
        onFieldChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        setValidationErrors: PropTypes.func.isRequired,
        cleanValidationErrors: PropTypes.func.isRequired,
        loadLocations: PropTypes.func.isRequired,
        eventPanelCreate: PropTypes.func.isRequired,
        // from state
        createType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: choiceShape,
        place: PropTypes.string.isRequired,
        capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        copyStartDate: PropTypes.instanceOf(Date).isRequired,
        copyEndDate: PropTypes.instanceOf(Date).isRequired,
        copyPeriodMinutes: PropTypes.number,
        copyPeriodDays: PropTypes.number,
        canPlus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        startTime: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
        copyStartTime: PropTypes.string.isRequired,
        copyEndTime: PropTypes.string.isRequired,
        owner: SUGGESTION_SELECT_VALUE_SHAPE.isRequired,
        processing: PropTypes.bool.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
        usersJoinAllowed: PropTypes.arrayOf(SUGGESTION_SELECT_VALUE_SHAPE).isRequired,
    };

    static defaultProps = {
        user: null,
        objectId: null,
        contentType: null,
        owner: null,
        canChangeOwner: null,
        location: null,
        copyPeriodMinutes: null,
        copyPeriodDays: null,
        capacity: null,
    };

    componentDidMount() {
        this.props.loadLocations();
    }

    getModalTitle = () => {
        const title = 'Создать событие';
        const validationProps = getValidationProps(this.props.validationErrors, PANEL_GLOBAL_ERROR);
        if (validationProps) {
            return (
                <div>
                    {title}
                    <Validation
                        state={ validationProps.validationState }
                        value={ validationProps.validationText }
                    />
                </div>
            );
        }
        return title;
    };

    getFormData = () => {
        const {
            name,
            createType,
            location,
            place,
            capacity,
            canPlus,
            startDate,
            startTime,
            duration, // renamed to eventDuration
            owner,
            usersJoinAllowed,
            copyStartDate,
            copyStartTime,
            copyEndDate,
            copyEndTime,
            copyPeriodMinutes,
            copyPeriodDays,
            canChangeOwner,
        } = this.props;
        const startMoment = moment(startDate).add(moment.duration(startTime));
        const { contentType, objectId } = this.props;
        const editData = {
            content_type: contentType,
            object_id: objectId,
            name,
            location: location && location.id,
            place,
            capacity: capacity === '' ? null : capacity,
            can_plus: canPlus,
            users_join_allowed: usersJoinAllowed.map(user => user.value),
            ...(canChangeOwner ? { owner: owner ? (owner.value || owner) : null } : null),
        };
        const copyEndMoment = moment(copyEndDate).add(moment.duration(copyEndTime));
        const copyStartMoment = moment(copyStartDate).add(moment.duration(copyStartTime));
        const editDataCopy = {
            copy_start_date: copyStartMoment.format(ACCEPT_DATE_FORMAT),
            copy_start_time: copyStartMoment.utc().format('HH:mm'),
            duration: formatDuration(moment.duration(duration)),
            copy_end_date: copyEndMoment.format(ACCEPT_DATE_FORMAT),
            copy_end_time: copyEndMoment.utc().format('HH:mm'),
            copy_period_days: copyPeriodDays,
            copy_period_minutes: copyPeriodMinutes,
        };
        const editDataSingle = {
            start_time: startMoment.toISOString(),
            duration: formatDuration(moment.duration(duration)),
        };
        return {
            [CREATE_TYPE_COPY]: { ...editData, ...editDataCopy },
            [CREATE_TYPE_SERIES]: { ...editData, ...editDataCopy },
            [CREATE_TYPE_SINGLE]: { ...editData, ...editDataSingle },
        }[createType];
    };

    getRequiredFields = () => {
        const requiredFields = [
            'name',
            'location',
            'place',
            'can_plus',
            'duration',
        ];
        const copyFields = [
            'copy_start_date',
            'copy_start_time',
            'copy_end_date',
            'copy_end_time',
            'copy_period_days',
            'copy_period_minutes',
        ];
        const { createType } = this.props;
        if (createType === CREATE_TYPE_COPY || createType === CREATE_TYPE_SERIES) {
            requiredFields.push(...copyFields);
        }
        return new Set(requiredFields);
    };

    handleSave = () => {
        const formData = this.getFormData();

        // check required fields
        const errors = {};
        const requiredFields = this.getRequiredFields();
        Object.entries(formData).forEach(([key, val]) => {
            const isFieldRequired = requiredFields.has(key);
            if (isFieldRequired && isFieldValueEmpty(val)) {
                errors[key] = 'Это поле обязательно';
            }
        });
        if (!isEmptyObject(errors)) {
            this.props.setValidationErrors(errors);
            return;
        }

        const url = {
            [CREATE_TYPE_COPY]: apiUrls().meetingCreateMulti(),
            [CREATE_TYPE_SERIES]: apiUrls().meetingCreateSeries(),
            [CREATE_TYPE_SINGLE]: apiUrls().meetingList(),
        }[this.props.createType];

        this.props.eventPanelCreate(url, formData).then((data) => {
            const {
                fail,
                statusCode,
                ...payload
            } = data;
            if (fail) {
                this.props.setValidationErrors(payload);
                showNotification({ message: 'Ошибка при сохранении', level: 'warning' });
            } else {
                showNotification({ message: 'Событие сохранено', level: 'success' });
                this.props.onClose();
            }
        }).catch(() => {
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
        });
    };

    render() {
        const actions = [
            <Button
                label="Отмена"
                onClick={ this.props.onClose }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
            <Button
                label="Сохранить"
                primary
                onClick={ this.handleSave }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
        ];

        return (
            <Dialog
                open
                autoDetectWindowHeight
                autoScrollBodyContent
                bodyStyle={ styles.dialogBody }
                title={ this.getModalTitle() }
                actions={ actions }
                actionsContainerStyle={ styles.actionsContainerStyle }
                onRequestClose={ this.props.onClose }
            >
                <div style={ styles.fieldContainer }>
                    <EventEditPanel />
                    <LabeledRadioSelect
                        label="Событие"
                        name="createType"
                        options={ [
                            { label: 'одиночное', value: CREATE_TYPE_SINGLE },
                            { label: 'повторяющееся', value: CREATE_TYPE_COPY },
                            { label: 'серия', value: CREATE_TYPE_SERIES },
                        ] }
                        value={ this.props.createType }
                        onChange={ this.props.onFieldChange }
                        disabled={ this.props.processing }
                        { ...styles.radioSelectStyleSet }
                    />
                    <div>
                        {this.props.createType === CREATE_TYPE_SINGLE &&
                            <EventSinglePanel />
                        }
                        {(this.props.createType === CREATE_TYPE_COPY
                            || this.props.createType === CREATE_TYPE_SERIES) &&
                            <EventCopyPanel />
                        }
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    onFieldChange: eventModalFieldValueChange,
    onClose: modalClose,
    loadLocations,
    eventPanelCreate,
    setValidationErrors: eventModalSetValidationErrors,
    cleanValidationErrors: eventModalCleanValidationErrors,
}, dispatch);

const mapStateToProps = state => ({
    ...getModalData(state),
});

const EventEditModal = connect(mapStateToProps, mapDispatchToProps)(EventEditModalComponent);

export default EventEditModal;
