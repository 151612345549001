import { CALL_API } from '../../constants/actionTypes';
import { loadChunkURL, editChunkURL } from './apiUrls';
import { FORMDATA } from '../../constants/ajaxBodyTypes';
import {
    GET_CHUNK_LOADING,
    GET_CHUNK_FINISH,
    GET_CHUNK_FAIL,
    EDIT_CHUNK_LOADING,
    EDIT_CHUNK_FINISH,
    EDIT_CHUNK_FAIL,
} from './actionTypes';

export const loadChunk = name => ({
    type: CALL_API,
    method: 'get',
    endpoint: loadChunkURL(name),
    types: [GET_CHUNK_LOADING, GET_CHUNK_FINISH, GET_CHUNK_FAIL],
    additionalData: { name },
    payload: data => ({
        objectId: data.id,
        contentType: data.content_type,
        text: data.text,
        canEdit: data.edit,
    }),
});

export const editChunk = (name, data) => ({
    type: CALL_API,
    method: 'post',
    endpoint: editChunkURL(name),
    bodyFormat: FORMDATA,
    body: data,
    types: [EDIT_CHUNK_LOADING, EDIT_CHUNK_FINISH, EDIT_CHUNK_FAIL],
    additionalData: { name, text: data.text },
});
