import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { QuizDetailHeader } from '../components/QuizDetailHeader';
import { quizToArchive, restrictQuiz, setActive, unRestrictQuiz } from '../actions/manage';


const mapStateToProps = ({ quizApp: state, userPermissions }) => ({
    quizEditPerm: state.permissions.quizEditPerm,
    viewResultsPerm: state.permissions.viewResultsPerm,
    pubOnMainPagePerm: userPermissions.permissions['quiz.publish_quiz_on_main_page'],
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setActive,
    quizToArchive,
    handleRestrictQuiz: restrictQuiz,
    handleUnrestrictQuiz: unRestrictQuiz,
}, dispatch);

const QuizDetailHeaderContainer = connect(
    mapStateToProps, mapDispatchToProps,
)(QuizDetailHeader);

export default QuizDetailHeaderContainer;
