import update from 'immutability-helper';
import {
    LOAD_PERMISSIONS_SUCCESS,
    LOAD_PERMISSIONS_START,
} from '../constants/actionConstants';


const defaultState = {
    permissions: {},
    isLoaded: false,
};


export const userPermissions = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PERMISSIONS_START:
            return update(state, {
                isLoaded: { $set: false },
            });

        case LOAD_PERMISSIONS_SUCCESS:
            return update(state, {
                permissions: {
                    $merge: action.payload.permissions,
                },
                isLoaded: { $set: true },
            });

        default:
            return state;
    }
};
