import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';

import ACTION_TYPES from '../actionTypes';
import { showNotification } from '../../../common/helpers/showNotification';

export const questionsWithError = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.STRICT_QUESTION_VALIDATION_ERROR: {
            return update(state, {
                $set: action.strictQuestions,
            });
        }
        case ACTION_TYPES.COMMIT_ANSWERS_FINISH:
        case ACTION_TYPES.GO_TO_PREVIOUS: {
            return update(state, {
                $set: [],
            });
        }
        case ACTION_TYPES.API_FAIL: {
            const { status, ...errors } = action.error;
            let message = 'Не удалось выполнить операцию';
            const errorKeys = Object.keys(errors);
            if ('detail' in errors) {
                message = errors.detail;
            } else if (errorKeys.length) {
                const keyOfError = errorKeys[0];
                const messages = errors[keyOfError];
                message = Array.isArray(messages) ? messages[0] : messages;
            }

            showNotification({ level: 'error', message });
            const questionWithErrors = Object.keys(errors).filter(item => !isNaN(item));
            if (status === 400) {
                return update(state, { $push: questionWithErrors.map(item => parseInt(item, 10)) });
            }
            return state;
        }
        case LOCATION_CHANGE:
            return [];
        default:
            return state;
    }
};
