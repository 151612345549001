import { normalize, schema } from 'normalizr';
import * as Sentry from '@sentry/browser';
import { Record } from './components/Record';
import { AggregatedThanksBlock } from './components/AggregatedThanks/AggregatedThanksBlock';
import { BadgeRecord } from './components/BadgeRecord';
import { CongratulationRecord } from './components/CongratulationRecord';
import { ThanksRecord } from './components/ThanksRecord';
import { Record as MicropostRecord } from '../micropost/components/Record';
import { IdeaRecord } from './components/IdeaRecord';
import contentTypes from '../common/constants/contentTypes';
import { TermStudentRecord } from './components/TermStudentRecord';
import { UserAvatarRecord } from './components/UserAvatarRecord';
import { PersonalAggregatedBadgeRecord } from './components/personalAggregated/PersonalAggregatedBadgeRecord';
import { AlbumPhotoRecord } from './components/AlbumPhotoRecord';
import { VideoRecord } from './components/VideoRecord';
import { FollowingRecommendation } from './components/Recommendation/FollowingRecommendation';
import { AlbumRecord } from './components/AlbumRecord';
import { AggregatedSkillsProves } from './components/skills/AggregatedSkillsProves';
import { SkillRecord } from './components/skills/SkillRecord';
import CommentRecord from './components/CommentRecord';
import { BasicRecordWithDate } from './components/BasicRecordWithDate';
import { ServiceSincereRecord } from './components/ServiceSincereRecord';
import { PecocracyStoryRecord } from './components/PecocracyStoryRecord';


const contentTypeRecordClasses = {
    [contentTypes.ALBUMS_ALBUMPHOTO]: AlbumPhotoRecord,
    [contentTypes.ALBUMS_ALBUM]: AlbumRecord,
    [contentTypes.AVATAR_USERAVATAR]: UserAvatarRecord,
    [contentTypes.BADGE_ACHIEVEMENT]: BadgeRecord,
    [contentTypes.CELEBRATION_CONGRATULATIONFEBRUARY23]: CongratulationRecord,
    [contentTypes.CELEBRATION_CONGRATULATIONMARCH8]: CongratulationRecord,
    [contentTypes.EDU_TERMSTUDENT]: TermStudentRecord,
    [contentTypes.IDEAS_TRACKER_IDEA]: IdeaRecord,
    [contentTypes.LEARN_VIDEO]: VideoRecord,
    [contentTypes.THANKS_THANKS]: ThanksRecord,
    [contentTypes.TIMELINE_TIMELINEPOST]: MicropostRecord,
    [contentTypes.FOLLOWING_RECOMMENDATION]: FollowingRecommendation,
    [contentTypes.SKILLS_SKILLPROVED]: SkillRecord,
    [contentTypes.SKILLS_SKILLTOOWNER]: SkillRecord,
    [contentTypes.COMMENTS_COMMENT]: CommentRecord,
    [contentTypes.MENTIONS_USERMENTION]: BasicRecordWithDate,
    [contentTypes.USERS_TRANSITION]: BasicRecordWithDate,
    [contentTypes.SERVICE_SINCERE_ENTRY]: ServiceSincereRecord,
    [contentTypes.PECOCRACY_STORY]: PecocracyStoryRecord,
};

export const personalAggregatedRecordClasses = {
    [contentTypes.BADGE_ACHIEVEMENT]: PersonalAggregatedBadgeRecord,
    [contentTypes.THANKS_THANKS]: AggregatedThanksBlock,
    [contentTypes.SKILLS_SKILLPROVED]: AggregatedSkillsProves,
    [contentTypes.SKILLS_SKILLTOOWNER]: AggregatedSkillsProves,
};

export function getRecordClassByContentType(contentTypeName) {
    return contentTypeRecordClasses[contentTypeName] || Record;
}

const RECORDS_LIMIT = 6;
const recordSchema = new schema.Entity('records', {}, { idAttribute: 'record_id' });
const recordListSchema = new schema.Array(recordSchema);


const defineShouldMergeRecords = (records, {
    contentType,
    mergeAttribute,
    mergeLimit = RECORDS_LIMIT,
    secondaryAttribute = mergeAttribute,
}) => {
    const recordsByUser = {};
    const recordsWithCt = records.filter(item => item.content_type_name === contentType && item.can_aggregate);
    for (const record of recordsWithCt) {
        if (record[mergeAttribute].username in recordsByUser) {
            recordsByUser[record[mergeAttribute].username].push(record);
            if (
                recordsByUser[record[mergeAttribute].username].map(
                    recordObj => recordObj[mergeAttribute],
                ).length > mergeLimit - 1 ||
                [...new Set(
                    recordsByUser[record[mergeAttribute].username].map(
                        recordObj => recordObj[secondaryAttribute],
                    ),
                )].length > RECORDS_LIMIT - 1
            ) {
                // добавляем записи по RECORDS_LIMIT штук, если уже больше, меняем ключ на идентификатор последней
                // записи, что бы можно было формировать новую "пачку" по ключу username
                recordsByUser[record.record_id] = [...recordsByUser[record[mergeAttribute].username]];
                delete recordsByUser[record[mergeAttribute].username];
            }
        } else {
            recordsByUser[record[mergeAttribute].username] = [record];
        }
    }
    return Object.values(recordsByUser).map(
        recordsArray => recordsArray.map(record => record.record_id),
    ).filter(recordIds => recordIds.length > 1);
};


export const joinSameRecords = (records, forbidMerge = false) => {
    /**
     * mergeAttribute -- аттрибут (объект пользователя), по которому аггрегировать записи
     * mergeLimit -- лимит записей, которые добавляем в блок по основному аттрибуту.
     * secondaryAttribute -- второстепенный аттрибут, по которому останавливать добавление
     * записей в единичный блок
     *
     * Например, для навыков, основная аггрегация идет по владельцу навыка.
     * В один аггрегированный блок не может попасть больше 6 записей с разными skill_to_owned_id,
     * при этому, количество записей, аггрегированных по владельцу не может превышать 10
     * (да, тут подобие аггрегации по аггрегированным данным)
     */
    const contentTypesMap = [
        {
            contentType: contentTypes.BADGE_ACHIEVEMENT,
            mergeAttribute: 'userData',
        },
        {
            contentType: contentTypes.THANKS_THANKS,
            mergeAttribute: 'userData',
        },
        {
            contentType: contentTypes.SKILLS_SKILLPROVED,
            mergeAttribute: 'owner',
            mergeLimit: 10,
            secondaryAttribute: 'skill_to_owner_id',
        },
        {
            contentType: contentTypes.SKILLS_SKILLTOOWNER,
            mergeAttribute: 'owner',
            mergeLimit: 10,
            secondaryAttribute: 'skill_to_owner_id',
        },
    ];
    const mergedRecords = {};
    let removedRecordIds = [];
    let recordsForMerge = [];
    const normalizedRecords = normalize(records, recordListSchema);

    if (!forbidMerge) {
        // получаем массивы с id записей, которые надо "склеить"
        try {
            recordsForMerge = contentTypesMap.map(
                cTypeItem => defineShouldMergeRecords(records, cTypeItem),
            ).reduce(
                (a, b) => a.concat(b), [],
            );
        } catch (exc) {
            console.error(exc);

            Sentry.withScope((scope) => {
                scope.setExtra('exc', exc);
                scope.setExtra('records', records);
                Sentry.captureMessage('Cannot define whether to merge records');
            });
        }

        for (const recordIds of recordsForMerge) {
            // помещаем каждый набор записей в objectList последней (самой ранней) записи из набора
            const lastRecordId = recordIds[recordIds.length - 1];
            const resultRecord = { ...normalizedRecords.entities.records[lastRecordId] };
            resultRecord.objectList = [...recordIds.map(id => normalizedRecords.entities.records[id])];
            resultRecord.personalAgreggatedContentType = resultRecord.content_type_name;
            mergedRecords[lastRecordId] = resultRecord;
        }
        if (recordsForMerge.length) {
            // помечаем все записи в каждом наборе кроме последний - удалёнными
            // (выкидываем их из результирующего массива)
            removedRecordIds = recordsForMerge.reduce((a, b) => a.concat(b.slice(0, -1)), []);
        }
    }

    return normalizedRecords.result.filter(
        id => !removedRecordIds.includes(id),
    ).map(
        id => mergedRecords[id] || normalizedRecords.entities.records[id],
    );
};


export const getRandomInt = (min, max) => {
    const rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
};
