// https://stackoverflow.com/a/26215431

export function objCamelFromSnake(obj) {
    if (!obj) {
        return obj;
    }
    const keyToCamel = key => key.replace(/[a-zA-Z0-9]_[a-zA-Z0-9]/g, m => `${m[0]}${m[2].toUpperCase()}`);
    let newObj;
    let origKey;
    let newKey;
    let value;
    if (obj instanceof Array) {
        newObj = [];
        for (origKey = 0; origKey < obj.length; origKey++) {
            value = obj[origKey];
            if (typeof value === 'object') {
                value = objCamelFromSnake(value);
            }
            newObj.push(value);
        }
    } else {
        newObj = {};
        for (origKey in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
                newKey = keyToCamel(origKey);
                value = obj[origKey];
                if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
                    value = objCamelFromSnake(value);
                }
                newObj[newKey] = value;
            }
        }
    }
    return newObj;
}

export function objSnakeFromCamel(obj) {
    if (!obj) {
        return obj;
    }
    let newObj;
    if (obj instanceof Array) {
        newObj = obj.map((value) => {
            if (typeof value === 'object') {
                return objSnakeFromCamel(value);
            }
            return value;
        });
    } else {
        newObj = {};
        Object.keys(obj).forEach((key) => {
            const newKey = key.replace(/\.?([A-Z]+)/g, (match, group) => `_${group.toLowerCase()}`).replace(/^_/, '');
            let value = obj[key];
            if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
                value = objSnakeFromCamel(value);
            }
            newObj[newKey] = value;
        });
    }
    return newObj;
}
