import React, { useEffect, useState } from 'react';
import { Paper, A } from 'intdev-ui';
import PropTypes from 'prop-types';
import { OwnGroupSelect } from '@/common/components/OwnGroupSelect';
import './styles.css';
import { BaseMenu } from '@/base_page/components/BaseMenu';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { containerStyle, menuStyle } from '../../constants';
import { apiUrls } from '../../constants/apiUrls';

export const MainMenu = ({ onIsMenuShownChange, isMenuShown }) => {
    const [isMounted, setIsMounted] = useState(true);
    const [menu, setMenu] = useState([]);
    const [perms, setPerms] = useState([]);

    const changeIsMenuShown = (status) => {
        onIsMenuShownChange(status);
        localStorage.setItem('isMenuShown', status);
    };

    const filterMenuByPerms = ({
        menu,
        perms,
    }) => {
        const filteredHighOrderedMenuItems = menu.filter(menu => (!menu.perm || perms.includes(menu.perm))
            && menu.feature !== false);

        const filteredMenuByPerms = filteredHighOrderedMenuItems.map((menuItem) => {
            const filteredSubmenuItems = menuItem.submenu_items.filter(item => ((!item.perm || perms.includes(item.perm))
                && item.feature !== false));
            return {
                ...menuItem,
                submenu_items: filteredSubmenuItems,
            };
        });

        const filteredMenu = filteredMenuByPerms.filter(category => (!category.includes_submenu)
            || (category.includes_submenu && !!category.submenu_items.length));

        return filteredMenu;
    };

    const fetchMenuItems = () => {
        if (isMounted) {
            callApi(apiUrls.menuItemsUrl).then((data) => {
                const filteredMenu = filterMenuByPerms({ menu: data.menu, perms: data.perms });
                setMenu(filteredMenu);
                setPerms(data.perms);
                if (onIsMenuShownChange) {
                    if (isMenuShown && !filteredMenu.length) {
                        changeIsMenuShown(false);
                    } else if (!isMenuShown && filteredMenu.length) {
                        changeIsMenuShown(true);
                    }
                }
                window.init_counters();
            }).catch(() => {
                showErrorNotification('Произошла ошибка при загрузке меню');
                changeIsMenuShown(false);
            });
        }
    };

    useEffect(() => {
        fetchMenuItems();
        return () => setIsMounted(false);
    }, []);

    return (
        <>
            <Paper style={ containerStyle }>
                <div style={ menuStyle }>
                    <BaseMenu navigationMenu={ menu } />
                </div>
                <div>
                    <OwnGroupSelect />
                    {
                        perms?.includes('navigation.change_menuitem') ? (
                            <div className="menu-admin-ref">
                                <A href={ apiUrls.menuAdmin } className="menu-admin-ref-link">Изменить меню</A>
                            </div>
                        ) : null
                    }
                </div>
            </Paper>

        </>
    );
};

MainMenu.propTypes = {
    onIsMenuShownChange: PropTypes.func,
    isMenuShown: PropTypes.bool,
};

MainMenu.defaultProps = {
    onIsMenuShownChange: null,
    isMenuShown: true,
};
