import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'intdev-ui';

import { UserSuggestions } from '@/user_suggestions/userSuggestions';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { cleanData } from '@/common/helpers/cleanData';

import { groupsURL } from './apiUrls';
import { hasListChanged } from './helpers';
import { LabeledComponent } from '../LabeledComponent';


export class GenericGroupManager extends Component {
    /**
     @param {Number} objectId -- не обязательный параметр, если объект еще не создан
     @param {Number|String} contentType -- может быть либо id'шников ct, так и ключом (например, blog.entry)
     */
    static propTypes = {
        objectId: PropTypes.number,
        contentType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        role: PropTypes.number,
        // optionalProps
        placeholder: PropTypes.string,
        noResultsText: PropTypes.string,
        disabled: PropTypes.bool,
        defaultReason: PropTypes.string,
        hideReasonField: PropTypes.bool, // work only if defaultReason is passed
    };

    static defaultProps = {
        contentType: null,
        role: 1,
        objectId: null,
        placeholder: '',
        noResultsText: 'не найдено',
        disabled: false,
        defaultReason: '',
        hideReasonField: false,
    };

    state = {
        isLoading: false,
        users: [],
        initialUsers: [],
        reason: '',
        reasonError: null,
    };

    componentDidMount() {
        this.getGenericGroups();
    }

    get hasUsersChanged() {
        const { users, initialUsers } = this.state;
        return hasListChanged(users, initialUsers);
    }

    getGenericGroups = () => {
        const {
            contentType,
            objectId,
            role,
            defaultReason,
        } = this.props;
        // в случае если объект еще не создался, нет смысла подгружать права
        if (objectId) {
            this.setState({ isLoading: true }, () => {
                callApi(groupsURL, 'get', cleanData({
                    role,
                    content_type: contentType,
                    obj_id: objectId,
                }))
                    .then((response) => {
                        this.setState({
                            users: response.users,
                            initialUsers: response.users,
                            reason: defaultReason || '',
                            isLoading: false,
                        });
                    })
                    .catch((err) => {
                        if (err.status === 400) showErrorNotification('Не удалось получить информацию о группах');
                        else if (err.status === 404) showErrorNotification('Такого объекта не существует');
                        else showErrorNotification('Неизвестная ошибка');

                        this.setState({ isLoading: false });
                    });
            });
        } else this.setState({ reason: defaultReason || '' });
    };

    validateReason = () => {
        /**
         * этот метод вызывается из родительского компонента.
         */
        const { reason } = this.state;
        let isValid = true;
        if (!this.hasUsersChanged) {
            return isValid;
        }
        if (!reason || !reason.trim()) {
            this.setState({ reasonError: 'Укажи причину изменения' });
            isValid = false;
        }
        return isValid;
    };

    saveManager = (objectId) => {
        /**
         * @param {number} objectId
         * этот метод вызывается из родительского компонента.
         * в случае если мы редактируем права на объект, он уже создан, и мы знаем его id.
         * если мы создаем объект, и тут же выдаем на него права, мы еще не знаем его айдишник, поэтому
         * id нужно прокидывать с родительского компонента.
         */
        const { contentType, role } = this.props;
        const { users, reason } = this.state;

        if (!this.hasUsersChanged) return Promise.resolve();
        return new Promise((resolve, reject) => {
            callApi(groupsURL, 'post', cleanData({
                content_type: contentType,
                obj_id: objectId,
                users: users.map(u => u.value),
                reason,
                role,
            }))
                .then(resolve)
                .catch(() => {
                    reject({
                        managerKey: role,
                        objectId,
                    });
                });
        });
    };

    handleChangeUsers = users => this.setState({ users });

    handleReasonChange = (_, reason) => this.setState({ reason, reasonError: null });

    render = () => {
        const {
            placeholder,
            noResultsText,
            disabled,
            hideReasonField,
        } = this.props;

        const {
            isLoading,
            users,
            reason,
            reasonError,
        } = this.state;

        return (
            <div className="root-container">
                <UserSuggestions
                    selectValue={ users }
                    onChangeValue={ this.handleChangeUsers }
                    placeholder={ placeholder }
                    noResultsText={ noResultsText }
                    disabled={ disabled || isLoading }
                    selectMultiple
                    fullWidth
                    clearable={ false }
                />
                { this.hasUsersChanged && !(hideReasonField && reason) && (
                    <LabeledComponent label="Причина">
                        <TextField
                            name="reason"
                            value={ reason }
                            onChange={ this.handleReasonChange }
                            disabled={ disabled || isLoading }
                            hintText="Укажи причину изменения"
                            validationState={ reasonError && 'error' }
                            validationText={ reasonError }
                            fullWidth
                        />
                    </LabeledComponent>
                ) }
            </div>
        );
    }
}
