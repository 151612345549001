import { isMobile } from '@/common/mediaQueries';

export const styles = {
    dialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    questionIcon: {
        fontSize: '20px',
        marginLeft: '15px',
        cursor: 'pointer',
    },
    tooltip: {
        width: '310px',
        fontSize: '12px',
        color: 'grey',
    },
    dialogStyle: {
        zIndex: 1000,
    },
    contentStyle: {
        width: isMobile ? 'auto' : '850px',
    },
};
