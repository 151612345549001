import { getLocalStorageText } from '../../helpers/localStorage';
import { LOCAL_STORAGE_EXTERNAL_CALENDAR } from './constants';

export const getCalendars = data =>
    data.calendars &&
    data.calendars.sort((a, b) => a.sort - b.sort).map(calendar => ({
        value: calendar.uid,
        label: calendar.summary,
    }));


export const getLastOrFirstCalendar = (calendars) => {
    const lastCalendar = getLocalStorageText(LOCAL_STORAGE_EXTERNAL_CALENDAR);
    let calendar;
    if (calendars.length > 0) {
        calendar = calendars.find(item => item.value === lastCalendar) || calendars[0];
    }
    return calendar;
};
