import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Question from '../questions/Question';
import { initAnswers } from '../../actions/answers';
import * as constants from '../../../builder/constants.es6';


class OnePageQuestionGroup extends React.Component {
    static propTypes = {
        questionsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        initAnswers: PropTypes.func.isRequired,
        compactMode: PropTypes.bool,
    };

    static defaultProps = {
        compactMode: false,
    };

    componentDidMount() {
        this.props.initAnswers(this.props.questionsList, constants.QUESTION_GROUP_TYPE_ON_ONE_PAGE);
    }

    render() {
        const questions = this.props.questionsList.map(question => (
            <Question key={ question.id } question={ question } compactMode={ this.props.compactMode } />
        ));
        return (
            <div>
                { questions }
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }) => {
    const currentGroup = state.groups[state.controls.currentGroupId];
    let curQuestionId = currentGroup.first_question;
    let curQuestion;
    const questionsListIds = [];
    while (curQuestionId) {
        questionsListIds.push(curQuestionId);
        curQuestion = state.questions[curQuestionId];
        curQuestionId = curQuestion.next_question;
    }
    return {
        questionsList: questionsListIds.map(questionId => state.questions[questionId]),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    initAnswers,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OnePageQuestionGroup);
