/* eslint-disable react/no-danger */
import React from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'intdev-ui';
import PropTypes from 'prop-types';

export class QuestionStatistic extends React.Component {
    static propTypes = {
        question: PropTypes.shape().isRequired,
    };

    render() {
        const question = this.props.question;
        const choices = question.choices.map((choice) => {
            const progressBarValue = choice.percent.toFixed(2);
            return (
                <TableRow hoverable key={ choice.id }>
                    <TableRowColumn className="quiz-detail__question-stat-numbers-cell">
                        <p><b>{`${progressBarValue}%`}</b><br /><span className="quiz-detail__text_color_grey">({ choice.count })</span></p>
                    </TableRowColumn>
                    <TableRowColumn className="quiz-detail__question-stat-progress-cell">
                        <div className="quiz-detail__question-stat-progress">
                            <div className="quiz-detail__question-stat-progress-bar" style={ { width: `${progressBarValue}%` } } />
                        </div>
                    </TableRowColumn>
                    <TableRowColumn className="quiz-detail__question-stat-choice-cell">
                        <div dangerouslySetInnerHTML={ { __html: choice.text } } />
                        { choice.picture && <img src={ choice.picture } className="quiz-detail__choice-picture" /> }
                    </TableRowColumn>
                </TableRow>
            );
        });
        return (
            <div className="quiz-detail__question-stat-container" hidden={ question.hidden }>
                <div className="quiz-detail__text_size_m" dangerouslySetInnerHTML={ { __html: question.text } } />
                <Table className="quiz-detail__question-stat-table">
                    <TableBody>
                        { choices }
                    </TableBody>
                </Table>
                <div className="quiz-detail__question-stat-counter quiz-detail__text_size_s">
                    <b>Всего ответов на этот вопрос:</b> { question.count_all }
                </div>
            </div>
        );
    }
}
