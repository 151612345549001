import React from 'react';

export const DelayRender = ({ children, delay }) => {
    const [showChildren, setShowChildren] = React.useState(!delay);

    React.useEffect(() => {
        if (!delay) {
            return () => {};
        }
        const timerId = setTimeout(() => setShowChildren(true), delay);
        return () => clearTimeout(timerId);
    });

    return showChildren && children;
};
