/**
 * Determines whether an error is a QuotaExceededError.
 *
 * Browsers love throwing slightly different variations of QuotaExceededError
 * (this is especially true for old browsers/versions), so we need to check
 * different fields and values to ensure we cover every edge-case.
 */
function isQuotaExceededError(err) {
    return (
        err instanceof DOMException
        && (
            err.name === 'QuotaExceededError'
            // Firefox
            || err.name === 'NS_ERROR_DOM_QUOTA_REACHED'
            // everything except Firefox
            || err.code === 22
            // Firefox
            || err.code === 1014
            // test name field too, because code might not be present
            // everything except Firefox)
        )
    );
}


export const updatePersistedState = (key, obj, onQuotaExceededErrorCallback) => {
    try {
        const prevState = JSON.parse(sessionStorage.getItem(key)) || {};
        const newState = JSON.stringify({ ...prevState, ...obj });
        sessionStorage.setItem(key, newState);
    } catch (error) {
        if (onQuotaExceededErrorCallback && isQuotaExceededError(error)) {
            onQuotaExceededErrorCallback();
        } else {
            // eslint-disable-next-line no-console
            console.log('Ошибка при работе с SessionStorage');
        }
    }
};

export const getPersistedState = (key) => {
    let state;
    try {
        const serializedState = sessionStorage.getItem(key);
        state = JSON.parse(serializedState);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Ошибка при чтении из SessionStorage');
    }
    return state || {};
};


export const getDocumentHeight = () => (
    Math.max(
        window.document.body.scrollHeight,
        window.document.body.offsetHeight,
        window.document.body.clientHeight,
        window.document.documentElement.scrollHeight,
        window.document.documentElement.offsetHeight,
        window.document.documentElement.clientHeight,
    )
);

export const restoreDocumentHeight = (documentHeight) => {
    if (documentHeight) {
        const wrapper = window.document.querySelector('#wrapper');
        wrapper.style.minHeight = `${documentHeight}px`;
    }
};

export const cleanupAfterRestoreDocumentHeight = () => {
    const wrapper = window.document.querySelector('#wrapper');
    wrapper.style.removeProperty('min-height');
};
