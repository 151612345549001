import { determineScreenSize } from '@/common/mediaQueries';

export const media = determineScreenSize({
    desktop: {
        popoverStyle: {
            fontSize: 17,
        },
    },
    small: {
        popoverStyle: {
            fontSize: 14,
        },
        attachmentText: {
            fontSize: 'var(--font-size-body)',
        },
    },
    medium: {
        popoverStyle: {
            fontSize: 14,
        },
        attachmentText: {
            fontSize: 'var(--font-size-body)',
        },
    },
    large: {
        popoverStyle: {
            fontSize: 15,
        },
        attachmentText: {
            fontSize: 15,
        },
    },
});
