import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
};
ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
};
