import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    loadPermissions,
    loadSelfProfile,
    loadConstants,
    loadFeatures,
} from '@/common/components/DefaultParams/actions';
import { showErrorNotification } from '@/common/helpers/showNotification';

class DefaultParamsComponent extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        loadPermissions: PropTypes.func.isRequired,
        permissions: PropTypes.arrayOf(PropTypes.string),
        loadedPermissions: PropTypes.shape({ key: PropTypes.bool }),
        features: PropTypes.arrayOf(PropTypes.string),
        loadedFeatures: PropTypes.shape({ key: PropTypes.bool }),
        needConstants: PropTypes.shape({
            settings: PropTypes.arrayOf(PropTypes.string),
            db_options: PropTypes.arrayOf(PropTypes.string),
        }),
        loadedConstants: PropTypes.shape({
            settings: PropTypes.shape(),
            db_options: PropTypes.shape(),
        }),
        needSelfProfile: PropTypes.bool,
        loadedSelfProfile: PropTypes.shape(),
        loadSelfProfile: PropTypes.func.isRequired,
        loadConstants: PropTypes.func.isRequired,
        loadFeatures: PropTypes.func.isRequired,
    };

    static defaultProps = {
        permissions: [],
        loadedPermissions: {},
        needConstants: {},
        loadedConstants: {},
        children: null,
        needSelfProfile: false,
        loadedSelfProfile: {},
        features: [],
        loadedFeatures: {},
    };

    componentDidMount() {
        this.loadPermissions();
        this.loadSelfProfile();
        this.loadConstants();
        this.loadFeatures();
    }

    getKeysToLoad = (needKeys = [], loadedState = {}) => {
        const loadedKeys = Object.keys(loadedState);
        return needKeys.filter(val => loadedKeys.indexOf(val) === -1);
    };

    loadConstants = () => {
        const { needConstants, loadedConstants } = this.props;
        if (Object.keys(needConstants).length) {
            const settingsToLoad = this.getKeysToLoad(needConstants.settings, loadedConstants.settings);
            const dbOptionsToLoad = this.getKeysToLoad(needConstants.db_options, loadedConstants.db_options);
            if (settingsToLoad.length || dbOptionsToLoad.length) {
                this.props.loadConstants({
                    settings: settingsToLoad,
                    db_options: dbOptionsToLoad,
                });
            }
        }
    };

    loadSelfProfile = () => {
        const { needSelfProfile, loadedSelfProfile } = this.props;
        if (needSelfProfile && !loadedSelfProfile.username) {
            this.props.loadSelfProfile();
        }
    };

    loadFeatures = () => {
        const { features, loadedFeatures } = this.props;
        if (features.length) {
            const featuresToLoad = this.getKeysToLoad(features, loadedFeatures);
            if (featuresToLoad.length) {
                this.props.loadFeatures(featuresToLoad);
            }
        }
    };

    loadPermissions = () => {
        const { permissions, loadedPermissions } = this.props;
        if (permissions.length) {
            const permsToLoad = this.getKeysToLoad(permissions, loadedPermissions);
            if (permsToLoad.length) {
                this.props.loadPermissions(permsToLoad).then((response) => {
                    if (response.fail) {
                        showErrorNotification(
                            'Не удалось загрузить права пользователя, попробуйте перезагрузить страницу',
                        );
                    }
                });
            }
        }
    };

    render() {
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    loadedPermissions: state.userPermissions?.permissions,
    loadedFeatures: state.features,
    loadedConstants: state.projectConstants,
    loadedSelfProfile: state.selfProfile,
});

const mapDispatchToProps = {
    loadPermissions,
    loadSelfProfile,
    loadConstants,
    loadFeatures,
};

export const DefaultParams = connect(mapStateToProps, mapDispatchToProps)(DefaultParamsComponent);
