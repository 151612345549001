import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { callApi } from '@/common/middlewares/apiMiddleware';
import CommentsForm from '../CommentsForm';
import Comment from '../comments/Comment';

export const OfficialResponse = (props) => {
    const {
        objectId,
        userId,
        officialResponseForm,
        officialResponse,
        changeOfficialResponseForm,
        changeOfficialResponse,
        actions,
        officialResponseLikesBlockUuid,
        onOfficialResponseButtonClick,
    } = props;

    const { loadOfficialResponseURL, ...restProps } = props;

    React.useEffect(() => {
        callApi(loadOfficialResponseURL)
            .then((response) => {
                if (!response.status && response.official_response) {
                    const data = response.official_response;
                    data.count_likes = response.official_response.like_count;
                    changeOfficialResponse(data);
                }
            });
    }, [objectId]);

    const handleClickButton = () => {
        changeOfficialResponseForm(true);
    };

    const makeOfficialResponseButton = (
        responseText,
        officialResponseAction,
        userId,
        hasResponse,
    ) => {
        let responseBlock = '';
        if (officialResponseAction && (
            officialResponseAction.response_ids.indexOf(userId) !== -1
            || officialResponseAction.can_make_official_response
        )) {
            responseBlock = (
                <button
                    type="button"
                    onClick={ onOfficialResponseButtonClick || handleClickButton }
                    className={ classnames({
                        'official-response-button': !hasResponse,
                        'b-button p-color-blue': true,
                        'response-button': true,
                    }) }
                >
                    { responseText }
                </button>
            );
        }
        return responseBlock;
    };

    const officialResponseAction = actions.official_response;
    const officialResponseProps = {
        ...restProps,
        data: {
            ...officialResponse,
            children: undefined, // убирает дочерние комментарии из официального ответа
        },
        isOfficialComment: true,
    };

    return (
        <div>
            {
                Object.keys(officialResponse).length
                    ? (
                        <div className="official-comment">
                            <div className="official-comment__top-container">
                                <h3>Официальный ответ</h3>
                                {
                                    makeOfficialResponseButton('Изменить официальный ответ', officialResponseAction, userId, true)
                                }
                            </div>
                            <Comment { ...officialResponseProps } likesBlockUuid={ officialResponseLikesBlockUuid } />
                        </div>
                    )
                    : makeOfficialResponseButton('Официальный ответ', officialResponseAction, userId, false)
            }
            { officialResponseForm ? <CommentsForm { ...restProps } /> : null }
        </div>
    );
};

OfficialResponse.propTypes = {
    objectId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    officialResponseForm: PropTypes.bool.isRequired,
    officialResponse: PropTypes.shape().isRequired,
    changeOfficialResponseForm: PropTypes.func.isRequired,
    changeOfficialResponse: PropTypes.func.isRequired,
    actions: PropTypes.shape().isRequired,
    loadOfficialResponseURL: PropTypes.string.isRequired,
    officialResponseLikesBlockUuid: PropTypes.string,
    onOfficialResponseButtonClick: PropTypes.func,
};

OfficialResponse.defaultProps = {
    officialResponseLikesBlockUuid: null,
    onOfficialResponseButtonClick: null,
};
