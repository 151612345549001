import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentTypeSetting from './ContentTypeSetting';
import { callApi } from '../../../common/middlewares/apiMiddleware';

const contentTypeFilters = {
    timeline_blogs(record, contentTypes, data) {
        if (!contentTypes.has(record.content_type_id)) {
            return true;
        }
        if (!record.blog_ids) {
            return true; // :-(
        }
        const ids = record.blog_ids.filter(x => data.subscriptions.includes(x));
        return ids.length > 0;
    },
    timeline_posts(record, contentTypes, data) {
        if (!contentTypes.has(record.content_type_id)) {
            return true;
        }
        return !!((data.user === record.userData.id) || data.follows.includes(record.userData.id));
    },
};

export default class ContentTypeSettingsBox extends Component {
    contentTypes = new Set();

    contentTypeUrls = {
        timeline_blogs: `/users/${this.props.authUserUsername}/blogs/json/`,
        timeline_posts: `/following/user/${this.props.authUserUsername}/follows/json/?ids_only=1`,
    };

    static propTypes = {
        authUserUsername: PropTypes.string.isRequired,
        settingsUrl: PropTypes.string.isRequired,
        contentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        onRequestClose: PropTypes.func,
        filterData: PropTypes.func,
        reloadData: PropTypes.func,
        scrollToRecord: PropTypes.func,
        objectId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        onRequestClose: () => {},
        filterData: () => {},
        reloadData: () => {},
        scrollToRecord: () => {},
    };

    state = {
        settings: {
            data: {},
            order: [],
        },
    };

    componentDidMount = () => {
        const url = `${this.props.settingsUrl}?content_type=${this.props.contentType}`;
        callApi(url).then(data => this.setState({ settings: data }));
    };

    hideContent = (event, widget, state) => {
        event.preventDefault();
        const that = this;
        const payload = {
            option: widget.data.name,
            flag: widget.flag,
            value: state,
        };

        let reloadTimeline = false;

        if ((state === false) || (state === 'none')) {
            this.props.onRequestClose();
            // close all by ct
            this.props.filterData(record => !this.contentTypes.has(record.content_type_id));
        } else if (state === 'subscriptions') {
            const subscriptionsDataURL = this.contentTypeUrls[widget.data.name];
            const subscriptionsFilter = contentTypeFilters[widget.data.name];
            if (!(subscriptionsDataURL && subscriptionsFilter)) {
                this.props.onRequestClose();
                reloadTimeline = true;
            } else {
                callApi(subscriptionsDataURL).then((data) => {
                    this.props.onRequestClose();
                    this.props.filterData(record => subscriptionsFilter(record, that.contentTypes, data));
                    this.props.scrollToRecord(this.props.objectId);
                }).catch(
                    () => this.props.onRequestClose(),
                );
            }
        } else { // all
            this.props.onRequestClose();
            reloadTimeline = true;
        }

        const url = `${this.props.settingsUrl}?content_type=${this.props.contentType}`;
        callApi(url, 'POST', payload).then(() => {
            if (reloadTimeline) {
                this.props.reloadData(this.props.objectId);
            }
            return null;
        });
    };

    render() {
        const settingsArray = [];
        this.contentTypes = [];
        this.state.settings.order.map(
            (key) => {
                const option = this.state.settings.data[key];
                this.contentTypes.push(...option.content_types);
                settingsArray.push(
                    <ContentTypeSetting
                        key={ key }
                        data={ option }
                        hideContent={ this.hideContent }
                    />,
                );
                return null;
            },
        );
        this.contentTypes = new Set(this.contentTypes);
        return (
            <div>
                { settingsArray }
            </div>
        );
    }
}
