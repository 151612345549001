import React, { Component } from 'react';

export class Timer extends Component {
    constructor(props) {
        super(props);

        this.initTime = Date.now();

        this.state = {
            minutes: 0,
            seconds: 0,
        };
    }

    componentDidMount() {
        this.start();
    }

    componentWillUnmount() {
        this.clear();
    }

    start = () => {
        this.timer = setInterval(() => this.setTime(), 1000);
    };

    getTimeComponents = () => {
        const time = (Date.now() - this.initTime) / 1000;
        return {
            minutes: Math.floor(Math.round(time) / 60),
            seconds: Math.round(time) % 60,
        };
    };

    setTime = () => {
        const { minutes, seconds } = this.getTimeComponents();
        this.setState({ minutes, seconds });
    };

    clear = () => {
        clearTimeout(this.timer);
    };

    render() {
        const { minutes, seconds } = this.state;

        return (
            <div>
                { minutes }:{ seconds < 10 ? `0${seconds}` : seconds }
            </div>
        );
    }
}
