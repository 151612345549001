import React from 'react';
import PropTypes from 'prop-types';
import { AttachmentGallery } from '../AttachmentGallery';
import classes from './TimelineRecordBody.scss';

export const TimelineRecordBody = ({ text, sticker, attachments }) => (
    <div className={ classes.content }>
        <div
            className={ classes.contentText }
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={ { __html: text } }
        />
        {
            !!sticker && (
                <div key={ `attachment-sticker+${sticker.id.toString()}` } className={ classes.sticker }>
                    <img
                        src={ sticker.url }
                        className={ classes.stickerImage }
                        alt="Стикер"
                    />
                </div>
            )
        }
        <div className={ classes.contentAttachments }>
            <AttachmentGallery attachments={ attachments } />
        </div>
    </div>
);

TimelineRecordBody.propTypes = {
    text: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired,
    sticker: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
};

TimelineRecordBody.defaultProps = {
    sticker: null,
};
