import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PeopleTooltip } from '../../users/components/CounterTooltip/PeopleTooltip';

import love from '../../../img/emoji/love.svg';
import haha from '../../../img/emoji/haha.svg';
import wow from '../../../img/emoji/wow.svg';
import sad from '../../../img/emoji/sad.svg';
import angry from '../../../img/emoji/angry.svg';
import facepalm from '../../../img/emoji/facepalm.svg';
import fire from '../../../img/emoji/fire.svg';
import thumbsUp from '../../../img/emoji/thumbsUp.svg';

const HUMAN_EMOJI = {
    1: love,
    2: haha,
    3: wow,
    4: sad,
    5: angry,
    6: facepalm,
    7: fire,
    8: thumbsUp,
};

const getEmptyLike = color => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
        <g fill={ color }>
            <path d="m61.1 18.2c-6.4-17-27.2-9.4-29.1-.9-2.6-9-22.9-15.7-29.1.9-6.9 18.5 26.7 35.1 29.1 37.8 2.4-2.2 36-19.6 29.1-37.8m-29.1 32.6c-2-2.2-30-15.2-24.2-29.8 5.1-13.1 22-7.8 24.2-.7 1.6-6.6 18.9-12.6 24.2.7 5.8 14.3-22.2 28.1-24.2 29.8" />
        </g>
    </svg>
);

const getLike = color => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
        <g fill={ color }>
            <path d="m61.1 18.2c-6.4-17-27.2-9.4-29.1-.9-2.6-9-22.9-15.7-29.1.9-6.9 18.5 26.7 35.1 29.1 37.8 2.4-2.2 36-19.6 29.1-37.8" />
        </g>
    </svg>
);

export default class LikesCounterItem extends Component {
    static propTypes = {
        onLikeClick: PropTypes.func.isRequired,
        isLiked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
        color: PropTypes.string.isRequired,
        count: PropTypes.shape().isRequired,
        tooltipItemInfo: PropTypes.shape(),
        tooltipPosition: PropTypes.string,
        emoji: PropTypes.string.isRequired,
        collapsed: PropTypes.bool,
    };

    static defaultProps = {
        tooltipItemInfo: {
            likes: [],
            num_likes_to_show: [],
        },
        isLiked: undefined,
        tooltipPosition: 'bottom_right',
        collapsed: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
            tooltipPageX: null,
        };
    }

    getAdaptiveTooltipPosition = (position, likesCount, x, y) => {
        if (!likesCount || !x) {
            return position;
        }
        const separator = '_';
        const positionComponents = position.split(separator);
        // 70px is roughly width of people avatar in Tooltip
        const xLimit = 70 * likesCount;
        if (positionComponents[1] === 'right' && x < xLimit) {
            positionComponents[1] = 'left';
        }
        const tooltipHeight = 110;
        if (tooltipHeight + y > window.innerHeight) {
            positionComponents[0] = 'top';
        }
        return positionComponents.join(separator);
    };

    handleShowTooltip = (e) => {
        this.setState({ tooltipPageX: e.clientX, tooltipPageY: e.clientY });
        this.setState({ isTooltipActive: true });
    };

    handleHideTooltip = () => {
        this.setState({ isTooltipActive: false });
    };

    handleLikeClick = (emoji) => {
        this.props.onLikeClick(emoji);
        this.handleHideTooltip();
    };

    renderEmoji = (isLiked, emoji, color) => {
        const className = 'like-emoji-icon';
        if (emoji === 0) {
            if (isLiked === false || isLiked !== 0) {
                return (
                    <div className={ className }>
                        { getEmptyLike(color) }
                    </div>
                );
            }
            return (
                <div className={ className }>
                    { getLike(color) }
                </div>
            );
        }
        return (<img className={ className } src={ HUMAN_EMOJI[emoji] } />);
    };

    render() {
        const { isTooltipActive, tooltipPageX, tooltipPageY } = this.state;
        const {
            isLiked,
            color,
            count,
            tooltipItemInfo,
            tooltipPosition,
            collapsed,
        } = this.props;
        const position = this.getAdaptiveTooltipPosition(
            tooltipPosition, tooltipItemInfo.likes.length, tooltipPageX, tooltipPageY,
        );
        const emoji = parseInt(this.props.emoji, 10);
        const thisEmojiCount = count[emoji];
        if (!thisEmojiCount) {
            return null;
        }
        return (
            <div
                className="likes-counter-item"
                style={ collapsed ? { marginRight: '-10px' } : { marginRight: '4px' } }
                onMouseEnter={ this.handleShowTooltip }
                onMouseLeave={ this.handleHideTooltip }
            >
                <div
                    onClick={ () => this.handleLikeClick(emoji) }
                    className="like-counter-icon-number"
                >
                    { this.renderEmoji(isLiked, emoji, color) }
                    { !collapsed && <div className="like-counter-number">{ thisEmojiCount }</div> }
                </div>
                <PeopleTooltip
                    isTooltipActive={ isTooltipActive }
                    tooltipItemInfo={ tooltipItemInfo }
                    position={ position }
                />
            </div>
        );
    }
}
