import React from 'react';
import { SvgIcon } from 'intdev-ui/lib/SvgIcon/SvgIcon';

/* eslint-disable max-len */
const AttachImageButton = props => (<SvgIcon { ...props }>
    <path d="M12 0a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h10zm-1.274 12c.514 0 .932-.373.932-.833a.77.77 0 0 0-.133-.43l-.014-.02-1.883-3.294c-.16-.253-.386-.52-.811-.52-.437 0-.659.272-.817.53L6.574 9.399l-.862-.91C5.504 8.269 5.343 8 4.929 8c-.414 0-.614.297-.816.538l-1.631 2.19a.762.762 0 0 0-.14.439c0 .46.418.833.932.833h7.452zM4.5 2a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 4.5 2z" />
</SvgIcon>);
/* eslint-enable max-len */

AttachImageButton.defaultProps = {
    viewBox: '0 0 14 14',
    fillRule: 'evenodd',
};

export default AttachImageButton;
