import React from 'react';
import { Spinner } from 'intdev-ui';
import PropTypes from 'prop-types';

const styles = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export class SpinnerCentered extends React.Component {
    static propTypes = {
        size: PropTypes.number,
        padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        wrapperStyle: PropTypes.shape({}),
    };

    static defaultProps = {
        size: 32,
        padding: 8,
        wrapperStyle: {},
    };

    render() {
        const {
            size, padding, wrapperStyle, ...rest
        } = this.props;
        const wrapperStyleMerged = { ...styles.wrapper, padding, ...wrapperStyle };
        return (
            <div style={ wrapperStyleMerged }>
                <Spinner
                    size={ size }
                    { ...rest }
                />
            </div>
        );
    }
}
