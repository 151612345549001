import PropTypes from 'prop-types';
import { SUGGESTION_SELECT_VALUE_SHAPE } from '../../user_suggestions/constants';

export const EVENT_SHAPE = PropTypes.shape({
    id: PropTypes.number,
    meetingId: PropTypes.number,
    canPlus: PropTypes.number,
    capacity: PropTypes.number,
    copy: PropTypes.bool,
    edit: PropTypes.bool,
    end: PropTypes.number,
    hasPassed: PropTypes.bool,
    name: PropTypes.string,
    ownerId: PropTypes.number,
    people: PropTypes.number,
    series: PropTypes.number,
    remind: PropTypes.bool,
    start: PropTypes.number,
    subscribed: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    updateTime: PropTypes.number,
    usersJoinAllowed: PropTypes.arrayOf(PropTypes.number),
});

export const EVENT_GROUP_SHAPE = PropTypes.shape({
    label: PropTypes.string,
    events: PropTypes.arrayOf(EVENT_SHAPE),
});

export const CALENDAR_CONFIG_SHAPE_ITEMS = {
    isAnyMeeting: PropTypes.bool,
    objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contentType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    user: SUGGESTION_SELECT_VALUE_SHAPE,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    canTruncate: PropTypes.bool,
    timeInterval: PropTypes.array,
    duration: PropTypes.array,
    centrifugeChannels: PropTypes.string,
    canChangeOwner: PropTypes.bool,
    canEdit: PropTypes.bool,
    canCreate: PropTypes.bool,
    singleJoin: PropTypes.bool,
    participantsSet: PropTypes.instanceOf(Set), // used for single_join violation check
};
