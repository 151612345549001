import { combineReducers } from 'redux';
import { apiUrls } from '@/blog/constants/apiUrls';
import { sideblock } from '@/blog/reducers/sideblock';
import publicationAttachments from '@/blog/reducers/publicationAttachments';
import { albumPhoto } from '@/blog/reducers/photos';
import { blogSubscribe } from '@/blog/reducers/blogSubscribe';
import { albums } from '@/blog/reducers/albums';
import { routerReducer } from '../../spa/history';
import { userPermissions } from '../../common/components/DefaultParams/reducers/userPermissions';
import { selfProfile } from '../../common/components/DefaultParams/reducers/selfProfile';


export const attachmentReducersMap = {
    publicationAttachments,
    apiUrls: (state = { ...apiUrls }) => state,
    albumPhoto,
    albums,
    userPermissions,
    selfProfile,
    router: routerReducer(),
};

export const attachmentReducers = combineReducers(attachmentReducersMap);
export const sideblockReducers = combineReducers({sideblock});
export const blogSubscribeReducers = combineReducers(blogSubscribe);
