import React from 'react';
import { SvgIcon } from 'intdev-ui';

export const Persons = props => (
    <SvgIcon { ...props }>
        <path id="a" d="M15 15H5a1 1 0 0 1-1-1v-.3c0-2.48 2.01-4.494 4.488-4.5v-.796A3 3 0 0 1 7 5.813V3.544a3.001 3.001 0 0 1 6 0v2.269a3 3 0 0 1-1.488 2.591V9.2A4.502 4.502 0 0 1 16 13.7v.3a1 1 0 0 1-1 1zM2.541 13H.753A.754.754 0 0 1 0 12.247a3.392 3.392 0 0 1 3.206-3.385v-.574a2.142 2.142 0 0 1-1.063-1.85V5.143A2.144 2.144 0 0 1 5.504 3.38l-.004.154c0 .785-.006 1.571.001 2.356.006.253.029.505.076.755.073.389.199.763.368 1.12l.009.017a2.163 2.163 0 0 1-.588.506v.296a6.035 6.035 0 0 0-2.639 3.477 6 6 0 0 0-.186.939z"/>
    </SvgIcon>
);

Persons.defaultProps = { viewBox: '0 0 16 16', color: 'currentColor' };
