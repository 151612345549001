import update from 'immutability-helper';
import { ACTION_TYPES } from '@/blog/constants/actionTypes';


const defaultState = {
    isLoaded: false,
    subscribedBlogs: [],
    activeBlogs: [],
    commentedEntries: [],
    recommendedEntries: [],
};

export const sideblock = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SIDEBLOCK_INFO_LOADING_START:
            return defaultState;

        case ACTION_TYPES.SIDEBLOCK_INFO_LOADING_FINISH: {
            const data = action.payload;
            return update(state, {
                $merge: {
                    isLoaded: true,
                    subscribedBlogs: data.subscribedBlogs,
                    activeBlogs: data.activeBlogs,
                    commentedEntries: data.commentedEntries,
                    recommendedEntries: data.recommendedEntries,
                },
            });
        }

        case ACTION_TYPES.SIDEBLOCK_INFO_LOADING_FAIL:
            return update(state, {
                isLoaded: { $set: true },
            });

        default:
            return state;
    }
};
