import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from 'intdev-ui';
import { RemoveButton } from '../RemoveButton';
import classes from './AttachmentGalleryItem.scss';

export const AttachmentGalleryItem = ({
    previewText,
    imageUrl,
    single,
    first,
    onClick,
    onDelete,
}) => {
    if (previewText) {
        return (
            <div
                className={ single ? classes.singleItem : classnames(classes.gridItem, first && classes.gridItemFirst) }
                style={ { backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))' } }
            >
                <div className={ classes.preview }>
                    <div className={ classes.previewSpinner }>
                        <Spinner size={ 42 } color="white" />
                    </div>
                    <div className={ classes.previewText }>
                        { previewText }
                    </div>
                </div>
            </div>
        );
    }

    if (single) {
        return (
            <div className={ classes.singleItem }>
                <img src={ imageUrl } onClick={ onClick } />
                { onDelete && (
                    <div className={ classes.deleteButton }>
                        <RemoveButton onClick={ onDelete } />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            className={ classnames(classes.gridItem, first && classes.gridItemFirst) }
            style={ { backgroundImage: `url("${imageUrl}")` } }
            onClick={ onClick }
        >
            { onDelete && (
                <div className={ classes.deleteButton }>
                    <RemoveButton onClick={ onDelete } />
                </div>
            )}
        </div>
    );
};

AttachmentGalleryItem.propTypes = {
    imageUrl: PropTypes.string,
    previewText: PropTypes.string,
    single: PropTypes.bool,
    first: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
};

AttachmentGalleryItem.defaultProps = {
    imageUrl: '',
    previewText: null,
    single: false,
    first: false,
    onClick: null,
    onDelete: null,
};
