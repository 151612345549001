import { determineScreenSize, isMobile } from '@/common/mediaQueries';

export const commonMedia = determineScreenSize({
    desktop: {
        subscribe: {
            fontSize: 13,
            size: 25,
        },
        buttonStyles: { buttonStyle: {}, overlayStyle: {}, labelStyle: {} },
    },
    common: {
        textFieldInputWrapperStyle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    small: {
        textFieldHintStyle: {
            fontSize: 12,
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 24px)',
        },
        fontSizeBody: {
            fontSize: 'var(--font-size-body)',
        },
        fontSizeHeader: {
            fontSize: 'var(--font-size-header)',
        },
        buttonStyles: {
            buttonStyle: { height: 26, lineHeight: '24px' },
            labelStyle: { fontSize: 12 },
            overlayStyle: { height: 26 },
        },
        subscribe: {
            fontSize: 'var(--font-size-body)',
            size: 20,
        },
        listItem: {
            primaryText: {
                fontSize: 'var(--font-size-body)',
            },
            itemWrapper: {
                padding: '0 8px',
            },
        },
    },
    medium: {
        textFieldHintStyle: {
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 24px)',
        },
        fontSizeBody: {
            fontSize: 'var(--font-size-body)',
        },
        fontSizeHeader: {
            fontSize: 'var(--font-size-header)',
        },
        buttonStyles: {
            buttonStyle: { height: 30, lineHeight: '28px' },
            overlayStyle: { height: 30 },
            labelStyle: {},
        },
        subscribe: {
            fontSize: 'var(--font-size-body)',
            size: 20,
        },
        listItem: {
            primaryText: {
                fontSize: 'var(--font-size-body)',
            },
            itemWrapper: {
                padding: '0 8px',
            },
        },
    },
    large: {
        fontSizeBody: {
            fontSize: 15,
        },
        fontSizeHeader: {
            fontSize: 'var(--font-size-big)',
        },
        subscribe: {
            fontSize: 15,
            size: 20,
        },
        listItem: {
            primaryText: {
                fontSize: 15,
            },
            itemWrapper: {
                padding: '0 8px',
            },
        },
        buttonStyles: { buttonStyle: {}, overlayStyle: {}, labelStyle: {} },
    },
});

export const tabStyle = {
    padding: 'var(--tabs-padding)',
    fontSize: 'var(--tabs-font-size)',
    whiteSpace: 'var(--tabs-white-space)',
};

export const containerStyle = {
    overflowX: isMobile ? 'scroll' : 'hidden',
    overflowY: 'hidden',
    width: 'var(--tabs-container-width)',
};

/*
    1. DropdownTreeMultiselect
        style={ { label: commonMedia.smallFontSize } }
        inputStyle={ commonMedia.smallFontSize }
        inputWrapperStyle={ commonMedia.inputWrapperStyle }

    2. Select
        menuStyle={ commonMedia.fontSizeBody }
        valueStyle={ commonMedia.fontSizeBody }
        hintStyle={ commonMedia.fontSizeBody }

        * SELECT WITH CHIP
            labelStyle={ commonMedia.fontSizeBody }

        * SELECT WITH UNCONTROLLABLE TEXTFIELD
            inputProps={ { style: { ...commonMedia.fontSizeBody } } }

        * if the select options are too large and go out of the screen
            popoverProps={ { className: 'название класса' } }
            и в стилях задать этому классу width и overflow: 'scroll', пример в QuizForm

    3. UserSuggestions
        menuStyle={ commonMedia.fontSizeBody }
        valueStyle={ commonMedia.fontSizeBody }
        hintStyle={ commonMedia.fontSizeBody }

        * IN SOME CASES FOR SMALL FONT SIZE FOR INPUT
            inputProps={ { style: { ...commonMedia.fontSizeBody } } }

    4. TextField
        hintStyle={ commonMedia.textFieldHintStyle }
        inputStyle={ commonMedia.fontSizeBody }

        * in some cases makes the entered text centered
            inputWrapperStyle={ commonMedia.textFieldInputWrapperStyle }

     5. Button
        { ...commonMedia.buttonStyles }

     6. Toggle
        size={ commonMedia.subscribe.size }
        labelStyle={ { fontSize: commonMedia.subscribe.fontSize } }

     7. LabelWithHelp
        popoverStyle={ commonMedia.fontSizeBody }

     8. SuggestField
        menuStyle={ commonMedia.fontSizeBody }
        valueStyle={ commonMedia.fontSizeBody }
        inputProps={ { style: { ...commonMedia.fontSizeBody } } }

     9. DatePicker
        hintStyle={ commonMedia.textFieldHintStyle }
        inputStyle={ commonMedia.fontSizeBody }
        inputWrapperStyle={ commonMedia.textFieldInputWrapperStyle }

    10. Dialog
        contentStyle={ { width: '100vw', maxWidth: 640} } // maxWidth optionally
        style={ isMobile ? { fontSize: `calc(4px + ${commonMedia.fontSizeBody.fontSize})` } : {} }

    11. Tabs
        tabStyle={ tabStyle }
        containerStyle={ containerStyle }
 */
