import React from 'react';
import { A } from 'intdev-ui';
import PropTypes from 'prop-types';
import CommentTooltipContainer from '@/timeline/components/CommentTooltipContainer';
import UserTooltipContainer from '@/common/components/UserTooltipContainer';
import { Record as BasicRecord } from './Record';


class CommentRecord extends React.Component {
    static propTypes = {
        date: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        parent_related_text: PropTypes.string.isRequired,
        action_text: PropTypes.string.isRequired,
        new_comments_count: PropTypes.number.isRequired,
        comments_count: PropTypes.number.isRequired,
        content_type_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        parent_comment_id: PropTypes.number,
        record_id: PropTypes.number.isRequired,
        is_private: PropTypes.bool.isRequired,
        is_liked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        authUserData: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        content_type_name: PropTypes.string.isRequired,
        header: PropTypes.node,
        related_author: PropTypes.shape({
            username: PropTypes.string.isRequired,
            full_name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        header: null,
        parent_comment_id: null,
        related_author: null,
    };

    get recordHead() {
        const {
            parent_related_text: parentRelatedText,
            is_private: isPrivate,
            action_text: actionText,
            parent_comment_id: parentCommentId,
            related_author: relatedAuthor,
        } = this.props;
        const relatedTextHTML = {
            __html: parentRelatedText,
        };
        let privateIndicator;
        let resultActionText;
        if (isPrivate) {
            privateIndicator = <span title="Доступ к записи ограничен" className="icon icon_status--lock_on" />;
        }
        let header;
        if (this.props.action_text) {
            resultActionText = actionText;
            if (this.props.action_text[actionText.length - 1] !== ' ') {
                resultActionText += ' ';
            }
            resultActionText = <span>{actionText}</span>;
        }
        if (this.props.header) {
            header = (
                <span>
                    <A
                        className="inline-link timeline-header"
                        href={ this.props.url }
                    >
                        { this.props.header }
                    </A>
                    &nbsp;
                </span>
            );
        }
        let author;
        if (relatedAuthor) {
            author = (
                <UserTooltipContainer
                    username={ relatedAuthor.username }
                    wrapper="span"
                    horizontal="middle"
                >
                    &nbsp;
                    <A
                        className="inline-link"
                        href={ relatedAuthor.url }
                        style={ { color: '#333' } }
                    >
                        { relatedAuthor.full_name }
                    </A>
                </UserTooltipContainer>
            );
        }
        return (
            <span>
                { privateIndicator }
                { resultActionText }
                { header }
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={ relatedTextHTML } />
                { author }
                { parentCommentId && <CommentTooltipContainer commentId={ parentCommentId } /> }
            </span>
        );
    }

    render() {
        return (
            <BasicRecord
                { ...this.props }
                recordHead={ this.recordHead }
                type="комментарий"
            />
        );
    }
}

export default CommentRecord;
