export default {
    API_FAIL: 'API_FAIL',
    QUIZ_LOAD_START: 'QUIZ_LOAD_START',
    QUIZ_LOAD_FINISH: 'QUIZ_LOAD_FINISH',
    QUIZ_LOAD_FAIL: 'QUIZ_LOAD_FAIL',
    TMP_ANSWER_SET: 'TMP_ANSWER_SET',
    TMP_ANSWERS_INIT: 'TMP_ANSWERS_INIT',
    STRICT_QUESTION_VALIDATION_ERROR: 'STRICT_QUESTION_VALIDATION_ERROR',
    COMMIT_ANSWERS_START: 'COMMIT_ANSWERS_START',
    COMMIT_ANSWERS_FINISH: 'COMMIT_ANSWERS_FINISH',
    GO_TO_PREVIOUS: 'GO_TO_PREVIOUS',
    GO_TO_NEXT: 'GO_TO_NEXT',
    GO_TO_START: 'GO_TO_START',
    GET_RESULTS_FINISH: 'GET_RESULTS_FINISH',
    GET_RESULTS_START: 'GET_RESULTS_START',
    SET_ACTIVE_FINISH: 'SET_ACTIVE_FINISH',
    SET_RESTRICT_FINISH: 'SET_RESTRICT_FINISH',
    SET_UNRESTRICT_FINISH: 'SET_UNRESTRICT_FINISH',
    API_CALL_START: 'API_CALL_START',
    SET_TO_ARCHIVE_FINISH: 'SET_TO_ARCHIVE_FINISH',
    PUBLISH_ON_MAIN_PAGE_FINISH: 'PUBLISH_ON_MAIN_PAGE_FINISH',
    NOT_PUBLISH_ON_MAIN_PAGE_FINISH: 'NOT_PUBLISH_ON_MAIN_PAGE_FINISH',
    UNPUBLISH_FROM_MAIN_PAGE_FINISH: 'UNPUBLISH_FROM_MAIN_PAGE_FINISH',
    DELETE_FINISH: 'DELETE_FINISH',
    QUIZZES_FIRST_LOAD_START: 'QUIZZES_FIRST_LOAD_START',
    QUIZZES_LOAD_FINISH: 'QUIZZES_LOAD_FINISH',
    QUIZZES_COUNTERS_LOAD_FINISH: 'QUIZZES_COUNTERS_LOAD_FINISH',
    QUIZZES_COUNTERS_LOAD_START: 'QUIZZES_COUNTERS_LOAD_START',
    GET_STATISTIC_FINISH: 'GET_STATISTIC_FINISH',
    GET_STATISTIC_START: 'GET_STATISTIC_START',
    GET_QUIZ_RESULT_START: 'GET_QUIZ_RESULT_START',
    GET_QUIZ_RESULT_FINISH: 'GET_QUIZ_RESULT_FINISH',
    REMOVE_ANSWERS_START: 'REMOVE_ANSWERS_START',
    REMOVE_ANSWERS_FINISH: 'REMOVE_ANSWERS_FINISH',
    SCHEME_LOAD_START: 'SCHEME_LOAD_START',
    SCHEME_LOAD_FINISH: 'SCHEME_LOAD_FINISH',
    GET_FIRST_QUIZ_RESULT_START: 'GET_FIRST_QUIZ_RESULT_START',
    QUIZ_BY_PASS_LOAD_START: 'QUIZ_BY_PASS_LOAD_START',
    QUIZ_BY_PASS_LOAD_FINISH: 'QUIZ_BY_PASS_LOAD_FINISH',
    ANSWER_BY_PASS_START: 'ANSWER_BY_PASS_START',
    ANSWER_BY_PASS_FINISH: 'ANSWER_BY_PASS_FINISH',
    ANSWER_BY_PASS_FAIL: 'ANSWER_BY_PASS_FAIL',
};
