import ru from './locales/ru';
import tg from './locales/tg';
import uz from './locales/uz';

export const locale = ['ru', 'uz', 'tg'].includes(window.USER_PROFILE?.language) ? window.USER_PROFILE?.language : 'ru';

const dict = {
    ru,
    uz,
    tg,
};

export const dictionary = dict[locale] || dict.ru;
