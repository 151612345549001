import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

let spaHistoryInstance = null;

export const getHistory = () => {
    spaHistoryInstance = spaHistoryInstance || createBrowserHistory();
    return spaHistoryInstance;
};

export const routerReducer = (history = getHistory()) => connectRouter(history);
