import { LOCATION_CHANGE } from 'connected-react-router';
import ACTION_TYPES from '../actionTypes';
import { arrayToObject } from '../utils';

export const choices = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZ_LOAD_FINISH: {
            return arrayToObject(action.payload.choices, 'id');
        }
        case LOCATION_CHANGE:
            return {};
        default:
            return state;
    }
};
