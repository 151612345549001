const booleanType = typeof true;

export const extractValue = (v) => {
    if (v.value === undefined) {
        return v.id === undefined ? v : v.id;
    }
    return v.value;
};

export const prepareValue = (v) => {
    const extractedValue = extractValue(v);
    const typeOfValue = typeof extractedValue;
    if (typeOfValue === booleanType) {
        return extractedValue ? '1' : '0';
    }
    return extractedValue;
};

export const parseUrlParams = (search) => {
    const obj = {};
    const queryString = search.substr(0, 1) === '?' ? search.substr(1) : search;
    queryString.replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(decodedKey)) {
            if (!Array.isArray(obj[decodedKey])) {
                obj[decodedKey] = [obj[decodedKey]];
            }
            obj[decodedKey].push(decodedValue);
        } else {
            obj[decodedKey] = decodedValue;
        }
    });
    return obj;
};

export const getUrlParams = search => parseUrlParams(search === undefined ? window.location.search : search);


export const buildUrlParamsFromArray = (params) => {
    const result = [];
    params.forEach((p) => {
        if (Array.isArray(p.value)) {
            p.value.forEach(elem => result.push(`${encodeURIComponent(p.id)}=${encodeURIComponent(prepareValue(elem))}`));
        } else {
            result.push(`${encodeURIComponent(p.id)}=${encodeURIComponent(prepareValue(p.value))}`);
        }
    });
    if (result.length === 0) {
        return '';
    }
    return result.join('&');
};

export const makeJsonParams = (params) => {
    const result = {};
    Object.keys(params).forEach((p) => {
        if (Array.isArray(params[p])) {
            result[p] = params[p].map(el => extractValue(el));
        } else {
            result[p] = extractValue(params[p]);
        }
    });
    return result;
};

export const buildUrlParams = (params) => {
    if (Array.isArray(params)) {
        return buildUrlParamsFromArray(params);
    }
    const result = [];
    Object.keys(params).filter(
        k => typeof params[k] !== 'undefined' && params[k] !== null,
    ).forEach((p) => {
        const param = params[p];
        if (Array.isArray(params[p])) {
            param.forEach(
                el => result.push(
                    `${encodeURIComponent(p)}=${encodeURIComponent(prepareValue(el))}`,
                ),
            );
        } else {
            result.push(`${encodeURIComponent(p)}=${encodeURIComponent(prepareValue(param))}`);
        }
    });
    if (result.length === 0) {
        return '';
    }
    return `?${result.join('&')}`;
};

export const buildUrl = (params, url) => {
    const queryParams = buildUrlParams(params);
    return `${url || window.location.pathname}${queryParams}`;
};
