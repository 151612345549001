import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'intdev-ui';
import moment from 'moment';

import { EVENT_SHAPE } from '../../shapes';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { showNotification } from '../../../common/helpers/showNotification';
import { apiUrls } from '../../constants/apiUrls';
import { FORMDATA } from '../../../common/constants/ajaxBodyTypes';
import { PANEL_GLOBAL_ERROR } from './EventEditModal';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';

export default class RemindConfirm extends React.Component {
    static propTypes = {
        event: EVENT_SHAPE.isRequired,
        onRequestClose: PropTypes.func.isRequired,
    };

    state = {
        processing: false,
    };

    render() {
        const startMoment = moment(this.props.event.startTime);
        const endMoment = moment(this.props.event.endTime);
        startMoment.locale('ru');

        return (<Dialog
            title="Напоминание о событии"
            modal
            open
            disabled={ this.state.disabled }
            actionsContainerStyle={ { display: 'flex', justifyContent: 'flex-end' } }
            actions={ [
                <Button
                    disabled={ this.state.disabled }
                    label="Отмена"
                    onClick={ this.props.onRequestClose }
                />,
                <Button
                    disabled={ this.state.disabled }
                    primary
                    label="Подтвердить"
                    onClick={ () => {
                        const url = apiUrls().meetingRemind(this.props.event.meetingId);
                        this.setState({ processing: true });
                        callApi(url, 'POST', { id: this.props.event.id }, FORMDATA).then((data) => {
                            if (data.error) {
                                showNotification({ message: data.error, level: 'warning' });
                            } else if (data.text) {
                                showNotification({ message: data.text, level: 'success' });
                            }
                            this.props.onRequestClose();
                        }).catch((data) => {
                            const camelizedData = objCamelFromSnake(data);
                            const message = camelizedData[PANEL_GLOBAL_ERROR] || 'Ошибка при выполнении запроса';
                            showNotification({ message, level: 'error' });
                            this.props.onRequestClose();
                        });
                    } }
                />,
            ] }
        >
            <div>{`Разослать напоминания записавшимся на событие "${this.props.event.name}"
                проходящее ${startMoment.format('D MMMM YYYY года с HH:mm')}
                до ${endMoment.format('HH:mm')}
                Следующее напоминание можно будет отправить через сутки!`}</div>
        </Dialog>);
    }
}

