import update from 'immutability-helper';
import moment from 'moment';
import { LOCATION_CHANGE } from 'connected-react-router';
import ACTION_TYPES from '../actionTypes';

export const quizScheme = (state = { process: false }, action) => {
    switch (action.type) {
        case ACTION_TYPES.SCHEME_LOAD_START: {
            return update(state, { process: { $set: true } });
        }
        case ACTION_TYPES.SCHEME_LOAD_FINISH: {
            moment.locale('ru');
            const newState = {
                process: false,
                title: action.payload.title,
                id: action.payload.id,
                quizzes: action.payload.quizzes.map(item => ({
                    value: item.id,
                    label: `${item.title} от ${moment(item.created_at).format('ll')}`,
                })),
            };
            return update(state, { $set: newState });
        }
        case ACTION_TYPES.API_FAIL: {
            return update(state, { process: { $set: false } });
        }
        case LOCATION_CHANGE:
            return update(state, { $set: { process: false } });
        default:
            return state;
    }
};
