import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { pagesUrls } from '../constants';
import UserTooltipContainer from '../../common/components/UserTooltipContainer';

const LIMIT = 2; // лайков в текстовом представлении

const templatesBefore = {
    0: 'нравится ',
    2: 'рассмешило ',
    3: 'удивляет ',
    4: 'огорчает ',
    5: 'возмущает ',
    6: 'обескуражило ',
};

const templatesAfter = {
    1: ' в восторге',
    7: ' считает, что это «Огонь»!',
    8: ' считает классным',
};

const capitalize = s => s[0].toUpperCase() + s.slice(1);


const FollowingLikes = props => {
    const { followingLikes } = props;
    const count = followingLikes.length;
    if (!count) {
        return null;
    }
    const limitedNames = followingLikes.slice(0, LIMIT);
    const namesByEmoji = {};
    for (const item of limitedNames) {
        if (item.emoji in namesByEmoji) {
            namesByEmoji[item.emoji].push(item);
        } else {
            namesByEmoji[item.emoji] = [item];
        }
    }
    const delimiter = (LIMIT === 2) ? ' и ' : ', ';
    const names = Object.entries(namesByEmoji).map(([emoji, items], index) => {
        let before = templatesBefore[emoji];
        if (before && !index) {
            before = capitalize(before);
        }
        const after = templatesAfter[emoji];
        return (
            <span key={ emoji }>
                { (index) ? ', ' : '' }
                { before }
                {
                    items.map((userData, innerIndex) => (
                        <span key={ userData.username } >
                            { (innerIndex) ? delimiter : '' }
                            <UserTooltipContainer username={ userData.username } wrapper="span">
                                <A
                                    href={ pagesUrls.userProfile(userData.username) }
                                    className="timeline-record-following-likes-link"
                                >
                                    { userData.full_name }
                                </A>
                            </UserTooltipContainer>
                        </span>
                    ))
                }
                { after }
            </span>
        );
    });

    return (
        <span className="timeline-record-following-likes">
            { names }
        </span>
    );
};

FollowingLikes.propTypes = {
    followingLikes: PropTypes.arrayOf(PropTypes.shape({
        username: PropTypes.string,
        full_name: PropTypes.string,
        emoji: PropTypes.number,
    })),
};

FollowingLikes.defaultProps = {
    followingLikes: [],
};

export default FollowingLikes;
