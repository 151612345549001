import { moduleName } from './constants';

export const FETCH_MICROPOST = `${moduleName}/FETCH_MICROPOST`;
export const FETCH_MICROPOST_SUCCESS = `${moduleName}/FETCH_MICROPOST_SUCCESS`;
export const FETCH_MICROPOST_FAILURE = `${moduleName}/FETCH_MICROPOST_FAILURE`;

export const FETCH_URL = `${moduleName}/FETCH_URL`;
export const FETCH_URL_SUCCESS = `${moduleName}/FETCH_URL_SUCCESS`;
export const FETCH_URL_FAILURE = `${moduleName}/FETCH_URL_FAILURE`;

export const SAVE_MICROPOST = `${moduleName}/SAVE_MICROPOST`;
export const SAVE_MICROPOST_SUCCESS = `${moduleName}/SAVE_MICROPOST_SUCCESS`;
export const SAVE_MICROPOST_FAILURE = `${moduleName}/SAVE_MICROPOST_FAILURE`;

export const UPLOAD_FILE = `${moduleName}/UPLOAD_FILE`;
export const UPLOAD_FILE_SUCCESS = `${moduleName}/UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_FAILURE = `${moduleName}/UPLOAD_FILE_FAILURE`;

export const RM_ATTACHMENT = `${moduleName}/RM_ATTACHMENT`;

export const ADD_ERROR = `${moduleName}/ADD_ERROR`;

export const ADD_STICKER = `${moduleName}/ADD_STICKER`;
export const RM_STICKER = `${moduleName}/RM_STICKER`;

export const OPEN_STICKER_SELECT_DIALOG = `${moduleName}/OPEN_STICKER_SELECT_DIALOG`;
export const CLOSE_STICKER_SELECT_DIALOG = `${moduleName}/CLOSE_STICKER_SELECT_DIALOG`;

export const OPEN_IMAGE_DIALOG = `${moduleName}/OPEN_IMAGE_DIALOG`;
export const CLOSE_IMAGE_DIALOG = `${moduleName}/CLOSE_IMAGE_DIALOG`;

export const CLEAR_FORM = `${moduleName}/CLEAR_FORM`;

export const CHANGE = `${moduleName}/CHANGE`;

export const CHANGE_VISIBILITY = `${moduleName}/CHANGE_VISIBILITY`;

export const RESTORE_DRAFT_FROM_STORAGE = `${moduleName}/RESTORE_DRAFT_FROM_STORAGE`;
