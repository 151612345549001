export const cleanData = (data) => {
    const cleanedData = {};
    Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value || value === '') {
            cleanedData[key] = value;
        }
    });
    return cleanedData;
};
