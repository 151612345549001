import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, IUIPropTypes } from 'intdev-ui';
import { callApi } from './middlewares/apiMiddleware';
import { capitalize } from '@/common/helpers/capitalize';
import { dictionary } from '@/common/localization/dictionary';

export const clearTag = (tagName) => {
    let clearedTagName = tagName.trim().toLowerCase();
    clearedTagName = clearedTagName.replace(/[-\s]/g, '_');
    clearedTagName = clearedTagName.replace(/[^a-zё_а-я0-9@.]/gi, '');
    clearedTagName = clearedTagName.replace(/(\.+|,+|\/+)$/, ''); // remove dots, comas and slashes in the end
    return clearedTagName;
};

const defaultStyle = {
    wrapperStyle: {
        width: '100%',
    },
};


function shouldKeyDownEventCreateNewOption(event) {
    const keyCode = event.keyCode;
    if (event.key === ',') {
        return true;
    }
    switch (keyCode) {
        case 9: // TAB
        case 13: // ENTER
        case 32: // SPACE
            return true;
        default:
            return false;
    }
}


export class TagField extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string.isRequired),
            PropTypes.string,
        ]),
        onChange: PropTypes.func,
        wrapperStyle: IUIPropTypes.style,
        validationState: PropTypes.bool,
        validationText: PropTypes.string,
        placeholder: PropTypes.string,
        menuStyle: IUIPropTypes.style,
        labelStyle: IUIPropTypes.style,
        inputProps: PropTypes.instanceOf(Object),
    };

    static defaultProps = {
        disabled: false,
        value: '',
        onChange: () => {},
        wrapperStyle: {},
        menuStyle: {},
        validationState: null,
        validationText: '',
        placeholder: '',
        inputProps: {},
        labelStyle: {},
    };

    get currentArrayValue() {
        const tags = this.props.value;
        if (Array.isArray(tags)) {
            return tags.map(tag => ({ tag: tag.trim() }));
        }
        return tags ? tags.split(',').map(tag => ({ tag: tag.trim() })) : [];
    }

    loadOptions = (input) => {
        const tagName = clearTag(input);
        if (!tagName) {
            return Promise.resolve({ options: [] });
        }
        return callApi(`/go/suggest/tags/?q=${tagName}&limit=10`).then(data => ({ options: data.items }));
    };

    handleChangeTags = (values) => {
        let newValues = values;
        if (values.length) {
            newValues = values.map(item => clearTag(item.tag)).filter(item => !!item);
        }
        const tags = Array.isArray(this.props.value) ? newValues : newValues.join(', ');
        this.props.onChange(tags);
    };

    render() {
        const {
            disabled,
            wrapperStyle,
            validationState,
            validationText,
            placeholder,
            menuStyle,
            inputProps,
            labelStyle,
        } = this.props;
        return (
            <Select.AsyncCreatable
                value={ this.currentArrayValue }
                onChange={ this.handleChangeTags }
                valueKey="tag"
                labelKey="tag"
                disabled={ disabled }
                loadOptions={ this.loadOptions }
                noResultsText="Нет результатов"
                placeholder={ placeholder }
                wrapperStyle={ { ...defaultStyle.wrapperStyle, ...wrapperStyle } }
                searchPromptText={ capitalize(dictionary.startTyping) }
                cache={ false }
                validationState={ validationState }
                validationText={ validationText }
                shouldKeyDownEventCreateNewOption={ shouldKeyDownEventCreateNewOption }
                multi
                menuStyle={ menuStyle }
                inputProps={ inputProps }
                labelStyle={ labelStyle }
            />
        );
    }
}
