/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import apiMiddleware from '../middlewares/apiMiddleware';
import apiErrorsMiddleware from '../middlewares/apiErrorsMiddleware';

export const configureStore = (reducers, initialState = {}, middlewareList = [], includeErrorsMiddleware = true) => {
    let defaultMiddlewareList = [];
    if (includeErrorsMiddleware) {
        defaultMiddlewareList = [thunk, apiMiddleware, apiErrorsMiddleware];
    } else defaultMiddlewareList = [thunk, apiMiddleware];
    let enhancer;
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancer = compose(
            applyMiddleware(...middlewareList, ...defaultMiddlewareList),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        );
    } else {
        enhancer = compose(
            applyMiddleware(...middlewareList, ...defaultMiddlewareList),
        );
    }

    return createStore(reducers, initialState, enhancer);
};
