import React from 'react';
import PropTypes from 'prop-types';
import { Carousel, A } from 'intdev-ui';
import { Record as BasicRecord } from './Record';


export class UserAvatarRecord extends React.Component {
    static propTypes = {
        action_text: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        showSettings: PropTypes.bool,
        canDeleteAvatarRecords: PropTypes.bool,
        userData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        authUserData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        record_id: PropTypes.number.isRequired,
    };

    static defaultProps = {
        showSettings: false,
        canDeleteAvatarRecords: false,
    };

    state = {
        isOpened: false,
    };

    get src() {
        const regex = /<img.*?src="(.*?)"/;
        return regex.exec(this.props.text)[1];
    }

    handleRequestClose = () => this.setState({ isOpened: false });

    handleImageClick = () => this.setState({ isOpened: true });

    render() {
        const recordHead = (
            <span>
                { `${this.props.action_text} ` }
                <A href={ `/timeline/record/${this.props.record_id}/` } className="inline-link timeline-header">
                    фото профиля
                </A>
            </span>
        );
        const src = this.src;
        const recordBody = (
            <div>
                <div className="timeline-user-avatar-container">
                    <img src={ src } onClick={ this.handleImageClick } title="Развернуть" />
                </div>
                <Carousel
                    open={ this.state.isOpened }
                    images={ [{ url: src }] }
                    startIndex={ 0 }
                    onRequestClose={ this.handleRequestClose }
                />
            </div>
        );

        const { userData, authUserData } = this.props;
        const canDeleteAvatarRecords = this.props.canDeleteAvatarRecords || (
            userData.username === authUserData.username
        );
        const showSettings = this.props.showSettings || canDeleteAvatarRecords;
        const isUserDetailTimeline = this.props.showSettings !== showSettings;
        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ recordBody }
                collapsed={ false }
                { ...this.props }
                showSettings={ showSettings }
                canDeleteAvatarRecords={ canDeleteAvatarRecords }
                showContentSettings={ !isUserDetailTimeline }
                showAuthorSettings={ !isUserDetailTimeline }
            />
        );
    }
}
