/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getResults } from '../actions/results';
import { QuizStatistic } from './QuizStatistic';


class QuizEndText extends React.Component {
    static propTypes = {
        quiz: PropTypes.shape({
            id: PropTypes.number,
        }).isRequired,
        results: PropTypes.shape({
            statistics: PropTypes.arrayOf(PropTypes.shape()),
            rightAnswersCount: PropTypes.number,
            rightChoicesCount: PropTypes.number,
            endingText: PropTypes.string,
        }).isRequired,
        getResults: PropTypes.func.isRequired,
    };

    static defaultProps = {
        endingText: null,
    };

    componentDidMount() {
        this.props.getResults(this.props.quiz.id);
    }

    score = () => {
        if (!this.props.results.rightAnswersCount && !this.props.results.rightChoicesCount) {
            return null;
        }
        return (
            <div className="quiz-detail__test-score">
                <span>
                    {this.props.results.rightAnswersCount}/{this.props.results.rightChoicesCount}
                </span>
            </div>
        );
    };

    render() {
        if (!this.props.results.endingText) {
            return null;
        }
        return (
            <div>
                { this.score() }
                <div
                    className="quiz-detail__text_size_m"
                    dangerouslySetInnerHTML={ { __html: this.props.results.endingText } }
                />
                { this.props.results.statistics.length > 0 &&
                    <QuizStatistic
                        quizId={ this.props.quiz.id }
                        statistics={ this.props.results.statistics }
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }) => ({
    results: state.userResults,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getResults,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuizEndText);
