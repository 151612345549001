import PropTypes from 'prop-types';
import { visibilityOptions } from './constants';

export const attachmentProp = PropTypes.shape({
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    url: PropTypes.string,
    large_url: PropTypes.string,
    name: PropTypes.string,
});

export const micropostProps = {
    // basics
    fromUserAvatar: PropTypes.string.isRequired,
    fromUserFullname: PropTypes.string.isRequired,
    toUserId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    visibility: PropTypes.oneOf([...Object.keys(visibilityOptions)]),
    // options
    placeholder: PropTypes.string,
    inline: PropTypes.bool,
    expanded: PropTypes.bool,
    focused: PropTypes.bool,
    // content
    attachmentsInProgress: PropTypes.arrayOf(attachmentProp),
    attachments: PropTypes.arrayOf(attachmentProp),
    sticker: PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
    }),
    // state
    inProgress: PropTypes.bool,
    imageDialogOpen: PropTypes.bool,
    stickerSelectDialogOpen: PropTypes.bool,
    // edit
    id: PropTypes.number,
    text: PropTypes.string,
    // funcs
    onPostSuccess: PropTypes.func,
    onClearForm: PropTypes.func.isRequired,
    saveMicropost: PropTypes.func.isRequired,
    fetchUrl: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    rmAttachment: PropTypes.func.isRequired,
    addSticker: PropTypes.func.isRequired,
    rmSticker: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
    onOpenStickerSelectDialog: PropTypes.func.isRequired,
    onCloseStickerSelectDialog: PropTypes.func.isRequired,
    onOpenImageDialog: PropTypes.func.isRequired,
    onCloseImageDialog: PropTypes.func.isRequired,
    onVisibilityValueChange: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
    noPaper: PropTypes.bool,
};

export const micropostDefaultProps = {
    // basics
    toUserId: null,
    // options
    placeholder: 'Напиши что-нибудь интересное',
    inline: false,
    expanded: false,
    focused: false,
    // content
    attachmentsInProgress: {},
    attachments: {},
    sticker: null,
    // state
    inProgress: false,
    imageDialogOpen: false,
    stickerSelectDialogOpen: false,
    // edit
    id: null,
    text: '',
    // funcs
    onPostSuccess: null,
    noPaper: false,
};
