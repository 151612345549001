/* eslint-disable react/no-array-index-key */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { A, Button, Dialog, Popover } from 'intdev-ui';

import {
    quizToArchive, quizPublishOnMainPage, quizDelete,
    quizNotPublishOnMainPage, quizUnPublishFromMainPage,
} from '../actions/manage';
import * as constants from '../constants';
import { dictionary } from '@/common/localization/dictionary';


class CommonMenuButton extends React.Component {
    static propTypes = {
        buttonStyle: PropTypes.shape(),
        icon: PropTypes.node,
        title: PropTypes.string,
        quiz: PropTypes.shape().isRequired,
        quizToArchive: PropTypes.func.isRequired,
        quizPublishOnMainPage: PropTypes.func.isRequired,
        quizNotPublishOnMainPage: PropTypes.func.isRequired,
        quizUnPublishFromMainPage: PropTypes.func.isRequired,
        quizDelete: PropTypes.func.isRequired,
        pubOnMainPagePerm: PropTypes.bool.isRequired,
        usingInQuizzesList: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        buttonStyle: {},
        icon: null,
        title: undefined,
    };

    state = {
        popoverOpen: false,
        dialogOpen: false,
        dialogActionUrl: '',
        handleSuccessOnDialog: () => {},
    };

    handleDialogClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleToArchiveButton = (event) => {
        event.preventDefault();
        const handleSuccessOnDialog = () => {
            this.setState({ dialogOpen: false });
            this.props.quizToArchive(this.props.quiz.id, this.props.usingInQuizzesList);
        };
        this.setState({
            dialogText: 'Вы уверены, что хотите отправить опрос в архив?',
            dialogOpen: true,
            popoverOpen: false,
            handleSuccessOnDialog,
        });
    };

    handlePublishOnMainPageButton = (event) => {
        event.preventDefault();
        const handleSuccessOnDialog = () => {
            this.setState({ dialogOpen: false });
            this.props.quizPublishOnMainPage(this.props.quiz.id, this.props.usingInQuizzesList);
        };
        this.setState({
            dialogText: 'Вы уверены, что хотите опубликовать опрос на главной странице?',
            dialogOpen: true,
            popoverOpen: false,
            handleSuccessOnDialog,
        });
    };

    handleNotPublishOnMainPageButton = (event) => {
        event.preventDefault();
        const handleSuccessOnDialog = () => {
            this.setState({ dialogOpen: false });
            this.props.quizNotPublishOnMainPage(this.props.quiz.id, this.props.usingInQuizzesList);
        };
        this.setState({
            dialogText: 'Вы уверены, что не хотите публиковать опрос на главной странице?',
            dialogOpen: true,
            popoverOpen: false,
            handleSuccessOnDialog,
        });
    };

    handleUnPublishFromMainPageButton = (event) => {
        event.preventDefault();
        const handleSuccessOnDialog = () => {
            this.setState({ dialogOpen: false });
            this.props.quizUnPublishFromMainPage(this.props.quiz.id, this.props.usingInQuizzesList);
        };
        this.setState({
            dialogText: 'Вы уверены, что хотите убрать опрос с главной страницы?',
            dialogOpen: true,
            popoverOpen: false,
            handleSuccessOnDialog,
        });
    };

    handleDeleteButton = (event) => {
        event.preventDefault();
        const handleSuccessOnDialog = () => {
            this.setState({ dialogOpen: false });
            this.props.quizDelete(this.props.quiz.id, this.props.usingInQuizzesList);
        };
        this.setState({
            dialogText: 'Вы уверены, что хотите удалить этот опрос? Это действие также удалит все результаты опроса.',
            dialogOpen: true,
            popoverOpen: false,
            handleSuccessOnDialog,
        });
    };

    commonMenu = () => {
        const {
            quiz,
            pubOnMainPagePerm,
        } = this.props;
        const menuItems = [];
        if (this.props.quiz.flag !== constants.FLAG_ARCHIVE) {
            menuItems.push({ name: 'В архив', href: '#', onClick: this.handleToArchiveButton });
        }
        menuItems.push({ name: 'Клонировать', href: constants.QUIZ_SCHEME_CLONE_URL(quiz.quiz_scheme.id) });
        menuItems.push({ name: 'Провести повторно', href: constants.QUIZ_CLONE_URL(quiz.id) });
        if (pubOnMainPagePerm && quiz.main_page_status !== constants.ON_MAIN_PAGE) {
            menuItems.push({ name: 'Опубликовать на главной', href: '#', onClick: this.handlePublishOnMainPageButton });
            if (quiz.main_page_status === constants.ON_MODERATION) {
                menuItems.push({
                    name: 'Отказать в публикации на главной', href: '#', onClick: this.handleNotPublishOnMainPageButton,
                });
            }
        }
        if (pubOnMainPagePerm && quiz.main_page_status === constants.ON_MAIN_PAGE) {
            menuItems.push({
                name: 'Убрать опрос с главной страницы', href: '#', onClick: this.handleUnPublishFromMainPageButton,
            });
        }
        menuItems.push({
            name: 'Удалить', href: '#', onClick: this.handleDeleteButton,
        });
        // eslint-disable-next-line react/no-array-index-key
        return menuItems.map((item, index) => (
            <div key={ index } className="quiz-detail_drop-down-menu-item">
                <A
                    className="quiz-detail__drop-down-menu-item-link quiz-detail__text_size_s"
                    { ...item }
                >
                    { item.name }
                </A>
            </div>
        ));
    };

    handleClick = (event) => {
        event.preventDefault();
        this.setState({
            popoverOpen: true,
            anchorEl: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            popoverOpen: false,
        });
    };

    render() {
        return (
            <div className="quiz-detail__menu-button">
                <Button
                    icon={ this.props.icon }
                    style={ this.props.buttonStyle }
                    onClick={ this.handleClick }
                    title={ this.props.title }
                />
                <Popover
                    open={ this.state.popoverOpen }
                    anchorEl={ this.state.anchorEl }
                    anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
                    targetOrigin={ { horizontal: 'right', vertical: 'top' } }
                    onRequestClose={ this.handleRequestClose }
                >
                    <div className="quiz-detail__drop-down-menu">
                        { this.commonMenu() }
                    </div>
                </Popover>
                <Dialog
                    title="Подтвердите действие"
                    actions={ [
                        <Button
                            label={ dictionary.accept }
                            primary
                            onClick={ this.state.handleSuccessOnDialog }
                        />,
                        <Button
                            label={ dictionary.cancel }
                            onClick={ this.handleDialogClose }
                        />,
                    ] }
                    modal={ false }
                    open={ this.state.dialogOpen }
                    onRequestClose={ this.handleDialogClose }
                >
                    { this.state.dialogText }
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
    quizToArchive,
    quizPublishOnMainPage,
    quizNotPublishOnMainPage,
    quizUnPublishFromMainPage,
    quizDelete,
}, dispatch);

export default connect(
    mapStateToProps, mapDispatchToProps,
)(CommonMenuButton);
