export const MALE = 1;
export const MAX_SMS_LENGTH = 160;

export const CAUSE_ABSENCE = 0;
export const CAUSE_BUSTRIP = 1;
export const CAUSE_OTHER = 2;
export const CAUSE_CHILDCARE = 3;
export const CAUSE_SICKLEAVE = 4;

export const MAX_BADGE_DISPLAY = 18;

// ширина экрана меньше которой профиль отображается в 2 столбца
export const TWO_COLUMN_WIDTH = 910;

export const apiUrls = {
    addToBlackListUrl: '/following/api/black_list/add/',
    removeToBlackListUrl: '/following/api/black_list/remove/',
    userSuggestsUrl: '/we/get_users/',
    avatarRecordUrl: id => `/timeline/api/avatar/${id}/`,
};

export const CONTACT_ICQ = 'icq';
export const CONTACT_SKYPE = 'skype';
export const CONTACT_TELEGRAM = 'telegram';
export const CONTACT_VK = 'vkmessenger';
export const CONTACT_TAMTAM = 'tamtam';
export const CONTACT_WHATSAPP = 'whatsapp';
export const CONTACT_VIBER = 'viber';

export const VYDRA_BADGE_CODENAME = 'zona-rosta';
export const ANTI_VYDRA_BADGE_CODENAME = 'razvitie';
export const VYDRA_THANKS_CATEGORY_SLUG = 'competence_growth_area';
export const ANTI_VYDRA_THANKS_CATEGORY_SLUG = 'competence_razvitie';
