import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@/common/components/Helmet';
import { IUIPropTypes } from 'intdev-ui';

import { pageStyle } from './pageStyle';
import { mainPageRef } from './mainPageRef';

export const PageForbidden = (props) => {
    const containerStyle = {
        ...pageStyle.container,
        ...props.containerStyle,
    };
    const textStyle = {
        ...pageStyle.text,
        ...props.textStyle,
    };

    return (
        <div style={ containerStyle }>
            <Helmet>
                <title>Доступ к странице ограничен</title>
            </Helmet>
            <div>
                <img alt="403" src="/static/403image.png" />
            </div>
            <div style={ textStyle }>
                <span>{ props.text }</span>
            </div>
            <div>
                { props.referenceText }
            </div>
        </div>
    );
};

PageForbidden.propTypes = {
    text: PropTypes.string,
    referenceText: PropTypes.node,
    containerStyle: IUIPropTypes.style,
    textStyle: IUIPropTypes.style,
};

PageForbidden.defaultProps = {
    text: 'У тебя недостаточно прав для просмотра этой страницы.',
    containerStyle: {},
    textStyle: {},
    referenceText: mainPageRef,
};
