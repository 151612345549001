import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';
import { arrayToObject } from '../utils';
import { FLAG_ARCHIVE, FLAG_PUBLISHED, ON_MAIN_PAGE, NOT_ON_MAIN_PAGE } from '../constants';


export const quizzes = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZZES_FIRST_LOAD_START: {
            return {};
        }
        case ACTION_TYPES.QUIZZES_LOAD_FINISH: {
            return update(state, { $merge: arrayToObject(action.payload, 'id') });
        }
        case ACTION_TYPES.SET_ACTIVE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        flag: { $set: FLAG_PUBLISHED },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.SET_RESTRICT_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        is_restricted: { $set: true },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.SET_UNRESTRICT_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        is_restricted: { $set: false },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.SET_TO_ARCHIVE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        flag: { $set: FLAG_ARCHIVE },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.PUBLISH_ON_MAIN_PAGE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        main_page_status: { $set: ON_MAIN_PAGE },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.UNPUBLISH_FROM_MAIN_PAGE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        main_page_status: { $set: NOT_ON_MAIN_PAGE },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.NOT_PUBLISH_ON_MAIN_PAGE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, {
                    [quizId]: {
                        main_page_status: { $set: NOT_ON_MAIN_PAGE },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.DELETE_FINISH: {
            const quizId = action.additionalData.quizId;
            if (quizId in state) {
                return update(state, { $unset: [quizId] });
            }
            return state;
        }
        default:
            return state;
    }
};
