import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VisibilitySensor from 'react-visibility-sensor';
import { Carousel, Spinner } from 'intdev-ui';

import { isBeforeDate } from '@intdev/intdev-ui/lib/DatePicker/dateUtils';
import { BookComponent } from '@/blog/components/BlogDetail/BookComponent';
import { handleResponseError } from '@/common/helpers/errorHadlers';

import AttachmentListItem from './AttachmentListItem';
import { loadAttachments, loadAttachmentsForward, onCarouselClose } from '../../actions';
import {
    ICON_CLASS_IMG,
    attachmentsTypesList,
    attachmentsTypes,
} from '../../constants';

// todo fill shape;
const ATTACHMENT_ITEM_SHAPE = PropTypes.shape();

const styles = {
    spinnerContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '10px',
    },
};

class PublicationAttachmentsContainerComponent extends React.Component {
    static propTypes = {
        contentType: PropTypes.string.isRequired,
        objectId: PropTypes.string.isRequired,
        canManageAttachments: PropTypes.bool.isRequired,
        // from state
        carouselStartId: PropTypes.number.isRequired,
        isOpenCarousel: PropTypes.bool.isRequired,
        attachments: PropTypes.arrayOf(ATTACHMENT_ITEM_SHAPE).isRequired,
        loading: PropTypes.bool.isRequired,
        forwardEmpty: PropTypes.bool.isRequired,
        // dispatchers
        loadAttachments: PropTypes.func.isRequired,
        loadAttachmentsForward: PropTypes.func.isRequired,
        onCarouselClose: PropTypes.func.isRequired,
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
            this.handleScroll();
        }
    }

    handleScroll = () => {
        const { hash } = window.location;
        if (!hash) return;
        const element = document.getElementById(hash.replace('#', ''));

        if (element) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: 'smooth',
                    top: element.offsetTop,
                });
            }, 100);
        }
    };

    getCarousel = () => {
        let carouselStartIndex = 0;
        const carouselImages = this.props.attachments.filter(
            i => i.iconClass === ICON_CLASS_IMG,
        ).map((item, index) => {
            if (item.id === this.props.carouselStartId) {
                carouselStartIndex = index;
            }
            return {
                url: item.attachmentFileUrl,
            };
        });

        return (
            <Carousel
                open
                images={ carouselImages }
                startIndex={ carouselStartIndex }
                onRequestClose={ this.props.onCarouselClose }
            />
        );
    };

    loadData = () => {
        this.props.loadAttachments().then(() => {
            this.props.loadAttachmentsForward();
        }).catch((response) => {
            handleResponseError(response);
        });
    };

    handleVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.props.loadAttachmentsForward();
        }
    };

    render() {
        const { attachments, canManageAttachments } = this.props;
        const handleEditItem = this.loadData;
        const attachmentsGroups = attachmentsTypes.map(
            (group) => {
                const groupAttachment = attachments.filter(
                    i => i.iconClass === group.type,
                ).sort(
                    (a, b) => {
                        if (isBeforeDate(new Date(b.created), new Date(a.created))) return 1;
                        return -1;
                    },
                );
                if (groupAttachment.length) {
                    return (
                        <div key={ group.type }>
                            <div className="blog-post-album-title" id={ group.id }> { group.name } </div>
                            <div className="blog-post-attachment">
                                { groupAttachment.map(item => (
                                    <div key={ item.id }>
                                        <AttachmentListItem
                                            canManageAttachments={ canManageAttachments }
                                            handleEditItem={ handleEditItem }
                                            { ...item }
                                        />
                                    </div>
                                )) }
                            </div>
                        </div>
                    );
                }
                return '';
            },
        );
        const filesAttachmentsList = attachments.filter(i => !attachmentsTypesList.includes(i.iconClass) && !i.isBook);
        let filesAttachments = null;
        if (filesAttachmentsList.length) {
            filesAttachments = (
                <div>
                    <div className="blog-post-album-title" id="docs">Файлы</div>
                    <div className="blog-post-attachment">
                        { filesAttachmentsList.map(item => (
                            <div key={ item.id }>
                                <AttachmentListItem
                                    canManageAttachments={ canManageAttachments }
                                    handleEditItem={ handleEditItem }
                                    { ...item }
                                />
                            </div>
                        )) }
                    </div>
                </div>
            );
        }

        const booksList = attachments.sort((a, b) => new Date(a.created) - new Date(b.created)).filter(i => i.isBook);
        let books = null;
        if (booksList.length) {
            books = (
                <div className="pdf-viewer-container">
                    <div className="blog-post-album-title" id="books">Книги</div>
                    <div className="blog-post-attachments">
                        { booksList.map(item => (
                            <div className="book-component-container" key={ item.id }>
                                <BookComponent
                                    canManageAttachments={ canManageAttachments }
                                    url={ item.attachmentFileUrl }
                                    { ...item }
                                />
                            </div>
                        )) }
                    </div>
                </div>
            );
        }

        return (
            <div className="blog-post-attachments-container">
                <div className="books-container">
                    { books }
                </div>
                {/* attachments are divided into groups: images, video, audio, separately - files and other types */}
                <div className="attachments-groups-container">
                    { attachmentsGroups }
                </div>
                <div className="publication-files-container">
                    { filesAttachments }
                </div>

                { this.props.isOpenCarousel
                    && this.getCarousel()}
                { this.props.loading
                    ? (
                        <div style={ styles.spinnerContainer }>
                            <Spinner size={ 32 } />
                        </div>
                    )
                    : (
                        <VisibilitySensor
                            onChange={ this.handleVisibilityChange }
                            active={ !this.props.forwardEmpty && !!this.props.attachments.length }
                        />
                    )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.publicationAttachments,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadAttachments: () => dispatch(loadAttachments(ownProps.contentType, ownProps.objectId)),
    loadAttachmentsForward: () => dispatch(loadAttachmentsForward(ownProps.contentType, ownProps.objectId)),
    ...bindActionCreators({
        onCarouselClose,
    }, dispatch),
});

const PublicationAttachmentsContainer = connect(
    mapStateToProps, mapDispatchToProps,
)(PublicationAttachmentsContainerComponent);

export default PublicationAttachmentsContainer;
