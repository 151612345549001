import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';
import {
    ALL_CODES, ALL_CODE, ARCHIVED_CODE, MAIN_PAGE_CODE, MODERATION_CODE,
} from '../constants';

const initialState = ALL_CODES.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item] = {
        items: [],
        isEnded: false,
        count: 0,
    };
    return obj;
}, {});

export const quizzesByCode = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZZES_FIRST_LOAD_START: {
            return initialState;
        }
        case ACTION_TYPES.QUIZZES_LOAD_FINISH: {
            return update(state, {
                [action.additionalData.code]: {
                    items: { $push: action.payload.map(item => item.id) },
                    isEnded: { $set: action.payload.length === 0 },
                },
            });
        }
        case ACTION_TYPES.QUIZZES_COUNTERS_LOAD_FINISH: {
            const newState = { ...state };
            Object.keys(newState).forEach((code) => { newState[code].count = action.payload[code]; });
            return newState;
        }
        case ACTION_TYPES.SET_ACTIVE_FINISH: {
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            const index = state[ARCHIVED_CODE].items.indexOf(quizId);
            if (index !== -1) {
                return update(state, {
                    [ARCHIVED_CODE]: {
                        count: { $set: state[ARCHIVED_CODE].count - 1 },
                        items: { $splice: [[index, 1]] },
                    },
                    [ALL_CODE]: {
                        count: { $set: state[ALL_CODE].count + 1 },
                        items: { $unshift: [quizId] },
                        isEnded: { $set: false },
                    },
                });
            }
            return state;
        }
        case ACTION_TYPES.SET_TO_ARCHIVE_FINISH: {
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            const index = state[ALL_CODE].items.indexOf(quizId);
            if (index === -1) {
                return state;
            }
            return update(state, {
                [ALL_CODE]: {
                    count: { $set: state[ALL_CODE].count - 1 },
                    items: { $splice: [[index, 1]] },
                },
                [ARCHIVED_CODE]: {
                    count: { $set: state[ARCHIVED_CODE].count + 1 },
                    items: { $set: [] },
                    isEnded: { $set: false },
                },
            });
        }
        case ACTION_TYPES.PUBLISH_ON_MAIN_PAGE_FINISH: {
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            const index = state[MODERATION_CODE].items.indexOf(quizId);
            if (index === -1) {
                return update(state, {
                    [MAIN_PAGE_CODE]: {
                        count: { $set: state[MAIN_PAGE_CODE].count + 1 },
                        items: { $set: [] },
                        isEnded: { $set: false },
                    },
                });
            }
            return update(state, {
                [MODERATION_CODE]: {
                    count: { $set: state[MODERATION_CODE].count - 1 },
                    items: { $splice: [[index, 1]] },
                },
                [MAIN_PAGE_CODE]: {
                    count: { $set: state[MAIN_PAGE_CODE].count + 1 },
                    items: { $set: [] },
                    isEnded: { $set: false },
                },
            });
        }
        case ACTION_TYPES.UNPUBLISH_FROM_MAIN_PAGE_FINISH: {
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            const index = state[MAIN_PAGE_CODE].items.indexOf(quizId);
            if (index === -1) {
                return state;
            }
            return update(state, {
                [MAIN_PAGE_CODE]: {
                    count: { $set: state[MAIN_PAGE_CODE].count - 1 },
                    items: { $splice: [[index, 1]] },
                },
            });
        }
        case ACTION_TYPES.NOT_PUBLISH_ON_MAIN_PAGE_FINISH: {
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            const index = state[MODERATION_CODE].items.indexOf(quizId);
            if (index === -1) {
                return state;
            }
            return update(state, {
                [MODERATION_CODE]: {
                    count: { $set: state[MODERATION_CODE].count - 1 },
                    items: { $splice: [[index, 1]] },
                },
            });
        }
        case ACTION_TYPES.DELETE_FINISH: {
            const newState = { ...state };
            const { quizId, updateQuizzesList } = action.additionalData;
            if (!updateQuizzesList) {
                return state;
            }
            for (const code of ALL_CODES) {
                const index = newState[code].items.indexOf(quizId);
                if (index !== -1) {
                    newState[code].items.splice(index, 1);
                    newState[code].count -= 1;
                }
            }
            return newState;
        }
        default:
            return state;
    }
};
