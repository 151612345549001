import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Popover } from 'intdev-ui';
import { Arrow } from './Arrow';
import classes from './SimpleDropdown.scss';

export class SimpleDropdown extends Component {
    static propTypes = {
        value: PropTypes.shape({}),
        options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: undefined,
        onChange: () => {},
    };

    state = {
        open: false,
    };

    handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    makeOptionClickHandler = el => (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onChange(el);
        this.setState({ open: false });
    };

    render() {
        return (
            <>
                <Arrow onClick={ this.handleClick } className={ classnames(classes.arrow, this.state.open && classes.arrowOpen) } />
                <Popover
                    open={ this.state.open }
                    anchorEl={ this.state.anchorEl }
                    anchorOrigin={ { horizontal: 'left', vertical: 'bottom' } }
                    targetOrigin={ { horizontal: 'left', vertical: 'top' } }
                    onRequestClose={ () => this.setState({ open: false }) }
                >
                    { this.props.options.map(el => (
                        <div
                            className={ classes.option }
                            onClick={ this.makeOptionClickHandler(el) }
                            key={ el.id || el.name }
                        >
                            { el.name }
                        </div>
                    )) }
                </Popover>
            </>
        );
    }
}
