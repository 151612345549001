import React from 'react';
import { Paper, Spinner } from 'intdev-ui';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobile } from '@/common/mediaQueries';
import { Link } from 'react-router-dom';
import { BASE_URL } from '@/quiz/detail/constants';
import QuizDetailHeaderContainer from '../containers/QuizDetailHeaderContainer';
import QuestionGroup from './groups/QuestionGroup';
import QuizEndText from './QuizEndText';
import QuizControls from './QuizControls';


export default class QuizDetail extends React.Component {
    static propTypes = {
        quizId: PropTypes.number,
        quiz: PropTypes.shape({
            id: PropTypes.number,
            text: PropTypes.string,
            is_repeated: PropTypes.bool,
            flag: PropTypes.number,
            quiz_scheme: PropTypes.shape({
                is_test: PropTypes.bool,
            }),
        }).isRequired,
        fetchQuiz: PropTypes.func.isRequired,
        process: PropTypes.bool.isRequired,
        finished: PropTypes.bool.isRequired,
        quizOnMainPage: PropTypes.bool,
        singleMode: PropTypes.bool,
    };

    static defaultProps = {
        quizId: null,
        quizOnMainPage: false,
        singleMode: false,
    };

    componentDidMount() {
        this.props.fetchQuiz(this.props.quizId);
    }

    render() {
        let [loader, publicationText] = [null, null];
        const {
            quiz,
            process,
            finished,
            quizOnMainPage,
        } = this.props;

        if (!process && !quiz.id) {
            return null;
        }

        if (process) {
            loader = (
                <div className="quiz-detail__process-spinner">
                    <Spinner size={ 32 } />
                </div>
            );
        }

        if (!finished && quiz.text) {
            publicationText = (
                <div
                    className={ `quiz-detail__text_size_${(quizOnMainPage) ? 's' : 'm'}` }
                    dangerouslySetInnerHTML={ { __html: quiz.text } }
                />
            );
        } else if (finished) {
            publicationText = <QuizEndText quiz={ quiz } />;
        }

        const wrapperClasses = classnames({
            'quiz-detail': true,
            'quiz-detail-on-main-page': this.props.quizOnMainPage,
            'quiz-detail__width_full': this.props.singleMode,
        });

        return (
            <>
                { isMobile && this.props.history ? (
                    <div className="mobile-arrow-back">
                        <Link to={ BASE_URL } className="arrow-icon">
                            <i className="fa fa-angle-left" />
                        </Link>
                        <div className="arrow-text">
                            Опрос № {this.props.quizId}
                        </div>
                    </div>
                ) : null }
                <Paper className={ wrapperClasses }>
                    <QuizDetailHeaderContainer
                        compactMode={ quizOnMainPage }
                        quiz={ quiz }
                        showAdditionalData={ !quizOnMainPage }
                        singleMode={ this.props.singleMode }
                    />
                    { publicationText }
                    { loader }
                    { quiz.id && !process && quiz.flag !== 0
                    && (
                        <QuestionGroup
                            quizId={ quiz.id }
                            isTest={ quiz.quiz_scheme.is_test }
                            compactMode={ quizOnMainPage }
                        />
                    )}
                    { quiz.id && !process && quiz.flag !== 0
                    && (
                        <QuizControls
                            quizId={ quiz.id }
                            isTest={ quiz.quiz_scheme.is_test }
                            reloadAfterExecution={ !quizOnMainPage }
                            isRepeated={ quiz.is_repeated }
                        />
                    )}
                </Paper>
            </>
        );
    }
}
