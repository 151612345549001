import { ACTION_TYPES } from '@/blog/constants/actionTypes';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';

const publicationAttachments = (state = {
    attachments: [],
    loading: false,
    attachmentMinId: undefined,
    forwardEmpty: false,
    carouselStartId: 0,
    isOpenCarousel: false,
}, action) => {
    switch (action.type) {
        case ACTION_TYPES.BLOG_ATTACHMENT_CAROUSEL_CLOSE: {
            return {
                ...state,
                isOpenCarousel: false,
            };
        }
        case ACTION_TYPES.BLOG_ATTACHMENT_IMAGE_CLICK: {
            const carouselStartId = action.data;
            return {
                ...state,
                carouselStartId,
                isOpenCarousel: true,
            };
        }
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_START:
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FAILURE:
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_SUCCESS: {
            const data = action.payload;
            const attachments = objCamelFromSnake(data);

            const forwardEmpty = !attachments.length;
            let attachmentMinId = state.attachmentMinId || Infinity;
            attachments.forEach((attachment) => {
                attachmentMinId = Math.min(attachmentMinId, attachment.id);
            });

            return {
                ...state,
                attachments,
                forwardEmpty,
                attachmentMinId,
                loading: false,
            };
        }
        case ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_SUCCESS: {
            const data = action.payload;
            const attachments = objCamelFromSnake(data);

            const forwardEmpty = !attachments.length;
            let attachmentMinId = state.attachmentMinId || Infinity;
            attachments.forEach((attachment) => {
                attachmentMinId = Math.min(attachmentMinId, attachment.id);
            });

            return {
                ...state,
                forwardEmpty,
                attachmentMinId,
                loading: false,
                attachments: [...state.attachments, ...attachments],
            };
        }
        case ACTION_TYPES.BLOG_DELETE_ATTACHMENTS_SUCCESS: {
            const {
                attachmentId,
            } = action.additionalData;

            for (let i = 0; i < state.attachments.length; i++) {
                if (state.attachments[i].id === attachmentId) {
                    const attachments = [...state.attachments];
                    attachments.splice(i, 1);
                    return {
                        ...state,
                        attachments,
                    };
                }
            }
            return state;
        }
        default:
            return state;
    }
};

export default publicationAttachments;
