export default {
    ALBUMS_ALBUMPHOTO: 'albums.albumphoto',
    ALBUMS_ALBUM: 'albums.album',
    ATTACHMENTS_ATTACHMENT: 'attachments.attachment',
    AUTH_PERMISSION: 'auth.permission',
    AVATAR_USERAVATAR: 'avatar.useravatar',
    BADGE_ACHIEVEMENT: 'badge.achievement',
    BLOG_ENTRY: 'blog.entry',
    CELEBRATION_CONGRATULATIONFEBRUARY23: 'celebration.congratulationfebruary23',
    CELEBRATION_CONGRATULATIONMARCH8: 'celebration.congratulationmarch8',
    COMMENTS_COMMENT: 'comments.comment',
    COOLENDAR_MEETING: 'coolendar.meeting',
    EDU_TERMSTUDENT: 'edu.termstudent',
    FOLLOWING_RECOMMENDATION: 'following.followingrecommendation',
    IDEAS_TRACKER_IDEA: 'ideas_tracker.idea',
    LEARN_VIDEO: 'learn.video',
    LOCATION_FLOOR: 'location.floor',
    LOCATION_TOWN: 'location.town',
    MENTIONS_USERMENTION: 'mentions.usermention',
    PECOCRACY_STORY: 'pecocracy.pecocracystory',
    SKILLS_SKILLPROVED: 'skills.skillproved',
    SKILLS_SKILLTOOWNER: 'skills.skilltoowner',
    SERVICE_SINCERE_ENTRY: 'service_sincere.servicesincereentry',
    STRUCTURE_UNIT: 'structure.unit',
    STRUCTURE_PROJECT_STRUCTURAL: 'structure.projectstructural',
    TIMELINE_TIMELINEPOST: 'timeline.timelinepost',
    THANKS_THANKS: 'thanks.thanks',
    QUIZ_QUIZ: 'quiz.quiz',
    USERS_GROUP: 'users.group',
    USERS_USER: 'users.user',
    USERS_USERPROFILE: 'users.userprofile',
    USERS_TRANSITION: 'users.transition',
};
