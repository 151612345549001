import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from '../../helpers/helpers';
import { transliterate } from '../../helpers/trasliterate';


const PERMIT_WAIT_TIMEOUT = 50;

export class PermitCodeOnBody extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        clearInputTimeout: PropTypes.number,
    };

    static defaultProps = {
        clearInputTimeout: PERMIT_WAIT_TIMEOUT,
    };

    state = { cardCode: '' };

    componentDidMount() {
        document.body.addEventListener('keyup', this.bodyKeyupEvent);
        this.handleCodeEnterDebounced = debounce(() => {
            const { cardCode } = this.state;
            this.setState(
                { cardCode: '' },
                () => this.props.onChange(transliterate(cardCode)),
            );
        }, this.props.clearInputTimeout);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keyup', this.bodyKeyupEvent);
        this.handleCodeEnterDebounced = null;
    }

    bodyKeyupEvent = (e) => {
        if (e.which !== 0) {
            this.setState(prevState => ({ cardCode: prevState.cardCode + String.fromCharCode(e.which) }));
            this.handleCodeEnterDebounced();
        }
    };

    render() {
        return null;
    }
}
