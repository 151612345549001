import moment from 'moment';

import {
    CALENDAR_BELL_SUCCESS,
    CALENDAR_LOAD_SUCCESS,
    CENTRIFUGE_DELETE,
    CENTRIFUGE_EDIT,
    CENTRIFUGE_JOIN,
    CENTRIFUGE_LEAVE,
} from '../constants/actions';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';

const combineMeetingEventData = (meetingData, eventData) => {
    const {
        id: meetingId,
        events,
        userSpecific,
        ...meetingRest
    } = objCamelFromSnake(meetingData);

    const {
        id: eventId,
        ...eventRest
    } = objCamelFromSnake(eventData);
    return {
        ...meetingRest,
        ...eventRest,
        ...userSpecific,
        meetingId,
        id: eventId,
    };
};

const eventMap = (state = new Map(), action) => {
    switch (action.type) {
        case (CALENDAR_LOAD_SUCCESS): {
            const meetings = action.payload;
            const newEventMap = new Map(state);
            meetings.forEach((meeting) => {
                meeting.events.forEach((event) => {
                    const newEventData = combineMeetingEventData(meeting, event);
                    newEventMap.set(newEventData.id, newEventData);
                });
            });
            return newEventMap;
        }
        case (CENTRIFUGE_JOIN): {
            const user = action.payload.user;
            const eventsData = action.payload.messageData;
            const newEventMap = new Map(state);
            eventsData.forEach((event) => {
                if (newEventMap.has(event.id)) {
                    const joinedEvent = { ...newEventMap.get(event.id) };
                    if (!joinedEvent.updateTime || joinedEvent.updateTime < event.updateTime) {
                        joinedEvent.capacity = event.capacity;
                        joinedEvent.updateTime = event.updateTime;
                        joinedEvent.participantsCount = event.participantsCount;
                        joinedEvent.joined = event.userId === user.value ? true : joinedEvent.joined;
                        newEventMap.set(joinedEvent.id, joinedEvent);
                    }
                }
            });
            return newEventMap;
        }
        case (CENTRIFUGE_EDIT): {
            const meetings = action.payload.messageData;
            const newEventMap = new Map(state);
            meetings.forEach((meeting) => {
                meeting.events.forEach((event) => {
                    const oldEvent = newEventMap.get(event.id);
                    const newEventData = {
                        ...oldEvent,
                        ...combineMeetingEventData(meeting, event),
                    };
                    newEventMap.set(newEventData.id, newEventData);
                });
            });
            return newEventMap;
        }
        case (CENTRIFUGE_DELETE): {
            const eventsData = action.payload.messageData;
            const newEventMap = new Map(state);
            eventsData.forEach((event) => {
                newEventMap.delete(event.id);
            });
            return newEventMap;
        }
        case (CENTRIFUGE_LEAVE): {
            const user = action.payload.user;
            const eventsData = action.payload.messageData;
            const newEventMap = new Map(state);
            eventsData.forEach((event) => {
                if (newEventMap.has(event.id)) {
                    const leavedEvent = { ...state.get(event.id) };
                    if (!leavedEvent.updateTime || leavedEvent.updateTime < event.updateTime) {
                        leavedEvent.capacity = event.capacity;
                        leavedEvent.updateTime = event.updateTime;
                        leavedEvent.participantsCount = event.participantsCount;
                        leavedEvent.joined = event.userId === user.value ? false : leavedEvent.joined;
                        newEventMap.set(leavedEvent.id, leavedEvent);
                    }
                }
            });
            return newEventMap;
        }
        case (CALENDAR_BELL_SUCCESS): {
            const { isSubscribeAction } = action.additionalData;
            const data = objCamelFromSnake(action.payload);
            let newEventMap;
            data.eventIds.forEach((eventId) => {
                if (state.has(eventId)) {
                    newEventMap = newEventMap || new Map(state);
                    newEventMap.set(eventId, {
                        ...state.get(eventId),
                        subscribed: isSubscribeAction, // is want button available
                    });
                }
            });
            return newEventMap || state;
        }
        default:
            return state;
    }
};

export default eventMap;

function groupEventsByDate(events) {
    const dateMap = Object.create(null);
    const eventDateLabel = (event) => {
        const startDate = moment(event.startTime).startOf('day');
        startDate.locale('ru');
        return `${startDate.format('D MMMM (ddd.)')}${startDate.year() !== moment().year() ? startDate.format(' YYYY') : ''}`;
    };
    for (const event of events) {
        const eventLabel = eventDateLabel(event);
        if (!dateMap[eventLabel]) {
            dateMap[eventLabel] = [];
        }
        dateMap[eventLabel].push(event);
    }
    return dateMap;
}

export function groupEventsByName(events) {
    const nameMap = Object.create(null);
    for (const event of events) {
        if (!nameMap[event.name]) {
            nameMap[event.name] = [];
        }
        nameMap[event.name].push(event);
    }
    return nameMap;
}

export const getEvents = state => Array.from(state.values());

export const getColumnGroups = (eventsList, columnMaxElements, showEventLabel) => {
    /*
    * break down events by columns
    * each new date is new column
    * each column contains <= columnMaxElements elements
    *
    * each columnGroup is object with keys of 'title' and 'eventsGroup'
    * if column contains first date ocuurance its 'title' is date representation
    * if current date where already presented in previuos column, 'title' is empty
    * */
    const eventMapToArr = obj => Object.entries(obj).map(
        ([entryLabel, entryEvents]) => ({
            title: entryLabel,
            events: entryEvents,
        }),
    );
    const comparator = getter => ((a, b) => {
        if (getter(a) > getter(b)) {
            return 1;
        } else if (getter(a) < getter(b)) {
            return -1;
        }
        return 0;
    });
    const titleComparator = comparator(el => el.title);
    const startComparator = comparator(el => el.startTime);
    const idComparator = comparator(el => el.id);

    return eventMapToArr(
        groupEventsByDate(eventsList.sort((a, b) => {
            const result = startComparator(a, b);
            if (result === 0) {
                return idComparator(a, b);
            }
            return result;
        })),
    ).map(
        dayGroup => ({
            title: dayGroup.title,
            eventsGroup: eventMapToArr(
                groupEventsByName(dayGroup.events),
            ).sort(titleComparator),
        }),
    ).map((dayColumnGroup) => {
        const columnGroups = [];
        const TITLE_WEIGHT = showEventLabel ? 1 : 0;

        let columnFree = columnMaxElements;
        let columnGroup = {
            title: dayColumnGroup.title,
            eventsGroup: [],
        };

        for (const eventGroup of dayColumnGroup.eventsGroup) {
            const groupWeight = TITLE_WEIGHT + eventGroup.events.length;

            if (columnFree - groupWeight < 0) {
                let events = eventGroup.events;
                let isLabelPutted = false;
                const getGroupLabel = () => {
                    if (isLabelPutted) {
                        return '';
                    }
                    isLabelPutted = true;
                    return eventGroup.title;
                };

                if (columnFree > TITLE_WEIGHT) {
                    events = events.slice();
                    columnGroup.eventsGroup.push({
                        title: getGroupLabel(),
                        events: events.slice(0, columnFree - TITLE_WEIGHT),
                    });
                    events = events.slice(columnFree - TITLE_WEIGHT);
                }

                columnGroups.push(columnGroup);

                while (events.length + TITLE_WEIGHT > columnMaxElements) {
                    columnGroups.push({
                        title: '',
                        eventsGroup: [{
                            title: getGroupLabel(),
                            events: events.slice(0, columnMaxElements - TITLE_WEIGHT),
                        }],
                    });
                    events = events.slice(columnMaxElements - TITLE_WEIGHT);
                }

                columnFree = columnMaxElements - (events.length + TITLE_WEIGHT);
                columnGroup = {
                    title: '',
                    eventsGroup: [{
                        title: getGroupLabel(),
                        events,
                    }],
                };
            } else {
                columnGroup.eventsGroup.push(eventGroup);
                columnFree -= groupWeight;
            }
        }

        if (columnGroup.eventsGroup.length) {
            columnGroups.push(columnGroup);
        }

        return columnGroups;
    }).reduceRight(
        (previousValue, currentValue) => currentValue.concat(previousValue),
    );
};

