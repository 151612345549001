import { dictionary } from '@/common/localization/dictionary';

export const moduleName = 'micropost';
export const micropostEditTimeout = 60 * 60 * 24 * 1000; // one day
export const enterKeyCode = 13;
export const permissions = [
    'timeline.can_view_timeline_micropost_form',
    'timeline.can_view_user_timeline_micropost_form',
    'timeline.can_edit_any_micropost',
    'stats.can_view_statistic',
];
export const visibilityOptions = {
    all: dictionary.forAll,
    subscribers: dictionary.forSubscribers,
};

export const visibilityOptionsHelp = {
    all: 'Пост увидят сотрудники, выбравшие «все микропосты» в настройках ленты',
    subscribers: 'Твой пост попадет только в ленты твоих подписчиков, но по-прежнему будет доступен в твоем профиле',
};
