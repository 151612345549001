import React from 'react';
import { TextField } from 'intdev-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { commonMedia } from '@/common/media';


class FreeAnswerQuestion extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        question: PropTypes.shape({
            id: PropTypes.number,
        }).isRequired,
    };

    handleChange = (event, value) => {
        this.props.onChange(event, { text: value });
    };

    render() {
        return (
            <div>
                <TextField
                    multiLine
                    fullWidth
                    rows={ 6 }
                    onChange={ this.handleChange }
                    value={ this.props.value }
                    name={ `question${this.props.question.id}` }
                    hintStyle={ commonMedia.textFieldHintStyle }
                    inputStyle={ commonMedia.fontSizeBody }
                />
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id] || {};
    return {
        value: answer.text || '',
    };
};

export default connect(
    mapStateToProps,
)(FreeAnswerQuestion);
