import {
    CALENDAR_LOAD_FAILURE,
    CALENDAR_LOAD_START,
    CALENDAR_LOAD_SUCCESS,
} from '../constants/actions';


const defaultStatus = {
    forwardEmpty: false,
    backwardEmpty: false,
    maxEventStart: null,
    minEventStart: null,
    loadingForward: false,
    loadingBackward: false,
};

export const getLoadState = (state, gridGroupName) => ({ ...(state[gridGroupName] || defaultStatus) });

const loadState = (state = {}, action) => {
    switch (action.type) {
        case (CALENDAR_LOAD_START): {
            const { isBackward, gridGroupName } = action.additionalData;
            return {
                ...state,
                [gridGroupName]: {
                    ...getLoadState(state, gridGroupName),
                    [`${isBackward ? 'loadingBackward' : 'loadingForward'}`]: true,
                },

            };
        }
        case (CALENDAR_LOAD_FAILURE): {
            const { isBackward, gridGroupName } = action.additionalData;
            return {
                ...state,
                [gridGroupName]: {
                    ...getLoadState(state, gridGroupName),
                    [`${isBackward ? 'loadingBackward' : 'loadingForward'}`]: false,
                },
            };
        }
        case (CALENDAR_LOAD_SUCCESS): {
            const data = action.payload;
            const { isBackward, gridGroupName, isGroupEventsByName } = action.additionalData;

            const isEmptyKey = `${isBackward ? 'backwardEmpty' : 'forwardEmpty'}`;
            const newGroupStates = {};
            // update group maxEventStart, minEventStart, 'isEmptyKey'
            // if isGroupEventsByName enabled, update those attrs for each group
            if (!newGroupStates[gridGroupName]) {
                newGroupStates[gridGroupName] = getLoadState(state, gridGroupName);
            }
            const groupState = newGroupStates[gridGroupName];
            groupState[isEmptyKey] = true;

            data.forEach((meeting) => {
                // for each unique name, get nameGroup loadState
                let eventGroupState = null;
                if (isGroupEventsByName && meeting.name !== gridGroupName) {
                    if (!newGroupStates[meeting.name]) {
                        newGroupStates[meeting.name] = getLoadState(state, meeting.name);
                    }
                    eventGroupState = newGroupStates[meeting.name];
                }

                meeting.events.forEach((event) => {
                    const eventStartDate = new Date(event.start_time);

                    groupState[isEmptyKey] = false;

                    if (eventStartDate > groupState.maxEventStart || groupState.maxEventStart === null) {
                        groupState.maxEventStart = eventStartDate;
                    }
                    if (eventStartDate < groupState.minEventStart || groupState.minEventStart === null) {
                        groupState.minEventStart = eventStartDate;
                    }

                    if (eventGroupState) {
                        eventGroupState[isEmptyKey] = false;

                        if (eventStartDate > eventGroupState.maxEventStart || eventGroupState.maxEventStart === null) {
                            eventGroupState.maxEventStart = eventStartDate;
                        }
                        if (eventStartDate < eventGroupState.minEventStart || eventGroupState.minEventStart === null) {
                            eventGroupState.minEventStart = eventStartDate;
                        }
                    }
                });
            });

            return {
                ...state,
                ...newGroupStates,
                [gridGroupName]: {
                    ...(newGroupStates[gridGroupName] || getLoadState(state, gridGroupName)),
                    [`${isBackward ? 'loadingBackward' : 'loadingForward'}`]: false,
                },
            };
        }
        default:
            return state;
    }
};

export default loadState;
