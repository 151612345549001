/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobile } from '@/common/mediaQueries';
import { IdeaRating } from '@/ideas_pek/components/IdeaRating';
import LikesBlock from '@/likes/LikesBlock';
import { Record as BasicRecord } from './Record';

export class IdeaRecord extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        text: PropTypes.string,
        image: PropTypes.string,
        coins: PropTypes.number,
        closed: PropTypes.bool,
        likes_content_type_id: PropTypes.number,
        likes_object_id: PropTypes.number,
        is_liked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        like_count: PropTypes.shape({}),
        rating: PropTypes.number.isRequired,
        plus: PropTypes.number.isRequired,
        unique_user_id: PropTypes.string.isRequired,
        user_vote: PropTypes.number,
        ideaInfoBlock: PropTypes.node,
        authUserData: PropTypes.shape({ id: PropTypes.number }).isRequired,
        userData: PropTypes.shape({ id: PropTypes.number }).isRequired,
        color_status: PropTypes.shape({
            status: PropTypes.string,
            helps: PropTypes.string,
        }),
        canChangeStatus: PropTypes.bool,
        canChangeDate: PropTypes.bool,
        canChangeResponsible: PropTypes.bool,
        is_franchisee: PropTypes.bool,
        is_vote_active: PropTypes.bool,
        author_duties: PropTypes.arrayOf(PropTypes.shape({})),
        categories: PropTypes.arrayOf(PropTypes.shape({})),
        backlog_status: PropTypes.shape({
            label: PropTypes.string,
        }),
        solved_problem: PropTypes.string,
        note: PropTypes.string,
        planed_end_prioritization: PropTypes.string,
    };

    static defaultProps = {
        text: null,
        image: null,
        coins: null,
        closed: false,
        likes_content_type_id: null,
        likes_object_id: null,
        like_count: {},
        user_vote: null,
        ideaInfoBlock: null,
        color_status: {},
        canChangeStatus: false,
        canChangeDate: false,
        canChangeResponsible: false,
        is_franchisee: false,
        is_vote_active: false,
        author_duties: [],
        categories: [],
        solved_problem: null,
        backlog_status: null,
        planed_end_prioritization: null,
        note: '',
    };

    render = () => {
        const {
            id,
            text,
            closed,
            ideaInfoBlock,
            likes_content_type_id,
            likes_object_id,
            like_count,
            is_liked,
            authUserData,
            userData,
            color_status: colorStatus,
            canChangeStatus,
            canChangeDate,
            canChangeResponsible,
        } = this.props;

        const isSelfProfile = authUserData.id === userData.id;
        const hasAtLeastOnePerm = canChangeStatus || canChangeDate || canChangeResponsible;
        const ratingBlock = (!closed) ? (
            <>
                <IdeaRating
                    id={ id }
                    show={ false }
                    rating={ this.props.rating }
                    plus={ this.props.plus }
                    ownVote={ this.props.user_vote || 0 }
                    uniqueUserId={ this.props.unique_user_id }
                    canSeeRatingBeforeVote={ isSelfProfile }
                    isFranchiseeIdea={ this.props.is_franchisee }
                    isVoteActive={ this.props.is_vote_active }
                />
                { colorStatus.helps && colorStatus.status && hasAtLeastOnePerm && (
                    <div
                        title={ colorStatus.helps }
                        className={ `status_container ${colorStatus.status}_status_container` }
                    />
                ) }
            </>
        ) : null;

        const hasOfficialResponse = likes_content_type_id !== null;
        const likesBlock = (closed && hasOfficialResponse) ? (
            <LikesBlock
                contentTypeId={ likes_content_type_id }
                objectId={ likes_object_id }
                count={ like_count }
                isLiked={ is_liked }
                style={ { marginRight: 10 } }
                inTimeline
            />
        ) : null;

        const recordBody = (
            <div className="timeline-text">
                { isMobile && ratingBlock }
                { ideaInfoBlock }
                <div
                    className={ classnames({
                        'text-container': true,
                        'js-post_content_container': true,
                        post_content_container: true,
                    }) }
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: this.props.text } }
                />
            </div>
        );

        return (
            <BasicRecord
                { ...this.props }
                recordBody={ recordBody }
                sidePanelSecondaryBlock={ !isMobile && ratingBlock }
                hideLikes={ !text }
                hide_comments={ closed }
                likesBlock={ likesBlock }
                is_private={ false }
                showContentSettings={ false }
                type="идею"
            />
        );
    };
}
