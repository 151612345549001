export const apiUrls = {
    getRewardTypes: '/store/types/',
    getCommonGoods: (sorting = 'sorting_key') => `/store/goods/common/?order_by=${sorting}`,
    getUserGoods: '/store/goods/user/',
    getFilialGoods: (sorting = 'sorting_key') => `/store/goods/filial/?order_by=${sorting}`,
    getTeamGoods: (sorting = 'sorting_key') => `/store/goods/team_goods/?order_by=${sorting}`,
    getPurchaseFilters: tabName => `/store/goods/filters/?tab=${tabName}`,
    getAllOperations: '/store/operations/all/',
    getRecentOperations: '/store/operations/5/',
    getLotteryWins: '/store/lottery/wins/',
    getTopUsers: '/store/top/',
    getPurchases: '/store/purchases/',
    getUserWallet: '/store/wallet/',
    makePurchase: '/store/purchase/make/',
    cancelPurchase: '/store/purchase/cancel/',
    clearCart: '/store/cart/clear/',
    makeSpecialPurchase: '/store/special_purchase/make/',
    getAdminSalesList: (userId = '', page, ordering, isUserGoodsTable) => {
        const params = [];
        params.push({ key: 'ordering', value: ordering });
        if (page) params.push({ key: 'page', value: page });
        if (userId) params.push({ key: 'user', value: userId });
        if (isUserGoodsTable) params.push({ key: 'user_goods', value: true });
        const query = params.map(({ key, value }) => `${key}=${value}`).join('&');
        return `/store/admin/orders/?${query}`;
    },
    getUserSalesList: page => `/store/sales/orders/${page ? `&page=${page}` : ''}`,
    storeStatisticsData: '/store/admin/statistics/data/',
    confirmPurchase: '/store/purchase/confirm/', // в дальнейшем удалить
    deletePurchase: '/store/purchase/cancel/', // в дальнейшем удалить
    getAdminPurchasesFilters: '/store/admin/filters/',
    getTransitions: lastId => `/store/transitions/?last_id=${lastId}`,
    goodsAlbum: goodsId => `/store/goods/${goodsId}/album/`,
    getOrderingOffices: '/store/ordering/offices/',
    payForTheOrder: '/store/purchase/paid/',
    getMyActualOrders: page => `/store/purchase/orders/?status=2&paginate_by=5${page ? `&page=${page}` : ''}`,
    getMyFinishedOrders: page => `/store/purchase/orders/?status=5&paginate_by=5${page ? `&page=${page}` : ''}`,
    getUserInfo: username => `/users/${username}/contacts/`,
    loadSubscription: '/store/goods/subscription/',
    toggleSubscription: '/store/goods/subscribe/',
    submitUserGoodsForm: params => `/store/goods/submit/${params}`,
    submitUserGoodsEditForm: goodsId => `/store/goods/${goodsId}/submit/`,
    editUserGoodsData: goodsId => `/store/goods/${goodsId}/edit/data/`,
    declineUserGoods: goodsId => `/store/goods/${goodsId}/decline/`,
    userGoodsData: username => `/store/${username}/goods/json/`,
    userGoodsDetailDataForDelay: goodsId => `/store/goods/${goodsId}/detail/json/for_delay/`,
    getUserPurchaseRequests: '/store/goods/purchase_requests/',
    deleteUserGoods: goodsId => `/store/goods/${goodsId}/delete/`,
    getUserGoodsAttachments: (ctypeId, objectId) => `/store/goods/attached/${ctypeId}/${objectId}/`,
    unattachGoods: (ctypeId, objectId, goodsId) => `/store/goods/unattach/${ctypeId}/${objectId}/${goodsId}/`,
    getModerationGoods: '/store/admin/get_moderation_goods/',
    delayGoodsDelete: goodsId => `/store/goods/${goodsId}/delay/`,
    goodsDetailData: goodsId => `/store/goods/${goodsId}/detail/json/`,
    specialGoodsDetailData: goodsId => `/store/goods/special/${goodsId}/detail/json/`,
    rewardDetailData: rewardId => `/store/types/${rewardId}/detail/json/`,
    sendTransitionReport: (dateStart, dateEnd) => `/store/transition_report/?start_date=${dateStart ? dateStart.toISOString() : ''}&end_date=${dateEnd ? dateEnd.toISOString() : ''}`,
    sendWalletReport: '/store/wallet_report/',
    donatePostAuthor: '/store/donate_post_author/',
    cancelDonation: '/store/cancel_donation/',
    fetchTransitionsForPost: postId => `/store/fetch_transitions_for_post/${postId}/`,
    loadPermissions: permissionList => `/utils/load-permissions/?perm=${permissionList.join(',')}`,
    loadSelfProfileUrl: '/users/self/',
    attachAlbum: (objectId, ctype) => `/albums/cloud/attach/${ctype}/${objectId}/`,
    confirmOrder: id => `/store/purchase/orders/${id}/complete/`,
    deleteOrder: id => `/store/purchase/orders/${id}/cancel/`,
    deleteGoodsFromOrder: (orderId, goodsId) => `/store/purchase/orders/${orderId}/cancel/${goodsId}/`,
};
