import update from 'immutability-helper';
import { ACTION_TYPES } from '../constants/actionTypes';
import { generateUuid } from '../../common/uuid';

const defaultState = {
    photos: {},
    isLoaded: false,
};


export const albumPhoto = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOAD_PHOTOS_SUCCESS: {
            const requestPhotos = {};
            action.payload.photos.forEach((photo) => {
                requestPhotos[photo.photoId] = {
                    ...photo,
                    album: photo.album,
                    photoId: photo.photoId,
                    imId: photo.imId,
                    big: photo.big,
                    original: photo.original,
                    thumb: photo.thumb,
                    isLiked: photo.is_liked,
                    countLikes: photo.like_count,
                    countComments: photo.comments_count,
                    users: photo.users,
                    exifCreated: photo.exifCreated,
                    isDeleted: photo.isDeleted,
                    uuid: generateUuid(),
                };
            });
            return update(state, {
                photos: { $merge: requestPhotos },
                isLoaded: { $set: true },
            });
        }
        case ACTION_TYPES.UPDATE_LIKES: {
            if (action.payload.content_type_id === state.contentType) {
                return {
                    ...state,
                    avatars: state.avatars.map((avatar) => {
                        if (avatar.id === action.payload.object_id) {
                            return {
                                ...avatar,
                                like_count: action.payload.count,
                                countLikes: action.payload.count,
                                is_liked: action.payload.is_liked,
                                isLiked: action.payload.is_liked,
                            };
                        }
                        return avatar;
                    }),
                };
            }
            return state;
        }

        default:
            return state;
    }
};
