import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import moment from 'moment';
import { isMobile } from '@/common/mediaQueries';
import pluralized from '../../../common/helpers/pluralized';
import { Plus } from '../../../user_detail/components/icons/Plus';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer';
import { CentriSubscribe } from '../../../common/components/CentriSubscribe';
import { showErrorNotification } from '../../../common/helpers/showNotification';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { userPropTypes, skillPropTypes } from './propTypes';
import { Settings } from '../Settings';
import './styles.css';
import { media } from '../../media';
import { dictionary } from '@/common/localization/dictionary';

const styles = {
    ownerAvatar: avatarURL => ({
        backgroundImage: `url(${avatarURL})`,
    }),
};

export class AggregatedSkillsProves extends React.Component {
    /**
     * @param { is_added } приходит с бэка, но отвечает за абсолютно разные модели
     * при true -- на бэке используется модель SkillToOwner, означающая, что скилл был добавлен
     * false -- модель SkillProved, означающая что пользователь подтвердил навык
     */
    static propTypes = {
        objectList: PropTypes.arrayOf(PropTypes.shape({
            skill: skillPropTypes.isRequired,
            approver: userPropTypes.isRequired,
            provers: PropTypes.arrayOf(PropTypes.number).isRequired,
        })).isRequired,
        record_id: PropTypes.number.isRequired,
        content_type_id: PropTypes.number.isRequired,
        owner: userPropTypes.isRequired,
        date: PropTypes.string.isRequired,
        authUserData: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        userData: PropTypes.shape().isRequired,
        is_added: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = this.props.objectList.reduce((aggregatedBySkill, record) => {
            const newObj = {
                ...record,
                newProvers: (
                    aggregatedBySkill[+record.skill_to_owner_id]
                        ? aggregatedBySkill[+record.skill_to_owner_id].newProvers : []
                ).concat(record.approver),
                canBeProved: !record.provers.includes(+this.props.authUserData.id)
                    && record.owner.id !== this.props.authUserData.id,
            };
            // eslint-disable-next-line no-param-reassign
            aggregatedBySkill[+record.skill_to_owner_id] = newObj;
            return aggregatedBySkill;
        }, Object.create(null));
    }

    getSkillState = (record) => {
        const {
            owner,
            authUserData,
        } = this.props;

        // Если ты можешь подтвердить навык
        if (record.canBeProved) {
            return (
                <div className="aggregated-skills__plus-icon">
                    <Plus
                        style={ media.svgIconPlusSize }
                        className="aggregated-skills__icon"
                        onClick={ () => this.handleEndorseSkill(record) }
                    />
                </div>
            );
        }

        // Если это твои навыки
        if (owner.id === authUserData.id) {
            const proveCount = this.props.objectList.find(skill => skill.id === record.id).provers.length;
            return (
                <div
                    className="aggregated-skills__provers"
                    title={ `Этот навык подтвердили ${proveCount} ${pluralized(proveCount, 'раз:раз:раза')}` }
                >
                    <span className="aggregated-skills__provers-count">
                        { proveCount }
                    </span>
                </div>
            );
        }

        // Если ты уже подтверждал этот навык
        if (record.provers.includes(authUserData.id)) {
            return (
                <div className="aggregated-skills__done-icon" title="Ты уже проголосовал за этот навык">
                    <span className="icon icon_status--done_outline" />
                </div>
            );
        }

        return null;
    }

    get content() {
        return (
            <div className="aggregated-skills__flex">
                {
                    Object.values(this.state).map(record => (
                        <div className="aggregated-skills__skill-container" key={ record.skill.id }>
                            { this.renderProvers(record.newProvers) }
                            <div className="aggregated-skills__skill-name">
                                <A
                                    target="_blank"
                                    href={ record.skill.url }
                                >
                                    { record.skill.name }
                                </A>
                            </div>
                            { this.getSkillState(record) }
                        </div>
                    ))
                }
            </div>
        );
    }

    get title() {
        const { is_added: isAdded } = this.props;
        let action = dictionary.confirmed;
        let pluralized = dictionary.skills;

        if (isAdded) action = 'добавили';
        if (Object.keys(this.state).length === 1) pluralized = 'навык';

        return `${action} ${pluralized}`;
    }

    handleCentrifuge = ({ skill_to_owner_id: skillToOwnerId }) => {
        if (skillToOwnerId && Object.keys(this.state).includes(skillToOwnerId.toString())) {
            this.setState(prevState => ({
                [skillToOwnerId]: {
                    ...prevState[skillToOwnerId],
                    canBeProved: false,
                },
            }));
        }
    }

    handleEndorseSkill = (record) => {
        const url = record.endorse_url;
        callApi(url, 'post')
            .then(() => {
                this.setState(prevState => ({
                    [record.skill_to_owner_id]: {
                        ...prevState[record.skill_to_owner_id],
                        provers: [...record.provers, this.props.authUserData.id],
                        canBeProved: false,
                    },
                }));
            })
            .catch((err) => {
                if (err && err.msg) showErrorNotification(err.msg);
                else showErrorNotification('Не удалось подтвердить навык.');
            });
    }

    renderProvers = provers => (
        <div className="aggregated-skills__aggregated-provers-container">
            {
                provers.map(prover => (
                    <UserTooltipContainer withName username={ prover.username } key={ prover.id }>
                        <A href={ prover.url } className="aggregated-skills__prover_item">
                            <img
                                className="aggregated-skills_avatar-img"
                                title={ prover.full_name }
                                src={ prover.square_avatar }
                            />
                        </A>
                    </UserTooltipContainer>
                ))
            }
        </div>
    )

    render() {
        const {
            owner,
            authUserData,
            record_id: recordId,
            content_type_id: contentTypeId,
            userData,
            date,
        } = this.props;

        const aggregatedSkillsFlexStart = (
            <div className="aggregated-skills__flex-start">
                <div style={ { display: 'flex' } }>
                    <UserTooltipContainer username={ owner.username }>
                        <A href={ owner.url } className="aggregated-skills__text">
                            { owner.first_name }
                        </A>
                    </UserTooltipContainer>
                    <div className="aggregated-skills__text">
                                &nbsp;
                        { this.title }
                    </div>
                </div>
                <Settings
                    objectId={ recordId }
                    contentType={ contentTypeId }
                    recordDate={ date }
                    authUserData={ authUserData }
                    userData={ userData }
                    showSettings
                >
                    <div className="aggregated-skills__date" title={ moment(this.props.date).format('YYYY.MM.DD HH:mm') }>
                        { moment(this.props.date).locale('ru').fromNow(false) }
                    </div>
                </Settings>
            </div>
        );

        return (
            <>
                <div className="aggregated-skills__plus-aggregated-container">
                    <div className="aggregated-skills-header">
                        <UserTooltipContainer username={ owner.username }>
                            <A href={ owner.url }>
                                <div style={ styles.ownerAvatar(owner.square_avatar) } className="aggregated_skills__user-avatar" />
                            </A>
                        </UserTooltipContainer>
                        { isMobile && aggregatedSkillsFlexStart }
                    </div>
                    <div className="aggregated-skills__approves-container">
                        { !isMobile && aggregatedSkillsFlexStart }
                        { this.content }
                    </div>
                </div>
                <CentriSubscribe
                    channel={ `skills#${this.props.authUserData.id}` }
                    onMessage={ this.handleCentrifuge }
                />
            </>
        );
    }
}
