import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../fonts/mrg-icons/mrg-icons.css';

export default class MrgIcon extends Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
    };

    render() {
        const { className, ...rest } = this.props;
        return <i className={ `icon ${className}` } { ...rest } />;
    }
}

/* examples:
<MrgIcon className="icon_status--done_big" />
*/
