import PropTypes from 'prop-types';

export const ConditionalWrapper = ({
    condition,
    wrapper,
    renderNull,
    children,
}) => {
    if (condition) return wrapper(children);
    if (renderNull) return null;
    return children;
};

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.func,
    renderNull: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

ConditionalWrapper.defaultProps = {
    wrapper: c => c,
    renderNull: false,
};
