import ACTION_TYPES from '../actionTypes';

export const process = (state = false, action) => {
    switch (action.type) {
        case ACTION_TYPES.COMMIT_ANSWERS_START:
        case ACTION_TYPES.GET_RESULTS_START:
        case ACTION_TYPES.API_CALL_START:
        case ACTION_TYPES.GET_STATISTIC_START:
        case ACTION_TYPES.QUIZZES_FIRST_LOAD_START:
        case ACTION_TYPES.QUIZ_BY_PASS_LOAD_START:
        case ACTION_TYPES.QUIZ_LOAD_START: {
            return true;
        }
        case ACTION_TYPES.SET_RESTRICT_FINISH:
        case ACTION_TYPES.SET_UNRESTRICT_FINISH:
        case ACTION_TYPES.SET_ACTIVE_FINISH:
        case ACTION_TYPES.SET_TO_ARCHIVE_FINISH:
        case ACTION_TYPES.PUBLISH_ON_MAIN_PAGE_FINISH:
        case ACTION_TYPES.UNPUBLISH_FROM_MAIN_PAGE_FINISH:
        case ACTION_TYPES.NOT_PUBLISH_ON_MAIN_PAGE_FINISH:
        case ACTION_TYPES.DELETE_FINISH:
        case ACTION_TYPES.UPDATE_VIEWERS_FINISH:
        case ACTION_TYPES.COMMIT_ANSWERS_FINISH:
        case ACTION_TYPES.QUIZ_LOAD_FINISH:
        case ACTION_TYPES.GET_RESULTS_FINISH:
        case ACTION_TYPES.QUIZZES_LOAD_FINISH:
        case ACTION_TYPES.GET_STATISTIC_FINISH:
        case ACTION_TYPES.QUIZ_LOAD_FAIL:
        case ACTION_TYPES.QUIZ_BY_PASS_LOAD_FINISH:
        case ACTION_TYPES.API_FAIL: {
            return false;
        }
        default: {
            return state;
        }
    }
};
