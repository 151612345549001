import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';


export default class CommentNotification extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        userName: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
    };

    render() {
        const id = this.props.id;
        const userName = this.props.userName;
        const action = this.props.action;
        return (
            <span style={ { color: 'white' } }>
                {userName}&nbsp;{action} <A style={ { color: 'white', textDecoration: 'underline' } } href={ `#comment${id}` }>комментарий</A>
            </span>
        );
    }
}
