import React from 'react';
import PropTypes from 'prop-types';
import IUIPropTypes from 'intdev-ui/lib/utils/propTypes';


const getStyle = (anotherStyle) => {
    const style = {
        fontSize: '13px',
        lineHeight: '15px',
        color: '#f44e4e',
        bottom: '-2px',
        position: 'relative',
    };
    return Object.assign(style, anotherStyle);
};

export class ValidationText extends React.Component {
    static propTypes = {
        validationText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        validationStyle: IUIPropTypes.style,
        className: PropTypes.string,
    };

    static defaultProps = {
        validationStyle: {},
        validationText: '',
        className: 'validation-text',
    };

    render() {
        const { className, validationText, validationStyle } = this.props;
        return !!validationText && (
            <div className={ className } style={ getStyle(validationStyle) }>
                { validationText }
            </div>
        );
    }
}
