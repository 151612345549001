/* eslint-disable react/no-find-dom-node */
import React from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { Paper, Spinner } from 'intdev-ui';
import { findDOMNode } from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { difference, intersection } from 'lodash-es';
import {
    getRandomInt,
    getRecordClassByContentType,
    joinSameRecords,
    personalAggregatedRecordClasses,
} from '@/timeline/utils';
import {
    updatePersistedState,
    getPersistedState,
    getDocumentHeight,
    restoreDocumentHeight,
    cleanupAfterRestoreDocumentHeight,
} from '@/timeline/helpers';
import { dictionary } from '@/common/localization/dictionary';
import { TimelineHeader } from './TimelineHeader';
import { SimpleDropdown } from './SimpleDropdown';
import getCentrifugeConnection from '../../centrifuge';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { UserAvatarRecord } from './UserAvatarRecord';
import { buildUrl } from '../../common/helpers/urlParams';
import {
    apiUrls, pagesUrls, CUSTOM_TAB_PREFIX, CUSTOM_TABS_TYPES, NEWS_BLOG_ID,
} from '../constants';
import { debounce } from '../../common/helpers/helpers';
import { headerHeight } from '../../base_page/constants/constants';
import { ErrorBoundary } from '../../common/components/ErrorBoundary';
import { dispatchMetricsEvent } from '../../common/helpers/metrics';
import withDefaultParams from '../../common/components/DefaultParams/components/withDefaultParams';
import { getHistory } from '../../spa/history';
import '../../../css/timeline.css';
import '../../../css/micropost.css';

const TIMELINE_EVENTS = 'events';
const TIMELINE_COMMENTS = 'comments';
const DELAY_DELTA = 150;
const DELAYS_RANGE = [0, 80];

const defaultQueryParams = [
    {
        getValue: item => (
            (item.user_subscribed_at)
                ? (new Date(item.user_subscribed_at)).getTime()
                : (new Date(item.date)).getTime()),
        name: 'from_dt',
    },
    {
        getValue: item => item.record_id,
        name: 'from_id',
    },
];

const getAllIndexes = (source, getValue, value, delta = 0, result = []) => {
    // function return all indexes of source array elements, which have getValue(elemnet) === value
    const index = source.map(item => getValue(item)).indexOf(value);
    if (index === -1) {
        return result;
    }
    result.push(index + delta);
    const newDelta = delta + index + 1;
    return getAllIndexes(source.slice(index + 1), getValue, value, newDelta, result);
};


export class Timeline extends React.Component {
    static propTypes = {
        userId: PropTypes.number,
        dataURL: PropTypes.string,
        shouldSubscribeCentrifuge: PropTypes.bool,
        queryParamsForScroll: PropTypes.arrayOf(PropTypes.shape({
            getValue: PropTypes.func,
            name: PropTypes.string,
        })),
        urlParams: PropTypes.arrayOf(PropTypes.shape({
            getValue: PropTypes.func,
            name: PropTypes.string,
        })),
        withPaper: PropTypes.bool,
        wrapperClassName: PropTypes.string,
        mainPageTimeline: PropTypes.bool,
        userProfileTimeline: PropTypes.bool,
        canDeleteAvatarRecords: PropTypes.bool,
        canEditAnyMicropost: PropTypes.bool,
        projectFeatures: PropTypes.shape({
            coins_system: PropTypes.bool,
        }),
        forbidAggregation: PropTypes.bool,
        canPinRecords: PropTypes.bool,
        setMicropostStatus: PropTypes.func,
        recordsWrapper: PropTypes.elementType,
    };

    static contextTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        themeVars: PropTypes.object.isRequired,
    };

    static defaultProps = {
        userId: null,
        dataURL: null,
        withPaper: false,
        wrapperClassName: '',
        shouldSubscribeCentrifuge: true,
        queryParamsForScroll: defaultQueryParams,
        mainPageTimeline: false,
        userProfileTimeline: false,
        canDeleteAvatarRecords: false,
        canEditAnyMicropost: false,
        urlParams: null,
        projectFeatures: {
            coins_system: false,
        },
        forbidAggregation: false,
        canPinRecords: false,
        setMicropostStatus: () => {},
        recordsWrapper: React.Fragment,
    };

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            authUserData: {},
            loaded: false,
            timelineHasEnded: false,
            curMode: this.props.mainPageTimeline ? null : TIMELINE_EVENTS,
            ctypes: [],
            ctype: undefined,
            customTabs: [],
            activeCustomTab: null,
        };
        this.renderedRecords = {};
        this.centrifugeSubscription = null;
        this.visibleRecords = new Set();
        this.debouncedUpdateVisibleRecords = debounce(this.updateVisibleRecords, 300);
        this.timelineRef = React.createRef();
        this.navigationAction = sessionStorage?.getItem('navigation');
    }

    componentDidMount() {
        const {
            savedState,
            visibleRecordsData,
            timelineTop,
        } = this.isStateRestored ? getPersistedState(this.getSessionStorageKey()) : {};

        if (this.navigationAction === 'RELOAD') {
            this.clearSessionStorage();
        }

        if (savedState) {
            this.setState(
                savedState,
                () => setTimeout(() => {
                    this.scrollToPreviouslyVisibleRecords(visibleRecordsData, timelineTop);
                }, 400),
            );
            this.changeMicropostStatus(savedState.activeCustomTab);
            this.subscribeCentrifuge(savedState.authUserData);
        } else {
            this.loadData();
            this.loadCTypes();

            if (this.props.mainPageTimeline) {
                this.loadCustomTabs();
            }
        }


        window.addEventListener('scroll', this.debouncedUpdateVisibleRecords);
        window.addEventListener('resize', this.debouncedUpdateVisibleRecords);
        if (this.isStateRestored) {
            const { documentHeight } = getPersistedState(this.getDocumentHeightSessionStorageKey());
            if (documentHeight) restoreDocumentHeight(documentHeight);
        }
        if (this.isStatePersisted) window.addEventListener('scroll', this.saveDocumentHeight);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            dataURL,
            urlParams,
        } = this.props;

        if (
            prevProps.dataURL !== dataURL
            || (urlParams && (!prevProps.urlParams || !equal(prevProps.urlParams, urlParams)))
        ) {
            this.loadData(undefined, true);
        }
        if (this.isStatePersisted) {
            if (!equal(this.state, prevState)) {
                updatePersistedState(
                    this.getSessionStorageKey(),
                    { savedState: this.state },
                    this.clearSessionStorage,
                );
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedUpdateVisibleRecords);
        window.removeEventListener('resize', this.debouncedUpdateVisibleRecords);
        if (this.centrifugeSubscription) {
            this.centrifugeSubscription.unsubscribe();
        }
        this.renderedRecords = Object.create(null);
        if (this.isStatePersisted) window.removeEventListener('scroll', this.saveDocumentHeight);
        if (this.isStateRestored) cleanupAfterRestoreDocumentHeight();
        this.props.setMicropostStatus(true);
    }

    get isPersonalMode() {
        const { mainPageTimeline } = this.props;
        return mainPageTimeline;
    }

    get timelineType() {
        return this.props.userId ? 'user-timeline-scroll' : 'timeline-scroll';
    }

    get isStatePersisted() {
        return (this.props.mainPageTimeline || this.props.userProfileTimeline);
    }

    get isStateRestored() {
        return (
            this.isStatePersisted
            && ['POP', 'BACK_FORWARD'].includes(this.navigationAction)
        );
    }

    get seeMoreLink() {
        if (this.state.activeCustomTab?.type === CUSTOM_TABS_TYPES.BLOG
            || this.state.activeCustomTab?.type === CUSTOM_TABS_TYPES.SINCERE_SERVICE
        ) {
            return (
                <div className="timeline-see-more-link">
                    <a href={ pagesUrls.blogUrl(this.state.activeCustomTab.blog) }>
                        Смотреть еще
                    </a>
                </div>
            );
        }
        return null;
    }

    getURL = (toDate, updateRecords, ctype) => {
        const records = updateRecords ? [] : this.state.records;
        let url = this.props.dataURL;
        if (!url) {
            if (this.props.userId) {
                url = `${apiUrls.userTimeline}${this.props.userId}/`;
            } else if (this.isPersonalMode && this.state.curMode === TIMELINE_EVENTS) {
                url = `${apiUrls.fullTimeline}`;
            } else if (this.state.curMode.includes(CUSTOM_TAB_PREFIX)) {
                url = `${apiUrls.fullTimeline}`;
            } else {
                url = `${apiUrls.fullTimeline}${this.state.curMode}/`;
            }
        }
        if (toDate) {
            return (`${url}?to_dt=${toDate}`);
        }
        const urlParams = {};
        if (this.props.mainPageTimeline && this.state.curMode.includes(CUSTOM_TAB_PREFIX)) {
            urlParams.tab_id = this.state.curMode.replace(CUSTOM_TAB_PREFIX, '');
        }
        if (records.length > 0) {
            const earliestRecord = records[records.length - 1];
            for (const param of this.props.queryParamsForScroll) {
                urlParams[param.name] = param.getValue(earliestRecord);
            }
        }
        if (ctype) {
            urlParams.content_types = ctype;
        }
        if (this.props.urlParams) {
            for (const param of this.props.urlParams) {
                let paramValues = urlParams[param.name] || [];
                if (!Array.isArray(paramValues)) {
                    paramValues = [paramValues];
                }
                paramValues.push(param.value);
                urlParams[param.name] = paramValues;
            }
        }
        return buildUrl(urlParams, url);
    };

    getSessionStorageKey = () => {
        const history = getHistory();
        const key = `tl-${history.location.pathname}-${history.location.key}`;
        return key;
    }

    getDocumentHeightSessionStorageKey = () => {
        const history = getHistory();
        return `tl-docHeight-${history.location.pathname}-${history.location.key}`;
    }

    clearSessionStorage = () => {
        for (const key of Object.keys(window.sessionStorage)) {
            if (key.startsWith('tl')) {
                sessionStorage.removeItem(key);
            }
        }
    }

    saveDocumentHeight = () => {
        const documentHeight = getDocumentHeight();
        updatePersistedState(
            this.getDocumentHeightSessionStorageKey(),
            { documentHeight },
            this.clearSessionStorage,
        );
    }

    getTimelineTop = () => {
        const timelineNode = this.props.withPaper
            ? findDOMNode(this.timelineRef.current)
            : this.timelineRef.current;
        if (timelineNode) {
            const timelineTop = timelineNode.getBoundingClientRect().top;
            return timelineTop + window.pageYOffset;
        }
        return 0;
    }

    scrollToPreviouslyVisibleRecords = (visibleRecordsData, timelineTop) => {
        if (!visibleRecordsData?.length) {
            return;
        }

        const lastVisibleRecord = visibleRecordsData.reduce(
            (acc, cur) => ((cur.top > acc.top) ? cur : acc),
            visibleRecordsData[0],
        );
        const { recordId } = lastVisibleRecord;
        const targetTop = Math.max(document.documentElement.clientHeight / 3, lastVisibleRecord.top);
        const lastVisibleRecordNode = findDOMNode(this.renderedRecords[recordId]);

        if (lastVisibleRecordNode) {
            const lastVisibleRecordNodeRect = lastVisibleRecordNode.getBoundingClientRect();
            const timelineShift = (timelineTop - this.getTimelineTop());
            const requiredScrollY = lastVisibleRecordNodeRect.top - targetTop + timelineShift;
            window.scrollTo(0, requiredScrollY);
        }
    }

    updateVisibleRecords = () => {
        this.visibleRecords.clear();
        const visibleRecordsData = [];
        const height = document.documentElement.clientHeight;
        Object.keys(this.renderedRecords).forEach((recordId) => {
            const node = findDOMNode(this.renderedRecords[recordId]);
            if (node) {
                const rect = node.getBoundingClientRect();
                if (rect.bottom > headerHeight && rect.top < height) {
                    this.visibleRecords.add(recordId);
                    visibleRecordsData.push({
                        recordId,
                        top: rect.top,
                        bottom: rect.bottom,
                    });
                }
            }
        });
        const timelineTop = this.getTimelineTop();
        updatePersistedState(
            this.getSessionStorageKey(),
            { visibleRecordsData, timelineTop },
            this.clearSessionStorage,
        );
    };

    updateRecord = (recordId, recordData) => {
        this.setState(prevState => ({
            records: prevState.records.map(record => (
                record.record_id === recordId
                    ? { ...record, ...recordData } : record
            )),
        }));
    };

    strToTime = str => (new Date(str)).getTime();

    subscribeCentrifuge = (authUserData) => {
        if (this.props.shouldSubscribeCentrifuge && !this.centrifugeSubscription) {
            let channel;
            if (this.props.userId) {
                if (this.props.userId === authUserData.id) {
                    channel = `personal#${authUserData.id}`;
                } else {
                    channel = `public${this.props.userId}`;
                }
            } else {
                channel = `timelines#${authUserData.id}`;
            }
            this.centrifugeSubscription = getCentrifugeConnection().subscribe(
                channel,
                this.handleCentrifuge,
            );
        }
    };

    getDelayForAttachmentsLoad = () => getRandomInt(...DELAYS_RANGE) * DELAY_DELTA;

    handleCentrifuge = (message) => {
        if (this.state.curMode !== TIMELINE_COMMENTS) {
            if (!this.isPersonalMode && message.data.for_personal_timeline) {
                return;
            }
            if (message.data.record_id && message.data.record_id in this.renderedRecords) {
                return;
            }
            if (this.state.ctype && message.data.content_type_name !== this.state.ctype.id) {
                return;
            }

            if (this.state.activeCustomTab) {
                const activeTab = this.state.activeCustomTab;
                const tabsABlogs = this.state.customTabs.reduce((blogIds, tab) => {
                    if (tab.type === CUSTOM_TABS_TYPES.BLOG || tab.type === CUSTOM_TABS_TYPES.SINCERE_SERVICE) {
                        return [...blogIds, tab.blog];
                    }
                    return blogIds;
                }, []);
                if (activeTab.type === CUSTOM_TABS_TYPES.BLOG) {
                    if (!message.data.blog_ids) {
                        return;
                    }
                    if (message.data.blog_ids
                      && !message.data.blog_ids.includes(activeTab.blog)) {
                        return;
                    }
                }
                if (activeTab.type === CUSTOM_TABS_TYPES.SINCERE_SERVICE) {
                    const isSincereServiceStory = message.data.content_type_name === 'service_sincere.servicesincereentry';
                    const isSincereServiceBlogEntry = (
                        message.data.content_type_name === 'blog.entry'
                        && message.data.blog_ids.includes(activeTab.blog)
                    );
                    if (!isSincereServiceStory && !isSincereServiceBlogEntry) {
                        return;
                    }
                }
                if (activeTab.type === CUSTOM_TABS_TYPES.CATEGORY) {
                    if (!message.data.blog_categories) {
                        return;
                    }
                    const isMessageFromTabCategory = intersection(message.data.blog_categories, activeTab.categories).length;
                    const messageBlogIdsFromATabs = intersection(tabsABlogs, message.data.blog_ids);
                    const messageBlogIdsNotFromATabs = difference(message.data.blog_ids, messageBlogIdsFromATabs);
                    if (!isMessageFromTabCategory
                      || !messageBlogIdsNotFromATabs.length) {
                        return;
                    }
                }
                if (activeTab.type === CUSTOM_TABS_TYPES.OTHER) {
                    if (message.data.blog_ids && message.data.blog_ids.includes(NEWS_BLOG_ID) && message.data.blog_ids.length === 1) {
                        return;
                    }
                }
            }
            let top;
            let node;
            for (const recordId of this.visibleRecords) {
                node = findDOMNode(this.renderedRecords[recordId]);
                if (node) {
                    top = node.getBoundingClientRect().top;
                }
                break;
            }
            const callback = node ? () => {
                const pageOffset = window.scrollY ? window.scrollY : window.pageYOffset;
                window.scrollTo(0, (pageOffset + node.getBoundingClientRect().top) - top);
            } : null;

            this.setState(prevState => ({
                records: [
                    ...prevState.records.filter(({ is_pinned: isPinned }) => !!isPinned),
                    {
                        attachmentsLoadDelay: this.getDelayForAttachmentsLoad(),
                        ...message.data,
                    },
                    ...prevState.records.filter(({ is_pinned: isPinned }) => !isPinned),
                ],
            }), callback);
        }
    };

    handleTimelineEnd = (isVisible) => {
        // strange behaviour of visibility sensor with display none
        // makes additional request on changing tab
        if (isVisible && this.state.loaded) {
            this.loadData();
        }
    };

    loadCustomTabs = async () => {
        const customTabs = await callApi(apiUrls.getTimeLineCustomTabs);
        const activeCustomTab = customTabs?.[0];
        this.setState({
            customTabs,
            activeCustomTab,
            curMode: `${CUSTOM_TAB_PREFIX}${activeCustomTab?.id}`,
            records: [],
            ctype: null,
        }, this.loadData);
        this.changeMicropostStatus(activeCustomTab);
    }

    loadData = (toDate, updateRecords) => {
        if (updateRecords) {
            this.setState({ records: [] });
        }
        let newRecords;
        if (!this.state.curMode) {
            return;
        }
        const dataUrl = this.getURL(toDate, updateRecords, this.state.ctype?.id);

        // nvm, just metrics things
        const urlWithParamsRegex = new RegExp('(\\w+|\\/)\\?\\-?\\w+');
        if (urlWithParamsRegex.test(dataUrl)) {
            dispatchMetricsEvent(this.timelineType);
        }

        this.setState({ loaded: false });
        callApi(dataUrl).then((data) => {
            if (!data.timeline) {
                this.setState({
                    timelineHasEnded: true,
                    loaded: true,
                });
                return;
            }
            newRecords = joinSameRecords(data.timeline, this.props.forbidAggregation);

            const state = {
                records: this.state.records.concat(newRecords),
                authUserData: data.authUserData,
                nextDataURL: data.next,
                loaded: true,
                timelineHasEnded: (newRecords.length === 0),
            };

            this.subscribeCentrifuge(data.authUserData);

            this.setState(state);
            // костыль. нужен для случаев, когда весь контент полностью влезает в экран
            if (this.sensorRef) this.sensorRef.setState({ isVisible: false });
            this.debouncedUpdateVisibleRecords();
        });
    };

    scrollToRecord = (recordId) => {
        const recordDomNode = findDOMNode(this.renderedRecords[recordId]);
        if (!recordDomNode || !recordDomNode.getBoundingClientRect) {
            return;
        }
        const pageOffset = window.scrollY ? window.scrollY : window.pageYOffset;
        const offset = recordDomNode.getBoundingClientRect().top + pageOffset;
        window.scrollTo(0, offset - headerHeight);
    };

    reloadData = (toId) => {
        let toDate;
        const result = this.state.records.filter(record => record.id === toId);
        if (result.length) {
            toDate = this.strToTime(result[0].date);
        }
        this.setState(
            { records: [] },
            () => this.loadData(toDate),
        );
    };

    loadCTypes = () => {
        callApi(apiUrls.cTypes).then((resp) => {
            this.setState({ ctypes: [{ id: undefined, name: dictionary.timeline }, ...resp.ctypes] });
        });
    };

    filterData = (filterFn) => {
        this.setState(prevState => ({
            records: prevState.records.filter(record => filterFn(record)),
        }));
    };

    changeMicropostStatus = (tab) => {
        if (tab) {
            this.props.setMicropostStatus(tab.type === CUSTOM_TABS_TYPES.OTHER);
        } else {
            this.props.setMicropostStatus(true);
        }
    }

    changeTab = (e, ctype) => {
        let tab = null;
        if (e.includes(CUSTOM_TAB_PREFIX)) {
            const tabId = Number(e.replace(CUSTOM_TAB_PREFIX, ''));
            tab = this.state.customTabs.find(item => item.id === tabId);
        }
        this.changeMicropostStatus(tab);
        this.setState({
            curMode: e,
            records: [],
            activeCustomTab: tab,
            ctype: ctype || null,
            loaded: false,
        }, this.loadData);
    };

    removeAllRecordsByUser = (userId) => {
        const removedIndexes = getAllIndexes(
            this.state.records,
            item => (item.userData && item.userData.id) || null,
            userId,
        );
        this.setState(
            prevState => ({ records: prevState.records.filter((item, index) => !removedIndexes.includes(index)) }),
        );
    };

    removeRecord = (recordId) => {
        this.setState(
            prevState => ({ records: prevState.records.filter(item => item.record_id !== recordId) }),
        );
    };

    getTabIdForPin = () => {
        if (this.state.activeCustomTab && this.state.activeCustomTab.type !== CUSTOM_TABS_TYPES.OTHER) {
            return this.state.activeCustomTab.id;
        }
        return null;
    }

    canPinInThisTab = () => !(this.state.activeCustomTab && this.state.activeCustomTab.type === CUSTOM_TABS_TYPES.OTHER)

    render() {
        const renderRecords = this.state.records.filter(record => !(record.is_removed));
        const WrapperElement = this.props.withPaper ? Paper : 'div';
        const RecordsWrapperElement = this.props.recordsWrapper;

        const loader = (
            <div className="timeline-loader-container">
                <Spinner size={ 32 } />
            </div>
        );

        this.renderedRecords = {};

        const records = renderRecords.map(
            (record) => {
                const personalAggregatedCt = record.personalAgreggatedContentType;
                const RecordClass = personalAggregatedCt
                    ? personalAggregatedRecordClasses[personalAggregatedCt]
                    : getRecordClassByContentType(record.content_type_name);
                return (
                    <ErrorBoundary key={ record.record_id } renderNull>
                        <RecordClass
                            filterData={ this.filterData }
                            reloadData={ this.reloadData }
                            canPersonalBlocking={ this.isPersonalMode }
                            scrollToRecord={ this.scrollToRecord }
                            authUserData={ this.state.authUserData }
                            ref={ (c) => { this.renderedRecords[record.record_id] = c; } }
                            removeAllRecordsByUser={ this.removeAllRecordsByUser }
                            removeRecord={ this.removeRecord }
                            updateRecord={ this.updateRecord }
                            showSettings={
                                !(this.props.dataURL || this.props.userId)
                            }
                            canDeleteAvatarRecords={
                                RecordClass === UserAvatarRecord
                                && this.props.canDeleteAvatarRecords
                            }
                            canEditAnyMicropost={ this.props.canEditAnyMicropost }
                            coinsSystemFeatureIsOn={ this.props.projectFeatures.coins_system }
                            canPinRecords={ this.props.mainPageTimeline && this.props.canPinRecords && this.canPinInThisTab() }
                            currentTabId={ this.getTabIdForPin() }
                            { ...record }
                        />
                    </ErrorBoundary>
                );
            },
        );

        let header = null;

        if (!((this.props.dataURL || this.props.userId))) {
            const tabs = this.state.customTabs.map(({ id, title, type }) => ({
                tabsKey: `${CUSTOM_TAB_PREFIX}${id}`,
                value: (type === CUSTOM_TABS_TYPES.OTHER)
                    ? (
                        <div className="timeline-tab-title">
                            { this.state.ctype?.name || title }
                            <SimpleDropdown
                                options={ this.state.ctypes.filter(ct => ct.id !== 'blog.entry') }
                                onChange={ ctype => this.changeTab(`${CUSTOM_TAB_PREFIX}${id}`, ctype) }
                            />
                        </div>
                    ) : title,
            }));

            if (tabs.length) {
                header = (
                    <TimelineHeader
                        tabs={ tabs }
                        curTabIndex={ this.state.curMode }
                        onTabClick={ e => this.changeTab(e) }
                    />
                );
            }
        }

        if (!renderRecords.length && this.state.loaded && this.state.timelineHasEnded) {
            return (
                <WrapperElement className={ this.props.wrapperClassName }>
                    { header }
                    <div className="timeline-empty-list-text">{ this.props.mainPageTimeline ? 'Нет данных. Проверьте настройки вашей ленты' : 'Нет записей' }</div>
                </WrapperElement>
            );
        }

        return (
            <WrapperElement className={ this.props.wrapperClassName } ref={ this.timelineRef }>
                { header }
                <RecordsWrapperElement>
                    { records.slice(0, records.length - 1) }
                    <VisibilitySensor
                        onChange={ this.handleTimelineEnd }
                        active={ !this.state.timelineHasEnded }
                        ref={ (ref) => { this.sensorRef = ref; } }
                    />
                    { records[records.length - 1] }
                    { !this.state.loaded && loader }
                    { this.state.loaded && this.state.timelineHasEnded && this.seeMoreLink }
                </RecordsWrapperElement>
            </WrapperElement>
        );
    }
}


export default withDefaultParams(Timeline, { features: ['coins_system'] });
