import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker, Select, TimePicker } from 'intdev-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import pluralized from '../../../common/helpers/pluralized';
import getValidationProps from '../../helpers/getValidationProps';
import { styles } from './EventEditModal';
import { getCopyPeriodDaysValues, getCopyPeriodMinutesValues, getModalData } from '../../reducers';
import { eventModalFieldValueChange } from '../../actions';
import formatDuration from '../../helpers/formatDuration';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';
import { getMinCreateTime } from '../../helpers/minCreateTime';

export class EventCopyPanelComponent extends React.Component {
    static propTypes = {
        // dispatch
        onFieldChange: PropTypes.func.isRequired,
        // from state
        minCreateHour: PropTypes.number.isRequired,
        maxCreateHour: PropTypes.number.isRequired,
        minEventDuration: PropTypes.number.isRequired,
        minuteStartStep: PropTypes.number.isRequired,
        copyPeriodMinutesValues: PropTypes.arrayOf(PropTypes.number).isRequired,
        copyPeriodDaysValues: PropTypes.arrayOf(PropTypes.number).isRequired,
        copyStartDate: PropTypes.instanceOf(Date).isRequired,
        copyEndDate: PropTypes.instanceOf(Date).isRequired,
        copyPeriodMinutes: PropTypes.number,
        copyPeriodDays: PropTypes.number,
        copyStartTime: PropTypes.string.isRequired,
        copyEndTime: PropTypes.string.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
        processing: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        copyPeriodMinutes: null,
        copyPeriodDays: null,
    };

    getCopyPeriodMinutesOptions = () => this.props.copyPeriodMinutesValues.map(i => (
        i === 0 ? {
            value: i,
            label: 'раз в день',
        } : {
            value: i,
            label: `${pluralized(i, 'каждые:каждую:каждую')} ${i === 1 ? '' : `${i} `}${pluralized(i, 'минут:минуту:минуту')}`,
        }));

    getCopyPeriodDaysOptions = () => this.props.copyPeriodDaysValues.map(i => ({
        value: i,
        label: `${pluralized(i, 'каждые:каждый:каждые')} ${i === 1 ? '' : `${i} `}
                ${pluralized(i, 'дней:день:дня')}`,
    }));

    render() {
        const todayStartDate = moment().startOf('day').toDate();
        const { minCreateHour, copyStartDate, minuteStartStep } = this.props;
        const minCreateTime = formatDuration(
            getMinCreateTime(minCreateHour, copyStartDate, minuteStartStep),
        );

        return (
            <div>
                <div style={ styles.fieldRow }>
                    <div style={ styles.fieldLabelDiv }>
                        <div style={ styles.fieldLabelInner }>Диапазон дат</div>
                    </div>
                    <div style={ styles.fieldInputBody }>
                        <DatePicker
                            manualInput
                            value={ this.props.copyStartDate }
                            name="copyStartDate"
                            onChange={ (e, newDate) => this.props.onFieldChange({
                                target: {
                                    value: newDate,
                                    name: 'copyStartDate',
                                },
                            }) }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            minDate={ todayStartDate }
                            { ...getValidationProps(this.props.validationErrors, 'copyStartDate') }
                            fullWidth
                        />
                        <div style={ styles.delimiterDiv }>-</div>
                        <DatePicker
                            manualInput
                            value={ this.props.copyEndDate }
                            name="copyEndDate"
                            onChange={ (e, newDate) => this.props.onFieldChange({
                                target: {
                                    value: newDate,
                                    name: 'copyEndDate',
                                },
                            }) }
                            minDate={ this.props.copyStartDate }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            { ...getValidationProps(this.props.validationErrors, 'copyEndDate') }
                            fullWidth
                        />
                    </div>
                </div>
                <div style={ styles.fieldRow }>
                    <div style={ styles.fieldLabelDiv }>
                        <div style={ styles.fieldLabelInner }>
                            Диапазон времени (время начала первой и последней встречи)
                        </div>
                    </div>
                    <div style={ styles.fieldInputBody }>
                        <TimePicker
                            value={ this.props.copyStartTime }
                            name="copyStartTime"
                            onChange={ this.props.onFieldChange }
                            minTime={ minCreateTime }
                            maxTime={ formatDuration(moment.duration(
                                moment(this.props.maxCreateHour, 'HH').diff(moment(this.props.minEventDuration, 'mm')),
                            )) }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            minutesStep={ this.props.minuteStartStep }
                            { ...getValidationProps(this.props.validationErrors, 'copyStartTime') }
                            showBoundValidation={ false }
                            showTimeUnits
                            fullWidth
                        />
                        <div style={ styles.delimiterDiv }>-</div>
                        <TimePicker
                            value={ this.props.copyEndTime }
                            name="copyEndTime"
                            onChange={ this.props.onFieldChange }
                            minTime={ this.props.copyStartTime }
                            maxTime={ formatDuration(moment.duration(
                                moment(this.props.maxCreateHour, 'HH').diff(moment(this.props.minEventDuration, 'mm')),
                            )) }
                            minutesStep={ this.props.minuteStartStep }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            { ...getValidationProps(this.props.validationErrors, 'copyEndTime') }
                            showTimeUnits
                            fullWidth
                        />
                    </div>
                </div>
                <div style={ styles.fieldRow }>
                    <div style={ styles.fieldLabelDiv }>
                        <div style={ styles.fieldLabelInner }>Повторять события</div>
                    </div>
                    <div style={ styles.fieldInputBody }>
                        <Select
                            value={ this.props.copyPeriodDays }
                            name="copyPeriodDays"
                            placeholder="каждые..."
                            onChange={ (option) => {
                                this.props.onFieldChange({
                                    target: {
                                        value: option ? option.value : null,
                                        name: 'copyPeriodDays',
                                    },
                                });
                            } }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            options={ this.getCopyPeriodDaysOptions(
                                this.props.copyStartDate,
                                this.props.copyEndDate,
                            ) }
                            { ...getValidationProps(this.props.validationErrors, 'copyPeriodDays') }
                            fullWidth
                        />
                        <div style={ styles.delimiterDiv } />
                        <Select
                            value={ this.props.copyPeriodMinutes }
                            name="copyPeriodMinutes"
                            placeholder="каждые..."
                            onChange={ (option) => {
                                this.props.onFieldChange({
                                    target: {
                                        value: option ? option.value : null,
                                        name: 'copyPeriodMinutes',
                                    },
                                });
                            } }
                            disabled={ this.props.processing }
                            style={ styles.fieldInnerInputBody }
                            options={ this.getCopyPeriodMinutesOptions(
                                this.props.copyStartTime,
                                this.props.copyEndTime,
                            ) }
                            { ...getValidationProps(this.props.validationErrors, 'copyPeriodMinutes') }
                            fullWidth
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...getModalData(state),
    copyPeriodMinutesValues: getCopyPeriodMinutesValues(state),
    copyPeriodDaysValues: getCopyPeriodDaysValues(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    onFieldChange: eventModalFieldValueChange,
}, dispatch);

const EventCopyPanel = connect(mapStateToProps, mapDispatchToProps)(EventCopyPanelComponent);

export default EventCopyPanel;
