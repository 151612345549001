export const DEFAULT_NOTIFICATIONS_PAGE_SIZE = 8;

export const NOTIFICATION_STATUSES = {
    STATUS_NEW: 0,
    STATUS_SEEN: 1,
    STATUS_READ: 2,
};

export const CONTENT_STATUSES = {
    f_0: { text: 'архив', className: 'icon-label default-label' },
    s_2: { text: 'рассматривается', className: 'icon-label warning-label' },
    s_3: { text: 'в работе', className: 'icon-label info-label' },
    s_4: { text: 'отвергнута', className: 'icon-label inverse-label' },
    s_5: { text: 'реализована', className: 'icon-label success-label' },
};

export const NOTIFICATION_UPSTREAM_TYPES = {
    ADD: 'add',
    UPDATE: 'update',
    REPLACE: 'replace',
};
