import React, { Component } from 'react';
import { Tooltip } from 'intdev-ui';
import PropTypes from 'prop-types';

import { HUMAN_EMOJI } from '../constants';

export default class EmojiTooltip extends Component {
    static propTypes = {
        isTooltipActive: PropTypes.bool.isRequired,
        onLike: PropTypes.func.isRequired,
        tooltipPosition: PropTypes.oneOf([
            'top_left', 'top_right', 'top_center', 'bottom_left', 'bottom_right', 'bottom_center',
        ]),
    };

    static defaultProps = {
        tooltipPosition: 'top_center',
    };

    render() {
        const { isTooltipActive, tooltipPosition } = this.props;

        return (
            <Tooltip active={ isTooltipActive } position={ tooltipPosition } className="likes-form-tooltip">
                <div className="like-emoji-form-content">
                    {
                        Object.keys(HUMAN_EMOJI).map(
                            emoji => (
                                <div
                                    key={ `like-emoji-${emoji}` }
                                    className="like-emoji-form-icon"
                                    onClick={ () => this.props.onLike(emoji) }
                                >
                                    <div className={ `like-emoji-text${HUMAN_EMOJI[emoji].long ? ' long' : ''}` }>
                                        { HUMAN_EMOJI[emoji].text }
                                    </div>
                                    <img src={ HUMAN_EMOJI[emoji].icon } alt={ HUMAN_EMOJI[emoji].text } />
                                </div>
                            ),
                        )
                    }
                </div>
            </Tooltip>
        );
    }
}
