import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Dialog, TextField, TooltipContainer, A,
} from 'intdev-ui';
import { objCamelFromSnake } from '@/common/helpers/objCamelFromSnake';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { FORMDATA } from '@/common/constants/ajaxBodyTypes';
import { showNotification } from '@/common/helpers/showNotification';
import { COMPETENCE_MIN_LENGTH } from '@/thanks/constants';
import { validateCompetenceDescription } from '@/thanks/utils';
import { SayThanksTextArea } from './SayThanksTextArea';
import '../../../css/thanks.css';
import { styles } from '../styles';
import classes from './ThanksDialog.scss';
import { urls } from '../apiUrls';

export class ThanksDialog extends React.Component {
    static propTypes = {
        userId: PropTypes.number,
        thanksId: PropTypes.number,
        canSendCoins: PropTypes.bool,
        openDialog: PropTypes.bool,
        onCloseDialog: PropTypes.func.isRequired,
        onEdit: PropTypes.func,
        editMode: PropTypes.bool,
        chosenThanksTypeId: PropTypes.number,
        thanksText: PropTypes.string,
    };

    static defaultProps = {
        userId: null,
        thanksId: null,
        canSendCoins: false,
        openDialog: false,
        onEdit: undefined,
        editMode: false,
        chosenThanksTypeId: null,
        thanksText: null,
    };

    state = {
        textAreaError: null,
        chosenType: {},
        types: [],
        amountValue: '',
        coinsValidationText: '',
        disabled: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.openDialog && !prevProps.openDialog) {
            this.loadTypesData();
        }

        if ((prevProps.chosenThanksTypeId !== this.props.chosenThanksTypeId)
            || (prevState.types !== this.state.types)
        ) {
            let chosenTypeIndex = this.state.types.findIndex(el => el.id === this.props.chosenThanksTypeId);
            chosenTypeIndex = chosenTypeIndex > -1 ? chosenTypeIndex : 0;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(prevState => ({ chosenType: prevState.types[chosenTypeIndex] || {} }));
        }

        if (prevState.chosenType.id !== this.state.chosenType.id) {
            if (this.chosenTypeIsCompetence()
                && !(this.props.editMode && (this.props.chosenThanksTypeId === this.state.chosenType.id))
                && (this.state.chosenType.amountLeft <= 0)) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ disabled: true });
            }
        }
    }

    loadTypesData = () => {
        callApi(urls.getTypesUrl).then((requestData) => {
            const data = objCamelFromSnake(requestData);
            this.setState({ types: data.types });
        });
    }

    chosenTypeIsCompetence = () => !!this.state.chosenType.slug?.startsWith('competence_');

    handleCloseDialog = () => {
        this.setState({
            amountValue: '',
            coinsValidationText: '',
            textAreaError: null,
        });
        this.props.onCloseDialog();
    };

    handleClearTextareaError = () => {
        this.setState({
            textAreaError: null,
        });
    }

    handleSubmit = () => {
        if (this.props.editMode) {
            this.editThanks();
        } else {
            this.sendThanks();
        }
    };

    handleAmountChange = (value) => {
        this.setState({
            coinsValidationText: '',
            amountValue: value,
        });
    };

    handleChangeType(id) {
        this.setState({
            textAreaError: null,
            coinsValidationText: '',
        });
        const typeIndex = this.state.types.findIndex(el => el.id === id);
        if (typeIndex > -1) {
            this.setState(prevState => ({ chosenType: prevState.types[typeIndex], disabled: false }));
        }
    }

    sendThanks() {
        if (this.chosenTypeIsCompetence()) {
            const isMsgValid = validateCompetenceDescription($('.thanks-textarea').redactor('code.get'));
            if (!isMsgValid) return;
        }
        this.setState({ disabled: true });
        const body = {
            msg: $('.thanks-textarea').redactor('code.get'),
            type: this.state.chosenType?.id || null,
            transfer_amount: this.state.amountValue,
        };
        if (this.chosenTypeIsCompetence()) {
            delete body.transfer_amount;
        }
        callApi(urls.thankUserUrl(this.props.userId), 'post', body, FORMDATA)
            .then(() => {
                this.handleCloseDialog();
                showNotification({
                    level: 'success',
                    message: this.chosenTypeIsCompetence()
                        ? 'Подтверждение компетенции отправлено'
                        : 'Благодарность отправлена',
                });
            }).catch((error) => {
                const messages = error?.msg || {};
                let notification;
                if (messages.transfer_amount) {
                    this.setState({ coinsValidationText: messages.transfer_amount.join(' ') });
                    notification = 'У вас не хватает койнов.';
                    showNotification({ level: 'error', message: notification });
                }
                if (messages.msg) {
                    this.setState({ textAreaError: messages.msg.join(' ') });
                    notification = `При выдаче бейджа компетенции необходимо заполнить поле «Описание» (минимум ${COMPETENCE_MIN_LENGTH} символов).`;
                    showNotification({ level: 'error', message: notification });
                }
                /* eslint-disable no-underscore-dangle */
                if (messages.__all__) {
                    notification = messages.__all__.join(' ');
                    showNotification({ level: 'error', message: notification });
                }
                /* eslint-enable no-underscore-dangle */
                if (!notification) {
                    showNotification({ level: 'error', message: 'Произошла ошибка.' });
                }
            })
            .finally(() => {
                this.setState({ disabled: false });
            });
    }

    editThanks() {
        if (this.chosenTypeIsCompetence()) {
            const isMsgValid = validateCompetenceDescription($('.thanks-textarea').redactor('code.get'));
            if (!isMsgValid) return;
        }
        this.setState({ disabled: true });
        const body = {
            msg: $('.thanks-textarea').redactor('code.get'),
            type: this.state.chosenType.id,
        };
        callApi(urls.thankEditUrl(this.props.thanksId), 'post', body)
            .then((response) => {
                this.handleCloseDialog();
                if (this.props.onEdit) {
                    this.props.onEdit(response);
                }
                showNotification({
                    level: 'success',
                    message: this.chosenTypeIsCompetence()
                        ? 'Подтверждение компетенции отредактировано'
                        : 'Благодарность отредактирована',
                });
            })
            .catch((error) => {
                const messages = error?.msg || {};
                let notification;
                if (messages.msg) {
                    this.setState({ textAreaError: messages.msg.join(' ') });
                    notification = `При выдаче бейджа компетенции необходимо заполнить поле «Описание» (минимум ${COMPETENCE_MIN_LENGTH} символов).`;
                    showNotification({ level: 'error', message: notification });
                }
                /* eslint-disable no-underscore-dangle */
                if (messages.__all__) {
                    notification = messages.__all__.join(' ');
                    showNotification({ level: 'error', message: notification });
                }
                /* eslint-enable no-underscore-dangle */
                if (!notification) {
                    showNotification({ level: 'error', message: 'Произошла ошибка.' });
                }
            })
            .finally(() => {
                this.setState({ disabled: false });
            });
    }

    renderItem = (type, isChosen) => (
        <div
            key={ type.id }
            className={ `icon-bar-item ${isChosen ? 'chosen' : ''}` }
            onClick={ () => this.handleChangeType(type.id) }
        >
            <img src={ type.image } alt={ type.title } />
            <div
                className={ `icon-title ${isChosen ? 'chosen' : ''}` }
            >
                { type.title }
            </div>
        </div>
    )

    render() {
        const {
            types, chosenType, textAreaError, amountValue,
            coinsValidationText, disabled,
        } = this.state;

        const isRegularType = type => type.canChangeType && type.canBeCreatedNormally;

        const isRegular = isRegularType(chosenType);
        const availableTypes = types.filter(type => (
            (type.id === this.props.chosenThanksTypeId)
            || (isRegular ? isRegularType(type) : false)
        ));
        const thanksTypes = availableTypes.filter(type => !type.slug?.startsWith('competence_'));
        const competenceTypes = availableTypes.filter(type => type.slug?.startsWith('competence_'));

        let dialogTitle = 'Благодарность';
        if (this.chosenTypeIsCompetence()) {
            dialogTitle = 'Компетенция';
        }
        if (this.props.editMode) {
            dialogTitle = 'Редактировать благодарность';
            if (this.chosenTypeIsCompetence()) {
                dialogTitle = 'Редактировать компетенцию';
            }
        }

        let description = null;
        if (this.chosenTypeIsCompetence()) {
            const amountLeft = Math.max(0, chosenType.amountLeft);
            const limit = chosenType.amountLeft + chosenType.amountGiven;
            /* eslint-disable react/no-danger */
            description = (
                <React.Fragment>
                    <div dangerouslySetInnerHTML={ { __html: chosenType.description } } />
                    <div className={ classes.typeCount } hidden={ !isRegular }>Осталось { amountLeft }/{ limit }</div>
                </React.Fragment>
            );
            /* eslint-enable react/no-danger */
        } else {
            ({ description } = chosenType);
        }

        const thanksTransferExplanation = (
            <span>
                { 'Поблагодари коллегу коинами ' }
                <A href={ urls.store } target="_blank" rel="noopener noreferrer">
                    со своего счета
                </A>
            </span>
        );

        const transferCoins = (this.props.canSendCoins && !this.chosenTypeIsCompetence()) ? (
            <div className="thanks-transfer-coins">
                <TextField
                    value={ amountValue }
                    hintText="Перевести коины"
                    onChange={ (event, newValue) => this.handleAmountChange(newValue) }
                    validationState={ coinsValidationText ? 'error' : null }
                    validationText={ coinsValidationText }
                    maxLength={ 6 }
                />
                <TooltipContainer
                    tooltip={ thanksTransferExplanation }
                    tooltipStyles={ styles.tooltip }
                    position="top_left"
                >
                    <i
                        className="fa fa-question-circle-o"
                        aria-hidden="true"
                        style={ styles.questionIcon }
                    />
                </TooltipContainer>
            </div>
        ) : null;

        return (
            <div>
                <Dialog
                    title={ dialogTitle }
                    actionsContainerStyle={ styles.dialogActions }
                    actions={ [
                        <Button
                            label={ this.props.editMode ? 'Сохранить' : 'Отправить' }
                            onClick={ this.handleSubmit }
                            disabled={ disabled }
                            primary
                        />,
                    ] }
                    open={ this.props.openDialog }
                    onRequestClose={ this.handleCloseDialog }
                    contentStyle={ styles.contentStyle }
                    style={ styles.dialogStyle }
                    autoScrollBodyContent
                >
                    { (!!thanksTypes.length && !(this.props.editMode && this.chosenTypeIsCompetence())) && (
                        <>
                            <h1 className={ classes.title }>Благодарности</h1>
                            <div className="icon-bar-container">
                                { thanksTypes.map(type => this.renderItem(type, chosenType.id === type.id))}
                            </div>
                        </>
                    )}
                    { !!competenceTypes.length && !(this.props.editMode && !this.chosenTypeIsCompetence()) && (
                        <>
                            <h1 className={ classes.title }>Компетенции</h1>
                            <div className={ classes.totalCounts } hidden={ !isRegular }>
                                <div className={ classes.totalCountsItem }>
                                    <div className={ classes.totalCountsItemText }>
                                        всего вручил
                                    </div>
                                    <div className={ classes.totalCountsItemValue }>
                                        { competenceTypes.reduce((acc, cur) => (acc + cur.amountGiven), 0) }
                                    </div>
                                </div>
                                <div className={ classes.totalCountsItem }>
                                    <div className={ classes.totalCountsItemText }>
                                        всего осталось
                                    </div>
                                    <div className={ classes.totalCountsItemValue }>
                                        { competenceTypes.reduce((acc, cur) => (acc + Math.max(cur.amountLeft, 0)), 0) }
                                    </div>
                                </div>
                            </div>
                            <div className="icon-bar-container">
                                { competenceTypes.map(type => this.renderItem(type, chosenType.id === type.id))}
                            </div>
                        </>
                    )}
                    <div className="chosen-thank-type-title">
                        { chosenType ? chosenType.title : 'Спасибо' }
                    </div>
                    {
                        this.chosenTypeIsCompetence() && (
                            <div className="chosen-thank-type-warning">
                                Обязательно опишите как коллега проявил данную компетенцию
                            </div>
                        )
                    }
                    <div className="chosen-thank-type-description">
                        { description }
                    </div>
                    <SayThanksTextArea
                        thanksText={ this.props.thanksText }
                        onFocus={ this.handleClearTextareaError }
                    />
                    { !!textAreaError && (
                        <div className="thanks-textarea-error">
                            { textAreaError }
                        </div>
                    )}
                    { transferCoins }
                </Dialog>
            </div>
        );
    }
}
