export const styles = {
    additionalStyle: {
        display: 'block',
        fontSize: '12px',
        marginTop: '0px',
        color: 'rgb(153, 153, 153)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    containerStyle: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '0 0',
    },
    nameStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '1em',
        fontSize: '15px',
    },
    avatarStyle: {
        marginRight: '20px',
        marginTop: '5px',
    },
    infiContainer: {
        width: 'calc(100% - 72px)',
    },
    unitStyle: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
    },
    unitIconStyle: {
        fontSize: '30px',
    },
    unitNameStyle: {
        whiteSpace: 'initial',
    },
    onlineStyle: {
        color: 'green',
    },
};
