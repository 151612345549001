import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { A, Popover, Spinner } from 'intdev-ui';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';

class CommentBlackListToggle extends Component {
    static propTypes = {
        username: PropTypes.string.isRequired,
        onChangeBlackListStatus: PropTypes.func.isRequired,
    };

    state = {
        open: false,
        toRemove: false,
        inProcess: false,
        inBlackList: null,
        userData: null,
    };

    get popoverContent() {
        const { state: { inProcess, open, inBlackList, userData } } = this;
        let content = null;
        if (!open) {
            return null;
        }
        if (inProcess) {
            content = <Spinner />;
        }
        if (!inProcess && userData) {
            content = (
                <div className="comment-settings-item">
                    <A
                        href="#"
                        onClick={ this.handleToggleBlackList }
                        mode="menu"
                    >
                        { `${(!inBlackList) ? 'Больше не п' : 'П'}оказывать контент от ${userData.fullNameRo}` }
                    </A>
                </div>
            );
        }
        const className = classNames({
            'comment-settings-container': true,
            load: inProcess,
        });
        return (
            <div className={ className }> { content } </div>
        );
    }

    handleToggleBlackList = (event) => {
        const { state: { inBlackList, userData } } = this;
        event.preventDefault();
        const action = (inBlackList) ? 'remove' : 'add';
        callApi(`/following/api/black_list/${action}/`, 'POST', { user: userData.id }).then(() => {
            // передаём в родительский компонент нужно ли скрывать или показать комментарий
            this.setState(prevState => ({ inBlackList: !prevState.inBlackList, open: false }));
            this.props.onChangeBlackListStatus();
        });
    };

    handleClickArrow = (event) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
        this.fetchUserStatus();
    };

    handleRequestClose = () => this.setState({ open: false, inProcess: false });

    fetchUserStatus = () => {
        const {
            props: { username },
            state: { inBlackList },
        } = this;
        if (inBlackList !== null) {
            return;
        }
        this.setState({ inProcess: true });
        callApi(`/following/api/black_list/check/?username=${username}`).then((response) => {
            const data = objCamelFromSnake(response);
            this.setState({ ...data, inProcess: false });
        });
    };

    render() {
        return (
            <div>
                <i
                    onClick={ this.handleClickArrow }
                    className="comment-settings-button fa fa-angle-down"
                    title="Настройки видимости"
                />
                <Popover
                    open={ this.state.open }
                    anchorEl={ this.state.anchorEl }
                    anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
                    targetOrigin={ { horizontal: 'right', vertical: 'top' } }
                    onRequestClose={ this.handleRequestClose }
                >
                    { this.popoverContent }
                </Popover>
            </div>
        );
    }
}

export default CommentBlackListToggle;
