import React from 'react';
import PropTypes from 'prop-types';
import contentTypes from '../../../common/constants/contentTypes';
import Albums from './albums/Albums';
import Quizzes from './quizzes/Quizzes';
import Attachments from './files/Attachments';
import Meetings from './meetings/Meetings';

const allowedAttachmentsCts = [contentTypes.COOLENDAR_MEETING, contentTypes.ALBUMS_ALBUM,
    contentTypes.ATTACHMENTS_ATTACHMENT, contentTypes.QUIZ_QUIZ];

const attachmentGroups = {
    [contentTypes.ALBUMS_ALBUM]: Albums,
    [contentTypes.QUIZ_QUIZ]: Quizzes,
    [contentTypes.ATTACHMENTS_ATTACHMENT]: Attachments,
    [contentTypes.COOLENDAR_MEETING]: Meetings,
};

const RecordAttachments = (props) => {
    const {
        blocks,
        contentTypeId,
        objectId,
        onLoad,
    } = props;
    if (!blocks.length) {
        return null;
    }
    const blocksByCt = {};
    blocks.forEach((block) => {
        if (block.ct_natural_key in blocksByCt) {
            blocksByCt[block.ct_natural_key].push(block.object_id);
        } else {
            blocksByCt[block.ct_natural_key] = [block.object_id];
        }
    });
    return (
        <div>
            { allowedAttachmentsCts.map((ct) => {
                const ids = blocksByCt[ct];
                const GroupComponent = attachmentGroups[ct];
                if (!GroupComponent || !ids) {
                    return null;
                }
                return (
                    <GroupComponent
                        key={ ct }
                        ids={ ids }
                        objectId={ objectId }
                        contentTypeId={ contentTypeId }
                        onLoad={ onLoad }
                    />
                );
            })}
        </div>
    );
};

RecordAttachments.propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.shape({
        ct_id: PropTypes.number,
        object_id: PropTypes.number,
        ct_natural_key: PropTypes.string,
    })),
    objectId: PropTypes.number.isRequired,
    contentTypeId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
};

RecordAttachments.defaultProps = {
    blocks: [],
};

export default RecordAttachments;
