import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';
import PopoverContainer from '../PopoverContainer';
import getStyleSelector from './style';


export default class LabelWithHelp extends PureComponent {
    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        onLabelClick: PropTypes.func,
        help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        style: IUIPropTypes.style,
        questionIconStyle: IUIPropTypes.style,
        popoverStyle: IUIPropTypes.style,
        anchorOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string,
        }),
        targetOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string,
        }),
    };

    static defaultProps = {
        id: undefined,
        label: '',
        onLabelClick: null,
        style: {},
        popoverStyle: {},
        questionIconStyle: {},
        anchorOrigin: undefined,
        targetOrigin: undefined,
        help: '',
    };

    constructor() {
        super();
        this.styleSelector = getStyleSelector();
    }

    handleLabelClick = () => {
        if (this.props.onLabelClick) {
            this.props.onLabelClick();
        } else {
            this.forceUpdate();
        }
    };

    render() {
        const {
            label,
            help,
            // eslint-disable no-unused-vars
            popoverStyle,
            questionIconStyle,
            anchorOrigin,
            targetOrigin,
            style,
            // eslint-enable no-unused-vars
            ...rest
        } = this.props;
        delete rest.onLabelClick;
        const styles = this.styleSelector(this.props);
        return (
            <div style={ styles.style } { ...rest }>
                <span onClick={ this.handleLabelClick }>{ label }</span>
                { help ? (
                    <PopoverContainer
                        style={ styles.popoverRootStyle }
                        popoverContent={ help }
                        popoverStyle={ styles.popoverStyle }
                        targetOrigin={ targetOrigin }
                        anchorOrigin={ anchorOrigin }
                    >
                        <i className="fa fa-question-circle-o" style={ styles.questionIconStyle } />
                    </PopoverContainer>
                ) : null }
            </div>
        );
    }
}
