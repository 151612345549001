export const CONTAINER_ID = 'quiz-builder-app-container';

export const QUESTION_GROUP_TYPE_CONSECUTIVE = 0;
export const QUESTION_GROUP_TYPE_ON_ONE_PAGE = 1;
export const QUESTION_GROUP_TYPE_TABLE_CHOICE = 2;
export const QUESTION_GROUP_TYPE_TABLE_MULTICHOICE = 3;

export const QUESTION_TYPE_CHOICE = 0;
export const QUESTION_TYPE_MULTICHOICE = 1;
export const QUESTION_TYPE_FREE_ANSWER = 2;
export const QUESTION_TYPE_DROPDOWN = 6;
export const QUESTION_TYPE_SCALE = 7;
export const QUESTION_TYPE_PICTURES_CHOICE = 8;
export const QUESTION_TYPE_PICTURES_MULTICHOICE = 9;

export const TABLE_GROUPS = [QUESTION_GROUP_TYPE_TABLE_MULTICHOICE, QUESTION_GROUP_TYPE_TABLE_CHOICE];
