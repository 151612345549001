export const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
        marginBottom: '50px',
    },
    text: {
        fontWeight: 'bold',
        fontSize: '15px',
        marginBottom: '10px',
        marginTop: '25px',
        textAlign: 'center',
    },
    serverErrorBackRef: {
        lineHeight: '20px',
        marginTop: '16px',
    },
};
