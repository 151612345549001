import React from 'react';
import PropTypes from 'prop-types';
import { Select, UserInfo, TextField } from 'intdev-ui';

import { callApi } from '@/common/middlewares/apiMiddleware';
import { showErrorNotification } from '@/common/helpers/showNotification';
import gravatar from '@/common/gravatar';
import contentTypes from '@/common/constants/contentTypes';
import { cleanData } from '@/common/helpers/cleanData';

import { holdersURL, suggestHoldersURL } from './apiUrls';
import { hasListChanged } from './helpers';
import { commonMedia } from '@/common/media';


const optionRenderStyle = {
    group: {
        icon: 'fa-users',
        helperText: '(группа)',
    },
    city: {
        icon: 'fa-building',
        helperText: '(город)',
    },
    unit: {
        icon: 'fa-cogs',
        helperText: '(подразделение)',
    },
    project: {
        icon: 'fa-at',
        helperText: '(проект)',
    },
    floor: {
        icon: 'fa-building',
        helpText: '(этаж)',
    },
};


export class PermissionManager extends React.Component {
    /**
    @param {Array} permissionNames -- не обязательный параметр, если объект еще не создан
    @param {Number|String} contentType -- может быть либо id'шников ct, так и ключом (например, blog.entry)
     */
    static propTypes = {
        contentType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        permissionNames: PropTypes.arrayOf(PropTypes.string),
        objectId: PropTypes.number,
        // optional props
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        contentTypesForSuggest: PropTypes.arrayOf(PropTypes.string),
        defaultReason: PropTypes.string,
        hideReasonField: PropTypes.bool, // work only if defaultReason is passed
    };

    static defaultProps = {
        objectId: null,
        contentType: null,
        disabled: false,
        placeholder: 'Владельцы права на объект',
        contentTypesForSuggest: [contentTypes.USERS_USERPROFILE, contentTypes.USERS_GROUP],
        defaultReason: '',
        hideReasonField: false,
        permissionNames: [],
    };

    state = {
        isLoading: false,
        holders: [],
        initialHolders: [],
        reason: '',
        reasonError: null,
    };

    componentDidMount() {
        this.getHolders();
    }

    get hasHoldersChanged() {
        const { holders, initialHolders } = this.state;
        return hasListChanged(holders, initialHolders);
    }

    getHolders = () => {
        const {
            contentType,
            objectId,
            defaultReason,
            permissionNames,
        } = this.props;

        if (objectId && permissionNames.length) {
            this.setState({ isLoading: true }, () => {
                callApi(holdersURL, 'get', cleanData({
                    permissions: permissionNames,
                    content_type: contentType,
                    obj_id: objectId,
                }))
                    .then((response) => {
                        this.setState({
                            holders: response.holders,
                            initialHolders: response.holders,
                            reason: defaultReason || '',
                            isLoading: false,
                        });
                    })
                    .catch((err) => {
                        if (err.status === 400) showErrorNotification('Не удалось получить информацию о правах');
                        else if (err.status === 404) showErrorNotification('Такого объекта не существует');
                        else showErrorNotification('Неизвестная ошибка');
                    });
            });
        } else this.setState({ reason: defaultReason || '' });
    };

    validateReason = () => {
        /**
         * этот метод вызывается из родительского компонента.
         */
        const { reason } = this.state;
        let isValid = true;
        if (!this.hasHoldersChanged) {
            return isValid;
        }
        if (!reason || !reason.trim()) {
            this.setState({ reasonError: 'Укажи причину изменения' });
            isValid = false;
        }
        return isValid;
    };

    handleChangeHolders = holders => this.setState({ holders });

    handleChangeReason = (_, reason) => this.setState({ reason, reasonError: null });

    saveManager = (objectId) => {
        /**
         * @param {number} objectId
         * этот метод вызывается из родительского компонента.
         * в случае если мы редактируем права на объект, он уже создан, и мы знаем его id.
         * если мы создаем объект, и тут же выдаем на него права, мы еще не знаем его айдишник, поэтому
         * id нужно прокидывать с родительского компонента.
         */
        const { contentType, permissionNames } = this.props;
        const { reason, holders } = this.state;

        if (!this.hasHoldersChanged) return Promise.resolve();

        return new Promise((resolve, reject) => {
            callApi(holdersURL, 'post', cleanData({
                reason,
                holders: holders.map(h => h.value),
                obj_id: objectId,
                permissions: permissionNames,
                content_type: contentType,
            }))
                .then(resolve)
                .catch((err) => {
                    reject({
                        managerKey: permissionNames.join(', '),
                        objectId,
                        backendErr: err,
                    });
                });
        });
    };

    clearScope = () => {
        /**
         * метод вызывается из родителя
         * нужен для для компонентов-НЕмодалок, когда после сохранения страница остается той же.
         * в таком случае нам надо очищать поле причины и обновлять список initialHolders
         * мы не можем делать это в saveManager'e, поскольку модалка к моменту резолва,
         * может быть уже закрыта. Т.о. мы будем пытаться вызвать setState у размаунченного компонента.
         * при необходимости такое же можно сделать в GenericGroupManager'e,
         * но пока подобного функционала нигде не требуется.
         */
        const { defaultReason } = this.props;
        this.setState(prevState => ({
            reason: defaultReason || '',
            initialHolders: prevState.holders,
        }));
    };

    valueRenderer = value => value.label.value;

    optionRenderer = (option) => {
        if (option.label.type === 'user') {
            return (
                <UserInfo
                    avatar={ gravatar(option.label.email) }
                    fullname={ option.label.value }
                />
            );
        }
        const renderStyle = optionRenderStyle[option.label.type];
        if (!renderStyle) {
            return (
                <div className="group-container">
                    <div>
                        { option.label.value }
                    </div>
                </div>
            );
        }
        const iconClass = renderStyle.icon;
        return (
            <div className="group-container">
                {iconClass && (
                    <div className="select-group-icon">
                        <i className={ `fa ${iconClass}` } />
                    </div>
                ) }
                <div>
                    { option.label.value }
                </div>
                <div className="select-group-helper">{renderStyle.helperText}</div>
            </div>
        );
    };

    loadOptions = (term, callback) => {
        if (!term) {
            callback(null, []);
            return;
        }

        callApi(suggestHoldersURL, 'post', {
            content_types: this.props.contentTypesForSuggest,
            term,
        })
            .then((data) => {
                const filteredData = {
                    ...data,
                    options: data.options.filter(item => (
                        !(this.state.holders.find(holder => (
                            holder.value.content_type === item.value.content_type
                            && holder.value.id === item.value.id
                        )))
                    )),
                };
                callback(null, filteredData);
            });
    };

    render() {
        const {
            reason,
            holders,
            reasonError,
            isLoading,
        } = this.state;

        const {
            placeholder,
            disabled,
            hideReasonField,
        } = this.props;

        return (
            <div className="root-container">
                <Select.Async
                    loadOptions={ this.loadOptions }
                    placeholder={ placeholder }
                    searchPromptText="Никого не удалось найти"
                    value={ holders }
                    disabled={ disabled || isLoading }
                    filterOptions={ options => options }
                    onChange={ this.handleChangeHolders }
                    optionRenderer={ this.optionRenderer }
                    valueRenderer={ this.valueRenderer }
                    cache={ false }
                    inputProps={ { maxLength: 40 } }
                    clearable
                    fullWidth
                    multi
                    menuStyle={ commonMedia.fontSizeBody }
                    valueStyle={ commonMedia.fontSizeBody }
                    hintStyle={ commonMedia.fontSizeBody }
                    labelStyle={ commonMedia.fontSizeBody }
                />
                { this.hasHoldersChanged && !(hideReasonField && reason) && (
                    <TextField
                        name="reason"
                        value={ reason }
                        disabled={ disabled || isLoading }
                        onChange={ this.handleChangeReason }
                        hintText="Укажи причину изменения"
                        validationState={ reasonError && 'error' }
                        validationText={ reasonError }
                        fullWidth
                    />
                ) }
            </div>
        );
    }
}
