import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '../../common/helpers/store';

import { singleAppReducers } from './reducers';
import { getHistory } from '../../spa/history';


export const history = getHistory();

export function makeStore() {
    const historyMiddleware = routerMiddleware(history);
    return configureStore(singleAppReducers, {}, [historyMiddleware]);
}
