import { combineReducers } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { getHistory, routerReducer } from '@/spa/history';
import { notifications } from '@/notifications/reducers/notifications';
import { configureStore } from '@/common/helpers/store';
import { projectFeatures } from '@/common/components/DefaultParams/reducers/projectFeatures';
import { projectConstants } from '@/common/components/DefaultParams/reducers/projectConstants';

import { headerReducer } from '../reducers';

let headerStoreInstance = null;

export const getStore = () => {
    if (!headerStoreInstance) {
        const reducers = combineReducers({
            router: routerReducer(),
            notifications,
            features: projectFeatures,
            projectConstants,
            headerReducer,
        });
        const history = getHistory();
        headerStoreInstance = configureStore(reducers, {}, [routerMiddleware(history)]);
    }
    return headerStoreInstance;
};

export const headerDispatch = action => getStore().dispatch(action);
