import React from 'react';
import PropTypes from 'prop-types';

import { QuestionStatistic } from './QuestionStatistic';

export class QuizStatistic extends React.Component {
    static propTypes = {
        quizId: PropTypes.number,
        statistics: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        fetchQuizStatistic: PropTypes.func,
    };

    static defaultProps = {
        fetchQuizStatistic: null,
        quizId: null,
    };

    componentDidMount() {
        if (this.props.fetchQuizStatistic && this.props.quizId && !this.props.statistics.length) {
            this.props.fetchQuizStatistic(this.props.quizId);
        }
    }

    render() {
        const questionsWithStat = this.props.statistics.map(question => (
            <QuestionStatistic key={ question.id } question={ question } />
        ));
        return (
            <div>
                { questionsWithStat }
            </div>
        );
    }
}
