import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'intdev-ui';
import moment from 'moment';

import { EVENT_SHAPE } from '../../shapes';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { showNotification } from '../../../common/helpers/showNotification';
import { apiUrls } from '../../constants/apiUrls';

export default class DeleteConfirm extends React.Component {
    static propTypes = {
        event: EVENT_SHAPE.isRequired,
        onRequestClose: PropTypes.func.isRequired,
    };

    state = {
        processing: false,
    };

    handleDelete = () => {
        const url = apiUrls().meetingDetail(this.props.event.meetingId);
        this.setState({ processing: true });
        callApi(url, 'DELETE')
            .then(() => {
                this.props.onRequestClose();
                showNotification({ message: 'Событие удалено', level: 'success' });
            })
            .catch(() => {
                this.setState({ processing: false });
                showNotification({
                    message: 'Ошибка при удалении события',
                    level: 'error',
                });
            });
    }

    render() {
        const { event } = this.props;
        const startMoment = moment(event.startTime);
        const endMoment = moment(event.endTime);
        startMoment.locale('ru');
        return (
            <Dialog
                title="Удаление события"
                modal
                open
                disabled={ this.state.disabled }
                actionsContainerStyle={ { display: 'flex', justifyContent: 'flex-end' } }
                actions={ [
                    <Button
                        disabled={ this.state.disabled }
                        label="Отмена"
                        onClick={ this.props.onRequestClose }
                    />,
                    <Button
                        disabled={ this.state.disabled }
                        primary
                        label="Подтвердить"
                        onClick={ () => this.handleDelete() }
                    />,
                ] }
            >
                <div>{
                    `Ты собираешься удалить
                    ${event.isSeries ? 'серию событий проходящую' : 'событие проходящее'} "${event.name}"
                    ${startMoment.format('D MMMM YYYY года с HH:mm')}
                    до ${endMoment.format(`${event.isSeries ? 'D MMMM YYYY' : ''} HH:mm`)}`
                }</div>
            </Dialog>
        );
    }
}

