import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import QuizDetail from '../components/QuizDetail';
import { fetchQuiz } from '../actions/quiz';


const mapStateToProps = ({ quizApp: state }) => ({
    process: state.process,
    finished: state.controls.finished,
    quiz: state.quiz,
});


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuiz,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuizDetail);
