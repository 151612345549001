import PropTypes from 'prop-types';

export const DEFAULT_SUGGESTIONS_URL = '/we/get_users/';
export const SUGGESTION_SELECT_VALUE_SHAPE = PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.shape({
        photo: PropTypes.string,
        value: PropTypes.string,
    }),
});
