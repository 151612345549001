import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table,
    TableBody,
    TableRow,
    TableRowColumn,
} from 'intdev-ui';
import { bindActionCreators } from 'redux';

import LineChoiceQuestion from '../questions/LineChoiceQuestion';
import LineMultiChoiceQuestion from '../questions/LineMultiChoiceQuestion';
import { changeAnswer, initAnswers } from '../../actions/answers';
import * as constants from '../../../builder/constants.es6';


const styles = {
    choices: {
        whiteSpace: 'normal',
        textAlign: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        width: '80px',
    },
    questions: {
        width: '168px',
    },
};


class TableChoiceQuestionGroup extends React.Component {
    static propTypes = {
        multiple: PropTypes.bool,
        questionsList: PropTypes.arrayOf(PropTypes.shape),
        choicesList: PropTypes.arrayOf(PropTypes.shape),
        changeAnswer: PropTypes.func.isRequired,
        initAnswers: PropTypes.func.isRequired,
    };

    static defaultProps = {
        multiple: false,
        questionsList: [],
        choicesList: [],
    };

    componentDidMount() {
        const groupType = (this.props.multiple) ? constants.QUESTION_GROUP_TYPE_TABLE_MULTICHOICE :
            constants.QUESTION_GROUP_TYPE_TABLE_CHOICE;
        this.props.initAnswers(this.props.questionsList, groupType);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const groupType = (nextProps.multiple) ? constants.QUESTION_GROUP_TYPE_TABLE_MULTICHOICE :
            constants.QUESTION_GROUP_TYPE_TABLE_CHOICE;
        nextProps.initAnswers(nextProps.questionsList, groupType);
    }

    handleChange = (questionId, value) => {
        this.props.changeAnswer(questionId, { value });
    };

    render() {
        const choices = this.props.choicesList.map(choice => (
            <TableRowColumn style={ styles.choices } key={ choice.id }>
                <div className="quiz-detail__text_size_s" dangerouslySetInnerHTML={ { __html: choice.text } } />
            </TableRowColumn>
        ));
        const ChoiceQuestion = this.props.multiple ? LineMultiChoiceQuestion : LineChoiceQuestion;
        const questions = this.props.questionsList.map(question => (
            <ChoiceQuestion
                key={ question.id }
                question={ question }
                choiceList={ this.props.choicesList }
                onChange={ this.handleChange }
            />
        ));

        return (
            <Table style={ { overflowX: 'scroll', tableLayout: 'auto', width: 'auto' } }>
                <TableBody>
                    <TableRow>
                        <TableRowColumn style={ styles.questions } />
                        { choices }
                    </TableRow>
                    { questions }
                </TableBody>
            </Table>
        );
    }
}

const mapStateToProps = ({ quizApp: state }) => {
    const currentGroup = state.groups[state.controls.currentGroupId];
    return {
        questionsList: currentGroup.questions.map(questionId => state.questions[questionId]),
        choicesList: currentGroup.choice_set.map(choiceId => state.choices[choiceId]),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    changeAnswer,
    initAnswers,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TableChoiceQuestionGroup);
