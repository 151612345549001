export const urls = {
    getIdeasDistribution: (dateStart, dateEnd, category) => `/likes/get_ideas_distribution/?start_date=${dateStart ? dateStart.toISOString() : ''}&end_date=${dateEnd ? dateEnd.toISOString() : ''}${category || ''}`,
    getIdeasReport: (dateStart, dateEnd) => `/likes/get_ideas_report/?start_date=${dateStart ? dateStart.toISOString() : ''}&end_date=${dateEnd ? dateEnd.toISOString() : ''}`,
    getPublicationTopInfo: (publicationType, dateStart, dateEnd) => `/likes/api/publication_top/${publicationType}/?start_date=${dateStart ? dateStart.toISOString() : ''}&end_date=${dateEnd ? dateEnd.toISOString() : ''}`,
    getIdeasTopInfo: (dateStart, dateEnd, category, statuses) => `/likes/api/publication_top/ideas/?start_date=${dateStart ? dateStart.toISOString() : ''}&end_date=${dateEnd ? dateEnd.toISOString() : ''}${category || ''}${statuses || ''}`,
    fetchTooltipInfo: (contentTypeId, objectId) => `/likes/tooltip/${contentTypeId}/${objectId}/`,
    likeObject: (contentTypeId, objectId, emoji) => `/likes/like/${contentTypeId}/${objectId}/${emoji}/`,
    dislikeObject: (contentTypeId, objectId, emoji) => `/likes/dislike/${contentTypeId}/${objectId}/${emoji}/`,
    getAllCategories: '/likes/api/get_all_ideas_categories/',
    bulkLike: '/likes/bulk/',
};
