import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'intdev-ui';
import { dictionary } from '@/common/localization/dictionary';
import CommentsList from './components/comments/CommentsList';
import CommentsForm from './components/CommentsForm';
import CommentNotification from './components/helpers/CommentNotification';
import { OfficialResponse } from './components/ideas/OfficialResponse';
import { deepFind, NoParentException, treeify } from './components/helpers/helpers';
import { showErrorNotification, showNotification, showSuccessNotification } from '../common/helpers/showNotification';
import { callApi } from '../common/middlewares/apiMiddleware';
import getCentrifugeConnection from '../centrifuge';
import scrollKeeper from './utils/scrollKeeper';
import { editInterval } from './constants';
import {
    deleteCommentURL,
    loadCommentsURL,
    makeOfficialResponseURL,
    loadOfficialResponseURL,
} from './apiUrls';
import { generateUuid } from '../common/uuid';
import '../../comments/comments.css';
import { media } from './media';

const COMMENT_RELATION_TYPE = 1;

export default class CommentsBlock extends Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        objectId: PropTypes.number.isRequired,
        userId: PropTypes.number,
        currPhotoId: PropTypes.number,
        formHeight: PropTypes.number,
        editInterval: PropTypes.number,
        objectUrl: PropTypes.string,
        compactMode: PropTypes.bool,
        isAllowAudioComments: PropTypes.bool,
        isAllowSubscribtion: PropTypes.bool,
        commentsEnabled: PropTypes.bool,
        isFranchiseeIdeaCommentsBlock: PropTypes.bool,
        onChangeOfficialResponse: PropTypes.func,
        showOfficialResponseButton: PropTypes.bool,
    };

    static defaultProps = {
        currPhotoId: undefined,
        formHeight: 70,
        editInterval,
        objectUrl: '',
        requestClose: undefined,
        compactMode: false,
        userId: undefined,
        isAllowAudioComments: false,
        isAllowSubscribtion: true,
        commentsEnabled: true,
        isFranchiseeIdeaCommentsBlock: false,
        onChangeOfficialResponse: () => {},
        showOfficialResponseButton: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            contentTypeId: this.props.contentTypeId,
            objectId: this.props.objectId,
            allComments: {},
            currComments: [],
            parentForm: false,
            editForm: false,
            officialResponseForm: false,
            officialResponse: {},
            defaultText: '',
            blinkId: undefined,
            actions: {},
            subscribedToComments: false,
            lastViewTimestamp: null,
            userId: null,
            commentCtype: null,
            commentsEnabled: true,
            officialResponseLikesBlockUuid: null,
        };
    }

    componentDidMount() {
        this.loadComments();
        window.addEventListener('hashchange', this.handleHashChange);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { objectId, contentTypeId } = nextProps;
        const newState = {};
        if (objectId && this.props.objectId !== objectId) {
            newState.objectId = objectId;
        }
        if (contentTypeId && this.props.contentTypeId !== contentTypeId) {
            newState.contentTypeId = contentTypeId;
        }
        if (Object.keys(newState).length) {
            this.setState(newState, this.loadComments);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { blinkId, currComments } = this.state;
        if (!prevState.blinkId && blinkId) {
            this.blinkComment(false, blinkId);
        }
        if (!prevState.currComments.length && currComments.length) {
            this.blinkComment(true);
        }
    }

    componentWillUnmount = () => {
        if (this.subscription) {
            // see https://github.com/Olical/EventEmitter/blob/master/docs/api.md
            this.subscription.removeListener('message', this.handleMessage);
        }
        window.removeEventListener('hashchange', this.handleHashChange);
    };

    blinkComment = (scrollTo, id) => {
        let comment = id;
        if (comment === undefined) {
            const hash = window.location.hash;
            if (hash === '#comments') { return; }
            comment = hash;
        } else {
            comment = `#comment${comment}`;
        }
        const commentElement = $(`${comment}`);
        const commentId = parseInt(comment.split('#comment')[1], 10);
        const isChildForCurObject = this.state.allComments[this.state.objectId].filter(c => c.id === commentId)[0];
        if (commentElement.length && isChildForCurObject) {
            if (scrollTo) {
                scrollKeeper(commentElement, 5000);
            }
            commentElement.next('.comment').stop().delay(200).animate(
                { 'background-color': 'rgba(255, 221, 187, 1)' }, 100,
            )
                .animate(
                    { 'background-color': 'rgba(255, 221, 187, 0)' }, 10000,
                );
        }
        this.setState({ blinkId: undefined });
    };

    handleHashChange = () => {
        const commentID = window.location.hash.replace(/^\D+/g, '');
        this.blinkComment(true, commentID);
    }

    handleMessage = (message) => {
        if (message.data.key === 'comment') {
            this.insertComment(message.data.data, true);
        }
    };

    subscribeToChannel = () => {
        const centrifuge = getCentrifugeConnection();
        if (this.state.centrifugeChannel && !this.subscription) {
            this.subscription = centrifuge.subscribe(
                this.state.centrifugeChannel,
                this.handleMessage,
            );
        }
    }

    loadComments = () => {
        const { contentTypeId, objectId } = this.state;
        const loadURL = loadCommentsURL(contentTypeId, objectId);
        callApi(loadURL).then((data) => {
            const allComments = data.comments || {};
            let currComments = allComments[objectId] || [];
            currComments = treeify(currComments);
            const { actions } = data;
            this.setState({
                allComments,
                currComments,
                contentTypeId,
                objectId,
                actions,
                centrifugeChannel: data.centrifuge_channel,
                subscribedToComments: data.subscribed_to_comments,
                lastViewTimestamp: data.last_view_timestamp,
                commentCtype: data.comment_ct_id,
                userId: data.user_id,
                commentsEnabled: (data.comments_enabled === false) ? data.comments_enabled : true,
            }, this.subscribeToChannel);
            this.loadCommentsLikes();
        }).catch(() => { /* ничего */ });
    };

    loadCommentsLikes = () => {
        const { allComments, commentCtype } = this.state;

        const allCommentObjects = Object.values(allComments)
            .map(comments => comments.map(
                comment => ({
                    content_type_id: commentCtype,
                    object_id: comment.id,
                }),
            ))
            .flat();

        if (allCommentObjects.length) {
            callApi('/likes/bulk/', 'post', { objects: allCommentObjects }).then((data) => {
                this.updateLikes(data.counters);
            });
        }

        this.setState({
            allComments: Object.entries(allComments).reduce((acc, [objId, comments]) => {
                acc[objId] = comments.map(comment => ({
                    ...comment,
                    count_likes: {},
                    is_liked: false,
                }));
                return acc;
            }, {}),
        });
    }

    updateLikes = (updates) => {
        const { objectId, allComments } = this.state;

        const { officialResponse } = this.state;
        let nextOfficialResponse = officialResponse;

        const nextAllComments = {};
        Object.entries(allComments).forEach(([objId, comments]) => {
            comments.forEach((comment) => {
                let nextComment = comment;
                updates.forEach((update) => {
                    if (comment.id === update.object_id) {
                        nextComment = {
                            ...comment,
                            count_likes: update.count,
                            is_liked: update.is_liked,
                        };
                        if (comment.id === officialResponse.id
                            && parseInt(objId, 10) === officialResponse.object_id
                        ) {
                            nextOfficialResponse = nextComment;
                        }
                    }
                });
                nextAllComments[objId] = [...(nextAllComments[objId] || []), nextComment];
            });
        });

        let currComments = nextAllComments[objectId] || [];
        if (currComments.length) {
            currComments = treeify(currComments);
        }

        this.setState({
            allComments: nextAllComments,
            currComments,
            officialResponse: nextOfficialResponse,
        });
    };

    insertComment = (comment, keepForm) => {
        const newComment = comment;
        newComment.count_likes = {};
        newComment.is_liked = false;
        const { objectId } = this.state;
        let { allComments, currComments } = this.state;
        let addNewComment = true;
        if (!allComments[objectId]) {
            allComments[objectId] = [];
        }

        const existingComment = allComments[objectId].filter(c => c.id === comment.id)[0];
        let formState = {};
        if (!keepForm) {
            formState = {
                parentForm: false,
                editForm: false,
            };
        }
        if (existingComment && existingComment.text === comment.text) {
            this.setState(formState);
            return false;
        }

        const editComment = deepFind(currComments, newComment.id);
        if (editComment) {
            if (editComment.text === newComment.text) {
                this.setState({
                    ...formState,
                    defaultText: '',
                    blinkId: newComment.id,
                });
                return true;
            }
            editComment.text = newComment.text;
            editComment.updated_at = newComment.updated_at;
            editComment.is_delete = newComment.is_delete;
            addNewComment = false;
        }
        if (addNewComment) {
            allComments[objectId].push(comment);
            currComments = allComments[objectId];
            if (currComments.length) {
                try {
                    currComments = treeify(currComments);
                } catch (exc) {
                    if (exc instanceof NoParentException) {
                        // это нужно для случаев, когда у А пропало соединение, и за время, пока ее не было
                        // Б оставил комментарии. Когда у А соединение восстановится,
                        // и в канал опубликуется след
                        // комментарий, у А будет нарушено дерево, поскольку она
                        // пропустила сообщения предыдущие сообщение в этот канал
                        this.loadComments();
                        this.showCommentNotification(newComment);
                    }
                    return true;
                }
            }
        } else {
            allComments = { ...allComments, [objectId]: [...allComments[objectId]] };
            currComments = [...currComments];
        }
        this.showCommentNotification(newComment);
        this.setState({
            ...formState,
            allComments,
            currComments,
            defaultText: '',
            blinkId: newComment.id,
        });
        return true;
    };

    handleMakeOfficial = (comment) => {
        const url = makeOfficialResponseURL(this.state.objectId, this.props.isFranchiseeIdeaCommentsBlock);
        callApi(url, 'POST', { comment_id: comment.id })
            .then(() => {
                this.changeOfficialResponse(comment);
                this.props.onChangeOfficialResponse(comment.id);
                showSuccessNotification('Официальный ответ добавлен');
            }).catch((error) => {
                if (error.status >= 500) {
                    showErrorNotification(`Произошла ошибка: ${error.error}`);
                } else {
                    showErrorNotification('Не удалось добавить официальный ответ: недостаточно прав');
                }
            });
    };

    handleCommentEdit = () => {
        this.setState({ editForm: false, defaultText: '' });
    };

    changeParent = (id) => {
        this.setState({
            parentForm: id,
            editForm: false,
        });
    };

    changeEdit = (id) => {
        const { currComments } = this.state;
        let text = '';
        const editComment = deepFind(currComments, id);
        if (editComment) {
            text = editComment.text;
        }
        this.setState({
            editForm: id,
            defaultText: text,
            parentForm: false,
        });
    };

    deleteComment = (id) => {
        const { contentTypeId, objectId } = this.props;
        callApi(deleteCommentURL(contentTypeId, objectId), 'POST', { comment: id })
            .then((response) => {
                this.insertComment(response.comment);
                showSuccessNotification('Комментарий удален');
            })
            .catch(() => showErrorNotification('Не удалось удалить комментарий'));
    };

    updateList = (list, response) => list.map((comment) => {
        if (comment.id === response.id) {
            return response;
        }
        return comment;
    });

    changeOfficialResponseForm = (boolState) => {
        this.setState({
            officialResponseForm: boolState,
            editForm: false,
            parentForm: false,
            defaultText: '',
        });
    };

    changeOfficialResponse = (comment) => {
        this.setState({
            officialResponse: { ...comment },
            officialResponseLikesBlockUuid: generateUuid(),
            officialResponseForm: false,
            parentForm: false,
            editForm: false,
        });
    };

    handleSubscribeToComments = () => {
        const data = {
            content_type_id: this.props.contentTypeId,
            object_id: this.props.objectId,
            relation_type: COMMENT_RELATION_TYPE,
        };
        const { subscribedToComments } = this.state;
        this.setState({ subscribedToComments: !subscribedToComments });
        const url = this.state.subscribedToComments ? '/relation_off/' : '/relation_on/';
        callApi(url, 'post', data, 'formdata').catch(() => {
            this.setState({ subscribedToComments: !subscribedToComments });
        });
    };

    showCommentNotification = (comment) => {
        const { userId } = this.props;
        if (comment.userid !== userId) {
            showNotification({
                level: 'success',
                children: (
                    <CommentNotification
                        id={ comment.id }
                        userName={ comment.username }
                        action={ comment.action }
                    />
                ),
            });
        }
    };

    render() {
        const {
            allComments,
            currComments,
            actions,
            parentForm,
            editForm,
            officialResponseForm,
        } = this.state;

        const {
            isFranchiseeIdeaCommentsBlock,
            onChangeOfficialResponse,
            ...restProps
        } = this.props;

        if (!allComments) {
            return (
                <div>Загрузка...</div>
            );
        }

        const params = {
            ...restProps,
            ...this.state,
            list: currComments,
            isNested: false,
            updateLikes: this.updateLikes,
            insertComment: this.insertComment,
            changeParent: this.changeParent,
            changeEdit: this.changeEdit,
            changeOfficialResponseForm: this.changeOfficialResponseForm,
            changeOfficialResponse: this.changeOfficialResponse,
            handleMakeOfficial: this.handleMakeOfficial,
            handleCommentEdit: this.handleCommentEdit,
            lastViewTimestamp: parseInt(this.state.lastViewTimestamp, 10),
            userId: this.state.userId,
            onBlackListUpdate: this.loadComments,
            deleteComment: this.deleteComment,
        };

        let countBlock;
        if (!this.props.compactMode) {
            const commentCount = allComments[this.props.objectId] ? allComments[this.props.objectId].length : 0;
            countBlock = (
                <div className="comment-list-header">
                    <span>{`${dictionary.comments} — ${commentCount} `}</span>
                </div>
            );
        }

        const isCommentFormShown = !!(
            this.props.commentsEnabled
            && this.state.commentsEnabled
            && !parentForm
            && !editForm
            && !officialResponseForm
        );

        const isOfficialResponseShown = !!(
            actions.official_response
            && this.props.showOfficialResponseButton
        );

        return (
            <div>
                { isOfficialResponseShown && (
                    <OfficialResponse
                        loadOfficialResponseURL={ loadOfficialResponseURL(params.objectId, isFranchiseeIdeaCommentsBlock) }
                        { ...params }
                    />
                )}
                <div style={
                    {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '20px 0 10px 0',
                        flexWrap: 'wrap',
                    }
                }
                >
                    { countBlock }
                    { this.props.isAllowSubscribtion && (
                        <Toggle
                            rootStyle={ { color: '#7f8191', fontSize: 13 } }
                            onClick={ this.handleSubscribeToComments }
                            isOn={ this.state.subscribedToComments }
                            labelStyle={ media.subscribe }
                            label={ dictionary.subscribeToComments }
                            size={ 20 }
                        />
                    )}
                </div>
                <CommentsList { ...params } />
                { isCommentFormShown && <CommentsForm { ...params } /> }
                { (!this.props.commentsEnabled || !this.state.commentsEnabled) && <div className="comments-disabled">Комментарии отключены.</div>}
            </div>
        );
    }
}
