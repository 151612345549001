import { EVENT_LOAD_FAILURE, EVENT_SAVE_SUCCESS, MODAL_CLOSE, MODAL_OPEN } from '../constants/actions';
import { MODAL_COPY, MODAL_EDIT } from '../constants/modalTypes';

const RESULT_OK = 'OK';

const openedModal = (state = { type: undefined, event: {} }, action) => {
    switch (action.type) {
        case (MODAL_OPEN):
            return {
                type: action.payload.type,
                event: action.payload.event || {},
            };
        case (MODAL_CLOSE):
            return {
                type: undefined,
                event: {},
            };
        case (EVENT_SAVE_SUCCESS): {
            const data = action.payload;
            if (data.result === RESULT_OK &&
                (state.type === MODAL_EDIT || state.type === MODAL_COPY)) {
                return {
                    type: undefined,
                    event: {},
                };
            }
            return state;
        }
        case (EVENT_LOAD_FAILURE): {
            if (state.type === MODAL_EDIT || state.type === MODAL_COPY) {
                return {
                    type: undefined,
                    event: {},
                };
            }
            return state;
        }
        default:
            return state;
    }
};

export default openedModal;
