import pluralized from '@/common/helpers/pluralized';

export default {
    micropostPlaceholder: name => `Hamkasblaringiz bilan ${name} almashing`,
    timeline: 'Voqealar oqimi',
    comments: 'izoh',
    like: 'kabi',
    subscribeToComments: 'Izohlarga obuna bo\'ling',
    addingNewComment: 'Yangi sharh qo\'shish',
    send: 'Yuborish',
    profile: 'profil',
    settings: 'Sozlamalar',
    search: 'Izlash',
    logout: 'Chiqish',
    notificationSettings: 'XABARNOMA SOZLAMALARI',
    here: 'Bu erda',
    bell: 'qo\'ng\'iroq',
    resetToDefault: 'Standart holatga qaytarish',
    settingUpMailings: 'XABARLARNI SOZLASH',
    profileSettings: 'PROFIL SOZLAMALARI',
    timelineSettings: 'TASMANI SOZLASH',
    all: 'Hammasi',
    subscriptions: 'Obunalar',
    nothing: 'Hech narsa',
    subscriptionLikes: 'Obuna yoqdi',
    subscriptionLikesHelp: 'Sizning tasmangizda siz kuzatib boradigan xodimlar tomonidan yoqadigan yozuvlar mavjud',
    birthPubHelp: 'Ushbu sozlama profilning displeyiga, Jamoaning yangiliklar sahifasiga va xabarnomaning tarkibiga ta\'sir qiladi',
    jubileePubHelp: 'Ushbu sozlama Team News sahifasiga ta\'sir qiladi',
    addNote: 'Eslatma qo\'shing',
    editBlog: 'Blogni tahrirlash',
    edit: 'tahrirlash',
    delete: 'yo\'q qilish',
    makeMailing: 'axborot byulleteni yarating',
    anchor: 'langar',
    newNoteInBlog: 'Yangi blog',
    title: 'Sarlavha',
    author: 'Muallif',
    blogsToNote: 'Yozish uchun bloglar',
    tags: 'Teglar',
    publicate: 'Joylashtirish',
    saveDraft: 'Qoralamani saqlang',
    thanks: () => 'ташаккур',
    balance: 'Balans',
    likes: 'Yoqdi',
    photos: 'Rasmlar',
    stickers: 'Stikerlar',
    calls: 'Qo\'ng\'iroqlar',
    followers: 'Izdoshlar',
    follows: 'Obunalar',
    changeHistory: 'Tarixni o\'zgartirish',
    internalNumber: 'Kengaytma raqami',
    mobileNumber: 'Mobil telefon raqami',
    workEmail: 'Ishchi pochta',
    office: 'Idora',
    personalEmail: 'Shaxsiy pochta',
    inCaseOfAbsence: 'Agar yo\'q bo\'lsa, murojaat qiling',
    badges: 'Nishonlar',
    aboutWork: 'Ish haqida',
    information: 'Ma `lumot',
    skills: 'Ko\'nikmalar',
    callHistory: 'Qo\'ng\'iroqlar tarixi',
    lookFor: 'qidirmoq',
    sortBy: 'Saralash turi:',
    advancedSearch: 'Xodimlar tomonidan kengaytirilgan qidiruv',
    letsLookSearch: 'Keling, nimadir qidiramiz...',
    byRelevance: 'muhimligi',
    byTime: 'vaqt',
    filters: 'Filtrlar',
    ideasStatus: 'Fikrlar holati',
    popularCategories: 'Ommabop toifalar',
    addIdea: 'Fikr qo\'shing',
    ideas: 'Fikrlar',
    status: 'Holat',
    category: 'Kategoriya',
    responsibleExecutor: 'Mas\'ul ijrochi',
    ideaCategory: 'Fikr kategoriyasi:',
    addingIdea: 'Fikr qo\'shish',
    selectIdeaCategory: 'G\'oyalar toifasini tanlang',
    ideaTitle: 'Ism',
    writeIdeaTitle: 'O\'z mohiyatini aks ettiradigan g\'oyaning nomini yozing (qisqacha)',
    solvedProblem: 'Muammoni hal qilish',
    whatProblemSolved: 'Fikr qanday muammoni hal qiladi?',
    expectedResult: 'Kutilayotgan natija',
    whatImprovemenents: 'Qanday yaxshilanishlar bo\'ladi, qaysi ko\'rsatkichlar yaxshilanadi?',
    authorIsReady: 'Muallif o\'z fikrini mustaqil ravishda amalga oshirishga tayyor:',
    authorIsNotReady: 'Muallif o\'z g\'oyasini amalga oshirishga tayyor emas:',
    yes: 'Ha',
    no: 'Yo\'q',
    enterIdeaDescription: 'Fikr tavsifini kiriting',
    undo: 'Bekor qilish',
    create: 'Yarating a',
    date: 'sana',
    user: 'Foydalanuvchi',
    callerID: 'Raqamni aniqlash xizmati',
    destinationNumber: 'Belgilangan raqam',
    my: 'Mening',
    popular: 'Ommabop',
    uploadedByMe: 'Men yuklagan',
    stickersCount: count => pluralized(count, 'stikerlar:stiker:stiker'),
    added: 'Qo\'shilgan',
    uploadedBy: 'Yuklandi:',
    stickerSelection: 'Stiker tanlash',
    addingImage: 'Rasm qo\'shish',
    select: 'Tanlang',
    noFileSelected: 'Hech qanday fayl tanlanmagan',
    or: 'yoki',
    fileURL: 'fayl manzili',
    download: 'Yuklab oling',
    forSubscribers: 'Obunachilar',
    forAll: 'Hammaga',
    showAdditionalInfo: 'Qo\'shimcha ma\'lumotlarni ko\'rsatish',
    hideAdditionalInfo: 'Qo\'shimcha ma\'lumotni yashirish',
    subscription: 'Obuna',
    startTypingName: 'Xodimning ismini yozishni boshlang',
    top: 'Eng yaxshi',
    authors: 'mualliflar',
    hotline: '«Mehnatni muhofaza qilish boshqarmasi» ishonch telefoni ',
    blogs: 'Bloglar',
    publish: 'Joylashtirish',
    subscribeToBlog: 'Blogga obuna bo\'lish',
    startTyping: 'yozishni boshlang',
    toAnswer: 'Javob berish uchun',
    idea: 'Fikr',
    profilePhotos: 'Profil fotosurati',
    albums: 'Albomlar',
    marks: 'Belgilar',
    addTo: 'qo\'shish',
    staff: 'Xodimlar',
    subdivisions: 'Bo\'limlar',
    blocked: 'Bloklangan',
    addStickerPack: 'Stikerlar to\'plamini qo\'shing',
    stickerPackListIsEmpty: 'Sizning stikerlaringiz ro\'yxati bo\'sh',
    additional: 'Qo\'shimcha ',
    badgesProgress: 'Progress piktogramma',
    leaders: 'Rahbarlar',
    projects: 'Loyihalar',
    education: 'Ta\'lim',
    languages: 'Tillar',
    hobbies: 'Xobbi',
    aboutMyself: 'O\'zim haqimda',
    residenceAddress: 'Yashash manzili',
    bornInTheCity: () => 'Shaharda tug\'ilgan',
    birthDate: 'Tug\'ilgan kun',
    iLiveInTheCity: 'Men shaharda yashayman',
    workBefore: companyName => `${companyName} ishlamay turib`,
    giveThanks: 'Shukur qiling',
    confirmedTheSkill: (gender, self) => `${self ? 'Sizning mahoratingizni tasdiqladi' : 'Tasdiqlangan mahorat'}`,
    addTheSkill: (gender, self) => `${self ? 'Sizga mahorat qo\'shdi' : 'Qo\'shilgan mahorat'}`,
    notifications: 'Bildirishnomalar',
    markAllAsReserved: 'Barchasini ko\'rilgan deb belgilash',
    contacts: 'Kontaktlar',
    mobilePhone: 'Mobil telefon',
    primary: 'Asosiy',
    otherContacts: 'Boshqa aloqalar',
    telegramNickname: 'Nik Telegramda',
    tamTamNickname: 'Nik Tam-Tam',
    whatsappPhone: 'WhatsApp telefon',
    viberPhone: 'Viber telefon',
    skypeLogin: 'Skype-da kirish',
    vkNickname: 'Vkontakte-da Nik',
    additionalEmail: 'Qo\'shimcha email',
    enterYourPrivateEmail: 'shaxsiy elektron pochtangizni kiriting - asosiy',
    externalAccounts: 'Tashqi hisob qaydnomalari',
    additionalLinks: 'Qo\'shimcha havolalar',
    save: 'Saqlash',
    cancel: 'Bekor qilish',
    chooseProjects: 'loyihalarni tanlang',
    infoAboutAbsence: 'Yo\'qligi haqida ma\'lumot',
    nearestMetroStation: 'Eng yaqin metro stantsiyasi',
    gettingToWork: 'Men ishga kirishaman',
    driversLicense: 'Haydovchilik guvohnomasi',
    exWork: companyName => `${companyName ? `${companyName} ishlaganidan oldin` : 'oldin ishlagan'}`,
    foreignLanguages: 'Chet tillar',
    recommendSubscribing: 'Obuna bo\'lishni tavsiya etamiz',
    accept: 'Tasdiqlang',
    peopleWhoAcceptedSkill: 'odam mahoratini tasdiqladi',
    achievement: 'Ko\'ngil ochish',
    confirmed: 'tasdiqlangan',
    commentsRating: 'Reyting Izohlar',
    postsRating: 'Reyting xabarlar',
    ideasRating: 'Reyting g\'oyalar',
    micropostsRating: 'Reyting mikropost',
    overallRating: 'Umumiy reyting',
    viewed: 'Ko\'rilgan',
    noNotifications: 'Bildirishnomalar yo\'q',
    noNewNotifications: 'Yangi bildirishnomalar yo\'q',
    showAllNotifications: 'Hammasini ko\'rish',
};
