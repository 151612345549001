import React from 'react';
import PropTypes from 'prop-types';
import { A, Button } from 'intdev-ui';
import { commonMedia } from '@/common/media';
import { Record as BasicRecord } from '../Record';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer';
import { showErrorNotification } from '../../../common/helpers/showNotification';
import { linkStyles } from '../../../common/components/ALink';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { CentriSubscribe } from '../../../common/components/CentriSubscribe';
import { userPropTypes, skillPropTypes } from './propTypes';
import './styles.css';
import { dictionary } from '@/common/localization/dictionary';


export class SkillRecord extends React.Component {
    /**
     * @param { is_added } приходит с бэка, но отвечает за абсолютно разные модели
     * при true -- на бэке используется модель SkillToOwner, означающая, что скилл был добавлен
     * false -- модель SkillProved, означающая что пользователь подтвердил навык
     */
    static propTypes = {
        is_added: PropTypes.bool.isRequired,
        authUserData: PropTypes.shape({
            id: PropTypes.number.isRequired,
            gender: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
        }).isRequired,
        skill: skillPropTypes.isRequired,
        owner: userPropTypes.isRequired,
        provers: PropTypes.arrayOf(PropTypes.number).isRequired,
        approver: userPropTypes.isRequired,
        skill_to_owner_id: PropTypes.number.isRequired,
        endorse_url: PropTypes.string.isRequired,
        unendorse_url: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isApprovedByUser: this.props.provers.includes(this.props.authUserData.id),
        };
    }

    handleCentrifuge = ({ skill_to_owner_id: skillToOwnerId }) => {
        if (+skillToOwnerId === +this.props.skill_to_owner_id) {
            this.setState({ isApprovedByUser: true });
        }
    }

    handleEndorseSkill = () => {
        let url;
        if (this.state.isApprovedByUser) url = this.props.unendorse_url;
        else url = this.props.endorse_url;
        callApi(url, 'post')
            .then(() => {
                this.setState({ isApprovedByUser: true });
            })
            .catch((err) => {
                if (err && err.msg) showErrorNotification(err.msg);
                else showErrorNotification('Не удалось подтвердить навык.');
            });
    }

    render = () => {
        const { is_added: isAdded, skill, owner, approver, authUserData } = this.props;
        const { isApprovedByUser } = this.state;

        let recordTitle = '';
        if (isAdded) {
            recordTitle = dictionary.addTheSkill(approver.gender, owner.id === authUserData.id);
        } else {
            recordTitle = dictionary.confirmedTheSkill(approver.gender, owner.id === authUserData.id);
        }

        const recordHead = (
            <span className="skill-record__description-text">
                {
                    owner.id === approver.id ? (
                        <React.Fragment>
                            <UserTooltipContainer
                                username={ approver.username }
                                withName
                                wrapper="span"
                            >
                                <A href={ approver.url } style={ linkStyles.inline }>
                                    { `${approver.first_name} ${approver.last_name}` }
                                </A>
                            </UserTooltipContainer>
                            <span> {`овладел${approver.gender ? '' : 'а'} новым навыком`}:&nbsp;</span>
                        </React.Fragment>
                    ) : (
                        <span>
                            <UserTooltipContainer
                                username={ approver.username }
                                withName
                                wrapper="span"
                            >
                                <A href={ approver.url } style={ linkStyles.inline }>
                                    { `${approver.first_name} ${approver.last_name}` }
                                </A>
                            </UserTooltipContainer>&nbsp;
                            { recordTitle }&nbsp;
                        </span>
                    )
                }
                <A className="inline-link timeline-header" href={ skill.url }>
                    { skill.name }
                </A>
            </span>
        );

        const recordBody = (
            <div className="aggregated-skills__align-center">
                { skill.icon && (
                    <A href={ skill.url }>
                        <img
                            className="skill-record__skill-img"
                            src={ skill.icon }
                            alt={ skill.name }
                        />
                    </A>
                ) }
                { authUserData.id !== owner.id && (
                    <Button
                        primary
                        name="endorse"
                        label={ isApprovedByUser ? `Ты уже подтвердил${this.props.authUserData.gender ? '' : 'а'} этот навык` : dictionary.accept }
                        disabled={ isApprovedByUser }
                        onClick={ this.handleEndorseSkill }
                        { ...commonMedia.buttonStyles }
                    />
                ) }
            </div>
        );

        return (
            <React.Fragment>
                <BasicRecord
                    recordHead={ recordHead }
                    recordBody={ recordBody }
                    hide_likes
                    hide_comments
                    { ...this.props }
                />
                <CentriSubscribe
                    channel={ `skills#${this.props.authUserData.id}` }
                    onMessage={ this.handleCentrifuge }
                />
            </React.Fragment>
        );
    }
}
