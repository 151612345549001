import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableRowColumn } from 'intdev-ui';
import { connect } from 'react-redux';
import classnames from 'classnames';


const styles = {
    inputs: {
        whiteSpace: 'normal',
        textAlign: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        width: '80px',
    },
    questions: {
        width: '168px',
        whiteSpace: 'normal',
        textAlign: 'center',
    },
};


const LineChoiceQuestion = (
    {
        choiceList,
        value,
        onChange,
        question,
        isQuestionWithError,
    },
) => {
    const choicesInputs = choiceList.map(choice => (
        <TableRowColumn style={ styles.inputs } key={ choice.id }>
            <input
                type="radio"
                value={ choice.id }
                checked={ choice.id === value }
                onChange={ () => onChange(question.id, choice.id) }
                name={ `question${question.id}` }
            />
        </TableRowColumn>
    ));

    const classNames = classnames({
        'quiz-detail__question': true,
        'quiz-detail__text_size_s': true,
        'strict-question': isQuestionWithError,
        'quiz-detail__question-table': true,
    });

    return (
        <TableRow>
            <TableRowColumn style={ styles.questions }>
                <div className={ classNames }>
                    { question.strict_answer && <span className="quiz-detail__strict-icon">*</span> }
                    <div dangerouslySetInnerHTML={ { __html: question.text } } />
                </div>
            </TableRowColumn>
            { choicesInputs }
        </TableRow>
    );
};

LineChoiceQuestion.propTypes = {
    question: PropTypes.shape({
        text: PropTypes.string,
        id: PropTypes.number,
        strict_answer: PropTypes.bool,
    }).isRequired,
    choiceList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    isQuestionWithError: PropTypes.bool.isRequired,
};

LineChoiceQuestion.defaultProps = {
    choiceList: [],
    value: null,
};

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id] || {};
    const isQuestionWithError = (state.questionsWithError.indexOf(ownProps.question.id) !== -1);
    return {
        value: answer.value || null,
        isQuestionWithError,
    };
};

export default connect(mapStateToProps)(LineChoiceQuestion);
