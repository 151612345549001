import { combineReducers } from 'redux';
import { userPermissions } from '../../../common/components/DefaultParams/reducers/userPermissions';
import { quiz } from './quiz';
import { groups } from './groups';
import { questions } from './questions';
import { choices } from './choices';
import { process } from './process';
import { controls } from './controls';
import { answers } from './answers';
import { questionsWithError } from './questionsWithError';
import { rightChoices } from './rightChoices';
import { userResults } from './userResults';
import { permissions } from './permissions';
import { viewers } from './viewers';
import { quizzesByCode } from './quizzesByCode';
import { quizzes } from './quizzes';
import { quizResults } from './quizResults';
import { quizScheme } from './quizScheme';
import { quizByPass } from './quizByPass';
import { routerReducer } from '../../../spa/history';


export const spaReducers = combineReducers({
    quiz,
    groups,
    questions,
    choices,
    process,
    controls,
    answers,
    questionsWithError,
    rightChoices,
    userResults,
    permissions,
    viewers,
    quizzes,
    quizzesByCode,
    quizResults,
    quizScheme,
    quizByPass,
});

export const singleAppReducers = combineReducers({
    quizApp: spaReducers, userPermissions, router: routerReducer()
});
