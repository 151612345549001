import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import { SliderArrow } from '@/blog/components/BlogDetail/SliderArrow';
import { ConfirmButton, Spinner } from '@intdev/intdev-ui';
import classNames from 'classnames';
import IconFaButton from '@/common/components/IconFaButton';
import { connect } from 'react-redux';
import { deleteAttachments } from '@/blog/actions';
import { styles } from './AttachmentListItem';

class BookComponentContainer extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        canManageAttachments: PropTypes.bool.isRequired,
        isUserCreator: PropTypes.bool.isRequired,
        deleteAttachments: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
    };

    state = {
        pageNumber: 1,
        numPages: null,
        isLoading: true,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages, pageNumber: 1, isLoading: false });
    };

    changePage = offset => this.setState(state => ({ pageNumber: state.pageNumber + offset }));

    previousPage = () => {
        const { pageNumber } = this.state;
        const offset = pageNumber === 2 ? -1 : -2;
        if (pageNumber !== 1) {
            this.changePage(offset);
        }
    };

    nextPage = () => {
        const { pageNumber, numPages } = this.state;
        const offset = pageNumber === 1 ? +1 : +2;
        const diff = numPages - pageNumber;
        if (diff >= offset) {
            this.changePage(offset);
        }
    };

    handleRemoveAction = () => {
        this.props.deleteAttachments(this.props.id);
    };

    getManagementButtons = () => {
        if ((this.props.canManageAttachments || this.props.isUserCreator) && this.state.isHovered) {
            return (
                <div className="blog-post-management-button-container">
                    <ConfirmButton
                        label="Удалить вложение"
                        dialogText="Ты уверен, что хочешь удалить вложение?"
                        onClick={ this.handleRemoveAction }
                        buttonElement={ (
                            <IconFaButton
                                iconClass="fa-remove"
                                title="Удалить"
                                style={ styles.managementButton }
                                styleHover={ {
                                    ...styles.managementButtonHovered,
                                    ...styles.removeButtonHovered,
                                } }
                            />
                        ) }
                    />
                </div>
            );
        }
        return null;
    };

    render() {
        const { url } = this.props;
        const { pageNumber, numPages, isLoading } = this.state;

        const loading = (
            <div style={ { display: 'flex', alignItems: 'center', padding: '5px 10px' } }>
                <Spinner size={ 32 } />
                <span style={ { marginLeft: 10 } }>Книга загружается</span>
            </div>
        );

        return (
            <>
                <Document
                    file={ url }
                    onLoadSuccess={ this.onDocumentLoadSuccess }
                    loading={ loading }
                    className={ classNames({
                        'react-pdf__Document_shadows': !isLoading,
                    }) }
                    onMouseEnter={ () => this.setState({ isHovered: true }) }
                    onMouseLeave={ () => this.setState({ isHovered: false }) }
                >
                    <Page
                        pageNumber={ pageNumber }
                    />
                    { pageNumber !== 1 && pageNumber + 1 <= numPages && (
                        <Page pageNumber={ pageNumber + 1 } />
                    ) }
                    <div className="react-pdf__backdrop">
                        <SliderArrow
                            style={ { transform: 'rotate(180deg)' } }
                            arrowStyle={ { width: 6 } }
                            containerStyle={ { left: 10 } }
                            onClick={ this.previousPage }
                        />
                        <SliderArrow
                            containerStyle={ { right: 10 } }
                            arrowStyle={ { width: 6 } }
                            onClick={ this.nextPage }
                        />
                        <a className="book-fullscreen" href={ url }>
                            <i className="fa fa-arrows-alt" aria-hidden="true" />
                        </a>
                        { this.getManagementButtons() }
                    </div>
                </Document>
            </>
        );
    }
}

const mapDispatchToProps = {
    deleteAttachments,
};

export const BookComponent = connect(null, mapDispatchToProps)(BookComponentContainer);