import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';

const INITIAL_STATE = {
    endingText: '',
    rightAnswersCount: null,
    rightChoicesCount: null,
    statistics: [],
};


export const userResults = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_RESULTS_FINISH: {
            return update(state, {
                endingText: { $set: action.payload.text },
                rightAnswersCount: { $set: action.payload.right_answers_count },
                rightChoicesCount: { $set: action.payload.right_choices_count },
                statistics: { $set: action.payload.statistics },
            });
        }
        default:
            return state;
    }
};
