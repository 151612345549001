import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { dictionary } from '@/common/localization/dictionary';
import { Record as BasicRecord } from './Record';
import UserTooltipContainer from '../../common/components/UserTooltipContainer';


export class BadgeRecord extends React.Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        badge: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            codename: PropTypes.string.isRequired,
            description: PropTypes.string,
            approved_count: PropTypes.number,
            skill: PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
            }),
            owner: PropTypes.shape({
                url: PropTypes.string,
                first_name: PropTypes.string,
                username: PropTypes.string,
            }),
            created_by: PropTypes.shape({
                url: PropTypes.string,
                username: PropTypes.string,
                first_name: PropTypes.string,
            }),
        }).isRequired,
    };

    render = () => {
        const { url, badge } = this.props;

        const recordHead = (
            <span>
                <A className="inline-link timeline-header" href={ url }>
                    { badge.name }
                </A>&nbsp;
            </span>
        );

        let description = null;
        if (badge.description) {
            description = (
                <div
                    className="timeline-badge-description"
                    dangerouslySetInnerHTML={ { __html: this.props.badge.description } }
                />
            );
        } else if (badge.approved_count && badge.skill && badge.owner) {
            description = (
                <span>
                    { badge.approved_count } { dictionary.peopleWhoAcceptedSkill }&nbsp;
                    <UserTooltipContainer
                        username={ badge.owner.username }
                        withName
                        withAvatar
                        wrapper="span"
                    >
                        <strong>
                            <a href={ badge.owner.url }>
                                { badge.owner.first_name }
                            </a>
                        </strong>
                    </UserTooltipContainer>
                    &nbsp;&quot;
                    <strong>
                        <a href={ badge.skill.url }>
                            { badge.skill.name }
                        </a>
                    </strong>
                    &quot;
                    {
                        badge.created_by
                        && (
                            <span>, добавленный&nbsp;
                                <UserTooltipContainer
                                    username={ badge.created_by.username }
                                    withName
                                    withAvatar
                                    wrapper="span"
                                >
                                    <strong>
                                        <a href={ badge.created_by.url }>
                                            { badge.created_by.first_name }
                                        </a>
                                    </strong>
                                </UserTooltipContainer>
                            </span>
                        )
                    }
                </span>
            );
        }

        const recordBody = (
            <div style={ { display: 'flex' } }>
                <A
                    href={ badge.url }
                    style={ { flex: '0 0 auto' } }
                >
                    <img
                        style={ { width: '130px', height: '130px', margin: '16px' } }
                        src={ badge.image }
                        alt={ badge.name }
                    />
                </A>
                { description }
            </div>
        );

        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ recordBody }
                { ...this.props }
                type="достижение"
            />
        );
    }
}
