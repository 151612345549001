import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'intdev-ui';
import { PeopleTooltipItem } from './PeopleTooltipItem';
import './styles/PeopleCounter.css';

export default class PeopleCounterModal extends Component {
    static propTypes = {
        tooltipItemInfo: PropTypes.shape({
            likes: PropTypes.array.isRequired,
        }).isRequired,
        open: PropTypes.bool.isRequired,
        handleCloseModal: PropTypes.func.isRequired,
    };

    render() {
        const { tooltipItemInfo } = this.props;
        return (
            <Dialog
                autoScrollBodyContent
                open={ this.props.open }
                onRequestClose={ this.props.handleCloseModal }
                actions={ [] }
            >
                <div className="all-users-counter-container">
                    <div className="counter-tooltip-users">
                        {
                            tooltipItemInfo.likes.map(result => (
                                <PeopleTooltipItem userInfo={ result } key={ result.url } />
                            ))
                        }
                    </div>
                </div>
            </Dialog>
        );
    }
}
