import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'intdev-ui';


export const DebouncedLoader = ({
    timeout,
    loader,
    alignCenter,
}) => {
    /**
     * Компонент предназначен для отрисовывания лоудера после истечения таймаута
     * Пример: по состоянию isLoading, хотим показывать наш лоудер, но в большинстве случаев,
     * загрузка и отрисовка занимают 200-300мс и тогда лоудер только успевает моргнуть на экране,
     * что очень бесит. Тем не менее могут быть случаи, когда сеть нагружена, запрос идет долго и тдтп,
     * и убирать лоудер из вашего компонента не хочется. Можно использовать этот компонент, который
     * покажет лоудер только после истечения таймаута
     */
    const [showLoader, setShowLoader] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => setShowLoader(true), timeout);
        return () => clearTimeout(timer);
    }, []);

    if (!showLoader) return null;
    if (alignCenter) {
        return (
            <div className="b-flexrow__center">
                { loader }
            </div>
        );
    }

    return loader;
};

DebouncedLoader.propTypes = {
    timeout: PropTypes.number,
    loader: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
    ]),
    alignCenter: PropTypes.bool,
};

DebouncedLoader.defaultProps = {
    timeout: 400,
    loader: <Spinner />,
    alignCenter: false,
};
