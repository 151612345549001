export default {
    NOTIFICATIONS_LOADING_START: 'notifications.notifications_loading_start',
    NOTIFICATIONS_LOADING_FINISH: 'notifications.notifications_loading_finish',
    NOTIFICATIONS_LOADING_FAIL: 'notifications.notifications_loading_fail',

    NOTIFICATION_LIST_LOADING_START: 'notifications.notification_list_loading_start',
    NOTIFICATION_LIST_LOADING_FINISH: 'notifications.notification_list_loading_finish',
    NOTIFICATION_LIST_LOADING_FAIL: 'notifications.notification_list_loading_fail',

    NOTIFICATIONS_UPDATE_START: 'notifications.notifications_update_start',
    NOTIFICATIONS_UPDATE_FINISH: 'notifications.notifications_update_finish',
    NOTIFICATIONS_UPDATE_FAIL: 'notifications.notifications_update_fail',

    CENTRIFUGE_MESSAGE_RECEIVED: 'notifications.centrifuge_message_received',
    SYNCHRONIZE_TABS: 'notifications.synchronize_tabs',
};
