import React from 'react';
import PropTypes from 'prop-types';

export class CentriSubscribe extends React.Component {
    static propTypes = {
        channel: PropTypes.string.isRequired,
        onMessage: PropTypes.func, // onMessage(data)
    };

    static defaultProps = {
        onMessage: null,
    };

    state = {};

    componentDidMount = () => {
        this.connection = getCentrifugeConnection();
        this.subscription = this.connection.subscribe(this.props.channel, this.handler);
    };

    componentWillUnmount = () => {
        this.subscription.removeListener('message', this.handler);
    };

    handler = (message) => {
        if (this.props.onMessage) {
            this.props.onMessage(message.data);
        }
    };

    render() {
        return null;
    }
}
