/* eslint-disable no-shadow */
import { combineReducers } from 'redux';

import loadState, * as fromLoadState from './loadState';
import eventModal, * as fromEventEditModal from './eventModal';
import openedModal from './openedModal';
import footerEvent from './footerEvent';
import calendarConfig from './calendarConfig';
import eventMap, * as fromEventMap from './eventMap';
import { calendarContainerProps } from './calendarContainerProps';
import eventsUiState from './eventsUiState';
import navigationArrowUiState, * as fromNavigationArrowUiState from './navigationArrowUiState';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';
import { calendarConfigLoad, setCalendarContainerProps } from '../actions';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { apiUrls } from '../constants/apiUrls';
import { projectFeatures } from '../../common/components/DefaultParams/reducers/projectFeatures';

export const calendarReducerMap = {
    loadState,
    eventMap,
    calendarConfig,
    openedModal,
    eventModal,
    navigationArrowUiState,
    footerEvent,
    eventsUiState,
    calendarContainerProps, // used to store calendarContainer state
    projectFeatures,
};

export const calendarReducers = combineReducers(calendarReducerMap);

export const getEvents = state => fromEventMap.getEvents(state.eventMap);
export const groupEventsByName = eventList =>
    fromEventMap.groupEventsByName(eventList);
export const getColumnGroups = (eventList, columnMaxElements, showEventLabel) =>
    fromEventMap.getColumnGroups(eventList, columnMaxElements, showEventLabel);

export const getLoadState = (state, gridGroupName) => fromLoadState.getLoadState(state.loadState, gridGroupName);

export const getNavigationArrowUiState = (state, gridGroupName) =>
    fromNavigationArrowUiState.getNavigationArrowUiState(state.navigationArrowUiState, gridGroupName);

export const getModalData = state => fromEventEditModal.getModalData(state.eventModal);
export const getCopyPeriodMinutesValues = state =>
    fromEventEditModal.getCopyPeriodMinutesValues(state.eventModal);
export const getCopyPeriodDaysValues = state =>
    fromEventEditModal.getCopyPeriodDaysValues(state.eventModal);

export const setCalendarConfig = (store, configData) => {
    const camelizedData = objCamelFromSnake(configData);
    const {
        calendarConfig,
        ...otherData
    } = camelizedData;
    return store.dispatch(calendarConfigLoad({
        ...calendarConfig,
        ...otherData,
    }));
};

export const setCalendarContainerPropsReducer = (store, containerProps) => store.dispatch(
    setCalendarContainerProps(containerProps),
);

export const loadCalendarConfig = (store, contentType, objectId) => callApi(
    apiUrls().calendarConfig(contentType, objectId),
).then(data => setCalendarConfig(store, data[0]));
