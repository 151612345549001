import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '@/common/mediaQueries';

function getClassName(cls, mod) {
    return mod ? `${cls} ${cls}_${mod}` : cls;
}

export function LayoutDouble(props) {
    const {
        Menu,
        menuBlockClass,
        centerBlockClass,
        menuBlockClassMod,
        centerBlockClassMod,
        menuHiddenOnMobile,
        children,
    } = props;
    let menuBlockClassName = '';
    let centerBlockClassName = '';

    const isMenuShownFromStorage = localStorage.getItem('isMenuShown');
    const [isMenuShown, setIsMenuShown] = useState(
        isMenuShownFromStorage === null ? false : JSON.parse(isMenuShownFromStorage),
    );
    if (!isMenuShown) {
        menuBlockClassName = getClassName(menuBlockClass, 'hidden');
        centerBlockClassName = getClassName(centerBlockClass, 'full-width');
    } else {
        menuBlockClassName = getClassName(menuBlockClass, menuBlockClassMod);
        centerBlockClassName = getClassName(centerBlockClass, centerBlockClassMod);
    }

    if (isMobile && menuHiddenOnMobile) {
        menuBlockClassName = getClassName(menuBlockClass, 'hidden');
    }

    return (
        <div className="page-content">
            <div className={ menuBlockClassName }>
                <Menu onIsMenuShownChange={ setIsMenuShown } isMenuShown={ isMenuShown } />
            </div>
            <div className={ centerBlockClassName } id="primary-content">
                { children }
            </div>
        </div>
    );
}

LayoutDouble.propTypes = {
    children: PropTypes.element.isRequired,
    Menu: PropTypes.func.isRequired,
    menuBlockClass: PropTypes.string,
    centerBlockClass: PropTypes.string,
    menuBlockClassMod: PropTypes.string,
    centerBlockClassMod: PropTypes.string,
    menuHiddenOnMobile: PropTypes.bool,
};

LayoutDouble.defaultProps = {
    menuBlockClass: 'menu-block',
    centerBlockClass: 'center-block',
    menuBlockClassMod: '',
    centerBlockClassMod: '',
    menuHiddenOnMobile: true,
};
