/* global CENTRIFUGE_ADDRESS */
/* global CENTRIFUGE_USER */
/* global CENTRIFUGE_TIMESTAMP */
/* global CENTRIFUGE_INFO */
/* global CENTRIFUGE_TOKEN */

import Centrifuge from 'centrifuge';

let connection = null;

function getCentrifugeObject() {
    if (!CENTRIFUGE_TOKEN) {
        // eslint-disable-next-line no-console
        console.log('Centrifuge token not found');
        return null;
    }

    if (!CENTRIFUGE_USER) {
        // eslint-disable-next-line no-console
        console.log('Centrifuge user not found');
        return null;
    }

    if (!CENTRIFUGE_TIMESTAMP) {
        // eslint-disable-next-line no-console
        console.log('Centrifuge timestamp not found');
        return null;
    }

    if (!CENTRIFUGE_ADDRESS) {
        // eslint-disable-next-line no-console
        console.log('Centrifuge connection url not found');
        return null;
    }

    if (!CENTRIFUGE_INFO) {
        // eslint-disable-next-line no-console
        console.log('Centrifuge info not used, token must be generated without info part.');
    }

    return new Centrifuge({
        url: CENTRIFUGE_ADDRESS,
        user: CENTRIFUGE_USER,
        timestamp: CENTRIFUGE_TIMESTAMP,
        info: CENTRIFUGE_INFO,
        token: CENTRIFUGE_TOKEN,
    });
}

function getNewCentrifugeConnection() {
    try {
        const centrifuge = getCentrifugeObject();
        centrifuge.connect();

        return centrifuge;
    } catch (ex) {
        return null;
    }
}

function getFakeCentrifugeConnection() {
    return ({ subscribe() { return ({ unsubscribe() {}, removeListener() {} }); } });
}

function getCentrifugeConnection() {
    if (connection === null) {
        connection = getNewCentrifugeConnection() || getFakeCentrifugeConnection();
    }

    return connection;
}

// Для es5 кода (без использования модулей)
window.getCentrifugeConnection = getCentrifugeConnection;

export { getCentrifugeObject };
export default getCentrifugeConnection;
