import React from 'react';
import PropTypes from 'prop-types';
import { A, TooltipContainer, IUIPropTypes } from 'intdev-ui';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { generateUuid } from '@/common/uuid';
import { showNotification } from '@/common/helpers/showNotification';
import { CentriSubscribe } from '@/common/components/CentriSubscribe';
import { apiUrls } from '@/store/constants/apiUrls';
import { COIN_ICON, COIN_DISABLED_ICON } from '../../constants/constants';
import { PeopleTooltip } from '../../../users/components/CounterTooltip/PeopleTooltip';


import '../Store.css';


const DONATION_CHANNEL = 'store-donate-author';
const iconStyle = { height: '14px', width: '14px' };


export class DonateAuthorButton extends React.Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        objectId: PropTypes.number.isRequired,
        transferPassed: PropTypes.bool,
        style: IUIPropTypes.style,
        fetchTransitions: PropTypes.bool.isRequired,
        transitions: PropTypes.arrayOf(PropTypes.object),
        userId: PropTypes.number,
        authorId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        transferPassed: false,
        style: {},
        transitions: [],
        userId: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            transferPassed: props.transferPassed,
            transitions: props.transitions,
            userId: props.userId,
            peopleTooltipIsActive: false,
            disabled: false,
            cancelUuid: null,
            popoverOpen: false,
            balance: undefined,
            cancelAllowed: false,
            timeoutId: null,
        };
    }

    componentDidMount() {
        if (!this.state.userId) {
            this.setUser();
        }
        if (this.props.fetchTransitions) {
            this.fetchTransitions();
        } else {
            this.checkTransferPassed(this.state.userId, this.state.transitions);
        }
    }

    setUser = () => {
        const user = window.USER_PROFILE.id;
        this.setState({ userId: user });
        this.checkTransferPassed(user, this.state.transitions);
    };

    checkTransferPassed = (userId, transitions) => {
        const transitionSenderIds = transitions.map(item => item.user_id);
        if (transitionSenderIds.includes(userId)) {
            this.setState({ transferPassed: true });
        }
    };

    fetchTransitions = () => {
        callApi(apiUrls.fetchTransitionsForPost(this.props.objectId)).then((data) => {
            this.setState({ disabled: true });
            if (data.transitions) {
                this.setState({ transitions: data.transitions });
                this.checkTransferPassed(this.props.userId, data.transitions);
                this.setState({ disabled: false });
            }
        });
    };

    openPopover = () => {
        this.setState({ popoverOpen: true });
    };

    disableCancel = () => {
        this.setState({
            cancelAllowed: false,
            cancelUuid: null,
            transferPassed: true,
        });
    };

    handleTransfer = () => {
        if (!this.state.disabled) {
            if (!this.state.transferPassed && this.state.userId !== this.props.authorId) {
                this.setState({ disabled: true });

                const cancelUuid = generateUuid();
                const formData = {
                    object_id: this.props.objectId,
                    ctype_id: this.props.contentTypeId,
                    uuid: cancelUuid,
                };
                this.setState({ cancelUuid });

                callApi(apiUrls.donatePostAuthor, 'POST', formData, 'formdata').then((data) => {
                    this.setState({
                        disabled: false,
                        balance: data.balance || 0,
                    });
                    if (data.result === 'ok') {
                        if (data.transfer_passed) {
                            this.setState({
                                popoverOpen: true,
                                transferPassed: true,
                            });
                        } else {
                            this.setState({
                                balance: this.state.balance - 1,
                                cancelAllowed: true,
                                popoverOpen: true,
                            }, () => {
                                const timeoutId = setTimeout(this.disableCancel, 60000);
                                this.setState({ timeoutId });
                            });
                            showNotification({ level: 'success', message: data.msg });
                        }
                    } else if (data.result === 'insufficient_balance' || this.state.balance < 1) {
                        this.setState({ popoverOpen: true });
                    } else {
                        showNotification({ level: 'error', message: data.msg });
                    }
                }).catch(() => {
                    showNotification({ level: 'error', message: 'Что-то пошло не так' });
                });
            } else {
                this.setState({ popoverOpen: true });
            }
        }
    };

    cancelTransfer = () => {
        this.setState({ disabled: true });
        callApi(apiUrls.cancelDonation, 'POST', { uuid: this.state.cancelUuid }, 'formdata').then((data) => {
            this.setState({ disabled: false });
            if (data.result === 'ok') {
                this.setState({
                    balance: this.state.balance + 1,
                    cancelAllowed: false,
                    cancelUuid: null,
                    popoverOpen: false,
                });
                clearTimeout(this.state.timeoutId);
                this.setState({ timeoutId: null });
                showNotification({ level: 'success', message: data.msg });
            } else {
                showNotification({ level: 'error', message: data.msg });
            }
        }).catch(() => {
            showNotification({ level: 'error', message: 'Что-то пошло не так' });
        });
    };

    handleShowPeopleTooltip = () => {
        this.setState({ peopleTooltipIsActive: true });
    };

    handleHidePeopleTooltip = () => {
        this.setState({ peopleTooltipIsActive: false });
    };

    handleCentriMessage = (data) => {
        if (data[this.props.objectId]) {
            const transitions = this.state.transitions.slice();
            transitions.push(data[this.props.objectId]);
            this.setState({ transitions });
        }
    };

    render() {
        let counter = null;
        const tooltipItemInfo = {
            likes: this.state.transitions.map(
                user => Object.assign({}, user, { url: `/users/${user.username}/` }),
            ),
            num_likes_to_show: 7,
        };
        if (this.state.transitions && this.state.transitions.length) {
            counter = this.state.transitions.length;
        }

        let reason = null;
        if (this.state.transferPassed) {
            reason = 'Автор поста уже получил от тебя 1 коин';
        } else if (this.state.balance !== undefined && this.state.balance < 1) {
            reason = 'Недостаточно коинов для перевода';
        }
        if (this.props.authorId === this.state.userId) {
            reason = 'Извини, переводить коины себе нельзя';
        }

        const action = (
            <div className="store-donate-popover">
                {
                    this.state.cancelAllowed
                        ? (
                            <A
                                className="store-donate-ref"
                                role="button"
                                onClick={ this.state.disabled ? () => {} : this.cancelTransfer }
                            >
                                Отменить перевод
                            </A>
                        )
                        : null
                }
            </div>
        );

        const popoverContents = reason && (!this.state.cancelAllowed || this.state.userId === this.props.authorId)
            ? <div className="store-donate-forbidden">{ reason }</div>
            : action;

        return (
            <div
                className="store-donate-button"
            >
                <div style={ { display: 'flex' } }>
                    <TooltipContainer
                        tooltip={
                            this.state.popoverOpen
                                ? popoverContents
                                : reason || <span>Перевести 1 коин автору поста</span>
                        }
                        position={ 'top_left' }
                        tooltipStyles={ {
                            width: '230px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        } }
                        style={ Object.assign({}, { alignItems: 'unset', display: 'flex' }, this.props.style) }
                    >
                        <img
                            src={
                                this.state.transferPassed || this.state.userId === this.props.authorId
                                    ? COIN_DISABLED_ICON
                                    : COIN_ICON
                            }
                            alt="коин"
                            style={ iconStyle }
                            onClick={
                                (this.state.cancelAllowed || this.state.transferPassed)
                                    ? this.openPopover
                                    : this.handleTransfer
                            }
                        />
                    </TooltipContainer>
                    {
                        counter
                            ? (
                                <div
                                    className="store-donators"
                                    style={ {
                                        color: this.state.transferPassed
                                        ||
                                        this.state.userId === this.props.authorId
                                            ? '#7f8191'
                                            : 'red',
                                    } }
                                    onMouseEnter={ this.handleShowPeopleTooltip }
                                    onMouseLeave={ this.handleHidePeopleTooltip }
                                >
                                    { counter }
                                    <PeopleTooltip
                                        isTooltipActive={ this.state.peopleTooltipIsActive }
                                        tooltipItemInfo={ tooltipItemInfo }
                                    />
                                </div>
                            ) : null
                    }
                </div>
                <CentriSubscribe
                    onMessage={ this.handleCentriMessage }
                    channel={ DONATION_CHANNEL }
                />
            </div>
        );
    }
}
