/*
    Хук, который возвращает объект типа {
        isDesktop: true,
        isLargeScreen: false,
        isMediumScreen: false,
        isSmallScreen: false,
    } на основе медиа-запросов и брэйкпойнтов, принятых в проекте.
*/
import React from 'react';
import { shallowEqual } from 'react-redux';

const MEDIA_QUERY_LIST_OBJECTS = {
    desktop: window.matchMedia('screen and (min-width: 961px)'),
    largeScreen: window.matchMedia('screen and (min-width: 641px) and (max-width: 960px)'),
    mediumScreen: window.matchMedia('screen and (min-width: 481px) and (max-width: 640px)'),
    smallScreen: window.matchMedia('screen and (max-width: 480px)'),
};

const getMediaWidth = () => {
    const mediaWidth = {
        isDesktop: MEDIA_QUERY_LIST_OBJECTS.desktop.matches,
        isLargeScreen: MEDIA_QUERY_LIST_OBJECTS.largeScreen.matches,
        isMediumScreen: MEDIA_QUERY_LIST_OBJECTS.mediumScreen.matches,
        isSmallScreen: MEDIA_QUERY_LIST_OBJECTS.smallScreen.matches,
    };
    if (!Object.values(mediaWidth).includes(true)) {
        mediaWidth.isDesktop = true;
    }
    return mediaWidth;
};

export const useMediaWidth = () => {
    const [mediaWidth, setMediaWidth] = React.useState(() => getMediaWidth());
    const current = React.useRef(mediaWidth);

    React.useEffect(() => {
        const listenerFunc = () => {
            const newValue = getMediaWidth();
            if (!shallowEqual(newValue, current.current)) {
                current.current = newValue;
                setMediaWidth(newValue);
            }
        };

        const listeners = {};
        Object.entries(MEDIA_QUERY_LIST_OBJECTS).forEach(([screen, mql]) => {
            mql.addEventListener('change', listenerFunc);
            listeners[screen] = listenerFunc;
        });

        return () => Object.entries(listeners).forEach(([screen, listener]) => {
            MEDIA_QUERY_LIST_OBJECTS[screen].removeEventListener('change', listener);
        });
    }, []);

    return mediaWidth;
};
