import React from 'react';
import PropTypes from 'prop-types';

export const Arrow = ({ onClick, className }) => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={ onClick }
        className={ className }
    >
        <path d="M6.46355 7.45095C6.75779 7.7521 7.24221 7.7521 7.53645 7.45095L12.8998 1.96164C13.3638 1.48671 13.0273 0.6875 12.3634 0.6875H1.63664C0.972652 0.6875 0.636163 1.48671 1.10019 1.96164L6.46355 7.45095Z" fill="#E4003C" />
    </svg>
);

Arrow.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

Arrow.defaultProps = {
    className: '',
};
