import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'intdev-ui';

export default class PopoverContainer extends Component {
    static propTypes = {
        popoverContent: PropTypes.node.isRequired,
        anchorOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string,
        }),
        targetOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string,
        }),
        popoverStyle: PropTypes.shape({
            key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    };

    static defaultProps = {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        targetOrigin: { horizontal: 'middle', vertical: 'bottom' },
        popoverStyle: { padding: '6px', marginTop: '-5px' },
    };

    state = {
        open: false,
        wrapperHovered: false,
        popoverHovered: false,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleMouseEnterWrapper = () => this.setState({ open: true, wrapperHovered: true });

    handleMouseLeaveWrapper = () => {
        this.setState({ wrapperHovered: false });
        setTimeout(() => {
            if (!this.state.popoverHovered) {
                this.safeSetState({ open: false });
            }
        }, 100);
    };

    handleMouseEnterPopover = (event) => {
        this.setState({ open: true, popoverHovered: true });
        event.stopPropagation();
    };

    handleMouseLeavePopover = (event) => {
        this.setState({ popoverHovered: false });
        setTimeout(() => {
            if (!this.state.wrapperHovered) {
                this.safeSetState({ open: false });
            }
        }, 100);
        event.stopPropagation();
    };

    safeSetState(state) {
        if (this.mounted) {
            this.setState(state);
        }
    }

    render() {
        const {
            popoverContent, anchorOrigin, targetOrigin, popoverStyle, ...rest
        } = this.props;
        return (
            <>
                <div
                    ref={ (el) => { this.anchorEl = el; } }
                    onMouseEnter={ this.handleMouseEnterWrapper }
                    onMouseLeave={ this.handleMouseLeaveWrapper }
                    { ...rest }
                />
                {
                    this.anchorEl
                    && (
                        <Popover
                            anchorEl={ this.anchorEl }
                            open={ this.state.open }
                            anchorOrigin={ anchorOrigin }
                            targetOrigin={ targetOrigin }
                            useLayerForClickAway={ false }
                            style={ popoverStyle }
                            onMouseEnter={ this.handleMouseEnterPopover }
                            onMouseLeave={ this.handleMouseLeavePopover }
                        >
                            { popoverContent }
                        </Popover>
                    )
                }
            </>
        );
    }
}
