import React, { PureComponent } from 'react';
import { A } from 'intdev-ui';
import PropTypes from 'prop-types';
import './styles.css';
import { ALink } from '../ALink';


class PageHeader extends PureComponent {
    static propTypes = {
        text: PropTypes.node,
        rightElement: PropTypes.node,
        breadcrumb: PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
            inRouter: PropTypes.bool,
        }),
    };

    static defaultProps = {
        text: 'Заголовок',
        rightElement: null,
        breadcrumb: null,
    };

    get breadcrumb() {
        const { breadcrumb } = this.props;
        if (!breadcrumb) {
            return null;
        }
        const title = breadcrumb.title || 'Назад';
        return (
            <div className="page-header__breadcrumb">
                { breadcrumb.inRouter
                    ? <ALink mode="breadcrumb" to={ breadcrumb.url }>{ title || 'Назад' }</ALink>
                    : <A mode="breadcrumb" href={ breadcrumb.url }>{ title || 'Назад' }</A> }
            </div>
        );
    }

    render() {
        const { text, rightElement } = this.props;
        return (
            <div className="page-header__container">
                { this.breadcrumb }
                <div className="page-header__body">
                    <div className="page-header__text">
                        { text }
                    </div>
                    { rightElement ? <div>{ rightElement }</div> : null }
                </div>
            </div>
        );
    }
}

export default PageHeader;
