import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { SwitchLink } from './SwitchLink';
import { albumShape } from '../constants/propTypes';
import '../styles/albums.css';

export const Album = ({
    album: {
        url,
        user,
        title,
        count,
        cover,
        isInDraft,
    },
    canEdit,
    inRouter,
}) => {
    const previewStyle = {
        backgroundImage: `url(${cover})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };

    return (
        <div className="album-preview-container">
            {
                canEdit && (
                    <SwitchLink
                        inRouter={ inRouter }
                        to={ url }
                        className="album-edit-button"
                    >
                        <i title="Редактировать" className="icon icon_actions--edit" />
                    </SwitchLink>
                )
            }
            {
                isInDraft && (
                    <div className="album-status album-status--draft">
                        Не опубликован
                    </div>
                )
            }
            <SwitchLink to={ url } inRouter={ inRouter }>
                <div className="album-cover" style={ previewStyle } />
            </SwitchLink>
            <div className="album-info">
                <SwitchLink
                    inRouter={ inRouter }
                    to={ url }
                    className="album-info-header"
                >
                    <div className="album-info__title">
                        { title }
                    </div>
                    <div className="album-info__count">
                        { count }
                    </div>
                </SwitchLink>
                <A href={ user.url } className="album-info__user">
                    { user.fullName }
                </A>
            </div>
        </div>
    );
};

Album.propTypes = {
    album: albumShape.isRequired,
    canEdit: PropTypes.bool.isRequired,
    inRouter: PropTypes.bool,
};

Album.defaultProps = {
    inRouter: true,
};
