import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Checkbox, Dialog } from 'intdev-ui';

import { PermissionManager } from '../../../common/components/ProtectorManagers';
import contentTypes from '../../../common/constants/contentTypes';
import { showErrorNotification, showSuccessNotification } from '../../../common/helpers/showNotification';


export default class VisibleMenuButton extends React.Component {
    static propTypes = {
        buttonStyle: PropTypes.shape(),
        icon: PropTypes.node,
        title: PropTypes.string,
        quizId: PropTypes.number.isRequired,
        contentTypeId: PropTypes.number.isRequired,
        permission: PropTypes.string,
        onRestrictQuiz: PropTypes.func.isRequired,
        onUnrestrictQuiz: PropTypes.func.isRequired,
        isQuizRestricted: PropTypes.bool,
    };

    static defaultProps = {
        buttonStyle: {},
        icon: null,
        title: undefined,
        permission: 'protector.view_restricted_objects',
        isQuizRestricted: false,
    };

    state = {
        dialogOpen: false,
        isQuizRestricted: this.props.isQuizRestricted,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.dialogOpen && !prevState.dialogOpen) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isQuizRestricted: this.props.isQuizRestricted });
        }
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setState({
            dialogOpen: true,
        });
    };

    handleSuccessOnDialog = () => {
        const { quizId } = this.props;

        const isValid = this.managerRef.validateReason();
        if (isValid) {
            this.managerRef.saveManager(quizId)
                .then(() => {
                    this.saveQuizRestriction().then(this.handleDialogClose);
                    showSuccessNotification('Настройки видимости успешно обновлены.');
                })
                .catch(() => {
                    showErrorNotification('Не удалось обновить настройки видимости.');
                });
        }
    };

    handleDialogClose = () => {
        this.setState({ dialogOpen: false });
    };

    saveQuizRestriction = () => {
        if (this.state.isQuizRestricted === this.props.isQuizRestricted) {
            return new Promise(resolve => resolve());
        }
        if (this.state.isQuizRestricted) {
            return this.props.onRestrictQuiz(this.props.quizId);
        }
        return this.props.onUnrestrictQuiz(this.props.quizId);
    };

    handleToggleRestriction = () => {
        this.setState(prevState => ({ isQuizRestricted: !prevState.isQuizRestricted }));
    };

    render() {
        const {
            icon,
            buttonStyle,
            title,
        } = this.props;
        const cTypes = [
            contentTypes.USERS_USERPROFILE, contentTypes.USERS_GROUP, contentTypes.LOCATION_TOWN,
            contentTypes.STRUCTURE_UNIT, contentTypes.STRUCTURE_PROJECT_STRUCTURAL,
        ];
        const restrictedMessage = 'Сейчас опрос могут проходить только те сотрудники,' +
            ' данные которых проходят по выбранным фильтрам';
        const unrestrictMessage = 'Сейчас опрос могут проходить все сотрудники';
        const permManagerClassNames = classnames({
            hidden: !this.state.isQuizRestricted,
            'quiz-detail__visible-form-item': true,
        });
        return (
            <div className="quiz-detail__menu-button">
                <Button
                    icon={ icon }
                    style={ buttonStyle }
                    onClick={ this.handleClick }
                    title={ title }
                />
                <Dialog
                    title="Настройка видимости"
                    actions={ [
                        <Button
                            label="Сохранить"
                            primary
                            onClick={ this.handleSuccessOnDialog }
                        />,
                        <Button
                            label="Отмена"
                            onClick={ this.handleDialogClose }
                        />,
                    ] }
                    modal={ false }
                    open={ this.state.dialogOpen }
                    onRequestClose={ this.handleDialogClose }
                >
                    <div className="quiz-detail__visible-form">
                        <div className="quiz-detail__visible-form-help-text">
                            { (this.state.isQuizRestricted) ? restrictedMessage : unrestrictMessage }
                        </div>
                        <div className="quiz-detail__visible-form-item">
                            <Checkbox
                                checked={ this.state.isQuizRestricted }
                                onChange={ this.handleToggleRestriction }
                            /> Ограничить видимость опроса
                        </div>
                        {
                            <div className={ permManagerClassNames }>
                                <label className="quiz-detail__visible-form-label">
                                    Выбери сотрудников, группы, подразделения, проекты, города
                                </label>
                                <PermissionManager
                                    ref={ (el) => { this.managerRef = el; } }
                                    contentType={ this.props.contentTypeId }
                                    objectId={ this.props.quizId }
                                    permissionNames={ [this.props.permission] }
                                    contentTypesForSuggest={ cTypes }
                                    placeholder="Начните вводить"
                                    defaultReason="Изменение видимости опроса"
                                    hideReasonField
                                />
                            </div>
                        }
                    </div>
                </Dialog>
            </div>
        );
    }
}
