/* eslint-disable no-plusplus,no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
import { IUIPropTypes } from 'intdev-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EventColumn from './EventColumn';
import { getColumnGroups } from '../reducers';
import NavigationArrow from './NavigationArrow';
import { setLeftArrowVisibility, setRightArrowVisibility } from '../actions';
import { EVENT_SHAPE } from '../shapes';
import { GRID_DEFAULT_GROUP_NAME } from '../constants/defaults';

const COLUMNS_ON_PAGE = 3;
const SCROLL_ANIMATE_SPEED = 200;

const BACKWARD_FLAG = 1 << 0;
const FORWARD_FLAG = 1 << 1;
const INITIAL_SCROLL_FLAG = 1 << 2;

const styles = {
    body: {
        position: 'relative',
        display: 'flex',
    },
    columnsContainer: {
        overflow: 'hidden',
        flex: '1 1 auto',
        paddingBottom: '12px',
    },
    columnsDiv: {
        position: 'relative',
        marginTop: '-24px',
        top: '30px',
        display: 'flex',
        overflowX: 'scroll',
        paddingBottom: '30px',
    },
    columnDiv: {
        flex: '0 0 auto',
        // if width changed, COLUMNS_ON_PAGE must be changed appropriately
        width: '31.9%',
        marginRight: '2%',
    },

};

const getEventColumnIdWithGroup = group => `event-column-${
    (group.eventsGroup[0] || { events: [{ id: null }] }).events[0].id
}`;

class EventGridComponent extends React.Component {
    /*
        Component that renders events, scroll arrows.

        componentDidMount: allows to focus column that contains present event

        componentWillUpdate + componentDidUpdate: in case of update of eventList with events that should
        be rendered in front of existing events, allows (if there is no column group recreation)
        to block scroll focus on current column.
        - componentWillUpdate: sets current visible column
        - componentDidUpdate: sets scroll position to column that where visible before rerender

    */
    static propTypes = {
        // used for arrowUI state
        gridGroupName: PropTypes.string,
        styleColumnsContainer: IUIPropTypes.style,
        showEventLabel: PropTypes.bool,
        // should left arrow overflow container
        overflowLeftArrow: PropTypes.bool,
        // max height of grid column in 'blocks'
        columnMaxElements: PropTypes.number,
        // events to display, going to be grouped by name and separeted by date
        eventList: PropTypes.arrayOf(EVENT_SHAPE).isRequired,
        // dispatch
        setRightArrowVisibility: PropTypes.func.isRequired,
        setLeftArrowVisibility: PropTypes.func.isRequired,
    };

    static defaultProps = {
        gridGroupName: GRID_DEFAULT_GROUP_NAME,
        styleColumnsContainer: undefined,
        columnMaxElements: 12,
        overflowLeftArrow: false,
        showEventLabel: true,
    };

    constructor(props) {
        super(props);

        this.columnGroupsCache = {};

        this.innerLoading = 0;

        this.targetVisibleColumn = null;
        this.columns = [];
    }

    componentDidMount() {
        this.scrollToPresentEvent();
    }

    UNSAFE_componentWillUpdate() {
        // prevent change of visible column on events list change
        if (this.getColumnGroups().length < this.columns.length) {
            // there is less columns then where before, but we are only cares about columns append
            return;
        }
        const scrollTargetIndex = this.getVisibleIndex(false);
        if (scrollTargetIndex !== null) {
            this.targetVisibleColumn = {
                targetIndex: scrollTargetIndex,
                targetColumnClassName: this.columns[scrollTargetIndex].className,
                previousColumnLength: this.columns.length,
            };
        }
    }

    componentDidUpdate() {
        // prevent change of visible column on events list change
        if (this.targetVisibleColumn && !this.innerLoading) {
            const {
                targetIndex,
                targetColumnClassName,
                previousColumnLength,
            } = this.targetVisibleColumn;
            // if columns where shifted
            if (this.columns[targetIndex].className !== targetColumnClassName) {
                const indexOffset = this.columns.length - previousColumnLength;
                this.performScroll(targetIndex + indexOffset, true, 0);
                this.targetVisibleColumn = null;
            }
        }
    }

    getColumnGroups = () => {
        if (
            (this.columnGroupsCache.columnMaxElements !== this.props.columnMaxElements)
            ||
            (this.columnGroupsCache.eventList !== this.props.eventList)
        ) {
            const {
                eventList,
                columnMaxElements,
                showEventLabel,
            } = this.props;
            this.columnGroupsCache = {
                cachedValue: getColumnGroups(eventList, columnMaxElements, showEventLabel),
                eventList: this.props.eventList,
                columnMaxElements: this.props.columnMaxElements,
            };
        }
        return this.columnGroupsCache.cachedValue;
    };

    getVisibleIndex = (isForward) => {
        /*
        * finds last element in given direction, which is fully visible
        *
        * assumes each item in this.columns is not null
        * */
        if (this.columnContainer && this.columns && this.columns[0]) {
            const isElementFurther = (col) => {
                // is left corner of element further then right corner of container
                const elRect = col.getBoundingClientRect();
                const contRect = this.columnContainer.getBoundingClientRect();
                return elRect.left >= contRect.right;
            };
            const isVisible = (col) => {
                const elRect = col.getBoundingClientRect();
                const contRect = this.columnContainer.getBoundingClientRect();
                return (elRect.left >= contRect.left && elRect.left <= contRect.right) ||
                    (elRect.right >= contRect.left && elRect.right <= contRect.right);
            };
            const isFullyVisible = (col) => {
                const elRect = col.getBoundingClientRect();
                const contRect = this.columnContainer.getBoundingClientRect();
                return (elRect.left >= contRect.left && elRect.left <= contRect.right) &&
                    (elRect.right >= contRect.left && elRect.right <= contRect.right);
            };
            const isIndexInBound = index => index >= 0 && index < this.columns.length;

            // find any visible
            let searchIndex = Math.floor(this.columns.length / 2);
            let searchStep = 2;
            let anyVisible = 0;

            while (this.columns.length > searchStep) { // while (true)
                const increment = Math.floor(this.columns.length / (2 ** searchStep++)) || 1;
                const direction = isElementFurther(this.columns[searchIndex]) ? -1 : 1;
                searchIndex += increment * direction;
                if (isVisible(this.columns[searchIndex])) {
                    anyVisible = searchIndex;
                    break;
                }
            }
            const preciseStep = isForward ? 1 : -1;

            // find fully visible
            while (isIndexInBound(anyVisible + preciseStep)) {
                // if current element not fully visible, check previous
                if (!isFullyVisible(this.columns[anyVisible]) &&
                    (isIndexInBound(anyVisible - preciseStep) &&
                        isFullyVisible(this.columns[anyVisible - preciseStep]))) {
                    return anyVisible - preciseStep;
                } else if (!isFullyVisible(this.columns[anyVisible + preciseStep])) {
                    return anyVisible;
                }
                anyVisible += preciseStep;
            }
            return anyVisible;
        }
        return null;
    };

    getScrollTarget = (isScrollForward) => {
        /*
        * finds first element in scroll direction, which is not fully visible
        * */
        const isIndexInBound = index => index >= 0 && index < this.columns.length;
        const preciseStep = isScrollForward ? 1 : -1;
        const visibleIndex = this.getVisibleIndex(isScrollForward);
        if (isIndexInBound(visibleIndex + preciseStep)) {
            return visibleIndex + preciseStep;
        }
        return visibleIndex;
    };

    performScroll = (() => {
        /*
        * scrolls columns by COLUMNS_ON_PAGE
        * after function starts, disables for SCROLL_ANIMATE_SPEED
        * */
        let running = false;
        return (scrollTargetIndex, toRightCorner, speed) => new Promise((resolve) => {
            if (running) {
                resolve(null);
                return;
            }
            running = true;
            try {
                const scrollTarget = this.columns[scrollTargetIndex];
                const container = this.columnContainer;
                const contRect = container.getBoundingClientRect();
                const colRect = scrollTarget.getBoundingClientRect();
                let margin;

                if (toRightCorner) {
                    margin = colRect.left - (contRect.left + 1); // 1 is border widths
                } else {
                    margin = colRect.right - (contRect.right - 1);
                }
                const scrollLeft = container.scrollLeft + Math.floor(margin + 0.5);
                $(container).animate(
                    {
                        scrollLeft,
                    },
                    speed,
                    'swing',
                    () => { running = false; resolve(true); },
                );
            } catch (err) {
                running = false;
                throw err;
            }
        });
    })();

    scrollToPresentEvent = () => {
        let mostPresentDay = null;
        let mostPresentIndex = null;
        const nowDay = moment().startOf('day').valueOf();

        // assume that each this.columns represent columnGroups with corresponding index
        this.getColumnGroups().forEach((group, index) => {
            const eventGroupDay = moment(group.eventsGroup[0].events[0].startTime).startOf('day').valueOf();

            const oldDistance = mostPresentDay - nowDay;
            const newDistance = eventGroupDay - nowDay;
            if (
                (mostPresentDay === null)
                ||
                (newDistance >= 0 && oldDistance < 0)
                ||
                (
                    Math.sign(newDistance + 1) === Math.sign(oldDistance + 1)
                    &&
                    Math.abs(newDistance) < Math.abs(oldDistance)
                )
            ) {
                mostPresentDay = eventGroupDay;
                mostPresentIndex = index;
            }
        });
        // set INITIAL_SCROLL_FLAG
        this.innerLoading |= INITIAL_SCROLL_FLAG;
        if (mostPresentIndex !== null) {
            this.performScroll(mostPresentIndex, true, 0).then(() => {
                // remove INITIAL_SCROLL_FLAG
                this.innerLoading &= ~INITIAL_SCROLL_FLAG;
            });
        }
    };

    checkArrowVisibility = () => {
        const container = this.columnContainer;
        if (container) {
            const leftArrowVisible = container.scrollLeft !== 0;
            const rightArrowVisible = (container.clientWidth + container.scrollLeft)
                !== container.scrollWidth;
            this.props.setLeftArrowVisibility(leftArrowVisible, this.props.gridGroupName);
            this.props.setRightArrowVisibility(rightArrowVisible, this.props.gridGroupName);
        }
    };

    handleForwardButtonClick = () => {
        // set FORWARD_FLAG
        this.innerLoading |= FORWARD_FLAG;
        const scrollTargetIndex = this.getScrollTarget(true);
        if (scrollTargetIndex !== null) {
            this.performScroll(scrollTargetIndex, true, SCROLL_ANIMATE_SPEED).then(() => {
                // remove FORWARD_FLAG
                this.innerLoading &= ~FORWARD_FLAG;
            });
        }
    };

    handleBackButtonClick = () => {
        // set BACKWARD_FLAG
        this.innerLoading |= BACKWARD_FLAG;
        const scrollTargetIndex = this.getScrollTarget(false);
        if (scrollTargetIndex !== null) {
            this.performScroll(scrollTargetIndex, false, SCROLL_ANIMATE_SPEED).then(() => {
                // remove BACKWARD_FLAG
                this.innerLoading &= ~BACKWARD_FLAG;
            });
        }
    };

    render() {
        // array for column refs
        this.columns = [];
        const enableNavigation = this.getColumnGroups().length > COLUMNS_ON_PAGE;
        return (
            <div style={ styles.body }>
                { enableNavigation &&
                    <NavigationArrow
                        left
                        gridGroupName={ this.props.gridGroupName }
                        onClick={ this.handleBackButtonClick }
                        overflowLeftArrow={ this.props.overflowLeftArrow }
                    />
                }
                <div style={ {
                    ...styles.columnsContainer,
                    ...this.props.styleColumnsContainer,
                } }
                >
                    <div
                        ref={ (ref) => {
                            this.columnContainer = ref;
                            if (ref) {
                                // eslint-disable-next-line no-param-reassign
                                ref.onscroll = this.checkArrowVisibility;
                                this.checkArrowVisibility();
                            }
                        } }
                        style={ styles.columnsDiv }
                    >
                        { this.getColumnGroups().map((group, i) => (
                            <div
                                style={ styles.columnDiv }
                                className={ getEventColumnIdWithGroup(group) }
                                key={ getEventColumnIdWithGroup(group) }
                                ref={ (ref) => { this.columns[i] = ref; } }
                            >
                                <EventColumn
                                    showEventLabel={ this.props.showEventLabel }
                                    { ...group }
                                />
                            </div>
                        )) }
                    </div>
                </div>
                { enableNavigation &&
                    <NavigationArrow
                        right
                        gridGroupName={ this.props.gridGroupName }
                        onClick={ this.handleForwardButtonClick }
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    overflowLeftArrow: state.calendarContainerProps.overflowLeftArrow,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setRightArrowVisibility,
    setLeftArrowVisibility,
}, dispatch);

const EventGrid = connect(mapStateToProps, mapDispatchToProps)(EventGridComponent);

export default EventGrid;
