import * as Sentry from '@sentry/browser';

/**
 * Функция, которая в идеале должна избавить от chunkLoadError. При возникновении этой ошибки, парсим урл,
 * по которому браузер пытался загрузить чанк, вычленяем оттуда chunkName, и запрашиваем с бэка информацию о том,
 * по какому урлу сейчас лежит чанк. Чтобы точно работало, лучше всего указывать webpackChunkName в pages.
 * Если чанк не найден, перезагружаем страницу.
 *
 * Как воспроизвести chunkLoadError:
 * ** делаем прод сборку приложения, делаем так, чтобы статику отдавал nginx
 * ** заходим на какую-нибудь страницу
 * ** не перезагружаю страницу, меняем какой-нибудь файл в одном из спашных приложений. билдим по новой (в общем
 *      делаем так, чтобы изменился хэш чанка. еще можно в аутпуте вебпака chunkHash заменить на hash, чтобы
 *      хэш менялся при любом изменении)
 * ** переходим на спашную страницу, в которую внесли изменения
 * ** профит
 */
export const chunkLoader = importFn => new Promise((resolve) => {
    importFn()
        .then(resolve)
        .catch((error) => {
            if (error.name !== 'ChunkLoadError') {
                Sentry.withScope((scope) => {
                    scope.setExtra('error', error);
                    Sentry.captureMessage('Unexpected error upon chunk import');
                });
            }
            window.location.reload(true);
        });
});
