import moment from 'moment';
import update from 'immutability-helper';

import {
    CALENDAR_CONFIG_LOAD,
    EVENT_LOAD_FAILURE,
    EVENT_LOAD_START,
    EVENT_LOAD_SUCCESS,
    EVENT_LOCATIONS_LOAD_SUCCESS,
    EVENT_MODAL_CLEAN_VALIDATION_ERRORS,
    EVENT_MODAL_FIELD_VALUE_CHANGE,
    EVENT_MODAL_SET_VALIDATION_ERRORS,
    EVENT_SAVE_FAILURE,
    EVENT_SAVE_START,
    EVENT_SAVE_SUCCESS,
    PARSE_EXCEL_START,
    PARSE_EXCEL_FINISH,
    PARSE_EXCEL_ERROR,
    MODAL_OPEN,
} from '../constants/actions';
import durationFromTime from '../helpers/durationFromTime';
import ceilMomentWithMinuteStep from '../helpers/ceilMomentWithMinuteStep';
import formatDuration from '../helpers/formatDuration';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';
import { CREATE_TYPE_SINGLE } from '../components/modals/EventEditModal';

// selectors

export const getModalData = (state) => {
    const { config, ...other } = state;
    return { ...config, ...other };
};

const periodMinutesValueByStep = {
    5: [0, 5, 10, 15, 30, 45, 60, 90, 120],
};

const getPeriodMinutes = step => periodMinutesValueByStep[step] || [0, 1, 2, 3, 4, 6, 8].map(i => i * step);


export const getCopyPeriodMinutesValues = state => (
    getPeriodMinutes(state.config.minuteStartStep).filter(
        e => e <= moment(state.copyEndTime, 'HH:mm')
            .diff(moment(state.copyStartTime, 'HH:mm'), 'minutes'),
    )
);


export const getCopyPeriodDaysValues = (state) => {
    const arrLength = moment(state.copyEndDate)
        .diff(moment(state.copyStartDate), 'days') + 1;
    if (arrLength < 1) {
        return [];
    }
    const arrMinLenght = Math.min(14, arrLength);
    return Array.from({ length: arrMinLenght }, (_, i) => i + 1);
};

// helpers

const getMinCreateTime = (config) => {
    const propMinCreate = moment.duration(config.minCreateHour, 'hours');

    const nowMinCreate = durationFromTime(ceilMomentWithMinuteStep(moment(), config.minuteStartStep));

    return propMinCreate.asMilliseconds() > nowMinCreate.asMilliseconds() ?
        propMinCreate
        :
        nowMinCreate;
};

export const getStartMoment = (config) => {
    /*
    * gets now, corrects according to min and max createTime,
    * ceil it according to minuteStartStep
    * */
    const now = moment().add(1, 'minute');
    const minCreateTime = getMinCreateTime(config);
    // if now < minTime, now = minTime
    if (minCreateTime.asMilliseconds() > durationFromTime(now).asMilliseconds()) {
        now.startOf('day').add(minCreateTime);
    }
    // if now > maxTime, now = minTime + 1 day
    const maxCreateTimeMoment = moment.duration(
        moment(config.maxCreateHour, 'HH').diff(moment(config.minEventDuration, 'mm')),
    );
    if (durationFromTime(now).asMilliseconds() > maxCreateTimeMoment.asMilliseconds()) {
        now.hours(config.minCreateHour).add(1, 'days');
    }
    // ceil according to minutes step
    return ceilMomentWithMinuteStep(now, config.minuteStartStep);
};

export const getInitialModalState = config => ({
    createType: CREATE_TYPE_SINGLE,
    name: '',
    location: null,
    locations: [],
    place: '',
    capacity: null,
    canPlus: 0,
    startDate: getStartMoment(config).startOf('day').toDate(),
    startTime: getStartMoment(config).format('HH:mm'),
    duration: formatDuration(moment.duration(config.minuteDurationStep, 'minutes')),
    copyStartDate: getStartMoment(config).startOf('day').toDate(),
    copyStartTime: getStartMoment(config).format('HH:mm'),
    copyEndDate: getStartMoment(config).startOf('day').toDate(),
    copyEndTime: getStartMoment(config).format('HH:mm'),
    copyPeriodMinutes: null,
    copyPeriodDays: null,
    owner: config.user,
    usersJoinAllowed: [],
    isSeries: false,
    validationErrors: {},
    processing: false,
});

export const INITIAL_CONFIG = {
    user: null,
    objectId: null,
    contentType: null,
    canChangeOwner: false,
    minCreateHour: 8, // used by getInitialModalState
    maxCreateHour: 22, // used by getInitialModalState
    minuteStartStep: 5, // used by getInitialModalState
    minEventDuration: 5,
    maxEventDuration: 600,
    minuteDurationStep: 5, // used by getInitialModalState
};

const eventModal = (state = {
    ...getInitialModalState(INITIAL_CONFIG),
    config: INITIAL_CONFIG,
}, action) => {
    switch (action.type) {
        case EVENT_MODAL_SET_VALIDATION_ERRORS: {
            /*
            * Sets validation state
            * Remaps given validationErrors from server names to form names
            * */
            const validationErrors = action.payload;
            const errors = { ...validationErrors };
            const camelizedErrors = objCamelFromSnake(errors);
            return {
                ...state,
                validationErrors: camelizedErrors,
            };
        }
        case EVENT_MODAL_CLEAN_VALIDATION_ERRORS: {
            const name = action.payload;
            const errorValue = state.validationErrors[name];
            if (errorValue && (!Array.isArray(errorValue) || errorValue.length)) {
                const newValidationErrors = { ...state.validationErrors };
                delete newValidationErrors[name];
                return {
                    ...state,
                    validationErrors: newValidationErrors,
                };
            }
            return state;
        }
        case CALENDAR_CONFIG_LOAD: {
            // on calendar config load, saves in to own state
            const {
                user,
                objectId,
                contentType,
                canChangeOwner,
                timeInterval,
                duration,
            } = action.payload;
            const [minCreateHour, maxCreateHour, minuteStartStep] = timeInterval;
            const [minEventDuration, maxEventDuration, minuteDurationStep] = duration;
            const newConfig = {
                ...state.config,
                user,
                objectId,
                contentType,
                canChangeOwner,
                minCreateHour,
                maxCreateHour,
                minuteStartStep,
                minEventDuration,
                maxEventDuration,
                minuteDurationStep,
            };
            return {
                ...state,
                ...getInitialModalState(newConfig),
                config: newConfig,
            };
        }
        case EVENT_SAVE_START:
        case EVENT_LOAD_START: {
            return {
                ...state,
                processing: true,
            };
        }
        case EVENT_LOAD_SUCCESS: {
            const eventData = action.payload;
            const camelizedData = objCamelFromSnake(eventData);

            const startDayMoment = moment(camelizedData.startTime).startOf('day');
            const startMoment = moment(camelizedData.startTime);

            return {
                ...state,
                ...camelizedData,
                processing: false,
                startDate: startDayMoment.toDate(),
                startTime: startMoment.format('HH:mm'),
                duration: formatDuration(moment.duration(
                    parseInt(camelizedData.durationSingle, 10), 'seconds')),
            };
        }
        case EVENT_LOCATIONS_LOAD_SUCCESS: {
            const data = objCamelFromSnake(action.payload);
            return update(state, { locations: { $set: data } });
        }
        case EVENT_SAVE_SUCCESS:
        case EVENT_SAVE_FAILURE:
        case EVENT_LOAD_FAILURE: {
            return {
                ...state,
                processing: false,
            };
        }
        case EVENT_MODAL_FIELD_VALUE_CHANGE: {
            const event = action.payload;

            // checks ether new field value corresponds to field pattern
            if (event.target.pattern &&
                event.target.value.match(event.target.pattern)[0] !== event.target.value) {
                return state;
            }

            // do not set not selected time values
            const timeValues = [
                'copyStartTime', 'copyEndTime', 'copyStartDate', 'copyEndDate', 'duration', 'startDate',
            ];
            if (timeValues.indexOf(event.target.name) !== -1) {
                if (!event.target.value) {
                    return state;
                }
            }

            const newState = {
                ...state,
                [event.target.name]: event.target.value,
            };
            delete newState.validationErrors[event.target.name];


            // make some checks for possible valid value violations due to state change
            if (event.target.name === 'copyStartTime' ||
                event.target.name === 'copyEndTime') {
                // checks that startTime lte endTime, sets endTime = startTime if not
                if (event.target.name === 'copyStartTime') {
                    if (moment.duration(newState.copyStartTime)
                        .subtract(moment.duration(newState.copyEndTime))
                        .as('seconds') > 0) {
                        newState.copyEndTime = newState.copyStartTime;
                    }
                }
                // checks is old copyPeriodMinutes value valid within new limits,
                // sets to closest allowed, if its not
                // Uses 'copyStartTime' and 'copyEndTime' for calculation of new allowed values,
                // so any start-end time modifications must be performed before this check.
                const allowedValues = getCopyPeriodMinutesValues(newState);
                const isCopyPeriodMinutesAllowed = allowedValues.includes(state.copyPeriodMinutes);
                if (!isCopyPeriodMinutesAllowed && newState.copyPeriodMinutes) {
                    newState.copyPeriodMinutes = allowedValues[allowedValues.length - 1];
                }
            }

            // make some checks for possible valid walue violations due to state change
            if (event.target.name === 'copyStartDate' ||
                event.target.name === 'copyEndDate') {
                // checks that startDate lte endDate, sets endDate = startDate if not
                if (event.target.name === 'copyStartDate') {
                    const copyStartDateMoment = moment(newState.copyStartDate);
                    if (copyStartDateMoment
                        .isAfter(newState.copyEndDate)) {
                        newState.copyEndDate = newState.copyStartDate;
                    }
                    // check for start moment violation after day change
                    const tooday = moment().startOf('day');
                    const isMinDaySelected = tooday.isSame(copyStartDateMoment.startOf('day'));
                    if (isMinDaySelected) {
                        newState.copyStartTime = getStartMoment(state.config).format('HH:mm');
                    }
                }
                // Checks is old copyPeriodDays value valid within new limits,
                // sets to closest allowed, if its not.
                // Uses 'copyStartDate' and 'copyEndDate' for calculation of new allowed values,
                // so any start-end date modifications must be performed before this check.
                const allowedValues = getCopyPeriodDaysValues(newState);
                const isCopyPeriodDaysAllowed = allowedValues
                    .some(val => val === state.copyPeriodDays);
                if (!isCopyPeriodDaysAllowed && newState.copyPeriodDays) {
                    newState.copyPeriodDays = allowedValues[allowedValues.length - 1];
                }
            }
            return newState;
        }

        case PARSE_EXCEL_START:
            return update(state, { processing: { $set: true } });

        case PARSE_EXCEL_ERROR:
        case PARSE_EXCEL_FINISH:
            return update(state, { processing: { $set: false } });

        case MODAL_OPEN:
            // we only need to clean state on event modal open
            // but for now you cannot open two modal simultaneously
            return {
                ...state,
                ...getInitialModalState(state.config),
            };

        default:
            return state;
    }
};

export default eventModal;
