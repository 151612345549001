import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconFaButton from '../../common/components/IconFaButton';
import { getLoadState, getNavigationArrowUiState } from '../reducers';

const styles = {
    arrowButtonStylesSetLeftBase: {
        styleHover: {
            color: '#444',
            transition: 'all 0.2s ease-in',
            background: 'rgba(245,245,245, 0.6)',
        },
        styleDisabled: {
            background: 'transparent',
            cursor: 'auto',
        },
        styleClick: {
            transition: 'all 0s',
            background: 'rgba(245,245,245, 0.8)',
        },
    },
    arrowButtonStylesSetLeftOverflow: {
        style: {
            position: 'absolute',
            height: '100%',
            left: '-28px',
            flex: '0 0 auto',
            width: '28px',
            fontSize: '22px',
            transition: 'all 0.2s ease-in-out',
            color: '#888',
            background: 'transparent',
        },
    },
    arrowButtonStylesSetLeftNormal: {
        style: {
            flex: '1 0 auto',
            width: '28px',
            height: 'unset',
            fontSize: '22px',
            transition: 'all 0.2s ease-in-out',
            color: '#888',
            background: 'transparent',
        },
    },
    arrowButtonStylesSetRight: {
        style: {
            flex: '1 0 auto',
            width: '28px',
            height: 'unset',
            fontSize: '22px',
            transition: 'all 0.2s ease-in-out',
            color: '#888',
            background: 'transparent',
        },
        styleHover: {
            color: '#444',
            transition: 'all 0.2s ease-in',
            background: 'rgba(245,245,245, 0.6)',
        },
        styleDisabled: {
            background: 'transparent',
            cursor: 'auto',
        },
        styleClick: {
            transition: 'all 0s',
            background: 'rgba(245,245,245, 0.8)',
        },
    },
};

const NavigationArrowComponent = (
    {
        left,
        loadingBackward,
        onClick,
        leftArrowVisible,
        overflowLeftArrow,
        right,
        loadingForward,
        rightArrowVisible,
    },
) => {
    return (
        <React.Fragment>
            {left &&
                <IconFaButton
                    iconClass={ loadingBackward ? 'fa-spinner fa-spin' : 'fa-arrow-left' }
                    title="Назад"
                    onClick={ onClick }
                    disabled={ !leftArrowVisible || loadingBackward }
                    { ...{
                        ...styles.arrowButtonStylesSetLeftBase,
                        ...(overflowLeftArrow ?
                            styles.arrowButtonStylesSetLeftOverflow
                            :
                            styles.arrowButtonStylesSetLeftNormal),
                    } }
                />
            }
            {right &&
                <IconFaButton
                    iconClass={ loadingForward ? 'fa-spinner fa-spin' : 'fa-arrow-right' }
                    title="Вперёд"
                    onClick={ onClick }
                    disabled={ !rightArrowVisible || loadingForward }
                    { ...styles.arrowButtonStylesSetRight }
                />
            }
        </React.Fragment>
    );
};

NavigationArrowComponent.propTypes = {
    // render left arrow. you should enable eather left or right
    left: PropTypes.bool,
    // render right arrow. you should enable eather left or right
    right: PropTypes.bool,
    overflowLeftArrow: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    // from state
    leftArrowVisible: PropTypes.bool,
    rightArrowVisible: PropTypes.bool,
    loadingBackward: PropTypes.bool.isRequired,
    loadingForward: PropTypes.bool.isRequired,
};

NavigationArrowComponent.defaultProps = {
    left: false,
    right: false,
    overflowLeftArrow: false,
    leftArrowVisible: false,
    rightArrowVisible: false,
};

const mapStateToProps = (state, ownProps) => ({
    ...getLoadState(state, ownProps.gridGroupName),
    ...getNavigationArrowUiState(state, ownProps.gridGroupName),
});

const NavigationArrow = connect(mapStateToProps, null)(NavigationArrowComponent);
export default NavigationArrow;
