export const AVALIBLE_ICON_CLASS = [
    'img',
    'doc',
    'xls',
    'ppt',
    'archive',
    'video',
    'audio',
    'text',
    'code',
    'pdf',
];

export const DEFAULT_ICON_CLASS = 'other';

export const isIconClassAvalible = iconClass => AVALIBLE_ICON_CLASS.includes(iconClass.toLocaleLowerCase());
