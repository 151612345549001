import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { CALL_API } from '../constants/actionTypes';
import { JSON_FORMAT, FORMDATA } from '../constants/ajaxBodyTypes';
import { TIMESTAMP_ISO8601 } from '../constants/formats';
import { isEmptyObject, isObject } from '../helpers/helpers';
import { authErrorMessageShow } from '../helpers/authErrorMessageShow';
import { objCamelFromSnake, objSnakeFromCamel } from '../helpers/objCamelFromSnake';
import { showNetworkErrorNotification } from '../helpers/showNotification';


function checkResponseContentTypeHeader(response) {
    const contentType = response.headers.get('content-type');
    if (!(contentType && contentType.toLowerCase().includes('application/json'))) {
        const errorMessage = `no json headers in ${response.url}. contentType: ${contentType}.`;
        try {
            Sentry.captureMessage(errorMessage);
        } catch (e) {
            // eslint-disable-next-line
            console.error(errorMessage);
        }
    }
}

function checkResponseJSON(responseJSON) {
    if (Array.isArray(responseJSON)) {
        const errorMessage = 'Warning: Response arrays from backend is deprecated. Use object';
        try {
            Sentry.captureMessage(errorMessage);
        } catch (e) {
            // eslint-disable-next-line
            console.error(errorMessage, responseJSON);
        }
    }
}

// В случае ошибки оптимально с бэкенда возращать json с полем error. статус в зависимости от ошибки
// Так же в случае ошибки в объект ответа добавляется:
//      status - статус ответа
//      error - текст ошибки
//      rawResponse - исходный ответ от сервера
// Поэтому массив в случае ошибки возвращать нельзя!
function status(response) {
    if (response.status === 401) {
        authErrorMessageShow();
    }

    if (response.ok) {
        return response.text().then(content => new Promise((resolve, reject) => {
            if (content) checkResponseContentTypeHeader(response);
            try {
                resolve(content ? JSON.parse(content) : {});
            } catch (e) {
                const errorObj = {
                    error: 'bad JSON content',
                    rawResponse: response,
                };
                reject(errorObj);
            }
        }));
    }

    return new Promise((resolve, reject) => response.text().then((content) => {
        let errorObj = {
            error: response.statusText,
            status: response.status,
            rawResponse: response,
        };
        try {
            if (content) {
                const contentJSON = JSON.parse(content);
                checkResponseJSON(contentJSON);
                errorObj = {
                    ...errorObj,
                    ...contentJSON,
                };
            }
        } finally {
            reject(errorObj);
        }
    }));
}

function networkErrorHandler(error) {
    if (error.toString().indexOf('TypeError: Failed to fetch') + 1) {
        showNetworkErrorNotification();
    }
    return new Promise((resolve, reject) => {
        reject(error);
    });
}

const csrfTokenRe = /csrftoken=([^ ;]+)/;

function getCsrfToken() {
    const match = document.cookie.match(csrfTokenRe);
    if (!match) {
        return null;
    }
    return match[1];
}

function appendGetParam(url, data, useISODates) {
    if (!data || isEmptyObject(data)) return url;
    const indexQM = url.indexOf('?');
    const hasQMark = indexQM !== -1;
    const hasParams = hasQMark && indexQM !== url.length - 1;
    const queryString = Object.entries(data).map(
        ([key, value]) => {
            const preparedValue = useISODates && value instanceof Date ?
                moment(value).utc().format(TIMESTAMP_ISO8601) :
                encodeURIComponent(value);
            if (!useISODates && value instanceof Date) {
                Sentry.captureMessage('Not using ISO dates');
            }
            return `${encodeURI(key)}=${preparedValue}`;
        },
    ).join('&');
    let params = '?';
    if (hasQMark) {
        params = hasParams ? '&' : '';
    }
    return `${url}${params}${queryString}`;
}

export function callApi(endpoint, method = 'get', body, bodyFormat = JSON_FORMAT, useISODates = false) {
    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');
    headers.append('Accept', 'application/json');

    let requestBody = body;
    let requestEndpoint = endpoint;
    if (method.toUpperCase() === 'GET') {
        requestBody = undefined;
        requestEndpoint = appendGetParam(requestEndpoint, body, useISODates);
    } else if (bodyFormat === JSON_FORMAT) {
        if (typeof body === 'string') {
            requestBody = body;
            // eslint-disable-next-line
            console.error('request body already string');
        } else {
            requestBody = JSON.stringify(body);
        }
        headers.append('Content-Type', 'application/json');
    } else if (bodyFormat === FORMDATA) {
        requestBody = new FormData();
        const addValueInFormData = (key, value) => {
            if ((body[key] !== undefined) && (body[key] !== null)) {
                requestBody.append(key, value);
            }
        };
        for (const key in body) {
            if (Array.isArray(body[key])) {
                for (const arrayValue of body[key]) {
                    addValueInFormData(key, arrayValue);
                }
            } else {
                addValueInFormData(key, body[key]);
            }
        }
    }

    const csrftoken = getCsrfToken();
    if (csrftoken !== undefined) {
        headers.append('X-CSRFToken', csrftoken);
    }

    const requestOptions = {
        method: method.toUpperCase(),
        body: requestBody,
        credentials: 'same-origin',
        headers,
    };

    const request = new Request(requestEndpoint, requestOptions);
    return fetch(request).then(status).catch(networkErrorHandler);
}


export default store => next => (action) => {
    if (action.type !== CALL_API) {
        return next(action);
    }

    const defaultCallback = () => {};
    const defaultPayload = d => d;
    const errorPayload = (d) => {
        const { rawResponse, ...result } = d;
        return result;
    };

    const typesWithCallbacks = [];
    const {
        endpoint,
        method,
        types,
        body,
        bodyFormat,
        additionalData,
        payload = defaultPayload,
        transformCase = false,
        useISODates,
        ignoredStatuses = [],
    } = action;

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }

    for (const item of types) {
        if (typeof item === 'string') {
            typesWithCallbacks.push(({ type: item, callback: defaultCallback }));
        } else if (isObject(item)) {
            if (typeof item.type !== 'string') {
                throw new Error('Expected type in types items to be a string');
            }
            typesWithCallbacks.push({
                type: item.type,
                callback: (typeof item.callback === 'function' && item.callback) || defaultCallback,
            });
        } else {
            throw new Error('Expected action types to be strings or objects.');
        }
    }

    const [forRequest, forSuccess, forFailure] = typesWithCallbacks;

    const requestAction = {
        type: forRequest.type,
        additionalData,
        loadStatus: 'start',
    };
    store.dispatch(requestAction);
    forRequest.callback(store, requestAction);

    return callApi(
        endpoint,
        method,
        transformCase ? objSnakeFromCamel(body) : body,
        bodyFormat,
        useISODates,
    ).then(
        (rawData) => {
            const responseAction = {
                additionalData,
            };
            const data = transformCase ? objCamelFromSnake(rawData) : rawData;
            if (data.error || (data.result || '').toLowerCase() === 'fail') {
                responseAction.type = forFailure.type;
                responseAction.error = data.error;
                responseAction.ignoredStatuses = ignoredStatuses;
                responseAction.payload = payload(data);

                store.dispatch(responseAction);
                forFailure.callback(store, responseAction);
                return Promise.resolve({ ...data, fail: true });
            }
            responseAction.type = forSuccess.type;
            responseAction.payload = payload(data);
            responseAction.loadStatus = 'success';
            store.dispatch(responseAction);
            forSuccess.callback(store, responseAction);

            return Promise.resolve(data);
        },
        (error) => {
            const data = errorPayload(error);
            const responseAction = {
                type: forFailure.type,
                payload: transformCase ? objCamelFromSnake(data) : data,
                error: data,
                additionalData,
                rawResponse: error.rawResponse,
                ignoredStatuses,
                loadStatus: 'fail',
            };
            store.dispatch(responseAction);
            forFailure.callback(store, responseAction);
            const err = typeof error === 'string' ? { error, fail: true } : { ...error, fail: true };
            return Promise.resolve(err);
        });
};
