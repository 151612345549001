import { RIGHT_ARROW_VISIBILITY, LEFT_ARROW_VISIBILITY } from '../constants/actions';

const defaultState = {
    leftArrowVisible: false,
    rightArrowVisible: false,
};

// this store is used for render optimization on visibility change
// while this state where stored in eventGrid's state, there were
// whole grid rerender on arrow visibility change
const navigationArrowUiState = (state = {}, action) => {
    switch (action.type) {
        case (RIGHT_ARROW_VISIBILITY): {
            const { newVisible, gridGroupName } = action.payload;
            return {
                ...state,
                [gridGroupName]: {
                    ...(state[gridGroupName] || defaultState),
                    rightArrowVisible: newVisible,
                },
            };
        }
        case (LEFT_ARROW_VISIBILITY): {
            const { newVisible, gridGroupName } = action.payload;
            return {
                ...state,
                [gridGroupName]: {
                    ...(state[gridGroupName] || defaultState),
                    leftArrowVisible: newVisible,
                },
            };
        }
        default:
            return state;
    }
};

export default navigationArrowUiState;

export const getNavigationArrowUiState = (state, gridGroupName) => ({ ...(state[gridGroupName] || defaultState) });
