import moment from 'moment';
import { micropostEditTimeout } from './constants';

export function checkCanEdit(createdAt) {
    return moment().diff(moment(createdAt)) < micropostEditTimeout;
}

const mappedStateDefaults = {
    toUserId: null,
    toUserFirstName: null,
    toUserIsActive: null,
}

export function mapState(state) {
    const userData = (state.userDetail && state.userDetail.userData) || state.userData;
    const mainInfo = userData && userData.mainInfo;
    const mappedData = {
        ...mappedStateDefaults,
        toUserId: state.micropost.toUserId,
        toUserIsActive: state.micropost.toUserIsActive,
        loadingProfileInfo: false,
    };
    if (mainInfo) {
        const ownPage = state.selfProfile ? state.selfProfile.id === mainInfo.id : false;
        if (!ownPage) {
            return {
                ...mappedData,
                toUserId: mainInfo.id || mappedData.toUserId,
                toUserFirstName: mainInfo.firstNameRuRo || mappedData.toUserFirstName,
                toUserIsActive: mainInfo.isActive || mappedData.toUserIsActive,
                loadingProfileInfo: mainInfo.process,
            };
        }
    }
    return mappedData;
}
