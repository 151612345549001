import { CALL_API } from '../../../common/constants/actionTypes';
import ACTION_TYPES from '../actionTypes';
import { QUIZ_BY_PASS_URL, QUIZ_DETAIL_URL, QUIZ_SCHEME_DETAIL_URL } from '../constants';

export const fetchQuiz = (id, isAnotherTry = false) => ({
    type: CALL_API,
    endpoint: QUIZ_DETAIL_URL(id),
    method: 'get',
    types: [ACTION_TYPES.QUIZ_LOAD_START, ACTION_TYPES.QUIZ_LOAD_FINISH, ACTION_TYPES.QUIZ_LOAD_FAIL],
    additionalData: { isAnotherTry },
    ignoredStatuses: [403],
});

export const fetchQuizScheme = id => ({
    type: CALL_API,
    endpoint: QUIZ_SCHEME_DETAIL_URL(id),
    method: 'get',
    types: [ACTION_TYPES.SCHEME_LOAD_START, ACTION_TYPES.SCHEME_LOAD_FINISH, ACTION_TYPES.API_FAIL],
});

export const fetchQuizByPass = (quizId, choiceId) => ({
    type: CALL_API,
    endpoint: QUIZ_BY_PASS_URL(quizId, choiceId),
    method: 'get',
    types: [
        ACTION_TYPES.QUIZ_BY_PASS_LOAD_START,
        ACTION_TYPES.QUIZ_BY_PASS_LOAD_FINISH,
        ACTION_TYPES.API_FAIL,
    ],
});
