import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker, TimePicker } from 'intdev-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';
import getValidationProps from '../../helpers/getValidationProps';
import { styles } from './EventEditModal';
import { getModalData } from '../../reducers';
import { eventModalFieldValueChange } from '../../actions';
import formatDuration from '../../helpers/formatDuration';
import { getMinCreateTime } from '../../helpers/minCreateTime';

export class EventSinglePanelComponent extends React.Component {
    static propTypes = {
        // config
        minCreateHour: PropTypes.number.isRequired,
        maxCreateHour: PropTypes.number.isRequired,
        minuteStartStep: PropTypes.number.isRequired,
        minEventDuration: PropTypes.number.isRequired,
        // dispatch
        onFieldChange: PropTypes.func.isRequired,
        // from state
        startDate: PropTypes.instanceOf(Date).isRequired,
        startTime: PropTypes.string.isRequired,
        processing: PropTypes.bool.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
    };

    render() {
        const { minCreateHour, startDate, minuteStartStep } = this.props;
        const minCreateTime = formatDuration(
            getMinCreateTime(minCreateHour, startDate, minuteStartStep),
        );

        return (
            <div style={ styles.fieldRow }>
                <div style={ styles.fieldLabelDiv }>
                    <div style={ styles.fieldLabelInner }>Начало события</div>
                </div>
                <div style={ styles.fieldInputBody }>
                    <DatePicker
                        manualInput
                        name="startDate"
                        value={ this.props.startDate }
                        onChange={ (e, newDate) => this.props.onFieldChange({
                            target: {
                                value: newDate,
                                name: 'startDate',
                            },
                        }) }
                        minDate={ moment().startOf('day').toDate() }
                        style={ styles.fieldInnerInputBody }
                        disabled={ this.props.processing }
                        { ...getValidationProps(this.props.validationErrors, 'startTime') }
                        fullWidth
                    />
                    <div style={ styles.delimiterDiv } />
                    <TimePicker
                        name="startTime"
                        value={ this.props.startTime }
                        onChange={ this.props.onFieldChange }
                        style={ styles.fieldInnerInputBody }
                        disabled={ this.props.processing }
                        showTimeUnits
                        showBoundValidation={ false }
                        minTime={ minCreateTime }
                        /*
                        * in case of blog events
                        * max create time is (actual max time - min event duration)
                        * otherwise one of event fields will fail server side validation:
                        * either max end time (which is max create time) or min duration
                         */
                        maxTime={ formatDuration(moment.duration(
                            moment(this.props.maxCreateHour, 'HH').diff(moment(this.props.minEventDuration, 'mm')),
                        )) }
                        minutesStep={ this.props.minuteStartStep }
                        showStepValidation={ false }
                        fullWidth
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => getModalData(state);

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onFieldChange: eventModalFieldValueChange,
    }, dispatch),
});

const EventSinglePanel = connect(mapStateToProps, mapDispatchToProps)(EventSinglePanelComponent);

export default EventSinglePanel;
