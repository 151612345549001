export default {
    // pages
    stickers: '/stickers/',
    packPage: id => `/stickers/${id}/`,
    packEditUrl: id => `/stickers/${id}/edit/`,
    topStickers: '/stickers/top/',
    newStickersPage: '/stickers/new/',
    uploadedStickers: '/stickers/uploaded/',
    // regexp
    packEditUrlRegExp: '/stickers/:packId/edit/',
    packUrlRegExp: '/stickers/:packId/',
    // api
    createStickerPackUrl: '/stickers/api/pack/',
    fetchMyStickersUrl: '/stickers/api/pack/my/',
    fetchTopStickersUrl: '/stickers/api/pack/top/',
    fetchUploadedStickersUrl: '/stickers/api/pack/uploaded/',
    fetchPacksPreview: '/stickers/api/pack/preview/',
    packEntryUrl: id => `/stickers/api/pack/${id}/`,
    loadPermissions: permissionList => `/utils/load-permissions/?perm=${permissionList.join(',')}`,
    packAdd: id => `/stickers/api/pack/${id}/add/`,
    packDelete: id => `/stickers/api/pack/${id}/delete/`,
    packImportUrl: '/stickers/api/pack/import/',
    fetchStickerHintUrl: '/stickers/api/keyword/hint/',
    uploadNewStickerUrl: id => `/stickers/api/pack/${id}/sticker/`,
    createKeywordUrl: stickerId => `/stickers/api/sticker/${stickerId}/keyword/`,
    deleteKeywordUrl: keywordId => `/stickers/api/keyword/${keywordId}/`,
    deleteStickerUrl: id => `/stickers/api/sticker/${id}/`,
    recentStickersUrl: '/stickers/api/sticker/recent/',
};
