import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';
import { Helmet } from '@/common/components/Helmet';

import { pageStyle } from './pageStyle';
import { mainPageRef } from './mainPageRef';


export const PageNotFound = (props) => {
    const containerStyle = {
        ...pageStyle.container,
        ...props.containerStyle,
    };
    const textStyle = {
        ...pageStyle.text,
        ...props.textStyle,
    };
    return (
        <div style={ containerStyle }>
            <Helmet>
                <title>Страница не найдена</title>
            </Helmet>
            <div>
                <img alt="404" src="/static/404image.png" />
            </div>
            <div style={ textStyle }>
                <span>{ props.text }</span>
            </div>
            <div>
                { props.referenceText }
            </div>
        </div>
    );
};

PageNotFound.propTypes = {
    text: PropTypes.string,
    referenceText: PropTypes.node,
    containerStyle: IUIPropTypes.style,
    textStyle: IUIPropTypes.style,
};

PageNotFound.defaultProps = {
    text: 'Похоже, такой страницы у нас нет.',
    containerStyle: {},
    textStyle: {},
    referenceText: mainPageRef,
};
