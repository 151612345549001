export const INTRANET_EXPLORE_REWARD_ID = 161;
export const MAX_CARD_DESCRIPTION_LENGTH = 80;
export const MAX_GROUP_CARD_DESCRIPTION_LENGTH = 30;

export const ON_MODERATION_STATUS = 0;
export const IS_MODERATED = 1;
export const DECLINED = 2;
export const MODERATION_MAP = {
    0: 'На модерации',
    1: 'Прошел модерацию',
    2: 'Отклонён',
};
export const COIN_CURRENCY = 0;
export const RUBLE_CURRENCY = 1;
export const CURRENCY_OPTIONS = [
    { value: COIN_CURRENCY, label: 'Коины' },
    { value: RUBLE_CURRENCY, label: 'Рубли' },
];
export const COIN_ICON = '/static/img/store/coin.svg';
export const COIN_DISABLED_ICON = '/static/img/store/coin_grey.svg';
export const DEFAULT_GOODS_IMAGE = '/static/img/store/default_goods.svg';

export const OPERATIONS_TEXT = {
    all: 'Показать предыдущие операции',
    collapse: 'Свернуть',
};

export const TAB_COMMON_GOODS = 'commonGoods';
export const TAB_USER_GOODS = 'userGoods';
export const TAB_FILIAL_GOODS = 'filialGoods';
export const TAB_TEAM_GOODS = 'teamGoods';
export const TAB_GET_COINS = 'getCoins';

export const SHOP_TABS = [
    { tabsKey: '/store/', value: 'ПЭК Store', renderOption: TAB_COMMON_GOODS },
    { tabsKey: '/store/user_goods/', value: 'Товары от сотрудников', renderOption: TAB_USER_GOODS },
    { tabsKey: '/store/filial_goods/', value: 'Наборы новичка', renderOption: TAB_FILIAL_GOODS },
    { tabsKey: '/store/team_goods/', value: 'Товары для команды', renderOption: TAB_TEAM_GOODS },
    { tabsKey: '/store/get_coins/', value: 'Как получить коины', renderOption: TAB_GET_COINS },
];

export const ADMIN_TABS = {
    purchases: { tabsKey: 'purchases', value: 'Покупки' },
    userPurchases: { tabsKey: 'userPurchases', value: 'Продажи сотрудников' },
    statistics: { tabsKey: 'statistics', value: 'Статистика' },
    moderation: { tabsKey: 'moderation', value: 'Модерация' },
    reports: { tabsKey: 'reports', value: 'Отчеты' },
    transitions: { tabsKey: 'transitions', value: 'Транзакции' },
};

export const CATEGORY_SLUG_INTRANET = 'intranet';
export const CATEGORY_SLUG_HELP_FRIEND = 'help_friend';
export const CATEGORY_SLUG_ARCHIVE = 'archive';

export const helpText = limitPerPurchase => `На данный товар стоит ограничение по количеству к заказу в одни руки. Не более ${limitPerPurchase} шт.`;
