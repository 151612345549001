export const CENTRIFUGE_JOIN = 'calendar.centrefuge_join';
export const CENTRIFUGE_LEAVE = 'calendar.centrefuge_leave';
export const CENTRIFUGE_EDIT = 'calendar.centrefuge_edit';
export const CENTRIFUGE_DELETE = 'calendar.centrefuge_delete';

export const CALENDAR_CONFIG_LOAD = 'calendar.config_load';

export const SET_CALENDAR_CONTAINER_PROPS = 'calendar.set_container_props';

export const MODAL_OPEN = 'calendar.modal_open';
export const MODAL_CLOSE = 'calendar.modal_close';

export const LEFT_ARROW_VISIBILITY = 'calendar.left_arrow_visibility';
export const RIGHT_ARROW_VISIBILITY = 'calendar.right_arrow_visibility';

export const MOUSE_OVER_SERIES = 'calendar.mouse_over_series';
export const MOUSE_OUT_SERIES = 'calendar.mouse_out_series';

export const FOOTER_TOGGLE = 'calendar.footer_toggle';

export const CALENDAR_LOAD_START = 'calendar.calendar_load_start';
export const CALENDAR_LOAD_SUCCESS = 'calendar.calendar_load_success';
export const CALENDAR_LOAD_FAILURE = 'calendar.calendar_load_failure';

export const CALENDAR_BELL_START = 'calendar.calendar_bell_start';
export const CALENDAR_BELL_SUCCESS = 'calendar.calendar_bell_success';
export const CALENDAR_BELL_FAILURE = 'calendar.calendar_bell_failure';

export const EVENT_MODAL_FIELD_VALUE_CHANGE = 'calendar.event_modal.field_value_change';

export const EVENT_MODAL_SET_VALIDATION_ERRORS = 'calendar.event_modal.event_modal_set_validation_errors';
export const EVENT_MODAL_CLEAN_VALIDATION_ERRORS = 'calendar.event_modal.event_modal_clean_validation_errors';

export const EVENT_LOAD_START = 'calendar.event_modal.event_load_start';
export const EVENT_LOAD_SUCCESS = 'calendar.event_modal.event_load_success';
export const EVENT_LOAD_FAILURE = 'calendar.event_modal.event_load_failure';

export const EVENT_LOCATIONS_LOAD_START = 'calendar.event_modal.event_locations_load_start';
export const EVENT_LOCATIONS_LOAD_SUCCESS = 'calendar.event_modal.event_locations_load_success';
export const EVENT_LOCATIONS_LOAD_FAILURE = 'calendar.event_modal.event_locations_load_failure';

export const MEETING_LEAVE_START = 'calendar.event_modal.meeting_leave_start';
export const MEETING_LEAVE_SUCCESS = 'calendar.event_modal.meeting_leave_success';
export const MEETING_LEAVE_FAILURE = 'calendar.event_modal.meeting_leave_failure';

export const EVENT_SAVE_START = 'calendar.event_modal.event_save_start';
export const EVENT_SAVE_SUCCESS = 'calendar.event_modal.event_save_success';
export const EVENT_SAVE_FAILURE = 'calendar.event_modal.event_save_failure';

export const PARSE_EXCEL_START = 'calendar.event_modal.parse_excel_start';
export const PARSE_EXCEL_FINISH = 'calendar.event_modal.parse_excel_finish';
export const PARSE_EXCEL_ERROR = 'calendar.event_modal.parse_excel_failure';
