export const containerStyle = {
    width: 'var(--main-menu-width)',
    padding: '8px 0',
};

export const menuStyle = {
    padding: '0 4px',
};

export const popoverProps = {
    anchorOrigin: { horizontal: 'left', vertical: 'center' },
    targetOrigin: { horizontal: 'right', vertical: 'center' },
    popoverStyle: { maxWidth: 400, padding: 8, marginLeft: 10 },
};
