import moment from 'moment';

import durationFromTime from './durationFromTime';
import ceilMomentWithMinuteStep from './ceilMomentWithMinuteStep';

export const getMinCreateTime = (minCreateHour, selectedDate, minutesStep) => {
    /**
     * @param {Number} minCreateHour
     * @param {Date} selectedDate
     * @param {Number} minutesStep
     */
    const propMinCreate = moment.duration(minCreateHour, 'hours');
    const todayStartDate = moment().startOf('day').toDate();

    const isMinCreateDaySelected = todayStartDate.getTime() >= selectedDate.getTime();

    const nowMinCreate = durationFromTime(ceilMomentWithMinuteStep(moment(), minutesStep));

    const mostMinCreate = propMinCreate.asMilliseconds() > nowMinCreate.asMilliseconds()
        ? propMinCreate
        : nowMinCreate;
    return isMinCreateDaySelected ? mostMinCreate : propMinCreate;
};
