import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { IUIPropTypes } from 'intdev-ui';

import { PageNotFound } from './PageNotFound';
import { PageForbidden } from './PageForbidden';
import { PageServerError } from './PageServerError';


export class PageStatusView extends React.Component {
    static propTypes = {
        statusViewProps: PropTypes.oneOfType([
            PropTypes.shape({
                status: PropTypes.number,
                text: PropTypes.string,
                referenceText: PropTypes.node,
                containerStyle: IUIPropTypes.style,
                textStyle: IUIPropTypes.style,
            }),
            PropTypes.number,
        ]),
        children: IUIPropTypes.validChildren,
        location: ReactRouterPropTypes.location,
        setPageStatus: PropTypes.func,
    };

    static defaultProps = {
        statusViewProps: null,
        children: null,
        location: null,
        setPageStatus: null,
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.location?.pathname !== this.props.location?.pathname
            && this.props.setPageStatus
            && prevProps.statusViewProps
        ) {
            this.props.setPageStatus(null);
        }
    }

    render() {
        const { statusViewProps, children } = this.props;
        const status = statusViewProps?.status || statusViewProps;

        if (!status || status < 400) {
            return children;
        }

        switch (status) {
            case 403:
                return <PageForbidden { ...statusViewProps } />;
            case 404:
                return <PageNotFound { ...statusViewProps } />;
            default:
                return <PageServerError status={ status } { ...statusViewProps } />;
        }
    }
}
