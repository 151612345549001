import React from 'react';
import moment from 'moment';
import 'moment/locale/tg';
import 'moment/locale/uz';
import PropTypes from 'prop-types';
import { Settings } from '../Settings';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';
import { AggregatedThanksRecord } from './AggregatedThanksRecord';
import { locale } from '@/common/localization/dictionary';


export class AggregatedThanksBlock extends React.Component {
    static propTypes = {
        authUserData: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            last_name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired,
        }).isRequired,
        content_type_id: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        objectList: PropTypes.arrayOf(PropTypes.object).isRequired,
        record_id: PropTypes.number.isRequired,
        filterData: PropTypes.func.isRequired,
        reloadData: PropTypes.func.isRequired,
        canPersonalBlocking: PropTypes.bool,
        userData: PropTypes.shape({
            id: PropTypes.number,
            full_name_ro: PropTypes.string,
        }),
        removeAllRecordsByUser: PropTypes.func,
    };

    static defaultProps = {
        userData: null,
        canPersonalBlocking: false,
        removeAllRecordsByUser: () => {},
    };

    constructor(props) {
        super(props);
        this.state = objCamelFromSnake(this.props);
    }

    render() {
        const aggregatedThanksData = this.state;
        let userData = null;
        if (this.props.canPersonalBlocking) {
            userData = this.props.userData;
        }
        return (
            <div className="timeline-container timeline-full">
                <div className="timeline-record-information">
                    <div className="timeline-recent__header">
                        <div>Благодарности</div>
                        <div className="b-flexrow">
                            <Settings
                                objectId={ aggregatedThanksData.recordId }
                                contentType={ aggregatedThanksData.contentTypeId }
                                recordDate={ aggregatedThanksData.date }
                                authUserData={ aggregatedThanksData.authUserData }
                                filterData={ aggregatedThanksData.filterData }
                                reloadData={ aggregatedThanksData.reloadData }
                                userData={ userData }
                                canPersonalBlocking={ this.props.canPersonalBlocking }
                                removeAllRecordsByUser={ this.props.removeAllRecordsByUser }
                            >
                                <div className="timeline-date" title={ moment(this.props.date).format('YYYY.MM.DD HH:mm') }>
                                    { moment(aggregatedThanksData.date).locale(locale).fromNow(false) }
                                </div>
                            </Settings>
                        </div>
                    </div>
                    <div className="b-flexline">
                        { aggregatedThanksData.objectList.map(thanksRecord => (
                            <AggregatedThanksRecord
                                key={ thanksRecord.id }
                                contentTypeId={ this.props.content_type_id }
                                { ...thanksRecord }
                            />
                        )) }
                    </div>
                </div>
            </div>
        );
    }
}
