import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'intdev-ui';
import { connect } from 'react-redux';

import { attachAlbum, buildQuiz, editBanner } from '../../constants/apiUrls';
import { MODAL_CREATE } from '../../../calendar/constants/modalTypes';
import AttachmentButton from '../AttachmentButton';
import EventCreateModal from '../../../calendar/components/modals/EventCreateModal';
import { modalClose, modalOpen } from '../../../calendar/actions';
import { EVENT_SHAPE } from '../../../calendar/shapes';
import { AttachUserGoodsButton } from '../../../store/components/UserGoods/AttachUserGoodsButton';

const styles = {
    button: {
        flex: '0 0 auto',
        margin: '0 0 4px 4px',
    },
};

const EntryContentType = 254;

class PublicationToolbarComponent extends React.Component {
    static propTypes = {
        objectId: PropTypes.string.isRequired,
        contentType: PropTypes.string.isRequired,
        canEditEvent: PropTypes.bool.isRequired,
        canAddQuiz: PropTypes.bool.isRequired,
        canManageAttachments: PropTypes.bool.isRequired,
        canModerateAlbums: PropTypes.bool.isRequired,
        canEditBanners: PropTypes.bool.isRequired,
        onAddEventButtonClick: PropTypes.func.isRequired,
        openedModal: PropTypes.shape({
            type: PropTypes.string,
            event: EVENT_SHAPE,
        }).isRequired,
        itemTitle: PropTypes.string,
        itemUrl: PropTypes.string,
        canAttachUserGoods: PropTypes.bool,
        isAuthor: PropTypes.bool,
        coinsSystemFeatureIsOn: PropTypes.bool,
    };

    static defaultProps = {
        itemTitle: '',
        itemUrl: '',
        canAttachUserGoods: false,
        isAuthor: false,
        coinsSystemFeatureIsOn: false,
    }

    state = {
        modalType: null,
        modalData: null,
    };

    getModal = () => {
        switch (this.props.openedModal.type) {
            case (MODAL_CREATE):
                return (
                    <EventCreateModal />
                );
            default:
                return null;
        }
    };

    render() {
        const canAttachGoods = this.props.canAttachUserGoods
            && this.props.isAuthor
            && +this.props.contentType === EntryContentType;
        return (
            <div className="publication-toolbar-container">
                {
                    this.props.canAddQuiz
                    && (
                        <Button
                            label="Добавить опрос"
                            href={ buildQuiz(this.props.contentType, this.props.objectId) }
                            style={ styles.button }
                        />
                    )
                }
                {
                    this.props.canEditEvent
                    && (
                        <Button
                            label="Добавить событие"
                            onClick={ this.props.onAddEventButtonClick }
                            style={ styles.button }
                        />
                    )
                }
                {
                    this.props.canManageAttachments
                    && (
                        <AttachmentButton
                            label="Прикрепить файл"
                            contentType={ this.props.contentType }
                            objectId={ this.props.objectId }
                            style={ styles.button }
                        />
                    )
                }
                {
                    this.props.canModerateAlbums
                    && (
                        <Button
                            label="Прикрепить фотоальбом"
                            href={ attachAlbum(this.props.contentType, this.props.objectId) }
                            style={ styles.button }
                        />
                    )
                }
                {
                    this.props.canEditBanners
                    && (
                        <Button
                            label="Баннер на главной"
                            href={ editBanner(
                                this.props.contentType,
                                this.props.objectId,
                                this.props.itemTitle,
                                this.props.itemUrl,
                            ) }
                            style={ styles.button }
                        />
                    )
                }
                {
                    canAttachGoods && this.props.coinsSystemFeatureIsOn
                    && (
                        <AttachUserGoodsButton
                            contentTypeId={ +this.props.contentType }
                            objectId={ +this.props.objectId }
                            style={ styles.button }
                        />
                    )
                }
                { this.getModal() }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    openedModal: state.openedModal,
    coinsSystemFeatureIsOn: !!state.features && state.features.coins_system,
});

const mapDispatchToProps = dispatch => ({
    modalClose: () => dispatch(modalClose()),
    onAddEventButtonClick: () => dispatch(modalOpen(MODAL_CREATE)),
});

const PublicationToolbar = connect(mapStateToProps, mapDispatchToProps)(PublicationToolbarComponent);

export default PublicationToolbar;
