import React from 'react';
import PropTypes from 'prop-types';

export const PenIcon = ({ iconColor }) => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5745 3.5701L4.56978 10.9843C4.30529 11.2659 4.04934 11.8204 3.99814 12.2044L3.68246 14.9687C3.57155 15.9669 4.28823 16.6495 5.27793 16.4789L8.0252 16.0096C8.40914 15.9414 8.94665 15.6598 9.21114 15.3697L16.2158 7.95549C17.4274 6.67571 17.9734 5.21675 16.0878 3.43359C14.2108 1.66748 12.786 2.29031 11.5745 3.5701Z" fill={ iconColor } />
        <path d="M10.0735 5.61768C11.7799 7.32406 12.6331 8.17725 13.9129 9.45703" stroke="#F6F6F9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

PenIcon.propTypes = {
    iconColor: PropTypes.string,
};

PenIcon.defaultProps = {
    iconColor: '#292D32',
};
