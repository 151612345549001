import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Dialog, Select, A, TextField, Upload, Checkbox
} from 'intdev-ui';
import update from 'immutability-helper';
import { showNotification } from '@/common/helpers/showNotification';
import { callApi } from '@/common/middlewares/apiMiddleware';
import LabelWithHelp from '@/common/components/LabelWithHelp';
import { BeautifulRedactorField } from '@/common/components/BeautifulRedactorField';
import { ValidationText } from '@/common/components/ValidationText';
import { apiUrls } from '@/store/constants/apiUrls';
import { CURRENCY_OPTIONS } from '@/store/constants/constants';
import './UserGoods.css';

const styles = {
    cancelButton: {
        marginLeft: '16px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    marginRight: {
        marginRight: '8px',
    },
    collapse: {
        marginTop: '8px',
        marginBottom: '16px',
    },
};

const emptyErrors = {
    title: null,
    shortDescription: null,
    description: null,
    image: null,
    price: null,
    limit: null,
}


export class AttachUserGoodsButton extends Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        objectId: PropTypes.number.isRequired,
    };

    state = {
        userGoods: {
            currency: CURRENCY_OPTIONS[0].value,
            unlimitedQuantity: true,
            isActive: true,
        },
        validationState: emptyErrors,
        attachDialogIsOpen: false,
        inProgress: false,
        collapsed: true,
    };

    handleSubmitForm = () => {
        this.setState({
            inProgress: true,
            validationState: emptyErrors,
        });

        const { contentTypeId, objectId } = this.props;
        const {
            isActive, price, title, image, shortDescription, description, stockedOut, unlimitedQuantity, limit, currency
        } = this.state.userGoods;

        const formData = {
            is_active: isActive,
            price: price,
            title: title,
            image: image,
            description: shortDescription,
            full_description: description,
            stocked_out: stockedOut,
            unlimited_quantity: unlimitedQuantity,
            limit: limit,
            currency: currency,
        };

        callApi(
            apiUrls.submitUserGoodsForm(`?content_type=${contentTypeId}&object_id=${objectId}`),
            'POST',
            formData,
            'formdata',
        ).then((data) => {
            this.setState({ inProgress: false });
            if (data && data.moderation) {
                showNotification({ level: 'success', message: data.moderation });
            } else {
                showNotification({ level: 'success', message: 'Товар прикреплен' });
            }
            this.handleCloseDialog();
        }).catch((data) => {
            if (data.status === 400 && data.errors) {
                const { errors } = data;
                this.setState({
                    validationState: {
                        title: errors.title ? errors.title[0] : null,
                        shortDescription: errors.description ? errors.description[0] : null,
                        description: errors.full_description ? errors.full_description[0] : null,
                        image: errors.image ? errors.image[0] : null,
                        price: errors.price ? errors.price[0] : null,
                        limit: errors.limit ? errors.limit[0] : null,
                        stockedOut: errors.stocked_out ? errors.stocked_out[0] : null,
                    },
                });
            } else {
                showNotification({ level: 'error', message: 'Что-то пошло не так' });
            }
            this.setState({ inProgress: false });
        });
    };

    handleCollapse = () => {
        this.setState({ collapsed: !this.state.collapsed })
    };

    handleChangeField = (field, value) => {
        this.setState(prevState => (
            update(prevState, { userGoods: { $merge: { [field]: value } } })
        ));
    };

    handleOpenDialog = () => {
        this.setState({ attachDialogIsOpen: true });
    };

    handleCloseDialog = () => {
        this.setState({
            attachDialogIsOpen: false,
            userGoods: {},
            collapsed: true,
            validationState: emptyErrors,
        });
    };

    render() {
        const actions = [
            <Button
                primary
                key="handle-attach-button"
                label="Добавить"
                onClick={ this.handleSubmitForm }
            />,
            <Button
                key="cancel-button"
                style={ styles.cancelButton }
                label="Отменить"
                onClick={ this.handleCloseDialog }
                disabled={ this.props.inProgress }
            />,
        ];

        const {
            isActive,
            price,
            title,
            image,
            shortDescription,
            description,
            stockedOut,
            unlimitedQuantity,
            limit,
            currency,
        } = this.state.userGoods;
        const { inProgress, attachDialogIsOpen, validationState, collapsed } = this.state;
        const inStock = !unlimitedQuantity && parseInt(limit, 10) > 0;

        let uploadLabel;
        if (image) {
            if (image.name) {
                uploadLabel = `Выбрана ${image.name.slice(0, 12)}...`;
            } else {
                uploadLabel = 'Выбрать новую';
            }
        } else {
            uploadLabel = 'Выбрать';
        }

        return (
            <div className="store-attach-goods-form-container">
                <Button
                    label="Прикрепить товар"
                    onClick={ this.handleOpenDialog }
                />
                <Dialog
                    actions={ actions }
                    actionsContainerStyle={ styles.actionContainer }
                    title="Создание нового товара"
                    open={ attachDialogIsOpen }
                    onRequestClose={ this.handleCloseDialog }
                    autoScrollBodyContent
                >
                    <div>
                        <div>
                            <div className="store-create-goods-form-field-name">
                                Название&nbsp;
                                <span title="Обязательное поле" className="store-form-obligatory-field">*</span>
                            </div>
                            <div className="store-create-goods-form-field">
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    name="title"
                                    disabled={ inProgress }
                                    value={ title }
                                    onChange={ (e, value) => this.handleChangeField('title', value) }
                                    validationState={ validationState.title ? 'error' : null }
                                    validationText={ validationState.title || ' ' }
                                    maxLength={ 100 }
                                />
                            </div>
                            <div className="store-create-goods-form-field-name">
                                <LabelWithHelp
                                    label="Цена"
                                    help="Цена в коинах или рублях. Должна быть больше 0"
                                />
                                &nbsp;
                                <span title="Обязательное поле" className="store-form-obligatory-field">*</span>
                            </div>
                            <div className="store-create-goods-form-field" style={ styles.flexCenter }>
                                <TextField
                                    autoComplete="off"
                                    name="price"
                                    disabled={ inProgress }
                                    value={ price }
                                    onChange={ (e, value) => this.handleChangeField('price', value) }
                                    validationState={ validationState.price ? 'error' : null }
                                    validationText={ validationState.price || ' ' }
                                    type="number"
                                    style={ styles.marginRight }
                                    fullWidth
                                />
                                <Select
                                    value={ currency }
                                    options={ CURRENCY_OPTIONS }
                                    onChange={ e => this.handleChangeField('currency', e.value) }
                                    clearable={ false }
                                    fullWidth
                                />
                            </div>
                        </div>
                        <A onClick={ this.handleCollapse } mode="breadcrumb">
                            { `${collapsed ? 'Показать' : 'Свернуть'} дополнительные опции` }
                        </A>
                        { !collapsed &&
                            <div>
                                <div className="store-create-goods-form-field-name">
                                    <LabelWithHelp
                                        label="Краткое описание"
                                        help="Краткое описание будет показано в карточке товара на главной странице магазина"
                                    />
                                </div>
                                <div className="store-create-goods-form-field">
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        name="shortDescription"
                                        disabled={ inProgress }
                                        value={ shortDescription }
                                        onChange={ (e, value) => this.handleChangeField('shortDescription', value) }
                                        validationState={ validationState.shortDescription ? 'error' : null }
                                        validationText={ validationState.shortDescription || ' ' }
                                        maxLength={ 100 }
                                    />
                                </div>
                                <div className="store-create-goods-form-field-name">
                                    <LabelWithHelp
                                        label="Полное описание"
                                        help="Полное описание будет показано на странице товара"
                                    />
                                </div>
                                <div className="store-create-goods-form-field">
                                    <BeautifulRedactorField
                                        formatting
                                        fullWidth
                                        onChange={ value => this.handleChangeField('description', value) }
                                        value={ description }
                                        disabled={ inProgress }
                                        validationState={ validationState.description ? 'error' : null }
                                        validationText={ validationState.description || ' ' }
                                    />
                                </div>
                                <div className="store-create-goods-form-icon-field">
                                    <div className="store-create-goods-form-field-name">Картинка</div>
                                    <div className="store-create-goods-form-field">
                                        <Upload
                                            uploadLabel={ uploadLabel }
                                            onDrop={ value => this.handleChangeField('image', value[0]) }
                                            disabled={ inProgress }
                                        />
                                    </div>
                                    {
                                        validationState.image && (
                                            <span className="store-form-obligatory-field">
                                                { validationState.image }
                                            </span>
                                        )
                                    }
                                    {
                                        image && (
                                            <div>
                                                <img
                                                    className="store-create-goods-form-img"
                                                    src={ image.preview || image }
                                                    alt=""
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <label>
                                    <div
                                        className="store-create-goods-form-field-name"
                                        style={ styles.flexCenter }
                                    >
                                        <span className="store-create-goods-form-label">Ограниченное количество</span>
                                        <Checkbox
                                            checked={ !Boolean(unlimitedQuantity) }
                                            onChange={ e => this.handleChangeField('unlimitedQuantity', !e.target.checked) }
                                            disabled={ inProgress }
                                        />
                                    </div>
                                </label>
                                <div className="store-create-goods-form-field-name">
                                    <LabelWithHelp
                                        label="Остаток"
                                        help="Количество оставшихся товаров. Счетчик меняется автоматически. Поля обязательно только товаров с ограниченным количеством"
                                    />
                                </div>
                                <div className="store-create-goods-form-field">
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        name="limit"
                                        disabled={ unlimitedQuantity }
                                        value={ limit }
                                        onChange={ (e, value) => this.handleChangeField('limit', value) }
                                        validationState={ validationState.limit ? 'error' : null }
                                        validationText={ validationState.limit || ' ' }
                                        type="number"
                                    />
                                </div>
                                <label>
                                    <div
                                        className="store-create-goods-form-field-name"
                                        style={ styles.flexCenter }
                                    >
                                        <LabelWithHelp
                                            style={ styles.marginRight }
                                            label="Нет в наличии"
                                            help="Если товара временно нет в наличии, то он будет отображаться на странице товаров, но его нельзя будет приобрести"
                                        />
                                        <Checkbox
                                            checked={ stockedOut }
                                            onChange={ e => this.handleChangeField('stockedOut', e.target.checked) }
                                            disabled={ inProgress || inStock }
                                        />
                                    </div>
                                    <ValidationText validationText={ validationState.stockedOut }/>
                                </label>
                                <label>
                                    <div
                                        className="store-create-goods-form-field-name"
                                        style={ styles.flexCenter }
                                    >
                                        <LabelWithHelp
                                            style={ styles.marginRight }
                                            label="Актуальный товар"
                                            help="На странице магазина будут отображаться только актуальные товары"
                                        />
                                        <Checkbox
                                            checked={ isActive }
                                            onChange={ e => this.handleChangeField('isActive', e.target.checked) }
                                            disabled={ inProgress }
                                        />
                                    </div>
                                </label>
                            </div>
                        }
                    </div>
                </Dialog>
            </div>
        );
    }
}
