import { CALL_API } from '../../../constants/actionTypes';
import { apiUrls } from '../constants/apiUrls';
import {
    LOAD_PROJECT_CONSTANTS_START,
    LOAD_PROJECT_CONSTANTS_SUCCESS,
    LOAD_PROJECT_CONSTANTS_FAIL,
} from '../constants/actionConstants';
import { showNotification } from '../../../helpers/showNotification';

export const loadConstants = neededConstants => (
    {
        type: CALL_API,
        endpoint: apiUrls.loadConstants({
            settings: neededConstants.settings,
            dbOptions: neededConstants.db_options,
        }),
        method: 'GET',
        types: [
            LOAD_PROJECT_CONSTANTS_START,
            LOAD_PROJECT_CONSTANTS_SUCCESS,
            {
                type: LOAD_PROJECT_CONSTANTS_FAIL,
                callback: () => showNotification({ level: 'error', message: 'Не удалось загрузить настройки' }),
            },
        ],
    }
);
