import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';

import ACTION_TYPES from '../actionTypes';
import { arrayToObject } from '../utils';
import * as constants from '../../builder/constants.es6';


const INIT_STATE = {
    prevGroupId: null,
    prevQuestionId: null,
    currentGroupId: null,
    currentQuestionId: null,
    finished: false,
};

export const controls = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZ_LOAD_START: {
            return update(state, { $set: INIT_STATE });
        }
        case ACTION_TYPES.QUIZ_LOAD_FINISH: {
            const newState = { };
            const answers = arrayToObject(action.payload.answers, 'question');
            const questions = arrayToObject(action.payload.questions, 'id');
            const groups = arrayToObject(action.payload.question_groups, 'id');
            const choices = arrayToObject(action.payload.choices, 'id');

            const firstGroupId = action.payload.quiz.quiz_scheme.first_question_group;
            if (!firstGroupId) {
                return state;
            }

            if (action.additionalData.isAnotherTry) {
                // if execute quiz from first question or another try
                const firstGroup = groups[firstGroupId];
                newState.currentGroupId = firstGroupId;
                if (firstGroup.type === constants.QUESTION_GROUP_TYPE_CONSECUTIVE) {
                    const currentQuestionId = groups[firstGroupId].first_question;
                    if (currentQuestionId) {
                        newState.currentQuestionId = currentQuestionId;
                    }
                }
                return update(state, { $merge: newState });
            }

            newState.currentGroupId = firstGroupId;
            newState.prevGroupId = null;
            while (true) {
                const group = groups[newState.currentGroupId];
                const questionsWithoutAnswer = group.questions.filter(questionId => (
                    !(questionId in answers)
                ));
                const questionsWithAnswer = action.payload.answers.filter(
                    answer => (group.questions.indexOf(answer.question) !== -1)).map(answer => answer.question);
                if (!questionsWithoutAnswer.length && group.next_question_group) {
                    newState.prevGroupId = newState.currentGroupId;
                    newState.currentGroupId = group.next_question_group;
                } else if (!questionsWithoutAnswer.length && !group.next_question_group) {
                    newState.finished = true;
                    newState.currentGroupId = null;
                    break;
                } else if (questionsWithoutAnswer.length && group.type !== constants.QUESTION_GROUP_TYPE_CONSECUTIVE) {
                    break;
                } else if (questionsWithAnswer.length) {
                    newState.prevQuestionId = questionsWithAnswer[questionsWithAnswer.length - 1];
                    const prevQuestion = questions[newState.prevQuestionId];
                    const prevQuestionAnswer = answers[newState.prevQuestionId];
                    if (prevQuestionAnswer.choice && choices[prevQuestionAnswer.choice].next_question) {
                        newState.currentQuestionId = choices[prevQuestionAnswer.choice].next_question;
                    } else if (prevQuestion.next_question) {
                        newState.currentQuestionId = prevQuestion.next_question;
                    } else {
                        if (group.next_question_group) {
                            newState.prevGroupId = newState.currentGroupId;
                            newState.currentGroupId = group.next_question_group;
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        newState.finished = true;
                        newState.currentGroupId = null;
                        break;
                    }
                    break;
                } else {
                    // if group type is CONSECUTIVE and no answers
                    newState.currentQuestionId = group.first_question;
                    break;
                }
            }

            return update(state, { $merge: newState });
        }
        case ACTION_TYPES.COMMIT_ANSWERS_FINISH: {
            const newControls = action.additionalData.controls;
            if (!Object.keys(newControls).length) {
                return state;
            }
            const prevGroupId = ('currentGroupId' in newControls) ? state.currentGroupId : state.prevGroupId;
            const currentGroupId = ('currentGroupId' in newControls) ? newControls.currentGroupId : state.currentGroupId;
            const finished = (!currentGroupId && !newControls.currentQuestionId);
            return update(state, {
                prevGroupId: { $set: prevGroupId },
                prevQuestionId: { $set: state.currentQuestionId },
                currentGroupId: { $set: currentGroupId },
                currentQuestionId: { $set: newControls.currentQuestionId },
                finished: { $set: finished },
            });
        }
        case ACTION_TYPES.GO_TO_NEXT: {
            const newControls = action.controls;
            const prevGroupId = ('currentGroupId' in newControls) ? state.currentGroupId : state.prevGroupId;
            const currentGroupId = ('currentGroupId' in newControls) ? newControls.currentGroupId : state.currentGroupId;
            const finished = (!currentGroupId && !newControls.currentQuestionId);
            return update(state, {
                prevGroupId: { $set: prevGroupId },
                prevQuestionId: { $set: state.currentQuestionId },
                currentGroupId: { $set: currentGroupId },
                currentQuestionId: { $set: newControls.currentQuestionId },
                finished: { $set: finished },
            });
        }
        case ACTION_TYPES.GO_TO_PREVIOUS: {
            const newControls = action.controls;
            const currentGroupId = ('prevGroupId' in newControls) ? state.prevGroupId : state.currentGroupId;
            const prevGroupId = ('prevGroupId' in newControls) ? newControls.prevGroupId : state.prevGroupId;
            const currentQuestionId = ('currentQuestionId' in newControls) ?
                newControls.currentQuestionId : state.prevQuestionId;
            return update(state, {
                prevGroupId: { $set: prevGroupId },
                prevQuestionId: { $set: newControls.prevQuestionId },
                currentGroupId: { $set: currentGroupId },
                currentQuestionId: { $set: currentQuestionId },
            });
        }
        case LOCATION_CHANGE:
            return INIT_STATE;
        default:
            return state;
    }
};
