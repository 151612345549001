import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, A } from 'intdev-ui';

import { PeopleTooltipItem } from './PeopleTooltipItem';
import PeopleCounterModal from './PeopleCounterModal';

const styles = {
    tooltipStyle: {
        zIndex: 2,
    },
};

export class PeopleTooltip extends Component {
    static propTypes = {
        isTooltipActive: PropTypes.bool.isRequired,
        position: PropTypes.string,
        tooltipItemInfo: PropTypes.shape(),
    };

    static defaultProps = {
        tooltipItemInfo: undefined,
        position: 'bottom_right',
    };

    state = {
        isOpenModal: false,
    };

    handleOpenModal() {
        this.setState({ isOpenModal: true });
    }

    handleCloseModal() {
        this.setState({ isOpenModal: false });
    }

    render() {
        const { isTooltipActive, tooltipItemInfo, position } = this.props;
        let isVeryLong = false;

        if (!tooltipItemInfo) {
            return (
                <Tooltip
                    active={ isTooltipActive }
                    position={ position }
                    style={ styles.tooltipStyle }
                >
                    <i className="fa fa-spinner fa-spin fa-fw" />
                </Tooltip>
            );
        }
        return (
            <Tooltip
                active={ isTooltipActive && !!tooltipItemInfo.likes.length }
                position={ position }
                style={ styles.tooltipStyle }
            >
                <div className="user-counter-container">
                    <div className="user-counter-tooltip">
                        { tooltipItemInfo.likes.map((result, index) => {
                            if (!tooltipItemInfo.num_likes_to_show || (index < tooltipItemInfo.num_likes_to_show)) {
                                return (
                                    <PeopleTooltipItem userInfo={ result } key={ result.url } />
                                );
                            }
                            isVeryLong = true;
                            return null;
                        }) }
                    </div>
                    {
                        isVeryLong
                            ? (
                                <div className="user-counter-tooltip-info-line">
                                    <A
                                        className="user-counter-info"
                                        onClick={ () => this.handleOpenModal() }
                                    >
                                        посмотреть все
                                    </A>
                                </div>
                            ) : null
                    }
                </div>
                <PeopleCounterModal
                    open={ this.state.isOpenModal }
                    tooltipItemInfo={ tooltipItemInfo }
                    handleCloseModal={ () => this.handleCloseModal() }
                />
            </Tooltip>
        );
    }
}
