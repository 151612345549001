import React from 'react';
import { A, Dialog } from 'intdev-ui';
import PropTypes from 'prop-types';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import urls from '../../urls';
import MrgIcon from '../../../common/components/MrgIcon';
import StickerPreloader from '../StickerPreloader';
import cry from '../../stickers_cry.png';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary';
import { dictionary } from '@/common/localization/dictionary';

const contentStyle = {
    width: '90vw',
    maxWidth: '1100px',
    maxHeight: '100vh',
};
const bodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    borderTop: 'solid rgba(0,0,0,.1) 1px',
};

const RECENT = 'RECENT';

class StickerSelectDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func.isRequired,
        onRequestAttachSticker: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
    };

    static postRecentSticker(stickerId) {
        callApi(
            urls.recentStickersUrl,
            'POST',
            {
                sticker_id: stickerId,
            },
        );
    }

    state = {
        fetching: true,
        packs: [],
        packSelected: -1,
        stickers: [],
        recentStickers: [],
    };

    componentDidMount() {
        if (this.props.open) {
            this.fetchPackPreview();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            this.fetchPackPreview();
        }
    }

    fetchPackPreview = () => {
        callApi(urls.fetchPacksPreview)
            .then((data) => {
                const packs = data.packs;
                const recentStickers = data.recent_stickers;

                if (packs && packs.length) {
                    const packSelected = this.state.packSelected + 1 ? this.state.packSelected : packs[0].id;
                    this.setState({
                        packs,
                    }, () => {
                        this.fetchPack(packSelected);
                    });
                } else {
                    this.setState({
                        fetching: false,
                    });
                }
                if (recentStickers && recentStickers.length) {
                    this.setState({
                        recentStickers,
                    });
                }
            });
    };

    fetchPack = (id) => {
        if (!this.state.stickers.length) {
            this.setState({
                fetching: true,
            });
        }
        callApi(urls.packEntryUrl(id))
            .then((data) => {
                this.setState({
                    stickers: data.pack.stickers,
                    fetching: false,
                    packSelected: id,
                });
            });
    };

    handleSelectPack = (e) => {
        this.fetchPack(e.target.dataset.id);
    };

    handleSelectSticker = (e) => {
        const {
            id,
            url,
            packId,
        } = e.target.dataset;

        StickerSelectDialog.postRecentSticker(+id);
        this.props.onRequestAttachSticker(+id, url, +packId);
    };

    handleSelectRecent = () => {
        this.setState({
            packSelected: RECENT,
        });
    };

    renderStickers = () => {
        const {
            packs,
            stickers,
            packSelected,
            recentStickers,
        } = this.state;

        const {
            onRequestClose,
        } = this.props;

        const recentNotSelected = packSelected !== RECENT;

        if (!stickers.length && recentNotSelected) {
            return (
                <div className="sticker_select_dialog__empty">
                    <img src={ cry } alt="cry" />
                    <div>{ dictionary.stickerPackListIsEmpty }</div>
                    <div>
                        <A href={ urls.topStickers } onClick={ onRequestClose }>{ dictionary.addStickerPack }</A>
                    </div>
                </div>
            );
        }

        return (
            <div className="sticker_select_dialog__sticker_wrapper">
                <div className="sticker_select_dialog__sticker_wrapper_title">
                    {
                        recentNotSelected
                            ? packs.filter(pack => pack.id === +packSelected)[0].name
                            : 'Недавние'
                    }
                </div>
                {
                    (recentNotSelected ? stickers : recentStickers)
                        .filter(sticker => sticker.url)
                        .map(sticker => (
                            <img
                                className="sticker_select_dialog__item_sticker"
                                key={ sticker.id }
                                data-id={ sticker.id }
                                data-url={ sticker.url }
                                data-pack-id={ recentNotSelected ? packSelected : sticker.pack_id }
                                src={ sticker.url }
                                onClick={ this.handleSelectSticker }
                                alt="Стикер"
                            />
                        ))
                }
            </div>
        );
    };

    render() {
        const {
            open,
            onRequestClose,
        } = this.props;

        const {
            packs,
            fetching,
            packSelected,
            recentStickers,
        } = this.state;

        return (
            <Dialog
                open={ open }
                title={ dictionary.stickerSelection }
                contentStyle={ contentStyle }
                onRequestClose={ onRequestClose }
                bodyStyle={ bodyStyle }
            >
                {
                    fetching
                        ? <StickerPreloader />
                        : this.renderStickers()
                }
                <div className="sticker-dialog__packs">
                    <div className="sticker-dialog__pack" onClick={ onRequestClose }>
                        <A href={ urls.stickers }>
                            <MrgIcon
                                className="icon_navigation--settings sticker-dialog__pack-icon"
                            />
                        </A>
                    </div>
                    {
                        recentStickers.length ?
                            <div
                                className={ `sticker-dialog__pack ${packSelected === RECENT ? 'sticker-dialog__pack--active' : ''}` }
                                onClick={ this.handleSelectRecent }
                            >
                                <MrgIcon
                                    className="icon_status--time_5min sticker-dialog__pack-icon"
                                />
                            </div> : null
                    }
                    {
                        packs.map(pack => (
                            <img
                                key={ pack.id }
                                className={ `sticker-dialog__pack ${pack.id === +packSelected ? 'sticker-dialog__pack--active' : ''}` }
                                src={ pack.url }
                                data-id={ pack.id }
                                onClick={ this.handleSelectPack }
                                alt="Стикер"
                            />
                        ))
                    }
                </div>
            </Dialog>
        );
    }
}

export default props => <ErrorBoundary errorNotification renderNull><StickerSelectDialog { ...props } /></ErrorBoundary>;
