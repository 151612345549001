import { showNotification } from './showNotification';


export const handleResponseError = (response) => {
    const notification = {
        level: 'error',
        message: `${response.status} Error!`,
    };
    if (typeof response.error === 'object') {
        let errorList = [];
        Object.keys(response.error).forEach((key) => {
            errorList = errorList.concat(response.error[key]);
        });
        const errorMessage = errorList.join('; ');
        notification.message = `${response.status} ${errorMessage}`;
    } else if (typeof response.error === 'string') {
        notification.message = `${response.status} ${response.error}`;
    }
    showNotification(notification);
};

