import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { configureStore } from '../../../../common/helpers/store';
import { calendarReducerMap, loadCalendarConfig } from '../../../../calendar/reducers';
import { CalendarContainer } from '../../../../calendar/components/CalendarContainer';


class Meetings extends Component {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.number),
        objectId: PropTypes.number.isRequired,
        contentTypeId: PropTypes.number.isRequired,
    };
    static defaultProps = {
        ids: [],
    };
    constructor(props) {
        super(props);
        const publicationStore = configureStore(combineReducers({
            ...calendarReducerMap,
        }));
        loadCalendarConfig(publicationStore, this.props.contentTypeId, this.props.objectId);
    }

    render() {
        const {
            props: { ids, objectId, contentTypeId },
        } = this;
        if (!ids.length) {
            return null;
        }
        return (
            <div className="calendar-container">
                <CalendarContainer
                    objectId={ objectId }
                    contentType={ contentTypeId }
                    isGroupEventsByName
                />
            </div>
        );
    }
}

export default Meetings;
