export const getMicropostUrl = postId => `/timeline/api/micropost/post/${postId}/`;
export const uploadFileUrl = '/uploads/ajax-upload_new/';
export const getWriteMicropostUrl = (toUserId = null, postId = null) => {
    if (postId) {
        return `/timeline/api/micropost/post/${postId}/edit/`;
    }
    if (toUserId) {
        return `/timeline/api/micropost/user/${toUserId}/`;
    }
    return '/timeline/api/micropost/';
};
export const fetchFileUrl = '/uploads/url-upload/';
export const getPublicMicropostUrl = (postId, edit = false) => {
    const url = `/timeline/micropost/${postId}/`;
    if (edit) {
        return `${url}edit/`;
    }
    return url;
};
