import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { callApi } from '@/common/middlewares/apiMiddleware';
import CommentTooltip from '@/comments/components/helpers/CommentTooltip';


class CommentTooltipContainer extends Component {
    static propTypes = {
        commentId: PropTypes.number.isRequired,
    };

    state = {
        commentData: null,
        process: false,
    };

    loadData = () => {
        const {
            commentId,
        } = this.props;
        this.setState({ process: true });
        callApi(`/comments/${commentId}/tooltip_info/`).then(({ comment_data: commentData }) => {
            this.setState({
                commentData: {
                    created_at: moment(commentData.created_at).format('DD.MM.YYYY HH:mm'),
                    username: commentData.user.full_name,
                    photo: commentData.user.avatar,
                    userlink: commentData.user.url,
                    text: commentData.text,
                    url: commentData.url,
                },
                process: false,
            });
        });
    };

    handleMouseEnter = () => {
        if (this.state.commentData) {
            return;
        }
        this.loadData();
    };

    handleClick = (event) => {
        event.stopPropagation();
        return true;
    };

    render() {
        const { commentData, process } = this.state;
        return (
            <span className="timeline-comment-tooltip-container" onMouseEnter={ this.handleMouseEnter }>
                <CommentTooltip data={ commentData } process={ process }>
                    <span>в ответ на </span>
                    <A
                        href={ commentData ? commentData.url : '#' }
                        className="timeline-comment-tooltip-label"
                        onClick={ this.handleClick }
                        mode="secondary"
                    >
                        комментарий...
                    </A>
                </CommentTooltip>
            </span>
        );
    }
}

export default CommentTooltipContainer;
