import { determineScreenSize } from '@/common/mediaQueries';

export const media = determineScreenSize({
    common: {
        timelineSettingsIconStyle: { color: '#706F6F' },
        followingRecommendationTooltipContainer: {
            avatarSize: 40,
            display: 'flex',
        },
    },
    small: {
        svgIconPlusSize: { height: 16, width: 16 },
    },
    medium: {
        svgIconPlusSize: { height: 18, width: 18 },
    },
    large: {
        svgIconPlusSize: { height: 20, width: 20 },
    },
    desktop: {
        svgIconPlusSize: { height: 14, width: 14 },
        followingRecommendationTooltipContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
});
