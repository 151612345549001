import { CALL_API } from '../../../common/constants/actionTypes';
import ACTION_TYPES from '../actionTypes';
import {
    GET_USER_RESULTS_URL, GET_STATISTIC_URL,
    GET_QUIZ_RESULT_LIST_URL, REMOVE_QUIZ_ANSWERS_URL,
} from '../constants';

export const getResults = quizId => ({
    type: CALL_API,
    endpoint: GET_USER_RESULTS_URL(quizId),
    method: 'get',
    types: [ACTION_TYPES.GET_RESULTS_START, ACTION_TYPES.GET_RESULTS_FINISH, ACTION_TYPES.API_FAIL],
});


export const fetchQuizStatistic = quizId => ({
    type: CALL_API,
    endpoint: GET_STATISTIC_URL(quizId),
    method: 'get',
    types: [ACTION_TYPES.GET_STATISTIC_START, ACTION_TYPES.GET_STATISTIC_FINISH, ACTION_TYPES.API_FAIL],
});


export const fetchQuizResults = (quizId, pageNum = 1, userIds = []) => ({
    type: CALL_API,
    endpoint: GET_QUIZ_RESULT_LIST_URL(quizId, pageNum, userIds),
    method: 'get',
    types: [ACTION_TYPES.GET_QUIZ_RESULT_START, ACTION_TYPES.GET_QUIZ_RESULT_FINISH, ACTION_TYPES.API_FAIL],
    additionalData: {
        pageNum,
    },
});


export const removeAnswers = (quizId, objId, pageNum) => ({
    type: CALL_API,
    endpoint: REMOVE_QUIZ_ANSWERS_URL(quizId, objId),
    method: 'delete',
    types: [
        ACTION_TYPES.REMOVE_ANSWERS_START,
        {
            type: ACTION_TYPES.REMOVE_ANSWERS_FINISH,
            callback: store => store.dispatch(fetchQuizResults(quizId, pageNum)),
        },
        ACTION_TYPES.API_FAIL,
    ],
});


export const firstFetchQuizResults = (quizId, pageNum = 1, userIds = []) => ({
    type: CALL_API,
    endpoint: GET_QUIZ_RESULT_LIST_URL(quizId, pageNum, userIds),
    method: 'get',
    types: [ACTION_TYPES.GET_FIRST_QUIZ_RESULT_START, ACTION_TYPES.GET_QUIZ_RESULT_FINISH, ACTION_TYPES.API_FAIL],
    additionalData: {
        pageNum,
    },
});
