import React from 'react';
import PropTypes from 'prop-types';
import { RadioSelect } from 'intdev-ui';
import { UserSuggestionsWithDropzone } from '../../common/components/UserSuggestionsWithDropzone';


const radioOptions = [
    { value: 1, label: 'всем' },
    { value: 2, label: 'некоторым сотрудникам' },
];

const styles = {
    rootStyle: {
        width: '100%',
    },
    radioSelectWrapperStyle: {
        marginBottom: '0',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    radioOptionStyle: {
        marginBottom: '0',
        marginRight: '20px',
    },
};


export default class UserJoinAllowedSelect extends React.Component {
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape()),
        onChange: PropTypes.func.isRequired,
        parseExcel: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        values: [],
        useDropzone: false,
    };

    state = {
        radioValue: undefined,
    };

    handleRadioChange = (event, value) => {
        this.setState({ radioValue: value });
        this.props.onChange([]);
    };

    render() {
        const { values, disabled } = this.props;

        const radioValue = this.state.radioValue || radioOptions[values.length ? 1 : 0];
        const allowAny = (radioValue && radioValue.value === 1) || (!radioValue && !values.length);
        return (
            <div style={ styles.rootStyle }>
                <RadioSelect
                    name="horizontal"
                    options={ radioOptions }
                    onChange={ this.handleRadioChange }
                    value={ radioValue }
                    wrapperStyle={ styles.radioSelectWrapperStyle }
                    style={ styles.radioOptionStyle }
                />
                {
                    !allowAny &&
                    <UserSuggestionsWithDropzone
                        users={ values }
                        disabled={ disabled }
                        onChange={ this.props.onChange }
                        parseExcel={ this.props.parseExcel }
                    />
                }
            </div>
        );
    }
}
