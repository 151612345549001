import { CALL_API } from '../../../constants/actionTypes';
import { apiUrls } from '../constants/apiUrls';
import {
    LOAD_PERMISSIONS_START,
    LOAD_PERMISSIONS_SUCCESS,
    LOAD_PERMISSIONS_FAIL,
} from '../constants/actionConstants';


export const loadPermissions = permissionList => ({
    type: CALL_API,
    endpoint: apiUrls.loadPermissions(permissionList),
    method: 'GET',
    types: [
        LOAD_PERMISSIONS_START,
        LOAD_PERMISSIONS_SUCCESS,
        LOAD_PERMISSIONS_FAIL,
    ],
});
