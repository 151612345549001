export const backgroundColors = [
    '#9043ca',
    '#ef6176',
    '#f56d44',
    '#5684e3',
    '#839098',
    '#35a8c0',
    '#99c953',
];

export const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        border: '1px solid rgb(220, 220, 220)',
        borderRadius: 2,
        padding: 8,
        marginBottom: 4,
        width: '95%',
    },
    titleWrapper: {
        flex: 1,
        minWidth: 0,
        color: 'inherit',
    },
    title: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        fontWeight: 'bold',
        fontSize: 16,
    },
    imageWrapper: {
        flex: '0 0 64px',
        height: 64,
        width: 64,
        marginRight: 8,
    },
    image: {
        height: 64,
        width: 64,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    description: {
        marginTop: 8,
        color: '#999',
        fontSize: 11,
    },
    firstLetterWrapper: {
        width: '100%',
        height: '100%',
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        position: 'relative',
        borderRadius: '50%',
    },
    firstLetter: {
        position: 'absolute',
        display: 'inline-block',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
    },
    prefixTextStyle: {
        color: '#ff1100',
    },
};
