/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Slider } from './Slider';

import { changeAnswer } from '../../actions/answers';


class SliderQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.shape().isRequired,
        choicesList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            number: PropTypes.number,
        })).isRequired,
        onChange: PropTypes.func.isRequired,
        changeAnswer: PropTypes.func.isRequired,
        activeIndex: PropTypes.number,
        tmpAnswerExist: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        activeIndex: null,
    };

    componentDidUpdate(prevProps) {
        const {
            activeIndex,
            question,
            tmpAnswerExist,
        } = this.props;
        if (activeIndex === -1 && prevProps.tmpAnswerExist !== tmpAnswerExist) {
            // if we haven't answer and have already initialized tmp answer for this question
            // create tmp answer with first option
            this.props.changeAnswer(question.id, { value: question.choice_set[0] });
        }
    }

    get value() {
        return this.props.activeIndex + 1;
    }

    handleChange = (event, number) => {
        const index = parseInt(number, 10) - 1;
        this.props.onChange(event, { value: this.props.question.choice_set[index] });
    };

    labelFunc = (number) => {
        const index = parseInt(number, 10) - 1;
        return (
            <span dangerouslySetInnerHTML={ { __html: this.props.choicesList[index].text } } />
        );
    };

    render() {
        if (!this.props.choicesList.length) {
            return null;
        }
        return (
            <div className="quiz-detail__slider-question-container">
                <Slider
                    minValue={ 1 }
                    maxValue={ this.props.choicesList.length }
                    value={ this.value }
                    onChange={ this.handleChange }
                    options={ this.props.choicesList }
                    labelsCount={ this.props.choicesList.length }
                    labelFunc={ this.labelFunc }
                />
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id];
    const choicesList = ownProps.question.choice_set.map(choiceId => state.choices[choiceId]);
    const tmpAnswerExist = !!(state.answers.tmpAnswers[ownProps.question.id]);
    return {
        activeIndex: (answer) ? ownProps.question.choice_set.indexOf(answer.value) : -1,
        tmpAnswerExist,
        choicesList,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    changeAnswer,
}, dispatch);

export default connect(
    mapStateToProps, mapDispatchToProps,
)(SliderQuestion);
