export const styles = {
    checkbox: {
        display: 'flex',
        marginBottom: '5px',
    },
    checkboxLabel: {
        fontSize: '14px',
        marginLeft: '4px',
        cursor: 'pointer',
    },
    checkboxLabelDisabled: {
        fontSize: '14px',
        marginLeft: '4px',
        color: '#a6a6a6',
    },
    externalCalendars: {
        marginBottom: '5px',
    },
    externalCalendarsLabel: {
        fontSize: '14px',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    externalCalendarsMessage: {
        paddingTop: '4px',
        fontSize: '13px',
        lineHeight: '12px',
    },
    externalCalendarsError: {
        color: 'red',
    },
};
