import { buildUrl } from '../../common/helpers/urlParams';

export const CONTAINER_CLASS_NAME = 'quiz-detail-container';
export const BASE_URL = '/quiz/';
export const BASE_API_URL = `${BASE_URL}api/`;
export const BASE_BUILDER_URL = `${BASE_URL}builder/`;

export const QUIZ_DETAIL_URL = quizId => `${BASE_API_URL}${quizId}/`;
export const QUIZZES_LIST_URL = `${BASE_API_URL}list/`;
export const COMMIT_ANSWERS_URL = quizId => `${BASE_API_URL}${quizId}/answers/`;
export const REMOVE_QUIZ_ANSWERS_URL = (quizId, userId) => (
    `${BASE_API_URL}${quizId}/answers/${userId}/`
);
export const GET_USER_RESULTS_URL = quizId => `${BASE_API_URL}${quizId}/user_results/`;
export const QUIZ_UNRESTRICT_URL = quizId => `${BASE_API_URL}${quizId}/unrestrict/`;
export const QUIZ_RESTRICT_URL = quizId => `${BASE_API_URL}${quizId}/restrict/`;
export const QUIZ_SET_ACTIVE_URL = quizId => `${BASE_API_URL}${quizId}/set_active/`;
export const QUIZ_TO_ARCHIVE_URL = quizId => `${BASE_API_URL}${quizId}/set_archive/`;
export const QUIZ_PUBLISH_ON_MAIN_PAGE_URL = quizId => `${BASE_API_URL}${quizId}/publish_on_main_page/`;
export const QUIZ_NOT_PUBLISH_ON_MAIN_PAGE_URL = quizId => `${BASE_API_URL}${quizId}/not_publish_on_main_page/`;
export const QUIZ_UNPUBLISH_FROM_MAIN_PAGE_URL = quizId => `${BASE_API_URL}${quizId}/unpublish_from_main_page/`;
export const QUIZ_BY_PASS_URL = (quizId, choiceId) => `${BASE_API_URL}${quizId}/by_pass/${choiceId}`;
export const ANSWER_BY_PASS_URL = `${BASE_API_URL}answer_by_pass/`;

export const QUIZ_BUILDER_URL = quizId => `${BASE_BUILDER_URL}${quizId}/`;
export const QUIZ_BUILDER_WITH_CT_URL = `${BASE_BUILDER_URL}?content_type=&object_id=`;
export const QUIZ_SCHEME_CLONE_URL = quizId => `${BASE_BUILDER_URL}quiz-scheme/${quizId}/clone/`;
export const QUIZ_CLONE_URL = quizId => `${BASE_BUILDER_URL}quiz/${quizId}/clone/`;
export const QUIZ_SCHEME_DETAIL_URL = schemeId => `${BASE_API_URL}scheme/${schemeId}/`;

export const EXPORT_RESULTS_URL = quizId => `${BASE_URL}${quizId}/export/`;
export const EXPORT_STATISTICS_URL = quizId => `${BASE_URL}${quizId}/export_stats/`;
export const EXPORT_SCHEME_RESULTS_URL = quizId => `${BASE_URL}scheme/${quizId}/export/`;
export const EXPORT_SCHEME_STATISTICS_URL = quizId => `${BASE_URL}scheme/${quizId}/export_stats/`;
export const QUIZZES_COUNTERS_URL = `${BASE_API_URL}list/counters/`;
export const GET_STATISTIC_URL = quizId => `${BASE_API_URL}${quizId}/answers/statistic/`;

export const RESULT_PAGE_SIZE = 15;
export const GET_QUIZ_RESULT_LIST_URL = (quizId, pageNum = 1, userIds = []) => {
    const params = {
        page: pageNum,
        page_size: RESULT_PAGE_SIZE,
    };
    if (userIds.length) {
        params.ids = userIds.join(',');
    }
    return buildUrl(params, `${BASE_API_URL}${quizId}/results/`);
};

export const OTHER_ANSWER_VALUE = 0;

export const FLAG_ARCHIVE = 0;
export const FLAG_PUBLISHED = 1;
export const FLAG_NEW = 2;
export const FLAG_DELETE = 3;

export const NOT_ON_MAIN_PAGE = 0;
export const ON_MODERATION = 1;
export const ON_MAIN_PAGE = 2;


export const QUIZZES_LIST_PAGE_URL = BASE_URL;
export const QUIZ_PAGE_URL = quizId => `${BASE_URL}${quizId}/`;
export const SINGLE_QUIZ_RESULTS_PAGE_URL = quizId => `${BASE_URL}${quizId}/results/`;
export const QUIZ_SCHEME_RESULTS_PAGE_URL = quizSchemeId => `${BASE_URL}scheme/${quizSchemeId}/results/`;
export const CHOICE_BY_PASS_URL = (quizId, choiceId) => `${BASE_URL}${quizId}/by_pass/${choiceId}/`;

export const [
    MY_CODE, ALL_CODE, MODERATION_CODE, ARCHIVED_CODE, MAIN_PAGE_CODE,
] = ['my', 'all', 'moderation', 'archived', 'mainPage'];
export const ALL_CODES = [
    MY_CODE, ALL_CODE, MODERATION_CODE, ARCHIVED_CODE, MAIN_PAGE_CODE,
];

export const LIMITED_CODES = [
    ALL_CODE, ARCHIVED_CODE, MY_CODE,
];

export const MAX_QUESTION_LENGTH = 50;
export const EXPORT_TYPE_RESULTS = 0;
export const EXPORT_TYPE_STATISTICS = 1;
export const EXPORT_TYPE_SCHEME_RESULTS = 2;
export const EXPORT_TYPE_SCHEME_STATISTICS = 3;

export const CLONE_QUESTION_GROUP_URL = groupId => `${BASE_URL}builder/question-group/${groupId}/clone/`;

export const quizListBreadCrumb = { url: QUIZZES_LIST_PAGE_URL, title: 'Опросы', inRouter: true };
