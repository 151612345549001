import React, { Component } from 'react';
import { Popover } from 'intdev-ui';
import PropTypes from 'prop-types';

import { HUMAN_EMOJI } from '../constants';

export default class EmojiMobileTooltip extends Component {
    static propTypes = {
        isOpenPopover: PropTypes.bool.isRequired,
        onLike: PropTypes.func.isRequired,
        handleClosePopover: PropTypes.func,
        anchor: PropTypes.instanceOf(Object),
    };

    static defaultProps = {
        anchor: null,
        handleClosePopover: () => {},
    };

    onLike = emoji => () => {
        this.props.onLike(emoji);
        this.props.handleClosePopover();
    };

    render() {
        const { isOpenPopover, anchor } = this.props;

        return (
            <Popover
                open={ isOpenPopover }
                anchorEl={ anchor }
                anchorOrigin={ { horizontal: 'left', vertical: 'top' } }
                targetOrigin={ { horizontal: 'left', vertical: 'top' } }
                className="likes-form-tooltip"
                onRequestClose={ this.props.handleClosePopover }
                style={ { display: 'flex', borderRadius: 50 } }
                preventClickAway={ false }
                useLayerForClickAway={ false }
            >
                {
                    Object.keys(HUMAN_EMOJI).map(
                        emoji => (
                            <div
                                key={ `like-emoji-${emoji}` }
                                className="like-emoji-form-icon"
                                onClick={ this.onLike(emoji) }
                            >
                                <div className={ `like-emoji-text${HUMAN_EMOJI[emoji].long ? ' long' : ''}` }>
                                    { HUMAN_EMOJI[emoji].text }
                                </div>
                                <img src={ HUMAN_EMOJI[emoji].icon } alt={ HUMAN_EMOJI[emoji].text } />
                            </div>
                        ),
                    )
                }
            </Popover>
        );
    }
}
