import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { merge } from 'lodash';
import { ResizableBox } from 'react-resizable';
import { IUIPropTypes, Validation, Spinner } from 'intdev-ui';

import redactorInitializer from '@/redactor';

import { fileUploadUrl } from '@/common/constants/apiUrls';
import { ACCEPTED_FILE_FORMATS, ACCEPTED_MAX_FILE_SIZE } from '@/common/constants/fileUploadSettings';

import { isMobile } from '@/common/mediaQueries';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { DebouncedLoader } from '../DebouncedLoader';

import './BeautifulRedactorFieldResetStyles.css';
import 'react-resizable/css/styles.css';

export class BeautifulRedactorField extends React.Component {
    /**
     * @param {Number} height -- передавай null, если хочешь, чтобы контейнер сам растянулся по вертикали
     * @param {Number} width -- этот параметр игнорируется, если передан fullWidth
     * @param {boolean} resizable -- если тру, то контейнер можно ресайзить по вертикали
     * @param {func} onSendButtonClick -- добавляет кнопку "Отправить" в правый верхний край тулбара
     */
    static propTypes = {
        disabled: PropTypes.bool,
        resizable: PropTypes.bool,
        resizableInitHeight: PropTypes.number,
        fullWidth: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        formatting: PropTypes.bool,
        style: IUIPropTypes.style,
        mentions: PropTypes.bool,
        imagemanager: PropTypes.bool,
        file: PropTypes.bool,
        plugins: PropTypes.arrayOf(PropTypes.string),
        focusOnInit: PropTypes.bool,
        onSendButtonClick: PropTypes.func,

        validationText: PropTypes.string,
        validationState: PropTypes.string,
        validationScroll: PropTypes.string,
        validationStyle: IUIPropTypes.style,
        config: PropTypes.shape({}),
    };

    static defaultProps = {
        fullWidth: false,
        resizable: false,
        resizableInitHeight: 300,
        disabled: false,
        placeholder: '',
        width: 400,
        height: 200,
        value: '',
        formatting: false,
        style: {},
        mentions: false,
        imagemanager: false,
        file: false,
        plugins: [],
        focusOnInit: false,
        onSendButtonClick: null,
        validationText: '',
        validationState: null,
        validationScroll: null,
        validationStyle: {},
        config: {},
    };

    isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;

    state = {
        redactorReady: false,
        imageUploads: [],
    };

    async componentDidMount() {
        const that = this;

        const redactorConfig = {
            lang: 'ru',
            focus: this.props.focusOnInit,
            toolbar: this.props.formatting || this.props.config?.buttons,
            pasteLinks: true,
            pasteImages: this.isFirefox,
            toolbarFixed: !isMobile,
            pasteBlockTags: [],
            pasteInlineTags: [],
            pastePlainText: false,
            maxWidth: that.props.fullWidth ? '100%' : that.props.width,
            plugins: this.props.plugins,
            callbacks: {
                change() {
                    that.handleInput(this.code.get());
                },
                initToEdit() {
                    that.setState({
                        redactorReady: true,
                    }, () => {
                        this.code.set(that.props.value);
                        this.observe.load();
                    });
                },
                onPastedUpload({ placeholderId, inProgress }) {
                    that.setState({
                        imageUploads: inProgress
                            ? [...that.state.imageUploads, placeholderId]
                            : that.state.imageUploads.filter(id => id !== placeholderId),
                    });
                },
                fileUploadTransformFormData(formData, file) {
                    if (file && file.name) {
                        formData.append('name', file.name);
                        formData.append('from_comment', true);
                    }
                    return formData;
                },
                fileUploadTransformXHR(xhr) {
                    function getCsrfToken() {
                        const match = document.cookie.match(/csrftoken=([^ ;]+)/);
                        if (!match) {
                            return null;
                        }
                        return match[1];
                    }
                    const csrftoken = getCsrfToken();
                    if (csrftoken !== undefined) {
                        xhr.setRequestHeader('X-CSRFToken', csrftoken);
                    }
                },
                imageUpload(el, json) {
                    if (this.opts.imageUploadNoClipboardBlob) {
                        el.attr('src', json.path);
                        el.attr('data-attachment-image-id', json.attachment_image_id);
                    }
                },
            },
        };

        if (this.props.height && !this.props.resizable) {
            redactorConfig.maxHeight = this.formHeight;
            redactorConfig.minHeight = this.formHeight;
        }

        if (this.props.mentions) {
            redactorConfig.plugins.push('mention');
            redactorConfig.mentionDelimiters = ['@'];
        }

        redactorConfig.buttons = [];
        redactorConfig.formatting = [];

        if (this.props.formatting) {
            redactorConfig.buttons = isMobile
                ? ['format', 'link', 'bold', 'italic']
                : ['format', 'lists', 'link', 'bold', 'italic', 'deleted', 'underline', 'horizontalrule'];
            redactorConfig.formatting = ['p', 'blockquote', 'pre', 'h1', 'h2'];
        }

        if (this.props.imagemanager) {
            redactorConfig.plugins.push('imagemanager');
            redactorConfig.buttons.push('image');
            redactorConfig.imageUpload = '/uploads/ajax-upload_new/';
            redactorConfig.imageTag = 'p';
            redactorConfig.imagePosition = true;
            redactorConfig.imageResizable = true;
            redactorConfig.urlUploads = '/uploads/url-upload/';
        }
        if (this.props.placeholder) {
            redactorConfig.placeholder = this.props.placeholder;
        }
        if (this.props.file) {
            redactorConfig.buttons.push('file');
            redactorConfig.fileUpload = fileUploadUrl;
            redactorConfig.fileUploadParam = 'attachment_file';
            redactorConfig.fileUploadAccept = [
                ACCEPTED_FILE_FORMATS.map(({ extension }) => `.${extension}`),
                ACCEPTED_FILE_FORMATS.map(({ mime }) => mime).flat(),
            ].flat();
            redactorConfig.fileUploadFilenameField = false;
            redactorConfig.fileUploadInfo = (
                /* eslint-disable-next-line prefer-template */
                '<section><p>'
                    + '<span>Поддерживаемые форматы: ' + ACCEPTED_FILE_FORMATS.map(fmt => fmt.extension).join(', ') + '.</span>'
                    + '<br/>'
                    + '<span>Максимальный размер: не более ' + ACCEPTED_MAX_FILE_SIZE + 'Мб.</span>'
                + '</p></section>'
            );
        }

        if (this.props.config) {
            Object.entries(this.props.config).forEach(([k, v]) => {
                redactorConfig[k] = v;
            });
        }

        await redactorInitializer(redactorConfig.plugins);
        $(this.textArea).redactor(redactorConfig);
    }

    componentDidUpdate(_, prevState) {
        /**
         * у редактора нет нормального колбэка на инициализацию (init/start/startToEdit/initToEdit работают не как надо)
         */
        try {
            const condition = this.state.redactorReady && this.props.value !== $(this.textArea).redactor('code.get');
            if (this.isFirefox) {
                if (condition && prevState.imageUploads.length === this.state.imageUploads.length) {
                    $(this.textArea).redactor('code.set', this.props.value);
                }
            } else if (condition) {
                $(this.textArea).redactor('code.set', this.props.value);
            }
        } catch { /* redactor not initialized yet */ }
    }

    get isDisabled() {
        return this.props.disabled || !this.state.redactorReady || this.state.imageUploads.length;
    }

    get formHeight() {
        /**
         * когда передаем высота, наверное, все-таки расчитываем задать высоту всего блока, а не контента в едиторе...
         * -высота тулбара
         * -высота паддингов в едиторе
         * -2 границы по 1px сверху и снизу
         */
        return this.props.height - 49 - 44 - 2;
    }

    get sendButton() {
        /**
         * ты не можешь добавить иконку с тайтлом (только иконку с тултипом)
         * + ты не можешь запихнуть ее в конец тулбара
         */
        const {
            value,
            onSendButtonClick,
        } = this.props;
        const toolbarNode = document.getElementsByClassName('redactor-toolbar')[0];
        if (!this.state.redactorReady || !toolbarNode || !onSendButtonClick) {
            return null;
        }
        const buttonClassName = cn({
            'redactor-send-btn': true,
            'redactor-send-btn__disabled': !value || this.isDisabled,
        });
        const onSendButtonClickAction = value && !this.isDisabled ? onSendButtonClick : null;

        return ReactDOM.createPortal(
            <li className={ buttonClassName }>
                <a onClick={ onSendButtonClickAction }>
                    Отправить&nbsp;
                    <i className="fa fa-paper-plane" />
                </a>
            </li>,
            toolbarNode,
        );
    }

    get rootStyle() {
        const {
            resizable,
            fullWidth,
            validationState,
            width,
            style,
        } = this.props;

        const redactorContainerStyle = {
            transition: 'all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            boxSizing: 'border-box',
            borderRadius: 2,
            color: 'rgb(51, 51, 51)',
            fontSize: '15px',
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
        };

        const styleCustom = {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            width: fullWidth ? '100%' : width,
        };

        if (validationState === 'error') {
            styleCustom.border = '1px solid rgba(244, 78, 78, 1)';
        }
        if (resizable) {
            styleCustom.height = 'calc(100% - 20px)';
        }

        if (this.isDisabled) {
            styleCustom.cursor = 'not-allowed';
            styleCustom.opacity = '0.48';
            styleCustom.backgroundColor = 'rgb(240, 240, 240)';
        } else {
            styleCustom.cursor = 'text';
            styleCustom.backgroundColor = 'rgb(255, 255, 255)';
        }

        const resultStyle = merge(redactorContainerStyle, styleCustom);
        return merge(resultStyle, style);
    }

    handleInput = (value) => {
        if (this.props.onChange && this.props.value !== value) {
            this.props.onChange(value);
        }
    };

    checkEmpty = () => $(this.textArea).redactor('placeholder.isEditorEmpty');

    sanitize = text => $(this.textArea).redactor('clean.getPlainText', text);

    focus = () => $(this.textArea).redactor('focus.start');

    focusEnd = () => $(this.textArea).redactor('focus.end');

    allowInput = () => {
        if (this.isDisabled) {
            return;
        }
        let el = null;
        try {
            el = $(this.textArea).redactor('core.object').$editor;
        } catch (err) {
            return;
        }
        if (!el) {
            return;
        }
        el.attr('contenteditable', 'true');
    };

    getResizableBoxWrapper = children => (
        <ResizableBox
            className="redactor-resizable-full-width"
            width={ Infinity } // https://github.com/STRML/react-resizable/issues/69
            height={ this.props.resizableInitHeight }
            resizeHandles={ ['s'] }
            minConstraints={ [Infinity, 155] }
            maxConstraints={ [Infinity, 800] }
        >
            { children }
        </ResizableBox>
    );

    render() {
        const { redactorReady } = this.state;
        const {
            resizable,
            validationText,
            validationState,
            validationStyle,
            validationScroll,
        } = this.props;

        this.allowInput();

        const loader = (
            <div className="redactor-preloader">
                <Spinner />
            </div>
        );

        return (
            <div className="redactor-container">
                <ConditionalWrapper
                    condition={ resizable }
                    wrapper={ this.getResizableBoxWrapper }
                >
                    <ConditionalWrapper
                        condition={ !redactorReady }
                        renderNull
                    >
                        <DebouncedLoader loader={ loader } />
                    </ConditionalWrapper>
                    <div
                        className="redactor-reset-styles"
                        style={ this.rootStyle }
                    >
                        <textarea ref={ (ref) => { this.textArea = ref; } } />
                        <Validation
                            value={ validationText || null }
                            state={ validationState }
                            style={ validationStyle }
                            scroll={ validationScroll }
                        />
                        { this.sendButton }
                    </div>
                </ConditionalWrapper>
            </div>
        );
    }
}
