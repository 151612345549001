import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'intdev-ui';
import classes from './IdeaRatingPresentational.scss';

export const IdeaRatingPresentational = ({
    rating,
    plus,
    minus,
    ownVote,
    onPlusVote,
    onMinusVote,
    isPlusButtonDisabled,
    isMinusButtonDisabled,
    canSeeRating,
    isVoteActive,
}) => {
    const getBackgroundColor = (color, isActive) => `rgba(${color}, ${isActive ? '0.9' : '0.35'})`;
    const getPlusBackgroundColor = ownVote => getBackgroundColor('60, 199, 92', ownVote === 1);
    const getMinusBackgroundColor = ownVote => getBackgroundColor('216, 42, 42', ownVote === -1);

    return (
        <div
            className={ isVoteActive ? classes.container : classes.containerWithMessage }
            data-title="В данном статусе голосование  не проводится"
        >
            <div className={ classes.rating }>
                {
                    (canSeeRating)
                        ? (
                            <>
                                <div
                                    className={ classes.ratingCount }
                                >
                                    { rating }
                                </div>
                                <div
                                    className={ classes.ratingDetails }
                                >
                                    <div className={ classnames(classes.ratingDetailsItem, classes.ratingDetailsItemMinus) }>
                                        { minus }
                                    </div>
                                    <div className={ classnames(classes.ratingDetailsItem, classes.ratingDetailsItemPlus) }>
                                        { plus }
                                    </div>
                                </div>
                            </>
                        )
                        : <div className={ classes.ratingPreview }>—</div>
                }
            </div>
            <div className={ classes.buttons }>
                <Button
                    className={ classnames(classes.buttonsItem, classes.buttonsItemMinus) }
                    backgroundColor={ getMinusBackgroundColor(ownVote) }
                    onClick={ onMinusVote }
                    disabled={ isPlusButtonDisabled }
                    disabledBackgroundColor="rgb(208, 212, 217)"
                >
                    <i className={ classnames('b-ico', 'i-minus', classes.buttonsItemIcon) } />
                </Button>
                <Button
                    className={ classnames(classes.buttonsItem, classes.buttonsItemPlus) }
                    backgroundColor={ getPlusBackgroundColor(ownVote) }
                    onClick={ onPlusVote }
                    disabled={ isMinusButtonDisabled }
                    disabledBackgroundColor="rgb(208, 212, 217)"
                >
                    <i className={ classnames('b-ico', 'i-plus', classes.buttonsItemIcon) } />
                </Button>
            </div>
        </div>
    );
};

IdeaRatingPresentational.propTypes = {
    rating: PropTypes.number.isRequired,
    plus: PropTypes.number.isRequired,
    minus: PropTypes.number.isRequired,
    ownVote: PropTypes.oneOf([-1, 0, 1]).isRequired,
    onPlusVote: PropTypes.func.isRequired,
    onMinusVote: PropTypes.func.isRequired,
    isPlusButtonDisabled: PropTypes.bool.isRequired,
    isMinusButtonDisabled: PropTypes.bool.isRequired,
    canSeeRating: PropTypes.bool.isRequired,
    isVoteActive: PropTypes.bool.isRequired,
};
