import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Button } from 'intdev-ui';
import classnames from 'classnames';
import './CommentsForm.css';
import { isMobile, fromMobile } from '@/common/mediaQueries';
import { dictionary } from '@/common/localization/dictionary';
import { VoiceMessage } from '@/common/components/VoiceMessage/VoiceMessage';
import redactorInitializer from '@/redactor';
import { showNotification } from '@/common/helpers/showNotification';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { fileUploadUrl } from '@/common/constants/apiUrls';
import { ACCEPTED_FILE_FORMATS, ACCEPTED_MAX_FILE_SIZE } from '@/common/constants/fileUploadSettings';
import { media } from '../media';


const enterKeyCode = 13;

export default class CommentsForm extends Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        objectId: PropTypes.number.isRequired,
        parentForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        editForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        defaultText: PropTypes.string.isRequired,
        officialResponseForm: PropTypes.bool.isRequired,
        insertComment: PropTypes.func.isRequired,
        changeParent: PropTypes.func.isRequired,
        changeEdit: PropTypes.func.isRequired,
        formHeight: PropTypes.number.isRequired,
        changeOfficialResponseForm: PropTypes.func.isRequired,
        handleMakeOfficial: PropTypes.func.isRequired,
        handleCommentEdit: PropTypes.func.isRequired,
        isAllowAudioComments: PropTypes.bool,
    };

    static defaultProps = {
        parentForm: undefined,
        editForm: undefined,
        isAllowAudioComments: false,
    };

    state = {
        title: dictionary.addingNewComment,
        text: this.props.defaultText,
        isButtonDisabled: false,
        redactorInitialized: false,
        imageUploads: [],
        toolbarHeight: 48,
    };

    async componentDidMount() {
        const { formHeight } = this.props;
        const textarea = $(this.textarea);
        const { text } = this.state;
        const plugins = isMobile
            ? ['mention']
            : [
                'iconic', 'imagemanager', 'ishortcuts', 'mention', 'stickers', 'pasted', 'emojifix',
            ];
        const that = this;
        if (textarea.length) {
            await redactorInitializer(plugins);

            try { textarea.redactor('core.destroy'); } catch (err) { /* empty */ }
            textarea.redactor({
                fileUpload: fileUploadUrl,
                fileUploadParam: 'attachment_file',
                fileUploadAccept: [
                    ACCEPTED_FILE_FORMATS.map(({ extension }) => `.${extension}`),
                    ACCEPTED_FILE_FORMATS.map(({ mime }) => mime).flat(),
                ].flat(),
                fileUploadFilenameField: false,
                fileUploadInfo: (
                    /* eslint-disable-next-line prefer-template */
                    '<section><p>'
                        + '<span>Поддерживаемые форматы: ' + ACCEPTED_FILE_FORMATS.map(fmt => fmt.extension).join(', ') + '.</span>'
                        + '<br/>'
                        + '<span>Максимальный размер: не более ' + ACCEPTED_MAX_FILE_SIZE + 'Мб.</span>'
                    + '</p></section>'
                ),
                focus: !!this.props.parentForm,
                lang: 'ru',
                imageUpload: '/uploads/ajax-upload_new/',
                imageResizable: true,
                urlUploads: '/uploads/url-upload/',
                pasteImages: false,
                toolbarFixed: true,
                plugins,
                buttons: isMobile
                    ? []
                    : ['format', 'lists', 'link', 'bold', 'italic', 'deleted', 'image', 'file'],
                formatting: ['p', 'blockquote', 'pre', 'h1', 'h2'],
                maxHeight: formHeight,
                minHeight: formHeight,
                callbacks: {
                    init() {
                        that.setState({ redactorInitialized: true });
                    },
                    change() {
                        that.handleText(this.code.get());
                    },
                    onPastedUpload({ placeholderId, inProgress }) {
                        that.setState({
                            imageUploads: inProgress
                                ? [...that.state.imageUploads, placeholderId]
                                : that.state.imageUploads.filter(id => id !== placeholderId),
                        });
                    },
                    fileUploadTransformFormData(formData, file) {
                        if (file && file.name) {
                            formData.append('name', file.name);
                            formData.append('from_comment', true);
                        }
                        return formData;
                    },
                    fileUploadTransformXHR(xhr) {
                        function getCsrfToken() {
                            const match = document.cookie.match(/csrftoken=([^ ;]+)/);
                            if (!match) {
                                return null;
                            }
                            return match[1];
                        }
                        const csrftoken = getCsrfToken();
                        if (csrftoken !== undefined) {
                            xhr.setRequestHeader('X-CSRFToken', csrftoken);
                        }
                    },
                    showError(message) {
                        showNotification({ message, level: 'error' });
                    },
                },
            });
            textarea.redactor('code.set', text);
            const { 0: { parentNode } } = textarea;
            const { height } = parentNode.querySelector('ul.redactor-toolbar').getBoundingClientRect();
            this.setState({ toolbarHeight: height });
        }
    }

    handleKeydown = (event) => {
        if (
            (event.metaKey || event.ctrlKey) && event.keyCode === enterKeyCode
            && !this.state.isButtonDisabled
        ) {
            event.stopPropagation();
            event.preventDefault();
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        const {
            contentTypeId, objectId, parentForm, editForm, officialResponseForm,
        } = this.props;
        const { text } = this.state;
        const url = `/comments/${contentTypeId}/${objectId}/${editForm ? 'edit' : 'post'}/`;
        if (!text) {
            showNotification({ message: 'Введите текст комментария', level: 'error' });
            return;
        }
        this.setState({ isButtonDisabled: true });
        const body = {
            text,
            parent: parentForm,
            comment: editForm,
            from_mobile_promo: fromMobile,
        };
        callApi(url, 'post', body).then((data) => {
            this.props.insertComment(data.comment);
            $(this.textarea).redactor('code.set', '');
            if (officialResponseForm) {
                this.props.handleMakeOfficial(data.comment);
            }
            this.setState({ isButtonDisabled: false, text: '' });
            if (editForm) this.props.handleCommentEdit();
        }).catch((data) => {
            showNotification({ message: data.error, level: 'error' });
            this.setState({ isButtonDisabled: false, text: '' });
        });
    };

    handleCancel = () => {
        this.props.changeParent(false);
        this.props.changeEdit(false);
        this.props.changeOfficialResponseForm(false);
    };

    handleText = (text) => {
        const clearText = text.replace(/&nbsp;/gi, ' ');
        this.setState({ text: clearText });
    };

    sendVoiceMessage = text => this.setState({ text }, this.handleSubmit);

    render() {
        const {
            parentForm, editForm, officialResponseForm, isAllowAudioComments,
        } = this.props;
        const {
            title, text, isButtonDisabled, toolbarHeight,
        } = this.state;
        const disableSendButton = isButtonDisabled || !!this.state.imageUploads.length;

        return (
            <div style={ { position: 'relative' } }>
                <div className="comment-form" onKeyDown={ this.handleKeydown }>
                    <div className="comment-form-title">{ editForm ? 'Редактирование комментария' : title }</div>
                    <div
                        className="comment-form-main"
                        style={ { height: `${this.props.formHeight + (isMobile ? 0 : toolbarHeight) + 2 + 30}px` } }
                    >
                        <textarea
                            className={
                                classnames({
                                    'comments-text-editor': true,
                                    'comments-text-editor_hidden': !this.state.redactorInitialized,
                                })
                            }
                            defaultValue={ text }
                            ref={ (input) => { this.textarea = input; } }
                        />
                        {!this.state.redactorInitialized && <Spinner />}
                        { isAllowAudioComments && (
                            <div className="comment-form-main-send">
                                {text ? (
                                    <div
                                        className="comment-form-main-send-icon"
                                        onClick={ disableSendButton ? null : this.handleSubmit }
                                    >
                                        <i className="fa fa-arrow-right" />
                                    </div>
                                ) : <VoiceMessage sendVoiceMessage={ this.sendVoiceMessage } />}
                            </div>
                        ) }
                    </div>
                    <div className="comment-submit">
                        { isAllowAudioComments ? null : (
                            <Button
                                style={ { marginRight: 16 } }
                                primary
                                onClick={ this.handleSubmit }
                                label="Отправить"
                                disabled={ disableSendButton }
                            />
                        ) }
                        {(parentForm || editForm || officialResponseForm)
                            && (
                                <Button
                                    onClick={ this.handleCancel }
                                    label="Отменить"
                                    { ...media.commentsButtonStyles }
                                />
                            )}
                        <span className="comment-post-result" ref={ (input) => { this.result = input; } } />
                    </div>
                </div>
            </div>
        );
    }
}
