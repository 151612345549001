import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { UserSuggestions } from '../../../user_suggestions/userSuggestions';
import { userShape } from '../../constants/propTypes';
import { showErrorNotification, showSuccessNotification } from '../../helpers/showNotification';
import { callApi } from '../../middlewares/apiMiddleware';
import { FORMDATA } from '../../constants/ajaxBodyTypes';
import { PARSE_EXCEL_API_URL } from './urls';
import { styles } from './styles';
import { dropzoneHelp } from './constants';
import LabelWithHelp from '../LabelWithHelp';


export class UserSuggestionsWithDropzone extends React.Component {
    static propTypes = {
        users: PropTypes.arrayOf(userShape),
        onChange: PropTypes.func.isRequired,
        parseExcel: PropTypes.func,
        disabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        users: [],
        parseExcel: null,
    };

    state = {
        isLoading: false,
    };

    handleUsersChange = (values) => {
        this.props.onChange(values);
    };

    handleOnDrop = (accepted) => {
        const { users } = this.props;
        const usersCount = users.length;
        const file = accepted[0];
        if (!file) {
            showErrorNotification('Некорретный файл, попробуй использовать файл отчета');
        } else {
            this.setState({ isLoading: true });
            const parseExcel = this.props.parseExcel || this.parseExcel;
            parseExcel(file).then((response) => {
                this.setState({ isLoading: false });
                const userValues = users.map(user => user.value);
                const newUsers = users.concat(response.users.filter(user => !userValues.includes(user.value)));
                this.props.onChange(newUsers);
                const newUsersCount = newUsers.length - usersCount;
                if (response && !response.status) {
                    showSuccessNotification(`Добавлено ${newUsersCount} новых пользователей`);
                } else if (response.status === 400) {
                    showErrorNotification('Не удалось найти колонку \'Логин\' или \'Email\'');
                }
            });
        }
    };

    parseExcel = file => callApi(PARSE_EXCEL_API_URL, 'POST', { file }, FORMDATA);

    render() {
        const { users, disabled } = this.props;
        const { isLoading } = this.state;

        const usersLabel = users && users.length > 0
            ? `Выбрано сотрудников: ${users.length}`
            : 'Не выбрано ни одного сотрудника';

        return (
            <div style={ styles.container }>
                <Dropzone
                    accept=".xls,.xlsx,.csv"
                    onDrop={ this.handleOnDrop }
                    style={ styles.dropzone.default }
                    activeStyle={ styles.dropzone.active }
                    multiple={ false }
                    disabled={ disabled || isLoading }
                >
                    <LabelWithHelp
                        label="Перетащи файл отчета или нажми для выбора файла"
                        help={ dropzoneHelp }
                        style={ styles.label }
                        questionIconStyle={ styles.label }
                    />
                </Dropzone>
                <div style={ styles.label }>{ usersLabel }</div>
                <UserSuggestions
                    selectValue={ users }
                    onChangeValue={ this.handleUsersChange }
                    selectMultiple
                    fullWidth
                    disabled={ disabled || isLoading }
                />
            </div>
        );
    }
}
