import React from 'react';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Link = props => (<SvgIcon { ...props }>
    <path id="a" fill="currentColor" d="M9.546 6.01l.793.793a2.7 2.7 0 0 1 0 3.818l-2.599 2.6a2.702 2.702 0 0 1-3.819 0l-1.13-1.132a2.702 2.702 0 0 1 0-3.819l.434-.433a.826.826 0 1 1 1.17 1.168l-.345.345-.011.011-.036.037a1.2 1.2 0 0 0 0 1.697l.91.91a1.201 1.201 0 0 0 1.696 0l2.493-2.492a1.201 1.201 0 0 0 0-1.697l-.68-.68A.795.795 0 1 1 9.544 6.01zM6.46 9.997l-.788-.789a2.7 2.7 0 0 1 0-3.818L8.27 2.79a2.702 2.702 0 0 1 3.818 0l1.131 1.131a2.702 2.702 0 0 1 0 3.82l-.159.159-.296.27A.826.826 0 1 1 11.598 7l.364-.34c.005-.003.008-.006.011-.01l.037-.037a1.2 1.2 0 0 0 0-1.697l-.91-.91a1.201 1.201 0 0 0-1.697 0L6.909 6.496a1.201 1.201 0 0 0 0 1.697l.676.677A.795.795 0 0 1 6.46 9.995z" />
</SvgIcon>);
/* eslint-enable max-len */

Link.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Link;
