import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';


const styles = {
    label: {
        fontSize: '13px',
        fontWeight: 'bold',
        marginBottom: '4px',
    },
};

export class LabeledComponent extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
        rootClassName: PropTypes.string,
        rootStyle: IUIPropTypes.style,
        labelClassName: PropTypes.string,
        labelStyle: IUIPropTypes.style,
        labelInnerClassName: PropTypes.string,
        labelInnerStyle: IUIPropTypes.style,
        componentContainerClassName: PropTypes.string,
        componentContainerStyle: IUIPropTypes.style,
        onLabelClick: PropTypes.func,
    };

    static defaultProps = {
        rootClassName: null,
        rootStyle: null,
        labelClassName: null,
        labelStyle: null,
        labelInnerClassName: null,
        labelInnerStyle: null,
        componentContainerClassName: null,
        componentContainerStyle: null,
        onLabelClick: null,
    };

    render = () => {
        const {
            children, label,
            rootClassName, rootStyle,
            labelClassName, labelStyle,
            labelInnerClassName, labelInnerStyle,
            componentContainerClassName, componentContainerStyle,
        } = this.props;

        let labelResultStyle = {};
        if (!labelClassName) {
            labelResultStyle = labelStyle ? { ...labelStyle } : styles.label;
        }
        if (this.props.onLabelClick) {
            labelResultStyle = { ...labelResultStyle, cursor: 'pointer' };
        }

        return (
            <div className={ rootClassName } style={ rootStyle }>
                <div
                    className={ labelClassName }
                    style={ labelResultStyle }
                    onClick={ this.props.onLabelClick }
                >
                    <div className={ labelInnerClassName } style={ labelInnerStyle }>
                        { label }
                    </div>
                </div>
                <div className={ componentContainerClassName } style={ componentContainerStyle }>
                    { children }
                </div>
            </div>
        );
    }
}
