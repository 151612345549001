export const isMobile = window.matchMedia('(min-width: 320px) and (max-width: 960px)').matches;
export const fromMobile = /(iPhone|iPod|iPad|BlackBerry|Android)/i.test(navigator.userAgent);

const detectInterval = (num, range) => Object.keys(range).find(size => num >= range[size][0] && num <= range[size][1]);

const mediaWidth = {
    small: [320, 480],
    medium: [481, 640],
    large: [641, 960],
};

export const determineScreenSize = (params) => {
    const width = document.documentElement.clientWidth;
    const size = detectInterval(width, mediaWidth);
    // common - styles that are used only on mobile devices 320px-960px
    return (isMobile && size
        ? { ...params.common, ...params[size] }
        : params.desktop || {}
    );
};

export const isDeviceInInterval = (interval) => {
    if (!Array.isArray(interval) && interval.length !== 2) return false;
    const width = document.documentElement.clientWidth;
    return (width >= interval[0] && width <= interval[1]);
};
