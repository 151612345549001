import update from 'immutability-helper';
import ACTION_TYPES from '../constants/actionTypes';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';
import { DEFAULT_NOTIFICATIONS_PAGE_SIZE, NOTIFICATION_UPSTREAM_TYPES } from '../constants';
import { setLocalStorageNotifications, getLocalStorageName } from '../helpers';


const defaultState = {
    notifications: [],
    newNotificationsCount: 0,
    unreadNotificationsCount: 0,
    pageSize: DEFAULT_NOTIFICATIONS_PAGE_SIZE,
    notificationsList: [],
    page: 1,
    isLoading: true,
};

export const notifications = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.NOTIFICATIONS_LOADING_FINISH:
        case ACTION_TYPES.NOTIFICATIONS_UPDATE_FINISH: {
            const data = objCamelFromSnake(action.payload);
            const updatedState = {
                notifications: data.notifications,
                newNotificationsCount: data.newNotificationsCount,
                unreadNotificationsCount: data.unreadNotificationsCount,
                pageSize: data.pageSize || DEFAULT_NOTIFICATIONS_PAGE_SIZE,
            };
            setLocalStorageNotifications(updatedState);
            return update(state, {
                $merge: { ...updatedState },
            });
        }

        case ACTION_TYPES.CENTRIFUGE_MESSAGE_RECEIVED: {
            const data = objCamelFromSnake(action.message.data);
            const upstreamType = data.upstream;

            if (upstreamType === NOTIFICATION_UPSTREAM_TYPES.ADD) {
                const updatedNotifications = state.notifications;
                const notification = {
                    id: data.id,
                    broadcastId: data.broadcastId,
                    status: data.status,
                    createdAt: data.date,
                    notificationContent: { ...data },
                };
                updatedNotifications.unshift(notification);
                return update(state, {
                    notifications: { $set: updatedNotifications },
                    $merge: {
                        newNotificationsCount: state.newNotificationsCount + 1,
                        unreadNotificationsCount: state.unreadNotificationsCount + 1,
                    },
                });
            }

            if (upstreamType === NOTIFICATION_UPSTREAM_TYPES.REPLACE) {
                const updatedNotifications = state.notifications.map((notification) => {
                    const updatedNotification = Object.assign({}, notification);
                    if (updatedNotification.broadcastId === data.broadcastId) {
                        updatedNotification.notificationContent = {
                            ...updatedNotification.notificationContent,
                            ...data,
                        };
                    }
                    return updatedNotification;
                });
                return update(state, {
                    notifications: { $set: updatedNotifications },
                });
            }

            return state;
        }

        case ACTION_TYPES.SYNCHRONIZE_TABS: {
            const localStorageName = getLocalStorageName();
            if (action.event.key === localStorageName) {
                const updatedState = JSON.parse(event.newValue);
                return update(state, { $merge: { ...updatedState } });
            }
            return state;
        }

        case ACTION_TYPES.NOTIFICATION_LIST_LOADING_START: {
            return update(state, {
                isLoading: { $set: true },
            });
        }

        case ACTION_TYPES.NOTIFICATION_LIST_LOADING_FINISH: {
            const data = objCamelFromSnake(action.payload);
            return update(state, {
                notificationsList: { $push: data },
                page: { $set: data.length ? state.page + 1 : null },
                isLoading: { $set: false },
            });
        }

        default:
            return state;
    }
};
