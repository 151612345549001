import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as constants from '../../../builder/constants.es6';
import ConsecutiveQuestionGroup from './ConsecutiveQuestionGroup';
import OnePageQuestionGroup from './OnePageQuestionGroup';
import TableChoiceQuestionGroup from './TableChoiceQuestionGroup';


class QuestionGroup extends React.Component {
    static propTypes = {
        group: PropTypes.shape(),
        isTest: PropTypes.bool.isRequired,
        compactMode: PropTypes.bool,
    };

    static defaultProps = {
        group: null,
        compactMode: false,
    };

    questionGroup = () => {
        const {
            isTest,
            compactMode,
        } = this.props;
        switch (parseInt(this.props.group.type, 10)) {
            case constants.QUESTION_GROUP_TYPE_CONSECUTIVE:
                return <ConsecutiveQuestionGroup isTest={ isTest } compactMode={ compactMode } />;
            case constants.QUESTION_GROUP_TYPE_ON_ONE_PAGE:
                return <OnePageQuestionGroup compactMode={ compactMode } />;
            case constants.QUESTION_GROUP_TYPE_TABLE_CHOICE:
                return <TableChoiceQuestionGroup />;
            case constants.QUESTION_GROUP_TYPE_TABLE_MULTICHOICE:
                return <TableChoiceQuestionGroup multiple />;
            default:
                return null;
        }
    };

    groupText = () => {
        if (!this.props.group.text) {
            return null;
        }
        return (
            <div
                className={ `quiz-detail__text_size_${(this.props.compactMode) ? 's' : 'm'}` }
                dangerouslySetInnerHTML={ { __html: this.props.group.text } }
            />
        );
    };

    render() {
        if (!this.props.group) {
            return null;
        }
        return (
            <div>
                { this.groupText() }
                { this.questionGroup() }
            </div>
        );
    }
}


const mapStateToProps = ({ quizApp: state }) => {
    const currentGroup = state.groups[state.controls.currentGroupId];
    return {
        group: currentGroup,
    };
};

export default connect(
    mapStateToProps,
)(QuestionGroup);
