import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IUIPropTypes } from 'intdev-ui';

import { EVENT_GROUP_SHAPE } from '../shapes';
import Event from './Event';
import TextEllipsisTooltip from '../../common/components/TextEllipsisTooltip';

const styles = {
    root: {
        height: '100%',
    },
    title: {
        height: '21px',
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '20px',
    },
    titleGroupedByName: {
        height: '21px',
        fontSize: '15px',
        lineHeight: '20px',
    },
    eventLabel: {
        padding: '16px 0 8px 0',
        fontSize: '17px',
        lineHeight: '24px',
        marginTop: '-1px',
        minHeight: '24px',
    },
    event: {
        marginTop: '-1px',
    },
};

class EventColumnComponent extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        /*
        * events to display in column
        * should be sorted by name, for proper event label display
        * */
        showEventLabel: PropTypes.bool,
        eventsGroup: PropTypes.arrayOf(EVENT_GROUP_SHAPE).isRequired,
        style: IUIPropTypes.style,
        // from state
        isGroupEventsByName: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        title: null,
        style: {},
        showEventLabel: true,
    };

    getEvents = () => this.props.eventsGroup.map((eventGroup, index) => {
        const result = [];
        if (this.props.showEventLabel && (index === 0 || eventGroup.title)) {
            const isEmpty = index === 0 && !eventGroup.title;
            result.push(<TextEllipsisTooltip
                key={ `${eventGroup.events[0].id}-label` }
                text={ isEmpty ? '' : eventGroup.title }
                style={ styles.eventLabel }
            />);
        }
        return result.concat(eventGroup.events.map((event, eventIndex) => (
            <Event
                style={ styles.event }
                key={ event.id }
                tooltipBottom={ eventGroup.events.length / 2 > eventIndex && eventGroup.events.length > 4 }
                event={ event }
            />
        )));
    }).reduceRight(
        (previousValue, currentValue) => currentValue.concat(previousValue),
    );

    render() {
        return (
            <div style={ { ...styles.root, ...this.props.style } }>
                <div
                    style={ this.props.isGroupEventsByName ? styles.titleGroupedByName : styles.title }
                >{ this.props.title }</div>
                <div>
                    { this.getEvents() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isGroupEventsByName: state.calendarContainerProps.overflowLeftArrow,
});

const EventColumn = connect(mapStateToProps, null)(EventColumnComponent);

export default EventColumn;
