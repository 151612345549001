import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Help = props => (<SvgIcon { ...props }>
    <path fill="currentColor" d="M8 0c4.415 0 8 3.585 8 8s-3.585 8-8 8-8-3.585-8-8 3.585-8 8-8zm0 2c3.312 0 6 2.688 6 6s-2.688 6-6 6-6-2.688-6-6 2.688-6 6-6zm-1.175 9.306a1.022 1.022 0 1 0 2.044-.002 1.022 1.022 0 0 0-2.044.002zm1.016-7.782c-2.116.099-2.735 1.735-2.735 2.442 0 .314 0 .916.695.993.655.073.904-.368 1.034-.746.091-.263.384-.988 1.261-.988.628 0 1 .374 1 .924 0 1.1-2.138 1.102-2.138 2.726 0 .55.496.686.799.686.211 0 .556-.007.748-.391.409-.82 2.389-1.145 2.389-3.17 0-1.303-1.168-2.564-3.053-2.476z"/>
</SvgIcon>);
/* eslint-enable max-len */

Help.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Help;
