import React from 'react';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Write = props => (<SvgIcon { ...props }>
    <path d="M.975 9.649l6.591-6.591 3.376 3.376-4.903 4.903-1.688 1.688c-.001.001-.378.35-1.108.487l-2.522.477a.685.685 0 0 1-.56-.15.685.685 0 0 1-.15-.56l.477-2.522c.137-.729.485-1.105.487-1.108zm1.01.916a.387.387 0 0 1 .56-.014l.904.904a.386.386 0 0 1 0 .546l-.059.059s-.162.15-.476.209l-1.083.205a.295.295 0 0 1-.241-.064.295.295 0 0 1-.064-.241l.205-1.083c.059-.314.209-.476.209-.476l.045-.045zM11.746.293a.999.999 0 0 0-1.414 0L9 1.624 12.376 5l1.331-1.332a.999.999 0 0 0 0-1.414L11.746.293z" />
</SvgIcon>);
/* eslint-enable max-len */

Write.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Write;
