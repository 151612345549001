import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@/common/components/Helmet';
import { IUIPropTypes } from 'intdev-ui';
import { pageStyle } from './pageStyle';
import { mainPageRef } from './mainPageRef';

export const PageServerError = (props) => {
    const containerStyle = {
        ...pageStyle.container,
        ...props.containerStyle,
    };
    const textStyle = {
        ...pageStyle.text,
        ...props.textStyle,
    };

    return (
        <div style={ containerStyle }>
            <Helmet>
                <title>Что-то пошло не так</title>
            </Helmet>
            <div>
                <img alt={ props.status } src="/static/500image.png" />
            </div>
            <div>
                <p style={ textStyle }>
                    { props.text }{ props.status !== 500 && <span> ({ props.status })</span> }.
                </p>
                <p style={ textStyle }>Мы уже знаем об этом и в ближайшее время все исправим.</p>
                <p style={ textStyle }>Спасибо за терпение.</p>
            </div>
            <div style={ pageStyle.serverErrorBackRef }>
                { props.referenceText }
            </div>
        </div>
    );
};

PageServerError.propTypes = {
    status: PropTypes.number,
    text: PropTypes.string,
    referenceText: PropTypes.node,
    containerStyle: IUIPropTypes.style,
    textStyle: IUIPropTypes.style,
};

PageServerError.defaultProps = {
    status: 500,
    text: 'Похоже, что-то пошло не так',
    containerStyle: {},
    textStyle: {},
    referenceText: mainPageRef,
};
