import { CALL_API } from '@/common/constants/actionTypes';
import { apiUrls } from '@/base_page/constants/apiUrls';
import { ACTION_TYPES } from '../constants/actionConstants';

export const notificationSystem = obj => ({
    type: ACTION_TYPES.RECEIVE_NOTIFICATIONS,
    ...obj,
});

export const authErrorMessageShow = visible => ({
    type: ACTION_TYPES.AUTH_ERROR_VISIBLE,
    visible: visible !== false,
});

export const showHeader = () => ({
    type: ACTION_TYPES.SHOW_HEADER,
});

export const hideHeader = () => ({
    type: ACTION_TYPES.HIDE_HEADER,
});

export const getActualTheme = () => ({
    type: CALL_API,
    method: 'GET',
    endpoint: apiUrls.getActualTheme,
    transformCase: true,
    types: [
        ACTION_TYPES.GET_ACTUAL_THEME_START,
        ACTION_TYPES.GET_ACTUAL_THEME_FINISH,
        ACTION_TYPES.GET_ACTUAL_THEME_FAIL,
    ],
});

export const getMenu = () => ({
    type: CALL_API,
    method: 'GET',
    endpoint: apiUrls.getMenu,
    types: [
        ACTION_TYPES.GET_HEADER_MENU_START,
        ACTION_TYPES.GET_HEADER_MENU_FINISH,
        ACTION_TYPES.GET_HEADER_MENU_FAIL,
    ],
});
