import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { objCamelFromSnake } from '@/common/helpers/objCamelFromSnake';
import { albumSchema } from '../utils/schemas';
import { ACTION_TYPES } from '../constants/actionTypes';

const defaultState = {
    albums: {},
    albumsId: [],
    AlbumPhotoCType: null,
    isLoaded: false,
    defaultRowLen: 5,
};


export const albums = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOAD_ALBUMS_SUCCESS: {
            const camelData = objCamelFromSnake(action.payload);
            const newData = normalize(camelData.albums, [albumSchema]);
            const albumsDict = newData.entities.albums;
            return update(state, {
                albums: { $set: albumsDict },
                albumsId: { $set: newData.result },
                AlbumPhotoCType: { $set: camelData.albumPhotoCtype },
                isLoaded: { $set: true },
                defaultRowLen: { $set: camelData.defaultRowLen },
            });
        }
        default:
            return state;
    }
};
