export const ACCEPTED_FILE_FORMATS = [
    { extension: 'pdf', mime: 'application/pdf' },
    { extension: 'doc', mime: 'application/msword' },
    { extension: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extension: 'xls', mime: 'application/vnd.ms-excel' },
    { extension: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: 'ppt', mime: 'application/vnd.ms-powerpoint' },
    { extension: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
    { extension: 'mp4', mime: 'video/mp4' },
    { extension: 'm4v', mime: 'video/x-m4v' },
    { extension: 'mov', mime: 'video/quicktime' },
    { extension: 'avi', mime: ['video/x-msvideo', 'video/avi', 'video/vnd.avi', 'video/ms-video'] },
    { extension: 'mkv', mime: 'video/x-matroska' },
];

export const ACCEPTED_MAX_FILE_SIZE = 300; // Мбайт
