import { CALENDAR_CONFIG_LOAD, CENTRIFUGE_JOIN, CENTRIFUGE_LEAVE } from '../constants/actions';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';

const calendarConfig = (state = {
    objectId: undefined,
    isAnyMeeting: undefined,
    contentType: undefined,
    user: undefined,
    canTruncate: undefined,
    timeInterval: undefined,
    duration: undefined,
    centrifugeChannels: undefined,
    canChangeOwner: undefined,
    contentObjectAutor: undefined,
    canEdit: undefined,
    canCreate: undefined,
    singleJoin: undefined,
    participantsSet: new Set(), // used for single_join violation calculation
}, action) => {
    switch (action.type) {
        case (CALENDAR_CONFIG_LOAD): {
            const {
                participants,
                ...other
            } = action.payload;

            const participantsSet = new Set(participants);
            return {
                ...state,
                participantsSet,
                ...other,
            };
        }
        case (CENTRIFUGE_JOIN): {
            if (!state.singleJoin) {
                return state;
            }
            const data = objCamelFromSnake(action.payload.messageData);
            // check data.userId is subscribed user
            if (state.participantsSet.has(data.userId)) {
                return state;
            }
            const newParticipantsSet = new Set(state.participantsSet);
            newParticipantsSet.add(data.userId);
            return {
                ...state,
                participantsSet: newParticipantsSet,
            };
        }
        case (CENTRIFUGE_LEAVE): {
            if (!state.singleJoin) {
                return state;
            }

            const data = objCamelFromSnake(action.payload.messageData);
            // check data.userId is subscribed user
            if (!state.participantsSet.has(data.userId)) {
                return state;
            }
            const newParticipantsSet = new Set(state.participantsSet);
            newParticipantsSet.remove(data.userId);
            return {
                ...state,
                participantsSet: newParticipantsSet,
            };
        }
        default:
            return state;
    }
};

export default calendarConfig;
