import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';
import * as Sentry from '@sentry/browser';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { PageServerError } from '@/common/components/PageStatus';


export class ErrorBoundary extends Component {
    static propTypes = {
        children: IUIPropTypes.validChildren,
        renderNull: PropTypes.bool,
        errorNotification: PropTypes.bool,
        onErrorNotificationText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
    };

    static defaultProps = {
        errorNotification: false,
        onErrorNotificationText: 'Похоже, что-то пошло не так',
        children: null,
        renderNull: false,
    };

    state = {
        error: false,
    };

    componentDidCatch = (err, info) => {
        const {
            errorNotification,
            onErrorNotificationText,
        } = this.props;
        this.setState({ error: true });
        if (errorNotification) {
            if (typeof onErrorNotificationText === 'function') {
                showErrorNotification(onErrorNotificationText(err, info));
            } else {
                showErrorNotification(onErrorNotificationText);
            }
        }
        Sentry.withScope((scope) => {
            scope.setExtra('info', info);
            Sentry.captureException(err);
        });
    };

    render = () => {
        if (this.state.error) {
            return this.props.renderNull ? null : <PageServerError />;
        }
        return this.props.children;
    }
}
