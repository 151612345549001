import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';
import { normalize } from 'normalizr';
import { statListSchema, userListSchema, answerListSchema } from '@/quiz/detail/utils';

import { RESULT_PAGE_SIZE } from '../constants';
import ACTION_TYPES from '../actionTypes';


const INITIAL_STATE = {
    statistics: {},
    resultsByUser: {},
    resultsCount: 0,
    pagination: {
        currentPageNumber: 1,
        maxPageNumber: 1,
        pages: {
            1: {
                ids: [],
                fetched: false,
            },
        },
    },
};


export const quizResults = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_STATISTIC_FINISH: {
            const normalizedData = normalize(action.payload, statListSchema);
            return update(state, {
                statistics: { $set: normalizedData.entities.statistics },
            });
        }
        case ACTION_TYPES.GET_FIRST_QUIZ_RESULT_START:
        case ACTION_TYPES.QUIZ_LOAD_START: {
            return update(state, { $set: INITIAL_STATE });
        }
        case ACTION_TYPES.GET_QUIZ_RESULT_START: {
            const pageNum = action.additionalData.pageNum;
            return update(state, {
                pagination: {
                    pages: {
                        [pageNum]: {
                            $set: {
                                ids: [],
                                fetched: false,
                            },
                        },
                    },
                },
            });
        }
        case ACTION_TYPES.GET_QUIZ_RESULT_FINISH: {
            const {
                results,
                count,
            } = action.payload;
            const pageNum = action.additionalData.pageNum;
            if (!count) {
                return update(state, {
                    pagination: {
                        pages: {
                            [pageNum]: {
                                fetched: { $set: true },
                            },
                        },
                    },
                });
            }
            const normalizedData = normalize(results, userListSchema);
            Object.keys(normalizedData.entities.users).forEach((userId) => {
                const normalizedAnswersData = normalize(
                    normalizedData.entities.users[userId].answers, answerListSchema,
                );
                normalizedData.entities.users[userId].answers = normalizedAnswersData.entities.answers;
            });
            return update(state, {
                resultsByUser: { $merge: normalizedData.entities.users },
                resultsCount: { $set: count },
                pagination: {
                    maxPageNumber: { $set: Math.ceil(count / RESULT_PAGE_SIZE) },
                    currentPageNumber: { $set: pageNum },
                    pages: {
                        [pageNum]: {
                            ids: { $push: normalizedData.result },
                            fetched: { $set: true },
                        },
                    },
                },
            });
        }
        case LOCATION_CHANGE:
            return update(state, { $set: INITIAL_STATE });
        default:
            return state;
    }
};
