import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Add = props => (<SvgIcon { ...props }>
    <path fill="currentColor" d="M7.4 0a.6.6 0 0 1 .6.6V6h5.4a.6.6 0 0 1 .6.6v.8a.6.6 0 0 1-.6.6H8v5.4a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V8H.6a.6.6 0 0 1-.6-.6v-.8A.6.6 0 0 1 .6 6H6V.6a.6.6 0 0 1 .6-.6h.8z" />
</SvgIcon>);
/* eslint-enable max-len */

Add.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Add;
