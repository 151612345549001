import React from 'react';
import PropTypes from 'prop-types';

import { Record as BasicRecord } from './Record';


const styles = {
    src: {
        width: '100%',
        minHeight: '315px',
        maxWidth: 'calc(100% - 10px)',
        padding: '0 5px',
    },
    youTube: {
        position: 'relative',
    },
};


export class VideoRecord extends React.Component {
    static propTypes = {
        action_text: PropTypes.string.isRequired,
        video_url: PropTypes.string.isRequired,
        preview_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        youtube_player: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    };

    state = {
        imported: false,
    }

    async componentDidMount() {
        // TODO: вообще выпилить videojs + когда шамиль переделает таймлайн,
        // сделать подгрузку record компонентов асинхронной
        await import('../../videojs').then(() => {
            this.setState({ imported: true });
        });
    }

    get srcVideo() {
        return (
            <div className="js-video-player" data-url={ this.props.url }>
                <video
                    className="video-js vjs-big-play-centered"
                    style={ styles.src }
                    poster={ this.props.preview_url }
                    preload="none"
                    controls
                >
                    <source src={ this.props.video_url } type="video/mp4" />
                </video>
            </div>
        );
    }

    get youTubeVideo() {
        return (
            <div style={ styles.youTube } dangerouslySetInnerHTML={ { __html: this.props.youtube_player } } />
        );
    }

    render() {
        const { video_url } = this.props;
        const { imported } = this.state;
        const recordBody = video_url ? this.srcVideo : this.youTubeVideo;

        if (!imported) return null;
        return (
            <BasicRecord
                recordBody={ recordBody }
                collapsed={ false }
                { ...this.props }
            />
        );
    }
}
