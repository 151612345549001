import React from 'react';
import PropTypes from 'prop-types';
import { CentriSubscribe } from '@/common/components/CentriSubscribe';
import { objCamelFromSnake } from '@/common/helpers/objCamelFromSnake';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { apiUrls } from '@/ideas_pek/constants/urls';
import { IdeaRatingPresentational } from './IdeaRatingPresentational';

export const IdeaRatingStateful = ({
    id,
    rating: initialRating,
    plus: initialPlus,
    ownVote: initialOwnVote,
    uniqueUserId,
    canSeeRatingBeforeVote,
    isFranchiseeIdea,
    onVoteSuccess,
    isVoteActive,
    show,
}) => {
    const [state, setState] = React.useState({
        rating: initialRating,
        plus: initialPlus,
        ownVote: initialOwnVote,
        ownVoteUid: null,
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const handleResponseOrMessage = React.useCallback((data) => {
        setState((prevState) => {
            const isOwnVote = data.uniqueUserId === uniqueUserId;
            if (isOwnVote && prevState.ownVoteUid === data.uid) {
                return prevState;
            }
            return {
                rating: data.sumRating.all,
                plus: data.sumRating.plus,
                ownVote: isOwnVote ? data.update : prevState.ownVote,
                ownVoteUid: isOwnVote ? data.uid : prevState.ownVoteUid,
            };
        });
    }, [uniqueUserId]);

    if (!show) {
        return null;
    }

    const handleCentrifugeMessage = (message) => {
        const data = objCamelFromSnake(message);
        handleResponseOrMessage(data);
    };

    const handleSubmitVote = (vote) => {
        if (state.ownVote === vote) {
            return;
        }
        setIsLoading(true);
        const url = apiUrls.voteForIdea(id, isFranchiseeIdea);
        callApi(url, 'POST', { rate: (vote === 1 ? '+' : '-') })
            .then((responseData) => {
                const data = objCamelFromSnake(responseData);
                handleResponseOrMessage(data);
                onVoteSuccess(data);
                setIsLoading(false);
            }).catch(() => {
                showErrorNotification('Не удалось проголосовать.');
                setIsLoading(false);
            });
    };

    return (
        <>
            <IdeaRatingPresentational
                rating={ state.rating }
                plus={ state.plus }
                minus={ state.rating - state.plus }
                ownVote={ state.ownVote }
                onMinusVote={ () => handleSubmitVote(-1) }
                onPlusVote={ () => handleSubmitVote(1) }
                isPlusButtonDisabled={ isLoading || !isVoteActive }
                isMinusButtonDisabled={ isLoading || !isVoteActive }
                canSeeRating={ canSeeRatingBeforeVote || state.ownVote !== 0 || !isVoteActive }
                isVoteActive={ isVoteActive }
            />
            <CentriSubscribe
                onMessage={ handleCentrifugeMessage }
                channel={ `intranet_idea_${id}` }
            />
        </>

    );
};

IdeaRatingStateful.propTypes = {
    id: PropTypes.number.isRequired,
    rating: PropTypes.number,
    plus: PropTypes.number,
    ownVote: PropTypes.oneOf([-1, 0, 1]),
    uniqueUserId: PropTypes.string.isRequired,
    canSeeRatingBeforeVote: PropTypes.bool,
    isFranchiseeIdea: PropTypes.bool,
    onVoteSuccess: PropTypes.func,
    isVoteActive: PropTypes.bool,
    show: PropTypes.bool,
};

IdeaRatingStateful.defaultProps = {
    rating: 0,
    plus: 0,
    ownVote: 0,
    canSeeRatingBeforeVote: false,
    isFranchiseeIdea: false,
    onVoteSuccess: () => {},
    isVoteActive: false,
    show: false,
};
