import { COMPETENCE_MIN_LENGTH } from '@/thanks/constants';
import { showErrorNotification } from '@/common/helpers/showNotification';


export const validateCompetenceDescription = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const msgText = tempDiv.textContent;
    const msgTextChars = msgText.replace(/\s/g, '');
    const msgLength = msgTextChars.length;
    if (msgLength < COMPETENCE_MIN_LENGTH) {
        const notification = `При выдаче бейджа компетенции необходимо заполнить поле «Описание» (минимум ${COMPETENCE_MIN_LENGTH} символов). Сейчас длина сообщения ${msgLength} символов.`;
        showErrorNotification(notification);
        return false;
    }
    return true;
};
