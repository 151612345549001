export default function pluralized(value, template) {
    /*
    * template examples:
    * 'каждые:каждую:каждую'
    * 'сотрудников:сотрудник:сотрудника'
    * 'часов:час:часа'
    * pluralized(0, 'часов:час:часа') -> 'часов'
    * */
    const [t0, t1, t2] = template.split(':');
    // t0 - 0 часов
    // t1 - 1 час
    // t2 - 3 часа
    const lastDigit = value % 10;
    if (value % 100 > 10 && value % 100 < 20) {
        return t0;
    } if (lastDigit === 1) {
        return t1;
    } if ([2, 3, 4].includes(lastDigit)) {
        return t2;
    }
    return t0;
}
