import React from 'react';
import { RadioSelect, TextField } from 'intdev-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OTHER_ANSWER_VALUE } from '../../constants';

const styles = {
    choice: {
        margin: '15px 0',
    },
};


class ChoiceQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.shape().isRequired,
        choicesList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            picture: PropTypes.string,
        })).isRequired,
        value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        rightChoicesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        isRightAnswerDisplayed: PropTypes.bool,
    };

    static defaultProps = {
        isRightAnswerDisplayed: false,
        value: null,
    };

    optionRenderer = (choice) => {
        let choiceClassName = 'quiz-detail__choice-label';
        if (choice.value === OTHER_ANSWER_VALUE) {
            return (
                <div className={ choiceClassName }>
                    <label>
                        Другое:
                        <TextField
                            value={ this.props.text }
                            onChange={ this.handleOtherAnswerChange }
                            name={ `otherChoice${this.props.question.id}` }
                            className="quiz-detail__textfield"
                        />
                    </label>
                </div>
            );
        }
        if (this.props.isRightAnswerDisplayed && this.props.rightChoicesIds.indexOf(choice.value) !== -1) {
            choiceClassName += ' quiz-detail__right-choice';
        }
        return (
            <div className={ choiceClassName }>
                {choice.label ? <div dangerouslySetInnerHTML={ { __html: choice.label } } /> : null}
                {choice.picture ? <img src={ choice.picture } style={ { maxWidth: '420px' } } /> : null}
            </div>
        );
    };

    handleDisabledChange = (event) => {
        event.preventDefault();
    };

    handleEnabledChange = (event, input) => {
        const answer = {};
        if (input.value === OTHER_ANSWER_VALUE) {
            answer.value = null;
        } else {
            answer.text = null;
            answer.value = input.value;
        }
        this.props.onChange(event, answer);
    };

    handleOtherAnswerChange = (event, text) => {
        const answer = { text };
        if (this.props.value) {
            answer.value = null;
        }
        this.props.onChange(event, answer);
    };

    options = () => {
        const options = this.props.choicesList.map(item => ({
            value: item.id,
            label: item.text,
            picture: item.picture,
        }));
        if (this.props.question.allow_other) {
            options.push({
                value: OTHER_ANSWER_VALUE,
                label: '',
                picture: null,
            });
        }
        return options;
    };

    currentValue = () => {
        if (this.props.question.allow_other && this.props.text) {
            return OTHER_ANSWER_VALUE;
        }
        return this.props.value;
    };

    render() {
        const handleChange = (this.props.isRightAnswerDisplayed) ? this.handleDisabledChange : this.handleEnabledChange;
        return (
            <div className="quiz-detail__choice-question quiz-detail__text_size_s">
                <RadioSelect
                    options={ this.options() }
                    onChange={ handleChange }
                    value={ this.currentValue() }
                    style={ styles.choice }
                    name={ `question${this.props.question.id}` }
                    optionRenderer={ this.optionRenderer }
                />
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id]
        || state.answers.dbAnswers[ownProps.question.id] || {};
    return {
        choicesList: ownProps.question.choice_set.map(choiceId => state.choices[choiceId]),
        rightChoicesIds: state.rightChoices[ownProps.question.id] || [],
        text: answer.text || '',
        value: answer.value || null,
    };
};

export default connect(mapStateToProps)(ChoiceQuestion);
