import React from 'react';
import { A } from 'intdev-ui';
import { connect } from 'react-redux';

import { EVENT_SHAPE } from '../shapes';
import { apiUrls } from '../constants/apiUrls';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { showNotification } from '../../common/helpers/showNotification';

const styles = {
    root: {
        margin: '4px 0 4px 0',
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        whiteSpace: 'nowrap',
        margin: '2px',
    },
    plus: {
        color: '#787878',
    },
};

class CalendarFooterComponent extends React.Component {
    static propTypes = {
        event: EVENT_SHAPE.isRequired,
    };

    constructor(props) {
        super(props);

        this.scrolledToBody = false;
        this.bodyRef = null;

        this.state = {
            participants: [],
        };

        if (props.event.id) {
            this.loadData(this.props);
        }
    }

    componentDidMount = () => {
        this.scrollToBody();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.event.id && this.props.event.id !== nextProps.event.id) {
            this.loadData(nextProps);
        }
    }

    componentDidUpdate = () => {
        this.scrollToBody();
    };

    scrollToBody = () => {
        if (this.bodyRef && !this.scrolledToBody) {
            const rect = this.bodyRef.getBoundingClientRect();
            const scrollBy = (rect.bottom - window.innerHeight) + 40;
            // scroll if footer below viewport
            if (scrollBy > 0) {
                window.scrollBy({
                    top: scrollBy,
                    left: 0,
                    behavior: 'smooth',
                });
            }
            this.scrolledToBody = true;
        }
    };

    loadData = (props) => {
        const url = apiUrls().meetingParticipants(props.event.meetingId);
        callApi(url).then((data) => {
            const urls = new Set();

            const uniqueUserParticipants = data.filter((participant) => {
                if (urls.has(participant.url)) {
                    return false;
                }
                urls.add(participant.url);
                return true;
            });

            this.scrolledToBody = false;
            this.setState({ participants: uniqueUserParticipants });
        }).catch(() => {
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
        });
    };

    render() {
        this.bodyRef = null;
        if (!this.state.participants.length || !this.props.event.id) {
            return null;
        }
        return (
            <div
                style={ styles.root }
                ref={ (ref) => {
                    this.bodyRef = ref;
                } }
            >
                <div>
                    {`Участники события "${this.props.event.name}" (`}
                    <A href={ apiUrls().meetingUserSearch(this.props.event.meetingId) }>
                        поиск
                    </A>
                    {'):'}
                </div>
                <div style={ styles.itemContainer }>
                    {this.state.participants.map((participant, index, arr) => (
                        <span
                            style={ styles.item }
                            key={ participant.url }
                        >
                            <A
                                target="_blank"
                                href={ participant.url }
                            >
                                { participant.name }
                            </A>
                            { (participant.people > 1) &&
                                <span style={ styles.plus }>
                                    +{participant.people - 1}
                                </span>
                            }
                            { index !== (arr.length - 1) &&
                                <span>,</span>
                            }
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.calendarConfig,
    event: state.footerEvent,
});

const CalendarFooter = connect(mapStateToProps)(CalendarFooterComponent);

export default CalendarFooter;
