import update from 'immutability-helper';
import {
    LOAD_SELF_PROFILE_SUCCESS,
} from '../constants/actionConstants';


export const selfProfile = (state = {}, action) => {
    switch (action.type) {
        case LOAD_SELF_PROFILE_SUCCESS:
            return update(state, {
                $merge: action.payload,
            });

        default:
            return state;
    }
};
