import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UserInfo, ListItem } from 'intdev-ui';
import { styles } from './styles';

export class Option extends Component {
    static propTypes = {
        option: PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            isOptGroup: PropTypes.bool,
            value: PropTypes.any,
        }).isRequired,
        withAppointment: PropTypes.bool,
        withEmail: PropTypes.bool,
        withPhone: PropTypes.bool,
        withOnlineStatus: PropTypes.bool,
    }

    static defaultProps = {
        withAppointment: true,
        withEmail: true,
        withPhone: false,
        withOnlineStatus: false,
    }

    render() {
        const { option } = this.props;
        let renderedOption;
        if (option.isOptGroup) {
            renderedOption = (
                <div>{option.label}</div>
            );
        }
        if (option.label.type === 'user') {
            const additionalInfo = [];
            if (this.props.withAppointment) {
                additionalInfo.push(
                    <span
                        title={ option.label.appointment }
                        style={ styles.additionalStyle }
                        key="appointment"
                    >
                        { option.label.appointment }
                    </span>,
                );
            }
            if (this.props.withEmail) {
                additionalInfo.push(
                    <span
                        style={ styles.additionalStyle }
                        key="email"
                    >
                        {option.label.email}
                    </span>,
                );
            }
            if (this.props.withPhone) {
                additionalInfo.push(<span style={ styles.additionalStyle } key="phone">{option.label.phone}</span>);
            }
            if (this.props.withOnlineStatus) {
                const style = {
                    ...styles.additionalStyle,
                    ...(option.label.is_online ? styles.onlineStyle : {}),
                };
                additionalInfo.push((
                    <span style={ style } key="online_status">
                        {option.label.is_online ? 'В сети' : 'Нет в сети'}
                    </span>
                ));
            }
            renderedOption = (
                <div style={ styles.containerStyle }>
                    <div style={ styles.avatarStyle }>
                        <UserInfo
                            avatar={ option.label.photo_square }
                            hideFullname
                            fullname={ option.label.value }
                        />
                    </div>
                    <div style={ styles.infoContainer }>
                        <span style={ styles.nameStyle }>{ option.label.value }</span>
                        { additionalInfo }
                    </div>
                </div>
            );
        }
        if (option.label.type === 'unit') {
            const icon = [];
            icon.push(<i className="fa fa-users" style={ styles.unitIconStyle } />);

            renderedOption = (
                <ListItem
                    leftItems={ icon }
                    primaryText={ (
                        <span
                            style={ styles.unitNameStyle }
                            title={ option.label.value }
                        >
                            { option.label.value }
                        </span>
                    ) }
                    bottomDivider={ false }
                    topDivider={ false }
                    hoverable={ false }
                />
            );
        }
        return renderedOption;
    }
}
