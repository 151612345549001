import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SUGGESTION_SELECT_VALUE_SHAPE } from '../../../user_suggestions/constants';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';
import getValidationProps from '../../helpers/getValidationProps';
import {
    CREATE_TYPE_SINGLE,
    LabeledSelect,
    LabeledTextField,
    LabeledTimePicker,
    LabeledUserSuggestions,
    LabeledUserJoinAllowedSelect,
} from './EventEditModal';
import { getModalData } from '../../reducers';
import { eventModalFieldValueChange, eventModalParseExcel } from '../../actions';
import formatDuration from '../../helpers/formatDuration';
import { choiceShape } from '../../../common/constants/propTypes';


export class EventEditPanelComponent extends React.Component {
    static propTypes = {
        // config
        canChangeOwner: PropTypes.bool,
        maxCreateHour: PropTypes.number.isRequired,
        // minuteStartStep: PropTypes.number.isRequired,
        minEventDuration: PropTypes.number.isRequired,
        maxEventDuration: PropTypes.number.isRequired,
        minuteDurationStep: PropTypes.number.isRequired,
        // dispatch
        onFieldChange: PropTypes.func.isRequired,
        parseExcel: PropTypes.func.isRequired,
        // from state
        createType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: choiceShape,
        locations: PropTypes.arrayOf(choiceShape).isRequired,
        place: PropTypes.string.isRequired,
        capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        canPlus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        startTime: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
        copyStartTime: PropTypes.string.isRequired,
        owner: SUGGESTION_SELECT_VALUE_SHAPE,
        processing: PropTypes.bool.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
        usersJoinAllowed: PropTypes.arrayOf(SUGGESTION_SELECT_VALUE_SHAPE).isRequired,
    };

    static defaultProps = {
        owner: null,
        canChangeOwner: null,
        location: null,
        capacity: null,
    };

    getDurationMaxTime = () => {
        // duration should be lte maxEventDuration and startTime + duration should be lte maxCreateHour
        const minDurationMs = moment.duration(this.props.minEventDuration, 'minutes').asMilliseconds();
        const maxDurationMs = moment.duration(this.props.maxEventDuration, 'minutes').asMilliseconds();
        const timeBasis = this.props.createType === CREATE_TYPE_SINGLE
            ? this.props.startTime
            : this.props.copyStartTime;
        const createStartDurationMs = moment(this.props.maxCreateHour, 'HH').diff(moment(timeBasis, 'HH:mm'));
        const resultDurationMs = Math.max(minDurationMs, Math.min(maxDurationMs, createStartDurationMs));
        return formatDuration(moment.duration(resultDurationMs));
    };

    render() {
        return (
            <div>
                <LabeledTextField
                    label="Название"
                    key="name"
                    value={ this.props.name }
                    name="name"
                    onChange={ this.props.onFieldChange }
                    disabled={ this.props.processing }
                    { ...getValidationProps(this.props.validationErrors, 'name') }
                    fullWidth
                />
                <LabeledSelect
                    label="Место проведения"
                    value={ this.props.location }
                    options={ this.props.locations }
                    valueKey="id"
                    labelKey="name"
                    onChange={ option => this.props.onFieldChange({
                        target: {
                            name: 'location',
                            value: option,
                        },
                    }) }
                    placeholder="Выбери место проведения..."
                    { ...getValidationProps(this.props.validationErrors, 'location') }
                    disabled={ this.props.processing }
                    fullWidth
                />
                <LabeledTextField
                    label="Точное место проведения"
                    value={ this.props.place }
                    key="place"
                    name="place"
                    onChange={ this.props.onFieldChange }
                    disabled={ this.props.processing }
                    { ...getValidationProps(this.props.validationErrors, 'place') }
                    fullWidth
                />
                <LabeledTextField
                    label="Кол-во участников"
                    pattern="[0-9]{0,4}"
                    name="capacity"
                    hintText="Не ограничено"
                    value={ this.props.capacity === null ? '' : this.props.capacity }
                    key="capacity"
                    onChange={ this.props.onFieldChange }
                    disabled={ this.props.processing }
                    { ...getValidationProps(this.props.validationErrors, 'capacity') }
                    fullWidth
                />
                <LabeledTextField
                    label="Макс. гостей для одного участника"
                    pattern="[0-9]{0,3}"
                    name="canPlus"
                    value={ this.props.canPlus }
                    key="canPlus"
                    onChange={ this.props.onFieldChange }
                    disabled={ this.props.processing }
                    { ...getValidationProps(this.props.validationErrors, 'canPlus') }
                    fullWidth
                />
                <LabeledTimePicker
                    label="Продолжительность"
                    name="duration"
                    showTimeUnits
                    value={ this.props.duration }
                    key="duration"
                    onChange={ this.props.onFieldChange }
                    minTime={ formatDuration(moment.duration(this.props.minEventDuration, 'minutes')) }
                    maxTime={ this.getDurationMaxTime() }
                    minutesStep={ this.props.minuteDurationStep }
                    disabled={ this.props.processing }
                    flexOrder={ 99 }
                    { ...getValidationProps(this.props.validationErrors, 'duration') }
                    fullWidth
                />
                <LabeledUserJoinAllowedSelect
                    label="Запись доступна"
                    onChange={ value => this.props.onFieldChange({
                        target: {
                            name: 'usersJoinAllowed',
                            value,
                        },
                    }) }
                    values={ this.props.usersJoinAllowed }
                    parseExcel={ this.props.parseExcel }
                    disabled={ this.props.processing }
                />
                { this.props.canChangeOwner && (
                    <LabeledUserSuggestions
                        label="Автор"
                        name="owner"
                        onChangeValue={ option => this.props.onFieldChange({
                            target: {
                                name: 'owner',
                                value: option,
                            },
                        }) }
                        selectValue={ this.props.owner }
                        key="owner"
                        flexOrder={ 99 }
                        disabled={ this.props.processing }
                        { ...getValidationProps(this.props.validationErrors, 'owner') }
                        fullWidth
                    />
                ) }
            </div>
        );
    }
}

const mapStateToProps = state => getModalData(state);

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onFieldChange: eventModalFieldValueChange,
        parseExcel: eventModalParseExcel,
    }, dispatch),
});

const EventEditPanel = connect(mapStateToProps, mapDispatchToProps)(EventEditPanelComponent);
export default EventEditPanel;
