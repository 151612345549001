import { debounce } from '@/common/helpers/helpers';

import { headerDispatch } from '@/base_page/helpers/createStore';
import { notificationSystem } from '@/base_page/actions/actions';

const errorNotificationDebouncedFuncs = {};

export const showNotification = (obj) => {
    // obj may look like { message: '', level: '' }
    const action = notificationSystem(obj);
    headerDispatch(action);
};

export const showSuccessNotification = (message) => {
    showNotification({ level: 'success', message });
};

export const showWarningNotification = (message) => {
    showNotification({ level: 'warning', message });
};

export const showErrorNotification = (message) => {
    if (!errorNotificationDebouncedFuncs[message]) {
        errorNotificationDebouncedFuncs[message] = debounce(() => {
            showNotification({ level: 'error', message });
        }, 100);
        setTimeout(() => delete errorNotificationDebouncedFuncs[message], 10000);
    }
    errorNotificationDebouncedFuncs[message]();
};

export const show403Notification = () => {
    showErrorNotification('Недостаточно прав для выполнения операции');
};

export const show404Notification = () => {
    showErrorNotification('Запрашиваемый ресурс не найден');
};

export const showNetworkErrorNotification = () => {
    showErrorNotification('Сетевая ошибка при выполнении запроса');
};

export const show500Notification = () => {
    showErrorNotification('Ошибка при выполнении запроса');
};
