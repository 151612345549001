import { createSelector } from 'reselect';
import { objCamelFromSnake } from './helpers/objCamelFromSnake';

export const projectFeaturesSelector = createSelector(
    state => state.projectFeatures || state.features, // for spa pages
    features => objCamelFromSnake(features),
);

export const constantsSelector = createSelector(
    state => state.projectConstants,
    constants => objCamelFromSnake(constants),
);

export const selfProfileSelector = createSelector(
    state => state.selfProfile,
    selfProfile => objCamelFromSnake(selfProfile),
);

export const userPermissionsSelector = state => state.userPermissions.permissions;
export const userPermissionsLoadedSelector = state => state.userPermissions.isLoaded;

/*
    пермишены часто грузятся 2 раза - один раз для spa, второй раз - для приложения
    isLoaded может оказаться true если загрузились первые, хоть вторые еще нет
    поэтому дополнительно проверяем наличие пермишенов приложения в redux state
*/
export const makeUserPermissionsLoadedSelector = appPermissions => createSelector(
    userPermissionsSelector,
    userPermissionsLoadedSelector,
    (permissions, isLoaded) => {
        const allLoaded = appPermissions.reduce((acc, cur) => acc && (cur in permissions), true);
        return isLoaded && allLoaded;
    },
);
