import PropTypes from 'prop-types';

export const micropostTimelineRecordProps = {
    action_text: PropTypes.string.isRequired,
    to_user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    timeline_text: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
    })),
    image_count: PropTypes.number,
    sticker_url: PropTypes.string,
    pack_id: PropTypes.number,
    id: PropTypes.number.isRequired,
    url: PropTypes.string,
    date: PropTypes.string,
    userData: PropTypes.shape({
        id: PropTypes.number,
        full_name_ro: PropTypes.string,
        username: PropTypes.string,
    }),
    authUserData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string,
    }).isRequired,
    canEditAnyMicropost: PropTypes.bool,
    canViewStatistic: PropTypes.bool,
};

export const micropostTimelineRecordPropDefaults = {
    userData: null,
    url: null,
    timeline_text: '',
    images: [],
    to_user: null,
    image_count: 0,
    sticker_url: null,
    date: null,
    canEditAnyMicropost: false,
    canViewStatistic: false,
    pack_id: null,
};
