import { CALL_API } from '../../../common/constants/actionTypes';
import ACTION_TYPES from '../actionTypes';
import {
    QUIZ_SET_ACTIVE_URL, QUIZ_RESTRICT_URL, QUIZ_UNRESTRICT_URL,
    QUIZ_TO_ARCHIVE_URL, QUIZ_PUBLISH_ON_MAIN_PAGE_URL,
    QUIZ_NOT_PUBLISH_ON_MAIN_PAGE_URL, QUIZ_UNPUBLISH_FROM_MAIN_PAGE_URL,
    QUIZ_DETAIL_URL, UPDATE_HOLDERS_URL,
} from '../constants';
import { showNotification } from '../../../common/helpers/showNotification';

export const setActive = (quizId, updateQuizzesList) => ({
    type: CALL_API,
    endpoint: QUIZ_SET_ACTIVE_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.SET_ACTIVE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'Опрос опубликован' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});

export const restrictQuiz = quizId => ({
    type: CALL_API,
    endpoint: QUIZ_RESTRICT_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        ACTION_TYPES.SET_RESTRICT_FINISH,
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId },
});

export const unRestrictQuiz = quizId => ({
    type: CALL_API,
    endpoint: QUIZ_UNRESTRICT_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        ACTION_TYPES.SET_UNRESTRICT_FINISH,
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId },
});

export const quizToArchive = (quizId, updateQuizzesList = false) => ({
    type: CALL_API,
    endpoint: QUIZ_TO_ARCHIVE_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.SET_TO_ARCHIVE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'Опрос отправлен в архив' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});

export const quizPublishOnMainPage = (quizId, updateQuizzesList = false) => ({
    type: CALL_API,
    endpoint: QUIZ_PUBLISH_ON_MAIN_PAGE_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.PUBLISH_ON_MAIN_PAGE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'Опрос опубликован на главной' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});

export const quizNotPublishOnMainPage = (quizId, updateQuizzesList = false) => ({
    type: CALL_API,
    endpoint: QUIZ_NOT_PUBLISH_ON_MAIN_PAGE_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.NOT_PUBLISH_ON_MAIN_PAGE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'В публикации на главной отказано' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});

export const quizUnPublishFromMainPage = (quizId, updateQuizzesList = false) => ({
    type: CALL_API,
    endpoint: QUIZ_UNPUBLISH_FROM_MAIN_PAGE_URL(quizId),
    method: 'post',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.UNPUBLISH_FROM_MAIN_PAGE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'Опрос убран с главной' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});

export const quizDelete = (quizId, updateQuizzesList = false) => ({
    type: CALL_API,
    endpoint: QUIZ_DETAIL_URL(quizId),
    method: 'delete',
    types: [
        ACTION_TYPES.API_CALL_START,
        {
            type: ACTION_TYPES.DELETE_FINISH,
            callback: () => showNotification({ level: 'success', message: 'Опрос удалён' }),
        },
        ACTION_TYPES.API_FAIL,
    ],
    additionalData: { quizId, updateQuizzesList },
});
