import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import classNames from 'classnames';
import UserTooltipContainer from '@/common/components/UserTooltipContainer';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { ThanksDialog } from '../../thanks/components/ThanksDialog';
import { thanksCanEditUrl } from '../../thanks/constants';
import { Record as BasicRecord } from './Record';
import { COIN_ICON } from '../../store/constants/constants';


const styles = {
    coinsStyle: {
        color: '#f44e4e',
        fontSize: '16px',
        marginRight: '1px',
    },
    coinsImageStyle: {
        width: '13px',
        height: '13px',
        marginTop: '3px',
    },
    recordBodyStyle: isHaveDescription => ({
        display: 'flex',
        justifyContent: isHaveDescription ? 'space-between' : 'flex-end',
    }),
    imageContainerStyle: {
        paddingLeft: '15px',
        paddingTop: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: '0',
    },
    imageStyle: {
        width: '100px',
        height: '100px',
    },
};

export class ThanksRecord extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        extra_text: PropTypes.string,
        image: PropTypes.string,
        coins: PropTypes.number,
        url: PropTypes.string,
        thank_type: PropTypes.number,
        thank_type_slug: PropTypes.string,
        action_text: PropTypes.string.isRequired,
        parent_related_text: PropTypes.string.isRequired,
        to_user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            square_avatar: PropTypes.string.isRequired,
            first_name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
        id: PropTypes.number.isRequired, // id благодарности
        record_id: PropTypes.number.isRequired,
        updateRecord: PropTypes.func.isRequired,
    };

    static defaultProps = {
        text: null,
        extra_text: null,
        image: null,
        coins: null,
        url: null,
        thank_type: null,
        thank_type_slug: null,
    };

    state = {
        openDialog: false,
        canEdit: false,
    };

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    handleEdit = (thanksData) => {
        this.props.updateRecord(this.props.record_id, {
            ...thanksData,
            thank_type: thanksData.thank_type,
        });
    };

    handleOpenSettings = () => {
        callApi(thanksCanEditUrl(this.props.id)).then((response) => {
            this.setState({ canEdit: response.can_edit });
        });
    };

    render() {
        const actionText = this.props.action_text;
        const toUser = this.props.to_user;
        const relatedText = this.props.parent_related_text;

        let descriptionHTML = null;
        if (this.props.text) {
            descriptionHTML = this.props.text;
            if (this.props.extra_text) {
                descriptionHTML += `<div class="timeline-thanks-description-extra-text">${this.props.extra_text}</div>`;
            }
        }
        const description = descriptionHTML ? (
            <div
                className="timeline-thanks-description-container"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={ { __html: descriptionHTML } }
            />
        ) : null;

        const coins = this.props.coins ? (
            <div
                title="Коины в благодарности"
                className="b-flexrow"
            >
                <span style={ styles.coinsStyle }>{ this.props.coins }</span>
                <img src={ COIN_ICON } alt="коинов" style={ styles.coinsImageStyle } />
            </div>
        ) : null;

        const recordBody = (
            <div className={ classNames({
                'timeline-thanks-body': true,
                'timeline-thanks-body-with-description': description,
            }) }
            >
                { description }
                <div className="timeline-thanks-image-container">
                    <A href={ this.props.url } title={ this.props.thank_type }>
                        <img style={ styles.imageStyle } src={ this.props.image } alt="Спасибо" />
                    </A>
                    { coins }
                </div>
            </div>
        );

        const recordHead = (
            <span>
                <span>{ actionText } </span>
                <UserTooltipContainer username={ toUser.username } wrapper="span">
                    <A className="inline-link" href={ toUser.url }>{ relatedText }</A>
                </UserTooltipContainer>
            </span>
        );

        return (
            <Fragment>
                <BasicRecord
                    onEditClick={ this.state.canEdit ? this.handleOpenDialog : undefined }
                    recordBody={ recordBody }
                    recordHead={ recordHead }
                    onOpenSettings={ this.handleOpenSettings }
                    { ...this.props }
                    type="благодарность"
                />

                <ThanksDialog
                    key={ this.props.id }
                    thanksId={ this.props.id }
                    canSendCoins={ false }
                    openDialog={ this.state.openDialog }
                    onCloseDialog={ this.handleCloseDialog }
                    editMode
                    chosenThanksTypeId={ this.props.thank_type }
                    thanksText={ this.props.text }
                    onEdit={ this.handleEdit }
                />

            </Fragment>
        );
    }
}
