import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from '@/common/components/Helmet';
import { IntdevuiThemeProvider } from 'intdev-ui';
import { addLocaleData, IntlProvider } from 'react-intl';
import ruLocaleData from 'react-intl/locale-data/ru';
import { getAllCssVars } from './cssVar';
import 'intdev-ui/dist/intdev-ui.css';


addLocaleData(ruLocaleData);

export function render(node, element, intdevuiThemeProviderProps = {}) {
    const defaultThemeVars = getAllCssVars();

    ReactDOM.render(
        <>
            <Helmet titleTemplate="%s | Интранет" />
            <IntdevuiThemeProvider themeVars={ defaultThemeVars } { ...intdevuiThemeProviderProps }>
                <IntlProvider defaultLocale="ru" locale="ru">
                    {node}
                </IntlProvider>
            </IntdevuiThemeProvider>
        </>,
        element,
    );
}
