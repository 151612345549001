import React from 'react';
import PropTypes from 'prop-types';


const styles = {
    label: {
        fontSize: '16px',
        color: '#333',
        cursor: 'pointer',
        wordBreak: 'break-word',
        position: 'relative',
    },
    multiSlider: {
        margin: '10px 0 0',
    },
};

const LABEL_MARGIN = 10;
const MIN_LABEL_WIDTH = 40;


export class Slider extends React.Component {
    static propTypes = {
        minValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        onChange: PropTypes.func.isRequired,
        labelFunc: PropTypes.func,
        labels: PropTypes.arrayOf(PropTypes.number),
        labelsCount: PropTypes.number.isRequired,
    };

    static defaultProps = {
        labelFunc: v => v,
        labels: null,
    };

    state = {
        isInput: false,
        value: this.props.value,
    };

    componentDidMount() {
        this.forceUpdate();
    }

    get currentValue() {
        if (this.state.isInput) {
            return this.state.value;
        }
        return this.props.value;
    }

    handleInput = (e, value) => {
        e.preventDefault();
        if (this.state.isInput) {
            this.setState({ value });
        }
    }

    handleMouseDown = () => {
        this.setState({ isInput: true });
    }

    handleMouseUp = (event) => {
        this.setState({ isInput: false });
        this.props.onChange(event, this.state.value);
    }

    makeLabels = (minValue, maxValue, count) => {
        const labels = [minValue];
        for (let i = 1; i < (count - 1); i++) {
            const label = Math.round((i * (maxValue - minValue)) / (count - 1)) + minValue;
            labels.push(label);
        }
        labels.push(maxValue);
        return labels;
    };

    handleLabelClick = (e, value) => {
        this.setState({ value });
        this.props.onChange(e, value);
    };

    render() {
        const valueRange = this.props.maxValue - this.props.minValue;
        let labels = [];
        if (valueRange && this.originalEl) {
            const delta = this.originalEl.clientWidth / valueRange;
            const intDelta = Math.floor(delta);
            const width = Math.max(MIN_LABEL_WIDTH, intDelta - 2 * LABEL_MARGIN);
            labels = (
                this.props.labels || this.makeLabels(this.props.minValue, this.props.maxValue, this.props.labelsCount)
            ).map((lbl, index, arr) => {
                if (index === arr.length - 1) {
                    return {
                        value: lbl,
                        style: {
                            width: width / 2,
                            textAlign: 'right',
                        },
                    };
                }
                if (!index) {
                    return {
                        value: lbl,
                        style: {
                            width: width / 2,
                            textAlign: 'left',
                        },
                    };
                }
                return {
                    value: lbl,
                    style: {
                        width,
                        textAlign: 'center',
                    },
                };
            });
        }
        return (
            <div className="multi-slider" style={ styles.multiSlider }>
                <div className="multi-slider-ranges">
                    <input
                        type="range"
                        value={ this.currentValue }
                        min={ this.props.minValue }
                        max={ this.props.maxValue }
                        className="multi-slider-multirange multi-slider-original"
                        onInput={ e => this.handleInput(e, e.target.value) }
                        onMouseDown={ e => this.handleMouseDown(e) }
                        onMouseUp={ e => this.handleMouseUp(e) }
                        onChange={ () => {} }
                        ref={ (ref) => { this.originalEl = ref; } }
                    />
                </div>

                <div className="slider-labels">
                    { labels.map(label => (
                        <div
                            className="multi-slider-value-label"
                            style={ { ...label.style, ...styles.label } }
                            key={ label.value }
                            onClick={ e => this.handleLabelClick(e, label.value) }
                        >
                            { this.props.labelFunc(label.value) }
                        </div>
                    )) }
                </div>

            </div>
        );
    }
}
