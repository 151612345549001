import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Album from './Album';

import './styles.css';

class Albums extends Component {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.number),
        onLoad: PropTypes.func.isRequired,
    };
    static defaultProps = {
        ids: [],
    };

    handleLoad = () => this.props.onLoad();

    render() {
        const { ids } = this.props;
        if (!ids.length) {
            return null;
        }
        return (
            <div className="album-list-container">
                { ids.map(id => <Album key={ id } id={ id } onLoad={ this.handleLoad } />) }
            </div>
        );
    }
}

export default Albums;
