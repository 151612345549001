export const APP_NAME = 'blog';

export const ICON_CLASS_IMG = 'img';
export const ICON_CLASS_VIDEO = 'video';
export const ICON_CLASS_AUDIO = 'audio';

export const LOCAL_STORAGE_EXPIRE_MINUTES = 120;

export const attachmentsTypesList = [ICON_CLASS_IMG, ICON_CLASS_VIDEO, ICON_CLASS_AUDIO];
export const attachmentsTypes = [
    { type: ICON_CLASS_IMG, name: 'Фотографии', id: 'photo' },
    { type: ICON_CLASS_VIDEO, name: 'Видеозаписи', id: 'video' },
    { type: ICON_CLASS_AUDIO, name: 'Аудиозаписи', id: 'audio' },
];
