import { isEmptyObject } from '../../common/helpers/helpers';
import { BLOG_CTYPE } from './ctypes';


const defaultApiUrls = {
    meetingTruncate: () => ('/calendar/api/truncate/'),
    meetingUserSearch: meetingId => (`/search/?meeting=${meetingId}`),
    meetingSubscribers: (contentType, objectId) => (`/search/?moid=${objectId}&mcid=${contentType}`),
    calendarConfig: (contentType, objectId) => (`/calendar/api/config/?content_type=${contentType}&object_id=${objectId}`),

    meetingParticipants: meetingId => (`/calendar/api/participant/?meeting=${meetingId}`),
    meetingRemind: meetingId => (`/calendar/api/meeting/${meetingId}/remind/`),

    // meeting list views
    meetingLoad: (contentType, objectId, fromId, isBackward, nameFilter) => (
        `/calendar/api/meeting/?content_type=${contentType}&object_id=${objectId}${fromId ? `&from_value=${fromId}` : ''}${isBackward ? '&backward=true' : ''}${nameFilter ? `&name=${nameFilter}` : ''}`
    ),
    meetingInitiallLoad: (contentType, objectId) => (
        `/calendar/api/meeting/initial_load/?content_type=${contentType}&object_id=${objectId}`
    ),
    meetingList: () => ('/calendar/api/meeting/'),
    meetingCreateMulti: () => ('/calendar/api/meeting/create_multi/'),
    meetingCreateSeries: () => ('/calendar/api/meeting/create_series/'),

    // meeting detail views
    meetingUnsubscribee: meetingId => (`/calendar/api/meeting/${meetingId}/unsubscribe/`),
    meetingSubscribee: meetingId => (`/calendar/api/meeting/${meetingId}/subscribe/`),

    meetingDetail: meetingId => (`/calendar/api/meeting/${meetingId}/`),
    meetingLocations: () => ('/calendar/api/meeting_locations/'),
    eventCopy: (contentType, objectId, meetingId) => (`/calendar/api/meeting/${meetingId}/duplicate/`),
    meetingJoin: meetingId => (`/calendar/api/meeting/${meetingId}/join/`),
    meetingLeave: meetingId => (`/calendar/api/meeting/${meetingId}/leave/`),

    parseExcel: () => ('/utils/parse_excel/'),
};

const ctypeOverridesApiUrls = {
    [BLOG_CTYPE]: {
        meetingLoad: (contentType, objectId, fromId, isBackward) => (`/calendar/api/meeting/blog_list/?content_object_blog_id_in=${objectId}${fromId ? `&from_value=${fromId}` : ''}${isBackward ? '&backward=true' : ''}`),
    },
};

// todo add apiUrls overrides injection to CalendarContainer
export const apiUrls = (props = {}) => {
    const {
        contentType,
    } = props;
    const overrides = ctypeOverridesApiUrls[contentType] || {};
    if (isEmptyObject(overrides)) {
        return defaultApiUrls;
    }
    return {
        ...defaultApiUrls,
        ...overrides,
    };
};
