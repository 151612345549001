import { ACTION_TYPES } from '../constants/actionConstants';

const defaultState = {
    menu: [],
    perms: [],
    isLoading: false,
    isError: false,
    errorMessage: '',
};

export const menu = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_HEADER_MENU_START:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION_TYPES.GET_HEADER_MENU_FINISH:
            return {
                ...state,
                menu: action.payload.menu,
                perms: action.payload.perms,
                isLoading: false,
            };
        case ACTION_TYPES.GET_HEADER_MENU_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Произошла ошибка',
            };

        default: return state;
    }
};
