import { ACTION_TYPES } from '@/base_page/constants/actionConstants';

const defaultState = {
    title: '',
    message: '',
    level: '',
    children: {},
    id: 0,
};

export const notificationSystem = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.RECEIVE_NOTIFICATIONS: {
            const {
                message, title, level, children,
            } = action;
            return {
                ...state,
                message,
                title,
                level,
                children,
                id: state.id + 1,
            };
        }

        default:
            return state;
    }
};
