import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';

import ACTION_TYPES from '../actionTypes';
import {
    FLAG_PUBLISHED, FLAG_ARCHIVE, ON_MAIN_PAGE,
    NOT_ON_MAIN_PAGE, QUIZZES_LIST_PAGE_URL,
} from '../constants';

export const quiz = (state = {}, action) => {
    if (action.additionalData && action.additionalData.updateQuizzesList) {
        return state;
    }
    switch (action.type) {
        case ACTION_TYPES.QUIZ_LOAD_FINISH: {
            return action.payload.quiz;
        }
        case ACTION_TYPES.SET_ACTIVE_FINISH: {
            return update(state, {
                flag: { $set: FLAG_PUBLISHED },
            });
        }
        case ACTION_TYPES.SET_RESTRICT_FINISH: {
            return update(state, {
                is_restricted: { $set: true },
            });
        }
        case ACTION_TYPES.SET_UNRESTRICT_FINISH: {
            return update(state, {
                is_restricted: { $set: false },
            });
        }
        case ACTION_TYPES.SET_TO_ARCHIVE_FINISH: {
            return update(state, {
                flag: { $set: FLAG_ARCHIVE },
            });
        }
        case ACTION_TYPES.PUBLISH_ON_MAIN_PAGE_FINISH: {
            return update(state, {
                main_page_status: { $set: ON_MAIN_PAGE },
            });
        }
        case ACTION_TYPES.UNPUBLISH_FROM_MAIN_PAGE_FINISH: {
            return update(state, {
                main_page_status: { $set: NOT_ON_MAIN_PAGE },
            });
        }
        case ACTION_TYPES.NOT_PUBLISH_ON_MAIN_PAGE_FINISH: {
            return update(state, {
                main_page_status: { $set: NOT_ON_MAIN_PAGE },
            });
        }
        case ACTION_TYPES.GET_RESULTS_FINISH: {
            return update(state, {
                answered_count: { $set: action.payload.answered_count },
            });
        }
        case ACTION_TYPES.DELETE_FINISH: {
            window.location.href = QUIZZES_LIST_PAGE_URL;
            return state;
        }
        case LOCATION_CHANGE:
            return {};
        default:
            return state;
    }
};
