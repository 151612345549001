import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';


const initialState = {
    quizEditPerm: false,
    viewResultsPerm: false,
    pubOnMainPagePerm: false,
};


export const permissions = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZ_LOAD_FINISH: {
            return update(state, {
                quizEditPerm: { $set: !!(action.payload.permissions.quiz_edit) },
                viewResultsPerm: { $set: !!(action.payload.permissions.view_results) },
                pubOnMainPagePerm: { $set: !!(action.payload.permissions.publish_quiz_on_main_page) },
            });
        }
        default:
            return state;
    }
};
