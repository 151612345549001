import { isMobile } from '@/common/mediaQueries';

export const styles = {
    menuBlockStyles: {
        padding: 0,
        marginRight: 0,
        marginLeft: 20,
        width: 240,
        backgroundColor: 'transparent',
    },
    myItemStyle: {
        width: '100%',
    },
    myTableStyle: [
        { width: '1%', padding: 0, position: 'relative' },
        { width: '30%', fontSize: '13px', overflow: 'visible' },
        { width: '15%', fontSize: '13px' },
        { width: '12%', fontSize: '13px' },
        { width: '25%', fontSize: '13px' },
        { width: '12%', fontSize: '13px' },
        { width: '5%', fontSize: '13px', padding: 0 },
    ],
    myTableStyleHeader: [
        { width: '1%', padding: 0, position: 'relative' },
        {
            width: '30%', fontSize: '13px', overflow: 'visible', textAlign: 'center',
        },
        { width: '15%', fontSize: '13px' },
        { width: '12%', fontSize: '13px' },
        { width: '25%', fontSize: '13px' },
        { width: '12%', fontSize: '13px' },
        { width: '5%', fontSize: '13px' },
    ],
    matchingTableStyle: [
        {
            width: '25%', fontSize: 13, overflow: 'visible', verticalAlign: 'top',
        },
        {
            width: '25%', verticalAlign: 'top', fontSize: 13, padding: '17px 16px',
        },
        {
            width: '15%', verticalAlign: 'top', fontSize: 13, padding: '17px 16px',
        },
        {
            width: '15%', verticalAlign: 'top', fontSize: 13, padding: '17px 16px',
        },
        { width: '10%', verticalAlign: 'top', padding: '8px 16px 8px 0' },
        { width: '10%', verticalAlign: 'top', padding: '8px 16px 8px 0' },
    ],
    matchingTableHeaderStyle: [
        { width: '25%', fontSize: 13 },
        { width: '25%', fontSize: 13 },
        { width: '15%', fontSize: 13 },
        { width: '15%', fontSize: 13 },
        { width: '10%' },
        { width: '10%' },
    ],
    processingTableStyle: [
        { width: '1%', padding: 0, position: 'relative' },
        {
            width: '22%', overflow: 'visible', verticalAlign: 'top', paddingRight: 0,
        },
        { width: '10%', padding: '17px 16px', verticalAlign: 'top' },
        { width: '14%', padding: '17px 16px', verticalAlign: 'top' },
        { width: '9%', padding: '17px 16px', verticalAlign: 'top' },
        { width: '9%', padding: '17px 16px', verticalAlign: 'top' },
        {
            width: '20%', overflow: 'hidden', verticalAlign: 'top', padding: '8px 16px',
        },
        { width: '15%', padding: '8px 16px 8px 0', verticalAlign: 'top' },
    ],
    processingTableHeaderStyle: [
        { width: '1%', padding: 0 },
        { width: '22%', overflow: 'visible' },
        { width: '10%' },
        { width: '14%' },
        { width: '9%' },
        { width: '9%' },
        { width: '20%', overflow: 'visible' },
        { width: '15%' },
    ],
    dialog: {
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            padding: isMobile ? 10 : 20,
        },
        title: {
            display: 'none',
        },
    },
    popover: {
        width: '250px',
        margin: '0, 5px',
    },
    listItem: {
        primaryText: {
            fontSize: '12px',
        },
    },
    disabledListItem: {
        itemWrapper: {
            opacity: 0.48,
            backgroundColor: 'rgb(236, 237, 240)',
            ':hover': {
                cursor: 'not-allowed',
                backgroundColor: 'rgb(236, 237, 240)',
            },
        },
    },
    uploadButton: {
        width: '256px',
    },
    filterLink: {
        marginRight: '20px',
        color: '#999999',
    },
};
