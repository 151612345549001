export default function getValidationProps(validationErrors, name, omitText) {
    const names = Array.isArray(name) ? name : [name];
    const anyError = names.some(el => validationErrors[el]);
    const validationProps = {};

    if (anyError) {
        validationProps.validationState = 'error';
        validationProps.validationText = '';
        if (!omitText) {
            for (const fieldName of names) {
                const validationError = validationErrors[fieldName];
                if (Array.isArray(validationError)) {
                    validationProps.validationText += validationError.join('. ');
                } else {
                    validationProps.validationText += validationError;
                }
            }
        }
    }

    return validationProps;
}
