import { showErrorNotification } from '../helpers/showNotification';

// eslint-disable-next-line no-unused-vars
export default store => next => (action) => {
    if (action.error && action.error.status && !action.ignoredStatuses.includes(action.error.status)) {
        switch (action.error.status) {
            case 502:
            case 500:
                showErrorNotification('При выполнении запроса произошла ошибка');
                break;

            case 404:
                showErrorNotification('Запрашиваемый ресурс не найден');
                break;

            case 403:
                showErrorNotification('У тебя недостаточно прав для совершения этого действия');
                break;

            default:
                break;
        }
    }
    return next(action);
};
