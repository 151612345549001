import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { urls } from '../constants/apiUrls';
import LikesCounterItem from './LikesCounterItem';
import { countShape } from '../constants/propTypes';


export default class LikesCounter extends Component {
    static propTypes = {
        contentTypeId: PropTypes.PropTypes.number.isRequired,
        objectId: PropTypes.PropTypes.number.isRequired,
        count: countShape,
        isLiked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        handleLike: PropTypes.func.isRequired,
        updateLikeTooltipInfo: PropTypes.func.isRequired,
        color: PropTypes.string.isRequired,
        tooltipInfo: PropTypes.shape(),
        collapsed: PropTypes.bool,
    };

    static defaultProps = {
        count: {},
        isLiked: undefined,
        tooltipInfo: {},
        collapsed: false,
    };

    handleTooltip = () => {
        const { contentTypeId, objectId } = this.props;
        callApi(urls.fetchTooltipInfo(contentTypeId, objectId)).then((data) => {
            this.props.updateLikeTooltipInfo(data);
        });
    };

    render() {
        const {
            count,
            isLiked,
            color,
            tooltipInfo,
            collapsed,
        } = this.props;

        let countForm = <i className="fa fa-spinner fa-spin fa-fw" />;
        const likesSum = Object.values(count).reduce((acc, el) => Number(acc) + Number(el), 0);

        if (count) {
            countForm = (
                <div className="like-counter-form">
                    {
                        Object.keys(count).map(result => (
                            <LikesCounterItem
                                key={ `likesCounter-item${result}` }
                                isLiked={ isLiked }
                                color={ color }
                                count={ count }
                                emoji={ result }
                                tooltipItemInfo={ tooltipInfo ? tooltipInfo[result] : undefined }
                                onLikeClick={ this.props.handleLike }
                                collapsed={ collapsed }
                            />
                        ))
                    }
                    {
                        collapsed && !!likesSum && (
                            <span className="likes-counter-value">
                                { likesSum }
                            </span>
                        )
                    }
                </div>
            );
        }

        return <div onMouseEnter={ this.handleTooltip }>{ countForm }</div>;
    }
}
