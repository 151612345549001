import { setLocalStorageText } from '../../common/helpers/localStorage';

export const getLocalStorageName = () => {
    const notificationsKey = window.USER_PROFILE.id || '';
    return `notifications${notificationsKey}`;
};

export const setLocalStorageNotifications = (state) => {
    const localStorageName = getLocalStorageName();
    const text = JSON.stringify(state);
    setLocalStorageText(text, localStorageName);
};
