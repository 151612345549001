import moment from 'moment';

export default function ceilMomentWithMinuteStep(valueMoment, step) {
    /*
    * gets moment instance
    * returns new instance with ceiled minutes
    * eg:
    * (20.11.2017 14:50, 15min) -> 20.11.2017 15:00
    * (08.05.2017 11:23, 5min) -> 08.05.2017 11:25
    * (10.02.2017 03:20, 5min) -> 10.02.2017 03:20
    * */
    const ceiledMinutes = Math.ceil((valueMoment.minute()) / step) * step;
    return moment(valueMoment).startOf('hour').add(ceiledMinutes, 'minutes');
}
