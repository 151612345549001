import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobile } from '@/common/mediaQueries';
import CommentsForm from '../CommentsForm';
import Comment from './Comment';

export default class CommentsList extends Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        objectId: PropTypes.number.isRequired,
        list: PropTypes.arrayOf(PropTypes.shape()),
        isNested: PropTypes.bool.isRequired,
        userId: PropTypes.number,
        lastViewTimestamp: PropTypes.number,
        onBlackListUpdate: PropTypes.func,
        replyForm: PropTypes.bool,
        deleteComment: PropTypes.func.isRequired,
        commentsEnabled: PropTypes.bool,
        officialResponse: PropTypes.shape(),
        officialResponseLikesBlockUuid: PropTypes.string,
    };

    static defaultProps = {
        parentComment: {},
        userId: undefined,
        lastViewTimestamp: undefined,
        onBlackListUpdate: undefined,
        list: [],
        replyForm: false,
        commentsEnabled: true,
        officialResponse: {},
        officialResponseLikesBlockUuid: null,
    };

    componentDidUpdate(prevProps) {
        const { list } = this.props;
        if (!prevProps.list.length && list.length) {
            this.markNewComments(true);
        }
    }

    markNewComments() {
        if (!this.props.lastViewTimestamp || !this.props.userId) {
            return;
        }
        const { list } = this.props;
        for (let i = 0; i < list.length; ++i) {
            if ((list[i].timestamp_created > this.props.lastViewTimestamp)
                && (this.props.userId !== list[i].userid)) {
                list[i].isNew = true;
            }
        }
        this.setState({ list: [...list] });
    }

    render() {
        const {
            list,
            isNested,
            commentsEnabled,
            officialResponse,
            officialResponseLikesBlockUuid,
        } = this.props;

        const commentsListBlockClassName = classnames(
            'comments-list',
            { 'comments-list--nested': isNested },
        );

        return (
            <div className="comments-list-box">
                { !isMobile && isNested ? <div className="parent-padding" /> : null }
                <div className={ commentsListBlockClassName }>
                    {
                        this.props.commentsEnabled && this.props.replyForm
                            ? <CommentsForm { ...this.props } />
                            : null
                    }
                    {list.map(comment => (
                        <Comment
                            key={ `comment_${comment.id}` }
                            { ...this.props }
                            data={ comment }
                            canReply={ commentsEnabled }
                            likesBlockUuid={ comment.id === officialResponse.id ? officialResponseLikesBlockUuid : null }
                        />
                    ))}
                </div>
            </div>
        );
    }
}
