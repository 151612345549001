import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';
import classNames from 'classnames';


export const PageContent = ({ children, style, className }) => (
    <div
        className={ classNames({
            'page-content': true,
            [className]: className,
        }) }
        style={ style }
    >
        { children }
    </div>
);

PageContent.propTypes = {
    children: IUIPropTypes.validChildren.isRequired,
    style: IUIPropTypes.style,
    className: PropTypes.string,
};

PageContent.defaultProps = {
    style: {},
    className: null,
};
