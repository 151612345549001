/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'intdev-ui';
import { callApi } from '../middlewares/apiMiddleware';
import { JSON_FORMAT } from '../constants/ajaxBodyTypes';
import getCentrifugeConnection from '../../centrifuge';
import { dictionary } from '@/common/localization/dictionary';

const getLabelStyle = (anotherStyle) => {
    const style = {
        color: '#7f8191',
        fontSize: 13,
    };
    return Object.assign(style, anotherStyle || {});
};

export class SubscribeToggle extends Component {
    static propTypes = {
        subscribeUrl: PropTypes.string.isRequired,
        unSubscribeUrl: PropTypes.string.isRequired,
        label: PropTypes.string,
        labelStyle: PropTypes.shape({}),
        rootStyle: PropTypes.shape({}),
        method: PropTypes.string,
        body: PropTypes.any,
        bodyFormat: PropTypes.string,
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
        isSubscribed: PropTypes.bool,
        channel: PropTypes.string,
        toggleSize: PropTypes.number,
    };

    static defaultProps = {
        isSubscribed: false,
        labelStyle: {},
        rootStyle: {},
        method: 'get',
        label: dictionary.subscription,
        body: null,
        bodyFormat: JSON_FORMAT,
        channel: null,
        onSuccess: () => {},
        onError: () => {},
        toggleSize: 25,
    };

    state = {
        isSubscribed: this.props.isSubscribed,
    }

    componentDidMount() {
        if (this.props.channel) {
            const connection = getCentrifugeConnection();
            this.centriSubscription = connection.subscribe(this.props.channel, this.centriHandler);
        }
    }

    componentWillUnmount = () => {
        if (this.centriSubscription) {
            this.centriSubscription.removeListener('message', this.centriHandler);
        }
    };

    centriHandler = (data) => {
        if (this.state.isSubscribed !== data.data.state) {
            this.setState({ isSubscribed: data.data.state });
        }
    };

    handleSwitchToggleSubscription = () => {
        this.setState(
            state => ({ isSubscribed: !state.isSubscribed }),
            () => this.doToggleSubscription(this.state.isSubscribed),
        );
    };

    doToggleSubscription = (subscribe) => {
        callApi(
            subscribe ? this.props.subscribeUrl : this.props.unSubscribeUrl,
            this.props.body ? 'post' : this.props.method,
            this.props.body || undefined,
            this.props.bodyFormat,
        ).then(
            (data) => {
                if (this.props.onSuccess) {
                    this.props.onSuccess(data);
                }
            },
        ).catch(
            () => {
                this.setState(state => ({ isSubscribed: !state.isSubscribed }));
                if (this.props.onError) {
                    this.props.onError();
                }
            },
        );
    };

    render() {
        return (
            <Toggle
                onClick={ this.handleSwitchToggleSubscription }
                isOn={ this.state.isSubscribed }
                label={ this.props.label }
                labelStyle={ getLabelStyle(this.props.labelStyle) }
                rootStyle={ this.props.rootStyle }
                size={ this.props.toggleSize }
            />
        );
    }
}
