import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMediaWidth } from '@/common/mediaWidth';
import classes from './MobileHeader.scss';


export const MobileHeader = ({
    text, onGoBack, extraStyles, className, multiline,
}) => {
    const { isDesktop } = useMediaWidth();

    return isDesktop ? null : (
        <div className={ classnames('mobile-arrow-back', multiline && classes.multiline, className) } style={ extraStyles }>
            <div onClick={ onGoBack } className="arrow-icon">
                <i className="fa fa-angle-left" />
            </div>
            <div className="arrow-text arrow-text-blog-detail">
                { text }
            </div>
        </div>
    );
};

MobileHeader.propTypes = {
    text: PropTypes.string,
    onGoBack: PropTypes.func,
    extraStyles: PropTypes.shape({}),
    className: PropTypes.string,
    multiline: PropTypes.bool,
};

MobileHeader.defaultProps = {
    text: '',
    onGoBack: () => window.history.back(),
    extraStyles: {},
    className: '',
    multiline: false,
};
