import * as Sentry from '@sentry/browser';

function NoParentException() {}

NoParentException.prototype = new Error();

function treeify(list) {
    const treeList = [];
    const lookup = {};
    for (const obj of list) {
        lookup[obj.id] = obj;
        lookup[obj.id].children = [];
    }
    for (const obj of list) {
        if (obj.parent) {
            try {
                lookup[obj.parent].children.push(obj);
            } catch (error) {
                if (error instanceof TypeError) {
                    throw new NoParentException();
                } else {
                    Sentry.withScope((scope) => {
                        scope.setExtra('list', list);
                        Sentry.captureMessage('Comments treeify error');
                    });
                }
            }
        } else {
            treeList.push(obj);
        }
    }
    return treeList;
}

function deepFind(comments, id, result) {
    let currResult = result;
    if (comments instanceof Array) {
        for (let i = 0; i < comments.length; ++i) {
            currResult = deepFind(comments[i], id, currResult);
        }
    } else {
        if (comments.id === id) {
            return comments;
        }
        if (comments.children.length) {
            currResult = deepFind(comments.children, id, currResult);
        }
    }
    return currResult;
}

export { NoParentException, treeify, deepFind };
