import React from 'react';
import PropTypes from 'prop-types';

import { calendarReducers, loadCalendarConfig, setCalendarConfig, setCalendarContainerPropsReducer } from '../reducers';
import { CustomProvider } from '../../common/components/CustomProvider';
import { configureStore } from '../../common/helpers/store';
import { showErrorNotification } from '../../common/helpers/showNotification';
import Calendar from './Calendar';
import { CALENDAR_CONFIG_SHAPE_ITEMS } from '../shapes';

class CalendarContainerKeyFree extends React.Component {
    static propTypes = {
        objectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        contentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        calendarConfig: PropTypes.shape(CALENDAR_CONFIG_SHAPE_ITEMS),
        // eslint-disable-next-line react/no-unused-prop-types
        overflowLeftArrow: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        toolbarEnabled: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        isGroupEventsByName: PropTypes.bool,
        store: PropTypes.shape({}),
    };

    static defaultProps = {
        overflowLeftArrow: true,
        toolbarEnabled: true,
        isGroupEventsByName: false,
        calendarConfig: undefined,
        store: null,
    };

    constructor(props) {
        super(props);
        this.store = this.props.store || configureStore(calendarReducers);
    }

    componentDidMount() {
        if (this.props.calendarConfig) {
            this.setCalendarConfig(this.props.calendarConfig);
        } else {
            this.loadCalendarConfig(this.props);
        }

        const { store, ...containerProps } = this.props;
        setCalendarContainerPropsReducer(this.store, containerProps);
    }

    setCalendarConfig = (configData) => {
        setCalendarConfig(this.store, configData);
        this.forceUpdate();
    };

    loadCalendarConfig = (props) => {
        loadCalendarConfig(this.store, props.contentType, props.objectId).then(() => {
            this.forceUpdate();
        }).catch(() => showErrorNotification('Ошибка при загрузке конфигурации календаря'));
    };

    render() {
        // if calendar config were loaded, there would be objectId
        if (!this.store.getState().calendarConfig.objectId) {
            return null;
        }
        const features = [
            'coolendar_sms_notifications',
            'coolendar_external_calendars',
        ];
        return (
            <CustomProvider store={ this.store } features={ features }>
                <Calendar isGroupEventsByName={ this.props.isGroupEventsByName } />
            </CustomProvider>
        );
    }
}

export const CalendarContainer = props => (
    // eslint-disable-next-line react/prop-types
    <CalendarContainerKeyFree { ...props } key={ props.objectID } />
);
