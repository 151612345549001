import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Question from '../questions/Question';
import { initAnswers } from '../../actions/answers';
import * as constants from '../../../builder/constants.es6';


class ConsecutiveQuestionGroup extends React.Component {
    static propTypes = {
        question: PropTypes.shape(),
        initAnswers: PropTypes.func.isRequired,
        prevQuestionId: PropTypes.number,
        isTest: PropTypes.bool.isRequired,
        isRightAnswerDisplayed: PropTypes.bool.isRequired,
        compactMode: PropTypes.bool,
    };

    static defaultProps = {
        prevQuestionId: null,
        compactMode: false,
    };

    componentDidMount() {
        if (!this.props.question) {
            return;
        }
        this.props.initAnswers(
            [this.props.question],
            constants.QUESTION_GROUP_TYPE_CONSECUTIVE,
            { prevQuestionId: this.props.prevQuestionId },
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.question) {
            return;
        }
        nextProps.initAnswers(
            [nextProps.question],
            constants.QUESTION_GROUP_TYPE_CONSECUTIVE,
            { prevQuestionId: nextProps.prevQuestionId },
        );
    }

    render() {
        return (
            <div>
                { this.props.question &&
                    <Question
                        question={ this.props.question }
                        isTest={ this.props.isTest }
                        isRightAnswerDisplayed={ this.props.isRightAnswerDisplayed }
                        compactMode={ this.props.compactMode }
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    let isRightAnswerDisplayed = false;
    const currentQuestionId = state.controls.currentQuestionId;
    if (ownProps.isTest && currentQuestionId in state.answers.dbAnswers) {
        isRightAnswerDisplayed = true;
    }
    return {
        question: state.questions[state.controls.currentQuestionId],
        prevQuestionId: state.controls.prevQuestionId,
        isRightAnswerDisplayed,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    initAnswers,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConsecutiveQuestionGroup);
