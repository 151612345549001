import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const AttentionHollow = props => (<SvgIcon { ...props }>
    <path fill="currentColor" d="M8 0c4.415 0 8 3.585 8 8s-3.585 8-8 8-8-3.585-8-8 3.585-8 8-8zm0 2c3.311 0 6 2.689 6 6s-2.689 6-6 6-6-2.689-6-6 2.689-6 6-6zm.999 9a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm0-6a1 1 0 0 0-2 0v3a1 1 0 0 0 2 0V5z" />
</SvgIcon>);
/* eslint-enable max-len */

AttentionHollow.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default AttentionHollow;
