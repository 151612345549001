import React from 'react';
import PropTypes from 'prop-types';
import { Record as BasicRecord } from './Record';
import { Album as AlbumPreview } from '../../albums/components/Album';


export class AlbumRecord extends React.Component {
    static propTypes = {
        action_text: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        photos_count: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        userData: PropTypes.shape({
            username: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            id: PropTypes.number,
        }).isRequired,
        authUserData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        cover_url: PropTypes.string.isRequired,
        album_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        const album = {
            id: this.props.id,
            title: this.props.title,
            cover: this.props.cover_url,
            url: this.props.album_url,
            count: this.props.photos_count,
            user: {
                username: this.props.userData.username,
                id: this.props.userData.id,
                fullName: `${this.props.userData.first_name} ${this.props.userData.last_name}`,
            },
        };
        const recordBody = (
            <div className="timeline__single-album-container">
                <AlbumPreview canEdit={ false } album={ album } inRouter={ false } />
            </div>
        );
        return (
            <BasicRecord
                recordBody={ recordBody }
                collapsed={ false }
                hide_comments
                hideLikes
                { ...this.props }
            />
        );
    }
}
