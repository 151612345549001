import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CalendarFooter from './CalendarFooter';
import EventGrid from './EventGrid';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';
import { SUGGESTION_SELECT_VALUE_SHAPE } from '../../user_suggestions/constants';
import getCentrifugeConnection from '../../centrifuge';
import {
    centrifugeDelete,
    centrifugeEdit,
    centrifugeJoin,
    centrifugeLeave,
    initialLoadNameGroups,
    loadCalendar,
    modalClose,
    modalOpen,
} from '../actions';
import {
    MODAL_COPY,
    MODAL_CREATE,
    MODAL_DELETE,
    MODAL_EDIT,
    MODAL_NOTIFY,
    MODAL_SUBSCRIBE,
} from '../constants/modalTypes';
import CalendarToolbar from './CalendarToolbar';
import DeleteConfirm from './modals/DeleteConfirm';
import RemindConfirm from './modals/RemindConfirm';
import EventEditModal from './modals/EventEditModal';
import SubscribeModal from './modals/SubscribeModal';
import EventCopyModal from './modals/EventCopyModal';
import EventCreateModal from './modals/EventCreateModal';
import { EVENT_SHAPE } from '../shapes';
import { BLOG_CTYPE, DISCOUNT_CTYPE } from '../constants/ctypes';
import { GRID_DEFAULT_GROUP_NAME, NAME_GROUP_COLUMN_HEIGHT } from '../constants/defaults';
import { groupEventsByName } from '../reducers';
import TextEllipsisTooltip from '../../common/components/TextEllipsisTooltip';
import { projectFeaturesSelector } from '../../common/selectors';

const styles = {
    root: {
        margin: '12px 0 12px 0',
    },
    calendarToolbar: {
        padding: '12px 0',
    },
    columnsContainer: {
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        borderTop: '1px solid rgba(0,0,0,0.08)',
    },
    nameGroup: {
        fontSize: '17px',
        lineHeight: '36px',
        fontWeight: 'bold',
    },
};

class CalendarComponent extends React.Component {
    static propTypes = {
        objectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        contentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        user: SUGGESTION_SELECT_VALUE_SHAPE,
        isGroupEventsByName: PropTypes.bool.isRequired,
        // Канал для центрифуги
        centrifugeChannels: PropTypes.string,
        eventMap: PropTypes.instanceOf(Map).isRequired,
        isAnyMeeting: PropTypes.bool.isRequired,

        openedModal: PropTypes.shape({
            type: PropTypes.string,
            event: EVENT_SHAPE,
        }).isRequired,

        centrifugeJoin: PropTypes.func.isRequired,
        centrifugeEdit: PropTypes.func.isRequired,
        centrifugeDelete: PropTypes.func.isRequired,
        centrifugeLeave: PropTypes.func.isRequired,
        initialLoad: PropTypes.func.isRequired,
        onModalClose: PropTypes.func.isRequired,
        projectFeatures: PropTypes.shape({
            coolendarSmsNotifications: PropTypes.bool,
            coolendarExternalCalendars: PropTypes.bool,
        }).isRequired,
    };

    static defaultProps = {
        objectId: null,
        contentType: null,
        user: {},
        timeInterval: [8, 22, 15],
        duration: [15, 600, 15],
        centrifugeChannels: false,
        canChangeOwner: false,
    };

    constructor(props) {
        super(props);

        this.eventGridGroupsCache = {};
    }

    componentDidMount() {
        // todo add COLUMN_MAX_ELEMENT to props
        // page is 21 item, there is an least 1 + COLUMN_MAX_ELEMENT items per column
        // in case of COLUMN_MAX_ELEMENT == 12, its necessary to load 2 pages to fill 3 columns
        if (this.props.isAnyMeeting || this.props.contentType === BLOG_CTYPE || this.props.contentType === DISCOUNT_CTYPE) {
            this.props.initialLoad(this.props.contentType, this.props.objectId);
        }
        if (this.props.centrifugeChannels) {
            const centrifuge = getCentrifugeConnection();
            centrifuge.subscribe(this.props.centrifugeChannels, (message) => {
                const messageData = objCamelFromSnake(message.data.data);
                switch (message.data.key) {
                    case 'join':
                        this.props.centrifugeJoin(messageData, this.props.user);
                        break;
                    case 'edit':
                        this.props.centrifugeEdit(messageData, this.props.user);
                        break;
                    case 'delete':
                        this.props.centrifugeDelete(messageData, this.props.user);
                        break;
                    case 'leave':
                        this.props.centrifugeLeave(messageData, this.props.user);
                        break;
                    default:
                        break;
                }
            });
        }
    }

    getModal = () => {
        const modalEvent = this.props.openedModal.event;
        switch (this.props.openedModal.type) {
            case (MODAL_DELETE):
                return (
                    <DeleteConfirm
                        event={ modalEvent }
                        onRequestClose={ this.props.onModalClose }
                    />
                );
            case (MODAL_NOTIFY):
                return (
                    <RemindConfirm
                        event={ modalEvent }
                        onRequestClose={ this.props.onModalClose }
                    />
                );
            case (MODAL_CREATE):
                return (
                    <EventCreateModal />
                );
            case (MODAL_EDIT):
                return (
                    <EventEditModal event={ modalEvent } />
                );
            case (MODAL_COPY):
                return (
                    <EventCopyModal event={ modalEvent } />
                );
            case (MODAL_SUBSCRIBE):
                return (
                    <SubscribeModal
                        event={ modalEvent }
                        userId={ this.props.user.value }
                        onClose={ this.props.onModalClose }
                        smsNotificationsEnabled={ this.props.projectFeatures.coolendarSmsNotifications }
                        externalCalendarsEnabled={ this.props.projectFeatures.coolendarExternalCalendars }
                    />
                );
            default:
                return null;
        }
    };

    getEventGridNameGroups = () => {
        if (
            !this.eventGridGroupsCache.cachedValue
            ||
            (this.eventGridGroupsCache.isGroupEventsByName !== this.props.isGroupEventsByName)
            ||
            (this.eventGridGroupsCache.eventMap !== this.props.eventMap)
        ) {
            const eventArr = Array.from(this.props.eventMap.values());
            let cachedValue;
            if (this.props.isGroupEventsByName) {
                cachedValue = groupEventsByName(eventArr);
            } else {
                cachedValue = { [GRID_DEFAULT_GROUP_NAME]: eventArr };
            }

            this.eventGridGroupsCache = {
                cachedValue,
                eventMap: this.props.eventMap,
                isGroupEventsByName: this.props.isGroupEventsByName,
            };
        }
        return this.eventGridGroupsCache.cachedValue;
    };

    render() {
        if (!this.props.eventMap.size) {
            return null;
        }
        return (
            <div
                style={ styles.root }
            >
                <CalendarToolbar
                    styles={ styles.calendarToolbar }
                />
                { Object.entries(this.getEventGridNameGroups()).map(([groupName, groupEvents]) => (
                    <React.Fragment key={ groupName }>
                        { this.props.isGroupEventsByName &&
                            <TextEllipsisTooltip
                                style={ styles.nameGroup }
                                text={ groupName }
                            />
                        }
                        <EventGrid
                            gridGroupName={ groupName }
                            eventList={ groupEvents }
                            showEventLabel={ !this.props.isGroupEventsByName }
                            columnMaxElements={ this.props.isGroupEventsByName ? NAME_GROUP_COLUMN_HEIGHT : undefined }
                            styleColumnsContainer={ this.props.isGroupEventsByName ? {} : styles.columnsContainer }
                        />
                    </React.Fragment>
                ))}
                <CalendarFooter />
                { this.getModal() }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    ...bindActionCreators({
        centrifugeJoin,
        centrifugeEdit,
        centrifugeDelete,
        centrifugeLeave,
        modalOpen,
        onModalClose: modalClose,
    }, dispatch),
    initialLoad: (contentType, objectId) => dispatch(
        ownProps.isGroupEventsByName
            ? initialLoadNameGroups(contentType, objectId)
            : loadCalendar(contentType, objectId, null, false, GRID_DEFAULT_GROUP_NAME),
    ),
});

const mapStateToProps = state => ({
    ...state.calendarConfig,
    eventMap: state.eventMap,
    openedModal: state.openedModal,
    footerEvent: state.footerEvent,
    projectFeatures: projectFeaturesSelector(state),
});

const Calendar = connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);

export default Calendar;
