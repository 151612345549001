import React from 'react';
import { A } from 'intdev-ui';
import classnames from 'classnames';

import { Record as BasicRecord } from '../../../timeline/components/Record';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer';
import { Opengraph } from '../../../opengraph_tags/components/Opengraph';
import { getPublicMicropostUrl } from '../../apiUrls';
import { checkCanEdit } from '../../helpers';
import {
    micropostTimelineRecordProps,
    micropostTimelineRecordPropDefaults,
} from './props';
import { Gallery } from '../Gallery';
import { visibilityOptions } from '../../constants';
import { Persons } from '../../../common/components/icons';
import { isMobile } from '@/common/mediaQueries';

const shortPostLength = 93;
const collapseAfterImgCnt = 11;
const collapseAfterTextLen = 1700;

const visIcons = {
    subscribers: Persons,
};

export class Record extends React.Component {
    static propTypes = micropostTimelineRecordProps;
    static defaultProps = micropostTimelineRecordPropDefaults;

    state = {
        isOpened: false,
    };

    getTextLength = () => {
        if (!this.props.timeline_text) {
            return 0;
        }
        const div = document.createElement('div');
        div.innerHTML = this.props.timeline_text;
        return (div.innerText || div.textContent || '').length;
    };

    renderText = () => {
        if (!this.props.timeline_text) {
            return null;
        }
        if (this.props.sticker) {
            return (
                <div
                    className={
                        classnames({
                            'timeline-record-sticker-caption': true,
                            'timeline-record-sticker-short-caption': this.props.timeline_text.length <= shortPostLength,
                        })
                    }
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: this.props.timeline_text } }
                />
            );
        }
        return (
            <div
                className="timeline-record-text-content"
                key="mictopost-text-content"
                dangerouslySetInnerHTML={ { __html: this.props.timeline_text } } // eslint-disable-line react/no-danger
            />
        );
    }

    render() {
        const toUser = this.props.to_user ? (
            <div
                key="micropost-text-to-user"
                style={ { flexBasis: '100%', marginBottom: 10 } }
            >
                <span>
                    { this.props.action_text ?
                        `${this.props.action_text[0].toUpperCase()}${this.props.action_text.slice(1)} `
                        : null }
                </span>
                <UserTooltipContainer username={ this.props.to_user.username } wrapper="span">
                    <A href={ `/users/${this.props.to_user.username}/` } className="inline-link">
                        { `${this.props.to_user.name}` }
                    </A>
                </UserTooltipContainer>
            </div>
        ) : null;

        const text = this.renderText();
        const opengraph = this.props.timeline_text ? (
            <Opengraph
                text={ this.props.timeline_text }
            />
        ) : null;

        const VisibilityIcon = Object.prototype.hasOwnProperty.call(visIcons, this.props.visibility) ?
            visIcons[this.props.visibility] :
            null;
        const visibility = VisibilityIcon ? (
            <div
                className="timeline-micropost-visibility-cnt"
            >
                <VisibilityIcon
                    fill="#999"
                    color="#999"
                    className="timeline-micropost-visibility-icon"
                    style={ { fill: '#999' } }
                />
                { visibilityOptions[this.props.visibility].toLowerCase() }
            </div>
        ) : null;

        /* eslint-disable react/no-danger */
        const content = this.props.sticker_url ? (
            <div
                className="timeline-record-sticker-cnt"
                key="micropost-sticker"
            >
                { !isMobile && visibility }
                { toUser }
                { text }
                <div
                    className="timeline-record-sticker"
                >
                    <a href={ `/stickers/${this.props.pack_id}/` }>
                        <img
                            className="sticker-image"
                            src={ this.props.sticker_url }
                            alt="Стикер"
                        />
                    </a>
                </div>

            </div>
        ) : (
            <div>
                { toUser }
                { text }
            </div>
        );
        /* eslint-enable react/no-danger */

        const recordHead = (
            <div className="timeline-text" style={ { wordWrap: 'break-word', overflowWrap: 'break-word' } }>
                <div className="timeline-cropper cropped-text">
                    <div className="text-container">
                        { content }
                        <Gallery images={ this.props.images } />
                    </div>
                </div>
            </div>
        );

        const userCanEditOwnPost = (this.props.userData.username === this.props.authUserData.username) &&
            this.props.date &&
            checkCanEdit(this.props.date);
        const editUrl = userCanEditOwnPost || this.props.canEditAnyMicropost ?
            getPublicMicropostUrl(this.props.id, true) :
            null;

        const textLength = this.getTextLength();

        // collapse post if there are more than 'collapseAfterImgCnt' images or
        // if text len is more than 'collapseAfterTextLen' chars or
        // if text len is more than 'collapseAfterTextLen' / 2 chars and there is at least one image or
        // if text len is more than 'collapseAfterTextLen' / 2 chars and there is a sticker attached
        const imageCount = (this.props.images && this.props.images.length) || 0;
        const collapsed = (imageCount && (imageCount > collapseAfterImgCnt)) ||
              (textLength > collapseAfterTextLen) ||
              ((textLength > (collapseAfterTextLen / 2)) && (imageCount && imageCount > 1)) ||
              ((textLength > (collapseAfterTextLen / 2)) && this.props.sticker_url);

        return (
            <BasicRecord
                recordHead={ recordHead }
                collapsed={ collapsed }
                recordBody={ opengraph }
                rightBlock={ visibility }
                { ...this.props }
                editUrl={ editUrl }
            />
        );
    }
}

export { micropostTimelineRecordProps, micropostTimelineRecordPropDefaults };
