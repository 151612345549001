import { photoDelete } from '@/blog/constants/apiUrls';
import { ACTION_TYPES } from '@/blog/constants/actionTypes';
import { CALL_API } from '../../common/constants/actionTypes';
import { showSuccessNotification } from '@/common/helpers/showNotification';


export const loadAttachments = (contentType, objectId) => (dispatch, getState) => dispatch({
    type: CALL_API,
    endpoint: getState().apiUrls.attachmentListUrl(contentType, objectId),
    types: [
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_START,
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_SUCCESS,
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FAILURE,
    ],
});

export const loadAttachmentsForward = (contentType, objectId) => (dispatch, getState) => dispatch({
    type: CALL_API,
    endpoint: getState().apiUrls.attachmentListUrl(contentType, objectId),
    body: {
        ...(getState().publicationAttachments.attachmentMinId
            ? { from_id: getState().publicationAttachments.attachmentMinId }
            : null
        ),
    },
    types: [
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_START,
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_SUCCESS,
        ACTION_TYPES.BLOG_LOAD_ATTACHMENTS_FORWARD_FAILURE,
    ],
});

export const deleteAttachments = attachmentId => (dispatch, getState) => dispatch({
    type: CALL_API,
    endpoint: getState().apiUrls.deleteAttachmentUrl(attachmentId),
    method: 'delete',
    additionalData: {
        attachmentId,
    },
    types: [
        ACTION_TYPES.BLOG_DELETE_ATTACHMENTS_START,
        {
            type: ACTION_TYPES.BLOG_DELETE_ATTACHMENTS_SUCCESS,
            callback: () => showSuccessNotification('Успешно удалено'),
        },
        ACTION_TYPES.BLOG_DELETE_ATTACHMENTS_FAILURE,
    ],
});


export const attachmentImageClick = attachmentId => ({
    type: ACTION_TYPES.BLOG_ATTACHMENT_IMAGE_CLICK,
    data: attachmentId,
});


export const onCarouselClose = () => ({
    type: ACTION_TYPES.BLOG_ATTACHMENT_CAROUSEL_CLOSE,
});

export const removePhoto = (albumId, pk, photoListIndex, showedPhotoIndex) => ({
    type: CALL_API,
    endpoint: photoDelete(albumId, pk),
    types: [
        ACTION_TYPES.BLOG_REMOVE_PHOTO_START,
        ACTION_TYPES.BLOG_REMOVE_PHOTO_SUCCESS,
        ACTION_TYPES.BLOG_REMOVE_PHOTO_ERROR,
    ],
    method: 'POST',
    additionalData: {
        photoListIndex,
        showedPhotoIndex,
        pk,
        albumId,
    },
});

export const updateLikes = photos => (
    {
        type: ACTION_TYPES.BLOG_UPDATE_LIKES,
        additionalData: { photos },
    }
);
