import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel, IconButton, Spinner } from 'intdev-ui';
import classnames from 'classnames';

import { CloseButton } from '../buttons';
import { attachmentProp } from '../../props';

import './styles.css';
import { isMobile } from '@/common/mediaQueries';

export class Gallery extends Component {
    static propTypes = {
        containerStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        images: PropTypes.arrayOf(attachmentProp),
        onRemoveImage: PropTypes.func,
        mode: PropTypes.oneOf(['default', 'attachment']),
    }

    static defaultProps = {
        containerStyle: {},
        onRemoveImage: null,
        images: [],
        mode: 'default',
    }

    state = {
        carouselOpen: false,
        carouselIndex: 0,
    }

    getImageKey = image => `micropost-image-${image.id}`;

    toggleCarousel = (index = 0) => {
        this.setState(state => ({
            carouselOpen: !state.carouselOpen,
            carouselIndex: index,
        }));
    }

    processImages = () => {
        if (this.props.images.length === 1 &&
            this.props.images[0].url &&
            this.props.mode !== 'attachment') {
            return [
                this.renderSingleImage(this.props.images[0], 0),
            ];
        }
        return this.props.images.map((image, index) => {
            if (!image.url) {
                return this.renderPreview(image);
            }
            return this.renderImage(image, index);
        });
    }

    handleCarouselClose = () => this.setState({ carouselOpen: false });

    renderImage = (image, carouselIndex) => (
        <div
            key={ this.getImageKey(image) }
                className={ classnames({
                    'micropost-gallery-image-container': true,
                    'micropost-gallery-image-container-attachment': this.props.mode === 'attachment',
                }) }
        >
            <div
                className={ classnames({
                    'micropost-gallery-image': true,
                    'micropost-gallery-image-attachment': this.props.mode === 'attachment',
                }) }
                style={ { backgroundImage: `url(${image.url})` } }
                data-image-url={ image.url }
                onClick={ () => this.toggleCarousel(carouselIndex) }
            />
            { this.renderRemoveButton(image) }
        </div>
    )

    renderRemoveButton = (image) => {
        if (this.props.onRemoveImage) {
            return (
                <div className="micropost-gallery-image-rm">
                    <IconButton
                        onClick={ () => this.props.onRemoveImage(image.id) }
                        style={ { width: 16, height: 16, padding: 0 } }
                        iconStyle={ { width: 12, height: 12, padding: 0, fill: 'white' } }
                    >
                        <CloseButton
                            color="#ececec"
                            hoverColor="white"
                        />
                    </IconButton>
                </div>
            );
        }
        return null;
    }

    renderPreview = image => (
        <div
            key={ this.getImageKey(image) }
            className = { classnames({
                'micropost-gallery-image-container': true,
                'micropost-gallery-image-preview': true,
                'micropost-gallery-image-container-attachment': this.props.mode === 'attachment',
            }) }
        >
            <div
                className={ classnames({
                    'micropost-gallery-image': true,
                    'micropost-gallery-image-attachment': this.props.mode === 'attachment',
                }) }
            >
                <div className="micropost-gallery-image-spinner">
                    <Spinner size={ 42 } color="white" />
                </div>
                <div className="micropost-gallery-image-placeholder">
                    { image.name || image.id }
                </div>
            </div>
        </div>
    )

    renderSingleImage = image => (
        <div
            key={ this.getImageKey(image) }
            className="micropost-gallery-image-container"
        >
            <div
                className="micropost-gallery-image micropost-gallery-image-single"
                onClick={ () => this.toggleCarousel(0) }
            >
                <img src={ image.url } />
                { this.renderRemoveButton(image) }
            </div>
        </div>
    )

    render() {
        const imageCount = (this.props.images && this.props.images.length) || 0;
        if (!imageCount) {
            return null;
        }

        const images = this.processImages();

        const content = imageCount === 1 ? (
            <div className="micropost-gallery-single-image">
                { images[0] }
            </div>
        ) : (
            <div className="micropost-gallery">
                <div className="micropost-gallery-top">
                    <div className="micropost-gallery-top-left">
                        { images[0] }
                    </div>
                    <div className="micropost-gallery-top-right">
                        { images.slice(1, 5) }
                    </div>
                </div>
                <div className="micropost-gallery-bottom">
                    { images.slice(5) }
                </div>
            </div>
        );

        // TODO: handle images without url maintaining indices
        const carouselImages = this.props.images.filter(
            image => image.url || image.large_url,
        ).map(
            image => ({ url: image.large_url || image.url }),
        );
        const carousel = carouselImages.length ? (
            <Carousel
                open={ this.state.carouselOpen }
                images={ carouselImages }
                startIndex={ this.state.carouselIndex }
                onRequestClose={ this.handleCarouselClose }
            />
        ) : null;

        return (
            <div
                style={ isMobile ? {} : this.props.containerStyle }
                className="micropost-gallery-container"
            >
                { this.props.mode === 'attachment' ?
                    <div className="micropost-gallery-container-attachment">{ images }</div> :
                content }
                { carousel }
            </div>
        );
    }
}
