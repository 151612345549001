import React from 'react';
import { SvgIcon } from 'intdev-ui';

export const Plus = props => (
    <SvgIcon { ...props }>
        <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M8 0c4.415 0 8 3.585 8 8s-3.585 8-8 8-8-3.585-8-8 3.585-8 8-8zm1 7V4.6a.6.6 0 0 0-.6-.6h-.8a.6.6 0 0 0-.6.6V7H4.6a.6.6 0 0 0-.6.6v.8a.6.6 0 0 0 .6.6H7v2.4a.6.6 0 0 0 .6.6h.8a.6.6 0 0 0 .6-.6V9h2.4a.6.6 0 0 0 .6-.6v-.8a.6.6 0 0 0-.6-.6H9z" />
    </SvgIcon>
);

Plus.defaultProps = { viewBox: '0 0 16 16' };
