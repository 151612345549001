import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableRowColumn, Checkbox } from 'intdev-ui';
import { connect } from 'react-redux';
import classnames from 'classnames';

const styles = {
    inputs: {
        whiteSpace: 'normal',
        textAlign: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        width: '80px',
    },
    questions: {
        width: '168px',
        whiteSpace: 'normal',
        textAlign: 'center',
    },
};

class LineMultiChoiceQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.shape({
            text: PropTypes.string,
            id: PropTypes.number,
            limit_answers: PropTypes.number,
            strict_answer: PropTypes.bool,
        }).isRequired,
        choiceList: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })),
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.number).isRequired,
        isQuestionWithError: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        choiceList: [],
    };

    handleCheckboxChange = (event) => {
        const input = event.target;
        const value = parseInt(input.value, 10);
        const currentValue = [...this.props.value];
        if (input.checked && currentValue.indexOf(value) === -1) {
            currentValue.push(value);
        } else if (!input.checked) {
            const index = currentValue.indexOf(value);
            if (index > -1) {
                currentValue.splice(index, 1);
            }
        }
        if (this.props.question.limit_answers && currentValue.length > this.props.question.limit_answers) {
            currentValue.shift();
        }
        this.props.onChange(this.props.question.id, currentValue);
    };

    render() {
        const choicesInputs = this.props.choiceList.map(choice => (
            <TableRowColumn style={ styles.inputs } key={ choice.id }>
                <Checkbox
                    value={ choice.id }
                    checked={ this.props.value.indexOf(choice.id) !== -1 }
                    onChange={ this.handleCheckboxChange }
                    name={ `question${this.props.question.id}` }
                />
            </TableRowColumn>
        ));

        const classNames = classnames({
            'quiz-detail__question': true,
            'quiz-detail__text_size_s': true,
            'strict-question': this.props.isQuestionWithError,
            'quiz-detail__question-table': true,
        });

        return (
            <TableRow>
                <TableRowColumn style={ styles.questions }>
                    <div className={ classNames }>
                        { this.props.question.strict_answer && <span className="quiz-detail__strict-icon">*</span> }
                        <div dangerouslySetInnerHTML={ { __html: this.props.question.text } } />
                    </div>
                </TableRowColumn>
                { choicesInputs }
            </TableRow>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id] || {};
    let answerValue = answer.value;
    if (!(answerValue instanceof Array)) {
        answerValue = (answer.value) ? [answer.value] : [];
    }
    const isQuestionWithError = (state.questionsWithError.indexOf(ownProps.question.id) !== -1);
    return {
        value: answerValue,
        isQuestionWithError,
    };
};

export default connect(mapStateToProps)(LineMultiChoiceQuestion);
