import React from 'react';

export const Wheel = () => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.4766 15.5C14.1334 15.5 15.4766 14.1569 15.4766 12.5C15.4766 10.8431 14.1334 9.5 12.4766 9.5C10.8197 9.5 9.47656 10.8431 9.47656 12.5C9.47656 14.1569 10.8197 15.5 12.4766 15.5Z"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.47656 13.3814V11.6214C2.47656 10.5814 3.32656 9.7214 4.37656 9.7214C6.18656 9.7214 6.92656 8.4414 6.01656 6.8714C5.49656 5.9714 5.80656 4.8014 6.71656 4.2814L8.44656 3.2914C9.23656 2.8214 10.2566 3.1014 10.7266 3.8914L10.8366 4.0814C11.7366 5.6514 13.2166 5.6514 14.1266 4.0814L14.2366 3.8914C14.7066 3.1014 15.7266 2.8214 16.5166 3.2914L18.2466 4.2814C19.1566 4.8014 19.4666 5.9714 18.9466 6.8714C18.0366 8.4414 18.7766 9.7214 20.5866 9.7214C21.6266 9.7214 22.4866 10.5714 22.4866 11.6214V13.3814C22.4866 14.4214 21.6366 15.2814 20.5866 15.2814C18.7766 15.2814 18.0366 16.5614 18.9466 18.1314C19.4666 19.0414 19.1566 20.2014 18.2466 20.7214L16.5166 21.7114C15.7266 22.1814 14.7066 21.9014 14.2366 21.1114L14.1266 20.9214C13.2266 19.3514 11.7466 19.3514 10.8366 20.9214L10.7266 21.1114C10.2566 21.9014 9.23656 22.1814 8.44656 21.7114L6.71656 20.7214C5.80656 20.2014 5.49656 19.0314 6.01656 18.1314C6.92656 16.5614 6.18656 15.2814 4.37656 15.2814C3.32656 15.2814 2.47656 14.4214 2.47656 13.3814Z"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
