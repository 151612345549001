import { Button, IUIPropTypes } from 'intdev-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isMobile } from '@/common/mediaQueries';
import { AttachmentDialog } from './AttachmentDialog';
import { getAddAttachmentUrl } from '../constants/apiUrls';
import { loadAttachments } from '../actions';

class AttachmentButtonComponent extends Component {
    static propTypes = {
        contentType: PropTypes.string,
        objectId: PropTypes.string,
        label: PropTypes.string,
        style: IUIPropTypes.style,
        loadAttachments: PropTypes.func.isRequired,
    };

    static defaultProps = {
        contentType: null,
        objectId: null,
        style: {},
        label: 'Прикрепить файл',
    };

    state = {
        isDialogOpen: false,
    };

    handleClick = () => this.setState({
        isDialogOpen: true,
    });

    handleCloseDialog = () => this.setState({
        isDialogOpen: false,
    });

    handleEdit = () => {
        this.setState({ isDialogOpen: false });
        this.props.loadAttachments();
    };

    render() {
        return (
            <div style={ this.props.style }>
                <Button fullWidth={ isMobile } label={ this.props.label } onClick={ this.handleClick } />
                {this.state.isDialogOpen &&
                    <AttachmentDialog
                        isDialogOpen
                        // eslint-disable-next-line react/jsx-handler-names
                        handleCloseDialog={ this.handleCloseDialog }
                        // eslint-disable-next-line react/jsx-handler-names
                        url={ getAddAttachmentUrl(this.props.contentType, this.props.objectId) }
                        onEdit={ this.handleEdit }
                        name=""
                        attachmentFile={ null }
                    />
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadAttachments: () => dispatch(loadAttachments(ownProps.contentType, ownProps.objectId)),
});

const AttachmentButton = connect(null, mapDispatchToProps)(AttachmentButtonComponent);

export default AttachmentButton;
