export const ACTION_TYPES = {
    RECEIVE_NOTIFICATIONS: 'notificationSystem/RECEIVE_NOTIFICATIONS',
    AUTH_ERROR_VISIBLE: '@@authError/VISIBLE',
    SHOW_HEADER: 'WE_HEADER.SHOW_HEADER',
    HIDE_HEADER: 'WE_HEADER.HIDE_HEADER',

    GET_ACTUAL_THEME_START: 'WE_HEADER.GET_ACTUAL_THEME_START',
    GET_ACTUAL_THEME_FINISH: 'WE_HEADER.GET_ACTUAL_THEME_FINISH',
    GET_ACTUAL_THEME_FAIL: 'WE_HEADER.GET_ACTUAL_THEME_FAIL',

    GET_HEADER_MENU_START: 'WE_HEADER.GET_HEADER_MENU_START',
    GET_HEADER_MENU_FINISH: 'WE_HEADER.GET_HEADER_MENU_FINISH',
    GET_HEADER_MENU_FAIL: 'WE_HEADER.GET_HEADER_MENU_FAIL',
};
