import { JSON_FORMAT, FORMDATA } from '../constants/ajaxBodyTypes';
import { authErrorMessageShow } from './authErrorMessageShow';

const csrfTokenRe = /csrftoken=([^ ;]+)/;
const hasOwnProp = (o, prop) => typeof o === 'object' && o !== null && Object.prototype.hasOwnProperty.call(o, prop);

function getCsrfToken() {
    const match = document.cookie.match(csrfTokenRe);
    if (!match) {
        return null;
    }
    return match[1];
}

function finalizeSuccessfulRequest(data, response, isJson, args) {
    if (args.success) {
        args.success(data, response, isJson);
    }
}

export function fetchUrl(args) {
    /*
    Arguments:
    {
        url: string,
        method: 'get' || 'post' || null,
        body: object || null,
        success: function(data, response, isJson) || null,
        error: function(response) || null,
        noCsrf: true || false || null,
        bodyFormat: json|formdata|null
        body: string
    }
    */
    if (!args.url) {
        return null;
    }

    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');

    const requestOptions = {
        method: args.method ? args.method.toLowerCase() : 'get',
        credentials: 'same-origin',
        headers,
    };

    if (requestOptions.method === 'post') {
        if (args.bodyFormat === FORMDATA) {
            const requestBody = new FormData();
            const addValueInFormData = (key, value) => requestBody.append(key, value);
            for (const key in args.body) {
                if (Array.isArray(args.body[key])) {
                    for (const arrayValue of args.body[key]) {
                        if (hasOwnProp(arrayValue, 'value')) {
                            addValueInFormData(key, arrayValue.value);
                        } else {
                            addValueInFormData(key, arrayValue);
                        }
                    }
                } else {
                    const v = args.body[key];
                    if (hasOwnProp(v, 'value')) {
                        addValueInFormData(key, v.value);
                    } else {
                        addValueInFormData(key, v);
                    }
                }
            }
            requestOptions.body = requestBody;
        } else if (args.bodyFormat === JSON_FORMAT) {
            requestOptions.body = JSON.stringify(args.body);
        } else {
            requestOptions.body = args.body;
        }

        if (!args.noCsrf) {
            requestOptions.headers.append('X-CSRFToken', getCsrfToken());
        }
    } else if (requestOptions.method === 'delete') {
        if (!args.noCsrf) {
            requestOptions.headers.append('X-CSRFToken', getCsrfToken());
        }
    }

    const request = new Request(args.url, requestOptions);
    const resultPromise = fetch(request);
    resultPromise.then(
        (response) => {
            if (response.status === 401) {
                authErrorMessageShow();
            }

            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    response.json().then(
                        data => finalizeSuccessfulRequest(data, response, true, args),
                    );
                } else {
                    response.text().then(
                        data => finalizeSuccessfulRequest(data, response, false, args),
                    );
                }
                return;
            }
            if (args.error) {
                args.error(response);
            }
        },
    ).catch((error) => {
        if (args.error) {
            args.error(error);
        }
    });
    return resultPromise;
}
