/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';
import { isMobile } from '@/common/mediaQueries';
import LikesCounter from './counter_form/LikesCounter';
import { EmojiForm } from './emoji_form/EmojiForm';
import { generateUuid } from '../common/uuid';
import { urls } from './constants/apiUrls';
import { callApi } from '../common/middlewares/apiMiddleware';
import { dispatchMetricsEvent } from '../common/helpers/metrics';
import './styles/Likes.css';
import { countShape } from './constants/propTypes';
import { emojiTooltipPositions } from './constants';

/* global getCentrifugeConnection */

const centrifugeSubscriptions = {};


export default class LikesBlock extends React.Component {
    static propTypes = {
        contentTypeId: PropTypes.PropTypes.number.isRequired,
        objectId: PropTypes.PropTypes.number.isRequired,
        count: countShape,
        isLiked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        style: IUIPropTypes.style,
        textPrefix: PropTypes.bool,
        updateLikes: PropTypes.func,
        // eslint-disable-next-line react/no-unused-prop-types
        uuid: PropTypes.string,
        color: PropTypes.string,
        emojiTooltipPosition: PropTypes.oneOf(emojiTooltipPositions),
        inTimeline: PropTypes.bool,
        collapsed: PropTypes.bool,
    };

    static defaultProps = {
        count: {},
        isLiked: undefined,
        style: null,
        textPrefix: true,
        updateLikes: null,
        color: isMobile ? 'var(--color-text-mobile)' : 'var(--color-link)',
        uuid: null,
        emojiTooltipPosition: null,
        inTimeline: false,
        collapsed: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            count: props.count,
            isLiked: props.isLiked,
            likeTooltipInfo: {},
        };

        this.uuid = this.getUuid(props);
        this.subscription = null;
    }

    componentDidMount() {
        this.subscribeCentrifuge();

        this.userInfo = {
            id: window.USER_PROFILE.id || window._AUTH_USER_ID,
            url: window.USER_PROFILE.url || window._AUTH_USER_URL,
            avatar: window.USER_PROFILE.avatarSmall || window._AUTH_USER_AVATAR_SMALL,
            first_name: (window.USER_PROFILE.fullname || window._AUTH_USER_FULLNAME).split(' ')[0],
        };
    }

    componentDidUpdate = (prevProps) => {
        const {
            contentTypeId,
            objectId,
            count,
            isLiked,
            uuid,
        } = this.props;

        const objectChanged = (
            contentTypeId !== prevProps.contentTypeId
            || objectId !== prevProps.objectId
            || uuid !== prevProps.uuid
        );
        const countChanged = count !== prevProps.count;
        const isLikedChanged = isLiked !== prevProps.isLiked;
        if (countChanged || objectChanged || isLikedChanged) {
            if (objectChanged) {
                this.uuid = this.getUuid(this.props);
                this.unsubscribeCentrifuge();
                this.subscribeCentrifuge();
            }

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ count, isLiked });
        } else {
            this.checkSubscription();
        }
    };

    componentWillUnmount() {
        this.unsubscribeCentrifuge();
    }

    getUuid = props => props.uuid || generateUuid();

    getChannelName = (props) => {
        const { contentTypeId, objectId } = props;
        return `likes-${contentTypeId}-${objectId}`;
    };

    checkSubscription = () => {
        const channel = this.getChannelName(this.props);
        if (!centrifugeSubscriptions[channel]) {
            // Для случая, когда unmount другого LikesBlock
            // этого же объекта отписался от канала
            this.unsubscribeCentrifuge();
            this.subscribeCentrifuge();
        }
    };

    subscribeCentrifuge = () => {
        this.subscription = null;
        const channel = this.getChannelName(this.props);
        if (centrifugeSubscriptions[channel]) {
            // Обработка ситуации, когда LikesBlock одного объекта
            // на странице более чем в одном месте
            return;
        }
        const centrifugeConnection = getCentrifugeConnection();
        this.subscription = centrifugeConnection.subscribe(
            channel,
            this.handleCentrifugeMessage,
        );
        centrifugeSubscriptions[channel] = true;
    };

    unsubscribeCentrifuge = () => {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription.removeAllListeners();
            delete centrifugeSubscriptions[this.getChannelName(this.props)];
        }
    };

    handleCentrifugeMessage = (message) => {
        if (message.data.key === 'like') {
            // uuid не равны, если лайк от другого юзера или из другой вкладки
            if (this.uuid !== message.data.data.uuid) {
                const { data } = message.data;
                this.insertLike(data, message.uid);
            }
        }
    };

    insertLike = (data, likeCentrifugeUid) => {
        const {
            contentTypeId,
            objectId,
        } = this.props;

        const {
            count,
            isLiked,
        } = this.state;

        const userId = window.USER_PROFILE.id || window._AUTH_USER_ID;
        const fromOtherUser = (
            this.uuid !== data.uuid && (
                !userId // Если userId не указан, считаем что другой юзер
                || userId !== data.user_id
            )
        );

        let actualIsLiked = data.is_liked;
        if (fromOtherUser) {
            actualIsLiked = isLiked;
        }

        if (count[data.emoji] !== undefined) {
            count[data.emoji] = count[data.emoji] > 0
                ? count[data.emoji] + data.operation
                : 0;
        } else {
            count[data.emoji] = 1;
        }
        if (count[data.emoji] === 0) {
            delete count[data.emoji];
        }

        if (data.prev_like !== undefined && data.is_liked !== false) {
            count[data.prev_like] = count[data.prev_like] > 0
                ? count[data.prev_like] - data.operation
                : 0;
            if (count[data.prev_like] === 0) {
                delete count[isLiked];
            }
        }

        this.modifyTooltip(data);
        this.setState({
            count,
            isLiked: actualIsLiked,
        });

        if (this.props.updateLikes) {
            this.props.updateLikes([{
                content_type_id: contentTypeId,
                object_id: objectId,
                count,
                is_liked: actualIsLiked,
                likeCentrifugeUid,
            }]);
        }
    };

    modifyTooltip = (data) => {
        const { likeTooltipInfo } = this.state;

        if (!likeTooltipInfo[data.emoji]) {
            likeTooltipInfo[data.emoji] = { likes: [] };
        }

        if (parseInt(data.operation, 10) > 0) {
            if (likeTooltipInfo[data.emoji].likes) {
                likeTooltipInfo[data.emoji].likes.push(data.like_user_info);
            } else {
                likeTooltipInfo[data.emoji].likes = [data.like_user_info];
            }
        } else {
            const { likes } = likeTooltipInfo[data.emoji];
            if (likes) {
                for (let i = 0; i < likes.length; ++i) {
                    if (likes[i].id === data.user_id) {
                        likeTooltipInfo[data.emoji].likes.splice(i, 1);
                    }
                }
            }
        }
        if (data.prev_like !== undefined) {
            const likeData = likeTooltipInfo[data.prev_like];
            const likes = likeData ? likeData.likes : null;
            if (likes) {
                for (let i = 0; i < likes.length; ++i) {
                    if (likes[i].id === data.user_id) {
                        likeTooltipInfo[data.prev_like].likes.splice(i, 1);
                    }
                }
            }
        }
        if (likeTooltipInfo[data.emoji] === undefined) {
            delete likeTooltipInfo[data.emoji];
        }

        this.setState({ likeTooltipInfo });
    };

    onLikeClick = (emoji) => {
        const {
            contentTypeId,
            objectId,
            inTimeline,
        } = this.props;

        const { isLiked } = this.state;

        let url = urls.likeObject(contentTypeId, objectId, emoji);
        let action = 'like';
        const disliked = parseInt(isLiked, 10) === parseInt(emoji, 10);
        if (disliked) {
            url = urls.dislikeObject(contentTypeId, objectId, emoji);
            action = 'dislike';
        }

        if (inTimeline) {
            dispatchMetricsEvent(`timeline-${action}`);
        }

        const data = {
            emoji,
            is_liked: disliked ? false : parseInt(emoji, 10),
            prev_like: isLiked !== false ? isLiked : undefined,
            operation: disliked ? -1 : 1,
            like_user_info: disliked ? '' : this.userInfo,
            user_id: window.USER_PROFILE.id || window._AUTH_USER_ID,
            uuid: this.uuid,
        };
        this.insertLike(data);
        callApi(url, 'POST', { uuid: this.uuid });
    };

    updateLikeTooltipInfo = (likeTooltipInfo) => {
        this.setState({ likeTooltipInfo });
    };

    render() {
        const {
            contentTypeId,
            objectId,
            textPrefix,
            emojiTooltipPosition,
            color,
            style,
            collapsed,
        } = this.props;

        const {
            count,
            isLiked,
            likeTooltipInfo,
        } = this.state;

        return (
            <div className="like-button-simple" style={ style }>
                <div className="like-form-container">
                    <EmojiForm
                        isLiked={ isLiked }
                        textPrefix={ textPrefix }
                        handleLike={ this.onLikeClick }
                        emojiTooltipPosition={ emojiTooltipPosition }
                    />
                    <LikesCounter
                        count={ count }
                        contentTypeId={ contentTypeId }
                        objectId={ objectId }
                        isLiked={ isLiked }
                        color={ color }
                        tooltipInfo={ likeTooltipInfo }
                        handleLike={ this.onLikeClick }
                        updateLikeTooltipInfo={ this.updateLikeTooltipInfo }
                        collapsed={ collapsed }
                    />
                </div>
            </div>
        );
    }
}
