import moment from 'moment';
import 'moment/locale/tg';
import 'moment/locale/uz';
import PropTypes from 'prop-types';
import React from 'react';
import { combineReducers } from 'redux';
import { A } from 'intdev-ui';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { DelayRender } from '@/common/components/DelayRender';
import { isMobile } from '@/common/mediaQueries';
import MrgIcon from '@/common/components/MrgIcon';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { showSuccessNotification, showErrorNotification } from '@/common/helpers/showNotification';
import { locale } from '@/common/localization/dictionary';
import CommentsBlock from '../../comments/CommentsBlock';
import CommentsLink from '../../comments/CommentsLink';
import LikesBlock from '../../likes/LikesBlock';
import { fetchUrl } from '../../common/helpers/fetchUrl';
import { Settings, shareEditIconStyle } from './Settings';
import { DonateAuthorButton } from '../../store/components/Donation/DonateAuthorButton';
import UserTooltipContainer from '../../common/components/UserTooltipContainer';
import FollowingLikes from './FollowingLikes';
import { pagesUrls } from '../constants';
import { configureStore } from '../../common/helpers/store';
import { calendarReducerMap, loadCalendarConfig } from '../../calendar/reducers';
import { attachmentReducersMap } from '../../blog/reducers';
import PublicationAttachmentsContainer from '../../blog/components/BlogDetail/PublicationAttachmentsContainer';
import PublicationToolbar from '../../blog/components/BlogDetail/PublicationToolbar';
import { CustomProvider } from '../../common/components/CustomProvider';
import BuiltInQuizDetailContainer from '../../quiz/detail/containers/BuiltInQuizDetailContainer';
import { makeStore } from '../../quiz/detail/store';
import { CalendarContainer } from '../../calendar/components/CalendarContainer';
import { Rating } from '../../common/components/icons/Rating';
import { pubStatsPageUrl } from '../../stats/constants';
import RecordAttachments from './attachments/RecordAttachments';
import contentTypes from '../../common/constants/contentTypes';
import { projectFeatures } from '../../common/components/DefaultParams/reducers/projectFeatures';
import { dispatchMetricsEvent } from '../../common/helpers/metrics';


const ALLOWED_DONATE_CTYPES = [contentTypes.BLOG_ENTRY];
const COLLAPSE_TEXT_HEIGHT = 306;

const headerHeight = 60;

const publicationStore = configureStore(combineReducers({
    ...calendarReducerMap,
    ...attachmentReducersMap,
    features: projectFeatures,
}));
const quizStore = makeStore();


const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();
    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset,
    };
};


export class Record extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        action_text: PropTypes.string,
        header: PropTypes.string,
        parent_related_text: PropTypes.string,
        url: PropTypes.string,
        editUrl: PropTypes.string,
        onEditClick: PropTypes.func,
        date: PropTypes.string.isRequired,
        new_comments_count: PropTypes.number.isRequired,
        comments_count: PropTypes.number.isRequired,
        content_type_id: PropTypes.number.isRequired,
        comments_channel: PropTypes.string,
        id: PropTypes.number.isRequired,
        record_id: PropTypes.number.isRequired,
        is_private: PropTypes.bool.isRequired,
        is_liked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        inappropriate_content: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        like_count: PropTypes.shape({}),
        commentsOpen: PropTypes.bool,
        showSettings: PropTypes.bool,
        scrollToRecord: PropTypes.func,
        hide_comments: PropTypes.bool,
        /* eslint-disable-next-line */
        userData: PropTypes.object,
        hideLikes: PropTypes.bool,
        authUserData: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        sidePanelMainBlock: PropTypes.node,
        sidePanelSecondaryBlock: PropTypes.node,
        recordHead: PropTypes.node,
        recordBody: PropTypes.node,
        likesBlock: PropTypes.node,
        handleRecordVisibility: PropTypes.func,
        /* eslint-disable-next-line */
        filterData: PropTypes.func,
        reloadData: PropTypes.func,
        coin_transitions: PropTypes.arrayOf(PropTypes.shape({
            avatar: PropTypes.string,
            first_name: PropTypes.string,
            id: PropTypes.number,
            last_name: PropTypes.string,
            user_id: PropTypes.number,
            username: PropTypes.string,
        })),
        collapsed: PropTypes.bool,
        following_likes: PropTypes.arrayOf(PropTypes.shape({
            username: PropTypes.string,
            full_name: PropTypes.string,
        })),
        canPersonalBlocking: PropTypes.bool,
        showContentSettings: PropTypes.bool,
        removeAllRecordsByUser: PropTypes.func,
        removeRecord: PropTypes.func,
        canDeleteAvatarRecords: PropTypes.bool,
        updated_at: PropTypes.string,
        content_type_name: PropTypes.string.isRequired,
        tag_name: PropTypes.string,
        showAttachments: PropTypes.bool,
        quizzes: PropTypes.arrayOf(PropTypes.number),
        canAddQuiz: PropTypes.bool,
        canEditEvent: PropTypes.bool,
        canManageAttachments: PropTypes.bool,
        canModerateAlbums: PropTypes.bool,
        canEditBanners: PropTypes.bool,
        views_unique_count: PropTypes.number,
        views_total_count: PropTypes.number,
        canViewStatistic: PropTypes.bool,
        contentStyle: PropTypes.shape(),
        attachments_meta: PropTypes.shape({
            blocks: PropTypes.arrayOf(PropTypes.shape({
                ct_id: PropTypes.number,
                object_id: PropTypes.number,
                ct_natural_key: PropTypes.string,
            })),
        }),
        // eslint-disable-next-line react/forbid-prop-types
        rightBlock: PropTypes.object,
        coinsSystemFeatureIsOn: PropTypes.bool,
        onOpenSettings: PropTypes.func,
        type: PropTypes.string,
        isShow: PropTypes.bool,
        has_cut: PropTypes.bool,
        attachmentsLoadDelay: PropTypes.number,
        hideTime: PropTypes.bool,
        canPinRecords: PropTypes.bool,
        is_pinned: PropTypes.bool,
        is_franchisee: PropTypes.bool,
        show_official_response_button: PropTypes.bool,
        currentTabId: PropTypes.number,
    };

    static defaultProps = {
        url: null,
        editUrl: null,
        onEditClick: undefined,
        text: null,
        action_text: '',
        header: '',
        parent_related_text: '',
        commentsOpen: false,
        comments_channel: null,
        scrollToRecord: null,
        hide_comments: false,
        showSettings: false,
        userData: undefined,
        hideLikes: false,
        recordHead: null,
        inappropriate_content: false,
        sidePanelMainBlock: null,
        sidePanelSecondaryBlock: null,
        recordBody: null,
        likesBlock: null,
        like_count: {},
        handleRecordVisibility: undefined,
        filterData: undefined,
        reloadData: undefined,
        coin_transitions: [],
        collapsed: true,
        following_likes: [],
        canPersonalBlocking: false,
        showContentSettings: true,
        removeRecord: () => {},
        removeAllRecordsByUser: () => {},
        canDeleteAvatarRecords: false,
        updated_at: null,
        tag_name: null,
        showAttachments: false,
        quizzes: [],
        canAddQuiz: false,
        canEditEvent: false,
        canManageAttachments: false,
        canModerateAlbums: false,
        canEditBanners: false,
        views_unique_count: null,
        views_total_count: null,
        canViewStatistic: false,
        contentStyle: {},
        attachments_meta: { blocks: [] },
        rightBlock: null,
        coinsSystemFeatureIsOn: false,
        onOpenSettings: undefined,
        type: 'пост',
        isShow: true,
        has_cut: false,
        attachmentsLoadDelay: 0,
        hideTime: false,
        canPinRecords: false,
        is_pinned: false,
        is_franchisee: false,
        show_official_response_button: false,
        currentTabId: null,
    };

    state = {
        showInappropriateContent: false,
        showArrow: false,
        collapsed: this.props.collapsed,
        showComments: false,
        requiresCollapseButton: false,
        isPinned: false,
    };

    componentDidMount = () => {
        if (this.props.collapsed) {
            this.checkHeight();
            this.watchImagesLoading();
        }
        this.setState({ isPinned: this.props.is_pinned });
    };

    watchImagesLoading = () => {
        for (const el of [this.recordBodyContainer, this.recordHeadContainer]) {
            if (el) {
                for (const img of Array.from(el.getElementsByTagName('img'))) {
                    img.onload = this.handleAttachmentLoaded;
                }
            }
        }
    };

    handleAttachmentLoaded = () => {
        this.checkHeight();
    };

    checkHeight = () => {
        if (!this.recordHeadContainer || !this.recordBodyContainer) {
            return;
        }
        const headCoords = getCoords(this.recordHeadContainer);
        const bodyCoords = getCoords(this.recordBodyContainer);
        const recordBodyHeight = this.recordBodyContainer.offsetHeight;
        const requiresArrow = ((bodyCoords.top - headCoords.top) + recordBodyHeight) > COLLAPSE_TEXT_HEIGHT;
        if (!this.state.showArrow && requiresArrow) {
            this.setState({ showArrow: true });
        }
        if (!this.state.requiresCollapseButton
            && requiresArrow
            && (recordBodyHeight >= (document.documentElement.clientHeight - headerHeight))) {
            this.setState({ requiresCollapseButton: true });
        }
    }

    handleCommentsClick = (ev) => {
        if (
            (ev.which && ev.which !== 1)
            || ev.ctrlKey || ev.shiftKey || ev.metaKey || ev.altKey
            || ev.defaultPrevented
        ) return;
        ev.preventDefault();
        const sendViewArgs = {
            url: '/stats/send_view/',
            method: 'post',
            bodyFormat: 'formdata',
            body: { object_id: this.props.id, content_type_id: this.props.content_type_id },
        };
        if (this.state.showComments) {
            this.setState({ showComments: false });
            // doing this in order to 'view' new comments which arrived
            // while comment block was open
            fetchUrl(sendViewArgs);
        } else {
            fetchUrl(sendViewArgs);
            this.setState({ showComments: true });
            dispatchMetricsEvent('timeline-comments-click');
        }
    };

    handleCollapsePost = () => {
        this.setState({ collapsed: true });
    }

    handleCollapseAndScrollPost = () => {
        this.setState({
            collapsed: true,
        }, () => {
            if (this.props.scrollToRecord) {
                this.props.scrollToRecord(this.props.record_id);
            }
        });
    }

    handleRollClick = () => {
        this.setState({ collapsed: false });
    };

    handleInappropriateContentStatus = () => {
        this.setState({ showInappropriateContent: true });
    };

    handleCloseComments = (event) => {
        event.preventDefault();
        this.setState({
            showComments: false,
        }, () => {
            if (this.props.scrollToRecord) {
                this.props.scrollToRecord(this.props.record_id);
            }
        });
    };

    render() {
        const html = {
            __html: this.props.text,
        };
        const relatedTextHTML = {
            __html: this.props.parent_related_text,
        };
        const { userData } = this.props;
        let privateIndicator;
        let commentBlock;
        let recordHead;

        if (this.props.recordHead) {
            // eslint-disable-next-line prefer-destructuring
            recordHead = this.props.recordHead;
        } else {
            if (this.props.is_private) {
                privateIndicator = <span title="Доступ к записи ограничен" className="icon icon_status--lock_on" />;
            }
            let actionText;
            let header;
            if (this.props.action_text) {
                actionText = this.props.action_text;
                if (this.props.action_text[this.props.action_text.length - 1] !== ' ') {
                    actionText += ' ';
                }
                actionText = <span>{actionText}</span>;
            }
            if (this.props.header) {
                header = (
                    <span>
                        <A
                            className="inline-link timeline-header"
                            href={ this.props.url }
                        >
                            {this.props.header}
                        </A>
                        &nbsp;
                    </span>
                );
            }
            /* eslint-disable react/no-danger */
            recordHead = (
                <span>
                    { privateIndicator }
                    { actionText }
                    { header }
                    <span dangerouslySetInnerHTML={ relatedTextHTML } />
                </span>
            );
            /* eslint-enable react/no-danger */
        }

        let arrow;
        if (this.state.showArrow && this.state.collapsed) {
            arrow = (
                <div onClick={ this.handleRollClick } className="roll-arrow statsify">
                    <i className="fa fa-chevron-down" />
                </div>
            );
        }

        const textStyle = { position: 'relative', ...this.props.contentStyle };
        if (this.state.collapsed) {
            textStyle.maxHeight = COLLAPSE_TEXT_HEIGHT;
            textStyle.overflow = 'hidden';
        }

        const hasCut = this.props.has_cut && this.props.url;
        const readMore = hasCut ? (
            <A
                className="read-more timeline-read-more"
                href={ this.props.url }
            >
                <span>
                    Читать далее&nbsp;
                    <span className="fa fa-angle-right" />
                </span>
            </A>
        ) : null;

        const containerClasses = classnames({
            'text-container': true,
            'js-post_content_container': true,
            post_content_container: true,
            post_content_container_cut_override: hasCut,
        });

        let recordBody;
        if (this.props.recordBody) {
            recordBody = (
                <div ref={ (c) => { this.recordBodyContainer = c; } }>
                    { this.props.recordBody }
                </div>
            );
        } else if (this.props.text) {
            if ((typeof this.props.inappropriate_content === 'string') !== this.state.showInappropriateContent) {
                recordBody = (
                    <div className="comment-body hideable-content">
                        <p>{ this.props.inappropriate_content }</p>
                        <p onClick={ this.handleInappropriateContentStatus }>Показать</p>
                    </div>
                );
            } else {
                recordBody = (
                    <div className="timeline-text" ref={ (c) => { this.recordBodyContainer = c; } }>
                        <div
                            className={ containerClasses }
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={ html }
                        />
                        { readMore }
                    </div>
                );
            }
        }

        let attachments;
        if (this.props.showAttachments) {
            const {
                url, header,
                canAddQuiz, canEditEvent, canManageAttachments,
                canModerateAlbums, canEditBanners, quizzes,
            } = this.props;
            const objectId = this.props.id.toString();
            const contentType = this.props.content_type_id.toString();

            const calendar = (
                <div className="calendar-container">
                    <CalendarContainer
                        objectId={ objectId }
                        contentType={ contentType }
                        isGroupEventsByName
                    />
                </div>
            );
            loadCalendarConfig(publicationStore, contentType, objectId);

            const publicationToolbar = (
                <CustomProvider store={ publicationStore } features={ ['coins_system'] }>
                    <PublicationAttachmentsContainer
                        canManageAttachments={ canManageAttachments }
                        objectId={ objectId }
                        contentType={ contentType }
                    />
                    <PublicationToolbar
                        objectId={ objectId }
                        contentType={ contentType }
                        canEditEvent={ canEditEvent }
                        canAddQuiz={ canAddQuiz }
                        canManageAttachments={ canManageAttachments }
                        canModerateAlbums={ canModerateAlbums }
                        canEditBanners={ canEditBanners }
                        itemTitle={ header }
                        itemUrl={ url }
                    />
                </CustomProvider>
            );

            const quizContainer = quizzes.map(quiz => (
                <CustomProvider key={ quiz } store={ quizStore } permissions={ ['quiz.publish_quiz_on_main_page'] }>
                    <BuiltInQuizDetailContainer
                        quizId={ quiz }
                        singleMode
                    />
                </CustomProvider>
            ));

            attachments = (
                <>
                    { calendar }
                    { publicationToolbar }
                    { quizContainer }
                </>
            );
        } else {
            attachments = (
                <DelayRender delay={ this.props.attachmentsLoadDelay }>
                    <RecordAttachments
                        blocks={ this.props.attachments_meta.blocks }
                        contentTypeId={ this.props.content_type_id }
                        objectId={ this.props.id }
                        authUserData={ this.props.authUserData }
                        onLoad={ this.handleAttachmentLoaded }
                    />
                </DelayRender>
            );
        }

        let likesBlock = null;
        if (!this.props.hideLikes) {
            if (!this.props.likesBlock) {
                likesBlock = (
                    <LikesBlock
                        contentTypeId={ this.props.content_type_id }
                        objectId={ this.props.id }
                        count={ this.props.like_count }
                        isLiked={ this.props.is_liked }
                        inTimeline
                    />
                );
            } else {
                // eslint-disable-next-line prefer-destructuring
                likesBlock = this.props.likesBlock;
            }
        }

        if (!this.props.hide_comments && (this.props.commentsOpen || this.state.showComments)) {
            commentBlock = (
                <CommentsBlock
                    formHeight={ isMobile ? 50 : 100 }
                    userId={ this.props.authUserData.id }
                    contentTypeId={ this.props.content_type_id }
                    objectId={ this.props.id }
                    objectUrl={ this.props.url }
                    handleRequestClose={
                        this.props.commentsOpen ? null : this.handleCloseComments
                    }
                    isFranchiseeIdeaCommentsBlock={ this.props.is_franchisee }
                    showOfficialResponseButton={ this.props.show_official_response_button }
                />
            );
        }

        let commentLink;
        if (!this.props.hide_comments && !this.props.commentsOpen) {
            commentLink = (
                <CommentsLink
                    onClick={ this.handleCommentsClick }
                    userId={ this.props.authUserData.id }
                    commentCount={ this.props.comments_count }
                    newCommentCount={ this.props.new_comments_count }
                    commentChannel={ this.props.comments_channel }
                    url={ this.props.url }
                />
            );
        }

        let visibilitySensor = null;
        if (this.props.handleRecordVisibility) {
            visibilitySensor = (
                <VisibilitySensor
                    onChange={
                        (isVisible) => {
                            this.props.handleRecordVisibility(isVisible, this.props.record_id);
                        }
                    }
                    scrollCheck
                    active
                />
            );
        }

        const settings = (
            <Settings
                contentType={ this.props.content_type_id }
                objectId={ this.props.record_id }
                recordDate={ this.props.date }
                authUserData={ this.props.authUserData }
                filterData={ this.props.filterData }
                reloadData={ this.props.reloadData }
                scrollToRecord={ this.props.scrollToRecord }
                userData={ this.props.userData }
                canPersonalBlocking={ this.props.canPersonalBlocking }
                showContentSettings={ this.props.showContentSettings }
                removeRecord={ this.props.removeRecord }
                removeAllRecordsByUser={ this.props.removeAllRecordsByUser }
                canDeleteAvatarRecords={ this.props.canDeleteAvatarRecords }
                id={ this.props.id }
                url={ this.props.url }
                editUrl={ this.props.editUrl }
                onEditClick={ this.props.onEditClick }
                showSettings={ this.props.showSettings }
                onOpenSettings={ this.props.onOpenSettings }
                type={ this.props.type }
                isShow={ this.props.isShow }
            >
                <div className="timeline-right-block">
                    <div
                        title={ moment(this.props.date).format('YYYY.MM.DD HH:mm') }
                        className="timeline-date"
                    >
                        { moment(this.props.date).locale(locale).fromNow(false) }
                    </div>
                    { this.props.rightBlock }
                </div>
            </Settings>
        );

        let userFirstNameLastName = null;
        if (userData) {
            userFirstNameLastName = (
                <UserTooltipContainer rootStyle={ { flexGrow: 1 } } username={ userData.username } horizontal="left">
                    <A href={ userData.url }>
                        <div className="user_name">
                            { userData.first_name }
                            &nbsp;
                            { userData.last_name }
                        </div>
                    </A>
                </UserTooltipContainer>
            );
        }

        let { sidePanelMainBlock } = this.props;
        const { sidePanelSecondaryBlock } = this.props;
        if (userData) {
            sidePanelMainBlock = (
                <div className="timeline_avatar user_table_cell">
                    <UserTooltipContainer username={ userData.username }>
                        <A href={ userData.url }>
                            <img className="user_avatar" alt={ userData.username } src={ userData.avatarURL } />
                        </A>
                    </UserTooltipContainer>
                </div>
            );
        }

        let views;
        if (this.props.views_unique_count != null) {
            views = (
                <div className="views_count" title="Количество просмотров" style={ { marginLeft: '10px' } }>
                    <i className="fa fa-eye view-icon" aria-hidden="true" />
                    <span>{ this.props.views_unique_count }</span>
                    {
                        this.props.views_total_count != null
                        && (
                            <span>
                                &nbsp;
                                {`/ ${this.props.views_total_count}`}
                            </span>
                        )
                    }
                </div>
            );
        }

        const statsButton = this.props.canViewStatistic ? (
            <div className="timeline-record-stats-button">
                <A
                    href={ pubStatsPageUrl(this.props.content_type_id, this.props.id) }
                    style={ { color: '#333' } }
                >
                    <Rating style={ shareEditIconStyle } />
                </A>
            </div>
        ) : null;

        const donateButton = this.props.coinsSystemFeatureIsOn
            && ALLOWED_DONATE_CTYPES.includes(this.props.content_type_name)
            ? (
                <DonateAuthorButton
                    contentTypeId={ this.props.content_type_id }
                    objectId={ this.props.id }
                    fetchTransitions={ false }
                    userId={ this.props.authUserData.id }
                    transitions={ this.props.coin_transitions }
                    authorId={ this.props.userData.id }
                />
            )
            : null;

        const followingLikes = (this.props.following_likes.length > 0 && !this.props.hideLikes)
            ? (
                <FollowingLikes followingLikes={ this.props.following_likes } />
            )
            : null;

        /* eslint-disable no-nested-ternary */
        const updatedTimestamp = this.props.updated_at ? (
            moment(this.props.updated_at).isSame(moment(), 'day') ? (
                <div className="timeline-record-updated-at">
                    отредактировано&nbsp;в&nbsp;
                    { moment(this.props.updated_at).format('HH:mm') }
                </div>
            ) : (
                <div className="timeline-record-updated-at">
                    запись отредактирована
                </div>
            )
        ) : null;
        /* eslint-enable no-nested-ternary */

        const tag = (this.props.tag_name)
            ? (
                <span className="timeline-record-tag">
                    <i className="fa fa-hashtag" />
                    <A
                        className="tag-link-simple"
                        href={ pagesUrls.taggedTimeline(this.props.tag_name) }
                    >
                        { this.props.tag_name }
                    </A>
                </span>
            )
            : null;

        const collapseButtonTop = this.state.requiresCollapseButton && !this.state.collapsed ? (
            <div
                onClick={ this.handleCollapsePost }
                className="timeline-collapse-button"
            >
                свернуть
            </div>
        ) : null;

        const collapseButtonBottom = this.state.requiresCollapseButton && !this.state.collapsed ? (
            <div
                onClick={ this.handleCollapseAndScrollPost }
                className="timeline-collapse-button"
            >
                свернуть
            </div>
        ) : null;

        const pinTitle = (canPinEntry) => {
            if (canPinEntry) {
                return this.state.isPinned ? 'открепить' : 'закрепить';
            }
            return 'Запись закреплена';
        };

        const handleTogglePinned = async () => {
            const { isPinned } = this.state;
            let url = isPinned
                ? `/timeline/api/record/${this.props.record_id}/unpin/`
                : `/timeline/api/record/${this.props.record_id}/pin/`;
            if (this.props.currentTabId) {
                url += `?tab_id=${this.props.currentTabId}`;
            }
            callApi(url, 'POST')
                .then(({ msg }) => {
                    this.setState(({ isPinned: !isPinned }));
                    showSuccessNotification(msg);
                })
                .catch(({ msg }) => {
                    showErrorNotification(msg || 'Действие не удалось.');
                });
        };

        const pinIcon = (
            (this.props.canPinRecords || this.state.isPinned) && (
                <MrgIcon
                    onClick={ this.props.canPinRecords ? handleTogglePinned : null }
                    className={ classnames({
                        'pin-control-icon icon_actions--pin': true,
                        'pin-control-icon-pointer': this.props.canPinRecords,
                        'pin-control-icon-pinned': this.state.isPinned,
                    }) }
                    title={ pinTitle(this.props.canPinRecords) }
                />
            )
        );

        /* eslint-disable react/no-danger */
        const timelineHeader = (
            <div
                className={ classnames({
                    'timeline-record-header': true,
                    'without-name': !userData,
                }) }
                ref={ (c) => {
                    this.recordHeadContainer = c;
                } }
            >
                { userFirstNameLastName }
                <div className="timeline-record-header-right">
                    { pinIcon }
                    { !this.props.hideTime && settings }
                </div>
            </div>
        );

        return (
            <div
                className={ `timeline-container timeline-full timeline-container-${this.props.record_id}` }
            >
                <div className="timeline-record-information b-flexrow">
                    <div className="timeline-record-side-panel">
                        { sidePanelMainBlock }
                        { sidePanelSecondaryBlock }
                        { collapseButtonTop }
                        { isMobile && timelineHeader }
                        <div className="timeline-record-side-panel-spacer-namer-juice" />
                        { collapseButtonBottom }
                    </div>
                    <div className="timeline-record-content">
                        { !isMobile && timelineHeader }
                        <div className="timeline-head-full">
                            { recordHead }
                        </div>
                        <div style={ textStyle }>
                            { recordBody }
                            { arrow }
                        </div>
                        {
                            attachments && (
                                <div
                                    className="attachments-container"
                                >
                                    { attachments }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div
                    className="like-comment-block"
                    style={ { display: 'flex' } }
                >
                    { likesBlock }
                    { statsButton }
                    { followingLikes }
                    { tag }
                    { commentLink }
                    { views }
                    { donateButton }
                    { updatedTimestamp }
                </div>
                { commentBlock }
                { visibilitySensor }
            </div>
        );
        /* eslint-enable react/no-danger */
    }
}

export default Record;
