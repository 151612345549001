import React from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    UserInfo,
    A,
    Spinner,
} from 'intdev-ui';

const styles = {
    popover: {
        display: 'flex',
        margin: '6px',
        maxWidth: '500px',
        fontSize: '12px',
    },
};

export default class CommentTooltip extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            username: PropTypes.string,
            photo: PropTypes.string,
            userlink: PropTypes.string,
            created_at: PropTypes.string,
        }),
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]).isRequired,
        showInappropriateContent: PropTypes.bool,
        process: PropTypes.bool,
    };

    static defaultProps = {
        showInappropriateContent: undefined,
        process: false,
        data: null,
    };

    state = {
        popoverIsOpen: null,
    };

    handleShowPopover = () => {
        this.setState({ popoverIsOpen: null });
        setTimeout(() => this.setState((state) => {
            if (state.popoverIsOpen === null) {
                return { popoverIsOpen: true };
            }
            return {};
        }), 450);
    };

    handleHidePopover = () => {
        this.setState({ popoverIsOpen: false });
    };

    renderPopover = () => {
        const { data, process } = this.props;
        if (process) {
            return (
                <div style={ styles.popover }>
                    <Spinner />
                </div>
            );
        }
        if (!data) {
            return null;
        }
        return (
            <div style={ styles.popover }>
                <div className="comment-sidebar">
                    <UserInfo
                        avatarSize={ 40 }
                        fullname={ data.username }
                        avatar={ data.photo }
                        url={ data.userlink }
                        hideFullname
                    />
                </div>
                <div className="comment-content">
                    <div className="comment-header">
                        <A href={ data.userlink }>{data.username}</A>
                        <span className="comment-time">{data.created_at}</span>
                    </div>
                    {
                        this.props.showInappropriateContent === false ? (
                            <div className="comment-body hideable-content">
                                <span>Потенциально нежелательный контент.</span>
                            </div>
                        ) : (
                            <div className="comment-body">
                                {/* eslint-disable-next-line */}
                                <div dangerouslySetInnerHTML={ { __html: data.text } } />
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };

    render() {
        return (
            <span
                className="comment-popover-container"
                ref={ (ref) => { this.anchorEl = ref; } }
                onMouseEnter={ this.handleShowPopover }
                onMouseLeave={ this.handleHidePopover }
            >
                {this.props.children}
                {this.state.popoverIsOpen
                && (
                    <Popover
                        open
                        useLayerForClickAway={ false }
                        anchorEl={ this.anchorEl }
                        anchorOrigin={ { horizontal: 'middle', vertical: 'top' } }
                        targetOrigin={ { horizontal: 'middle', vertical: 'bottom' } }
                        style={ {
                            pointerEvents: 'none',
                            marginTop: '-5px',
                            marginLeft: '50px',
                        } }
                    >
                        <div className="comment-popover">
                            {this.renderPopover()}
                        </div>
                    </Popover>
                )}
            </span>
        );
    }
}
