/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Dialog, Validation } from 'intdev-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showNotification } from '../../../common/helpers/showNotification';
import { isEmptyObject } from '../../../common/helpers/helpers';
import { apiUrls } from '../../constants/apiUrls';
import {
    eventModalCleanValidationErrors,
    eventModalFieldValueChange,
    eventModalSetValidationErrors,
    modalClose,
    saveEventPanel,
} from '../../actions';
import {
    ACCEPT_DATE_FORMAT,
    isFieldValueEmpty,
    PANEL_GLOBAL_ERROR,
    styles,
} from './EventEditModal';
import getValidationProps from '../../helpers/getValidationProps';
import EventCopyPanel from './EventCopyPanel';
import { getModalData } from '../../reducers';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';

export class EvenCopyModalComponent extends React.Component {
    static propTypes = {
        event: PropTypes.shape({ meetingId: PropTypes.number }).isRequired,
        // config
        objectId: PropTypes.number,
        contentType: PropTypes.number,
        // dispatch
        onClose: PropTypes.func.isRequired,
        setValidationErrors: PropTypes.func.isRequired,
        saveEventPanel: PropTypes.func.isRequired,
        // from state
        copyStartDate: PropTypes.instanceOf(Date).isRequired,
        copyEndDate: PropTypes.instanceOf(Date).isRequired,
        copyPeriodMinutes: PropTypes.number,
        copyPeriodDays: PropTypes.number,
        copyStartTime: PropTypes.string.isRequired,
        copyEndTime: PropTypes.string.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
        processing: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        objectId: null,
        contentType: null,
        canChangeOwner: null,
        copyPeriodMinutes: null,
        copyPeriodDays: null,
    };

    getModalTitle = () => {
        const title = 'Копировать событие';

        const validationProps = getValidationProps(this.props.validationErrors, PANEL_GLOBAL_ERROR);
        if (validationProps) {
            return (
                <div>
                    { title }
                    <Validation
                        state={ validationProps.validationState }
                        value={ validationProps.validationText }
                    />
                </div>
            );
        }
        return title;
    };

    prepareRequestData = () => {
        const {
            copyStartDate,
            copyStartTime,
            copyEndDate,
            copyEndTime,
            copyPeriodMinutes,
            copyPeriodDays,
        } = this.props;

        const copyStartMoment = moment(copyStartDate).add(moment.duration(copyStartTime));
        const copyEndMoment = moment(copyEndDate).add(moment.duration(copyEndTime));

        const formData = {
            copy_start_date: copyStartMoment.format(ACCEPT_DATE_FORMAT),
            copy_start_time: copyStartMoment.utc().format('HH:mm'),
            copy_end_date: copyEndMoment.format(ACCEPT_DATE_FORMAT),
            copy_end_time: copyEndMoment.utc().format('HH:mm'),
            copy_period_days: copyPeriodDays,
            copy_period_minutes: copyPeriodMinutes,
        };

        // check required fields
        const errors = {};
        const requiredFields = new Set([
            'copy_start_date',
            'copy_end_date',
            'copy_start_time',
            'copy_end_time',
            'copy_period_days',
            'copy_period_minutes',
        ]);
        Object.entries(formData).forEach(([key, val]) => {
            const isFieldRequired = requiredFields.has(key);
            if (isFieldRequired && isFieldValueEmpty(val)) {
                errors[key] = 'Это поле обязательно';
            }
        });
        if (!isEmptyObject(errors)) {
            this.props.setValidationErrors(errors);
            return null;
        }
        return formData;
    };

    handleSave = () => {
        const formData = this.prepareRequestData();
        if (!formData) {
            return;
        }
        const url = apiUrls().eventCopy(this.props.contentType, this.props.objectId, this.props.event.meetingId);
        this.props.saveEventPanel(url, formData).then((data) => {
            const {
                fail,
                statusCode,
                ...payload
            } = data;
            if (fail) {
                this.props.setValidationErrors(payload);
                showNotification({ message: 'Ошибка при сохранении', level: 'warning' });
            } else {
                showNotification({ message: 'Событие сохранено', level: 'success' });
                this.props.onClose();
            }
        }).catch(() => {
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
        });
    };

    render() {
        const actions = [
            <Button
                label="Отмена"
                onClick={ this.props.onClose }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
            <Button
                label="Сохранить"
                primary
                onClick={ this.handleSave }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
        ];

        return (
            <Dialog
                open
                autoDetectWindowHeight
                autoScrollBodyContent
                bodyStyle={ styles.dialogBody }
                title={ this.getModalTitle() }
                actions={ actions }
                actionsContainerStyle={ styles.actionsContainerStyle }
                onRequestClose={ this.props.onClose }
            >
                <div style={ styles.fieldContainer }>
                    <EventCopyPanel />
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    saveEventPanel,
    onFieldChange: eventModalFieldValueChange,
    onClose: modalClose,
    setValidationErrors: eventModalSetValidationErrors,
    cleanValidationErrors: eventModalCleanValidationErrors,
}, dispatch);

const mapStateToProps = state => ({
    ...getModalData(state),
});

const EvenCopyModal = connect(mapStateToProps, mapDispatchToProps)(EvenCopyModalComponent);

export default EvenCopyModal;
