export const transliterate = (str) => {
    const ruen = {
        с: 'c',
        р: 'h',
        у: 'e',
        т: 'n',
        х: '[',
        ф: 'a',
        ч: 'x',
        ц: 'w',
        щ: 'o',
        м: 'v',
        п: 'g',
        ш: 'i',
        ы: 's',
        ъ: ']',
        э: '\'',
        ь: 'm',
        я: 'z',
        ю: '.',
        б: ',',
        а: 'f',
        н: 'y',
        о: 'j',
        г: 'u',
        в: 'd',
        е: 't',
        д: 'l',
        з: 'p',
        ж: ';',
        й: 'q',
        и: 'b',
        л: 'k',
        к: 'r',
    };
    let result = '';
    for (const letter of str) {
        const en = ruen[letter];
        if (en) {
            result += en;
        } else {
            result += letter;
        }
    }
    return result;
};
