import like from '../../../img/emoji/like.svg';
import love from '../../../img/emoji/love.svg';
import haha from '../../../img/emoji/haha.svg';
import wow from '../../../img/emoji/wow.svg';
import sad from '../../../img/emoji/sad.svg';
import angry from '../../../img/emoji/angry.svg';
import facepalm from '../../../img/emoji/facepalm.svg';
import fire from '../../../img/emoji/fire.svg';
import thumbsUp from '../../../img/emoji/thumbsUp.svg';

export const HUMAN_EMOJI = {
    0: { text: 'Нравится', icon: like },
    1: { text: 'Супер', icon: love },
    2: { text: 'Ха-ха', icon: haha },
    3: { text: 'Ух ты!', icon: wow },
    4: { text: 'Грустно', icon: sad },
    5: { text: 'Возмутительно', icon: angry, long: true },
    6: { text: 'Ну надо же', icon: facepalm, long: true },
    7: { text: 'Огонь', icon: fire },
    8: { text: 'Класс', icon: thumbsUp },
};

export const emojiTooltipPositions = [
    'top_left',
    'top_right',
    'top_center',
    'bottom_left',
    'bottom_right',
    'bottom_center',
];

export const EMOJI_TOOLTIP_WIDTH = 268;
export const LIKE_ELEMENT_WIDTH = 52;
