import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import * as Sentry from '@sentry/browser';
import getCentrifugeConnection from '../../centrifuge';
import { UrlPreview } from './UrlPreview';
import { apiUrls } from '../constants/apiUrls';
import { callApi } from '../../common/middlewares/apiMiddleware';


export class UrlPreviewAsync extends PureComponent {
    static propTypes = {
        url: PropTypes.string.isRequired,
    };

    state = {
        data: null,
    };

    componentDidMount() {
        this.centrifuge = getCentrifugeConnection();
        const channel = `opengraph_${md5(this.props.url)}`;
        this.subscription = this.centrifuge
            .subscribe(channel, this.handleMessage)
            .on('subscribe', () => callApi(apiUrls.get_opengraph_tags, 'POST', { url: this.props.url }))
            .on('error', (error) => {
                Sentry.withScope((scope) => {
                    scope.setExtra('error', error);
                    Sentry.captureMessage('Can`t get opengraph tags');
                });
            });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription.removeAllListeners();
        }
    }

    handleMessage = (message) => {
        if (message.data) { this.setState({ data: message.data }); }
        if (this.subscription) { this.subscription.unsubscribe(); }
    };

    render() {
        if (this.state.data) { return <UrlPreview key={ this.props.url } { ...this.state.data } />; }
        return null;
    }
}
