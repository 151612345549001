import React from 'react';
import { A } from 'intdev-ui';
import { Link } from 'react-router-dom';

export const linkStyles = {
    accentHovered: {
        color: '#ff9e00',
        textDecoration: 'none',
    },
    noUnderline: {
        textDecoration: 'none',
    },
    breadcrumb: {
        color: '#999999',
        textDecoration: 'underline',
    },
    likeText: {
        color: 'inherit',
        textDecoration: 'none',
    },
    noBorder: {
        borderBottomColor: 'rgba(0, 0, 0, 0)',
        textDecoration: 'none',
    },
    inline: {
        color: 'inherit',
    },
};


export const ALink = props => <A rootComponent={ Link } { ...props } />;


// menu
// breadcrumb
// action
