import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { DefaultParams } from '../DefaultParams/components/DefaultParams';

export class CustomProvider extends Component {
    static propTypes = {
        children: PropTypes.node,
        permissions: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.arrayOf(PropTypes.string),
        needSelfProfile: PropTypes.bool,
        needConstants: PropTypes.shape({
            settings: PropTypes.arrayOf(PropTypes.string),
        }),
    };

    static defaultProps = {
        children: null,
        needSelfProfile: false,
        permissions: [],
        needConstants: {},
        features: [],
    };

    render() {
        const {
            children,
            permissions,
            needSelfProfile,
            needConstants,
            features,
            ...otherProps
        } = this.props;
        return (
            <Provider
                { ...otherProps }
            >
                <DefaultParams
                    permissions={ permissions }
                    needSelfProfile={ needSelfProfile }
                    needConstants={ needConstants }
                    features={ features }
                >
                    { children }
                </DefaultParams>
            </Provider>
        );
    }
}
