import {
    Button, Dialog, Spinner, TextField, Upload, Checkbox,
} from 'intdev-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LabelWithHelp from '@/common/components/LabelWithHelp/LabelWithHelp';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { showNotification } from '../../common/helpers/showNotification';
import { FORMDATA } from '../../common/constants/ajaxBodyTypes';
import { TagField } from '../../common/tagging';


const styles = {
    row: {
        marginBottom: '20px',
    },
    bottomRow: {
        display: 'flex',
        alignItems: 'center',
    },
    errorMsg: {
        margin: '0',
        marginTop: '-8px',
        marginBottom: '16px',
        color: '#fc2d39',
    },
    spinner: {
        marginLeft: '20px',
        display: 'inline-block',
    },
};

const DEFAULT_FIELD_LABEL = 'Выберите файл';

const DEFAULT_ERRORS = {
    attachment_file_error_msg: null,
    name_error_msg: null,
};

export class AttachmentDialog extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        isDialogOpen: PropTypes.bool,
        handleCloseDialog: PropTypes.func,
        name: PropTypes.string,
        tags: PropTypes.string,
        title: PropTypes.string,
        attachmentFile: PropTypes.shape({
            name: PropTypes.string,
        }),
        onEdit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isDialogOpen: false,
        handleCloseDialog: this.handleCloseDialog,
        name: '',
        tags: '',
        attachmentFile: null,
        title: 'Прикрепить файл',
    };

    state = {
        name: this.props.name,
        attachmentFile: this.props.attachmentFile,
        attachedVideoPreview: null,
        tags: this.props.tags,
        isUploadFieldDisabled: true,
        isSaveButtonDisabled: true,
        isPending: false,
        fileIsPdf: false,
        uploadAsBook: false,
        ...DEFAULT_ERRORS,
    };

    handleFieldChange = (event, newValue) => {
        this.setState({
            [event.target.name]: newValue,
        });
    };

    handleChangeTags = value => this.setState({ tags: value });

    handleUploadResult = (response) => {
        this.setState({ isPending: false });
        if (response.result === 'OK') {
            this.props.onEdit();
        } else {
            const fieldNames = Object.keys(response.msg);
            const messages = {};
            fieldNames.forEach((fieldName) => {
                messages[`${fieldName}_error_msg`] = response.msg[fieldName].join(' ');
            });
            this.setState(messages);
        }
    };

    handleException = (response) => {
        if (response.status === 413) {
            showNotification({
                message: 'Попытка зарузить слишком большой файл',
                level: 'error',
            });
        } else {
            showNotification({
                message: 'Произошла ошибка',
                level: 'error',
            });
        }
        this.setState({ isPending: false });
    };

    handleFormSubmit = () => {
        this.setState({ isPending: true, ...DEFAULT_ERRORS });
        const { uploadAsBook } = this.state;
        const body = {
            attachment_file: this.state.attachmentFile,
            attachment_preview: this.state.attachedVideoPreview,
            name: this.state.name,
            tags: this.state.tags,
            is_book: uploadAsBook,
        };
        callApi(this.props.url, 'post', body, FORMDATA).then(
            this.handleUploadResult,
        ).catch(
            this.handleException,
        );
    };

    handleFile = (files) => {
        const file = files[0];
        const fileIsPdf = /\.pdf/.test(file.name);
        this.setState({
            attachmentFile: file,
            fileIsPdf,
        });
    };

    handleVideoPreview = (files) => {
        const file = files[0];
        this.setState({
            attachedVideoPreview: file,
        });
    };

    uploadFieldLabel = () => {
        let text = DEFAULT_FIELD_LABEL;
        if (this.state.attachmentFile) {
            text = 'Выбрать другой файл';
        }
        return (
            <div>
                <span>{ text }</span>
                <LabelWithHelp
                    label=""
                    questionIconStyle={ {
                        color: '#7f8191',
                        fontSize: 15,
                        margin: '0 7px',
                    } }
                    help={ (
                        <div>
                            <div>При загрузке видеофайла необходимо придерживаться требований.</div>
                            <div>
                                Рекомендуемое cоотношение сторон: 1280 x 720 (16 : 9).
                                Размер не более 1 Гбайт. Формат: mp4, mov, avi.
                            </div>
                        </div>
                    ) }
                />
            </div>
        );
    };

    attachedFileIsVideo = () => {
        if (!this.state.attachmentFile) {
            return false;
        }
        const ext = this.state.attachmentFile.name.split('.').slice(-1)[0];
        return ['mov', 'mp4', 'avi', 'wmv', 'ogv'].includes(ext.toLowerCase());
    };

    render() {
        const { fileIsPdf, uploadAsBook } = this.state;
        const uploadVideoPreview = (
            <Upload
                uploadLabel="Выбрать обложку видео"
                onDrop={ this.handleVideoPreview }
            />
        );

        return (
            <Dialog
                title={ this.props.title }
                modal={ false }
                open={ this.props.isDialogOpen }
                onRequestClose={ this.props.handleCloseDialog }
            >
                <TextField
                    fullWidth
                    style={ styles.row }
                    disabled={ this.state.isPending }
                    hintText="Название вложения"
                    value={ this.state.name }
                    name="name"
                    validationState={ this.state.name_error_msg ? 'error' : null }
                    validationText={ this.state.name_error_msg }
                    onChange={ this.handleFieldChange }
                />
                <div>
                    <Upload
                        disabled={ this.state.isPending }
                        onDrop={ this.handleFile }
                        uploadLabel={ this.uploadFieldLabel() }
                    />
                </div>
                {this.state.attachment_file_error_msg &&
                <p style={ styles.errorMsg }>{this.state.attachment_file_error_msg}</p>
                }
                <div style={ styles.row }>
                    {this.state.attachmentFile && <p>{this.state.attachmentFile.name}</p>}
                </div>
                <div>
                    { fileIsPdf && (
                        <>
                            <Checkbox
                                checked={ uploadAsBook }
                                onChange={ e => this.setState({ uploadAsBook: e.target.checked }) }
                            />
                            <span style={ { marginLeft: 10 } }>Загрузить как книгу</span>
                        </>
                    ) }
                </div>
                {
                    this.attachedFileIsVideo() && uploadVideoPreview
                }
                <div style={ styles.row }>
                    {this.state.attachedVideoPreview && <p>{this.state.attachedVideoPreview.name}</p>}
                </div>
                <TagField
                    value={ this.state.tags }
                    onChange={ this.handleChangeTags }
                    placeholder="Теги"
                    wrapperStyle={ styles.row }
                    disabled={ this.state.isPending }
                />
                <div style={ styles.bottomRow }>
                    <Button
                        label="Сохранить"
                        primary
                        disabled={ !(this.state.attachmentFile && this.state.name) || this.state.isPending }
                        onClick={ this.handleFormSubmit }
                    />
                    { this.state.isPending &&
                        <div style={ styles.spinner }>
                            <Spinner size={ 20 } />
                        </div>
                    }
                </div>
            </Dialog>
        );
    }
}
