import React from 'react';
import { SvgIcon } from 'intdev-ui/lib/SvgIcon/SvgIcon';

/* eslint-disable max-len */
const AttachStickerButton = props => (<SvgIcon { ...props }>
    <path d="M8 0c4.415 0 8 3.585 8 8s-3.585 8-8 8-8-3.585-8-8 3.585-8 8-8zm0 2c3.312 0 6 2.688 6 6s-2.688 6-6 6-6-2.688-6-6 2.688-6 6-6zM5.516 9c.519.944 1.438 1.573 2.484 1.573.987 0 1.861-.559 2.391-1.415l.002-.003a.736.736 0 0 1 1.33.619l-.131.353c-.797 1.286-2.11 2.126-3.592 2.126-1.572 0-2.952-.944-3.73-2.363l-.1-.273A.743.743 0 0 1 5.516 9zM7 6a1 1 0 0 0-2 0v1a1 1 0 0 0 2 0V6zm4 0a1 1 0 0 0-2 0v1a1 1 0 0 0 2 0V6z" />
</SvgIcon>);
/* eslint-enable max-len */

AttachStickerButton.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
};

export default AttachStickerButton;
