import { camelCase, snakeCase } from 'lodash';

export const cssVar = (name = '', value) => {
    let safeName = name;

    if (safeName.substr(0, 2) !== '--') {
        safeName = `--${name}`;
    }

    if (value) {
        document.documentElement.style.setProperty(safeName, value);
    }
    return getComputedStyle(document.documentElement).getPropertyValue(safeName);
};

const varsObj = {
    loadVars: (keys = []) => {
        keys.forEach((key) => {
            if (Object.prototype.toString.call(key) !== '[object String]') {
                return;
            }
            const name = snakeCase(key).replace(/_/g, '-');
            Object.defineProperty(varsObj, key, {
                get: () => cssVar(name),
                configurable: true,
            });
        });
    },
};

const varsObjProxy = new Proxy(varsObj, {
    get(target, p) {
        if (target[p]) {
            return target[p];
        }
        varsObj.loadVars([p]);
        return cssVar(p);
    },
});

export const getAllCssVars = () => {
    Array.from(document.styleSheets)
        .filter(
            (sheet) => {
                try {
                    // In Chrome, if stylesheet originates from a different domain,
                    // styleSheet.cssRules simply won't exist.
                    //
                    // In Firefox, if stylesheet originates from a different domain, trying
                    // to access styleSheet.cssRules will throw a SecurityError. Hence, we must use
                    // try/catch to detect this condition in Firefox.
                    return (sheet.href === null || sheet.href.startsWith(window.location.origin)) && sheet.cssRules;
                } catch (e) {
                    // Rethrow exception if it's not a SecurityError. Note that SecurityError
                    // exception is specific to Firefox.
                    if (e.name !== 'SecurityError') {
                        throw e;
                    }
                    return false;
                }
            },
        )
        .forEach((styleSheet) => {
            try {
                Array
                    .from(styleSheet.cssRules).forEach((rule) => {
                        if (rule.selectorText === ':root') {
                            Array
                                .from(rule.style)
                                .filter(name => name.startsWith('--'))
                                .forEach((name) => {
                                    const key = camelCase(name.slice(2).replace(/-/g, '_'));
                                    Object.defineProperty(varsObj, key, {
                                        get: () => cssVar(name),
                                        configurable: true,
                                    });
                                });
                        }
                    });
            } catch (e) {
                if (e.name !== 'SecurityError') {
                    throw e;
                }
            }
        });
    return varsObjProxy;
};
