import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../common/components/ErrorBoundary';
import { UrlPreviewAsync } from './UrlPreviewAsync';

const nPreviewUrls = 1;

function patchUrl(url) {
    // Last slash unification
    return url.substr(-1) === '/' ? url.substring(0, url.length - 1) : url;
}

function parseUrls(text) {
    const urls = [];
    const rx = /href="([^"]*)/g;
    let match = null;
    // eslint-disable-next-line
    while (match = rx.exec(text)) {
        const url = patchUrl(match[1]);
        if (!urls.includes(url)) {
            try {
                // exclude internal links
                const parsedUrl = new URL(url);
                if (window.location.host !== parsedUrl.host) {
                    urls.push(url);
                }
            } catch {
                // not valid url: do nothing
            }
        }
    }
    return urls.slice(0, nPreviewUrls);
}

function isShallowEqualArrays(arr1, arr2) {
    return arr1.every((el, id) => el === arr2[id]);
}

export class Opengraph extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line
        text: PropTypes.string.isRequired,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.text) { return { urls: [] }; }
        const urlsFromProps = parseUrls(nextProps.text);
        if (!isShallowEqualArrays(urlsFromProps, prevState.urls)) {
            return { urls: urlsFromProps };
        }
        return null;
    }

    state = {
        urls: [],
    };

    render() {
        if (!this.state.urls.length) { return null; }
        return (
            <ErrorBoundary renderNull>
                { this.state.urls.map(url => (<UrlPreviewAsync key={ url } url={ url } />)) }
            </ErrorBoundary>
        );
    }
}
