import { MOUSE_OUT_SERIES, MOUSE_OVER_SERIES } from '../constants/actions';

const eventsUiState = (state = {
    hoveredEventSeries: null,
}, action) => {
    switch (action.type) {
        case (MOUSE_OVER_SERIES): {
            const hoveredEvent = action.payload;
            if (!hoveredEvent.series) {
                return state;
            }
            return {
                ...state,
                hoveredEventSeries: hoveredEvent.series,
            };
        }
        case (MOUSE_OUT_SERIES): {
            const hoveredEvent = action.payload;
            if (!hoveredEvent.series || hoveredEvent.series !== state.hoveredEventSeries) {
                return state;
            }
            return {
                ...state,
                hoveredEventSeries: null,
            };
        }
        default:
            return state;
    }
};

export default eventsUiState;
