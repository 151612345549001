import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BuiltInQuizDetailContainer from '../../../../quiz/detail/containers/BuiltInQuizDetailContainer';
import { CustomProvider } from '../../../../common/components/CustomProvider';
import { makeStore } from '../../../../quiz/detail/store';


class Quizzes extends Component {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.number),
    };
    static defaultProps = {
        ids: [],
    };

    constructor(props) {
        super(props);
        this.stores = {};
        for (const id of props.ids) {
            this.stores[id] = makeStore();
        }
    }

    render() {
        const { ids } = this.props;
        if (!ids.length) {
            return null;
        }
        return (
            <div>
                { ids.map(id => (
                    <CustomProvider
                        key={ id }
                        store={ this.stores[id] }
                        permissions={ ['quiz.publish_quiz_on_main_page'] }
                    >
                        <BuiltInQuizDetailContainer
                            quizId={ id }
                            singleMode
                        />
                    </CustomProvider>
                ))}
            </div>
        );
    }
}

export default Quizzes;
