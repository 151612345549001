const extractKeyValue = (list, objectKey) => {
    /**
     * Если передан список из объектов, извлекаем только (условно) айдишники по ключу objectKey
     */
    if (list.some(Number.isNaN)) {
        return list.map(item => +item[objectKey]);
    }
    return list.map(Number);
};

export const hasListChanged = (fList, sList, objectKey = 'value') => {
    const fListCopy = extractKeyValue(fList, objectKey).sort((a, b) => a - b);
    const sListCopy = extractKeyValue(sList, objectKey).sort((a, b) => a - b);
    // by this moment we'd have 2 lists of numbers
    return JSON.stringify(fListCopy) !== JSON.stringify(sListCopy);
};
