import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IUIPropTypes } from 'intdev-ui';
import { editChunk } from './actions';
import { BaseChunk } from './BaseChunk';

class ReduxChunkComponent extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        /* eslint-disable react/require-default-props */
        showEditButton: PropTypes.bool,
        placeholder: PropTypes.string,
        formatting: PropTypes.bool,
        fullWidth: PropTypes.bool,
        style: IUIPropTypes.style,
        redactorStyle: PropTypes.shape({}),
        mentions: PropTypes.bool,
        imagemanager: PropTypes.bool,
        // redux state
        text: PropTypes.string,
        contentType: PropTypes.number,
        objectId: PropTypes.number,
        canEdit: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        /* eslint-disable react/require-default-props */
        // redux funcs
        editChunk: PropTypes.func.isRequired,
    };

    handleSave = dataToSave => new Promise((resolve, reject) => {
        this.props.editChunk(this.props.name, dataToSave)
            .then((response) => {
                if (response.status) {
                    reject();
                } else {
                    resolve();
                }
            });
    });

    render() {
        const {
            name,
            text,
            contentType,
            objectId,
            canEdit,
            isLoading,
            ...chunkProps
        } = this.props;
        return (
            <BaseChunk
                name={name}
                onHandleSave={ this.handleSave }
                text={ text }
                contentType={ contentType }
                objectId={ objectId }
                canEdit={ canEdit }
                isLoading={ isLoading }
                { ...chunkProps }
            />
        );
    }
}

const mapStateToProps = ({ chunkStore }, ownProps) => ({
    text: chunkStore.chunks[ownProps.name] ? chunkStore.chunks[ownProps.name].text : null,
    contentType: chunkStore.chunks[ownProps.name] ? chunkStore.chunks[ownProps.name].contentType : null,
    objectId: chunkStore.chunks[ownProps.name] ? chunkStore.chunks[ownProps.name].objectId : null,
    canEdit: chunkStore.chunks[ownProps.name] ? chunkStore.chunks[ownProps.name].canEdit : false,
    isLoading: chunkStore.isLoading[ownProps.name] === undefined ? true : !!chunkStore.isLoading[ownProps.name],
});

const mapDispatchToProps = {
    editChunk,
};

export const ReduxChunk = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReduxChunkComponent);
