import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { ALink } from '../../common/components/ALink';


export const SwitchLink = ({ inRouter, to, ...rest }) => (
    inRouter
        ? <ALink to={ to } { ...rest } />
        : <A href={ to } { ...rest } />
);

SwitchLink.propTypes = {
    inRouter: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

SwitchLink.defaultProps = {
    inRouter: true,
};
