import React from 'react';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Rating = props => (<SvgIcon { ...props }>
    <path xmlns="http://www.w3.org/2000/svg" id="raiting" d="M4 6a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h2zm10 3a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h2zM9 2a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h2z" />
</SvgIcon>);
/* eslint-enable max-len */

Rating.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Rating;
