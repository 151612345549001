import update from 'immutability-helper';

import ACTION_TYPES from '../actionTypes';

export const viewers = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_VIEWERS: {
            return update(state, { $set: action.value });
        }
        case ACTION_TYPES.RESET_VIEWERS: {
            return update(state, { $set: [] });
        }
        default:
            return state;
    }
};
