/* eslint-disable prefer-rest-params */

// простая проверка, что объект содержит пустые ключи
export function isEmptyObject(obj) {
    if (typeof obj !== 'object') {
        throw new Error(`аргумент isEmptyObject ${obj} не является объектом`);
    }
    if (Array.isArray(obj)) {
        throw new Error('аргумент isEmptyObject является массивом');
    }
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
            return false;
        }
    }
    return true;
}


// склонение существительных
// declOfNum(сount, ['получатель', 'получателя', 'получателей']);
export function declOfNum(number, titles) {
    const absNumber = Math.abs(number);
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(absNumber % 100 > 4 && absNumber % 100 < 20) ? 2 : cases[(absNumber % 10 < 5) ? absNumber % 10 : 5]];
}


export function isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

export function debounce(originalFn, timeout = 100) {
    let timer = null;
    return function debounced(...args) {
        function delayed() {
            timer = null;
            originalFn.apply(this, args);
        }

        if (timer !== null) {
            clearTimeout(timer);
        }
        timer = setTimeout(delayed, timeout);
    };
}


function detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    // other browser
    return false;
}


export function isBrowserWorseThanIE11() {
    const versionOfIE = detectIE();
    return versionOfIE && versionOfIE <= 11;
}
