import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'intdev-ui';
import { Record as BasicRecord } from './Record';


export class AlbumPhotoRecord extends React.Component {
    static propTypes = {
        action_text: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        userData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        authUserData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        original_url: PropTypes.string.isRequired,
        preview_url: PropTypes.string.isRequired,
    };

    state = {
        isOpened: false,
    };

    handleRequestClose = () => this.setState({ isOpened: false });

    handleImageClick = () => this.setState({ isOpened: true });

    render() {
        const recordBody = (
            <div>
                <div className="timeline-user-avatar-container">
                    <img src={ this.props.preview_url } onClick={ this.handleImageClick } title="Развернуть" />
                </div>
                <Carousel
                    open={ this.state.isOpened }
                    images={ [{ url: this.props.original_url }] }
                    startIndex={ 0 }
                    onRequestClose={ this.handleRequestClose }
                />
            </div>
        );
        return (
            <BasicRecord
                recordBody={ recordBody }
                collapsed={ false }
                { ...this.props }
            />
        );
    }
}
