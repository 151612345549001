import React from 'react';
import { SvgIcon } from 'intdev-ui/lib/SvgIcon/SvgIcon';

/* eslint-disable max-len */
const CloseButton = props => (<SvgIcon { ...props }>
    <path d="M6.983 5.455l4.34-4.34a1.105 1.105 0 0 1 1.562 1.562l-4.34 4.34 4.34 4.34a1.08 1.08 0 1 1-1.528 1.528l-4.34-4.34-4.34 4.34a1.105 1.105 0 0 1-1.562-1.562l4.34-4.34-4.34-4.34a1.08 1.08 0 1 1 1.528-1.528l4.34 4.34z" />
</SvgIcon>);
/* eslint-enable max-len */

CloseButton.defaultProps = {
    viewBox: '-3 -3 20 20',
    fillRule: 'evenodd',
    color: '#333',
    hoverColor: '#fc2c38',
};

export default CloseButton;
