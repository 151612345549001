import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    A,
    IUIPropTypes,
    Select,
    UserInfo,
} from 'intdev-ui';
import { dictionary } from '@/common/localization/dictionary';
import { callApi } from '../common/middlewares/apiMiddleware';
import { Option } from './Option';
import { DEFAULT_SUGGESTIONS_URL } from './constants';
import UserTooltipContainer from '../common/components/UserTooltipContainer';
import './userSuggestionsStyle.css';


export const selectValueShape = PropTypes.shape({
    label: PropTypes.shape(
        {
            photo: PropTypes.string,
            value: PropTypes.string,
        },
    ),
    value: PropTypes.any,
});

const styles = {
    withAvatar: {
        margin: '4px 3px 2px -4px',
    },
    withAvatarMultiple: {
        margin: '1px 3px 1px -4px',
    },
};

export class UserSuggestions extends Component {
    static propTypes = {
        /**
        * {value: number, label: {fullName: string, email: string}}
        */
        selectValue: PropTypes.oneOfType([
            selectValueShape,
            PropTypes.arrayOf(selectValueShape),
        ]),
        /**
         * url for get suggestion data
         */
        suggest_url: PropTypes.string,
        /**
         * function for change user value
         */
        onChangeValue: PropTypes.func,
        /**
         * get params for suggest request
         */
        suggestionParams: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        /**
         * is select multiple
         */
        selectMultiple: PropTypes.bool,
        /**
         * style of select
         */
        style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        /**
         * render custom value
         */
        renderValue: PropTypes.func,
        /**
         * render custom options, return list of options for render
         */
        renderOptions: PropTypes.func,
        /*
        * text for validation info
        * */
        validationText: PropTypes.string,
        /*
        * state for validation info
        * */
        validationState: PropTypes.string,
        /*
        * auto scroll to element, when validation message with given state appears
        * */
        validationScroll: PropTypes.string,
        /*
        * custom validation style
        * */
        validationStyle: IUIPropTypes.style,
        withUnits: PropTypes.bool,
        withPhone: PropTypes.bool,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool,
        wrapperStyle: IUIPropTypes.style,
        placeholder: PropTypes.string,
        searchPromptText: PropTypes.string,
        noResultsText: PropTypes.string,
        autoFocus: PropTypes.bool,
        hideArrow: PropTypes.bool,
        autoBlur: PropTypes.bool,
        closeMenuAfterChoice: PropTypes.bool,
        withAvatars: PropTypes.bool,
        withTooltips: PropTypes.bool,
        withUrls: PropTypes.bool,
        withEmail: PropTypes.bool,
        withOnlineStatus: PropTypes.bool,
        clearable: PropTypes.bool,
        backspaceRemoves: PropTypes.bool,
        deleteRemoves: PropTypes.bool,

        excludeUsers: PropTypes.arrayOf(PropTypes.number),
        menuStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        valueStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        labelStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        hintStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        inputProps: PropTypes.instanceOf(Object),
    };

    static defaultProps = {
        suggest_url: DEFAULT_SUGGESTIONS_URL,
        autoBlur: true,
        selectValue: null,
        suggestionParams: {},
        selectMultiple: false,
        style: {},
        renderValue: null,
        renderOptions: null,
        validationText: '',
        validationState: null,
        validationScroll: null,
        validationStyle: {},
        withUnits: false,
        withPhone: false,
        disabled: false,
        fullWidth: true,
        wrapperStyle: {},
        placeholder: 'Введи имя сотрудника',
        searchPromptText: dictionary.startTypingName,
        noResultsText: 'не найдено',
        autoFocus: false,
        hideArrow: false,
        onChangeValue: () => {},
        closeMenuAfterChoice: false,
        withAvatars: false,
        withTooltips: false,
        withUrls: false,
        withEmail: true,
        withOnlineStatus: false,
        excludeUsers: null,
        clearable: true,
        backspaceRemoves: true,
        deleteRemoves: true,
        menuStyle: {},
        valueStyle: {},
        labelStyle: {},
        hintStyle: {},
        inputProps: {},
    };

    getSuggestions = (term) => {
        if (!term) {
            return Promise.resolve({ options: [] });
        }
        const data = {
            term,
            ...this.props.suggestionParams,
        };
        return callApi(this.props.suggest_url, 'GET', data).then((suggestData) => {
            if (this.props.withUnits && this.props.selectMultiple) {
                return {
                    options: suggestData.map(itemGroup => ({
                        label: itemGroup.label,
                        value: itemGroup.value.map(item => ({
                            value: item.id,
                            label: item,
                        })),
                    })),
                };
            }
            if (suggestData.length && suggestData[0].value) {
                const userList = suggestData[0];
                return {
                    options: userList.value.map(item => ({
                        value: item.id,
                        label: item,
                    })),
                };
            }
            return {
                options: [],
            };
        });
    };

    handleChange = (values) => {
        if (this.props.selectMultiple) {
            if (!Array.isArray(values)) {
                return;
            }
            let hasUnit = false;
            values.forEach((value, index) => {
                if (value.label && value.label.type === 'unit') {
                    hasUnit = true;
                    callApi(value.label.url).then((data) => {
                        const users = values.slice();
                        users.splice(index, 1);

                        const newUsers = data.map(user => ({
                            value: user.id,
                            label: user.user || user,
                        }));
                        this.props.onChangeValue(users.concat(newUsers));
                    }).catch((data) => {
                        this.props.onChangeValue(data);
                    });
                }
            });
            if (!hasUnit) {
                this.props.onChangeValue(values);
            }
        } else {
            this.props.onChangeValue(values);
        }
    };

    filterOptions = (options) => {
        const { selectMultiple, selectValue, excludeUsers } = this.props;
        let filter = [];
        if (selectValue) {
            filter = selectMultiple ? selectValue.map(user => user.value) : [selectValue.value];
        }
        if (excludeUsers) {
            filter = filter.concat(excludeUsers);
        }
        return filter.length ? options.filter(option => !filter.includes(option.value)) : options;
    };

    renderValue = (value) => {
        const {
            withAvatars,
            withUrls,
            withTooltips,
            selectMultiple,
        } = this.props;

        let renderedValue;
        if (this.props.renderValue) {
            renderedValue = this.props.renderValue(value);
        } else {
            renderedValue = withAvatars
                ? (
                    <div style={ selectMultiple ? styles.withAvatarMultiple : styles.withAvatar }>
                        <UserInfo
                            avatar={ value.label.photoSquare || value.label.photo_square }
                            avatarSize={ 22 }
                            fullname={ value.label.value }
                        />
                    </div>
                ) : <span>{ value.label.value }</span>;

            if (withTooltips) {
                renderedValue = (
                    <UserTooltipContainer
                        username={ value.label.username }
                        withAvatar={ !withAvatars }
                        horizontal="middle"
                    >
                        { renderedValue }
                    </UserTooltipContainer>
                );
            }
        }

        if (withUrls) {
            renderedValue = (
                <A href={ `/users/${value.label.username}/` } target="_blank">{ renderedValue }</A>
            );
        }
        return renderedValue;
    };

    renderOptions = option => (
        <Option
            option={ option }
            withEmail={ this.props.withEmail }
            withPhone={ this.props.withPhone }
            withOnlineStatus={ this.props.withOnlineStatus }
        />
    );

    render() {
        const {
            autoBlur, inputProps, renderOptions, autoFocus,
            disabled, clearable, backspaceRemoves, deleteRemoves,
            fullWidth, wrapperStyle, placeholder, searchPromptText,
            noResultsText, selectMultiple, selectValue, validationText,
            validationState, validationScroll, validationStyle, style,
            hideArrow, closeMenuAfterChoice, menuStyle, valueStyle,
            labelStyle, hintStyle,
        } = this.props;
        return (
            <Select.Async
                autoBlur={ autoBlur }
                cache={ false }
                filterOptions={ this.filterOptions }
                inputProps={ { maxLength: 40, ...inputProps } }
                menuContainerStyle={ { flexGrow: '1' } }
                loadOptions={ this.getSuggestions }
                valueRenderer={ this.renderValue }
                onChange={ this.handleChange }
                optionRenderer={ renderOptions || this.renderOptions }
                autoFocus={ autoFocus }
                disabled={ disabled }
                clearable={ clearable }
                backspaceRemoves={ backspaceRemoves }
                deleteRemoves={ deleteRemoves }
                fullWidth={ fullWidth }
                wrapperStyle={ wrapperStyle }
                placeholder={ placeholder }
                searchPromptText={ searchPromptText }
                noResultsText={ noResultsText }
                multi={ selectMultiple }
                value={ selectValue }
                validationText={ validationText }
                validationState={ validationState }
                validationScroll={ validationScroll }
                validationStyle={ validationStyle }
                style={ style }
                hideArrow={ hideArrow }
                closeMenuAfterChoice={ closeMenuAfterChoice }
                clearValueText="Очистить"
                clearAllText="Очистить"
                menuStyle={ menuStyle }
                valueStyle={ valueStyle }
                labelStyle={ labelStyle }
                hintStyle={ hintStyle }
            />
        );
    }
}
