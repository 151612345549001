import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, IUIPropTypes, Select } from 'intdev-ui';
import { callApi } from '../../middlewares/apiMiddleware';
import { setLocalStorageText } from '../../helpers/localStorage';
import { showErrorNotification } from '../../helpers/showNotification';
import { EXTERNAL_CALENDARS_URL } from './urls';
import { styles } from './styles';
import { LOCAL_STORAGE_EXTERNAL_CALENDAR } from './constants';
import { getCalendars, getLastOrFirstCalendar } from './helpers';


export const calendarShape = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
});


export class ExternalCalendars extends React.Component {
    static propTypes = {
        userId: PropTypes.number,
        calendar: calendarShape,
        exportEnabled: PropTypes.bool,
        onChangeCalendar: PropTypes.func.isRequired,
        onChangeExport: PropTypes.func.isRequired,
        showLabel: PropTypes.bool,
        disabled: PropTypes.bool,
        checkboxStyle: IUIPropTypes.style,
        checkboxLabelStyle: IUIPropTypes.style,
        calendarsContainerStyle: IUIPropTypes.style,
        calendarsLabelStyle: IUIPropTypes.style,
    };

    static defaultProps = {
        userId: null,
        calendar: null,
        exportEnabled: true,
        disabled: false,
        showLabel: true,
        checkboxStyle: null,
        checkboxLabelStyle: null,
        calendarsContainerStyle: null,
        calendarsLabelStyle: null,
    };

    constructor(props) {
        super(props);
        const calendars = props.calendar
            ? [{ value: props.calendar, label: 'по умолчанию' }]
            : [];
        this.state = {
            calendars,
            isLoading: true,
            errorOccurred: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
        const { userId } = this.props;
        if (userId) {
            this.fetchCalendars(userId);
        }
    }

    componentDidUpdate = (prevProps) => {
        const { userId } = this.props;
        if (userId !== prevProps.userId) {
            if (userId) {
                this.fetchCalendars(userId);
            } else {
                this.resetCalendars();
            }
        }
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    resetCalendars = () => {
        this.setState({ calendars: [] });
        this.props.onChangeExport(false);
        this.props.onChangeCalendar(null);
    };

    fetchCalendars = (userId) => {
        this.setState({ isLoading: true });
        callApi(EXTERNAL_CALENDARS_URL(userId)).then((data) => {
            if (!this.mounted) {
                return;
            }

            const calendars = getCalendars(data);
            this.setState({ calendars, isLoading: false });

            const calendar = getLastOrFirstCalendar(calendars);
            if (calendar !== this.props.calendar) {
                this.props.onChangeCalendar(calendar);
            }

            const exportEnabled = this.props.exportEnabled && !!calendar;
            if (exportEnabled !== this.props.exportEnabled) {
                this.props.onChangeExport(exportEnabled);
            }
        }).catch(() => {
            showErrorNotification('Не удалось загрузить календари Mail.ru');
            this.setState({ isLoading: false, errorOccurred: true });
        });
    };

    handleCheckboxChange = (event) => {
        const { checked } = event.target;
        this.props.onChangeExport(checked);
    };

    handleCalendarChange = (calendar) => {
        if (calendar && calendar.value) {
            setLocalStorageText(calendar.value, LOCAL_STORAGE_EXTERNAL_CALENDAR);
        }
        this.props.onChangeCalendar(calendar);
    };

    render() {
        const {
            userId,
            calendar,
            exportEnabled,
            disabled,
            showLabel,
            checkboxStyle,
            calendarsContainerStyle,
            calendarsLabelStyle,
        } = this.props;

        const {
            calendars,
            isLoading,
            errorOccurred,
        } = this.state;

        let checkboxLabelStyle = disabled || isLoading
            ? styles.checkboxLabelDisabled
            : styles.checkboxLabel;
        checkboxLabelStyle = { ...checkboxLabelStyle, ...this.props.checkboxLabelStyle };

        let message;
        if (!calendars.length && !disabled && !isLoading && userId) {
            const messageStyle = {
                ...styles.externalCalendarsMessage,
                ...(errorOccurred && styles.externalCalendarsError),
            };
            const messageText = errorOccurred
                ? 'Не удалось загрузить список календарей'
                : 'У тебя еще нет календарей';
            message = (
                <div style={ messageStyle }>
                    { messageText }
                </div>
            );
        }

        return (
            <div className="external-calendars">
                <label style={ { ...styles.checkbox, ...checkboxStyle } }>
                    <Checkbox
                        onChange={ this.handleCheckboxChange }
                        checked={ exportEnabled && !!calendars.length }
                        disabled={ !calendars.length || disabled }
                    />
                    <div style={ checkboxLabelStyle }>
                        Экспорт в Календарь Mail.Ru
                    </div>
                </label>
                <div style={ { ...styles.externalCalendars, ...calendarsContainerStyle } }>
                    {
                        showLabel &&
                        <div style={ { ...styles.externalCalendarsLabel, ...calendarsLabelStyle } }>
                            Календарь
                        </div>
                    }
                    <Select
                        disabled={ disabled || isLoading || !exportEnabled || !calendars.length }
                        value={ calendar }
                        options={ calendars }
                        onChange={ this.handleCalendarChange }
                        clearable={ false }
                        noResultsText="Нет результатов"
                        placeholder=""
                        fullWidth
                    />
                    { message }
                </div>
            </div>
        );
    }
}
