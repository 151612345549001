import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const EyeCrossedOut = props => (<SvgIcon { ...props }>
    <path fill="currentColor" d="M11.949 2.578L13.527 1A1.041 1.041 0 1 1 15 2.473L2.473 15A1.041 1.041 0 1 1 1 13.527l1.409-1.409C.823 10.566 0 8.707 0 8c0-1.302 2.698-6.512 8-6.512 1.536 0 2.853.437 3.949 1.09zm2.573 2.314C15.505 6.167 16 7.442 16 8c0 1.302-2.791 6.512-8 6.512a7.452 7.452 0 0 1-2.626-.472l2.331-2.331a3.731 3.731 0 0 0 4.004-4.004l2.813-2.813zm-4.735-.153A3.688 3.688 0 0 0 8 4.279 3.731 3.731 0 0 0 4.279 8c0 .647.167 1.256.46 1.787l1.319-1.318A1.931 1.931 0 0 1 6 8c0-1.143.857-2 2-2 .163 0 .321.019.471.056l1.316-1.317z" />
</SvgIcon>);
/* eslint-enable max-len */

EyeCrossedOut.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default EyeCrossedOut;
