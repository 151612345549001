import React from 'react';
import { SvgIcon } from 'intdev-ui/lib/SvgIcon/SvgIcon';

/* eslint-disable max-len */
const CloseRoundButton = props => (<SvgIcon { ...props }>
    <path d="M8 .012A7.993 7.993 0 0 1 15.988 8 7.993 7.993 0 0 1 8 15.988 7.993 7.993 0 0 1 .012 8 7.993 7.993 0 0 1 8 .012zm0 6.565L6.185 4.762a1.008 1.008 0 0 0-1.423 0 1.008 1.008 0 0 0 0 1.423L6.577 8 4.769 9.808a1.008 1.008 0 0 0 0 1.423 1.008 1.008 0 0 0 1.423 0L8 9.423l1.815 1.815c.392.392 1.03.392 1.423 0a1.008 1.008 0 0 0 0-1.423L9.423 8l1.808-1.808a1.008 1.008 0 0 0 0-1.423 1.008 1.008 0 0 0-1.423 0L8 6.577z" />
</SvgIcon>);
/* eslint-enable max-len */

CloseRoundButton.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: '#efefef',
    hoverColor: '#fc2c38',
};

export default CloseRoundButton;
