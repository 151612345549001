import update from 'immutability-helper';
import {
    LOAD_PROJECT_CONSTANTS_FAIL,
    LOAD_PROJECT_CONSTANTS_START,
    LOAD_PROJECT_CONSTANTS_SUCCESS,
} from '../constants/actionConstants';


update.extend('$autoObject', (value, object) => (object ? update(object, value) : update({}, value)));

const initialState = {
    settings: {},
    db_options: {},
};

export const projectConstants = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PROJECT_CONSTANTS_START:
            return update(state, {
                isFetching: { $set: true },
            });
        case LOAD_PROJECT_CONSTANTS_SUCCESS: {
            let newState = update(state, {
                isFetching: { $set: false },
            });
            for (const key of Object.keys(action.payload)) {
                newState = update(newState, {
                    [key]: { $autoObject: { $merge: action.payload[key] } },
                });
            }
            return newState;
        }
        case LOAD_PROJECT_CONSTANTS_FAIL:
            return update(state, {
                isFetching: { $set: false },
            });

        default:
            return state;
    }
};
