import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { Record as BasicRecord } from './Record';

export class CongratulationRecord extends React.Component {
    static propTypes = {
        authUserData: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
        to_user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        action: PropTypes.string.isRequired,
        occasion: PropTypes.string.isRequired,
        picture_url: PropTypes.string.isRequired,
        to_user_name: PropTypes.string.isRequired,
        to_user_url: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
    };

    render() {
        let recordHead = null;
        if (this.props.authUserData.id === this.props.to_user_id) {
            recordHead = `${this.props.action} тебя ${this.props.occasion}`;
        } else {
            recordHead = (
                <span>
                    <span>{ this.props.action } </span>
                    <A
                        href={ this.props.to_user_url }
                        className="inline-link"
                    >
                        { this.props.to_user_name }
                    </A>
                    <span> { this.props.occasion }</span>
                </span>
            );
        }

        let message = null;
        if (this.props.message) {
            message = (
                <div
                    style={ { flexGrow: 1 } }
                    dangerouslySetInnerHTML={ { __html: this.props.message } }
                />
            );
        }

        const recordBody = (
            <div style={ { display: 'flex', marginTop: 10 } }>
                <div style={ { marginRight: 10 } }>
                    <A href={ this.props.picture_url }>
                        <img src={ this.props.picture_url } alt={ this.props.header } />
                    </A>
                </div>
                { message }
            </div>
        );

        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ recordBody }
                { ...this.props }
            />
        );
    }
}
