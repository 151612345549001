import React from 'react';
import { A } from 'intdev-ui';
import PropTypes from 'prop-types';
import { isMobile } from '@/common/mediaQueries';
import { media } from '../../media';
import { Record as BasicRecord } from '../Record';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer/UserTooltipContainer';
import { apiUrls } from '../../../user_detail/apiUrls';
import { SubscribeToggle } from '../../../common/components/SubscribeToggle';
import { topAuthorPageUrl } from '../../../top_authors/constants';
import { dictionary } from '@/common/localization/dictionary';


const styles = {
    recordContent: {
        marginLeft: 0,
    },
    subscribeLabel: {
        fontSize: '14px',
        color: 'rgb(153, 153, 153)',
    },
    subscribeLabelMobile: {
        display: 'none',
    },
};


export class FollowingRecommendation extends React.Component {
    static propTypes = {
        users_data: PropTypes.shape(),
        date: PropTypes.string.isRequired,
        authUserData: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
        record_id: PropTypes.number.isRequired,
        user_ids: PropTypes.arrayOf(PropTypes.number),
    };

    static defaultProps = {
        users_data: {},
        user_ids: [],
    };

    renderRecord = ({
        username, avatar, full_name: fullName, is_followed: isFollowed,
    }) => (
        <div
            key={ username }
            className="recommendation-record-user-container"
        >
            <UserTooltipContainer
                username={ username }
                withAvatar={ false }
                allowSubscription={ false }
                horizontal="middle"
                rootStyle={ media.followingRecommendationTooltipContainer }
            >
                <img src={ avatar } className="recommendation-record-avatar" />
                <a id="recommendation-record-link" href={ `/users/${username}/` }>
                    { fullName }
                </a>
            </UserTooltipContainer>
            <SubscribeToggle
                subscribeUrl={ apiUrls.getUserFollowUrl(username) }
                unSubscribeUrl={ apiUrls.getUserUnfollowUrl(username) }
                isSubscribed={ isFollowed }
                rootStyle={ isMobile ? { justifyContent: 'flex-start' } : { justifyContent: 'space-between' } }
                labelStyle={ isMobile ? styles.subscribeLabelMobile : styles.subscribeLabel }
                method="post"
                label={ dictionary.subscription }
                toggleSize={ 20 }
            />
        </div>
    );

    render() {
        const recordHead = (
            <span className="recommendation-record-header">
                <b>{ dictionary.recommendSubscribing }</b>
            </span>
        );
        const recordBody = (
            <div>
                <div className="recommendation-record-body-container">
                    { this.props.user_ids.map(userId => this.renderRecord(this.props.users_data[userId])) }
                </div>
                <div className="recommendation-record-top-authors-info">
                    С топом авторов можно ознакомиться <A href={ topAuthorPageUrl }> здесь</A>
                </div>
            </div>
        );
        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ recordBody }
                contentStyle={ styles.recordContent }
                hide_comments
                hideLikes
                collapsed={ !isMobile }
                hideTime={ isMobile }
                { ...this.props }
            />
        );
    }
}
