import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    TimePicker, Button, Dialog, RadioSelect, Select,
} from 'intdev-ui';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { EVENT_SHAPE } from '../../shapes';
import { showNotification } from '../../../common/helpers/showNotification';
import { apiUrls } from '../../constants/apiUrls';
import { localStorageAvailable } from '../../../common/helpers/localStorage';
import formatDuration from '../../helpers/formatDuration';
import durationFromTime from '../../helpers/durationFromTime';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';
import { PANEL_GLOBAL_ERROR } from './EventEditModal';
import { ExternalCalendars } from '../../../common/components/ExternalCalendars';

const REMIND_NO = '0';
const REMIND_CUSTOM = '3';

const styles = {
    actionsContainerStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogTitle: {
        borderBottom: '1px solid #EBEBEB',
    },
    dialogTitleDiv: {
        width: '100%',
    },
    remindBody: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '80px',
        margin: '20px 20px 0px 20px',
    },
    dialogTitleText: {
        display: 'flex',
        whiteSpace: 'nowrap',
        fontSize: '20px',
    },
    dialogTitleTextDiv: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        minHeight: '24px',
    },
    dialogTitleSubText: {
        fontSize: '16px',
        color: '#666666',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    selectElement: {
        margin: '7px 0',
    },
    selectWrapper: {
        paddingBottom: '12px',
    },
    plusDiv: {
        padding: '20px 0',
        borderBottom: '1px solid #EBEBEB',
    },
    plusSelect: {
        width: '250px',
    },
};

const getLocalStorageName = name => `calendar.subscribe_modal.${name}`;

export default class SubscribeModal extends React.Component {
    static propTypes = {
        userId: PropTypes.number.isRequired,
        event: EVENT_SHAPE.isRequired,
        onClose: PropTypes.func,
        smsNotificationsEnabled: PropTypes.bool.isRequired,
        externalCalendarsEnabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        event: null,
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            plus: { label: '0', value: 0 },
            processing: false,
            emailNotification: REMIND_NO,
            emailCustomTime: '00:05',
            smsNotification: REMIND_NO,
            smsCustomTime: '00:05',
            exportEnabled: true,
            externalCalendar: null,
        };

        // if local storage is available and there is saved choices for modal fields, set it
        if (localStorageAvailable()) {
            const choisesValues = this.getRemindChoices().map(choice => choice.value);

            const storage = window.localStorage;
            const emailNotification = storage.getItem(getLocalStorageName('emailNotification'));
            const smsNotification = storage.getItem(getLocalStorageName('smsNotification'));
            const emailCustomTime = storage.getItem(getLocalStorageName('emailCustomTime'));
            const smsCustomTime = storage.getItem(getLocalStorageName('smsCustomTime'));
            const maxRemindDuration = durationFromTime(this.maxCustomRemindTime());

            if (choisesValues.indexOf(emailNotification) !== -1) {
                this.state.emailNotification = emailNotification;
            }

            if (choisesValues.indexOf(smsNotification) !== -1) {
                this.state.smsNotification = smsNotification;
            }

            if (durationFromTime(emailCustomTime).valueOf() < maxRemindDuration.valueOf()) {
                this.state.emailCustomTime = emailCustomTime;
            }

            if (durationFromTime(smsCustomTime).valueOf() < maxRemindDuration.valueOf()) {
                this.state.smsCustomTime = smsCustomTime;
            }
        }
    }

    getRemindChoices = () => {
        const startDate = new Date(this.props.event.startTime);
        const now = new Date();
        const choices = [{ value: REMIND_NO, label: 'Не напоминать' }];
        const timeVariants = [
            { value: 15, label: 'За 15 минут' },
            { value: 60, label: 'За час' },
            { value: 24 * 60, label: 'За 1 день' },
        ];
        const msToMin = ms => ms / 1000 / 60;
        const startDayMorning = new Date(startDate);
        const beforeStartDayMorning = new Date(startDate);
        startDayMorning.setHours(8);
        startDayMorning.setMinutes(0);
        beforeStartDayMorning.setHours(8);
        beforeStartDayMorning.setMinutes(0);
        beforeStartDayMorning.setDate(startDate.getDate() - 1);

        for (const timeChoice of timeVariants) {
            if (msToMin(startDate - now) > timeChoice.value) {
                choices.push(timeChoice);
            }
        }

        if (now < startDayMorning && startDate > startDayMorning) {
            choices.push({ value: 1, label: 'Утром в день события' });
        }

        if (now < beforeStartDayMorning) {
            choices.push({ value: 2, label: 'Утром за день до события' });
        }

        choices.push({ value: REMIND_CUSTOM, label: 'Свое время' });

        return choices.map(el => ({ value: `${el.value}`, label: el.label }));
    };

    handleRequestClose = () => {
        this.close();
    };

    handleSave = () => {
        this.setState({ processing: true });

        const {
            smsNotificationsEnabled,
            externalCalendarsEnabled,
        } = this.props;

        const {
            emailNotification,
            smsNotification,
            smsCustomTime,
            emailCustomTime,
            plus,
            exportEnabled,
            externalCalendar,
        } = this.state;

        const requestData = {
            people: 1,
        };

        if (emailNotification === REMIND_CUSTOM) {
            requestData.email_timedelta = moment.duration(emailCustomTime).asMinutes();
        } else if (emailNotification === REMIND_NO) {
            requestData.email_timedelta = null;
        } else {
            requestData.email_timedelta = emailNotification;
        }

        if (smsNotificationsEnabled) {
            if (smsNotification === REMIND_CUSTOM) {
                requestData.sms_timedelta = moment.duration(smsCustomTime).asMinutes();
            } else if (smsNotification === REMIND_NO) {
                requestData.sms_timedelta = null;
            } else {
                requestData.sms_timedelta = smsNotification;
            }
        }

        if (this.props.event.canPlus) {
            requestData.people += plus.value;
        }

        if (externalCalendarsEnabled && exportEnabled && externalCalendar) {
            requestData.calendar_id = externalCalendar.value;
        }

        callApi(apiUrls().meetingJoin(this.props.event.meetingId), 'POST', requestData).then((data) => {
            const error = objCamelFromSnake(data)[PANEL_GLOBAL_ERROR];
            if (error) {
                showNotification({ message: error, level: 'error' });
            } else if (!data.fail) {
                showNotification({ message: 'Ты записался', level: 'success' });
                this.close();
            }
        }).catch((data) => {
            const error = objCamelFromSnake(data)[PANEL_GLOBAL_ERROR];
            if (error) {
                showNotification({ message: error, level: 'error' });
            } else {
                showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
            }
            this.close();
        });
    };

    close = () => {
        this.props.onClose();
    };

    handleCancel = () => {
        this.close();
    };

    handleFieldChange = (event) => {
        const { target } = event;
        if (target.pattern && target.value.match(target.pattern)[0] !== target.value) {
            return;
        }
        const saveForNames = [
            'emailNotification',
            'smsNotification',
            'emailCustomTime',
            'smsCustomTime',
        ];
        if (saveForNames.indexOf(event.target.name) !== -1) {
            window.localStorage.setItem(getLocalStorageName(event.target.name), event.target.value);
        }
        this.setState({
            [target.name]: target.value,
        });
    };

    maxCustomRemindTime = () => {
        const defaultMaxTime = moment.duration((100 * 60) + 59, 'minutes');
        const minTime = moment.duration(5, 'minutes');
        const timeToEvent = moment.duration(moment(this.props.event.startTime) - moment());
        if (timeToEvent.valueOf() > defaultMaxTime.valueOf()) {
            return formatDuration(defaultMaxTime);
        } if (timeToEvent.valueOf() < minTime.valueOf()) {
            return formatDuration(minTime);
        }
        return formatDuration(timeToEvent);
    };

    handleChangeCalendarCheckbox = (value) => {
        this.setState({ exportEnabled: value });
    };

    handleChangeCalendar = (value) => {
        this.setState({ externalCalendar: value });
    };

    render() {
        const {
            event,
            smsNotificationsEnabled,
            externalCalendarsEnabled,
        } = this.props;

        const startMoment = moment(event.startTime);
        const endMoment = moment(event.endTime);
        startMoment.locale('ru');
        return (
            <Dialog
                open
                autoDetectWindowHeight
                autoScrollBodyContent
                title={ (
                    <div style={ styles.dialogTitleDiv }>
                        <div style={ styles.dialogTitleText }>
                            { event.series ? 'Запись на серию событий ' : 'Запись на событие ' }
                            «
                            <div style={ styles.dialogTitleTextDiv }>{ event.name }</div>
                            »
                        </div>
                        <div style={ styles.dialogTitleSubText }>
                            {startMoment.format('D MMMM YYYY года с HH:mm')} до {endMoment.format('HH:mm')}
                        </div>
                    </div>
                ) }
                titleStyle={ styles.dialogTitle }
                onRequestClose={ this.handleRequestClose }
                actionsContainerStyle={ styles.actionsContainerStyle }
                actions={ [
                    <Button
                        label="Отмена"
                        disabled={ this.state.processing }
                        onClick={ this.handleCancel }
                    />,
                    <Button
                        label="Сохранить"
                        primary
                        keyboardFocused
                        disabled={ this.state.processing }
                        onClick={ this.handleSave }
                    />,
                ] }
            >
                { !!event.canPlus
                && (
                    <div style={ styles.plusDiv }>
                        <div style={ styles.label }>Со мной идут</div>
                        <Select
                            name="plus"
                            disabled={ this.state.processing }
                            value={ this.state.plus }
                            placeholder="Выбери..."
                            options={
                                Array.from(
                                    {
                                        length: event.capacity ? Math.min(
                                            event.canPlus,
                                            Math.max(0, (event.capacity || 0) - event.participantsCount - 1),
                                        ) + 1 : event.canPlus + 1,
                                    },
                                    (_, i) => ({ value: i, label: String(i) }),
                                )
                            }
                            style={ styles.plusSelect }
                            onChange={ value => this.handleFieldChange({
                                target: { name: 'plus', value },
                            }) }
                        />
                    </div>
                )}
                <div style={ styles.remindBody }>
                    {/* <div> */}
                    {/*    <div style={ styles.label }>Напомнить по email</div> */}
                    {/*    <RadioSelect */}
                    {/*        name="emailNotification" */}
                    {/*        value={ this.state.emailNotification } */}
                    {/*        options={ this.getRemindChoices() } */}
                    {/*        onChange={ this.handleFieldChange } */}
                    {/*        disabled={ this.state.processing } */}
                    {/*        style={ styles.selectElement } */}
                    {/*        wrapperStyle={ styles.selectWrapper } */}
                    {/*    /> */}
                    {/*    <div> */}
                    {/*        <div style={ styles.label }>Напомнить за</div> */}
                    {/*        <TimePicker */}
                    {/*            name="emailCustomTime" */}
                    {/*            minutesStep={ 5 } */}
                    {/*            showTimeUnits */}
                    {/*            minTime={ '00:05' } */}
                    {/*            maxTime={ this.maxCustomRemindTime() } */}
                    {/*            disabled={ */}
                    {/* eslint-disable-next-line max-len */}
                    {/*                this.state.emailNotification !== REMIND_CUSTOM.toString() || this.state.processing */}
                    {/*            } */}
                    {/*            value={ this.state.emailCustomTime } */}
                    {/*            onChange={ this.handleFieldChange } */}
                    {/*            fullWidth */}
                    {/*        /> */}
                    {/*    </div> */}
                    {/* </div> */}
                    {
                        smsNotificationsEnabled
                        && (
                            <div>
                                <div style={ styles.label }>Напомнить по sms</div>
                                <RadioSelect
                                    name="smsNotification"
                                    value={ this.state.smsNotification }
                                    options={ this.getRemindChoices() }
                                    onChange={ this.handleFieldChange }
                                    disabled={ this.state.processing }
                                    style={ styles.selectElement }
                                    wrapperStyle={ styles.selectWrapper }
                                />
                                <div>
                                    <div style={ styles.label }>Напомнить за</div>
                                    <TimePicker
                                        name="smsCustomTime"
                                        minutesStep={ 5 }
                                        showTimeUnits
                                        minTime="00:05"
                                        maxTime={ this.maxCustomRemindTime() }
                                        disabled={
                                            this.state.smsNotification !== REMIND_CUSTOM.toString() || this.state.processing
                                        }
                                        value={ this.state.smsCustomTime }
                                        onChange={ this.handleFieldChange }
                                        fullWidth
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    externalCalendarsEnabled
                    && (
                        <div style={ { marginTop: '16px', width: '50%' } }>
                            <ExternalCalendars
                                userId={ this.props.userId }
                                calendar={ this.state.externalCalendar }
                                exportEnabled={ this.state.exportEnabled }
                                onChangeExport={ this.handleChangeCalendarCheckbox }
                                onChangeCalendar={ this.handleChangeCalendar }
                                showLabel={ false }
                                disabled={ this.state.processing }
                            />
                        </div>
                    )
                }
            </Dialog>
        );
    }
}
