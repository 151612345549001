import PropTypes from 'prop-types';
import React, { Component } from 'react';
import $ from 'jquery';
import redactorInitializer from '@/redactor';
import { isMobile } from '@/common/mediaQueries';


export class SayThanksTextArea extends Component {
    static propTypes = {
        thanksText: PropTypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        onFocus: PropTypes.func,
    };

    static defaultProps = {
        thanksText: null,
        onFocus: null,
    };

    async componentDidMount() {
        const that = this;
        const textarea = $('.thanks-textarea');
        const plugins = ['iconic', 'mention'];
        let buttons = ['format', 'lists', 'link', 'bold', 'italic', 'deleted'];
        if (isMobile) {
            buttons = buttons.filter(button => !(['lists', 'deleted'].includes(button)));
        }
        const redactorConfig = {
            lang: 'ru',
            urlUploads: '/uploads/url-upload/',
            plugins,
            toolbarFixed: !isMobile,
            buttons,
            formatting: ['p', 'blockquote', 'pre', 'h1', 'h2'],
            minHeight: 100,
            pasteImages: false,
            callbacks: {
                focus() {
                    if (that.props.onFocus) that.props.onFocus();
                },
            },
        };
        await redactorInitializer(plugins);
        textarea.redactor(redactorConfig);
        if (this.props.thanksText) {
            textarea.redactor('code.set', this.props.thanksText);
        }
    }

    render() {
        return (
            <textarea
                className="thanks-textarea"
            />
        );
    }
}
