import React from 'react';
import PropTypes from 'prop-types';
import { TimelineRecordBody } from '@/pecocracy/components/Timeline/TimelineRecordBody';
import { Record as BasicRecord } from './Record';


export const PecocracyStoryRecord = React.forwardRef(({
    text, sticker, attachments, ...rest
}, ref) => (
    <BasicRecord
        recordBody={ <TimelineRecordBody text={ text } sticker={ sticker } attachments={ attachments } /> }
        { ...rest }
        ref={ ref }
        collapsed={ false }
    />
));

PecocracyStoryRecord.propTypes = {
    text: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired,
    sticker: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
};

PecocracyStoryRecord.defaultProps = {
    sticker: null,
};
