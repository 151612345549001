import React from 'react';
import PropTypes from 'prop-types';
import { A, Spinner, UserInfo } from 'intdev-ui';
import { SubscribeToggle } from '../SubscribeToggle';
import { getUserFollowUrl, getUserUnfollowUrl } from './constants';

const styles = {
    popover: {
        display: 'flex',
        padding: '6px',
        width: '250px',
        fontSize: '12px',
    },
    name: {
        fontSize: '1.1em',
        margin: '0 0 2px 0',
    },
    appointment: {
        fontSize: '0.9em',
        margin: '0 0 2px 0',
    },
    notActive: {
        color: '#FF9F2B',
        margin: '0 0 2px 0',
    },
    unit: {
        fontSize: '0.9em',
        margin: '0 0 5px 0',
        color: '#999',
    },
    position: {
        fontSize: '0.9em',
        margin: '0 0 2px 0',
    },
    location: {
        display: 'inline-block',
    },
    contacts: {
        margin: '0 0 2px 0',
    },
    subscribeLabel: {
        marginRight: '8px',
        color: 'inherit',
        fontSize: 'inherit',
    },
    subscribeRoot: {
        fontSize: '0.9em',
    },
    followsYouLabel: {
        color: '#999',
        fontSize: '0.9em',
        margin: '2px 0 0 0',
    },
};


export class UserTooltipContent extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        userData: PropTypes.shape({
            fullName: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            unit: PropTypes.string,
            position: PropTypes.string,
            appointment: PropTypes.string,
            avatar: PropTypes.string,
            isSelfProfile: PropTypes.bool,
            isFollowing: PropTypes.bool,
            followsYou: PropTypes.bool,
            isMale: PropTypes.bool,
        }),
        withAvatar: PropTypes.bool.isRequired,
        withName: PropTypes.bool.isRequired,
        avatarSize: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
        changeUserData: PropTypes.func.isRequired,
        allowSubscription: PropTypes.bool,
    };

    static defaultProps = {
        userData: null,
        allowSubscription: true,
    };

    get canSubscribe() {
        const {
            userData,
            allowSubscription,
        } = this.props;
        return allowSubscription && !userData.isSelfProfile && userData.isActive;
    }

    render = () => {
        const {
            userData,
            withAvatar,
            withName,
            avatarSize,
            username,
            loading,
            changeUserData,
        } = this.props;

        const serviceAccount = userData && !userData.appointment && !userData.unit && !userData.position;

        const followsYouLabel = userData && userData.isMale
            ? 'подписан на тебя'
            : 'подписана на тебя';

        const loader = (
            <div
                style={ {
                    height: '65px',
                    width: '240px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                } }
            >
                <Spinner />
            </div>
        );

        return (
            <div style={ styles.popover }>
                { loading && loader }
                {
                    !loading
                    && (
                        <div style={ { display: 'flex' } }>
                            {
                                withAvatar
                                && (
                                    <A href={ userData.url }>
                                        <UserInfo
                                            avatar={ userData.avatar }
                                            avatarSize={ avatarSize }
                                            fullname={ userData.fullName }
                                            hideFullname
                                        />
                                    </A>
                                )
                            }
                            <div>
                                { !withAvatar
                                && <A style={ styles.name } href={ userData.url }>{ userData.fullName }</A>}
                                {
                                    ((withAvatar && withName) || serviceAccount)
                                    && <p style={ styles.name }>{userData.fullName}</p>
                                }
                                { userData.isActive === false
                                && <p style={ styles.notActive }>Не работает в компании</p> }
                                { userData.appointment && <p style={ styles.appointment }>{userData.appointment}</p> }
                                { userData.unit && <p style={ styles.unit }>{userData.unit}</p> }
                                { userData.position
                                && (
                                    <div style={ styles.position }>
                                        {
                                            userData
                                                .position
                                                .split(', ')
                                                .map((pos, i, arr) => (
                                                    <span key={ pos } style={ styles.location }>
                                                        {pos}
                                                        {i === arr.length - 1 ? '' : ','}
                                                        &ensp;
                                                    </span>
                                                ))
                                        }
                                    </div>
                                ) }
                                {
                                    this.canSubscribe
                                    && (
                                        <SubscribeToggle
                                            subscribeUrl={ getUserFollowUrl(username) }
                                            unSubscribeUrl={ getUserUnfollowUrl(username) }
                                            isSubscribed={ userData.isFollowing }
                                            rootStyle={ styles.subscribeRoot }
                                            labelStyle={ styles.subscribeLabel }
                                            toggleSize={ 16 }
                                            onSuccess={ () => changeUserData('isFollowing', !userData.isFollowing) }
                                            method="POST"
                                        />
                                    )
                                }
                                { userData.followsYou && <p style={ styles.followsYouLabel }>{ followsYouLabel }</p>}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
