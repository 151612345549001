import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import UserTooltipContainer from '@/common/components/UserTooltipContainer';
import { COIN_ICON } from '../../../store/constants/constants';

const thanksUserShape = PropTypes.shape({
    username: PropTypes.string.isRequired,
    squareAvatar: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

const thanksRecord = {
    fromUser: thanksUserShape.isRequired,
    toUser: thanksUserShape.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    coins: PropTypes.number,
    text: PropTypes.string.isRequired,
    thankType: PropTypes.number,
};

const stripTags = new RegExp(/(<([^>]+)>)/, 'ig');

const getThanksText = (text) => {
    const title = text || null;
    return (title || '').replace(stripTags, '');
};


export class AggregatedThanksRecord extends React.Component {
    static propTypes = {
        contentTypeId: PropTypes.number.isRequired,
        ...thanksRecord,
    };

    static defaultProps = {
        coins: null,
    };

    renderUser = (userData) => {
        const userStyle = { backgroundImage: `url(${userData.squareAvatar})` };
        return (
            <UserTooltipContainer username={ userData.username }>
                <A href={ userData.url }>
                    <div className="b-thanks__avatar" style={ userStyle } />
                    <div className="b-thanks__username">{userData.firstName}</div>
                </A>
            </UserTooltipContainer>
        );
    };

    render() {
        const fromUser = this.props.fromUser;
        const toUser = this.props.toUser;

        const centerElemClass = `${'b-thanks__badge'}${this.props.text ? ' timeline-thanks-tooltip' : ''}`;
        const centerElemStyle = { backgroundImage: `url(${this.props.image})` };
        const centerElemTitle = getThanksText(this.props.text);

        const coins = this.props.coins ? (
            <div className="b-thanks__coins" title="Коины в благодарности">
                <span style={ { color: '#f44e4e', fontSize: '16px', marginRight: '1px' } }>{this.props.coins}</span>
                <img src={ COIN_ICON } alt="коинов" style={ { width: '13px', height: '13px', marginTop: '3px' } } />
            </div>
        ) : null;

        return (
            <div className="b-thanks__item" key={ this.props.id }>
                <div className="b-thanks__blockquote b-flexrow">
                    <div className="b-thanks__from_user">
                        { this.renderUser(fromUser) }
                    </div>
                    <div>
                        <A href={ this.props.url }>
                            <div
                                className={ centerElemClass }
                                style={ centerElemStyle }
                                title={ centerElemTitle || this.props.thankType }
                            />
                        </A>
                        <div style={ { display: 'flex', justifyContent: 'space-evenly' } }>
                            <div className="b-thanks__arrow">&#8594;</div>
                            {coins}
                        </div>
                    </div>
                    <div className="b-thanks__to_user">
                        { this.renderUser(toUser) }
                    </div>
                </div>
            </div>
        );
    }
}
