/* eslint-disable no-bitwise */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { A, IUIPropTypes, Popover } from 'intdev-ui';
import shallowEqual from 'react-redux/es/utils/shallowEqual';
import { PANEL_GLOBAL_ERROR } from './modals/EventEditModal';

import infinityGrey from '../../../img/calendar/infinity_grey.svg';
import infinityWhite from '../../../img/calendar/infinity_white.svg';
import { CALENDAR_CONFIG_SHAPE_ITEMS, EVENT_SHAPE } from '../shapes';
import pluralized from '../../common/helpers/pluralized';
import IconFaButton from '../../common/components/IconFaButton';
import PopoverContainer from '../../common/components/PopoverContainer';
import {
    calendarBell,
    meetingLeave,
    modalOpen,
    mouseOutSeries,
    mouseOverSeries,
    toggleFooter,
} from '../actions';
import { showNotification } from '../../common/helpers/showNotification';
import {
    MODAL_COPY,
    MODAL_DELETE,
    MODAL_EDIT,
    MODAL_NOTIFY,
    MODAL_SUBSCRIBE,
} from '../constants/modalTypes';
import { objCamelFromSnake } from '../../common/helpers/objCamelFromSnake';

function numberHash(x) {
    let hash = x | 0;
    hash -= (hash << 6);
    hash ^= (hash >>> 17);
    hash -= (hash << 9);
    hash ^= (hash << 4);
    hash -= (hash << 3);
    hash ^= (hash << 10);
    hash ^= (hash >>> 15);
    return Math.abs(hash);
}

const lightColorHash = (x) => {
    const hash = numberHash(x);
    const h = hash % 360;
    const l = 95;
    return `hsl(${h}, 100%, ${l}%)`;
};

const slightlyLesslightColorHash = (x) => {
    const hash = numberHash(x);
    const h = hash % 360;
    return `hsl(${h}, 77%, 80%)`;
};

const getStyles = (event, props) => {
    const styles = {
        body: {
            fontSize: '18px',
            border: '1px solid #DDD',
            background: 'white',
            position: 'relative',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
        },
        colorLabel: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '12px',
            height: '100%',
        },
        contentDiv: {
            display: 'flex',
            flex: '1 1 auto',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
        },
        controlDiv: {
            flex: '1 1 112px',
            alignItems: 'center',
            paddingRight: '4px',
            justifyContent: 'space-between',
            display: 'flex',
            height: '38px',
        },
        timeDiv: {
            paddingLeft: '12px',
            lineHeight: '20px',
            flex: '1 1 56px',
            minWidth: '56px',
            maxWidth: '100px',
            display: 'flex',
        },
        countDiv: {
            position: 'relative',
            display: 'flex',
            flex: '0 0 auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '4px',
            color: '#999',
            fontSize: '14px',
            letterSpacing: '-0.5pt',
            textIndent: '-0.5pt',
            fontWeight: 'bold',
            lineHeight: '18px',
            minHeight: '20px',
            minWidth: '20px',
            padding: '2px',
            border: '2px solid #999',
            borderRadius: '24px',
            cursor: 'pointer',
        },
        buttonDiv: {
            flex: '0 1 auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            alignSelf: 'center',
        },
        tooltip: {
            textAlign: 'left',
            fontWeight: 'normal',
            letterSpacing: '0',
            fontSize: '15px',
            lineHeight: '16px',
            width: '180px',
        },
        buttonStyleSet: {
            style: {
                flex: '0 1 auto',
                width: '38px',
                height: '38px',
            },
            styleHover: {
            },
            styleClick: {
            },
            styleDisabled: {},
        },
        managementPopoverOption: {
            margin: '17px 20px',
            cursor: 'pointer',
        },
        infinityIcon: {
            width: '16px',
            height: '16px',
        },
    };
    if (event.joined) {
        styles.body.border = '1px solid #34AE52';
        styles.body.backgroundColor = '#3CC75C';
        styles.timeDiv.color = '#FFF';
        styles.countDiv.color = '#FFF';
        styles.countDiv.border = '2px solid #FFF';
        styles.buttonStyleSet.style.color = '#FFF';
        styles.buttonStyleSet.styleHover.color = '#fafafa';
        styles.buttonStyleSet.styleHover.background = '#3edb5d';
        styles.buttonStyleSet.styleClick.color = '#f5f5f5';
        styles.buttonStyleSet.styleClick.background = '#3ee55d';
        styles.buttonStyleSet.styleDisabled.color = '#f0f0f0';
        styles.buttonStyleSet.styleDisabled.background = '#3fd65e';
    }
    if (props && props.hoveredEventSeries && props.hoveredEventSeries === event.series) {
        let color;
        if (event.joined) {
            color = slightlyLesslightColorHash(event.series);
        } else {
            color = lightColorHash(event.series);
        }
        styles.colorLabel.backgroundColor = color;
    }

    return styles;
};

const calculateEventActions = (event, calendarConfig) => {
    const hasPassed = event.hasPassed;

    const edit = ((event.ownerId === calendarConfig.userId) || calendarConfig.canEdit);
    const canCreate = (calendarConfig.contentObjectAutor === calendarConfig.userId) || calendarConfig.canCreate;

    const copy = canCreate && ((event.ownerId === calendarConfig.userId) || calendarConfig.canEdit);
    // there is also check for one notification per day,
    // but backend validation only is fine for this case
    const remind = !hasPassed && edit;

    const isCapacityOk = event.capacity === null || event.capacity > event.participantsCount;
    const isSingleSubscribeOk = !calendarConfig.singleJoin ||
        !calendarConfig.participantsSet.has(calendarConfig.userId);
    const canJoin = isCapacityOk && isSingleSubscribeOk;

    const joined = event.joined;
    const subscribed = event.subscribed;

    const { usersJoinAllowed } = event;
    const currentUserJoinAllowed = !usersJoinAllowed.length || usersJoinAllowed.indexOf(calendarConfig.userId) !== -1;

    return {
        // super action
        copy,
        remind,
        edit,
        // user aciton
        canJoin,
        hasPassed,
        subscribed,
        joined,
        currentUserJoinAllowed,
    };
};

class EventComponent extends React.Component {
    static propTypes = {
        ...CALENDAR_CONFIG_SHAPE_ITEMS,
        event: EVENT_SHAPE.isRequired,
        style: IUIPropTypes.style,
        tooltipBottom: PropTypes.bool,
        hoveredEventSeries: PropTypes.number,
        // dispatch
        onWantButtonClick: PropTypes.func.isRequired,
        onDontWantButtonClick: PropTypes.func.isRequired,
        onParticipantsClick: PropTypes.func.isRequired,
        onSubscribeClick: PropTypes.func.isRequired,
        onUnsubscribeClick: PropTypes.func.isRequired,
        onEditClick: PropTypes.func.isRequired,
        onNotifyClick: PropTypes.func.isRequired,
        onCopyClick: PropTypes.func.isRequired,
        onDeleteClick: PropTypes.func.isRequired,
        handleMouseOverSeries: PropTypes.func.isRequired,
        handleMouseOutSeries: PropTypes.func.isRequired,
    };

    static defaultProps = {
        event: null,
        hoveredEventSeries: null,
        style: {},
        tooltipBottom: false,
    };

    constructor(props) {
        super(props);
        this.popupAnchorRef = null;
        this.state = {
            loading: false,
            isPopupActive: false,
        };
    }

    getActionButton(eventActions) {
        const styles = getStyles(this.props.event);

        switch (true) {
            case (eventActions.hasPassed):
                return null;
            case (eventActions.joined):
                return (<IconFaButton
                    iconClass="fa-minus-circle"
                    title="Не пойду"
                    onClick={ this.handleLeaveButtonClick }
                    disabled={ this.state.loading }
                    { ...styles.buttonStyleSet }
                />);
            case (!eventActions.currentUserJoinAllowed):
                return (
                    <PopoverContainer popoverContent="Запись ограничена">
                        <IconFaButton
                            iconClass="fa-ban"
                            { ...styles.buttonStyleSet }
                            styleDisabled={ { background: 'inherit' } }
                            disabled
                        />
                    </PopoverContainer>
                );
            case (eventActions.canJoin):
                return (<IconFaButton
                    iconClass="fa-plus-circle"
                    title="Записаться"
                    onClick={ this.handleJoinButtonClick }
                    { ...styles.buttonStyleSet }
                />);
            case (!eventActions.subscribed):
                return (<IconFaButton
                    iconClass="fa-bell"
                    title="Сообщить о свободных местах"
                    onClick={ this.handleSubscribeButtonClick }
                    { ...styles.buttonStyleSet }
                />);
            case (eventActions.subscribed):
                return (<IconFaButton
                    iconClass="fa-bell-slash"
                    title="Не сообщать о свободных местах"
                    onClick={ this.handleUnsubscribeButtonClick }
                    { ...styles.buttonStyleSet }
                />);
            default:
                return null;
        }
    }

    getManagementButton(eventActions) {
        const event = this.props.event;
        const managementOptions = [];
        let managementButton;
        const styles = getStyles(event);

        if (eventActions.copy) {
            managementOptions.push(
                <div
                    key={ 'copy' }
                    style={ styles.managementPopoverOption }
                >
                    <A
                        secondary
                        onClick={ this.handleCopyButtonClick }
                    >
                        Копировать
                    </A>
                </div>,
            );
        }
        if (eventActions.remind) {
            managementOptions.push(
                <div
                    key={ 'remind' }
                    style={ styles.managementPopoverOption }
                >
                    <A
                        secondary
                        onClick={ this.handleNotifyButtonClick }
                    >
                        Напомнить
                    </A>
                </div>,
            );
        }
        if (eventActions.edit) {
            managementOptions.push(
                <div
                    key={ 'edit' }
                    style={ styles.managementPopoverOption }
                >
                    <A
                        secondary
                        onClick={ this.handleEditButtonClick }
                    >
                        Редактировать
                    </A>
                </div>,
            );
            managementOptions.push(
                <div
                    key={ 'delete' }
                    style={ styles.managementPopoverOption }
                >
                    <A
                        secondary
                        onClick={ this.handleDeleteButtonClick }
                    >
                        Удалить
                    </A>
                </div>,
            );
        }
        if (managementOptions.length) {
            managementButton = (
                <div ref={ (ref) => {
                    this.popupAnchorRef = ref;
                } }
                >
                    <IconFaButton
                        iconClass="fa-ellipsis-v"
                        title="Управление событием"
                        onClick={ this.handleShowPopup }
                        { ...styles.buttonStyleSet }
                    />
                    <Popover
                        anchorEl={ this.popupAnchorRef }
                        anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
                        targetOrigin={ { horizontal: 'right', vertical: 'top' } }
                        open={ this.state.isPopupActive }
                        onRequestClose={ this.handleHidePopup }
                    >
                        {managementOptions}
                    </Popover>
                </div>
            );
        }
        return managementButton;
    }

    getPopoverContent = () => {
        const event = this.props.event;
        const styles = getStyles(event);

        let freeText;
        if (event.hasPassed) {
            freeText = 'Событие уже прошло';
        } else if (event.capacity !== null) {
            const freeCount = Math.max(event.capacity - event.participantsCount, 0);
            if (freeCount === 0) {
                freeText = 'Места закончились';
            } else {
                freeText = `Свободно ${freeCount} ${pluralized(freeCount, 'мест:место:места')}`;
            }
        } else {
            freeText = 'Количество мест не ограничено';
        }

        let reservedText;
        const reservedCount = Math.max(event.participantsCount, 0);
        if (reservedCount) {
            reservedText = `${pluralized(reservedCount, 'записалось:записался:записалось')} 
                         ${reservedCount} 
                         ${pluralized(reservedCount, 'человек:человек:человека')}.`;
        } else {
            reservedText = `${event.hasPassed ? '' : 'пока '}никто не записался!`;
        }

        return (
            <div style={ styles.tooltip }>
                {freeText},<br />
                {reservedText}
            </div>
        );
    };

    getParticipantsCountContent = () => {
        const event = this.props.event;

        if (event.capacity !== null) {
            const sparePlaces = Math.max(event.capacity - event.participantsCount, 0);
            if (sparePlaces > 99) {
                return '99+';
            }
            return sparePlaces;
        }

        const styles = getStyles(event, this.props);
        const infinityIcon = this.props.event.joined ? infinityWhite : infinityGrey;
        return <img style={ styles.infinityIcon } src={ infinityIcon } alt="" />;
    };

    handleShowPopup = () => {
        this.setState({ isPopupActive: true });
    };

    handleHidePopup = () => {
        this.setState({ isPopupActive: false });
    };

    handleUnsubscribeButtonClick = () => {
        this.props.onDontWantButtonClick().then(() => {
            showNotification({ message: 'Ты отписался от уведомлений', level: 'success' });
        });
    };

    handleSubscribeButtonClick = () => {
        this.props.onWantButtonClick().then(() => {
            showNotification({ message: 'Тебе придет уведомление, если места освободятся', level: 'success' });
        });
    };

    handleEditButtonClick = () => {
        this.handleHidePopup();
        this.props.onEditClick();
    };

    handleCopyButtonClick = () => {
        this.handleHidePopup();
        this.props.onCopyClick();
    };

    handleNotifyButtonClick = () => {
        this.handleHidePopup();
        this.props.onNotifyClick();
    };

    handleDeleteButtonClick = () => {
        this.handleHidePopup();
        this.props.onDeleteClick();
    };

    handleLeaveButtonClick = () => {
        this.setState({ loading: true });
        this.props.onUnsubscribeClick().then((data) => {
            this.setState({ loading: false });
            const error = objCamelFromSnake(data)[PANEL_GLOBAL_ERROR];
            if (error) {
                showNotification({ message: error, level: 'warning' });
            } else if (!data.fail) {
                showNotification({ message: 'Ты отписался', level: 'success' });
            }
        }).catch(() => {
            this.setState({ loading: false });
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
        });
    };

    handleJoinButtonClick = () => {
        this.props.onSubscribeClick();
    };

    handleMouseOverBody = () => {
        if (this.props.event.series) {
            this.props.handleMouseOverSeries();
        }
    };

    handleMouseOutBody = () => {
        if (this.props.event.series) {
            this.props.handleMouseOutSeries();
        }
    };

    render() {
        const {
            event,
        } = this.props;
        const styles = getStyles(event, this.props);
        const startMoment = moment.utc(event.startTime).local();
        const endMoment = moment.utc(event.endTime).local();
        startMoment.locale('ru');
        const eventActions = calculateEventActions(event, this.props);

        return (
            <div
                onMouseEnter={ this.handleMouseOverBody }
                onMouseLeave={ this.handleMouseOutBody }
                style={ {
                    ...styles.body,
                    ...this.props.style,
                } }
            >
                <div style={ styles.colorLabel } />
                <div style={ styles.contentDiv }>
                    <div style={ styles.controlDiv }>
                        <div style={ styles.timeDiv }>
                            {`${startMoment.format('HH:mm')}-${endMoment.format('HH:mm')} `}
                        </div>
                        <div onClick={ this.props.onParticipantsClick }>
                            <PopoverContainer
                                style={ styles.countDiv }
                                popoverContent={ this.getPopoverContent() }
                            >
                                { this.getParticipantsCountContent() }
                            </PopoverContainer>
                        </div>
                    </div>
                    <div style={ styles.buttonDiv }>
                        { this.getActionButton(eventActions) }
                        { this.getManagementButton(eventActions) }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownState) => {
    const {
        hoveredEventSeries,
    } = state.eventsUiState;
    return {
        hoveredEventSeries: ownState.event.series === hoveredEventSeries ? hoveredEventSeries : null,
        ...state.calendarConfig,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleMouseOverSeries: () => dispatch(mouseOverSeries(ownProps.event)),
    handleMouseOutSeries: () => dispatch(mouseOutSeries(ownProps.event)),
    onParticipantsClick: () => dispatch(toggleFooter(ownProps.event)),
    onEditClick: () => dispatch(modalOpen(MODAL_EDIT, ownProps.event)),
    onDeleteClick: () => dispatch(modalOpen(MODAL_DELETE, ownProps.event)),
    onCopyClick: () => dispatch(modalOpen(MODAL_COPY, ownProps.event)),
    onNotifyClick: () => dispatch(modalOpen(MODAL_NOTIFY, ownProps.event)),
    onSubscribeClick: () => dispatch(modalOpen(MODAL_SUBSCRIBE, ownProps.event)),
    onUnsubscribeClick: () => dispatch(meetingLeave(ownProps.event)),
    onWantButtonClick: () => dispatch(calendarBell(ownProps.event.meetingId, true)),
    onDontWantButtonClick: () => dispatch(calendarBell(ownProps.event.meetingId, false)),
});

const Event = connect(mapStateToProps, mapDispatchToProps, null, {
    areOwnPropsEqual: (a, b) => {
        const {
            event: eventA,
            ...otherA
        } = a;
        const {
            event: eventB,
            ...otherB
        } = b;
        return shallowEqual(otherA, otherB) && shallowEqual(eventA, eventB);
    },
})(EventComponent);

export default Event;
