import update from 'immutability-helper';
import { ACTION_TYPES } from '../constants/actionTypes';

const initialState = {
    inProgress: false,
    users: '',
    isDialogOpen: false,
};

export function blogSubscribe(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.OPEN_BULK_SUBSCRIBE_DIALOG: {
            return update(state, { isDialogOpen: { $set: true } });
        }
        case ACTION_TYPES.CLOSE_BULK_SUBSCRIBE_DIALOG: {
            return update(state, {
                isDialogOpen: { $set: false },
                users: { $set: '' }
            });
        }
        case ACTION_TYPES.BULK_SUBSCRIBE_START: {
            return update(state, { inProgress: { $set: true } });
        }
        case ACTION_TYPES.BULK_SUBSCRIBE_SUCCESS: {
            return update(state, {
                inProgress: { $set: false },
                users: { $set: '' },
                isDialogOpen: { $set: false },
            });
        }
        case ACTION_TYPES.BULK_SUBSCRIBE_FAIL: {
            return update(state, { inProgress: { $set: false } });
        }
        case ACTION_TYPES.CHANGE_BULK_SUBSCRIBERS: {
            return update(state, { users: { $set: action.payload } });
        }
        default:
            return state;
    }
}
