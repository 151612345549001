import { ACTION_TYPES } from '../constants/actionConstants';

const defaultState = {
    headerIsShown: true,
    headerImage: null,
    contentImage: null,
    navigationMenuBackgroundColor: '#F8F8FF',
    menuItemTextColor: '#252069',
    menuBottomCellBackground: '#f0f0f0',
};

export const header = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SHOW_HEADER:
            return {
                ...state,
                headerIsShown: true,
            };

        case ACTION_TYPES.HIDE_HEADER:
            return {
                ...state,
                headerIsShown: false,
            };

        case ACTION_TYPES.GET_ACTUAL_THEME_FINISH: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default: return state;
    }
};
