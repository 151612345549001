import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, TextField } from 'intdev-ui';

import { OTHER_ANSWER_VALUE } from '../../constants';
import { commonMedia } from '@/common/media';
import { isMobile } from '@/common/mediaQueries';


class MultiChoiceQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.shape({
            limit_answers: PropTypes.number,
            id: PropTypes.number,
            allow_other: PropTypes.bool.isRequired,
        }).isRequired,
        choicesList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            picture: PropTypes.string,
        })).isRequired,
        value: PropTypes.arrayOf(PropTypes.number),
        onChange: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        rightChoicesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        isRightAnswerDisplayed: PropTypes.bool,
    };

    static defaultProps = {
        value: [],
        isRightAnswerDisplayed: false,
    };

    handleCheckboxChange = (event) => {
        const answer = {};
        const input = event.target;
        const value = parseInt(input.value, 10);
        const currentValue = this.currentValue();
        if (input.checked && currentValue.indexOf(value) === -1) {
            currentValue.push(value);
        } else if (!input.checked) {
            const index = currentValue.indexOf(value);
            if (index > -1) {
                if (value === OTHER_ANSWER_VALUE) {
                    answer.text = null;
                }
                currentValue.splice(index, 1);
            }
        }
        if (this.props.question.limit_answers && currentValue.length > this.props.question.limit_answers) {
            const removedValue = currentValue.shift();
            if (removedValue === OTHER_ANSWER_VALUE) {
                answer.text = null;
            }
        }
        const index = currentValue.indexOf(OTHER_ANSWER_VALUE);
        if (index !== -1) {
            currentValue.splice(index, 1);
        }
        answer.value = currentValue;
        this.props.onChange(event, answer);
    };

    handleOtherAnswerChange = (event, text) => {
        const answer = { text };
        let currentValue = this.currentValue();
        const index = currentValue.indexOf(OTHER_ANSWER_VALUE);
        if (index !== -1) {
            currentValue.splice(index, 1);
        }
        if (this.props.question.limit_answers && currentValue.length >= this.props.question.limit_answers) {
            currentValue = currentValue.slice(1);
        }
        answer.value = currentValue;
        this.props.onChange(event, answer);
    };

    options = () => {
        const options = this.props.choicesList.map(item => ({
            value: item.id,
            label: item.text,
            picture: item.picture,
        }));
        if (this.props.question.allow_other) {
            options.push({
                value: OTHER_ANSWER_VALUE,
                label: '',
                picture: null,
            });
        }
        return options;
    };

    currentValue = () => {
        const currentValue = [...this.props.value];
        if (this.props.question.allow_other && this.props.text && currentValue.indexOf(OTHER_ANSWER_VALUE) === -1) {
            currentValue.push(OTHER_ANSWER_VALUE);
        }
        return currentValue;
    };

    optionRenderer = (choice) => {
        let choiceClassName = 'quiz-detail__text_size_s quiz-detail__choice-label';
        if (choice.value === OTHER_ANSWER_VALUE) {
            return (
                <label className={ choiceClassName }>
                    Другое:
                    <TextField
                        value={ this.props.text }
                        onChange={ this.handleOtherAnswerChange }
                        name={ `otherChoice${this.props.question.id}` }
                        className="quiz-detail__textfield"
                        hintStyle={ commonMedia.textFieldHintStyle }
                        inputStyle={ commonMedia.fontSizeBody }
                    />
                </label>
            );
        }
        if (this.props.isRightAnswerDisplayed && this.props.rightChoicesIds.indexOf(choice.value) !== -1) {
            choiceClassName += ' quiz-detail__right-choice';
        }
        return (
            <div className={ choiceClassName }>
                {choice.label ? <div dangerouslySetInnerHTML={ { __html: choice.label } } /> : null}
                {choice.picture ? <img src={ choice.picture } style={ { maxWidth: '420px' } } /> : null}
            </div>
        );
    };

    render() {
        const value = this.currentValue();
        const choices = this.options().map(choice => (
            <div key={ choice.value } style={ { display: 'flex', alignItems: 'center', margin: '10px 0' } }>
                <div>
                    <Checkbox
                        type="checkbox"
                        value={ choice.value }
                        onChange={ this.handleCheckboxChange }
                        checked={ value.indexOf(choice.value) !== -1 }
                        disabled={ this.props.isRightAnswerDisplayed }
                    />
                </div>
                { this.optionRenderer(choice) }
            </div>
        ));
        return (
            <div>{choices}</div>
        );
    }
}

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const answer = state.answers.tmpAnswers[ownProps.question.id]
        || state.answers.dbAnswers[ownProps.question.id] || {};
    let answerValue = answer.value;
    if (!(answerValue instanceof Array)) {
        answerValue = (answerValue) ? [answerValue] : [];
    }
    return {
        choicesList: ownProps.question.choice_set.map(choiceId => state.choices[choiceId]),
        rightChoicesIds: state.rightChoices[ownProps.question.id] || [],
        text: answer.text || '',
        value: answerValue,
    };
};

export default connect(mapStateToProps)(MultiChoiceQuestion);
