import React from 'react';
import PropTypes from 'prop-types';
import { Button, A } from 'intdev-ui';
import { commonMedia } from '@/common/media';
import { Record as BasicRecord } from '../Record';
import { dictionary } from '@/common/localization/dictionary';


const COMMENT_BADGES = ['comment_10+', 'comment_25+', 'comment_50+', 'comment_100+'];

const styles = {
    badgeImg: {
        width: '100px',
        height: '100px',
        margin: '8px',
    },
    badgeHref: {
        flex: '0 0 auto',
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '8px',
        marginBottom: 8,
        alignItems: 'center',
    },
};

export class PersonalAggregatedBadgeRecord extends React.Component {
    static propTypes = {
        authUserData: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            last_name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired,
        }).isRequired,
        content_type_id: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        objectList: PropTypes.arrayOf(PropTypes.object).isRequired,
        record_id: PropTypes.number.isRequired,
        filterData: PropTypes.func.isRequired,
        reloadData: PropTypes.func.isRequired,
    };

    renderRecord = (record) => {
        let buttonUrl;
        let buttonText;
        if (COMMENT_BADGES.includes(record.badge.codename)) {
            buttonText = 'Комментарий';
            buttonUrl = `/comments/redirect/${record.object_id}/`;
        } else {
            buttonText = dictionary.achievement;
            buttonUrl = record.url;
        }
        return (
            <div
                key={ record.record_id }
                style={ styles.badgeContainer }
            >
                <A
                    href={ record.badge.url }
                    style={ styles.badgeHref }
                >
                    <img
                        style={ styles.badgeImg }
                        src={ record.badge.image }
                        alt={ record.badge.name }
                    />
                </A>
                <Button label={ buttonText } href={ buttonUrl } primary { ...commonMedia.buttonStyles } />
            </div>
        );
    }

    render() {
        const { objectList } = this.props;
        const recordHead = (
            <span>
                Достижения
            </span>
        );
        const recordBody = (
            <div style={ { display: 'flex', flexFlow: 'wrap row' } }>
                { objectList.map(record => this.renderRecord(record)) }
            </div>
        );

        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ recordBody }
                hide_comments
                hideLikes
                isShow={ false }
                { ...this.props }
            />
        );
    }
}
