import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { A, Button, ConfirmButton } from 'intdev-ui';

import { isMobile } from '@/common/mediaQueries';
import { commonMedia } from '@/common/media';
import { ALink } from '@/common/components/ALink';
import VisibleMenuButton from './VisibleMenuButton';
import * as constants from '../constants';
import CommonMenuButton from './CommonMenuButton';


const styles = {
    buttonLabelStyle: {
        fontSize: '12px',
    },
    commonButtonStyle: {
        minWidth: 'auto',
    },
    iconButtonStyle: {
        minWidth: '35px',
    },
};

export class QuizDetailHeader extends React.Component {
    static propTypes = {
        quiz: PropTypes.shape().isRequired,
        setActive: PropTypes.func.isRequired,
        handleRestrictQuiz: PropTypes.func.isRequired,
        handleUnrestrictQuiz: PropTypes.func.isRequired,
        compactMode: PropTypes.bool,
        quizEditPerm: PropTypes.bool.isRequired,
        viewResultsPerm: PropTypes.bool.isRequired,
        pubOnMainPagePerm: PropTypes.bool,
        showAdditionalData: PropTypes.bool,
        usingInQuizzesList: PropTypes.bool,
        singleMode: PropTypes.bool,
    };

    static defaultProps = {
        compactMode: false,
        showAdditionalData: true,
        usingInQuizzesList: false,
        pubOnMainPagePerm: false,
        singleMode: false,
    };

    publicationStatus = () => {
        let statusText;
        switch (this.props.quiz.flag) {
            case constants.FLAG_ARCHIVE: {
                statusText = 'В архиве';
                break;
            }
            case constants.FLAG_NEW: {
                statusText = 'Не опубликован';
                break;
            }
            default: {
                if (!this.props.quiz.published_at) {
                    statusText = 'Опубликован';
                } else {
                    moment.locale('ru');
                    const publishedDt = moment(this.props.quiz.published_at).format('ll');
                    statusText = `Дата публикации: ${publishedDt}`;
                }
            }
        }
        return (
            <div className="quiz-detail__text_color_grey quiz-detail__text_size_xs">
                { statusText }
            </div>
        );
    };

    additionInformation = () => {
        const {
            quiz,
            quizEditPerm,
        } = this.props;
        if (quizEditPerm) {
            return (
                <div className="quiz-detail__text_color_grey quiz-detail__text_size_xs">
                    Количество ответивших: { quiz.answered_count }
                </div>
            );
        }
        return null;
    };

    handlePublicationButton = () => {
        this.props.setActive(this.props.quiz.id, this.props.usingInQuizzesList);
    };

    manageMenu = () => {
        const {
            quiz,
            quizEditPerm,
            viewResultsPerm,
            pubOnMainPagePerm,
            usingInQuizzesList,
            singleMode,
        } = this.props;
        let [resultButton, publicationButton, builderButton] = [null, null, null];
        let [commonMenuButton, visibleMenuButton] = [null, null];
        const editIcon = <i className="fa fa-edit" aria-hidden="true" />;
        const menuIcon = <i className="fa fa-bars" aria-hidden="true" />;
        const restrictIcon = <i className="fa fa-lock" aria-hidden="true" />;
        const unRestrictIcon = <i className="fa fa-unlock" aria-hidden="true" />;
        if (!(pubOnMainPagePerm || quizEditPerm || (viewResultsPerm && !quiz.is_anonymous))) {
            return null;
        }
        if (quizEditPerm) {
            if (singleMode) {
                resultButton = (
                    <Button
                        className="quiz-detail__menu-button"
                        label="Результаты"
                        labelStyle={ styles.buttonLabelStyle }
                        style={ styles.commonButtonStyle }
                        onClick={ () => { window.location.href = constants.SINGLE_QUIZ_RESULTS_PAGE_URL(quiz.id); } }
                        { ...commonMedia.buttonStyles }
                    />
                );
            } else {
                resultButton = (
                    <ALink to={ constants.SINGLE_QUIZ_RESULTS_PAGE_URL(quiz.id) }>
                        <Button
                            className="quiz-detail__menu-button"
                            label="Результаты"
                            labelStyle={ styles.buttonLabelStyle }
                            style={ styles.commonButtonStyle }
                            { ...commonMedia.buttonStyles }
                        />
                    </ALink>
                );
            }
            if (quiz.flag === constants.FLAG_ARCHIVE || quiz.flag === constants.FLAG_NEW) {
                publicationButton = (
                    <ConfirmButton
                        label="Опубликовать"
                        buttonProps={
                            isMobile
                                ? { ...commonMedia.buttonStyles, className: 'quiz-detail__menu-button' }
                                : { labelStyle: styles.buttonLabelStyle, className: 'quiz-detail__menu-button' }
                        }
                        style={ styles.commonButtonStyle }
                        dialogText="Вы уверены, что хотите опубликовать опрос?"
                        onClick={ this.handlePublicationButton }
                    />
                );
            }
            builderButton = (
                <Button
                    className="quiz-detail__menu-button"
                    icon={ editIcon }
                    style={ styles.iconButtonStyle }
                    title="Редактировать"
                    onClick={ () => { window.location.href = constants.QUIZ_BUILDER_URL(quiz.id); } }
                    { ...commonMedia.buttonStyles }
                />
            );
            visibleMenuButton = (
                <VisibleMenuButton
                    buttonStyle={ isMobile
                        ? { ...commonMedia.buttonStyles.buttonStyle, ...styles.iconButtonStyle }
                        : styles.iconButtonStyle }
                    icon={ (quiz.is_restricted) ? restrictIcon : unRestrictIcon }
                    quizId={ this.props.quiz.id }
                    contentTypeId={ this.props.quiz.content_type_id }
                    title="Настройки видимости опроса"
                    onRestrictQuiz={ this.props.handleRestrictQuiz }
                    onUnrestrictQuiz={ this.props.handleUnrestrictQuiz }
                    isQuizRestricted={ quiz.is_restricted }
                />
            );
            commonMenuButton = (
                <CommonMenuButton
                    buttonStyle={ isMobile
                        ? { ...commonMedia.buttonStyles.buttonStyle, ...styles.iconButtonStyle }
                        : styles.iconButtonStyle }
                    icon={ menuIcon }
                    quiz={ quiz }
                    title="Ещё"
                    quizEditPerm={ quizEditPerm }
                    viewResultsPerm={ viewResultsPerm }
                    pubOnMainPagePerm={ pubOnMainPagePerm }
                    usingInQuizzesList={ usingInQuizzesList }
                />
            );
        }
        return (
            <div className="quiz-detail__menu quiz-detail__header-menu">
                { resultButton }
                { publicationButton }
                { builderButton }
                { visibleMenuButton }
                { commonMenuButton }
            </div>
        );
    };

    blogRefs = () => {
        const { quiz } = this.props;
        if (!quiz.blogs?.length) return '-';
        return quiz.blogs.map(blog => (
            <span key={ blog.id } className="quiz-blog_refs">
                <A href={ blog.url }>{ blog.name }</A>
            </span>
        ));
    }

    render() {
        const {
            quiz,
            compactMode,
            viewResultsPerm,
            usingInQuizzesList,
            singleMode,
        } = this.props;
        if (!quiz.id) {
            return null;
        }

        const isQuizGroupShow = (usingInQuizzesList && (quiz.group_quiz_count > 1));

        const wrapperClassName = classnames({
            'quiz-detail__header-container': true,
            'quiz-detail__header-padding': compactMode,
            'quiz-detail__header-group': (isQuizGroupShow && viewResultsPerm),
        });

        let quizTitle;
        const quizTitleText = quiz.title ? quiz.title : 'Опрос';
        if (!usingInQuizzesList) {
            quizTitle = quizTitleText;
        } else if (singleMode) {
            quizTitle = (
                <A className="quiz-detail__title-link" href={ constants.QUIZ_PAGE_URL(quiz.id) }>
                    { quizTitleText }
                </A>
            );
        } else {
            quizTitle = (
                <ALink to={ constants.QUIZ_PAGE_URL(quiz.id) } className="quiz-detail__title-link">
                    { quizTitleText }
                </ALink>
            );
        }

        return (
            <div className={ wrapperClassName }>
                <div className="quiz-detail__header">
                    <div className={ `quiz-detail__text_size_${(compactMode) ? 'm' : 'l'}` }>
                        { quizTitle }
                    </div>
                    { this.manageMenu() }
                </div>
                {
                    this.props.showAdditionalData
                    && (
                        <div className="quiz-detail__info">
                            { this.publicationStatus() }
                            { this.additionInformation() }
                            { quiz.author && (
                                <div className="quiz-detail__text_color_grey quiz-detail__text_size_xs">
                                    Автор опроса: <A href={ quiz.author?.url }>{ quiz.author?.full_name }</A>
                                </div>
                            ) }
                            { quiz.blogs && (
                                <div className="quiz-detail__text_color_grey quiz-detail__text_size_xs">
                                    Блоги, в которых размещен:&nbsp;
                                    { this.blogRefs() }
                                </div>
                            ) }
                        </div>
                    )
                }
                {
                    isQuizGroupShow && viewResultsPerm
                    && (
                        <div className="quiz-detail__group_info">
                            Опрос из группы &laquo;<i>{ quiz.quiz_scheme.title }</i>&raquo;<br />
                            <A
                                rootComponent={ (singleMode) ? Fragment : Link }
                                to={ constants.QUIZ_SCHEME_RESULTS_PAGE_URL(quiz.quiz_scheme.id) }
                            >
                                <span>Результаты группы</span>
                            </A>
                        </div>
                    )
                }
            </div>
        );
    }
}
