import $ from 'jquery';

const MIN_TOP = -20;
const MAX_TOP = 100;
const CHECK_INTERVAL = 400;
const MIN_DELTA = 80;

/**
 * Периодическая проверка видимости целевого коммента некоторое время после рендера, пока грузятся картинки
 * @param {number} interval сколько миллисекунд отрабатывать
 */
export default function scrollKeeper(element, interval) {
    let count = Math.ceil(interval / CHECK_INTERVAL);

    let lastTop = 0;

    function scrollToElem(commentElement) {
        const top = commentElement.offset().top;
        if (Math.abs(top - lastTop) < MIN_DELTA) {
            return;
        }
        lastTop = top;
        const delta = top - window.pageYOffset;
        if (delta < MIN_TOP || delta > MAX_TOP) {
            $('html, body').animate({
                scrollTop: top,
            }, 250);
        }
    }

    function checkScroll() {
        if (count > 0) {
            count -= 1;
            scrollToElem(element);
            setTimeout(checkScroll, CHECK_INTERVAL);
        }
    }
    setTimeout(checkScroll, CHECK_INTERVAL);
}
