import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Attention = props => (<SvgIcon { ...props }>
    <path fill="currentColor" d="M3 12.5a1.5 1.5 0 1 0-3.001.001A1.5 1.5 0 0 0 3 12.5zm0-11a1.5 1.5 0 0 0-3 0v6a1.5 1.5 0 0 0 3 0v-6z" />
</SvgIcon>);
/* eslint-enable max-len */

Attention.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Attention;
