const actionPrefix = 'permissionContainer';

export const LOAD_PERMISSIONS_START = `${actionPrefix}/LOAD_PERMISSIONS_START`;
export const LOAD_PERMISSIONS_SUCCESS = `${actionPrefix}/LOAD_PERMISSIONS_SUCCESS`;
export const LOAD_PERMISSIONS_FAIL = `${actionPrefix}/LOAD_PERMISSIONS_FAIL`;

export const LOAD_SELF_PROFILE_START = `${actionPrefix}/LOAD_SELF_PROFILE_START`;
export const LOAD_SELF_PROFILE_SUCCESS = `${actionPrefix}/LOAD_SELF_PROFILE_SUCCESS`;
export const LOAD_SELF_PROFILE_FAIL = `${actionPrefix}/LOAD_SELF_PROFILE_FAIL`;

export const LOAD_PROJECT_CONSTANTS_START = `${actionPrefix}/LOAD_PROJECT_CONSTANTS_START`;
export const LOAD_PROJECT_CONSTANTS_SUCCESS = `${actionPrefix}/LOAD_PROJECT_CONSTANTS_SUCCESS`;
export const LOAD_PROJECT_CONSTANTS_FAIL = `${actionPrefix}/LOAD_PROJECT_CONSTANTS_FAIL`;

export const LOAD_FEATURES = `${actionPrefix}/LOAD_FEATURES`;
export const LOAD_FEATURES_SUCCESS = `${actionPrefix}/LOAD_FEATURES_SUCCESS`;
export const LOAD_FEATURES_FAIL = `${actionPrefix}/LOAD_FEATURES_FAILURE`;
