import { APP_NAME } from './index';

export const ACTION_TYPES = {
    BEGIN_REQUEST: `${APP_NAME}/CREATE_BLOG_BEGIN_REQUEST`,
    REQUEST_SUCCESS: `${APP_NAME}/CREATE_BLOG_REQUEST_SUCCESS`,
    REQUEST_FAILURE: `${APP_NAME}/CREATE_BLOG_REQUEST_FAILURE`,
    CHANGE_FIELD: `${APP_NAME}/CREATE_BLOG_CHANGE_FIELD`,
    SET_ERROR: `${APP_NAME}/CREATE_BLOG_SET_ERROR`,
    FETCH_FORM_REQUEST: `${APP_NAME}/FETCH_FORM_REQUEST`,
    FETCH_FORM_REQUEST_SUCCESS: `${APP_NAME}/FETCH_FORM_REQUEST_SUCCESS`,
    FETCH_FORM_REQUEST_FAILURE: `${APP_NAME}/FETCH_FORM_REQUEST_FAILURE`,
    MAKE_BLOG_RESTRICTED: `${APP_NAME}/MAKE_BLOG_RESTRICTED`,

    SIDEBLOCK_INFO_LOADING_START: `${APP_NAME}/SIDEBLOCK_INFO_LOADING_START`,
    SIDEBLOCK_INFO_LOADING_FINISH: `${APP_NAME}/SIDEBLOCK_INFO_LOADING_FINISH`,
    SIDEBLOCK_INFO_LOADING_FAIL: `${APP_NAME}/SIDEBLOCK_INFO_LOADING_FAIL`,

    LOAD_PHOTOS_START: `${APP_NAME}/LOAD_PHOTOS_START`,
    LOAD_PHOTOS_SUCCESS: `${APP_NAME}/LOAD_PHOTOS_SUCCESS`,
    LOAD_PHOTOS_ERROR: `${APP_NAME}/LOAD_PHOTOS_ERROR`,

    LOAD_ALBUMS_START: `${APP_NAME}/LOAD_ALBUMS_START`,
    LOAD_ALBUMS_SUCCESS: `${APP_NAME}/LOAD_ALBUMS_SUCCESS`,
    LOAD_ALBUMS_ERROR: `${APP_NAME}/LOAD_ALBUMS_ERROR`,

    UPDATE_LIKES: `${APP_NAME}/UPDATE_LIKES`,

    BLOG_LOAD_ATTACHMENTS_START: 'blog.load_attachments_start',
    BLOG_LOAD_ATTACHMENTS_SUCCESS: 'blog.load_attachments_success',
    BLOG_LOAD_ATTACHMENTS_FAILURE: 'blog.load_attachments_failure',

    BLOG_LOAD_ATTACHMENTS_FORWARD_START: 'blog.load_attachments_forward_start',
    BLOG_LOAD_ATTACHMENTS_FORWARD_SUCCESS: 'blog.load_attachments_forward_success',
    BLOG_LOAD_ATTACHMENTS_FORWARD_FAILURE: 'blog.load_attachments_forward_failure',

    BLOG_DELETE_ATTACHMENTS_START: 'blog.delete_attachments_start',
    BLOG_DELETE_ATTACHMENTS_SUCCESS: 'blog.delete_attachments_success',
    BLOG_DELETE_ATTACHMENTS_FAILURE: 'blog.delete_attachments_failure',

    BLOG_REMOVE_PHOTO_START: 'blog.remove_photo_start',
    BLOG_REMOVE_PHOTO_SUCCESS: 'blog.remove_photo_success',
    BLOG_REMOVE_PHOTO_ERROR: 'blog.remove_photo_error',
    BLOG_ATTACHMENT_IMAGE_CLICK: 'blog.attachment_image_click',
    BLOG_ATTACHMENT_CAROUSEL_CLOSE: 'blog.attachment_carousel_close',
    BLOG_UPDATE_LIKES: 'blog.update_likes',

    BULK_SUBSCRIBE_START: `${APP_NAME}/BULK_SUBSCRIBE_START`,
    BULK_SUBSCRIBE_SUCCESS: `${APP_NAME}/BULK_SUBSCRIBE_SUCCESS`,
    BULK_SUBSCRIBE_FAIL: `${APP_NAME}/BULK_SUBSCRIBE_FAIL`,

    OPEN_BULK_SUBSCRIBE_DIALOG: `${APP_NAME}/OPEN_BULK_SUBSCRIBE_DIALOG`,
    CLOSE_BULK_SUBSCRIBE_DIALOG: `${APP_NAME}/CLOSE_BULK_SUBSCRIBE_DIALOG`,

    CHANGE_BULK_SUBSCRIBERS: `${APP_NAME}/CHANGE_BULK_SUBSCRIBERS`,
};
