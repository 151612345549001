import { determineScreenSize } from '@/common/mediaQueries';

export const media = determineScreenSize({
    desktop: {
        subscribe: {
            fontSize: 13,
        },
        commentsButtonStyles: {
            style: { marginRight: 16 },
        },
    },
    small: {
        subscribe: {
            fontSize: 'var(--font-size-body)',
        },
        commentsButtonStyles: {
            buttonStyle: { height: 26, lineHeight: '24px' },
            labelStyle: { fontSize: 12 },
            overlayStyle: { height: 26 },
            style: { marginRight: 16 },
        },
    },
    medium: {
        subscribe: {
            fontSize: 'var(--font-size-body)',
        },
        commentsButtonStyles: {
            buttonStyle: { height: 30, lineHeight: '28px' },
            overlayStyle: { height: 30 },
            style: { marginRight: 16 },
        },
    },
    large: {
        subscribe: {
            fontSize: 15,
        },
        commentsButtonStyles: {
            style: { marginRight: 16 },
        },
    },
});
