import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';

export class PeopleTooltipItem extends Component {
    static propTypes = {
        userInfo: PropTypes.shape({
            avatar: PropTypes.string.isRequired,
            first_name: PropTypes.node.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const { userInfo } = this.props;
        return (
            <div className="user-tooltip-item">
                <div>
                    <A href={ userInfo.url }>
                        <img className="user-tooltip-avatar" src={ userInfo.avatar } />
                        <div className="user-tooltip-username">
                            { userInfo.first_name }
                        </div>
                    </A>
                </div>
            </div>
        );
    }
}
