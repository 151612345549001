/* eslint-disable no-underscore-dangle */

// https://yandex.ru/support/metrica/general/goal-js-event.html
// https://top.mail.ru/help/ru/settings/goals

let mailtopCounter = null;
let yandexCounter = null;

export const sendMetricsEvent = ({ detail: goalName }) => {
    const _tmr = window._tmr || (window._tmr = []);
    if (_tmr) {
        _tmr.push({ id: mailtopCounter, type: 'reachGoal', goal: goalName });
    }

    if (window.ym) {
        window.ym(yandexCounter, 'reachGoal', goalName);
    }
};

export const dispatchMetricsEvent = (goalName) => {
    const metricsEvent = new CustomEvent('metricsEvent', { detail: goalName });
    document.dispatchEvent(metricsEvent);
};

export const initMetricsListener = () => {
    document.addEventListener('metricsEvent', sendMetricsEvent);
    const mailTopScriptContainer = document.getElementById('mail-top-metrics');
    const yandexScriptContainer = document.getElementById('ya-metrics');
    if (mailTopScriptContainer) {
        mailtopCounter = mailTopScriptContainer.dataset.counter;
        if (window.USER_PROFILE) {
            const _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ type: 'setUserID', userid: window.USER_PROFILE.id });
        }
    }
    if (yandexScriptContainer) {
        yandexCounter = yandexScriptContainer.dataset.counter;
    }
};
