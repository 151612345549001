import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmButton, A } from 'intdev-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { media } from '@/blog/media';
import { AttachmentDialog } from '../AttachmentDialog';
import { apiUrls } from '../../constants/apiUrls';
import {
    ICON_CLASS_AUDIO,
    ICON_CLASS_IMG,
    ICON_CLASS_VIDEO,
} from '../../constants';
import { attachmentImageClick, deleteAttachments } from '../../actions';
import IconFaButton from '../../../common/components/IconFaButton';


const VIDEO_DEFAULT_PREVIEW_IMG = '/static/img/no-video-preview.svg';

export const styles = {
    attachmentAudio: {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    attachmentImg: {
        width: '180px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '4px',
        marginRight: '16px',
    },
    attachmentTitle: {
        margin: '12px 0px 0px 0px',
        textAlign: 'left',
        fontSize: '16px',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '210px',
        color: '#333333',
    },
    managementButton: {
        flex: '0 1 auto',
        width: '24px',
        height: '24px',
        margin: '4px',
        background: 'white',
        opacity: 0.5,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '2px',
    },
    managementButtonHovered: {
        opacity: 1,
        background: 'white',
        transition: 'all 0.2s ease-out',
    },
    removeButtonHovered: {
        color: '#e01616',
    },
    editButtonHovered: {
        color: '#168DE2',
    },
};

class AttachmentListItemComponent extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
        isProcessed: PropTypes.bool.isRequired,
        attachmentFileUrl: PropTypes.string.isRequired,
        previewUrl: PropTypes.string,
        canManageAttachments: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
        tags: PropTypes.string,
        mime: PropTypes.string.isRequired,
        deleteAttachments: PropTypes.func.isRequired,
        isUserCreator: PropTypes.bool.isRequired,
        handleEditItem: PropTypes.func.isRequired,
        attachmentImageClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        previewUrl: null,
        tags: '',
        index: null,
    };

    state = {
        isHovered: false,
        isVideoActive: false,
        isEditDialogOpen: false,
        isRemoveDialogOpen: false,
    };

    onAttachmentDialogClose = () => this.setState({
        isEditDialogOpen: false,
    });

    getAttachmentContent = () => {
        if (this.props.iconClass === ICON_CLASS_IMG) {
            return (
                <div onClick={ this.handleAttachmentImgClick }>
                    <img
                        src={ this.props.previewUrl }
                        style={ styles.attachmentImg }
                    />
                </div>
            );
        }
        if (this.props.iconClass === ICON_CLASS_AUDIO) {
            return (
                <audio
                    controls
                    preload="auto"
                    autoPlay={ false }
                    style={ styles.attachmentAudio }
                >
                    <source
                        src={ this.props.attachmentFileUrl }
                        type={ this.props.mime }
                    />
                    Твой браузер не поддерживает аудио :(
                </audio>
            );
        }
        if (this.props.iconClass === ICON_CLASS_VIDEO) {
            const attachmentInProcess = !this.props.isProcessed;
            if (attachmentInProcess) {
                return (
                    <div className="video-processing-text">
                        Видео обрабатывается, приходи чуть позже
                    </div>
                );
            }
            const backgroundImageUrl = this.props.previewUrl || VIDEO_DEFAULT_PREVIEW_IMG;
            const videoId = `video-${this.props.id}`;
            const videoElem = (
                <video
                    id={ videoId }
                    controls
                    preload="none"
                    autoPlay={ false }
                    className="video-js vjs-sublime-skin post-video-element"
                    poster={ backgroundImageUrl }
                    data-setup="{}"
                >
                    <source
                        src={ this.props.attachmentFileUrl }
                        type={ this.props.mime }
                    />
                </video>
            );
            return (
                <div className="video-element-container">
                    { videoElem }
                    {!this.state.isVideoActive
                    && <div className="preview-video" style={ { backgroundImage: `url(${backgroundImageUrl})` } } />}
                </div>

            );
        }

        return (
            <A
                href={ this.props.attachmentFileUrl }
                title="Скачать вложение"
            >
                <img src={ apiUrls.iconUrl(this.props.iconClass) } />
            </A>
        );
    };

    getFileName = () => this.props.attachmentFileUrl.split('/').slice(-1)[0];

    getManagementButtons = () => {
        if ((this.props.canManageAttachments || this.props.isUserCreator) && this.state.isHovered) {
            return (
                <div className="blog-post-management-button-container">
                    <ConfirmButton
                        label="Удалить вложение"
                        dialogText="Ты уверен, что хочешь удалить вложение?"
                        onClick={ this.handleRemoveAction }
                        buttonElement={ (
                            <IconFaButton
                                iconClass="fa-remove"
                                title="Удалить"
                                style={ styles.managementButton }
                                styleHover={ {
                                    ...styles.managementButtonHovered,
                                    ...styles.removeButtonHovered,
                                } }
                            />
                        ) }
                    />
                    <IconFaButton
                        iconClass="fa-pencil"
                        title="Изменить вложение"
                        style={ styles.managementButton }
                        styleHover={ {
                            ...styles.managementButtonHovered,
                            ...styles.editButtonHovered,
                        } }
                        onClick={ this.handleEditClick }
                    />
                </div>
            );
        }
        return null;
    };

    handleAttachmentDialogSave = () => {
        this.props.handleEditItem();
        this.setState({ isEditDialogOpen: false });
    };

    handleAttachmentImgClick = () => {
        this.props.attachmentImageClick(this.props.id);
    };

    handleRemoveAction = () => {
        this.props.deleteAttachments(this.props.id);
    };

    handleEditClick = () => {
        this.setState({ isEditDialogOpen: true });
    };

    render() {
        return (
            <div
                className="attachment-container"
                title={ this.props.name }
                onMouseEnter={ () => this.setState({ isHovered: true }) }
                onMouseLeave={ () => this.setState({ isHovered: false }) }
                onClick={ () => this.setState(state => ({ isVideoActive: true })) }
            >
                { this.state.isEditDialogOpen
                    && (
                        <AttachmentDialog
                            isDialogOpen
                            handleCloseDialog={ this.onAttachmentDialogClose }
                            url={ apiUrls.editAttachmentUrl(this.props.id) }
                            name={ this.props.name }
                            tags={ this.props.tags }
                            title="Редактировать вложение"
                            attachmentFile={ { name: this.getFileName() } }
                            onEdit={ this.handleAttachmentDialogSave }
                        />
                    )}
                <div className="attachment-content-container">
                    {this.getAttachmentContent()}
                </div>
                <div className="attachment-title-container">
                    <A
                        href={ this.props.attachmentFileUrl }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ { ...styles.attachmentTitle, ...media.attachmentText } }
                        title={ this.props.name }
                    >
                        { this.props.name }
                    </A>
                </div>
                { this.getManagementButtons() }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    attachmentImageClick,
    deleteAttachments,
}, dispatch);

const AttachmentListItem = connect(null, mapDispatchToProps)(AttachmentListItemComponent);

export default AttachmentListItem;
