import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';

export default class ContentTypeSetting extends Component {
    static propTypes = {
        hideContent: PropTypes.func.isRequired,
        data: PropTypes.shape({
            label: PropTypes.string,
            type: PropTypes.string,
            name: PropTypes.string,
            choices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
            ]).isRequired,
        }),
        contentTypeLabels: PropTypes.shape({
            timeline_blogs: PropTypes.string,
            timeline_posts: PropTypes.string,
        }),
    };

    static defaultProps = {
        contentTypeLabels: {
            timeline_blogs: 'блоги, на которые ты подписан',
            timeline_posts: 'микропосты людей, на которых ты подписан',
            timeline_achievements: 'достижения людей, на которых ты подписан',
            timeline_skills: 'навыки людей, на которых ты подписан',
        },
        data: {},
    };

    render() {
        const label = this.props.data.label.toLowerCase();
        if (this.props.data.choices) {
            let labelSubscriptions;
            if (this.props.contentTypeLabels[this.props.data.name]) {
                labelSubscriptions = this.props.contentTypeLabels[this.props.data.name];
            } else {
                labelSubscriptions = `${label}, на которые ты подписан`;
            }
            if (this.props.data.value === 'all') {
                return (
                    <div>
                        <p className="timeline-record-setting-caption">Сейчас ты видишь все { label }</p>
                        <p>
                            <A
                                href=""
                                className="timeline-record-setting-link"
                                onClick={ event => this.props.hideContent(event, this.props, 'subscriptions') }
                            >
                                Показывать только { labelSubscriptions }
                            </A>
                        </p>
                        <p>
                            <A
                                href=""
                                className="timeline-record-setting-link"
                                onClick={ event => this.props.hideContent(event, this.props, 'none') }
                            >
                                Больше не показывать { label } в ленте
                            </A>
                        </p>
                    </div>
                );
            } else if (this.props.data.value === 'subscriptions') {
                return (
                    <div>
                        <p className="timeline-record-setting-caption">Сейчас ты видишь только { labelSubscriptions }</p>
                        <p>
                            <A
                                href=""
                                className="timeline-record-setting-link"
                                onClick={ event => this.props.hideContent(event, this.props, 'all') }
                            >
                                Показывать все { label } в ленте
                            </A>
                        </p>
                        <p>
                            <A
                                href=""
                                className="timeline-record-setting-link"
                                onClick={ event => this.props.hideContent(event, this.props, 'none') }
                            >
                                Больше не показывать { label } в ленте
                            </A>
                        </p>
                    </div>
                );
            }
            return (
                <div>
                    <p className="timeline-record-setting-caption">
                        Сейчас ты не видишь { label } других пользователей.
                        Ты можешь изменить это в настройках своего профиля.
                    </p>
                </div>
            );
        }
        return (
            <div>
                <p>
                    <A
                        href=""
                        className="timeline-record-setting-link"
                        onClick={ event => this.props.hideContent(event, this.props, false) }
                    >
                        Больше не показывать { label } в ленте
                    </A>
                </p>
            </div>
        );
    }
}
