import { declOfNum } from '@/common/helpers/helpers';
import pluralized from '@/common/helpers/pluralized';

export default {
    micropostPlaceholder: name => `Поделись чем-нибудь с коллегами, ${name}`,
    timeline: 'Лента событий',
    comments: 'Комментарии',
    like: 'Нравится',
    subscribeToComments: 'Подписка на комментарии',
    addingNewComment: 'Добавление нового комментария',
    send: 'Отправить',
    profile: 'Профиль',
    settings: 'Настройки',
    search: 'Поиск',
    logout: 'Выход',
    notificationSettings: 'НАСТРОЙКИ УВЕДОМЛЕНИЙ',
    here: 'здесь',
    bell: 'колокол',
    resetToDefault: 'Сбросить по умолчанию',
    settingUpMailings: 'НАСТРОЙКИ РАССЫЛОК',
    profileSettings: 'НАСТРОЙКИ ПРОФИЛЯ',
    timelineSettings: 'НАСТРОЙКА ЛЕНТЫ',
    all: 'Все',
    subscriptions: 'Подписки',
    nothing: 'Ничего',
    subscriptionLikes: 'Лайки подписок',
    subscriptionLikesHelp: 'В твою ленту будут попадать записи, понравившиеся сотрудникам, на которых ты подписан',
    birthPubHelp: 'Эта настройка влияет на отображение профиля, страницу "Новости команды" и содержимое рассылок',
    jubileePubHelp: 'Эта настройка влияет на страницу "Новости команды"',
    addNote: 'Добавить запись',
    editBlog: 'Редактировать блог',
    edit: 'редактировать',
    delete: 'удалить',
    makeMailing: 'сделать рассылку',
    anchor: 'закрепить',
    newNoteInBlog: 'Новая запись в блог',
    title: 'Заголовок',
    author: 'Автор',
    blogsToNote: 'Блоги для записи',
    tags: 'Теги',
    publicate: 'Опубликовать',
    saveDraft: 'Сохранить черновик',
    thanks: count => declOfNum(count, ['благодарность', 'благодарности', 'благодарностей']),
    balance: 'Баланс',
    likes: 'Лайки',
    photos: 'Фотографии',
    stickers: 'Стикеры',
    calls: 'Звонки',
    followers: 'Подписчики',
    follows: 'Подписки',
    changeHistory: 'История изменений',
    internalNumber: 'Внутренний номер',
    mobileNumber: 'Мобильный номер',
    workEmail: 'Рабочая почта',
    office: 'Офис',
    personalEmail: 'Личная почта',
    inCaseOfAbsence: 'В случае отсутствия обращаться к',
    badges: 'Значки',
    aboutWork: 'О работе',
    information: 'Информация',
    skills: 'Навыки',
    callHistory: 'История звонков',
    lookFor: 'искать',
    sortBy: 'Сортировать по:',
    advancedSearch: 'Расширенный поиск по сотрудникам',
    letsLookSearch: 'Давай поищем что-нибудь...',
    byRelevance: 'релевантности',
    byTime: 'времени',
    filters: 'Фильтры',
    ideasStatus: 'Статус идей',
    popularCategories: 'Популярные категории',
    addIdea: 'Добавить идею',
    ideas: 'Идеи',
    status: 'Статус',
    category: 'Категория',
    responsibleExecutor: 'Ответственный исполнитель',
    ideaCategory: 'Категория идеи:',
    addingIdea: 'Добавление идеи',
    selectIdeaCategory: 'Выберите категорию идеи',
    ideaTitle: 'Название',
    writeIdeaTitle: 'Впишите название идеи, отражающее суть (кратко)',
    solvedProblem: 'Решаемая проблема',
    whatProblemSolved: 'Какую проблему решает идея?',
    expectedResult: 'Ожидаемый результат',
    whatImprovemenents: 'Какие улучшения произойдут, какие показатели улучшатся?',
    authorIsReady: 'Автор готов самостоятельно реализовать свою идею:',
    authorIsNotReady: 'Автор не готов самостоятельно реализовать свою идею:',
    yes: 'Да',
    no: 'Нет',
    enterIdeaDescription: 'Введите описание идеи',
    undo: 'Отменить',
    create: 'Создать',
    date: 'Дата',
    user: 'Пользователь',
    callerID: 'Номер звонящего',
    destinationNumber: 'Номер назначения',
    my: 'Мои',
    popular: 'Популярные',
    uploadedByMe: 'Загруженные мной',
    stickersCount: count => pluralized(count, 'стикеров:стикер:стикера'),
    added: 'Добавлен',
    uploadedBy: 'Загрузил:',
    stickerSelection: 'Выбор стикера',
    addingImage: 'Добавление изображения',
    select: 'Выбрать',
    noFileSelected: 'Файл не выбран',
    or: 'или',
    fileURL: 'URL файла',
    download: 'Загрузить',
    forSubscribers: 'подписчикам',
    forAll: 'всем',
    showAdditionalInfo: 'Показать дополнительную информацию',
    hideAdditionalInfo: 'Скрыть дополнительную информацию',
    subscription: 'Подписка',
    startTypingName: 'начни вводить имя сотрудника',
    top: 'Топ',
    authors: 'авторов',
    hotline: 'Горячая линия «Отдел охраны труда»',
    blogs: 'Блоги',
    publish: 'Опубликовать',
    subscribeToBlog: 'Подписка на блог',
    startTyping: 'Начните вводить',
    toAnswer: 'Ответить',
    idea: 'Идея',
    profilePhotos: 'Фото профиля',
    albums: 'Альбомы',
    marks: 'Отметки',
    addTo: 'Добавить',
    staff: 'Сотрудники',
    subdivisions: 'Подразделения',
    blocked: 'Заблокированные',
    addStickerPack: 'Добавить стикерпак',
    stickerPackListIsEmpty: 'Список ваших стикеров пуст',
    additional: 'Дополнительно',
    badgesProgress: 'Прогресс значков',
    leaders: 'Руководители',
    projects: 'Проекты',
    education: 'Образование',
    languages: 'Языки',
    hobbies: 'Хобби',
    aboutMyself: 'О себе',
    residenceAddress: 'Адрес проживания',
    bornInTheCity: isMale => `Родил${(isMale) ? 'ся' : 'ась'} в городе`,
    birthDate: 'Дата рождения',
    foundingDate: 'Дата основания',
    iLiveInTheCity: 'Проживаю в городе',
    workBefore: (companyName, isMale) => `До ${companyName} работал${(isMale) ? '' : 'а'} в компаниях:`,
    giveThanks: 'Поблагодарить',
    confirmedTheSkill: (gender, self) => `подтвердил${gender ? '' : 'а'} ${self ? 'твой ' : ''}навык`,
    addTheSkill: (gender, self) => `добавил${gender ? '' : 'а'} ${self ? 'тебе ' : ''}навык`,
    notifications: 'Уведомления',
    markAllAsReserved: 'Отметить все, как прочитанное',
    contacts: 'Контакты',
    mobilePhone: 'Мобильный телефон',
    primary: 'Основной',
    otherContacts: 'Другие контакты',
    telegramNickname: 'Ник в Телеграм',
    tamTamNickname: 'Ник в Tam-Tam',
    whatsappPhone: 'Телефон в WhatsApp',
    skypeLogin: 'Логин в Skype',
    vkNickname: 'Ник во Вконтакте',
    additionalEmail: 'Дополнительный email',
    enterYourPrivateEmail: 'введите свой личный email',
    externalAccounts: 'Внешние аккаунты',
    additionalLinks: 'Дополнительные ссылки',
    save: 'Сохранить',
    cancel: 'Отмена',
    chooseProjects: 'Выбери проекты',
    infoAboutAbsence: 'Информация об отсутствии',
    nearestMetroStation: 'Ближайшая станция метро',
    gettingToWork: 'На работу добираюсь',
    driversLicense: 'Водительские права',
    exWork: (companyName, isMale) => `До ${companyName || 'этого'} работал${isMale ? '' : 'а'}`,
    foreignLanguages: 'Иностранные языки',
    recommendSubscribing: 'Рекомендуем подписаться',
    accept: 'Подтвердить',
    peopleWhoAcceptedSkill: 'человек подтвердили навык',
    achievement: 'Достижение',
    confirmed: 'подтвердили',
    commentsRating: 'Рейтинг комментариев',
    postsRating: 'Рейтинг постов',
    ideasRating: 'Рейтинг идей',
    micropostsRating: 'Рейтинг микропостов',
    overallRating: 'Общий рейтинг',
    viewed: 'Просмотренные',
    noNotifications: 'Нет уведомлений',
    noNewNotifications: 'Нет новых уведомлений',
    showAllNotifications: 'Показать все',
};
