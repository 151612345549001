import { CALL_API } from '../../../common/constants/actionTypes';
import ACTION_TYPES from '../actionTypes';
import { ANSWER_BY_PASS_URL, COMMIT_ANSWERS_URL } from '../constants';

export const changeAnswer = (questionId, answer) => ({
    type: ACTION_TYPES.TMP_ANSWER_SET,
    questionId,
    answer,
});

export const initAnswers = (questionList, groupType, options = {}) => ({
    type: ACTION_TYPES.TMP_ANSWERS_INIT,
    questionList,
    groupType,
    prevQuestionId: options.prevQuestionId || null,
    // Answer model has prev_question field (not null only for ConsecutiveQuestionGroup),
});

export const commitAnswers = (quizId, tmpAnswers, controls = {}) => ({
    type: CALL_API,
    endpoint: COMMIT_ANSWERS_URL(quizId),
    method: 'post',
    body: tmpAnswers,
    types: [ACTION_TYPES.COMMIT_ANSWERS_START, ACTION_TYPES.COMMIT_ANSWERS_FINISH, ACTION_TYPES.API_FAIL],
    additionalData: { controls },
});


export const answerByPass = body => ({
    type: CALL_API,
    endpoint: ANSWER_BY_PASS_URL,
    method: 'post',
    body,
    types: [
        ACTION_TYPES.ANSWER_BY_PASS_START,
        ACTION_TYPES.ANSWER_BY_PASS_FINISH,
        ACTION_TYPES.ANSWER_BY_PASS_FAIL,
    ],
});
