import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes, Popover } from 'intdev-ui';

const styles = {
    root: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    popover: {
        margin: '8px',
    },
    popoverRoot: {
        opacity: 0,
    },
    popoverRootHovered: {
        opacity: 1,
        transition: 'all 0.2s ease-out',
    },
};

export default class TextEllipsisTooltip extends React.PureComponent {
    static propTypes = {
        style: IUIPropTypes.style,
        text: PropTypes.string.isRequired,
    };

    static defaultProps = {
        style: {},
    };

    state = {
        hovered: false,
        // apply style with opacity transition right after popover open to create beautiful transition
        hoveredStyleApplyed: false,
    };

    render() {
        return (<div
            style={ {
                ...styles.root,
                ...this.props.style,
            } }
            ref={ (ref) => {
                this.popupAnchorRef = ref;
                if (ref) {
                    // if text where truncated
                    this.setState({ textTruncated: ref.offsetWidth < ref.scrollWidth });
                }
            } }
        >
            <span
                onMouseOver={ () => this.setState({ hovered: true, hoveredStyleApplyed: false }) }
                onMouseOut={ () => this.setState({ hovered: false }) }
            >
                { this.props.text }
            </span>
            { (this.state.textTruncated && this.state.hovered) &&
                <Popover
                    open
                    anchorEl={ this.popupAnchorRef }
                    anchorOrigin={ { horizontal: 'middle', vertical: 'bottom' } }
                    targetOrigin={ { horizontal: 'middle', vertical: 'top' } }
                    useLayerForClickAway={ false }
                    style={ this.state.hoveredStyleApplyed ? styles.popoverRootHovered : styles.popoverRoot }
                >
                    <div
                        style={ styles.popover }
                        ref={ () => this.setState((oldStyle) => {
                            if (oldStyle.hoveredStyleApplyed) {
                                return null;
                            }
                            return { hoveredStyleApplyed: true };
                        }) }
                    >
                        {this.props.text}
                    </div>
                </Popover>
            }
        </div>);
    }
}
