import React from 'react';
import PropTypes from 'prop-types';

export const SliderArrow = ({
    style, containerStyle, arrowStyle, ...other
}) => (
    <div className="arrow-container" style={ { ...containerStyle } } { ...other }>
        <div style={ { width: 9, ...arrowStyle } }>
            <svg
                style={ { display: 'block', ...style } }
                viewBox="0 0 9.3 17"
                xmlns="http://www.w3.org/2000/svg"
            >
                <polyline
                    fill="none"
                    stroke="#000000"
                    strokeLinejoin="butt"
                    strokeLinecap="butt"
                    strokeWidth="1"
                    points="0.5,0.5 8.5,8.5 0.5,16.5"
                />
            </svg>
        </div>
    </div>
);

SliderArrow.propTypes = {
    style: PropTypes.instanceOf(Object),
    containerStyle: PropTypes.instanceOf(Object),
    arrowStyle: PropTypes.instanceOf(Object),
};

SliderArrow.defaultProps = {
    style: {},
    containerStyle: {},
    arrowStyle: {},
};