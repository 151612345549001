import { apiUrls } from '../constants/apiUrls';
import { CALL_API } from '../../common/constants/actionTypes';
import {
    CALENDAR_BELL_FAILURE,
    CALENDAR_BELL_START,
    CALENDAR_BELL_SUCCESS,
    CALENDAR_CONFIG_LOAD,
    CALENDAR_LOAD_FAILURE,
    CALENDAR_LOAD_START,
    CALENDAR_LOAD_SUCCESS,
    CENTRIFUGE_DELETE,
    CENTRIFUGE_EDIT,
    CENTRIFUGE_JOIN,
    CENTRIFUGE_LEAVE,
    PARSE_EXCEL_START,
    PARSE_EXCEL_FINISH,
    PARSE_EXCEL_ERROR,
    EVENT_LOAD_FAILURE,
    EVENT_LOAD_START,
    EVENT_LOAD_SUCCESS,
    EVENT_LOCATIONS_LOAD_START,
    EVENT_LOCATIONS_LOAD_SUCCESS,
    EVENT_LOCATIONS_LOAD_FAILURE,
    EVENT_MODAL_CLEAN_VALIDATION_ERRORS,
    EVENT_MODAL_FIELD_VALUE_CHANGE,
    EVENT_MODAL_SET_VALIDATION_ERRORS,
    EVENT_SAVE_FAILURE,
    EVENT_SAVE_START,
    EVENT_SAVE_SUCCESS,
    FOOTER_TOGGLE,
    LEFT_ARROW_VISIBILITY,
    MEETING_LEAVE_FAILURE,
    MEETING_LEAVE_START,
    MEETING_LEAVE_SUCCESS,
    MODAL_CLOSE,
    MODAL_OPEN,
    MOUSE_OUT_SERIES,
    MOUSE_OVER_SERIES,
    RIGHT_ARROW_VISIBILITY,
    SET_CALENDAR_CONTAINER_PROPS,
} from '../constants/actions';
import { GRID_DEFAULT_GROUP_NAME } from '../constants/defaults';
import { getLoadState, getNavigationArrowUiState } from '../reducers';
import { FORMDATA } from '../../common/constants/ajaxBodyTypes';

export const calendarConfigLoad = configData => ({
    type: CALENDAR_CONFIG_LOAD,
    payload: configData,
});

export const setCalendarContainerProps = containerProps => ({
    type: SET_CALENDAR_CONTAINER_PROPS,
    payload: containerProps,
});

export const centrifugeJoin = (messageData, user) => ({
    type: CENTRIFUGE_JOIN,
    payload: { messageData, user },
});

export const centrifugeEdit = (messageData, user) => ({
    type: CENTRIFUGE_EDIT,
    payload: { messageData, user },
});

export const centrifugeDelete = (messageData, user) => ({
    type: CENTRIFUGE_DELETE,
    payload: { messageData, user },
});

export const centrifugeLeave = (messageData, user) => ({
    type: CENTRIFUGE_LEAVE,
    payload: { messageData, user },
});

export const modalOpen = (type, event) => ({
    type: MODAL_OPEN,
    payload: {
        type,
        event,
    },
});

export const modalClose = () => ({
    type: MODAL_CLOSE,
});

export const meetingLeave = event => ({
    type: CALL_API,
    endpoint: apiUrls().meetingLeave(event.meetingId),
    method: 'POST',
    types: [
        MEETING_LEAVE_START,
        MEETING_LEAVE_SUCCESS,
        MEETING_LEAVE_FAILURE,
    ],
});

export const mouseOverSeries = event => ({
    type: MOUSE_OVER_SERIES,
    payload: event,
});

export const mouseOutSeries = event => ({
    type: MOUSE_OUT_SERIES,
    payload: event,
});

export const toggleFooter = event => ({
    type: FOOTER_TOGGLE,
    payload: event,
});

export const loadEvent = meetingId => ({
    type: CALL_API,
    endpoint: apiUrls().meetingDetail(meetingId),
    types: [
        EVENT_LOAD_START,
        EVENT_LOAD_SUCCESS,
        EVENT_LOAD_FAILURE,
    ],
});

export const loadLocations = () => ({
    type: CALL_API,
    endpoint: apiUrls().meetingLocations(),
    types: [
        EVENT_LOCATIONS_LOAD_START,
        EVENT_LOCATIONS_LOAD_SUCCESS,
        EVENT_LOCATIONS_LOAD_FAILURE,
    ],
});

export const eventPanelUpdate = (endpoint, data) => ({
    type: CALL_API,
    endpoint,
    body: data,
    method: 'PUT',
    types: [
        EVENT_SAVE_START,
        EVENT_SAVE_SUCCESS,
        EVENT_SAVE_FAILURE,
    ],
});

export const eventPanelCreate = (endpoint, data) => ({
    type: CALL_API,
    endpoint,
    body: data,
    method: 'POST',
    types: [
        EVENT_SAVE_START,
        EVENT_SAVE_SUCCESS,
        EVENT_SAVE_FAILURE,
    ],
});

export const saveEventPanel = (endpoint, data) => ({
    type: CALL_API,
    endpoint,
    body: data,
    method: 'POST',
    types: [
        EVENT_SAVE_START,
        EVENT_SAVE_SUCCESS,
        EVENT_SAVE_FAILURE,
    ],
});

export const eventModalParseExcel = file => ({
    type: CALL_API,
    endpoint: apiUrls().parseExcel(),
    method: 'POST',
    body: { file },
    bodyFormat: FORMDATA,
    types: [
        PARSE_EXCEL_START,
        PARSE_EXCEL_FINISH,
        PARSE_EXCEL_ERROR,
    ],
});

export const eventModalFieldValueChange = changeEvent => ({
    type: EVENT_MODAL_FIELD_VALUE_CHANGE,
    payload: changeEvent,
});

export const eventModalSetValidationErrors = validationErrors => ({
    type: EVENT_MODAL_SET_VALIDATION_ERRORS,
    payload: validationErrors,
});

export const eventModalCleanValidationErrors = fieldName => ({
    type: EVENT_MODAL_CLEAN_VALIDATION_ERRORS,
    payload: fieldName,
});

export const calendarBell = (meetingId, isSubscribeAction) => ({
    type: CALL_API,
    endpoint: isSubscribeAction
        ? apiUrls().meetingSubscribee(meetingId)
        : apiUrls().meetingUnsubscribee(meetingId),
    method: 'POST',
    additionalData: { isSubscribeAction },
    types: [
        CALENDAR_BELL_START,
        CALENDAR_BELL_SUCCESS,
        CALENDAR_BELL_FAILURE,
    ],
});

export const loadCalendar = (contentType, objectId, fromId, isBackward, gridGroupName) => {
    const nameFilter = gridGroupName === GRID_DEFAULT_GROUP_NAME ? null : gridGroupName;
    return {
        type: CALL_API,
        endpoint: apiUrls({ contentType }).meetingLoad(contentType, objectId, fromId, isBackward, nameFilter),
        additionalData: { isBackward, gridGroupName, isGroupEventsByName: false },
        types: [
            CALENDAR_LOAD_START,
            CALENDAR_LOAD_SUCCESS,
            CALENDAR_LOAD_FAILURE,
        ],
    };
};

export const initialLoadNameGroups = (contentType, objectId) => ({
    type: CALL_API,
    endpoint: apiUrls({ contentType }).meetingInitiallLoad(contentType, objectId),
    additionalData: { isBackward: false, gridGroupName: GRID_DEFAULT_GROUP_NAME, isGroupEventsByName: true },
    types: [
        CALENDAR_LOAD_START,
        CALENDAR_LOAD_SUCCESS,
        CALENDAR_LOAD_FAILURE,
    ],
});

export const setRightArrowVisibility = (newVisible, gridGroupName) => (dispatch, getState) => {
    const state = getState();
    const rightArrowVisible = getNavigationArrowUiState(state, gridGroupName).rightArrowVisible;

    // check is value already set to desirable value
    if (rightArrowVisible !== newVisible) {
        dispatch({
            type: RIGHT_ARROW_VISIBILITY,
            payload: { newVisible, gridGroupName },
        });
        // if arrow became hidden, load events forward
        if (!newVisible) {
            const {
                contentType,
                objectId,
            } = state.calendarConfig;

            const {
                forwardEmpty,
                loadingForward,
                maxEventStart,
            } = getLoadState(state, gridGroupName);

            if (!(forwardEmpty || loadingForward)) {
                const fromValue = maxEventStart ? maxEventStart.toISOString() : maxEventStart;
                dispatch(loadCalendar(contentType, objectId, fromValue, false, gridGroupName));
            }
        }
    }
};

export const setLeftArrowVisibility = (newVisible, gridGroupName) => (dispatch, getState) => {
    const state = getState();
    const leftArrowVisible = getNavigationArrowUiState(state, gridGroupName).leftArrowVisible;

    // check is value already set to disarible value
    if (leftArrowVisible !== newVisible) {
        dispatch({
            type: LEFT_ARROW_VISIBILITY,
            payload: { newVisible, gridGroupName },
        });
        // if arrow became hidden, load events backward
        if (!newVisible) {
            const {
                contentType,
                objectId,
            } = state.calendarConfig;

            const {
                backwardEmpty,
                loadingBackward,
                minEventStart,
            } = getLoadState(state, gridGroupName);

            if (!(backwardEmpty || loadingBackward)) {
                const fromValue = minEventStart ? minEventStart.toISOString() : minEventStart;
                dispatch(loadCalendar(contentType, objectId, fromValue, true, gridGroupName));
            }
        }
    }
};
