import { LOAD_FEATURES, LOAD_FEATURES_FAIL, LOAD_FEATURES_SUCCESS } from '../constants/actionConstants';

const defaultState = {};

export const projectFeatures = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_FEATURES_SUCCESS:
            return { ...state, ...action.payload.features };

        case LOAD_FEATURES:
        case LOAD_FEATURES_FAIL:
        default:
            return state;
    }
};

