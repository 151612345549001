import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, IUIPropTypes } from 'intdev-ui';
import { BeautifulRedactorField } from '../BeautifulRedactorField';
import { PermissionManager } from '../ProtectorManagers/PermissionManager';
import { showErrorNotification, showSuccessNotification } from '../../helpers/showNotification';
import MrgIcon from '../MrgIcon';

const getRootStyle = (anotherStyle, fullWidth) => {
    let style = {};
    if (fullWidth) {
        style.width = '100%';
    }
    if (anotherStyle) {
        style = { ...style, ...anotherStyle };
    }
    return style;
};

const styles = {
    iconStyle: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: -16,
    },
    relativePos: {
        position: 'relative',
    },
    redactorStyle: (anotherStyle = {}) => ({
        fontSize: '15px',
        ...anotherStyle,
    }),
    mt30: {
        marginTop: '30px',
    },
    mr10: {
        marginRight: '10px',
    },
    chunkNameStyle: {
        color: 'grey',
        fontSize: '12px',
    },
};

const defaultState = {
    isEditOpen: false,
    editText: '',
};

export class BaseChunk extends React.Component {
    static propTypes = {
        showEditButton: PropTypes.bool,
        placeholder: PropTypes.string,
        style: IUIPropTypes.style,
        // redactor props
        formatting: PropTypes.bool,
        fullWidth: PropTypes.bool,
        redactorStyle: PropTypes.shape({}),
        mentions: PropTypes.bool,
        imagemanager: PropTypes.bool,
        // chunk props
        name: PropTypes.string,
        text: PropTypes.string,
        contentType: PropTypes.number,
        objectId: PropTypes.number,
        canEdit: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        // callback functions
        onHandleSave: PropTypes.func.isRequired,
    };

    static defaultProps = {
        showEditButton: true,
        formatting: false,
        fullWidth: true,
        mentions: false,
        style: {},
        redactorStyle: {},
        placeholder: 'Текстовый фрагмент не заполнен',
        imagemanager: false,
        text: '',
        contentType: null,
        objectId: null,
        canEdit: false,
        name: '',
    };

    state = defaultState;

    componentDidUpdate = (prevProps, prevState) => {
        const { isEditOpen } = this.state;
        if (isEditOpen && prevState.isEditOpen !== isEditOpen) {
            this.renewEditText();
        }
    };

    renewEditText = () => {
        const { text } = this.props;
        this.setState({ editText: text });
    };

    handleTextChange = value => this.setState({ editText: value });

    handleSave = () => {
        const { props } = this;
        const { editText } = this.state;
        const isValid = this.permManager.validateReason();
        if (isValid) {
            props.onHandleSave({ text: editText })
                .then(() => {
                    const { objectId } = this.props;
                    this.permManager.saveManager(objectId)
                        .then(() => {
                            showSuccessNotification('Сохранено');
                            this.handleCloseModal();
                        });
                })
                .catch(() => {
                    showErrorNotification('Не удалось сохранить изменения');
                });
        }
    };

    handleOpenModal = () => this.setState({ isEditOpen: true });

    handleCloseModal = () => this.setState(defaultState);

    render() {
        const {
            name,
            text,
            placeholder,
            style,
            showEditButton,
            canEdit,
            fullWidth,
            mentions,
            formatting,
            redactorStyle,
            imagemanager,
            isLoading,
            contentType,
            objectId,
        } = this.props;

        const {
            editText,
            isEditOpen,
        } = this.state;

        let editButton = null;
        let editDialog = null;
        if (showEditButton && canEdit) {
            editButton = (
                <div style={ styles.iconStyle }>
                    <MrgIcon
                        className="icon_actions--compose"
                        title="редактировать"
                        onClick={ this.handleOpenModal }
                    />
                </div>
            );

            const actions = [
                <Button
                    style={ styles.mr10 }
                    onClick={ this.handleCloseModal }
                    label="Отмена"
                    key="cancel-button"
                />,
                <Button
                    primary
                    onClick={ this.handleSave }
                    label="Сохранить"
                    key="save-button"
                />,
            ];

            editDialog = (
                <Dialog
                    actions={ actions }
                    open={ isEditOpen }
                    title="Редактирование фрагмента"
                    hideCloseButton
                >
                    <span style={ styles.chunkNameStyle }>{name}</span>
                    <div>
                        <BeautifulRedactorField
                            fullWidth={ fullWidth }
                            formatting={ formatting }
                            plugins={ ['source', 'alignment'] }
                            style={ styles.redactorStyle(redactorStyle) }
                            mentions={ mentions }
                            imagemanager={ imagemanager }
                            value={ editText }
                            onChange={ this.handleTextChange }
                            disabled={ isLoading }
                        />
                        <div style={ styles.mt30 }>
                            <PermissionManager
                                ref={ (ref) => { this.permManager = ref; } }
                                permissionNames={ ['utils.edit_text'] }
                                contentType={ contentType }
                                objectId={ objectId }
                                disabled={ isLoading }
                                placeholder="Выбери модераторов"
                            />
                        </div>
                    </div>
                </Dialog>
            );
        }

        let content = null;
        if (text && text.length) {
            content = (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={ { __html: text } } />
            );
        } else {
            content = (showEditButton && canEdit && !isEditOpen) ? (
                <div>
                    <p><i>{ placeholder }</i></p>
                </div>
            ) : (
                <div />
            );
        }

        return (
            <div style={ getRootStyle(style, fullWidth) }>
                <div style={ styles.relativePos }>
                    { content }
                    { editButton }
                </div>
                { editDialog }
            </div>
        );
    }
}
