import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, A } from 'intdev-ui';
import { bindActionCreators } from 'redux';

import { commonMedia } from '@/common/media';
import * as constants from '../../builder/constants.es6';
import { fetchQuiz } from '../actions/quiz';
import { showNotification } from '../../../common/helpers/showNotification';
import { sendStrictQuestionError } from '../actions/questions';
import { goToNext, goToPrevious, goToStart } from '../actions/controls';
import { commitAnswers } from '../actions/answers';
import { splitAnswers } from '../utils';


class QuizControls extends React.Component {
    static propTypes = {
        currentGroup: PropTypes.shape(),
        tmpAnswers: PropTypes.shape().isRequired,
        questions: PropTypes.shape().isRequired,
        dbAnswers: PropTypes.shape().isRequired,
        strictQuestions: PropTypes.arrayOf(PropTypes.number).isRequired,
        commitAnswers: PropTypes.func.isRequired,
        quizId: PropTypes.number.isRequired,
        currentQuestion: PropTypes.shape(),
        choices: PropTypes.shape().isRequired,
        groups: PropTypes.shape().isRequired,
        controls: PropTypes.shape().isRequired,
        goToPrevious: PropTypes.func.isRequired,
        goToNext: PropTypes.func.isRequired,
        isTest: PropTypes.bool.isRequired,
        isRightAnswerDisplayed: PropTypes.bool.isRequired,
        fetchQuiz: PropTypes.func.isRequired,
        sendStrictQuestionError: PropTypes.func.isRequired,
        reloadAfterExecution: PropTypes.bool.isRequired,
        isRepeated: PropTypes.bool,
    };

    static defaultProps = {
        currentGroup: null,
        currentQuestion: null,
        isRepeated: null,
    };

    nextButton = () => {
        const {
            isRightAnswerDisplayed,
            controls,
            isTest,
        } = this.props;

        if (controls.finished) {
            if (this.props.reloadAfterExecution) {
                return <A href="/" className="quiz-detail__text_size_m"> Перейти на главную </A>;
            }
            return null;
        }
        if (this.hasQuestionsWithoutChoices()) {
            return <Button label="Далее" primary disabled { ...commonMedia.buttonStyles } />;
        }
        if (isTest && !isRightAnswerDisplayed) {
            return <Button label="Далее" primary onClick={ this.handleCheckChoiceButton } { ...commonMedia.buttonStyles } />;
        }
        return <Button label="Далее" primary onClick={ this.handleNextButtonClick } { ...commonMedia.buttonStyles } />;
    };

    prevButton = () => {
        const {
            isTest,
            controls,
            isRepeated,
        } = this.props;

        if (isTest || !isRepeated) {
            return null;
        }
        if (this.props.controls.finished) {
            return (
                <Button
                    label="Пройти ещё раз"
                    onClick={ this.handleAnotherTryButtonClick }
                    { ...commonMedia.buttonStyles }
                />
            );
        }
        const disabled = !(controls.prevGroupId || controls.prevQuestionId);
        return (
            <Button
                label="Назад"
                disabled={ disabled }
                onClick={ this.handlePrevButtonClick }
                { ...commonMedia.buttonStyles }
            />
        );
    };

    isEmptyArray = arr => ((Array.isArray(arr) && !arr.length));

    hasQuestionsWithoutChoices = () => {
        const {
            currentGroup,
            currentQuestion,
            questions,
        } = this.props;
        const objectsForCheck = [];
        if (currentQuestion) {
            // for GROUP_TYPE_TABLE_CHOICE and GROUP_TYPE_CONSECUTIVE
            if (currentQuestion.type !== constants.QUESTION_TYPE_FREE_ANSWER) {
                objectsForCheck.push(currentQuestion);
            }
        } else if (currentGroup && currentGroup.type === constants.QUESTION_GROUP_TYPE_ON_ONE_PAGE) {
            objectsForCheck.push(...Object.values(questions).filter(
                question => currentGroup.questions.includes(question.id),
            ).filter(
                question => question.type !== constants.QUESTION_TYPE_FREE_ANSWER,
            ));
        } else if (currentGroup) {
            // for GROUP_TYPE_TABLE_CHOICE and GROUP_TYPE_TABLE_MULTICHOICE
            objectsForCheck.push(currentGroup);
        }
        return objectsForCheck.filter(item => !item.choice_set.length).length > 0;
    };

    newControlsForNextButton = () => {
        const tmpAnswers = this.props.tmpAnswers;
        const dbAnswers = this.props.dbAnswers;
        const newControls = { currentQuestionId: null, prevQuestionId: null };
        if (this.props.currentQuestion) {
            // if QUESTION_GROUP_TYPE_CONSECUTIVE group
            // try to find next_question among choices from tmpAnswer or dbAnswer
            const answer = tmpAnswers[this.props.currentQuestion.id] || dbAnswers[this.props.currentQuestion.id];
            if (answer.value) {
                const choiceIds = (Array.isArray(answer.value)) ? answer.value : [answer.value];
                for (const choiceId of choiceIds) {
                    const choice = this.props.choices[choiceId];
                    if (choice.next_question) {
                        newControls.currentQuestionId = choice.next_question;
                        break;
                    }
                }
            }
            if (!newControls.currentQuestionId && this.props.currentQuestion.is_last) {
                // choice.next_question has higher priority than question.is_last
                newControls.currentGroupId = null;
                return newControls;
            }
            if (!newControls.currentQuestionId) {
                // if not is_last and no choice.next_question common behaviour for QUESTION_GROUP_TYPE_CONSECUTIVE group
                newControls.currentQuestionId = this.props.currentQuestion.next_question;
            }
            newControls.prevQuestionId = this.props.currentQuestion.id;
        }

        if (!newControls.currentQuestionId) {
            // if should display new group or current question is last question in current group
            const currentGroupId = this.props.currentGroup.next_question_group;
            newControls.currentGroupId = currentGroupId;
            if (currentGroupId) {
                const nextGroup = this.props.groups[currentGroupId];
                if (nextGroup.type === constants.QUESTION_GROUP_TYPE_CONSECUTIVE) {
                    newControls.currentQuestionId = nextGroup.first_question;
                    if (!newControls.currentQuestionId) {
                        // if no question in next group finish quiz
                        newControls.currentGroupId = null;
                    }
                }
            }
        }
        return newControls;
    };

    newControlsForPrevButton = () => {
        const {
            controls, groups,
        } = this.props;
        const currentGroup = groups[controls.currentGroupId];
        const newControls = { prevQuestionId: null, recentQuestionId: this.props.controls.currentQuestionId };
        if ((controls.prevQuestionId) && (this.props.currentGroup.questions.indexOf(controls.prevQuestionId) !== -1)) {
            // if previous question in same group
            const prevAnswer = this.props.dbAnswers[controls.prevQuestionId];
            newControls.prevQuestionId = prevAnswer.prevQuestionId;
            return newControls;
        }
        if ((!newControls.prevQuestionId) && (controls.prevGroupId)) {
            // if previous question in other group
            const prevGroup = this.props.groups[controls.prevGroupId];
            if (prevGroup.type === constants.QUESTION_GROUP_TYPE_CONSECUTIVE && controls.prevQuestionId) {
                const prevAnswer = this.props.dbAnswers[controls.prevQuestionId];
                newControls.prevQuestionId = prevAnswer.prevQuestionId;
            } else if (prevGroup.type === constants.QUESTION_GROUP_TYPE_CONSECUTIVE
                && constants.TABLE_GROUPS.includes(currentGroup.type)
            ) {
                newControls.currentQuestionId = prevGroup.questions[prevGroup.questions.length - 1];
                const prevAnswer = this.props.dbAnswers[newControls.currentQuestionId];
                if (prevAnswer) newControls.prevQuestionId = prevAnswer.prevQuestionId;
            }
            for (const strGroupId of Object.keys(this.props.groups)) {
                const groupId = parseInt(strGroupId, 10);
                // search group, which next_question_group value is current prevGroupId
                if (this.props.groups[groupId].next_question_group === controls.prevGroupId) {
                    newControls.prevGroupId = groupId;
                    break;
                }
            }
            if (!newControls.prevGroupId) {
                // for first group
                newControls.prevGroupId = null;
            }
        }
        return newControls;
    };

    handlePrevButtonClick = () => {
        const newControls = this.newControlsForPrevButton();
        this.props.goToPrevious(newControls);
    };

    handleNextButtonClick = () => {
        const { tmpAnswers } = this.props;
        const isAnswersValid = this.validateAnswers();

        if (!isAnswersValid) {
            return;
        }

        const newControls = this.newControlsForNextButton();

        if (newControls.prevQuestionId in tmpAnswers) {
            this.props.commitAnswers(
                this.props.quizId,
                splitAnswers({ [newControls.prevQuestionId]: tmpAnswers[newControls.prevQuestionId] }),
                newControls,
            );
        } else if (Object.keys(tmpAnswers).length) {
            this.props.commitAnswers(this.props.quizId, splitAnswers(tmpAnswers), newControls);
        } else {
            this.props.goToNext(newControls);
        }
    };

    handleCheckChoiceButton = () => {
        const { tmpAnswers } = this.props;
        const isAnswersValid = this.validateAnswers();

        if (!isAnswersValid) {
            return;
        }

        if (Object.keys(tmpAnswers).length) {
            this.props.commitAnswers(this.props.quizId, splitAnswers(tmpAnswers));
        }
    };

    handleAnotherTryButtonClick = () => {
        this.props.fetchQuiz(this.props.quizId, true);
    };

    validateAnswers = () => {
        const { tmpAnswers } = this.props;
        const notAnsweredQuestions = this.props.strictQuestions.filter((questionId) => {
            const tmpAnswer = tmpAnswers[questionId];
            if (!tmpAnswer) {
                return !(questionId in this.props.dbAnswers);
            }
            return (!tmpAnswer.text && (!tmpAnswer.value || this.isEmptyArray(tmpAnswer.value)));
        });
        if (notAnsweredQuestions.length) {
            showNotification({ level: 'error', message: 'Вопрос, помеченный звездочкой("*"), обязателен для ответа' });
            this.props.sendStrictQuestionError(notAnsweredQuestions);
            return false;
        }
        return true;
    };

    render() {
        return (
            <div>
                <div className="quiz-detail__controls">
                    { this.prevButton() }
                    { this.nextButton() }
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ quizApp: state }, ownProps) => {
    const currentGroup = state.groups[state.controls.currentGroupId];
    let strictQuestions = [];
    let isRightAnswerDisplayed = false;
    if (!currentGroup) {
        return {
            currentGroup,
            isRightAnswerDisplayed,
            strictQuestions,
            tmpAnswers: state.answers.tmpAnswers,
            dbAnswers: state.answers.dbAnswers,
            choices: state.choices,
            groups: state.groups,
            controls: state.controls,
            questions: state.questions,
        };
    }
    const { currentQuestionId } = state.controls;
    let currentQuestion; // only for QUESTION_GROUP_TYPE_CONSECUTIVE group
    if (currentGroup.type === constants.QUESTION_GROUP_TYPE_CONSECUTIVE && currentQuestionId) {
        currentQuestion = state.questions[currentQuestionId];
        if (currentQuestion.strict_answer) {
            strictQuestions.push(currentQuestionId);
        }
        if (ownProps.isTest && currentQuestionId in state.answers.dbAnswers) {
            isRightAnswerDisplayed = true;
        }
    } else {
        currentQuestion = null;
        strictQuestions = currentGroup.questions.filter(questionId => (state.questions[questionId].strict_answer));
    }
    return {
        currentGroup,
        tmpAnswers: state.answers.tmpAnswers,
        dbAnswers: state.answers.dbAnswers,
        choices: state.choices,
        groups: state.groups,
        controls: state.controls,
        questions: state.questions,
        isRightAnswerDisplayed,
        currentQuestion,
        strictQuestions,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    sendStrictQuestionError,
    commitAnswers,
    goToPrevious,
    goToNext,
    goToStart,
    fetchQuiz,
}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuizControls);
