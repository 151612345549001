import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'intdev-ui';
import { AttachmentGalleryItem } from './AttachmentGalleryItem';
import classes from './AttachmentGallery.scss';

export const AttachmentGallery = ({ attachments, handleDeleteAttachment }) => {
    const [carouselIsOpen, setCarouselIsOpen] = React.useState(false);
    const [carouselIndex, setCarouselIndex] = React.useState(0);

    if (!attachments.length) return null;

    const makeOpenCarouselHandler = index => () => {
        setCarouselIndex(index);
        setCarouselIsOpen(true);
    };

    const handleCloseCarousel = () => setCarouselIsOpen(false);

    const makeRemoveAttachmentHandler = itemId => (e) => {
        e.stopPropagation();
        handleDeleteAttachment(itemId);
    };

    return (
        <>
            <div className={ attachments.length === 1 ? classes.singleItemContainer : classes.grid }>
                { attachments.map((item, index) => {
                    const isLoading = typeof item === 'string';
                    return (
                        <AttachmentGalleryItem
                            key={ isLoading ? item : item.id }
                            previewText={ isLoading ? 'загружается...' : null }
                            imageUrl={ isLoading ? null : item.url }
                            onDelete={ (!isLoading && handleDeleteAttachment) ? makeRemoveAttachmentHandler(item.id) : null }
                            onClick={ !isLoading ? makeOpenCarouselHandler(index) : null }
                            single={ attachments.length === 1 }
                            first={ index === 0 }
                        />
                    );
                }) }
            </div>
            <Carousel
                images={ attachments.filter(item => typeof item !== 'string').map(({ id, url }) => ({ id, url })) }
                open={ carouselIsOpen }
                onRequestClose={ handleCloseCarousel }
                startIndex={ carouselIndex }
            />
        </>
    );
};

AttachmentGallery.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
        }),
        PropTypes.string,
    ])),
    handleDeleteAttachment: PropTypes.func,
};

AttachmentGallery.defaultProps = {
    attachments: [],
    handleDeleteAttachment: null,
};
