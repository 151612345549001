import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes, Popover } from 'intdev-ui';
import { callApi } from '../../middlewares/apiMiddleware';
import { UserTooltipContent } from './UserTooltipContent';
import { objCamelFromSnake } from '../../helpers/objCamelFromSnake';
import { TOOLTIP_INFO_URL } from './constants';

const styles = {
    errorTooltip: {
        display: 'flex',
        padding: '6px',
        width: '170px',
        fontSize: '12px',
    },
};

export default class UserTooltipContainer extends React.Component {
    static propTypes = {
        username: PropTypes.string.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.node,
        ]).isRequired,
        withAvatar: PropTypes.bool,
        withName: PropTypes.bool,
        avatarSize: PropTypes.number,
        wrapper: PropTypes.string,
        horizontal: PropTypes.oneOf(['left', 'middle', 'right']),
        vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
        allowSubscription: PropTypes.bool,
        rootStyle: IUIPropTypes.style,
    };

    static defaultProps = {
        withAvatar: true,
        withName: false,
        avatarSize: 64,
        wrapper: 'div',
        horizontal: 'right',
        vertical: 'top',
        allowSubscription: true,
        rootStyle: {},
    };

    state = {
        popoverIsOpen: false,
        popoverHovered: false,
        wrapperHovered: false,
        loading: true,
        userData: null,
        errorMessage: '',
        popoverTimer: null,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.username !== state.username && !state.loading) {
            return {
                username: props.username,
                loading: true,
            };
        }
        return props;
    }

    safeSetState = (obj) => {
        if (this.mounted) {
            this.setState(obj);
        }
    }

    handleClick = () => {
        clearTimeout(this.state.popoverTimer);
    };

    handleShowPopover = () => {
        if (this.state.loading) {
            this.loadData();
        }
        this.setState({ wrapperHovered: true });
        const popoverTimer = setTimeout(() => {
            if (this.state.wrapperHovered) {
                this.safeSetState({ popoverIsOpen: true });
            }
        }, 700);
        this.setState({ popoverTimer });
    };

    handleHidePopover = () => {
        this.setState({ wrapperHovered: false });
        setTimeout(() => {
            if (!this.state.popoverHovered) {
                this.safeSetState({ popoverIsOpen: false });
            }
        }, 100);
    };

    handleMouseEnterPopover = (event) => {
        this.setState({ popoverHovered: true });
        event.stopPropagation();
    };

    handleMouseLeavePopover = (event) => {
        this.setState({ popoverHovered: false });
        setTimeout(() => {
            if (!this.state.wrapperHovered) {
                this.safeSetState({ popoverIsOpen: false });
            }
        }, 100);
        event.stopPropagation();
    };

    changeUserData = (field, value) => this.safeSetState({
        userData: {
            ...this.state.userData, [field]: value,
        },
    });

    loadData = () => {
        callApi(TOOLTIP_INFO_URL(this.props.username, 200)).then(
            data => this.safeSetState({
                userData: objCamelFromSnake(data),
                loading: false,
                errorMessage: null,
            }),
        ).catch(
            () => this.safeSetState({ errorMessage: 'Не удалось загрузить данные сотрудника' }),
        );
    };

    renderPopover = () => {
        const { errorMessage, loading, userData } = this.state;
        const {
            withAvatar, withName, avatarSize, username, allowSubscription,
        } = this.props;
        if (errorMessage) {
            return (
                <div style={ styles.errorTooltip }>
                    {errorMessage}
                </div>
            );
        }
        return (
            <UserTooltipContent
                loading={ loading }
                userData={ userData }
                withAvatar={ withAvatar }
                withName={ withName }
                avatarSize={ avatarSize }
                username={ username }
                changeUserData={ this.changeUserData }
                allowSubscription={ allowSubscription }
            />
        );
    };

    render() {
        const {
            vertical,
            horizontal,
            rootStyle,
            wrapper: Wrapper,
            children,
        } = this.props;
        const {
            popoverIsOpen,
        } = this.state;
        return (
            <>
                <Wrapper
                    className="user-popover-container"
                    ref={ (ref) => { this.anchorEl = ref; } }
                    onClick={ this.handleClick }
                    onMouseEnter={ this.handleShowPopover }
                    onMouseLeave={ this.handleHidePopover }
                    style={ rootStyle }
                >
                    { children }
                </Wrapper>
                {
                    popoverIsOpen
                    && (
                        <Popover
                            open
                            useLayerForClickAway={ false }
                            anchorEl={ this.anchorEl }
                            anchorOrigin={ { horizontal: 'left', vertical } }
                            targetOrigin={ { horizontal: 'left', vertical: 'bottom' } }
                            onMouseEnter={ this.handleMouseEnterPopover }
                            onMouseLeave={ this.handleMouseLeavePopover }
                            style={ {
                                marginTop: '-5px',
                            } }
                        >
                            <div className="user__popover">
                                { this.renderPopover() }
                            </div>
                        </Popover>
                    )
                }
            </>
        );
    }
}
