import { SET_CALENDAR_CONTAINER_PROPS } from '../constants/actions';

export const calendarContainerProps = (state = {}, action) => {
    switch (action.type) {
        case SET_CALENDAR_CONTAINER_PROPS:
            return action.payload;
        default:
            return state;
    }
};
