import { FOOTER_TOGGLE } from '../constants/actions';

const footerEvent = (state = {}, action) => {
    switch (action.type) {
        case (FOOTER_TOGGLE):
            if (state.id === action.payload.id) {
                return {};
            }
            return action.payload;

        default:
            return state;
    }
};

export default footerEvent;
