import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IUIPropTypes, Button, ConfirmButton } from 'intdev-ui';

import { apiUrls } from '../constants/apiUrls';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { showNotification } from '../../common/helpers/showNotification';
import { MODAL_CREATE } from '../constants/modalTypes';
import { modalOpen } from '../actions';

const styles = {
    body: {
        dislay: 'flex',
    },
    buttonDiv: {
        dislay: 'flex',
        justifyContent: 'space-between',
        flex: '1 1 auto',
    },
    button: {
        flex: '0 0 auto',
        marginRight: '10px',
        verticalAlign: 'bottom',
    },
};

class CalendarToolbarComponent extends React.Component {
    static propTypes = {
        styles: IUIPropTypes.style,
        objectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        contentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        canCreate: PropTypes.bool.isRequired,
        canTruncate: PropTypes.bool.isRequired,
        handleAddButtonClick: PropTypes.func.isRequired,
        toolbarEnabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        styles: {},
    };

    handleClearButtonClick = () => {
        const url = apiUrls().meetingTruncate();
        const {
            contentType,
            objectId,
        } = this.props;
        callApi(url, 'post', { content_type: contentType, object_id: objectId }).then(() => {
            showNotification({ message: 'События успешно удалены', level: 'success' });
        }).catch(() => {
            showNotification({ message: 'Ошибка при удалении событий', level: 'error' });
        });
    };

    render() {
        if (!(this.props.canCreate || this.props.canTruncate) || !this.props.toolbarEnabled) {
            return null;
        }
        return (
            <div style={ { ...styles.body, ...this.props.styles } } >
                <div style={ styles.buttonDiv }>
                    {this.props.canCreate &&
                        <Button
                            label="Добавить событие"
                            onClick={ this.props.handleAddButtonClick }
                            primary
                            style={ styles.button }
                        />
                    }
                    <Button
                        label="Подписчики"
                        href={ apiUrls().meetingSubscribers(this.props.contentType, this.props.objectId) }
                        style={ styles.button }
                    />
                    {this.props.canTruncate &&
                        <ConfirmButton
                            label="Очистить события"
                            onClick={ this.handleClearButtonClick }
                            style={ styles.button }
                            dialogText="Действительно хочешь удалить все события в этом календаре?"
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.calendarConfig,
    toolbarEnabled: state.calendarContainerProps.toolbarEnabled,
});

const mapDispatchToProps = dispatch => ({
    handleAddButtonClick: () => dispatch(modalOpen(MODAL_CREATE)),
});

const CalendarToolbar = connect(mapStateToProps, mapDispatchToProps)(CalendarToolbarComponent);

export default CalendarToolbar;
