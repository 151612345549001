import { combineReducers } from 'redux';
import { header } from './header';
import { notificationSystem } from './notificationSystem';
import { isAuthErrorShown } from './auth';
import { menu } from './menu';

export const headerReducer = combineReducers({
    notificationSystem,
    isAuthErrorShown,
    header,
    menu,
});
