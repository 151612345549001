import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';

import ACTION_TYPES from '../actionTypes';
import { joinAnswers } from '../utils';
import * as constants from '../../builder/constants.es6';


const INIT_STATE = {
    tmpAnswers: {},
    dbAnswers: {},
};

export const answers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUIZ_LOAD_FINISH: {
            return update(state, {
                dbAnswers: {
                    $set: joinAnswers(action.payload.answers),
                },
            });
        }
        case ACTION_TYPES.TMP_ANSWER_SET: {
            const currentAnswer = state.tmpAnswers[action.questionId] || state.dbAnswers[action.questionId];
            return update(state, {
                tmpAnswers: {
                    [action.questionId]: {
                        $set: Object.assign({}, currentAnswer, action.answer),
                    },
                },
            });
        }
        case ACTION_TYPES.TMP_ANSWERS_INIT: {
            const initialAnswers = {};
            const notInDbQuestionList = action.questionList.filter(question => (
                !((question.id in state.dbAnswers) || (question.id in state.tmpAnswers))
            ));
            if (!notInDbQuestionList.length) {
                return state;
            }
            notInDbQuestionList.forEach((question) => {
                const emptyAnswer = {
                    text: null,
                    comment: null,
                    value: null,
                    prevQuestionId: action.prevQuestionId,
                };
                const multiChoiceQuestion = ([
                    constants.QUESTION_TYPE_PICTURES_MULTICHOICE,
                    constants.QUESTION_TYPE_MULTICHOICE,
                ].indexOf(question.type) !== -1);

                if ((action.groupType === constants.QUESTION_GROUP_TYPE_TABLE_MULTICHOICE) ||
                    (multiChoiceQuestion && action.groupType !== constants.QUESTION_GROUP_TYPE_TABLE_CHOICE)) {
                    emptyAnswer.value = [];
                }
                initialAnswers[question.id] = emptyAnswer;
            });
            return update(state, {
                tmpAnswers: {
                    $merge: initialAnswers,
                },
            });
        }
        case ACTION_TYPES.COMMIT_ANSWERS_FINISH: {
            return update(state, {
                dbAnswers: {
                    $merge: joinAnswers(action.payload.answers),
                },
                tmpAnswers: {
                    $unset: [action.payload.answers.map(answ => String(answ.question))],
                },
            });
        }
        case ACTION_TYPES.GO_TO_PREVIOUS: {
            const { recentQuestionId } = action.controls;
            const currentAnswer = state.tmpAnswers[recentQuestionId];
            if (currentAnswer?.text || currentAnswer?.value) {
                return state;
            }
            return update(state, {
                tmpAnswers: {
                    $unset: [String(recentQuestionId)],
                },
            });
        }
        case LOCATION_CHANGE:
            return INIT_STATE;
        default:
            return state;
    }
};
