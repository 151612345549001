import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { FORMDATA } from '@/common/constants/ajaxBodyTypes';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import { Timer } from './Timer';

export class VoiceMessage extends Component {
    static propTypes = {
        sendVoiceMessage: PropTypes.func.isRequired,
    };

    state = {
        mediaRecorder: null,
        recording: false,
        reset: false,
    };

    componentDidMount() {
        AudioRecorder.encoder = mpegEncoder;
        AudioRecorder.prototype.mimeType = 'audio/mpeg';
        window.MediaRecorder = AudioRecorder;
    }

    startRecord = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.start();
                this.setState({
                    mediaRecorder,
                    recording: true,
                    reset: false,
                });
                const audioChunks = [];
                mediaRecorder.addEventListener('dataavailable', (event) => {
                    audioChunks.push(event.data);
                    if (mediaRecorder.state === 'inactive' && !this.state.reset) {
                        callApi('/uploads/ajax-upload-audio/', 'POST', { audioChunks }, FORMDATA)
                            .then((response) => {
                                const text = `<p><audio controls preload="auto"><source src=${response.path} type="audio/mpeg">Твой браузер не поддерживает аудио :(</audio></p>`;
                                this.props.sendVoiceMessage(text);
                            })
                            .catch(() => showErrorNotification('Произошла ошибка'));
                    }
                });
            }).catch(() => {
                showErrorNotification('Запись звука заблокирована');
            });
        } else {
            showErrorNotification('Запись звука не поддерживается в вашем браузере');
        }
    };

    stopRecord = (reset = false) => {
        const { mediaRecorder } = this.state;
        if (mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            this.setState({
                mediaRecorder: null,
                recording: false,
                reset,
            });
        }
    };

    toggleRecord = () => {
        const { recording } = this.state;
        if (recording) {
            this.stopRecord();
        } else {
            this.startRecord();
        }
    };

    onCancel = () => {
        this.stopRecord(true);
    };

    render() {
        const { recording } = this.state;

        return (
            <div className="voice-message-container">
                { recording ? <Timer /> : null }
                <div className="voice-message-start-recording" onClick={ this.toggleRecord }>
                    <i className="fa fa-microphone" />
                    { recording ? <div className="voice-message-pulse-ring" /> : null }
                </div>
                { recording ? (
                    <>
                        <div className="voice-message-cancel" onClick={ this.onCancel }>
                            Отмена
                        </div>
                    </>
                ) : null }
            </div>
        );
    }
}
