export const copyToClipboard = (text, onSuccess, onError) => {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();

    const wasCopied = document.execCommand('copy');
    if (wasCopied) {
        if (onSuccess) {
            onSuccess();
        }
    } else if (onError) {
        onError();
    }
};
