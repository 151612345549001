import {
    LOAD_FEATURES,
    LOAD_FEATURES_SUCCESS,
    LOAD_FEATURES_FAIL,
} from '../constants/actionConstants';
import { apiUrls } from '../constants/apiUrls';
import { CALL_API } from '../../../constants/actionTypes';

export function loadFeatures(features) {
    return {
        type: CALL_API,
        method: 'GET',
        bodyFormat: 'json',
        endpoint: apiUrls.loadProjectFeaturesUrl(features),
        types: [
            LOAD_FEATURES,
            LOAD_FEATURES_SUCCESS,
            LOAD_FEATURES_FAIL,
        ],
    };
}
