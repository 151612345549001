import { schema } from 'normalizr';

export const arrayToObject = (array, keyField) => (
    array.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item[keyField]] = item;
        return obj;
    }, {})
);


export const joinAnswers = (answers) => {
    const newAnswers = {};
    for (const answer of answers) {
        if (answer.question in newAnswers) {
            const existAnswer = newAnswers[answer.question];
            if (answer.text) {
                existAnswer.text = answer.text;
            }
            if (!answer.choice) {
                // eslint-disable-next-line no-continue
                continue;
            }
            if (existAnswer.value instanceof Array) {
                existAnswer.value.push(answer.choice);
            } else if (existAnswer.value) {
                existAnswer.value = [existAnswer.value];
                existAnswer.value.push(answer.choice);
            } else {
                existAnswer.value = answer.choice;
            }
        } else {
            newAnswers[answer.question] = {
                value: answer.choice,
                text: answer.text,
                comment: answer.comment,
                prevQuestionId: answer.prev_question,
            };
        }
    }
    return newAnswers;
};


export const splitAnswers = (tmpAnswers) => {
    const answersArray = [];
    for (const questionId of Object.keys(tmpAnswers)) {
        const tmpAnswer = tmpAnswers[questionId];
        const newAnswer = { question: questionId, answers: [] };
        if (tmpAnswer.text) {
            // other_answer and free question
            newAnswer.answers.push({
                text: tmpAnswer.text,
                comment: tmpAnswer.comment,
                choice: null,
                prev_question: tmpAnswer.prevQuestionId,
            });
        }
        if (tmpAnswer.value instanceof Array) {
            // answers with several choices
            if (!tmpAnswer.value.length) {
                newAnswer.answers.push({
                    text: null,
                    comment: tmpAnswer.comment,
                    choice: null,
                    prev_question: tmpAnswer.prevQuestionId,
                });
            } else {
                tmpAnswer.value.forEach((choice) => {
                    newAnswer.answers.push({
                        text: null,
                        comment: tmpAnswer.comment,
                        choice,
                        prev_question: tmpAnswer.prevQuestionId,
                    });
                });
            }
        } else if (tmpAnswer.value) {
            // answer with one choice
            newAnswer.answers.push({
                text: null,
                comment: tmpAnswer.comment,
                choice: tmpAnswer.value,
                prev_question: tmpAnswer.prevQuestionId,
            });
        } else if (!tmpAnswer.text) {
            // empty answers
            newAnswer.answers.push({
                text: null,
                comment: tmpAnswer.comment,
                choice: tmpAnswer.value,
                prev_question: tmpAnswer.prevQuestionId,
            });
        }
        answersArray.push(newAnswer);
    }
    return answersArray;
};

const answerSchema = new schema.Entity('answers', {}, {
    idAttribute: 'question_id',
    mergeStrategy: (entityA, entityB) => {
        const result = {
            answered_at: entityA.answered_at,
            text: entityA.text || entityB.text,
            question_id: entityA.question_id,
        };
        let choiceFirst;
        let choiceSecond;
        if (entityA.choice_id && !Array.isArray(entityA.choice_id)) {
            choiceFirst = [entityA.choice_id];
        } else if (entityA.choice_id && Array.isArray(entityA.choice_id)) {
            choiceFirst = entityA.choice_id;
        }
        if (entityB.choice_id && !Array.isArray(entityB.choice_id)) {
            choiceSecond = [entityB.choice_id];
        } else if (entityB.choice_id && Array.isArray(entityB.choice_id)) {
            choiceSecond = entityB.choice_id;
        }
        if (choiceFirst && choiceSecond) {
            result.choice_id = choiceFirst.concat(choiceSecond);
        } else {
            result.choice_id = choiceSecond || choiceFirst;
        }
        return result;
    },
    processStrategy: (entity) => {
        if (entity.choice_id && !Array.isArray(entity.choice_id)) {
            const newEntity = Object.assign({}, entity);
            newEntity.choice_id = [entity.choice_id];
            return newEntity;
        }
        return entity;
    },
});

const userSchema = new schema.Entity('users', {}, { idAttribute: 'user_id' });
const statSchema = new schema.Entity('statistics', {});

export const userListSchema = [userSchema];
export const answerListSchema = [answerSchema];
export const statListSchema = [statSchema];
