import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'intdev-ui';
import { isMobile } from '@/common/mediaQueries';
import { Wheel } from './Wheel';
import classes from './TimelineHeader.scss';

export const TimelineHeader = ({
    tabs,
    curTabIndex,
    onTabClick,
}) => {
    const activeTabStyle = {
        borderBottom: 'none',
        background: '#fff',
        color: '#252069',
        ':hover': {
            borderBottom: 'none',
        },
        fontWeight: 'normal',
    };

    const tabStyle = {
        width: 'min-content',
        borderBottom: 'none',
        background: '#fafafa',
        textTransform: 'lowercase',
        ':hover': {
            borderBottom: 'none',
        },
        whiteSpace: 'nowrap',
        padding: isMobile ? '14px 20px' : '14px 32px',
        marginRight: isMobile ? 1 : 8,
        borderRadius: isMobile ? 0 : '8px 8px 0 0',
        fontSize: isMobile ? 14 : 16,
        lineHeight: '18px',
    };

    const tabsContainerStyle = {
        width: 'auto',
        borderBottom: 'none',
        overflowX: 'auto',
        overflowY: 'hidden',
    };

    return (
        <div className={ classes.container }>
            <Tabs
                tabs={ tabs }
                curTabIndex={ curTabIndex }
                onTabClick={ onTabClick }
                activeTabStyle={ activeTabStyle }
                tabStyle={ tabStyle }
                containerStyle={ tabsContainerStyle }
            />
            <a href="/me/settings/" className={ classes.settingsLink }>
                <Wheel />
            </a>
        </div>
    );
};

TimelineHeader.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
        tabsKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.node.isRequired,
    }), PropTypes.node])).isRequired,
    curTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onTabClick: PropTypes.func.isRequired,
};
