import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A, Popover } from 'intdev-ui';
import ContentTypeSettingsBox from './settings/ContentTypeSettingsBox';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { apiUrls } from '../../user_detail/constants';
import { showSuccessNotification, showErrorNotification } from '../../common/helpers/showNotification';
import { Write, Link as LinkIcon } from '../../common/components/icons';
import { copyToClipboard } from '../../common/helpers/copyToClipboard';

export const shareEditIconStyle = {
    width: '14px',
    height: '14px',
    marginRight: '4px',
    marginBottom: '1px',
    color: '#999',
    verticalAlign: 'text-bottom',
};


export class Settings extends Component {
    static propTypes = {
        contentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        filterData: PropTypes.func,
        reloadData: PropTypes.func,
        settingsUrl: PropTypes.string,
        objectId: PropTypes.number.isRequired,
        authUserData: PropTypes.shape({
            id: PropTypes.number.isRequired,
            username: PropTypes.string,
        }).isRequired,
        scrollToRecord: PropTypes.func,
        recordDate: PropTypes.string.isRequired,
        userData: PropTypes.shape({
            id: PropTypes.number,
            full_name_ro: PropTypes.string,
            username: PropTypes.string,
        }),
        id: PropTypes.number,
        showContentSettings: PropTypes.bool,
        showAuthorSettings: PropTypes.bool,
        canPersonalBlocking: PropTypes.bool,
        canDeleteAvatarRecords: PropTypes.bool,
        removeAllRecordsByUser: PropTypes.func,
        removeRecord: PropTypes.func,
        url: PropTypes.string,
        editUrl: PropTypes.string,
        onEditClick: PropTypes.func,
        showSettings: PropTypes.bool,
        onOpenSettings: PropTypes.func,
        type: PropTypes.string,
        isShow: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        url: null,
        editUrl: null,
        onEditClick: undefined,
        settingsUrl: '/me/settings/json/',
        userData: null,
        canPersonalBlocking: false,
        filterData: () => {},
        reloadData: () => {},
        scrollToRecord: () => {},
        removeAllRecordsByUser: () => {},
        removeRecord: () => {},
        canDeleteAvatarRecords: false,
        showContentSettings: true,
        showAuthorSettings: true,
        id: undefined,
        showSettings: false,
        onOpenSettings: undefined,
        type: 'пост',
        isShow: true,
        children: {},
    };

    state = {
        open: false,
        toRemove: false,
    };

    handleTouchTap = (event) => {
        if (this.props.onOpenSettings) {
            this.props.onOpenSettings();
        }
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    handleRequestRemove = () => {
        this.setState({
            toRemove: true,
        });
    };

    handleDeleteAvatarRecord = (event) => {
        event.preventDefault();
        callApi(apiUrls.avatarRecordUrl(this.props.id), 'DELETE', { user: this.props.userData.id }).then(() => {
            this.handleRequestClose();
            this.props.removeRecord(this.props.objectId);
            showSuccessNotification('Фотография удалена из ленты');
        }).catch(() => {
            showErrorNotification('Ошибка');
        });
    };

    handleHideContentByUser = (event) => {
        event.preventDefault();
        callApi(apiUrls.addToBlackListUrl, 'post', { user: this.props.userData.id }).then(() => {
            this.handleRequestClose();
            this.props.removeAllRecordsByUser(this.props.userData.id);
            showSuccessNotification('Готово!');
        }).catch((response) => {
            const message = ('user' in response) ? response.user[0] : response.detail || 'Ошибка';
            showErrorNotification(message);
        });
    };

    handleCopyLink = () => {
        const { url } = this.props;
        copyToClipboard(`${window.location.origin}${url}`,
            () => showSuccessNotification('Ссылка на пост скопирована'),
            () => showErrorNotification('Не удалось скопировать ссылку на пост'));
    };

    handleEditClick = () => {
        this.handleRequestClose();
        this.props.onEditClick();
    };

    render() {
        const {
            userData,
            authUserData,
            url,
            editUrl,
            onEditClick,
            canPersonalBlocking,
            canDeleteAvatarRecords,
            showSettings,
            showContentSettings,
            showAuthorSettings,
            type,
            isShow,
        } = this.props;

        if (this.state.toRemove) {
            return null;
        }

        const contentTypeSettings = showSettings && showContentSettings ? (
            <div className="timeline-record-settings-box">
                <ContentTypeSettingsBox
                    settingsUrl={ this.props.settingsUrl }
                    onRequestRemove={ this.handleRequestRemove }
                    onRequestClose={ this.handleRequestClose }
                    contentType={ this.props.contentType }
                    objectId={ this.props.objectId }
                    authUserId={ this.props.authUserData.id }
                    authUserUsername={ this.props.authUserData.username }
                    recordDate={ this.props.recordDate }
                    filterData={ this.props.filterData }
                    reloadData={ this.props.reloadData }
                    scrollToRecord={ this.props.scrollToRecord }
                />
            </div>
        ) : null;

        const deleteAvatarRecord = showSettings && canDeleteAvatarRecords ? (
            <div className="timeline-record-settings-box">
                <p>
                    <A
                        href="#"
                        className="timeline-record-setting-link"
                        onClick={ this.handleDeleteAvatarRecord }
                    >
                        Удалить из ленты
                    </A>
                </p>
            </div>
        ) : null;

        const displayHideUserContent = showAuthorSettings && canPersonalBlocking
            && userData && (userData.username !== authUserData.username);
        const hideUserContent = showSettings && displayHideUserContent ? (
            <div className="timeline-record-settings-box">
                <p>
                    <A
                        href="#"
                        className="timeline-record-setting-link"
                        onClick={ this.handleHideContentByUser }
                    >
                        { `Больше не показывать контент от ${userData.full_name_ro}` }
                    </A>
                </p>
            </div>
        ) : null;

        const shareEditStyles = (contentTypeSettings || deleteAvatarRecord || hideUserContent)
            ? 'timeline-record-settings-box timeline-record-settings-box-share-edit timeline-record-settings-box-border'
            : 'timeline-record-settings-box timeline-record-settings-box-share-edit';

        const shareEditContainer = (url || editUrl) ? (
            <div className={ shareEditStyles }>
                {
                    url && (
                        <div>
                            <A
                                onClick={ this.handleCopyLink }
                                style={ { color: '#333' } }
                            >
                                <LinkIcon style={ shareEditIconStyle } />
                                { `Ссылка на ${type}` }
                            </A>
                        </div>
                    )
                }
                {
                    (editUrl || onEditClick) && (
                        <div>
                            <A
                                href={ editUrl }
                                style={ { color: '#333' } }
                                onClick={ onEditClick && this.handleEditClick }
                            >
                                <Write style={ shareEditIconStyle } />
                                <span>Редактировать</span>
                            </A>
                        </div>
                    )
                }
            </div>
        ) : null;

        if (!(contentTypeSettings || deleteAvatarRecord || hideUserContent || shareEditContainer)) {
            return null;
        }

        return (
            <div
                onClick={ this.handleTouchTap }
                style={ { cursor: isShow ? 'pointer' : 'inherit' } }
                className="timeline-record-settings-container"
            >
                { this.props.children }
                {
                    isShow && (
                        <div>
                            <i
                                className="timeline-record-settings-button fa fa-angle-down fa-2x btn"
                                title="Настройки"
                            />
                            <Popover
                                open={ this.state.open }
                                anchorEl={ this.state.anchorEl }
                                anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
                                targetOrigin={ { horizontal: 'right', vertical: 'top' } }
                                onRequestClose={ this.handleRequestClose }
                            >
                                { contentTypeSettings }
                                { deleteAvatarRecord }
                                { hideUserContent }
                                { shareEditContainer }
                            </Popover>
                        </div>
                    )
                }
            </div>
        );
    }
}
