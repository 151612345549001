import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import UserTooltipContainer from '@/common/components/UserTooltipContainer';
import { Record as BasicRecord } from './Record';

const styles = {
    teachersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    teacherContainer: {
        margin: '4px ',
        maxWidth: '82px',
    },
    teacherAvatar: {
        width: '80px',
        height: '80px',
        borderRadius: '80px',

        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundColor: '#DDD',
        border: '1px solid #CCC',
        verticalAlign: 'middle',
    },
    teacherName: {
        textAlign: 'center',
    },
};

export class TermStudentRecord extends React.Component {
    static propTypes = {
        teachers: PropTypes.arrayOf(PropTypes.shape({
            first_name: PropTypes.string,
            avatar: PropTypes.string,
            url: PropTypes.string,
        })).isRequired,
        text: PropTypes.string.isRequired,
        action_text: PropTypes.string,
        header: PropTypes.string,
        userData: PropTypes.object,
        parent_related_text: PropTypes.string,
    };

    static defaultProps = {
        action_text: '',
        header: '',
        userData: undefined,
        parent_related_text: '',
    };

    render() {
        const recordHead = (
            <span>
                <UserTooltipContainer
                    username={ this.props.userData.username }
                    withName
                    wrapper="span"
                >
                    { this.props.action_text }
                    &nbsp;
                    <A
                        className="inline-link timeline-header"
                        href={ this.props.userData.url }
                    >
                        { this.props.header }
                    </A>
                    &nbsp;
                    { this.props.parent_related_text }
                </UserTooltipContainer>
            </span>
        );
        return (
            <BasicRecord
                recordHead={ recordHead }
                recordBody={ (
                    <div>
                        <div>{ this.props.text }</div>
                        <div style={ styles.teachersContainer }>
                            { this.props.teachers.map(teacher => (
                                <div
                                    style={ styles.teacherContainer }
                                    key={ `teacher-${teacher.url}` }
                                >
                                    <A href={ teacher.url }>
                                        <div style={ {
                                            ...styles.teacherAvatar,
                                            backgroundImage: `url(${teacher.avatar})`,
                                        } }
                                        />
                                        <div style={ styles.teacherName }>{ teacher.first_name }</div>
                                    </A>
                                </div>
                            )) }
                        </div>
                    </div>
                ) }
                { ...this.props }
            />
        );
    }
}
