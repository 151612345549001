export default {
    micropostPlaceholder: name => `Бо ҳамкасбони худ ${name} чизе мубодила кунед`,
    timeline: 'чорабинӣ Тасма',
    comments: 'Шарҳҳо',
    like: 'Монанди',
    subscribeToComments: 'Обуна ба шарҳҳо',
    addingNewComment: 'Илова кардани тавзеҳи нав',
    send: 'Ирсоли паём',
    profile: 'Профили',
    settings: 'Танзимот',
    search: 'Ҷустуҷӯ',
    logout: 'Натиҷа',
    notificationSettings: 'ТАНЗИМОТИ ОГОҲӢ',
    here: 'ин ҷо',
    bell: 'Занг занед',
    resetToDefault: 'Ба ҳолати пешфарз',
    settingUpMailings: 'НАСБ КАРДАНИ ПАЁМНАВИСӢ',
    profileSettings: 'ТАНЗИМОТИ ПРОФИЛ',
    timelineSettings: 'МУТОБИҚСОЗИИ ТАСМА',
    all: 'Ҳама',
    subscriptions: 'Обунаҳо',
    nothing: 'Ҳеҷ чиз',
    subscriptionLikes: 'Обуна маъқул аст',
    subscriptionLikesHelp: 'Навори шумо дорои сабтҳое мебошад, ки онҳоро кормандони шумо дӯст медоранд',
    birthPubHelp: 'Ин танзимот ба намоиши профил, саҳифаи ахбори даста ва мундариҷаи ахбор таъсир мерасонад',
    jubileePubHelp: 'Ин танзимот ба саҳифаи Ахбори Team таъсир мерасонад',
    addNote: 'Илова кардани ёддошт',
    editBlog: 'Таҳрири блог',
    edit: 'таҳрир кунед',
    delete: 'нест кардан',
    makeMailing: 'варақаи иттилоотӣ эҷод кунед',
    anchor: 'анчуман',
    newNoteInBlog: 'Хабари нави блог',
    title: 'Сарлавҳа',
    author: 'Муаллиф',
    blogsToNote: 'Блогҳо барои навиштан',
    tags: 'Тегҳо',
    publicate: 'Нашр',
    saveDraft: 'Наҷоти лоиҳаи',
    thanks: () => 'rahmat',
    balance: 'Тавозун',
    likes: 'Маъқул',
    photos: 'Суратхо',
    stickers: 'Часпандаҳо',
    calls: 'Зангҳо',
    followers: 'Пайравони',
    follows: 'Обунаҳо',
    changeHistory: 'Таърихро тағир диҳед',
    internalNumber: 'Рақами тамдид',
    mobileNumber: 'Рақами мобилӣ',
    workEmail: 'Почтаи корӣ',
    office: 'Дафтар',
    personalEmail: 'Почтаи шахсӣ',
    inCaseOfAbsence: 'Дар ҳолати набудани тамос',
    badges: 'Нишонаҳо',
    aboutWork: 'Дар бораи кор',
    information: 'Маълумот',
    skills: 'Малакаҳо',
    callHistory: 'Таърихи занг',
    lookFor: 'ҷустуҷӯ',
    sortBy: 'Ҷудо кардан ба:',
    advancedSearch: 'Ҷустуҷӯи васеъи кормандон',
    letsLookSearch: 'Биёед чизеро ҷӯем...',
    byRelevance: 'ростӣ',
    byTime: 'вақт',
    filters: 'Филтрҳо',
    ideasStatus: 'Мақоми ғояҳо',
    popularCategories: 'Категорияҳои маъмул',
    addIdea: 'Илова кардани идея',
    ideas: 'Fikrlar',
    status: 'Вазъият',
    category: 'Гурӯҳ',
    responsibleExecutor: 'Иҷрокунандаи масъул',
    ideaCategory: 'Категорияи идеяҳо:',
    addingIdea: 'Илова кардани идея',
    selectIdeaCategory: 'Як категорияи идеяро интихоб кунед',
    ideaTitle: 'Ном',
    writeIdeaTitle: 'Номи идеяро, ки моҳиятро инъикос мекунад, нависед (мухтасар)',
    solvedProblem: 'Масъалаи ҳалшаванда',
    whatProblemSolved: 'Идея кадом масъаларо ҳал мекунад?',
    expectedResult: 'Натиҷаи чашмдошт',
    whatImprovemenents: 'Кадом беҳбудиҳо ба назар мерасанд ва кадом нишондиҳандаҳо беҳтар хоҳанд шуд?',
    authorIsReady: 'Муаллиф омода аст, ки ғояи худро мустақилона амалӣ намояд:',
    authorIsNotReady: 'Муаллиф барои амалӣ кардани идеяи худ НЕСТ:',
    yes: 'Бале',
    no: 'На',
    enterIdeaDescription: 'Тавсифи ғояро ворид кунед',
    undo: 'Бекор кардан',
    create: 'Эҷоди як',
    date: 'санаи',
    user: 'Корбар',
    callerID: 'Шиноскунандаи рақам',
    destinationNumber: 'Рақами таъинот',
    my: 'Ман',
    popular: 'Машҳур',
    uploadedByMe: 'Боргузорӣ аз ҷониби ман',
    stickersCount: () => 'стикер',
    added: 'Илова намуд',
    uploadedBy: 'Бор карда шудааст:',
    stickerSelection: 'Интихоби часпак',
    addingImage: 'Илова кардани тасвир',
    select: 'Интихоб кунед',
    noFileSelected: 'Ягон файл интихоб нашудааст',
    or: 'ё',
    fileURL: 'суроғаи файл',
    download: 'Зеркашӣ кунед',
    forSubscribers: 'Муштариён',
    forAll: 'Ба ҳама',
    showAdditionalInfo: 'Маълумоти иловагиро нишон диҳед',
    hideAdditionalInfo: 'Маълумоти иловагӣ пинҳон кунед',
    subscription: 'Обуна',
    startTypingName: 'Ба навиштани номи корманд оғоз кунед',
    top: 'Муаллифони',
    authors: 'беҳтарин',
    hotline: 'Телефони боварӣ',
    blogs: 'Блогҳо',
    publish: 'Нашр',
    subscribeToBlog: 'Ба блог обуна шавед',
    startTyping: 'навиштанро оғоз кунед',
    toAnswer: 'Барои посух додан',
    idea: 'Ғоя',
    profilePhotos: 'Сурати профил',
    albums: 'Албомҳо',
    marks: 'Маркҳо',
    addTo: 'илова ба',
    staff: 'Кормандон',
    subdivisions: 'Воҳидҳо',
    blocked: 'Манъ карда шудааст',
    addStickerPack: 'Бастаи стикерҳоро илова кунед',
    stickerPackListIsEmpty: 'Рӯйхати стикерҳои шумо холӣ аст',
    additional: 'Илова бар ин',
    badgesProgress: 'Нишонаҳои пешрафт',
    leaders: 'Роҳбарон',
    projects: 'Лоиҳаҳо',
    education: 'Маориф',
    languages: 'Забонҳои',
    hobbies: 'Хоб',
    aboutMyself: 'Дар бораи худам',
    residenceAddress: 'Суроғаи истиқомат',
    bornInTheCity: () => 'Shaharda tug\'ilgan',
    birthDate: 'Таърихи таввалуд',
    iLiveInTheCity: 'Ман дар шаҳр зиндагӣ мекунам',
    workBefore: companyName => `Пеш аз он ки ${companyName} кор мекард`,
    giveThanks: 'Сипос гӯед',
    confirmedTheSkill: (gender, self) => `${self ? 'Маҳорати худро тасдиқ кард' : 'Маҳорати тасдиқшуда'}`,
    addTheSkill: (gender, self) => `${self ? 'Ба шумо маҳорат илова кард' : 'Илова маҳорат'}`,
    notifications: 'Огоҳиҳои',
    markAllAsReserved: 'Ҳама тавре, ки дида шудааст, қайд кунед',
    contacts: 'Тамос',
    mobilePhone: 'Телефони мобилӣ',
    primary: 'Aсосӣ',
    otherContacts: 'Дигар тамосҳо',
    telegramNickname: 'Ник дар Телеграм',
    tamTamNickname: 'Ник дар Tam-Tam',
    whatsappPhone: 'телефон дар WhatsApp',
    viberPhone: 'телефон дар Viber',
    skypeLogin: 'воридшавӣ дар Скайп',
    vkNickname: 'Ник дар Вконтакте',
    additionalEmail: 'иловагӣ email',
    enterYourPrivateEmail: 'паёми электронии шахсии худро ворид кунед',
    externalAccounts: 'Ҳисобҳои беруна',
    additionalLinks: 'Истинодҳои иловагӣ',
    save: 'Захира кунед',
    cancel: 'Бекоркунӣ',
    chooseProjects: 'лоиҳаҳоро интихоб кунед',
    infoAboutAbsence: 'Маълумот дар бораи набудани',
    nearestMetroStation: 'Наздиктарин истгоҳи метро',
    gettingToWork: 'Ман ба кор медароям',
    driversLicense: 'Шаҳодатномаи ронандагӣ',
    exWork: companyName => `${companyName ? `пеш аз кор кардани ${companyName}` : 'қаблан кор карда буд\n'}`,
    foreignLanguages: 'Забонҳои хориҷӣ',
    recommendSubscribing: 'Мо тавсия медиҳем, ки обуна шавед',
    accept: 'Тасдиқ кунед',
    peopleWhoAcceptedSkill: 'шахс маҳоратро тасдиқ кардааст',
    achievement: 'Дастрасӣ',
    confirmed: 'исботшуда',
    commentsRating: 'Рейтинг шарҳҳо',
    postsRating: 'Рейтинг паёмҳо',
    ideasRating: 'Рейтинг ғояҳо',
    micropostsRating: 'Рейтинг микропост',
    overallRating: 'Рейтинги умумӣ',
    viewed: 'Дида шуд',
    noNotifications: 'Огоҳии нест',
    noNewNotifications: 'Огоҳии нав нест',
    showAllNotifications: 'Ҳамаро бинед',
};
