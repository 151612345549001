import React from 'react';
import { SvgIcon } from 'intdev-ui';

/* eslint-disable max-len */
export const Delete = props => (<SvgIcon className="delete-icon" { ...props }>
    <path fill="currentColor" d="M6.983 5.455l4.34-4.34a1.105 1.105 0 0 1 1.562 1.562l-4.34 4.34 4.34 4.34a1.08 1.08 0 1 1-1.528 1.528l-4.34-4.34-4.34 4.34a1.105 1.105 0 0 1-1.562-1.562l4.34-4.34-4.34-4.34a1.08 1.08 0 1 1 1.528-1.528l4.34 4.34z" />
</SvgIcon>);
/* eslint-enable max-len */

Delete.defaultProps = {
    viewBox: '0 0 16 16',
    fillRule: 'evenodd',
    color: 'currentColor',
    hoverColor: 'currentColor',
};

export default Delete;
