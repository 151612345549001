import { chunkLoader } from '../spa/utils/chunkLoader';

const initialized = {
    core: false,
    plugins: [],
};

const vendorPlugins = ['fullscreen', 'iconic', 'source'];
const customPlugins = [
    'cut',
    'imagemanager',
    'mention',
    'ishortcuts',
    'stickers',
    'learn',
    'attachments',
    'pasted',
    'emojifix',
    'twitch',
    'alignment',
    'video',
    'table',
    'clearfloat',
];

/* eslint-disable import/dynamic-import-chunkname */
const supportPlugins = {};
vendorPlugins.forEach((p) => {
    supportPlugins[p] = () => chunkLoader(() => import(/* webpackChunkName: "redactor-vendor-[request]" */ `../../vendor/redactor/plugins/${p}`));
});
customPlugins.forEach((p) => {
    supportPlugins[p] = () => chunkLoader(() => import(/* webpackChunkName: "redactor-custom-[request]" */ `./plugins/${p}`));
});
/* eslint-enable */


export default async function redactorInitializer(plugins) {
    const pluginPromises = [];

    if (!initialized.core) {
        await import(/* webpackChunkName: "redactor" */ './core');
        initialized.core = true;
    }

    pluginPromises.push(...plugins.map((p) => {
        !initialized.plugins.includes(p) && initialized.plugins.push(p);
        return supportPlugins[p];
    }));

    return Promise.all(pluginPromises.map(p => p()));
}
