/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Dialog, RadioSelect, Select, TextField, TimePicker, Validation } from 'intdev-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showNotification } from '../../../common/helpers/showNotification';
import { isEmptyObject } from '../../../common/helpers/helpers';
import { apiUrls } from '../../constants/apiUrls';
import { UserSuggestions } from '../../../user_suggestions/userSuggestions';
import {
    eventModalCleanValidationErrors,
    eventModalSetValidationErrors,
    eventPanelUpdate,
    loadEvent,
    modalClose,
} from '../../actions';
import EventEditPanel from './EventEditPanel';
import EventSinglePanel from './EventSinglePanel';
import UserJoinAllowedSelect from '../UserJoinAllowedSelect';
import getValidationProps from '../../helpers/getValidationProps';
import { getModalData } from '../../reducers';
import { SUGGESTION_SELECT_VALUE_SHAPE } from '../../../user_suggestions/constants';
import { VALIDATION_ERRORS_SHAPE } from '../../constants/shapes';
import formatDuration from '../../helpers/formatDuration';
import { choiceShape } from '../../../common/constants/propTypes';

export const CREATE_TYPE_SINGLE = 'single';
export const CREATE_TYPE_COPY = 'copy';
export const CREATE_TYPE_SERIES = 'series';

export const styles = {
    actionsContainerStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogBody: {
        padding: '0 20px 4px',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    fieldRow: {
        display: 'flex',
        paddingBottom: '16px',
    },
    delimiterDiv: {
        flex: '0 0 auto',
        alignSelf: 'center',
        textAlign: 'center',
        width: '12px',
        fontSize: '16px',
    },
    fieldLabelDiv: {
        flex: '1 0 0',
        height: '30px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    fieldLabelInner: {
        alignSelf: 'center',
        textAlign: 'right',
        paddingRight: '10px',
    },
    fieldInputBody: {
        alignSelf: 'center',
        flex: '3 0 0',
        display: 'flex',
    },
    fieldInnerInputBody: {
        flex: '1 0 0',
    },
    radioSelectStyleSet: {
        wrapperStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        style: {
            flex: '1 1 auto',
        },
    },
    actionContainer: {
        display: 'flex',
    },
    actionElement: {
        flex: '0 0 auto',
    },
};

export const isFieldValueEmpty = (value) => {
    switch (typeof value) {
        case 'string':
            return value === '';
        default:
            return value === null || value === undefined;
    }
};

// eslint-disable-next-line react/prop-types
export const labeledComponent = Component => ({ label, flexOrder, ...props }) => (
    <div style={ { ...(flexOrder ? { order: flexOrder } : {}), ...styles.fieldRow } }>
        <div style={ styles.fieldLabelDiv }>
            <div style={ styles.fieldLabelInner }>{label}</div>
        </div>
        <div style={ styles.fieldInputBody }>
            <Component { ...props } />
        </div>
    </div>
);

export const LabeledRadioSelect = labeledComponent(RadioSelect);
export const LabeledSelect = labeledComponent(Select);
export const LabeledTextField = labeledComponent(TextField);
export const LabeledTimePicker = labeledComponent(TimePicker);
export const LabeledUserSuggestions = labeledComponent(UserSuggestions);
export const LabeledUserJoinAllowedSelect = labeledComponent(UserJoinAllowedSelect);

export const ACCEPT_DATE_FORMAT = 'YYYY-MM-DD';

export const RESULT_OK = 'OK';

export const PANEL_GLOBAL_ERROR = 'nonFieldErrors';


export class EventEditModalComponent extends React.Component {
    static propTypes = {
        event: PropTypes.shape({ id: PropTypes.number }).isRequired,
        // dispatch
        loadEvent: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        setValidationErrors: PropTypes.func.isRequired,
        eventPanelUpdate: PropTypes.func.isRequired,
        // from state
        name: PropTypes.string.isRequired,
        location: choiceShape,
        place: PropTypes.string.isRequired,
        capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        canPlus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        startTime: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
        isSeries: PropTypes.bool.isRequired,
        owner: SUGGESTION_SELECT_VALUE_SHAPE,
        processing: PropTypes.bool.isRequired,
        validationErrors: VALIDATION_ERRORS_SHAPE.isRequired,
        usersJoinAllowed: PropTypes.arrayOf(SUGGESTION_SELECT_VALUE_SHAPE).isRequired,
    };

    static defaultProps = {
        objectId: null,
        contentType: null,
        canChangeOwner: null,
        location: null,
        owner: null,
        capacity: null,
    };

    componentDidMount() {
        if (this.props.event.id) {
            this.loadData(this.props.event);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.event.id && nextProps.event.id !== this.props.event.id) {
            this.loadData(nextProps.event);
        }
    }

    getModalTitle = () => {
        const title = this.props.isSeries ? 'Редактировать серию событий' : 'Редактировать событие';

        const validationProps = getValidationProps(this.props.validationErrors, PANEL_GLOBAL_ERROR);
        if (validationProps) {
            return (
                <div>
                    {title}
                    <Validation
                        state={ validationProps.validationState }
                        value={ validationProps.validationText }
                    />
                </div>
            );
        }
        return title;
    };

    loadData = (event) => {
        this.props.loadEvent(event.meetingId).catch(() => {
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
            this.props.onClose();
        });
    };

    handleSave = () => {
        const {
            event,
            name,
            location,
            place,
            capacity,
            canPlus,
            startDate,
            startTime,
            duration, // renamed to eventDuration
            owner,
            usersJoinAllowed,
        } = this.props;
        const startMoment = moment(startDate).add(moment.duration(startTime));
        const requestData = {
            id: event.id,
            name,
            location: location && location.id,
            place,
            capacity,
            can_plus: canPlus,
            duration: formatDuration(moment.duration(duration)),
            owner: owner ? owner.value || owner : null,
            users_join_allowed: usersJoinAllowed.map(user => user.value),
            ...(this.props.isSeries ? {} : { start_time: startMoment.toISOString() }),
        };

        // check required fields
        const errors = {};
        const requiredFields = new Set([
            'name',
            'location',
            'place',
            'can_plus',
            'owner',
            'duration',
        ]);
        Object.entries(requestData).forEach(([key, val]) => {
            const isFieldRequired = requiredFields.has(key);
            if (isFieldRequired && isFieldValueEmpty(val)) {
                errors[key] = 'Это поле обязательно';
            }
        });
        if (!isEmptyObject(errors)) {
            this.props.setValidationErrors(errors);
            return;
        }

        const url = apiUrls().meetingDetail(event.meetingId);

        this.props.eventPanelUpdate(url, requestData).then((data) => {
            const {
                fail,
                statusCode,
                ...payload
            } = data;
            if (fail) {
                this.props.setValidationErrors(payload);
                showNotification({ message: 'Ошибка при сохранении', level: 'warning' });
            } else {
                showNotification({ message: 'Событие сохранено', level: 'success' });
                this.props.onClose();
            }
        }).catch(() => {
            showNotification({ message: 'Ошибка при выполнении запроса', level: 'error' });
        });
    };

    render() {
        const actions = [
            <Button
                label="Отмена"
                onClick={ this.props.onClose }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
            <Button
                label="Сохранить"
                primary
                onClick={ this.handleSave }
                disabled={ this.props.processing }
                style={ styles.actionElement }
            />,
        ];

        return (
            <Dialog
                open
                autoDetectWindowHeight
                autoScrollBodyContent
                bodyStyle={ styles.dialogBody }
                title={ this.getModalTitle() }
                actions={ actions }
                actionsContainerStyle={ styles.actionsContainerStyle }
                onRequestClose={ this.props.onClose }
            >
                <div style={ styles.fieldContainer }>
                    <EventEditPanel />
                    { (!this.props.isSeries) &&
                        <EventSinglePanel />
                    }
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadEvent,
    eventPanelUpdate,
    onClose: modalClose,
    setValidationErrors: eventModalSetValidationErrors,
    cleanValidationErrors: eventModalCleanValidationErrors,
}, dispatch);

const mapStateToProps = state => ({
    ...getModalData(state),
});

const EventEditModal = connect(mapStateToProps, mapDispatchToProps)(EventEditModalComponent);

export default EventEditModal;
