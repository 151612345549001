import ACTION_TYPES from '../actionTypes';


export const goToPrevious = controls => ({
    type: ACTION_TYPES.GO_TO_PREVIOUS,
    controls,
});

export const goToNext = controls => ({
    type: ACTION_TYPES.GO_TO_NEXT,
    controls,
});

export const goToStart = controls => ({
    type: ACTION_TYPES.GO_TO_START,
    controls,
});
