import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { callApi } from '../../../../common/middlewares/apiMiddleware';
import { configureStore } from '../../../../common/helpers/store';
import { attachmentReducersMap } from '../../../../blog/reducers';
import PublicationAttachmentsContainer from '../../../../blog/components/BlogDetail/PublicationAttachmentsContainer';
import { CustomProvider } from '../../../../common/components/CustomProvider';


class Attachments extends Component {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.number),
        objectId: PropTypes.number.isRequired,
        contentTypeId: PropTypes.number.isRequired,
    };
    static defaultProps = {
        ids: [],
    };
    constructor(props) {
        super(props);
        this.publicationStore = configureStore(combineReducers({
            ...attachmentReducersMap,
        }));
        this.state = {
            canManage: false,
        };
    }

    componentDidMount() {
        const { objectId, contentTypeId } = this.props;
        callApi(`/attachments/${contentTypeId}/${objectId}/context/`).then(
            response => this.setState({ canManage: response.can_manage }),
        );
    }


    render() {
        const {
            props: { ids, objectId, contentTypeId },
        } = this;
        if (!ids.length) {
            return null;
        }
        return (
            <CustomProvider store={ this.publicationStore }>
                <PublicationAttachmentsContainer
                    canManageAttachments={ this.state.canManage }
                    objectId={ String(objectId) }
                    contentType={ String(contentTypeId) }
                />
            </CustomProvider>
        );
    }
}

export default Attachments;
