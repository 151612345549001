export const styles = {
    container: {
        display: 'grid',
        gridRowGap: '8px',
    },
    label: {
        color: '#a6a6a6',
    },
    dropzone: {
        default: {
            width: '100%',
            height: '80px',
            color: '#a6a6a6',
            fontSize: '15px',
            textDecoration: 'none',
            cursor: 'pointer',
            textAlign: 'center',
            border: '2px dashed rgb(240, 240, 240)',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        active: {
            backgroundColor: 'rgba(240, 240, 240, .7)',
        },
    },
};
