import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '@/common/mediaQueries';
import { dictionary } from '@/common/localization/dictionary';
import EmojiTooltip from './EmojiTooltip';
import {
    emojiTooltipPositions,
    EMOJI_TOOLTIP_WIDTH,
    LIKE_ELEMENT_WIDTH,
    HUMAN_EMOJI,
} from '../constants';
import EmojiMobileTooltip from './EmojiMobileTooltip';


export class EmojiForm extends React.Component {
    static propTypes = {
        isLiked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        textPrefix: PropTypes.bool.isRequired,
        handleLike: PropTypes.func.isRequired,
        emojiTooltipPosition: PropTypes.oneOf(emojiTooltipPositions),
    };

    static defaultProps = {
        isLiked: undefined,
        emojiTooltipPosition: null,
    };

    state = {
        isTooltipActive: false,
        isPrefixHovered: false,
        tooltipPosition: undefined,
    };

    handleShowTooltip = (clientX) => {
        const { isPrefixHovered } = this.state;
        if (!isPrefixHovered) {
            return;
        }

        const { emojiTooltipPosition } = this.props;
        let tooltipPosition = 'top_center';
        if (emojiTooltipPosition) {
            tooltipPosition = emojiTooltipPosition;
        } else if (clientX < (EMOJI_TOOLTIP_WIDTH + LIKE_ELEMENT_WIDTH) / 2) {
            tooltipPosition = 'top_left';
        }

        this.setState({ isTooltipActive: true, tooltipPosition });
    };

    handleHideTooltip = () => {
        this.setState({ isTooltipActive: false, isPrefixHovered: false });
    };

    handleMouseEnter = (event) => {
        event.preventDefault();
        const { clientX } = event;
        this.setState({ isPrefixHovered: true });
        setTimeout(() => this.handleShowTooltip(clientX), 0);
    };

    renderPrefix = (isLiked, style) => (
        !isLiked && isLiked !== 0
            ? <div className="like-element">{ dictionary.like }</div>
            : <div className={ `${style} like-element` }>{ HUMAN_EMOJI[isLiked].text }</div>

    );

    handleLike = () => {
        const { isLiked } = this.props;
        const like = !isLiked && isLiked !== 0 ? 0 : isLiked;
        this.props.handleLike(like);
    };

    handleEmojiLike = (like) => {
        this.props.handleLike(like);
        this.handleHideTooltip();
    };

    handleOpenMobilePopover = (event) => {
        const { target } = event;
        this.setState({
            isTooltipActive: true,
            tooltipPosition: target,
        });
    };

    handleCloseMobilePopover = () => {
        this.setState({
            isTooltipActive: false,
            tooltipPosition: null,
        });
    };

    render() {
        const {
            isLiked,
            textPrefix,
        } = this.props;

        const {
            isTooltipActive,
            tooltipPosition,
        } = this.state;

        let prefixStyle = 'liked-prefix yellow';
        if (parseInt(isLiked, 10) === 0) {
            prefixStyle = 'liked-prefix';
        } else if (parseInt(isLiked, 10) === 5) {
            prefixStyle = 'liked-prefix red';
        }

        return (isMobile
            ? (
                <div style={ { position: 'relative' } }>
                    <div
                        className="like-button"
                        onClick={ this.handleOpenMobilePopover }
                    >
                        {
                            textPrefix
                                ? this.renderPrefix(isLiked, prefixStyle)
                                : <i className="like-element like-icon fa fa-heart" />
                        }
                    </div>
                    <EmojiMobileTooltip
                        isOpenPopover={ isTooltipActive }
                        onLike={ this.props.handleLike }
                        anchor={ tooltipPosition }
                        handleClosePopover={ this.handleCloseMobilePopover }
                    />
                </div>
            )
            : (
                <div
                    style={ { position: 'relative' } }
                    onMouseEnter={ this.handleMouseEnter }
                    onMouseLeave={ this.handleHideTooltip }
                >
                    <div
                        className="like-button"
                        onClick={ this.handleLike }
                    >
                        {
                            textPrefix
                                ? this.renderPrefix(isLiked, prefixStyle)
                                : <i className="like-element like-icon fa fa-heart" />
                        }
                    </div>
                    {
                        !isLiked && isLiked !== 0
                            ? (
                                <EmojiTooltip
                                    isTooltipActive={ isTooltipActive }
                                    onLike={ this.handleEmojiLike }
                                    tooltipPosition={ tooltipPosition }
                                />
                            ) : null
                    }
                </div>
            )
        );
    }
}
