import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, SvgIcon } from 'intdev-ui';

export const RemoveButton = ({ onClick }) => (
    <IconButton
        onClick={ onClick }
        style={ { width: 20, height: 20, padding: 0 } }
        iconStyle={ {
            width: 16,
            height: 16,
            padding: 0,
            fill: 'white',
        } }
    >
        <SvgIcon viewBox="-3 -3 20 20" fillRule="evenodd" color="#ececec" hoverColor="white">
            <path d="M6.983 5.455l4.34-4.34a1.105 1.105 0 0 1 1.562 1.562l-4.34 4.34 4.34 4.34a1.08 1.08 0 1 1-1.528 1.528l-4.34-4.34-4.34 4.34a1.105 1.105 0 0 1-1.562-1.562l4.34-4.34-4.34-4.34a1.08 1.08 0 1 1 1.528-1.528l4.34 4.34z" />
        </SvgIcon>
    </IconButton>
);

RemoveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
