import { createSelector } from 'reselect';

export const ROOT_STYLE_DEFAULT = {
    display: 'inline-block',
};

export const POPOVER_ROOT_STYLE = {
    display: 'inline-block',
};

export const QUESTION_ICON_STYLE_DEFAULT = {
    marginLeft: '5px',
    cursor: 'pointer',
};

export const POPOVER_STYLE_DEFAULT = {
    maxWidth: '500px',
    padding: '8px',
    marginTop: '-5px',
};

export default function getStyleSelector() {
    return createSelector(
        props => props.style,
        props => props.popoverStyle,
        props => props.questionIconStyle,
        (style, popoverStyle, questionIconStyle) => (
            {
                style: { ...ROOT_STYLE_DEFAULT, ...style },
                popoverStyle: { ...POPOVER_STYLE_DEFAULT, ...popoverStyle },
                questionIconStyle: { ...QUESTION_ICON_STYLE_DEFAULT, ...questionIconStyle },
                popoverRootStyle: POPOVER_ROOT_STYLE,
            }
        ),
    );
}
