import React from 'react';
import { Record as BasicRecord } from './Record';


export const BasicRecordWithDate = React.forwardRef((props, ref) => (
    <BasicRecord
        { ...props }
        ref={ ref }
        isShow={ false }
        showSettings
        showContentSettings
    />
));
