export const moduleName = 'timeline';

export const pagesUrls = {
    userProfile: username => `/users/${username}/`,
    taggedTimeline: tagName => `/timeline/tagged/${tagName}/`,
    blogUrl: id => `/ctype/85/obj/${id}/`,
};

export const apiUrls = {
    fullTimeline: '/timeline/api/full/',
    cTypes: '/timeline/api/ctypes/',
    userTimeline: '/timeline/api/user/',
    tagTimelineInfo: tag => `/timeline/api/tagged_info/${tag}/`,
    tagTimeline: '/timeline/api/tagged/',
    getSingleRecordData: id => `/timeline/record/${id}/json/`,
    getTimeLineCustomTabs: '/timeline/api/tabs/',
};

export const CUSTOM_TAB_PREFIX = 'tab_';
export const CUSTOM_TABS_TYPES = {
    BLOG: 'a',
    CATEGORY: 'b',
    OTHER: 'c',
    SINCERE_SERVICE: 'd',
};
export const NEWS_BLOG_ID = 5;
