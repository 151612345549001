import React from 'react';
import { TextField } from 'intdev-ui';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as constants from '../../../builder/constants.es6';
import ChoiceQuestion from './ChoiceQuestion';
import MultiChoiceQuestion from './MultiChoiceQuestion';
import FreeAnswerQuestion from './FreeAnswerQuestion';
import DropDownQuestion from './DropDownQuestion';
import SliderQuestion from './SliderQuestion';
import { changeAnswer } from '../../actions/answers';
import { commonMedia } from '@/common/media';


class Question extends React.Component {
    static propTypes = {
        question: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.number,
            allow_comment: PropTypes.bool,
            text: PropTypes.string,
            hidden: PropTypes.bool,
            allow_other: PropTypes.bool,
            limit_answers: PropTypes.number,
            strict_answer: PropTypes.bool,
        }).isRequired,
        changeAnswer: PropTypes.func.isRequired,
        comment: PropTypes.string.isRequired,
        isQuestionWithError: PropTypes.bool.isRequired,
        selectedChoiceMessage: PropTypes.string.isRequired,
        isTest: PropTypes.bool,
        isRightAnswerDisplayed: PropTypes.bool,
        compactMode: PropTypes.bool,
    };

    static defaultProps = {
        isRightAnswerDisplayed: false,
        isTest: false,
        compactMode: false,
    };

    getQuestion = () => {
        switch (parseInt(this.props.question.type, 10)) {
            case constants.QUESTION_TYPE_CHOICE:
                return (<ChoiceQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                    isRightAnswerDisplayed={ this.props.isRightAnswerDisplayed }
                />);
            case constants.QUESTION_TYPE_MULTICHOICE:
                return (<MultiChoiceQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                    isRightAnswerDisplayed={ this.props.isRightAnswerDisplayed }
                />);
            case constants.QUESTION_TYPE_FREE_ANSWER:
                return (<FreeAnswerQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                />);
            case constants.QUESTION_TYPE_DROPDOWN:
                return (<DropDownQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                />);
            case constants.QUESTION_TYPE_SCALE:
                return (<SliderQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                />);
            case constants.QUESTION_TYPE_PICTURES_CHOICE:
                return (<ChoiceQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                    isRightAnswerDisplayed={ this.props.isRightAnswerDisplayed }
                />);
            case constants.QUESTION_TYPE_PICTURES_MULTICHOICE:
                return (<MultiChoiceQuestion
                    question={ this.props.question }
                    onChange={ this.handleChangeAnswer }
                    isRightAnswerDisplayed={ this.props.isRightAnswerDisplayed }
                />);
            default:
                return null;
        }
    };

    handleChangeAnswer = (event, answer) => {
        this.props.changeAnswer(this.props.question.id, answer);
    };

    handleChangeComment = (event, comment) => {
        this.props.changeAnswer(this.props.question.id, { comment });
    };

    messageAfterAnswer = () => {
        const message = this.props.selectedChoiceMessage;
        if (!message) {
            return null;
        }
        const fontSizeClassName = `quiz-detail__text_size_${(this.props.compactMode) ? 's' : 'm'}`;
        return (
            <div
                className={ `quiz-detail__message-after-answer quiz-detail__text_style_italic ${fontSizeClassName}` }
                dangerouslySetInnerHTML={ { __html: message } }
            />
        );
    };

    render() {
        const commentContainer = (
            <div className="quiz-detail__question-comment">
                <TextField
                    multiLine
                    fullWidth
                    rows={ 4 }
                    value={ this.props.comment }
                    onChange={ this.handleChangeComment }
                    name={ `comment${this.props.question.id}` }
                    hintText="Комментарий к ответу"
                    hintStyle={ commonMedia.textFieldHintStyle }
                    inputStyle={ commonMedia.fontSizeBody }
                />
            </div>
        );

        return (
            <div className="quiz-detail__question-container" hidden={ this.props.question.hidden }>
                <div className={
                    `quiz-detail__question
                     quiz-detail__text_size_${(this.props.compactMode) ? 's' : 'm'}
                      ${this.props.isQuestionWithError ? ' strict-question' : ''}` }
                >
                    { this.props.question.strict_answer && <span className="quiz-detail__strict-icon">*</span> }
                    <div dangerouslySetInnerHTML={ { __html: this.props.question.text } } />
                </div>
                { this.getQuestion() }
                { this.messageAfterAnswer() }
                { this.props.question.allow_comment && commentContainer }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeAnswer,
}, dispatch);

const mapStateToProps = ({ quizApp: state }, ownProps) => {
    let selectedChoiceMessage = '';
    const answer = state.answers.tmpAnswers[ownProps.question.id] ||
        state.answers.dbAnswers[ownProps.question.id] || {};

    if (ownProps.isTest) {
        // if quiz is test - get selected choice of dbAnswer and define message for user
        let choice = null;
        const dbAnswer = state.answers.dbAnswers[ownProps.question.id];
        if (dbAnswer) {
            if (Array.isArray(dbAnswer.value)) {
                const choices = dbAnswer.value.filter(choiceId => state.choices[choiceId].message);
                if (choices.length) {
                    choice = choices[0];
                }
            } else if (dbAnswer.value && state.choices[dbAnswer.value].message) {
                choice = state.choices[dbAnswer.value];
            }
        }
        if (choice) {
            selectedChoiceMessage = choice.message;
        }
    }
    const isQuestionWithError = (state.questionsWithError.indexOf(ownProps.question.id) !== -1);

    return {
        comment: answer.comment || '',
        selectedChoiceMessage,
        isQuestionWithError,
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Question);
