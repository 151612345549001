import { CALL_API } from '../../../constants/actionTypes';
import { apiUrls } from '../constants/apiUrls';
import {
    LOAD_SELF_PROFILE_START,
    LOAD_SELF_PROFILE_SUCCESS,
    LOAD_SELF_PROFILE_FAIL,
} from '../constants/actionConstants';
import { showNotification } from '../../../helpers/showNotification';


export const loadSelfProfile = () => (
    {
        type: CALL_API,
        endpoint: apiUrls.loadSelfProfileUrl,
        method: 'GET',
        types: [
            LOAD_SELF_PROFILE_START,
            LOAD_SELF_PROFILE_SUCCESS,
            {
                type: LOAD_SELF_PROFILE_FAIL,
                callback: () => showNotification({ level: 'error', message: 'Не удалось загрузить профиль' }),
            },
        ],
    }
);
