import { LOCAL_STORAGE_EXPIRE_MINUTES as EXPIRE_TIME } from '../../common/constants/localStorage';
// see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
// "Feature-detecting localStorage" section for details

export function storageAvailable(type) {
    const storage = window[type];
    try {
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' || // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED')) &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}

export function localStorageAvailable() {
    return storageAvailable('localStorage');
}

export const setLocalStorageText = (text, localStorageName, localStorageTime = null) => {
    if (localStorageAvailable()) {
        localStorage.setItem(localStorageName, text);
        if (localStorageTime) {
            const lastSaveDate = new Date();
            localStorage.setItem(localStorageTime, lastSaveDate.toString());
        }
    }
};

export const clearLocalStorageText = (localStorageName, localStorageTime = null) => {
    setLocalStorageText('', localStorageName, localStorageTime);
};

export const getLocalStorageText = (localStorageName, localStorageTime, localStorageExpireMinutes = EXPIRE_TIME) => {
    if (localStorageAvailable()) {
        if (!localStorageTime) {
            return localStorage.getItem(localStorageName) || '';
        }
        const lastSaveDateCache = localStorage.getItem(localStorageTime);
        const lastSaveDate = lastSaveDateCache
            ? new Date(lastSaveDateCache)
            : new Date();
        const minutesDifference = (new Date() - lastSaveDate) / 60000;
        if (minutesDifference < localStorageExpireMinutes) {
            return localStorage.getItem(localStorageName) || '';
        }
        clearLocalStorageText('', localStorageName, localStorageTime);
    }
    return '';
};
