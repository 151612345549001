import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    A, Button, ConfirmButton, Dialog, UserInfo,
} from 'intdev-ui';
import { isMobile } from '@/common/mediaQueries';
import { dictionary } from '@/common/localization/dictionary';
import { styles } from '@/services/styles';
import LikesBlock from '../../../likes/LikesBlock';
import CommentTooltip from '../helpers/CommentTooltip';
import CommentsList from './CommentsList';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer/UserTooltipContainer';
import { showNotification } from '../../../common/helpers/showNotification';
import { copyToClipboard } from '../../../common/helpers/copyToClipboard';
import CommentBlackListToggle from './CommentBlackListToggle';


export default class Comment extends Component {
    static propTypes = {
        editInterval: PropTypes.number.isRequired,
        data: PropTypes.shape().isRequired,
        userId: PropTypes.number.isRequired,
        updateLikes: PropTypes.func.isRequired,
        commentCtype: PropTypes.number.isRequired,
        changeParent: PropTypes.func.isRequired,
        changeEdit: PropTypes.func.isRequired,
        handleMakeOfficial: PropTypes.func.isRequired,
        parentForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        editForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
        parentComment: PropTypes.shape(),
        actions: PropTypes.shape().isRequired,
        officialResponse: PropTypes.shape().isRequired,
        objectUrl: PropTypes.string.isRequired,
        isOfficialComment: PropTypes.bool,
        handleRequestClose: PropTypes.func,
        parentShowInappropriateContent: PropTypes.bool,
        onBlackListUpdate: PropTypes.func,
        deleteComment: PropTypes.func,
        canReply: PropTypes.bool,
        likesBlockUuid: PropTypes.string,
    };

    static defaultProps = {
        parentComment: {},
        isOfficialComment: false,
        handleRequestClose: undefined,
        parentShowInappropriateContent: undefined,
        onBlackListUpdate: undefined,
        canReply: false,
        likesBlockUuid: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            showCloseButton: false,
            requestToShowComment: false,
            deleteDialogIsOpen: false,
        };
    }

    handleMouseEnter = () => {
        if (this.props.handleRequestClose) {
            this.setState({ showCloseButton: true });
        }
    };

    handleMouseLeave = () => {
        if (this.props.handleRequestClose) {
            this.setState({ showCloseButton: false });
        }
    };

    copyToClipboard = (link) => {
        copyToClipboard(
            link,
            () => {
                showNotification({
                    level: 'success',
                    message: 'Ссылка на комментарий скопирована',
                });
            },
            () => {
                showNotification({
                    level: 'error',
                    message: 'Не удалось скопировать ссылку на комментарий',
                });
            },
        );
    };

    handleShowInappropriateContent = () => this.setState({ requestToShowComment: true });

    isContentHided = () => (
        !this.state.requestToShowComment && typeof this.props.data.inappropriate_content === 'string'
    );

    handleScrollToParent = (parentComment) => {
        const parentDiv = document.getElementById(`comment${parentComment.id}`);
        if (parentDiv) parentDiv.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    showDeleteDialog = () => this.setState({ deleteDialogIsOpen: true });

    hideDeleteDialog = () => this.setState({ deleteDialogIsOpen: false });

    deleteComment = () => {
        this.props.deleteComment(this.props.data.id);
        this.hideDeleteDialog();
    };

    getDialogActions = () => [
        <Button
            label="Нет"
            onClick={ this.hideDeleteDialog }
        />,
        <Button
            label="Да"
            onClick={ this.deleteComment }
            primary
        />,
    ];

    render() {
        const {
            editInterval, userId, parentForm, editForm, commentCtype, data,
            parentComment, actions, officialResponse, objectUrl, isOfficialComment,
        } = this.props;

        const canEdit = data.userid === userId
            && (!editInterval || (data.timestamp - data.timestamp_created) < editInterval);

        let officialResponseButton = '';
        const officialResponseAction = actions.official_response;
        if (officialResponseAction) {
            const canMakeOfficialResponse = (
                officialResponseAction.response_ids.indexOf(data.userid) !== -1
                && data.userid === userId
            ) || officialResponseAction.can_make_official_response;
            if (canMakeOfficialResponse) {
                let responseText = 'Официальный ответ';
                if (Object.keys(officialResponse).length) responseText = 'Новый официальный ответ';
                officialResponseButton = (
                    <ConfirmButton
                        dialogTitle="Заменить официальный ответ"
                        onClick={ () => this.props.handleMakeOfficial(data) }
                        title="Сделать комментарий официальным ответом на идею"
                        buttonElement={ <a className="comment-make-official-response">{responseText}</a> }
                        dialogText="Ты действительно хочешь изменить официальный ответ?"
                    />
                );
            }
        }
        let commentStyle = '';
        if (!isOfficialComment && officialResponseAction) {
            if (officialResponseAction.response_ids.indexOf(data.userid) !== -1) {
                commentStyle = ' responsible-user';
            } else if (data.userid === officialResponseAction.author_id) {
                commentStyle = ' author';
            }
        }

        const editButtons = (
            <>
                <A
                    onClick={ this.showDeleteDialog }
                    title="удалить комментарий"
                    className="comment-edit"
                >
                    Удалить
                </A>
                <Dialog
                    open={ this.state.deleteDialogIsOpen }
                    actions={ this.getDialogActions() }
                    actionsContainerStyle={ styles.dialog.actions }
                    title="Вы уверены, что хотите удалить комментарий?"
                    modal
                />
                { data.text.includes('<audio')
                    ? null
                    : (
                        <A
                            onClick={ () => this.props.changeEdit(data.id) }
                            title="редактировать комментарий"
                            className="comment-edit"
                        >
                            Редактировать
                        </A>
                    )}
            </>
        );

        let commentActions;
        if (!isOfficialComment) {
            commentActions = (
                <div className="comment-actions">
                    {
                        this.state.showCloseButton && !isMobile
                        && (
                            <A
                                href=""
                                onClick={ this.props.handleRequestClose }
                                style={ { color: '#aaaaaa', marginRight: 10 } }
                            >
                                Свернуть комментарии
                            </A>
                        )
                    }
                    { officialResponseButton }
                    { canEdit && editButtons }
                    <LikesBlock
                        contentTypeId={ commentCtype }
                        objectId={ data.id }
                        count={ data.count_likes }
                        isLiked={ data.is_liked }
                        updateLikes={ this.props.updateLikes }
                        style={ { marginRight: 10 } }
                        uuid={ this.props.likesBlockUuid }
                    />
                    { this.props.canReply && (
                        <A
                            onClick={ () => this.props.changeParent(data.id) }
                            title="ответить на этот комментарий"
                            className="comment-reply"
                        >
                            { dictionary.toAnswer }
                        </A>
                    )}
                </div>
            );
        } else {
            commentActions = (
                <div className="comment-actions">
                    <LikesBlock
                        contentTypeId={ commentCtype }
                        objectId={ data.id }
                        count={ data.count_likes }
                        isLiked={ data.is_liked }
                        updateLikes={ this.props.updateLikes }
                        style={ { marginRight: 10 } }
                        uuid={ this.props.likesBlockUuid }
                    />
                </div>
            );
        }

        // eslint-disable-next-line no-restricted-globals
        const commentLink = `${location.protocol}//${location.host}${objectUrl || location.pathname}#comment${data.id}`;

        const updatedAtTimestamp = this.props.data.updated_at ? moment(this.props.data.updated_at) : null;

        const updatedAt = updatedAtTimestamp ? (
            <div className="comment-updated-at">
                { data.is_delete ? 'удалено' : 'отредактировано' }
                { updatedAtTimestamp.isSame(moment(), 'day') && ` в ${updatedAtTimestamp.format('HH:mm')}` }
            </div>
        ) : null;

        const fromMobile = data.from_mobile ? (
            <div className="comment-from-mobile">
                Отправлено из мобильной версии&nbsp;
                <span style={ { color: '#999999' } }>
                    Интранет
                </span>
            </div>
        ) : null;

        const commentAnchor = (
            <div>
                <A
                    onClick={ () => this.copyToClipboard(commentLink) }
                    className="comment-anchor"
                    title="ссылка на этот комментарий"
                >
                    #
                </A>
            </div>
        );

        const commentReply = isMobile
            ? (
                <A
                    className="comment-anchor"
                    onClick={ () => this.handleScrollToParent(parentComment) }
                >
                    в ответ на комментарий пользователя {parentComment.username}
                </A>
            )
            : (
                <CommentTooltip
                    data={ parentComment }
                    showInappropriateContent={
                        this.props.parentShowInappropriateContent
                    }
                    key={ `child-comment${data.id}` }
                >
                    <A
                        className="comment-anchor"
                        onClick={ () => this.handleScrollToParent(parentComment) }
                    >
                        в ответ на комментарий...
                    </A>
                </CommentTooltip>
            );

        const commentHeader = (
            <div className="comment-header">
                <div className="comment-header-left">
                    <UserTooltipContainer username={ data.user } wrapper="span">
                        <A
                            href={ data.userlink }
                            style={ isMobile ? { color: '#000' } : {} }
                        >
                            { data.username }
                        </A>
                    </UserTooltipContainer>
                    <div className="comment-time">{ data.created_at }</div>
                    { !isMobile && commentAnchor }
                    {
                        data.parent
                            ? (commentReply)
                            : null
                    }
                </div>
                {
                    this.props.data.userid !== this.props.userId
                    && (
                        <div className="comment-header-right">
                            { isMobile && commentAnchor }
                            <CommentBlackListToggle
                                username={ this.props.data.user }
                                onChangeBlackListStatus={ this.props.onBlackListUpdate }
                            />
                        </div>
                    )
                }
            </div>
        );
        return (
            <div className={ `comment-item${data.isNew ? ' comment-new' : ''}` }>
                <div id={ `comment${data.id}` } className="comment-head-anchor" />
                <div
                    className={ `comment${commentStyle}` }
                    onMouseEnter={ this.handleMouseEnter }
                    onMouseLeave={ this.handleMouseLeave }
                >
                    <div className="b-flexrow">
                        <div className="comment-sidebar">
                            <UserTooltipContainer username={ data.user }>
                                <UserInfo
                                    avatarSize={ isMobile ? 35 : 40 }
                                    fullname={ data.username }
                                    avatar={ data.photo }
                                    url={ data.userlink }
                                    hideFullname
                                    className="user-tooltip-avatar"
                                />
                            </UserTooltipContainer>
                            { isMobile && commentHeader }
                        </div>
                        <div className="comment-content">
                            { !isMobile && commentHeader }
                            {
                                this.isContentHided() ? (
                                    <div className="comment-body hideable-content">
                                        <p>{ this.props.data.inappropriate_content }</p>
                                        <p onClick={ this.handleShowInappropriateContent }>Показать</p>
                                    </div>
                                ) : (
                                    <div className="comment-body">
                                        {/* eslint-disable-next-line */}
                                        <div dangerouslySetInnerHTML={ { __html: data.text } } />
                                    </div>
                                )
                            }
                            <div className="comment-additional">
                                { fromMobile }
                                { updatedAt }
                            </div>
                        </div>
                    </div>
                    { data.is_delete ? null : commentActions }
                </div>
                {
                    (data.children && data.children.length) || (parentForm === data.id || editForm === data.id)
                        ? (
                            <CommentsList
                                { ...{
                                    ...this.props,
                                    list: data.children,
                                    parentComment: data,
                                    isNested: true,
                                    parentShowInappropriateContent: !this.isContentHided(),
                                } }
                                onBlackListUpdate={ this.props.onBlackListUpdate }
                                replyForm={ parentForm === data.id || editForm === data.id }
                            />
                        )
                        : null
                }
            </div>
        );
    }
}
